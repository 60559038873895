import { AirlineImageI } from "../types/flightListTypes";

const baseUrl = process.env.REACT_APP_AIRLINE_IMAGE_URL;

export const airlinesImg: AirlineImageI[] = [
  {
    id: "758a221f-cc1b-4025-b97a-d52a66807f2e",
    iata: "IS",
    airlineName: "AIS Airlines",
    imgUrl: `${baseUrl}/IS.png`,
  },
  {
    id: "da48cbf2-7f69-43d4-967c-19af23ecd4e8",
    iata: "UJ",
    airlineName: "ALMasria Universal Airlines",
    imgUrl: `${baseUrl}/UJ.png`,
  },
  {
    id: "f9dbdef2-4cc6-412a-bcc7-5f9091ae8ba3",
    iata: "6R",
    airlineName: "ALROSA",
    imgUrl: `${baseUrl}/6R.png`,
  },
  {
    id: "6b400f9d-8542-4804-a87d-3be424ea89cf",
    iata: "EH",
    airlineName: "ANA Wings",
    imgUrl: `${baseUrl}/EH.png`,
  },
  {
    id: "b7d16809-5502-4d8c-9a6a-1702ded28d0f",
    iata: "GP",
    airlineName: "APG Airlines",
    imgUrl: `${baseUrl}/GP.png`,
  },
  {
    id: "0ae46ac3-fd13-49da-8d78-79061c05ba7c",
    iata: "KP",
    airlineName: "ASKY Airlines",
    imgUrl: `${baseUrl}/KP.png`,
  },
  {
    id: "62d36cc8-712b-47a0-b7e0-904efd2af1f1",
    iata: "5O",
    airlineName: "ASL Airlines France",
    imgUrl: `${baseUrl}/5O.png`,
  },
  {
    id: "bb134801-577e-4351-8be3-950db0db14a0",
    iata: "I3",
    airlineName: "ATA Airlines",
    imgUrl: `${baseUrl}/I3.png`,
  },
  {
    id: "36bff7f6-dadd-4efc-8f60-fac6de81b11d",
    iata: "ZF",
    airlineName: "AZUR Air",
    imgUrl: `${baseUrl}/ZF.png`,
  },
  {
    id: "b2bdbfe6-bf05-4150-8654-a19da79de87b",
    iata: "AN",
    airlineName: "Advanced Air",
    imgUrl: `${baseUrl}/AN.png`,
  },
  {
    id: "b8835392-cc0e-4b45-9f4b-8ac981f5d3e5",
    iata: "A3",
    airlineName: "Aegean Airlines",
    imgUrl: `${baseUrl}/A3.png`,
  },
  {
    id: "af921869-e2be-4d5f-a304-a1092dd0394e",
    iata: "EI",
    airlineName: "Aer Lingus",
    imgUrl: `${baseUrl}/EI.png`,
  },
  {
    id: "1efc68be-69e1-459c-be1b-23c117d4e5fb",
    iata: "EG",
    airlineName: "Aer Lingus (U.K.)",
    imgUrl: `${baseUrl}/EG.png`,
  },
  {
    id: "7245ee15-b47f-4b32-bc61-e03a385ce43f",
    iata: "EI",
    airlineName: "Aer Lingus Regional",
    imgUrl: `${baseUrl}/EI.png`,
  },
  {
    id: "c205572d-783a-4c6c-8865-3fee845f0d95",
    iata: "RF",
    airlineName: "Aero K",
    imgUrl: `${baseUrl}/RF.png`,
  },
  {
    id: "379b97ff-1818-486d-8634-d029bebb52c6",
    iata: "M0",
    airlineName: "Aero Mongolia",
    imgUrl: `${baseUrl}/M0.png`,
  },
  {
    id: "fc23fb98-59d9-40f0-b3c9-b7cb33d33b06",
    iata: "KA",
    airlineName: "Aero Nomad Airlines",
    imgUrl: `${baseUrl}/KA.png`,
  },
  {
    id: "166d3f82-59d7-421e-8f87-1313f85bd080",
    iata: "XZ",
    airlineName: "AeroItalia",
    imgUrl: `${baseUrl}/XZ.png`,
  },
  {
    id: "dcd8a8d1-ffaa-4572-be20-1c3c539fce7d",
    iata: "A8",
    airlineName: "AeroLink",
    imgUrl: `${baseUrl}/A8.png`,
  },
  {
    id: "b48734d6-f71b-49e6-887c-582662839459",
    iata: "SU",
    airlineName: "Aeroflot",
    imgUrl: `${baseUrl}/SU.png`,
  },
  {
    id: "baa1625b-ebf8-4d01-a242-2bbb2797132b",
    iata: "KG",
    airlineName: "Aerogaviota",
    imgUrl: `${baseUrl}/KG.png`,
  },
  {
    id: "d4e33ef0-e2c0-410e-be82-6b19ef115718",
    iata: "XZ",
    airlineName: "Aeroitalia Regional",
    imgUrl: `${baseUrl}/XZ.png`,
  },
  {
    id: "f1ec158c-337c-4139-a510-0129f7441140",
    iata: "AR",
    airlineName: "Aerolineas Argentinas",
    imgUrl: `${baseUrl}/AR.png`,
  },
  {
    id: "27a64d0f-4cc4-460b-ac83-6fee3e9c5e0c",
    iata: "S0",
    airlineName: "Aerolineas Sosa",
    imgUrl: `${baseUrl}/S0.png`,
  },
  {
    id: "0b04ae69-a747-4b40-a8aa-011a639365ee",
    iata: "ES",
    airlineName: "Aerolíneas Estelar",
    imgUrl: `${baseUrl}/ES.png`,
  },
  {
    id: "6480c03f-712c-42cf-b928-eae2479ad687",
    iata: "AM",
    airlineName: "Aeromexico",
    imgUrl: `${baseUrl}/AM.png`,
  },
  {
    id: "d58df12e-cef4-42aa-ac62-b0de08b94c7d",
    iata: "5D",
    airlineName: "Aeromexico Connect",
    imgUrl: `${baseUrl}/5D.png`,
  },
  {
    id: "48142e21-954a-49c5-b2ea-91fc1d2ec614",
    iata: "6G",
    airlineName: "Aeroregional",
    imgUrl: `${baseUrl}/6G.png`,
  },
  {
    id: "2f391f97-efc9-433b-95cc-b6371055da81",
    iata: "V5",
    airlineName: "Aerovias DAP",
    imgUrl: `${baseUrl}/V5.png`,
  },
  {
    id: "3b663391-f653-4400-bbea-30c3357534e5",
    iata: "AW",
    airlineName: "Africa World Airlines",
    imgUrl: `${baseUrl}/AW.png`,
  },
  {
    id: "7a6ba6c2-0353-4e02-985a-1aa9516148e3",
    iata: "J7",
    airlineName: "Afrijet",
    imgUrl: `${baseUrl}/J7.png`,
  },
  {
    id: "67388e42-f555-47a8-98f4-88862d0e6c78",
    iata: "8U",
    airlineName: "Afriqiyah Airways",
    imgUrl: `${baseUrl}/8U.png`,
  },
  {
    id: "178af24c-0ba6-40f1-9bb0-741576e04bc2",
    iata: "ZB",
    airlineName: "Air Albania",
    imgUrl: `${baseUrl}/ZB.png`,
  },
  {
    id: "7ae8ecc7-f9f2-4578-849b-750595434e22",
    iata: "AH",
    airlineName: "Air Algerie",
    imgUrl: `${baseUrl}/AH.png`,
  },
  {
    id: "ccf0f4b7-1afd-48a3-81ed-100c52e77748",
    iata: "6K",
    airlineName: "Air Anka",
    imgUrl: `${baseUrl}/6K.png`,
  },
  {
    id: "fdf05167-88cf-4244-a12c-54705a290bc4",
    iata: "G9",
    airlineName: "Air Arabia",
    imgUrl: `${baseUrl}/G9.png`,
  },
  {
    id: "0fd44fef-42e5-4ee8-b971-e5d736156425",
    iata: "3L",
    airlineName: "Air Arabia Abu Dhabi",
    imgUrl: `${baseUrl}/3L.png`,
  },
  {
    id: "e238fbac-ea55-4739-a62a-6d136912ef87",
    iata: "E5",
    airlineName: "Air Arabia Egypt",
    imgUrl: `${baseUrl}/E5.png`,
  },
  {
    id: "fd3d8757-36e6-459c-a011-283a29d953a0",
    iata: "3O",
    airlineName: "Air Arabia Maroc",
    imgUrl: `${baseUrl}/3O.png`,
  },
  {
    id: "1db7c64e-81c2-4fdb-83ba-ef8558eb5f0d",
    iata: "KC",
    airlineName: "Air Astana",
    imgUrl: `${baseUrl}/KC.png`,
  },
  {
    id: "0a29e20f-3bc8-4909-939f-4703055838a9",
    iata: "2A",
    airlineName: "Air Astra",
    imgUrl: `${baseUrl}/2A.png`,
  },
  {
    id: "cb24de7e-0ba4-4606-b486-a5475d2df636",
    iata: "UU",
    airlineName: "Air Austral",
    imgUrl: `${baseUrl}/UU.png`,
  },
  {
    id: "bed4dd2a-ea8c-4599-9e4e-8a6e9f9fc6e3",
    iata: "KF",
    airlineName: "Air Belgium",
    imgUrl: `${baseUrl}/KF.png`,
  },
  {
    id: "ecc46400-dda3-4acc-b7e5-1fbd29d2573c",
    iata: "BP",
    airlineName: "Air Botswana",
    imgUrl: `${baseUrl}/BP.png`,
  },
  {
    id: "54899689-c7dd-4f86-b2a5-1fd5e07f59a9",
    iata: "2J",
    airlineName: "Air Burkina",
    imgUrl: `${baseUrl}/2J.png`,
  },
  {
    id: "2842e5b9-70fb-47ed-9bca-e3f34d9111bb",
    iata: "BX",
    airlineName: "Air Busan",
    imgUrl: `${baseUrl}/BX.png`,
  },
  {
    id: "f0caf4e6-6328-40ce-a2c3-c0167598a9ce",
    iata: "SM",
    airlineName: "Air Cairo",
    imgUrl: `${baseUrl}/SM.png`,
  },
  {
    id: "c84a490c-b8e9-43fe-bc45-760146f842e0",
    iata: "TY",
    airlineName: "Air Caledonie",
    imgUrl: `${baseUrl}/TY.png`,
  },
  {
    id: "b9e888f8-e8b2-43cb-9010-568df2e3044e",
    iata: "AC",
    airlineName: "Air Canada",
    imgUrl: `${baseUrl}/AC.png`,
  },
  {
    id: "049a2caf-79ba-440a-8349-386ac665e8d4",
    iata: "AC",
    airlineName: "Air Canada Express",
    imgUrl: `${baseUrl}/AC.png`,
  },
  {
    id: "7b5fe4a8-b404-45a6-8cbd-8022593fdbfc",
    iata: "RV",
    airlineName: "Air Canada Rouge",
    imgUrl: `${baseUrl}/RV.png`,
  },
  {
    id: "a937a03d-e6d0-48a8-9027-7705229697e3",
    iata: "TX",
    airlineName: "Air Caraibes",
    imgUrl: `${baseUrl}/TX.png`,
  },
  {
    id: "cf24a0c0-4d5b-4878-bc8a-c1970efacd02",
    iata: "Y2",
    airlineName: "Air Century",
    imgUrl: `${baseUrl}/Y2.png`,
  },
  {
    id: "18963a9a-2e48-431d-b69c-5b299eb5713e",
    iata: "9H",
    airlineName: "Air Changan",
    imgUrl: `${baseUrl}/9H.png`,
  },
  {
    id: "bc84f205-1e22-4d80-abef-82e50481d6f0",
    iata: "3C",
    airlineName: "Air Chathams",
    imgUrl: `${baseUrl}/3C.png`,
  },
  {
    id: "8ddebd22-a6f6-4cb2-9d11-41f364f6f99f",
    iata: "CA",
    airlineName: "Air China",
    imgUrl: `${baseUrl}/CA.png`,
  },
  {
    id: "209d1a4d-7bc2-4fb3-9170-039549a9f67a",
    iata: "XK",
    airlineName: "Air Corsica",
    imgUrl: `${baseUrl}/XK.png`,
  },
  {
    id: "f29d2680-fb92-42d1-ab0c-e1080ef8e8a0",
    iata: "HF",
    airlineName: "Air Cote D'Ivoire",
    imgUrl: `${baseUrl}/HF.png`,
  },
  {
    id: "68860d91-164e-4a47-88b7-52be1d00b168",
    iata: "YN",
    airlineName: "Air Creebec",
    imgUrl: `${baseUrl}/YN.png`,
  },
  {
    id: "a4800e45-20d8-497e-8ea8-83807525e1a0",
    iata: "RM",
    airlineName: "Air Dilijans",
    imgUrl: `${baseUrl}/RM.png`,
  },
  {
    id: "9f4d1cb9-5284-4bab-88c3-ac5bac654729",
    iata: "DJ",
    airlineName: "Air Djibouti",
    imgUrl: `${baseUrl}/DJ.png`,
  },
  {
    id: "22860df7-0b88-4acf-b56b-16f6f9c61757",
    iata: "HD",
    airlineName: "Airdo",
    imgUrl: `${baseUrl}/HD.png`,
  },
  {
    id: "39e60eb8-ce85-46d3-a99f-89793ad82d83",
    iata: "UX",
    airlineName: "Air Europa",
    imgUrl: `${baseUrl}/UX.png`,
  },
  {
    id: "f526f419-3f1f-499b-9f60-aca007b5605a",
    iata: "X5",
    airlineName: "Air Europa Express",
    imgUrl: `${baseUrl}/X5.png`,
  },
  {
    id: "8249d92c-24af-4491-b8bc-175d761b81d5",
    iata: "F4",
    airlineName: "Air Flamenco",
    imgUrl: `${baseUrl}/F4.png`,
  },
  {
    id: "85f6cfb3-648f-43bd-a64c-02434b5ef5ca",
    iata: "AF",
    airlineName: "Air France",
    imgUrl: `${baseUrl}/AF.png`,
  },
  {
    id: "ce27df39-04af-4c18-9925-1e01dcbb6dbc",
    iata: "A5",
    airlineName: "Air France Hop",
    imgUrl: `${baseUrl}/A5.png`,
  },
  {
    id: "397fbc11-d73c-4ae7-9c69-47607ddde8dd",
    iata: "GL",
    airlineName: "Air Greenland",
    imgUrl: `${baseUrl}/GL.png`,
  },
  {
    id: "1d359b1d-2b0b-4977-8606-94bb72664e2e",
    iata: "GT",
    airlineName: "Air Guilin",
    imgUrl: `${baseUrl}/GT.png`,
  },
  {
    id: "864489ad-2c5e-4d6f-bf6f-8c16c2d66cfb",
    iata: "HT",
    airlineName: "Air Horizont",
    imgUrl: `${baseUrl}/HT.png`,
  },
  {
    id: "3ada343a-1e89-409e-8ee5-08ad447f30bf",
    iata: "AI",
    airlineName: "Air India",
    imgUrl: `${baseUrl}/AI.png`,
  },
  {
    id: "3f68fb2a-6d69-4a47-bad6-18231e26cb73",
    iata: "IX",
    airlineName: "Air India Express",
    imgUrl: `${baseUrl}/IX.png`,
  },
  {
    id: "dbff92fb-018f-4972-951e-114f7ee9fdd5",
    iata: "3H",
    airlineName: "Air Inuit",
    imgUrl: `${baseUrl}/3H.png`,
  },
  {
    id: "b3e60bd3-cf3b-4f93-9de4-ad5e6aff9955",
    iata: "NQ",
    airlineName: "Air Japan",
    imgUrl: `${baseUrl}/NQ.png`,
  },
  {
    id: "4bf29e00-d06f-4107-a4fd-005c215efc51",
    iata: "AO",
    airlineName: "Air Juan",
    imgUrl: `${baseUrl}/AO.png`,
  },
  {
    id: "c6ef97d2-60bd-4402-80bb-407534648786",
    iata: "IK",
    airlineName: "Air Kiribati",
    imgUrl: `${baseUrl}/IK.png`,
  },
  {
    id: "0ded7fbc-ebc4-4bfd-8b41-681a3aa646d6",
    iata: "JS",
    airlineName: "Air Koryo",
    imgUrl: `${baseUrl}/JS.png`,
  },
  {
    id: "09cee77d-cff4-4a89-9a28-6a3740fe5711",
    iata: "DU",
    airlineName: "Air Liaison",
    imgUrl: `${baseUrl}/DU.png`,
  },
  {
    id: "e83d4052-6525-4a57-84ed-2e04eb89d536",
    iata: "4Z",
    airlineName: "AirLink",
    imgUrl: `${baseUrl}/4Z.png`,
  },
  {
    id: "e59fb0eb-dc1f-44ec-98fb-45a512c0fcbb",
    iata: "NX",
    airlineName: "Air Macau",
    imgUrl: `${baseUrl}/NX.png`,
  },
  {
    id: "1a5bd0b4-2b1c-4b1c-bd18-e77ab9a87f4d",
    iata: "ZM",
    airlineName: "Air Manas",
    imgUrl: `${baseUrl}/ZM.png`,
  },
  {
    id: "2b40a5f2-fd7d-4bf3-b7ae-5914b72e9c15",
    iata: "CW",
    airlineName: "Air Marshall Islands",
    imgUrl: `${baseUrl}/CW.png`,
  },
  {
    id: "d7f91b96-613c-479a-9339-de5de0edb10d",
    iata: "MK",
    airlineName: "Air Mauritius",
    imgUrl: `${baseUrl}/MK.png`,
  },
  {
    id: "1c727307-1af3-4cb4-ba7d-640325360939",
    iata: "MV",
    airlineName: "Air Mediterranean",
    imgUrl: `${baseUrl}/MV.png`,
  },
  {
    id: "5bc43ed1-63ec-4a84-ade6-d35741da0fea",
    iata: "4O",
    airlineName: "Air Montenegro",
    imgUrl: `${baseUrl}/4O.png`,
  },
  {
    id: "0f4b1189-462f-4cfa-a29b-8a7802dd253b",
    iata: "NZ",
    airlineName: "Air New Zealand",
    imgUrl: `${baseUrl}/NZ.png`,
  },
  {
    id: "d1fde9a7-eba0-41b7-bfca-cc0267263f9e",
    iata: "PX",
    airlineName: "Air Niugini",
    imgUrl: `${baseUrl}/PX.png`,
  },
  {
    id: "f9db3e89-a6f7-4023-ab34-6e5777004382",
    iata: "4N",
    airlineName: "Air North",
    imgUrl: `${baseUrl}/4N.png`,
  },
  {
    id: "7187e51c-1ded-4cf0-9821-4286159e671f",
    iata: "7P",
    airlineName: "Air Panama",
    imgUrl: `${baseUrl}/7P.png`,
  },
  {
    id: "1bf0609a-7494-408e-bc36-3e67de5d3e6f",
    iata: "P4",
    airlineName: "Air Peace",
    imgUrl: `${baseUrl}/P4.png`,
  },
  {
    id: "84cb75b4-8088-4cbd-83ed-893fedf1fc6e",
    iata: "YP",
    airlineName: "Air Premia",
    imgUrl: `${baseUrl}/YP.png`,
  },
  {
    id: "ef825995-e1fc-4291-ba05-7fb013ffacff",
    iata: "GZ",
    airlineName: "Air Rarotonga",
    imgUrl: `${baseUrl}/GZ.png`,
  },
  {
    id: "b0d1b23b-da98-4c2b-8959-5426d590f691",
    iata: "PJ",
    airlineName: "Air Saint Pierre",
    imgUrl: `${baseUrl}/PJ.png`,
  },
  {
    id: "ca42550e-e863-419d-820c-5a2dfbce4212",
    iata: "HC",
    airlineName: "Air Senegal",
    imgUrl: `${baseUrl}/HC.png`,
  },
  {
    id: "eacac3fb-6d2c-4f34-a8be-1f0c5f22cb7e",
    iata: "RS",
    airlineName: "Air Seoul",
    imgUrl: `${baseUrl}/RS.png`,
  },
  {
    id: "71c66626-25cf-4d61-95fb-5be0ccc7c7a9",
    iata: "JU",
    airlineName: "Air Serbia",
    imgUrl: `${baseUrl}/JU.png`,
  },
  {
    id: "d9a86240-5507-4923-9c5c-0d34fea4be7f",
    iata: "HM",
    airlineName: "Air Seychelles",
    imgUrl: `${baseUrl}/HM.png`,
  },
  {
    id: "6a59cca3-fc9b-44d2-ae3d-eb794a4cb9a8",
    iata: "PF",
    airlineName: "Air Sial",
    imgUrl: `${baseUrl}/PF.png`,
  },
  {
    id: "3a473802-7dc6-46a8-ace0-dbc0c33ad6f1",
    iata: "VT",
    airlineName: "Air Tahiti",
    imgUrl: `${baseUrl}/VT.png`,
  },
  {
    id: "f18b5a8e-0e51-4326-a083-338f37d82151",
    iata: "TN",
    airlineName: "Air Tahiti Nui",
    imgUrl: `${baseUrl}/TN.png`,
  },
  {
    id: "c890f186-6928-4118-8f9b-d0bdfb1684f0",
    iata: "TC",
    airlineName: "Air Tanzania",
    imgUrl: `${baseUrl}/TC.png`,
  },
  {
    id: "ce0f6ab4-12ed-49b4-8320-82fe2b794481",
    iata: "ST",
    airlineName: "Air Thanlwin",
    imgUrl: `${baseUrl}/ST.png`,
  },
  {
    id: "73319b55-42ad-426a-bcfd-f058df5d7a6c",
    iata: "6C",
    airlineName: "Air Timor",
    imgUrl: `${baseUrl}/6C.png`,
  },
  {
    id: "ea695aab-218b-4a55-bc9a-fccdafb268f1",
    iata: "8T",
    airlineName: "Air Tindi",
    imgUrl: `${baseUrl}/8T.png`,
  },
  {
    id: "631c13ea-a3cc-4f9c-8f60-f613c65a6373",
    iata: "TS",
    airlineName: "Air Transat",
    imgUrl: `${baseUrl}/TS.png`,
  },
  {
    id: "34e3c496-4c51-4000-97e1-fedddb138935",
    iata: "A6",
    airlineName: "Air Travel",
    imgUrl: `${baseUrl}/A6.png`,
  },
  {
    id: "4d838690-7332-4259-b176-8f26593a9917",
    iata: "NF",
    airlineName: "Air Vanuatu",
    imgUrl: `${baseUrl}/NF.png`,
  },
  {
    id: "7dcc1e64-0d02-4283-b2c3-8063b72743aa",
    iata: "ZW",
    airlineName: "Air Wisconsin",
    imgUrl: `${baseUrl}/ZW.png`,
  },
  {
    id: "195c4e91-3e7c-41b7-9df8-a0947725d0f3",
    iata: "UM",
    airlineName: "Air Zimbabwe",
    imgUrl: `${baseUrl}/UM.png`,
  },
  {
    id: "78799e08-436b-47be-8511-912f63f7a670",
    iata: "AK",
    airlineName: "AirAsia",
    imgUrl: `${baseUrl}/AK.png`,
  },
  {
    id: "2c55c497-00b5-4ef6-81f4-0f2dd4b3d008",
    iata: "D7",
    airlineName: "AirAsia X",
    imgUrl: `${baseUrl}/D7.png`,
  },
  {
    id: "5f0e8cac-74d9-422f-9a49-9d2be6369727",
    iata: "EN",
    airlineName: "Air Dolomiti",
    imgUrl: `${baseUrl}/EN.png`,
  },
  {
    id: "3ba1ae4b-68bd-4db0-9a43-6c6339bbadbd",
    iata: "ED",
    airlineName: "AirExplore",
    imgUrl: `${baseUrl}/ED.png`,
  },
  {
    id: "40966162-af0b-4165-9190-7962a0c2006a",
    iata: "P2",
    airlineName: "AirKenya",
    imgUrl: `${baseUrl}/P2.png`,
  },
  {
    id: "e614eba1-f623-4e15-908b-281a8d175c30",
    iata: "T6",
    airlineName: "AirSWIFT",
    imgUrl: `${baseUrl}/T6.png`,
  },
  {
    id: "312188ec-6eed-4034-b0cb-26f588225da2",
    iata: "SB",
    airlineName: "Air Calin",
    imgUrl: `${baseUrl}/SB.png`,
  },
  {
    id: "9fb43d1a-f399-4cf1-b945-4be307fe835f",
    iata: "3N",
    airlineName: "Airline Urga",
    imgUrl: `${baseUrl}/3N.png`,
  },
  {
    id: "a0cbbd49-a69e-4bd3-9123-ad6738749a28",
    iata: "TL",
    airlineName: "Airnorth",
    imgUrl: `${baseUrl}/TL.png`,
  },
  {
    id: "b7dc3933-c61f-4c9a-a8cf-64dea27173cf",
    iata: "QP",
    airlineName: "Akasa Air",
    imgUrl: `${baseUrl}/QP.png`,
  },
  {
    id: "660dd8d4-9930-4bff-9bd5-5ad8dcb659d8",
    iata: "6L",
    airlineName: "Aklak Air",
    imgUrl: `${baseUrl}/6L.png`,
  },
  {
    id: "9077f1ca-670a-4c29-bc10-f8befba90808",
    iata: "AS",
    airlineName: "Alaska Airlines",
    imgUrl: `${baseUrl}/AS.png`,
  },
  {
    id: "da5d4185-bc8d-4915-a750-5c5a89cba974",
    iata: "J5",
    airlineName: "Alaska Seaplanes",
    imgUrl: `${baseUrl}/J5.png`,
  },
  {
    id: "e0b8c46f-6b53-4a14-a0de-b83cd7b47301",
    iata: "AP",
    airlineName: "Alba Star",
    imgUrl: `${baseUrl}/AP.png`,
  },
  {
    id: "61f431e7-8b73-4a2c-b11b-de8d936a8f31",
    iata: "GO",
    airlineName: "Albatros Airlines",
    imgUrl: `${baseUrl}/GO.png`,
  },
  {
    id: "d9703c62-142e-4cd8-ad5c-254a21501aa3",
    iata: "DQ",
    airlineName: "Alexandria Airlines",
    imgUrl: `${baseUrl}/DQ.png`,
  },
  {
    id: "cfcfb880-d363-4440-b8aa-cbf1a59df6de",
    iata: "NH",
    airlineName: "All Nippon Airways",
    imgUrl: `${baseUrl}/NH.png`,
  },
  {
    id: "949a5597-6e60-4409-b303-e881799abcf9",
    iata: "G4",
    airlineName: "Allegiant Air",
    imgUrl: `${baseUrl}/G4.png`,
  },
  {
    id: "211ddcf0-23c7-47d1-ba24-a31fe046f3ea",
    iata: "9I",
    airlineName: "Alliance Air",
    imgUrl: `${baseUrl}/9I.png`,
  },
  {
    id: "9fd3bd02-7d9c-4952-a2c0-d07ef40ad3d8",
    iata: "QQ",
    airlineName: "Alliance Airlines",
    imgUrl: `${baseUrl}/QQ.png`,
  },
  {
    id: "e02126c3-093f-45e7-aa3b-628ae7c03b29",
    iata: "MZ",
    airlineName: "Amakusa Airlines",
    imgUrl: `${baseUrl}/MZ.png`,
  },
  {
    id: "40135322-b00b-450c-8f4b-60f81a6774ae",
    iata: "8R",
    airlineName: "Amelia",
    imgUrl: `${baseUrl}/8R.png`,
  },
  {
    id: "ea8909d2-48ca-405a-86e8-0dec1c81954e",
    iata: "NL",
    airlineName: "Amelia International",
    imgUrl: `${baseUrl}/NL.png`,
  },
  {
    id: "5d4d3c89-a860-467d-9bc0-1f9d86cab72f",
    iata: "AA",
    airlineName: "American Airlines",
    imgUrl: `${baseUrl}/AA.png`,
  },
  {
    id: "65f4d46f-2997-4d07-b14a-a139af88af78",
    iata: "O4",
    airlineName: "Andes Lineas Aereas",
    imgUrl: `${baseUrl}/O4.png`,
  },
  {
    id: "ef0adf09-7597-4b9e-8ece-99b75e7b2d67",
    iata: "Q3",
    airlineName: "Anguilla Air Services",
    imgUrl: `${baseUrl}/Q3.png`,
  },
  {
    id: "b98f09b1-de81-4d21-b202-6631f1d79ac6",
    iata: "A2",
    airlineName: "Anima Wings",
    imgUrl: `${baseUrl}/A2.png`,
  },
  {
    id: "8cd758a5-1814-4369-8dd5-1f39d4e4f782",
    iata: "DM",
    airlineName: "Arajet",
    imgUrl: `${baseUrl}/DM.png`,
  },
  {
    id: "1c14f22f-bbbe-4df3-88c6-75058a12c7fe",
    iata: "FG",
    airlineName: "Ariana Afghan Airlines",
    imgUrl: `${baseUrl}/FG.png`,
  },
  {
    id: "3cd30168-454f-49b5-bea0-9639dfceb1b8",
    iata: "W3",
    airlineName: "Arik Air",
    imgUrl: `${baseUrl}/W3.png`,
  },
  {
    id: "aad4f484-9df4-460a-973e-32de2fe847c0",
    iata: "IZ",
    airlineName: "Arkia",
    imgUrl: `${baseUrl}/IZ.png`,
  },
  {
    id: "0e07db94-56dd-40a2-a6de-7f49d5cabc86",
    iata: "6A",
    airlineName: "Armenia Airways",
    imgUrl: `${baseUrl}/6A.png`,
  },
  {
    id: "182d0a72-7216-45df-b2a7-e9173bf677de",
    iata: "AG",
    airlineName: "Aruba Airlines",
    imgUrl: `${baseUrl}/AG.png`,
  },
  {
    id: "57070cf4-1187-422e-a18f-e01b8135d686",
    iata: "OZ",
    airlineName: "Asiana Airlines",
    imgUrl: `${baseUrl}/OZ.png`,
  },
  {
    id: "cd1a067e-ddd0-4494-9348-870aec6bd47c",
    iata: "RC",
    airlineName: "Atlantic Airways",
    imgUrl: `${baseUrl}/RC.png`,
  },
  {
    id: "2beaf5fa-a0bf-45aa-bed4-ab9374f58918",
    iata: "UI",
    airlineName: "Auric Air",
    imgUrl: `${baseUrl}/UI.png`,
  },
  {
    id: "c3c8e2ce-0e55-4b67-be14-d4ce554c021e",
    iata: "GR",
    airlineName: "Aurigny",
    imgUrl: `${baseUrl}/GR.png`,
  },
  {
    id: "21e2e571-0736-4b6c-afd1-e1881752ea54",
    iata: "HZ",
    airlineName: "Aurora",
    imgUrl: `${baseUrl}/HZ.png`,
  },
  {
    id: "2565556c-620d-4f38-ba15-6f9dacf564bc",
    iata: "OS",
    airlineName: "Austrian Airlines",
    imgUrl: `${baseUrl}/OS.png`,
  },
  {
    id: "01640b35-9503-4c8a-a659-36024421711e",
    iata: "XP",
    airlineName: "Avelo Airlines",
    imgUrl: `${baseUrl}/XP.png`,
  },
  {
    id: "c1315708-d03a-4588-9411-74c84732a015",
    iata: "YK",
    airlineName: "Avia Traffic Company",
    imgUrl: `${baseUrl}/YK.png`,
  },
  {
    id: "4f43b108-0b4c-42cb-a9c4-99d0c0a05451",
    iata: "X9",
    airlineName: "Avion Express Lithuania",
    imgUrl: `${baseUrl}/X9.png`,
  },
  {
    id: "f833b1d5-8507-4413-87c7-5f969e1fd16d",
    iata: "9V",
    airlineName: "Avior Airlines",
    imgUrl: `${baseUrl}/9V.png`,
  },
  {
    id: "fc7c8ee3-ba90-46ed-864e-ddf0444d875a",
    iata: "J2",
    airlineName: "Azerbaijan Airlines",
    imgUrl: `${baseUrl}/J2.png`,
  },
  {
    id: "8aa9d942-555f-42ab-8be3-540f8a3e8d4e",
    iata: "A4",
    airlineName: "Azimuth",
    imgUrl: `${baseUrl}/A4.png`,
  },
  {
    id: "1c7420eb-8e4b-48e3-9c94-f75aabadb71d",
    iata: "S4",
    airlineName: "Azores Airlines",
    imgUrl: `${baseUrl}/S4.png`,
  },
  {
    id: "023632a2-3f74-424d-b64c-4658185e2e8b",
    iata: "AJ",
    airlineName: "Aztec Airways",
    imgUrl: `${baseUrl}/AJ.png`,
  },
  {
    id: "3dca8591-8f23-4692-be8c-c14072d2f8d6",
    iata: "AD",
    airlineName: "Azul Airlines",
    imgUrl: `${baseUrl}/AD.png`,
  },
  {
    id: "103d29b1-75be-4d4d-bb4d-44a402c5a159",
    iata: "2F",
    airlineName: "Azul Conecta",
    imgUrl: `${baseUrl}/2F.png`,
  },
  {
    id: "f00022c7-3442-40a3-96fd-2fc910f3b05f",
    iata: "BT",
    airlineName: "airBaltic",
    imgUrl: `${baseUrl}/BT.png`,
  },
  {
    id: "dfc9603f-1d64-40c5-844c-597592eaf80d",
    iata: "PA",
    airlineName: "airblue",
    imgUrl: `${baseUrl}/PA.png`,
  },
  {
    id: "6ecc106d-4fc8-4c8e-9c3f-88a813a5f78b",
    iata: "GU",
    airlineName: "avianca Guatemala",
    imgUrl: `${baseUrl}/GU.png`,
  },
  {
    id: "53a61e4c-7d25-4f4d-8aac-423d19046262",
    iata: "AV",
    airlineName: "Avianca Airlines",
    imgUrl: `${baseUrl}/AV.png`,
  },
  {
    id: "0d520b0a-fa8c-4bd7-92db-ad9a12e6d0fa",
    iata: "LR",
    airlineName: "avianca airlines Costa Rica",
    imgUrl: `${baseUrl}/LR.png`,
  },
  {
    id: "f585f253-77b2-4632-ac29-966abbfe2749",
    iata: "2K",
    airlineName: "avianca airlines Ecuador",
    imgUrl: `${baseUrl}/2K.png`,
  },
  {
    id: "4faba5e6-ef9c-4dc6-8f25-73ce570ff75f",
    iata: "CJ",
    airlineName: "BA Cityflyer",
    imgUrl: `${baseUrl}/CJ.png`,
  },
  {
    id: "be06912b-c5d5-44fc-a324-35a05c95e4be",
    iata: "A0",
    airlineName: "BA Euroflyer",
    imgUrl: `${baseUrl}/A0.png`,
  },
  {
    id: "beb41e68-6acd-4638-bd86-ed119c4e8ac1",
    iata: "8H",
    airlineName: "BH Air",
    imgUrl: `${baseUrl}/8H.png`,
  },
  {
    id: "777ce607-21b4-4e38-bcf3-2c0c15497148",
    iata: "OB",
    airlineName: "BOA Boliviana de Aviación",
    imgUrl: `${baseUrl}/OB.png`,
  },
  {
    id: "3e6fc3ee-e706-40b1-afde-39206fcfa921",
    iata: "TF",
    airlineName: "BRA - Braathens Regional Airways",
    imgUrl: `${baseUrl}/TF.png`,
  },
  {
    id: "d9d2cdda-de98-47e3-a6b4-b07c5e88e0b1",
    iata: "J4",
    airlineName: "Badr Airlines",
    imgUrl: `${baseUrl}/J4.png`,
  },
  {
    id: "ed13d4ed-bf7b-42d6-a485-c942577cb120",
    iata: "UP",
    airlineName: "Bahamasair",
    imgUrl: `${baseUrl}/UP.png`,
  },
  {
    id: "fd4eca82-7de0-4775-b1c8-0ddada3662f5",
    iata: "QH",
    airlineName: "Bamboo Airways",
    imgUrl: `${baseUrl}/QH.png`,
  },
  {
    id: "659c8473-7e70-43b7-8d07-8914e61d3101",
    iata: "PG",
    airlineName: "Bangkok Airways",
    imgUrl: `${baseUrl}/PG.png`,
  },
  {
    id: "8418d4f8-38cd-4e9b-9333-6d5d69ee5ec2",
    iata: "ID",
    airlineName: "Batik Air",
    imgUrl: `${baseUrl}/ID.png`,
  },
  {
    id: "31a00ced-9913-42dd-ba08-8f2f64f21e56",
    iata: "OD",
    airlineName: "Batik Air Malaysia",
    imgUrl: `${baseUrl}/OD.png`,
  },
  {
    id: "1710eabc-a3bc-43d3-a1a4-a16a71e5b14a",
    iata: "JV",
    airlineName: "Bearskin Airlines",
    imgUrl: `${baseUrl}/JV.png`,
  },
  {
    id: "6bde8a44-c121-4476-a8f6-feae2b93e48e",
    iata: "JD",
    airlineName: "Beijing Capital Airlines",
    imgUrl: `${baseUrl}/JD.png`,
  },
  {
    id: "4d6d3a79-4e12-4ff2-bf23-1bb209c1203a",
    iata: "B2",
    airlineName: "Belavia",
    imgUrl: `${baseUrl}/B2.png`,
  },
  {
    id: "7107d4b3-7570-4b05-afd5-a8aeafaa7aaf",
    iata: "8E",
    airlineName: "Bering Air",
    imgUrl: `${baseUrl}/8E.png`,
  },
  {
    id: "1db696f1-073c-4e19-8137-2564269843f3",
    iata: "NB",
    airlineName: "Berniq Airways",
    imgUrl: `${baseUrl}/NB.png`,
  },
  {
    id: "49fd8908-2c30-425b-815b-910dfe8f4895",
    iata: "B3",
    airlineName: "Bhutan Airlines",
    imgUrl: `${baseUrl}/B3.png`,
  },
  {
    id: "17b91ac3-c6c1-4121-ac14-4e47da8bb0ee",
    iata: "BG",
    airlineName: "Biman Bangladesh Airlines",
    imgUrl: `${baseUrl}/BG.png`,
  },
  {
    id: "6d06e449-2a35-4b42-b8f8-bc5f92edf62d",
    iata: "NT",
    airlineName: "Binter Canarias",
    imgUrl: `${baseUrl}/NT.png`,
  },
  {
    id: "3f972491-8674-478b-9c47-936d1951ed84",
    iata: "BZ",
    airlineName: "Blue Bird Airways",
    imgUrl: `${baseUrl}/BZ.png`,
  },
  {
    id: "d625177c-657c-4168-aa81-143f60261be1",
    iata: "SI",
    airlineName: "Blue Islands",
    imgUrl: `${baseUrl}/SI.png`,
  },
  {
    id: "d7bde35d-ac09-4855-8eca-c12a91e3c3df",
    iata: "4B",
    airlineName: "Boutique Air",
    imgUrl: `${baseUrl}/4B.png`,
  },
  {
    id: "8603d8a7-3c9a-4a27-82bb-3b10e4a9db62",
    iata: "MX",
    airlineName: "Breeze Airways",
    imgUrl: `${baseUrl}/MX.png`,
  },
  {
    id: "d8957cd3-211d-4b62-8219-87d7dd32ebde",
    iata: "BA",
    airlineName: "British Airways",
    imgUrl: `${baseUrl}/BA.png`,
  },
  {
    id: "3dfb349a-2d6f-4187-91ff-a47b9d4eef0b",
    iata: "SN",
    airlineName: "Brussels Airlines",
    imgUrl: `${baseUrl}/SN.png`,
  },
  {
    id: "2edbad6f-96f6-4d2c-8e0e-430d50dbfb19",
    iata: "U4",
    airlineName: "Buddha Air",
    imgUrl: `${baseUrl}/U4.png`,
  },
  {
    id: "ac36517f-9bef-4e99-8a63-c41be56ea473",
    iata: "FB",
    airlineName: "Bulgaria Air",
    imgUrl: `${baseUrl}/FB.png`,
  },
  {
    id: "fa0935d9-01c2-4cb3-b1bc-df4e1bfe597b",
    iata: "UZ",
    airlineName: "Buraq Air",
    imgUrl: `${baseUrl}/UZ.png`,
  },
  {
    id: "57b479ed-9f3c-45e9-865e-85283456edab",
    iata: "C2",
    airlineName: "CEIBA Intercontinental",
    imgUrl: `${baseUrl}/C2.png`,
  },
  {
    id: "4f95c9d1-1fd6-4ef6-941f-395af317a929",
    iata: "GM",
    airlineName: "Chair Airlines",
    imgUrl: `${baseUrl}/GM.png`,
  },
  {
    id: "9f0028f3-37f7-4493-b6e3-cfb022499da3",
    iata: "H5",
    airlineName: "CM Airlines",
    imgUrl: `${baseUrl}/H5.png`,
  },
  {
    id: "d7d3e97b-6896-466d-a2c7-03d9907999f5",
    iata: "VR",
    airlineName: "Cabo Verde Airlines",
    imgUrl: `${baseUrl}/VR.png`,
  },
  {
    id: "4849f68e-a582-4ce1-8aaf-35e60304887b",
    iata: "9Q",
    airlineName: "Caicos Express Airways",
    imgUrl: `${baseUrl}/9Q.png`,
  },
  {
    id: "742da056-a835-4027-872c-aed94ac3b9e3",
    iata: "MO",
    airlineName: "Calm Air",
    imgUrl: `${baseUrl}/MO.png`,
  },
  {
    id: "6316a90d-b74a-49e4-add7-9cb746f3cfe0",
    iata: "QC",
    airlineName: "Camair-Co",
    imgUrl: `${baseUrl}/QC.png`,
  },
  {
    id: "061dbd94-2a0a-4faa-9675-bb0bf2212fa5",
    iata: "KR",
    airlineName: "Cambodia Airways",
    imgUrl: `${baseUrl}/KR.png`,
  },
  {
    id: "4d8af8a7-19b9-4a7c-9e39-cc308e33a8d6",
    iata: "K6",
    airlineName: "Cambodia Angkor Air",
    imgUrl: `${baseUrl}/K6.png`,
  },
  {
    id: "be762792-73ec-4e6f-a87b-39dadc6975ca",
    iata: "5T",
    airlineName: "Canadian North",
    imgUrl: `${baseUrl}/5T.png`,
  },
  {
    id: "e0ff6051-ea0c-4e26-b662-5ac3f2860963",
    iata: "PM",
    airlineName: "Canaryfly",
    imgUrl: `${baseUrl}/PM.png`,
  },
  {
    id: "1f79703c-4aee-406d-8ed4-127554fc216c",
    iata: "9K",
    airlineName: "Cape Air",
    imgUrl: `${baseUrl}/9K.png`,
  },
  {
    id: "c305cb51-c260-40db-98ae-9de68f8968ff",
    iata: "BW",
    airlineName: "Caribbean Airlines",
    imgUrl: `${baseUrl}/BW.png`,
  },
  {
    id: "fd3f264a-e8c4-446c-8acc-0e9d75e9d323",
    iata: "V3",
    airlineName: "Carpatair",
    imgUrl: `${baseUrl}/V3.png`,
  },
  {
    id: "edb48470-befe-4f36-b153-8ddf6225614c",
    iata: "CX",
    airlineName: "Cathay Pacific",
    imgUrl: `${baseUrl}/CX.png`,
  },
  {
    id: "ef8946d0-1f60-4246-baad-ac1bbdbd2f4e",
    iata: "KX",
    airlineName: "Cayman Airways",
    imgUrl: `${baseUrl}/KX.png`,
  },
  {
    id: "0e2d4096-5e72-4298-ad98-38a0828617de",
    iata: "DG",
    airlineName: "Cebgo",
    imgUrl: `${baseUrl}/DG.png`,
  },
  {
    id: "a043f601-8201-4a96-980c-bcc80606644c",
    iata: "5J",
    airlineName: "Cebu Pacific",
    imgUrl: `${baseUrl}/5J.png`,
  },
  {
    id: "973b9e62-2e19-46ec-ab7c-5d0f8331718f",
    iata: "5Z",
    airlineName: "CemAir",
    imgUrl: `${baseUrl}/5Z.png`,
  },
  {
    id: "d65d87c2-2740-4c78-afba-e8968288843c",
    iata: "9M",
    airlineName: "Central Mountain Air",
    imgUrl: `${baseUrl}/9M.png`,
  },
  {
    id: "c9c96ebc-771d-4057-874b-ba595de7cfc5",
    iata: "CE",
    airlineName: "Chalair Aviation",
    imgUrl: `${baseUrl}/CE.png`,
  },
  {
    id: "cc2580e8-0c7a-4b6e-8930-ccda58fa4b92",
    iata: "EU",
    airlineName: "Chengdu Airlines",
    imgUrl: `${baseUrl}/EU.png`,
  },
  {
    id: "3961fdd9-8130-4025-8d51-f41d0335d9e1",
    iata: "CI",
    airlineName: "China Airlines",
    imgUrl: `${baseUrl}/CI.png`,
  },
  {
    id: "1aa97de7-b4e8-4a9f-9039-a8c18e68e70f",
    iata: "MU",
    airlineName: "China Eastern",
    imgUrl: `${baseUrl}/MU.png`,
  },
  {
    id: "de667aa4-9596-4c6c-b5cd-82f6879b2208",
    iata: "CZ",
    airlineName: "China Southern Airlines",
    imgUrl: `${baseUrl}/CZ.png`,
  },
  {
    id: "b84c4de3-ee80-48dc-9855-88686dbe189b",
    iata: "KN",
    airlineName: "China United Airlines",
    imgUrl: `${baseUrl}/KN.png`,
  },
  {
    id: "59c8173e-cfff-4a23-9078-db118615870a",
    iata: "OQ",
    airlineName: "Chongqing Airlines",
    imgUrl: `${baseUrl}/OQ.png`,
  },
  {
    id: "b532f096-6672-4cba-8180-ed68177a55a5",
    iata: "QG",
    airlineName: "Citilink",
    imgUrl: `${baseUrl}/QG.png`,
  },
  {
    id: "74735624-ad1a-43fd-ac4c-b404007fded6",
    iata: "VE",
    airlineName: "Clic",
    imgUrl: `${baseUrl}/VE.png`,
  },
  {
    id: "f8cc25e0-eb42-40ec-8ee1-d3e9727e4812",
    iata: "CQ",
    airlineName: "Coastal Aviation",
    imgUrl: `${baseUrl}/CQ.png`,
  },
  {
    id: "db807c69-9c4b-45cd-81f0-93ec4da2f578",
    iata: "GY",
    airlineName: "Colorful Guizhou Airlines",
    imgUrl: `${baseUrl}/GY.png`,
  },
  {
    id: "73a0e992-af00-4469-9b59-ec23cd7af2a7",
    iata: "DE",
    airlineName: "Condor",
    imgUrl: `${baseUrl}/DE.png`,
  },
  {
    id: "3fab3a50-416d-4d12-a2f9-66f7e7d6af4a",
    iata: "8Z",
    airlineName: "Congo Airways",
    imgUrl: `${baseUrl}/8Z.png`,
  },
  {
    id: "99fa9983-7d9f-4279-98a0-a73ecbc1d9c7",
    iata: "V0",
    airlineName: "Conviasa",
    imgUrl: `${baseUrl}/V0.png`,
  },
  {
    id: "81c603e4-5f00-43c5-bd3f-57dbe9654dcb",
    iata: "CM",
    airlineName: "Copa Airlines",
    imgUrl: `${baseUrl}/CM.png`,
  },
  {
    id: "6f3715d3-a3b8-4c24-98f4-00aed9e30778",
    iata: "XC",
    airlineName: "Corendon Airlines",
    imgUrl: `${baseUrl}/XC.png`,
  },
  {
    id: "7d8ca828-7147-454c-82c6-ea6fff07a3b0",
    iata: "XR",
    airlineName: "Corendon Airlines Europe",
    imgUrl: `${baseUrl}/XR.png`,
  },
  {
    id: "723400b5-7524-4fb5-bd55-6b5ac9bfeb80",
    iata: "CD",
    airlineName: "Corendon Dutch Airlines",
    imgUrl: `${baseUrl}/CD.png`,
  },
  {
    id: "d42edc9c-6856-4aa1-9a45-d6580a329fb5",
    iata: "SS",
    airlineName: "Corsair International",
    imgUrl: `${baseUrl}/SS.png`,
  },
  {
    id: "740bf841-71d7-4d72-94b8-a17059d59024",
    iata: "OU",
    airlineName: "Croatia Airlines",
    imgUrl: `${baseUrl}/OU.png`,
  },
  {
    id: "4d9b9b6b-95f8-4eaf-9742-ab5110a13137",
    iata: "C8",
    airlineName: "Cronos Airlines",
    imgUrl: `${baseUrl}/C8.png`,
  },
  {
    id: "415f0cea-c40e-4f09-acc5-8f64e28255be",
    iata: "CU",
    airlineName: "Cubana",
    imgUrl: `${baseUrl}/CU.png`,
  },
  {
    id: "89877200-e72a-46c1-ba18-3a29f8b55edc",
    iata: "CY",
    airlineName: "Cyprus Airways",
    imgUrl: `${baseUrl}/CY.png`,
  },
  {
    id: "5c7bfccd-3daa-4f78-9768-b5e8ab9593c0",
    iata: "D3",
    airlineName: "Daallo Airlines",
    imgUrl: `${baseUrl}/D3.png`,
  },
  {
    id: "b20df32d-8e2e-404d-bae1-1b169af877c7",
    iata: "D9",
    airlineName: "Daallo Airlines (Somalia)",
    imgUrl: `${baseUrl}/D9.png`,
  },
  {
    id: "a842d7fb-ea39-4d7b-85f8-2de7fe6b4b2a",
    iata: "DL",
    airlineName: "Delta Airlines",
    imgUrl: `${baseUrl}/DL.png`,
  },
  {
    id: "08744ba0-0f36-4dfe-a761-26b128f3a19e",
    iata: "4Y",
    airlineName: "Discover Airlines",
    imgUrl: `${baseUrl}/4Y.png`,
  },
  {
    id: "489313b1-eca6-4ef0-8b2f-8d81922defa5",
    iata: "3R",
    airlineName: "Divi Divi Air",
    imgUrl: `${baseUrl}/3R.png`,
  },
  {
    id: "214adef0-4f2b-4c36-8185-8e4fe8629c4a",
    iata: "DZ",
    airlineName: "Donghai Airlines",
    imgUrl: `${baseUrl}/DZ.png`,
  },
  {
    id: "4ea5ca01-4cc0-4ffe-a321-432acdcf9daa",
    iata: "KB",
    airlineName: "Drukair",
    imgUrl: `${baseUrl}/KB.png`,
  },
  {
    id: "5b56c47d-5795-46b0-9540-c006a9b39da8",
    iata: "LY",
    airlineName: "EL AL",
    imgUrl: `${baseUrl}/LY.png`,
  },
  {
    id: "28d2f5f1-8e3f-46b8-86cc-77166deffe59",
    iata: "BR",
    airlineName: "EVA Air",
    imgUrl: `${baseUrl}/BR.png`,
  },
  {
    id: "21370243-2bc9-4dd5-9b40-70df23ae2012",
    iata: "ZE",
    airlineName: "Eastar Jet",
    imgUrl: `${baseUrl}/ZE.png`,
  },
  {
    id: "834665a7-a011-4815-ac2f-da30d50d0006",
    iata: "2D",
    airlineName: "Eastern Airlines",
    imgUrl: `${baseUrl}/2D.png`,
  },
  {
    id: "3e1cd572-dcc3-4209-969a-6d3890e96c03",
    iata: "T3",
    airlineName: "Eastern Airways",
    imgUrl: `${baseUrl}/T3.png`,
  },
  {
    id: "b99cc8be-246e-4ed9-bb92-18df66c0ce3c",
    iata: "8J",
    airlineName: "Eco Jet",
    imgUrl: `${baseUrl}/8J.png`,
  },
  {
    id: "eb9cf9a9-d59b-48e9-a56b-aa34179612cd",
    iata: "WK",
    airlineName: "Edelweiss Air",
    imgUrl: `${baseUrl}/WK.png`,
  },
  {
    id: "4f196c78-6b59-4ed0-b292-0f28200c625b",
    iata: "MS",
    airlineName: "Egyptair",
    imgUrl: `${baseUrl}/MS.png`,
  },
  {
    id: "9b3ef546-b861-4c06-a2af-e226941f0fe9",
    iata: "EK",
    airlineName: "Emirates",
    imgUrl: `${baseUrl}/EK.png`,
  },
  {
    id: "596e183a-e361-4a39-8c61-0f3a28591eea",
    iata: "9E",
    airlineName: "Endeavor Air",
    imgUrl: `${baseUrl}/9E.png`,
  },
  {
    id: "a1e89cf5-0faf-41e3-853b-9fba0a2646fc",
    iata: "E4",
    airlineName: "Enter Air",
    imgUrl: `${baseUrl}/E4.png`,
  },
  {
    id: "30f6c1da-9d1f-41d7-950f-d5313133ba5c",
    iata: "MQ",
    airlineName: "Envoy Air",
    imgUrl: `${baseUrl}/MQ.png`,
  },
  {
    id: "c17ec695-5a5a-47d1-bd8c-5d321476cbad",
    iata: "RN",
    airlineName: "Eswatini Air",
    imgUrl: `${baseUrl}/RN.png`,
  },
  {
    id: "d381ea7b-274b-42df-addb-8f4d3c3e560a",
    iata: "ET",
    airlineName: "Ethiopian Airlines",
    imgUrl: `${baseUrl}/ET.png`,
  },
  {
    id: "b89c5cd0-0fd8-4a1d-99ee-94aedb8f9629",
    iata: "EY",
    airlineName: "Etihad Airways",
    imgUrl: `${baseUrl}/EY.png`,
  },
  {
    id: "e72ace68-672d-4ab8-90a9-38efa59b6c0f",
    iata: "YU",
    airlineName: "EuroAtlantic Airways",
    imgUrl: `${baseUrl}/YU.png`,
  },
  {
    id: "bc01a421-688d-453d-8845-bd8b5951530f",
    iata: "H6",
    airlineName: "European Air Charter",
    imgUrl: `${baseUrl}/H6.png`,
  },
  {
    id: "68682040-5dcb-46e3-8f10-68d6597bc4fc",
    iata: "EW",
    airlineName: "Eurowings",
    imgUrl: `${baseUrl}/EW.png`,
  },
  {
    id: "f4cfe4ab-57bc-4a42-a429-c0c99273baff",
    iata: "E6",
    airlineName: "Eurowings (Malta)",
    imgUrl: `${baseUrl}/E6.png`,
  },
  {
    id: "cd7fbfca-0735-4f84-9070-85cab2504093",
    iata: "E6",
    airlineName: "Eurowings Europe",
    imgUrl: `${baseUrl}/E6.png`,
  },
  {
    id: "eb26407b-2c77-4d10-a61c-c013a29d72ae",
    iata: "MG",
    airlineName: "Eznis Airways",
    imgUrl: `${baseUrl}/MG.png`,
  },
  {
    id: "0252461f-0e94-43b8-ade3-ca0ae316350d",
    iata: "U2",
    airlineName: "easyJet",
    imgUrl: `${baseUrl}/U2.png`,
  },
  {
    id: "82dcb145-d4b5-4424-bb4a-0888448ff720",
    iata: "3F",
    airlineName: "FLYONE Armenia",
    imgUrl: `${baseUrl}/3F.png`,
  },
  {
    id: "06e5b6c8-68a3-42f3-b42c-a26cc2af55a8",
    iata: "FN",
    airlineName: "Fastjet Zimbabwe",
    imgUrl: `${baseUrl}/FN.png`,
  },
  {
    id: "4c95d3fe-f345-4294-98dd-e2156a6e8602",
    iata: "FJ",
    airlineName: "Fiji Airways",
    imgUrl: `${baseUrl}/FJ.png`,
  },
  {
    id: "b14340a8-970e-4123-9f70-609a6c76879d",
    iata: "FJ",
    airlineName: "Fiji Link",
    imgUrl: `${baseUrl}/FJ.png`,
  },
  {
    id: "4dc6a24e-5d00-41bb-bc47-b4ff8c206971",
    iata: "AY",
    airlineName: "Finnair",
    imgUrl: `${baseUrl}/AY.png`,
  },
  {
    id: "303c93a8-bd57-456e-9972-2eb22c8fdb63",
    iata: "FY",
    airlineName: "Firefly",
    imgUrl: `${baseUrl}/FY.png`,
  },
  {
    id: "64a87d80-4bfc-4efb-81d0-025cf929d8f2",
    iata: "8D",
    airlineName: "FitsAir",
    imgUrl: `${baseUrl}/8D.png`,
  },
  {
    id: "b1b12591-c125-4efb-ad62-7cef989cdb28",
    iata: "F8",
    airlineName: "Flair Airlines",
    imgUrl: `${baseUrl}/F8.png`,
  },
  {
    id: "7fc4446e-8a99-4b7d-9ebf-318c96d3223a",
    iata: "W2",
    airlineName: "FlexFlight",
    imgUrl: `${baseUrl}/W2.png`,
  },
  {
    id: "df92cfa3-fe9e-481b-87f0-38b66f766a4b",
    iata: "YS",
    airlineName: "Flightlink",
    imgUrl: `${baseUrl}/YS.png`,
  },
  {
    id: "992d766e-7f1c-4f03-a250-77cc721fc32b",
    iata: "8W",
    airlineName: "Fly Always",
    imgUrl: `${baseUrl}/8W.png`,
  },
  {
    id: "913c5f9a-cb10-42dc-9da7-da0a32138a9c",
    iata: "EQ",
    airlineName: "Fly Angola",
    imgUrl: `${baseUrl}/EQ.png`,
  },
  {
    id: "b7390fa6-bbb0-433d-8f1f-c0cccd401e48",
    iata: "IF",
    airlineName: "Fly Baghdad",
    imgUrl: `${baseUrl}/IF.png`,
  },
  {
    id: "44485709-0da6-478b-a504-6f6786f56e4c",
    iata: "FL",
    airlineName: "Fly Lili",
    imgUrl: `${baseUrl}/FL.png`,
  },
  {
    id: "bfec0c56-10cf-4479-8b82-8533d71c67c6",
    iata: "YI",
    airlineName: "Fly OYA",
    imgUrl: `${baseUrl}/YI.png`,
  },
  {
    id: "3d6ac3e1-75a4-465c-b0bd-64f13c412e42",
    iata: "5F",
    airlineName: "Fly One",
    imgUrl: `${baseUrl}/5F.png`,
  },
  {
    id: "ea1031a4-c394-459a-936c-ec4e57f41532",
    iata: "FS",
    airlineName: "FlyArystan",
    imgUrl: `${baseUrl}/FS.png`,
  },
  {
    id: "60cb9da9-ac48-4d83-babc-ff69cf02e8af",
    iata: "9P",
    airlineName: "Fly Jinnah",
    imgUrl: `${baseUrl}/9P.png`,
  },
  {
    id: "a602b81c-d28a-48ab-b5a0-0772f547b084",
    iata: "5M",
    airlineName: "FlyMontserrat",
    imgUrl: `${baseUrl}/5M.png`,
  },
  {
    id: "3254e6c5-97e6-4af1-9250-d0f1c71841a8",
    iata: "FP",
    airlineName: "FlyPelican",
    imgUrl: `${baseUrl}/FP.png`,
  },
  {
    id: "7765f02d-f2fa-4707-9ff3-c6414b57b756",
    iata: "FA",
    airlineName: "FlySafair",
    imgUrl: `${baseUrl}/FA.png`,
  },
  {
    id: "7327f355-2027-4cbf-9c07-6410253dee53",
    iata: "FZ",
    airlineName: "Flydubai",
    imgUrl: `${baseUrl}/FZ.png`,
  },
  {
    id: "f219d912-0074-4a33-ba2f-882d4d3bfb46",
    iata: "XY",
    airlineName: "Flynas",
    imgUrl: `${baseUrl}/XY.png`,
  },
  {
    id: "18d03ab8-b7cc-49d8-a53d-c5d4adda409f",
    iata: "MI",
    airlineName: "Freebird Airlines Europe",
    imgUrl: `${baseUrl}/MI.png`,
  },
  {
    id: "b9771003-f95b-4415-b8de-2dfb8775fcdd",
    iata: "4K",
    airlineName: "Freedom Airline Express",
    imgUrl: `${baseUrl}/4K.png`,
  },
  {
    id: "882a3093-66cd-4e84-8a87-4de1741eef92",
    iata: "BF",
    airlineName: "French Bee",
    imgUrl: `${baseUrl}/BF.png`,
  },
  {
    id: "6b495401-faae-445e-997a-c5cf64c18e82",
    iata: "F9",
    airlineName: "Frontier Airlines",
    imgUrl: `${baseUrl}/F9.png`,
  },
  {
    id: "bd7ce930-5602-4400-ba65-567bc79a6a3c",
    iata: "JH",
    airlineName: "Fuji Dream Airlines",
    imgUrl: `${baseUrl}/JH.png`,
  },
  {
    id: "5146d5ae-7179-47c5-9cff-b04d0b573acf",
    iata: "FU",
    airlineName: "Fuzhou Airlines",
    imgUrl: `${baseUrl}/FU.png`,
  },
  {
    id: "8adf09ab-0fb6-42aa-9695-65c233faa19f",
    iata: "F3",
    airlineName: "flyadeal",
    imgUrl: `${baseUrl}/F3.png`,
  },
  {
    id: "10336217-982f-43d6-b2f6-2a27da20e55f",
    iata: "G3",
    airlineName: "GOL Linhas Aereas Inteligentes",
    imgUrl: `${baseUrl}/G3.png`,
  },
  {
    id: "79c88532-8c99-4206-b68e-3e8d5e5c2ff1",
    iata: "GX",
    airlineName: "GX Airlines",
    imgUrl: `${baseUrl}/GX.png`,
  },
  {
    id: "ef68da42-d269-428b-8913-b23037706302",
    iata: "GA",
    airlineName: "Garuda Indonesia",
    imgUrl: `${baseUrl}/GA.png`,
  },
  {
    id: "b3278e0d-3af9-48a1-87c9-423dd4490c31",
    iata: "4G",
    airlineName: "Gazpromavia",
    imgUrl: `${baseUrl}/4G.png`,
  },
  {
    id: "ff40c7bc-010f-43e4-936e-58321b805163",
    iata: "9D",
    airlineName: "Genghis Khan Airlines",
    imgUrl: `${baseUrl}/9D.png`,
  },
  {
    id: "271bc83e-b085-40b3-8591-5338017ee4e2",
    iata: "A9",
    airlineName: "Georgian Airways",
    imgUrl: `${baseUrl}/A9.png`,
  },
  {
    id: "ebfa37af-739c-48d8-bc64-65d3613f2b05",
    iata: "ZQ",
    airlineName: "German Airways",
    imgUrl: `${baseUrl}/ZQ.png`,
  },
  {
    id: "ba69af38-79a9-473e-8a98-6df0279827cf",
    iata: "GW",
    airlineName: "GetJet Airlines",
    imgUrl: `${baseUrl}/GW.png`,
  },
  {
    id: "7552cd20-9f07-4596-9793-fb1c4d345436",
    iata: "CN",
    airlineName: "Grand China Air",
    imgUrl: `${baseUrl}/CN.png`,
  },
  {
    id: "2e40dc65-8b3d-4b26-a9a1-efe5517fbcd9",
    iata: "GV",
    airlineName: "Grant Aviation",
    imgUrl: `${baseUrl}/GV.png`,
  },
  {
    id: "276f01b8-3792-4232-b8db-640c31b4b7f4",
    iata: "HB",
    airlineName: "Greater Bay Airlines",
    imgUrl: `${baseUrl}/HB.png`,
  },
  {
    id: "15959e24-70a8-4a32-9b17-a01f365de941",
    iata: "Q9",
    airlineName: "Green Africa Airways",
    imgUrl: `${baseUrl}/Q9.png`,
  },
  {
    id: "e26ab7c3-88e7-4f87-a92e-b8e6bde9a486",
    iata: "GF",
    airlineName: "Gulf Air",
    imgUrl: `${baseUrl}/GF.png`,
  },
  {
    id: "82b06dab-e7a0-4f42-bd6e-0b98af2019f6",
    iata: "G2",
    airlineName: "GullivAir",
    imgUrl: `${baseUrl}/G2.png`,
  },
  {
    id: "ea454169-3a13-45fc-b295-62cd5f949244",
    iata: "HR",
    airlineName: "Hahn Air",
    imgUrl: `${baseUrl}/HR.png`,
  },
  {
    id: "5f897363-5b9d-464f-aaaf-3cf82ecceafd",
    iata: "HU",
    airlineName: "Hainan Airlines",
    imgUrl: `${baseUrl}/HU.png`,
  },
  {
    id: "a2f10c3e-32e6-4807-8e51-f86776ed9378",
    iata: "YB",
    airlineName: "Harbour Air Seaplanes",
    imgUrl: `${baseUrl}/YB.png`,
  },
  {
    id: "051d443e-3509-4e1e-a95f-29e3e2f2e8ce",
    iata: "HA",
    airlineName: "Hawaiian Airlines",
    imgUrl: `${baseUrl}/HA.png`,
  },
  {
    id: "23847796-7cab-41c7-8e40-d1ca8320efc4",
    iata: "NS",
    airlineName: "Hebei Airlines",
    imgUrl: `${baseUrl}/NS.png`,
  },
  {
    id: "11856c0b-2d3e-4caf-8ff2-6282ddf02bb2",
    iata: "2L",
    airlineName: "Helvetic Airways",
    imgUrl: `${baseUrl}/2L.png`,
  },
  {
    id: "060897ea-9f41-4330-a5ac-d7980237f6eb",
    iata: "HN",
    airlineName: "Heston Airlines",
    imgUrl: `${baseUrl}/HN.png`,
  },
  {
    id: "ea783e79-ca2d-4f2f-b47e-02bd4d928c6c",
    iata: "H7",
    airlineName: "HiSky",
    imgUrl: `${baseUrl}/H7.png`,
  },
  {
    id: "974cf8e1-2bd1-49b7-8a10-5293bbc04925",
    iata: "H4",
    airlineName: "HiSky Europe",
    imgUrl: `${baseUrl}/H4.png`,
  },
  {
    id: "e728ae96-088b-486c-9329-e23548671006",
    iata: "H9",
    airlineName: "Himalaya Airlines",
    imgUrl: `${baseUrl}/H9.png`,
  },
  {
    id: "5ac042ad-bee9-44f7-9210-8c2dcbca4c2c",
    iata: "OI",
    airlineName: "Hinterland Aviation",
    imgUrl: `${baseUrl}/OI.png`,
  },
  {
    id: "b708df09-b8cb-44e1-9077-9e64366db597",
    iata: "HX",
    airlineName: "Hong Kong Airlines",
    imgUrl: `${baseUrl}/HX.png`,
  },
  {
    id: "7b4c01ef-4255-400f-a003-04de32147d0b",
    iata: "MR",
    airlineName: "Hunnu Air",
    imgUrl: `${baseUrl}/MR.png`,
  },
  {
    id: "2a63a807-ed0f-4a90-a961-6b9a65bc4109",
    iata: "AZ",
    airlineName: "ITA - Italia Trasporto Aereo",
    imgUrl: `${baseUrl}/AZ.png`,
  },
  {
    id: "12fb53f2-0ea0-430c-846d-28c2ec5e1a0e",
    iata: "IB",
    airlineName: "Iberia",
    imgUrl: `${baseUrl}/IB.png`,
  },
  {
    id: "f56c02b8-57a0-4ad0-a7a7-6843a70da05e",
    iata: "I2",
    airlineName: "Iberia Express",
    imgUrl: `${baseUrl}/I2.png`,
  },
  {
    id: "aeab77f5-ebe5-4ccd-a2ec-e976b16efb5d",
    iata: "E9",
    airlineName: "Iberojet",
    imgUrl: `${baseUrl}/E9.png`,
  },
  {
    id: "bff35c26-890e-4cb8-9e61-98eefc06388c",
    iata: "FW",
    airlineName: "Ibex Airlines",
    imgUrl: `${baseUrl}/FW.png`,
  },
  {
    id: "aebbcac1-23f2-4937-a6dc-825e9cdaaea2",
    iata: "QI",
    airlineName: "Ibom Air",
    imgUrl: `${baseUrl}/QI.png`,
  },
  {
    id: "9633f95c-94e1-40e0-890b-8380de5847f3",
    iata: "FI",
    airlineName: "Icelandair",
    imgUrl: `${baseUrl}/FI.png`,
  },
  {
    id: "e82a318e-8c1e-416a-a74c-0269ec4943ab",
    iata: "V8",
    airlineName: "Iliamna Air Taxi",
    imgUrl: `${baseUrl}/V8.png`,
  },
  {
    id: "25097864-ce8d-42bf-b47b-3adee8da01bc",
    iata: "6E",
    airlineName: "IndiGo",
    imgUrl: `${baseUrl}/6E.png`,
  },
  {
    id: "8ff72613-8b40-4635-b69e-f671efd69d87",
    iata: "I7",
    airlineName: "IndiaOne Air",
    imgUrl: `${baseUrl}/I7.png`,
  },
  {
    id: "04edc430-896a-42b4-b7a4-c13c01f09836",
    iata: "QZ",
    airlineName: "Indonesia AirAsia",
    imgUrl: `${baseUrl}/QZ.png`,
  },
  {
    id: "95309121-fbca-4d11-af26-1c634ec144c6",
    iata: "JY",
    airlineName: "InterCaribbean Airways",
    imgUrl: `${baseUrl}/JY.png`,
  },
  {
    id: "7ab1565f-bc02-4973-8dfc-dadc443e1c73",
    iata: "IO",
    airlineName: "IrAero",
    imgUrl: `${baseUrl}/IO.png`,
  },
  {
    id: "1f45b9a5-407a-45ef-86b7-01421eb14f53",
    iata: "IR",
    airlineName: "Iran Air",
    imgUrl: `${baseUrl}/IR.png`,
  },
  {
    id: "599509b1-416a-4e34-97f4-b6381c893cc7",
    iata: "B9",
    airlineName: "Iran Airtour",
    imgUrl: `${baseUrl}/B9.png`,
  },
  {
    id: "ac35f00a-634e-4a7e-8d10-426c10b8b9c5",
    iata: "EP",
    airlineName: "Iran Aseman Airlines",
    imgUrl: `${baseUrl}/EP.png`,
  },
  {
    id: "ca402923-14ba-4286-9386-6c83db36ada6",
    iata: "IA",
    airlineName: "Iraqi Airways",
    imgUrl: `${baseUrl}/IA.png`,
  },
  {
    id: "480ee184-d9d5-4c74-8df6-0214cd84b304",
    iata: "I4",
    airlineName: "Island Air Express",
    imgUrl: `${baseUrl}/I4.png`,
  },
  {
    id: "1261c9b3-9d08-4b99-994b-5b41180d6b68",
    iata: "6H",
    airlineName: "Israir Airlines",
    imgUrl: `${baseUrl}/6H.png`,
  },
  {
    id: "095e0075-7866-41c4-97d7-523a87294ca7",
    iata: "I8",
    airlineName: "Izhavia",
    imgUrl: `${baseUrl}/I8.png`,
  },
  {
    id: "2ea84f10-75e0-4652-863b-f553b449b2bd",
    iata: "F7",
    airlineName: "iFly",
    imgUrl: `${baseUrl}/F7.png`,
  },
  {
    id: "c632e7b4-842e-454d-b5f5-9b14a0596576",
    iata: "JL",
    airlineName: "J-Air",
    imgUrl: `${baseUrl}/JL.png`,
  },
  {
    id: "939ce59e-f2ef-49eb-93a4-cebfad80d775",
    iata: "JM",
    airlineName: "Jambojet",
    imgUrl: `${baseUrl}/JM.png`,
  },
  {
    id: "1a66e2cc-a736-4dec-83ef-2ca821ee0c70",
    iata: "JC",
    airlineName: "Japan Air Commuter",
    imgUrl: `${baseUrl}/JC.png`,
  },
  {
    id: "99e7ad6f-f1c6-450f-81e8-4ec6d65ef3ee",
    iata: "JL",
    airlineName: "Japan Airlines",
    imgUrl: `${baseUrl}/JL.png`,
  },
  {
    id: "933dff57-e201-42fd-bc33-e6940ae0f552",
    iata: "NU",
    airlineName: "Japan Transocean Air",
    imgUrl: `${baseUrl}/NU.png`,
  },
  {
    id: "942371e8-101d-411c-8b7b-899c39e48356",
    iata: "J9",
    airlineName: "Jazeera Airways",
    imgUrl: `${baseUrl}/J9.png`,
  },
  {
    id: "e343fdf8-788f-419f-8df5-88c81df940f9",
    iata: "7C",
    airlineName: "Jeju Air",
    imgUrl: `${baseUrl}/7C.png`,
  },
  {
    id: "40f13c11-1d77-4425-bf1d-9f5103ebbe53",
    iata: "LS",
    airlineName: "Jet2.com",
    imgUrl: `${baseUrl}/LS.png`,
  },
  {
    id: "ef1478bd-130b-4837-bf3a-876b5252283e",
    iata: "JA",
    airlineName: "JetSmart",
    imgUrl: `${baseUrl}/JA.png`,
  },
  {
    id: "02064877-a9aa-40d7-9b72-a464540ec6c7",
    iata: "WJ",
    airlineName: "JetSmart Argentina",
    imgUrl: `${baseUrl}/WJ.png`,
  },
  {
    id: "fb91d46a-6697-4726-97d6-6b74be4001e6",
    iata: "JZ",
    airlineName: "JetSmart Peru",
    imgUrl: `${baseUrl}/JZ.png`,
  },
  {
    id: "473a91ff-f4c7-417b-b963-f3092ecbcc01",
    iata: "JQ",
    airlineName: "Jetstar",
    imgUrl: `${baseUrl}/JQ.png`,
  },
  {
    id: "02fa7664-84a4-432c-ae36-015e667095f3",
    iata: "3K",
    airlineName: "Jetstar Asia",
    imgUrl: `${baseUrl}/3K.png`,
  },
  {
    id: "0b701580-0344-468f-a17c-5c6e76d7ce18",
    iata: "GK",
    airlineName: "Jetstar Japan",
    imgUrl: `${baseUrl}/GK.png`,
  },
  {
    id: "db75e815-9cea-4ec2-8e90-b13fab8762ba",
    iata: "JP",
    airlineName: "Jettime",
    imgUrl: `${baseUrl}/JP.png`,
  },
  {
    id: "c3b1f744-c950-4e8e-8280-5dc4b9f0093e",
    iata: "RY",
    airlineName: "Jiangxi Air",
    imgUrl: `${baseUrl}/RY.png`,
  },
  {
    id: "f77bdddc-2c11-42a3-9bb5-ecbbf922ab3e",
    iata: "LJ",
    airlineName: "Jin Air",
    imgUrl: `${baseUrl}/LJ.png`,
  },
  {
    id: "0d49c937-5fdc-4a3f-b1d9-2f3c2e215d3c",
    iata: "R5",
    airlineName: "Jordan Aviation",
    imgUrl: `${baseUrl}/R5.png`,
  },
  {
    id: "521af30b-5a10-453e-ab24-a3c7a669bd2b",
    iata: "JR",
    airlineName: "Joy Air",
    imgUrl: `${baseUrl}/JR.png`,
  },
  {
    id: "c916dac0-9b8a-4e29-86e3-cf80088c6af4",
    iata: "HO",
    airlineName: "Juneyao Airlines",
    imgUrl: `${baseUrl}/HO.png`,
  },
  {
    id: "94ff4063-ff63-471d-9103-98e984d65446",
    iata: "B6",
    airlineName: "jetBlue",
    imgUrl: `${baseUrl}/B6.png`,
  },
  {
    id: "c7e5c8e3-f09e-4249-b37f-cf00b5567c08",
    iata: "KL",
    airlineName: "KLM",
    imgUrl: `${baseUrl}/KL.png`,
  },
  {
    id: "ea3b3b14-cec3-4dae-8b1a-2d4781e300f8",
    iata: "WA",
    airlineName: "KLM Cityhopper",
    imgUrl: `${baseUrl}/WA.png`,
  },
  {
    id: "70919c49-19b2-4ec7-bf8b-d2feabfc6fa0",
    iata: "RQ",
    airlineName: "Kam Air",
    imgUrl: `${baseUrl}/RQ.png`,
  },
  {
    id: "6b08139c-cf31-4fb7-8bc0-d74bb2cdea7b",
    iata: "M5",
    airlineName: "Kenmore Air",
    imgUrl: `${baseUrl}/M5.png`,
  },
  {
    id: "7630fd75-6ebd-46e3-9ac2-2ce10c7b9c3a",
    iata: "KQ",
    airlineName: "Kenya Airways",
    imgUrl: `${baseUrl}/KQ.png`,
  },
  {
    id: "611a2715-5d92-4790-bbbc-67eebcc784fe",
    iata: "KE",
    airlineName: "Korean Air",
    imgUrl: `${baseUrl}/KE.png`,
  },
  {
    id: "48b2e45f-88aa-42f6-9361-ee674d088df9",
    iata: "KV",
    airlineName: "KrasAvia",
    imgUrl: `${baseUrl}/KV.png`,
  },
  {
    id: "b9e0271e-4bf2-4cee-85d0-8e0669a1aab2",
    iata: "KY",
    airlineName: "Kunming Airlines",
    imgUrl: `${baseUrl}/KY.png`,
  },
  {
    id: "d8664cf9-17bd-4334-ab7a-3b501150e0bd",
    iata: "KU",
    airlineName: "Kuwait Airways",
    imgUrl: `${baseUrl}/KU.png`,
  },
  {
    id: "5c0a10c4-7ff0-4340-addc-fb80901f2a09",
    iata: "TM",
    airlineName: "LAM Mozambique Airlines",
    imgUrl: `${baseUrl}/TM.png`,
  },
  {
    id: "9be7974a-9c39-4af7-86fe-5fd05fff806b",
    iata: "QL",
    airlineName: "LASER Airlines",
    imgUrl: `${baseUrl}/QL.png`,
  },
  {
    id: "5a931ba6-8a7b-4eab-be00-5875dfeffdc6",
    iata: "JJ",
    airlineName: "LATAM Brasil",
    imgUrl: `${baseUrl}/JJ.png`,
  },
  {
    id: "92cb58a1-20ff-4df5-b46b-373e9b7e0fcf",
    iata: "LA",
    airlineName: "LATAM Chile",
    imgUrl: `${baseUrl}/LA.png`,
  },
  {
    id: "005d0b21-5c9d-43d9-a8b0-84250a3162a9",
    iata: "4C",
    airlineName: "LATAM Colombia",
    imgUrl: `${baseUrl}/4C.png`,
  },
  {
    id: "e8087b60-3697-4d0d-9389-af8940848d18",
    iata: "XL",
    airlineName: "LATAM Ecuador",
    imgUrl: `${baseUrl}/XL.png`,
  },
  {
    id: "57975e16-9353-400f-a9f6-fbba48aaec25",
    iata: "LU",
    airlineName: "LATAM Express",
    imgUrl: `${baseUrl}/LU.png`,
  },
  {
    id: "aae16d9d-9949-4064-bc75-4d6d3ad3daa0",
    iata: "PZ",
    airlineName: "LATAM Paraguay",
    imgUrl: `${baseUrl}/PZ.png`,
  },
  {
    id: "fa8b2fda-2f88-4110-b5f7-37d9f0e46f93",
    iata: "LP",
    airlineName: "LATAM Peru",
    imgUrl: `${baseUrl}/LP.png`,
  },
  {
    id: "3c76ea82-97d9-48c6-9cea-21c30fe8f90d",
    iata: "LO",
    airlineName: "LOT Polish",
    imgUrl: `${baseUrl}/LO.png`,
  },
  {
    id: "d8b4bbe4-6cc4-4fd5-98fd-7b94813605ba",
    iata: "B0",
    airlineName: "La Compagnie",
    imgUrl: `${baseUrl}/B0.png`,
  },
  {
    id: "3cfbc870-bfe4-47e3-8477-6c0f49ba6620",
    iata: "QV",
    airlineName: "Lao Airlines",
    imgUrl: `${baseUrl}/QV.png`,
  },
  {
    id: "d4335e2e-986c-45f6-8965-c6962022cc1e",
    iata: "LK",
    airlineName: "Lao Skyway",
    imgUrl: `${baseUrl}/LK.png`,
  },
  {
    id: "7ed31133-23bb-4cb5-bf23-bfd0cc070368",
    iata: "LN",
    airlineName: "Libyan Airlines",
    imgUrl: `${baseUrl}/LN.png`,
  },
  {
    id: "e966be54-a0bb-485b-9566-08cda0eb4ead",
    iata: "YL",
    airlineName: "Libyan Wings",
    imgUrl: `${baseUrl}/YL.png`,
  },
  {
    id: "c58eaa96-27be-4f0e-900e-250e4370bef7",
    iata: "JT",
    airlineName: "Lion Air",
    imgUrl: `${baseUrl}/JT.png`,
  },
  {
    id: "b29a236a-4172-4177-9e32-8973860619be",
    iata: "LM",
    airlineName: "Loganair",
    imgUrl: `${baseUrl}/LM.png`,
  },
  {
    id: "2429f5a9-6d1f-47e7-94c1-02d4f4b03abd",
    iata: "LT",
    airlineName: "LongJiang Airlines",
    imgUrl: `${baseUrl}/LT.png`,
  },
  {
    id: "da69a6ab-ac12-4bbb-bd90-3475ad2d4be4",
    iata: "GJ",
    airlineName: "Loong Air",
    imgUrl: `${baseUrl}/GJ.png`,
  },
  {
    id: "20c01a12-6afa-417d-a592-bf441dfaa7fd",
    iata: "8L",
    airlineName: "Lucky Air",
    imgUrl: `${baseUrl}/8L.png`,
  },
  {
    id: "a41106c1-aab7-40c8-85f2-00c520c302bb",
    iata: "LH",
    airlineName: "Lufthansa",
    imgUrl: `${baseUrl}/LH.png`,
  },
  {
    id: "bad16640-0b88-4b20-a824-54d59f819ac8",
    iata: "CL",
    airlineName: "Lufthansa CityLine",
    imgUrl: `${baseUrl}/CL.png`,
  },
  {
    id: "7a3e22ea-d7fa-4c62-9885-f2805fe2e3f2",
    iata: "L9",
    airlineName: "Lumiwings",
    imgUrl: `${baseUrl}/L9.png`,
  },
  {
    id: "155cc113-051d-4464-8395-f7e03b94d21e",
    iata: "LG",
    airlineName: "Luxair",
    imgUrl: `${baseUrl}/LG.png`,
  },
  {
    id: "59260a28-3e77-4155-b7d7-3f25aa264a4b",
    iata: "7M",
    airlineName: "MAP Linhas Aéreas",
    imgUrl: `${baseUrl}/7M.png`,
  },
  {
    id: "9e1e99db-cc0a-4214-bbe2-ef4d39c750e5",
    iata: "MY",
    airlineName: "MASwings",
    imgUrl: `${baseUrl}/MY.png`,
  },
  {
    id: "d523defb-9341-43e5-b8dc-dd85bd0eb23e",
    iata: "OM",
    airlineName: "MIAT Mongolian Airlines",
    imgUrl: `${baseUrl}/OM.png`,
  },
  {
    id: "446e978e-d5fd-4317-8c86-e97984fe0ff8",
    iata: "W5",
    airlineName: "Mahan Air",
    imgUrl: `${baseUrl}/W5.png`,
  },
  {
    id: "10151905-3acd-4727-a198-14383f755e8e",
    iata: "MC",
    airlineName: "Mahogany Air",
    imgUrl: `${baseUrl}/MC.png`,
  },
  {
    id: "de58b234-332c-4fa9-9f04-c13157d8a25c",
    iata: "3W",
    airlineName: "Malawi Airlines",
    imgUrl: `${baseUrl}/3W.png`,
  },
  {
    id: "8f293b08-9829-4e59-bf58-a1577b4b6ee2",
    iata: "MH",
    airlineName: "Malaysia Airlines",
    imgUrl: `${baseUrl}/MH.png`,
  },
  {
    id: "392e5994-1831-4068-9d15-63ef57023b7b",
    iata: "AE",
    airlineName: "Mandarin Airlines",
    imgUrl: `${baseUrl}/AE.png`,
  },
  {
    id: "5be845b1-7a4a-4fde-aabb-715fc934d866",
    iata: "7Y",
    airlineName: "Mann Yadanarpon Airlines",
    imgUrl: `${baseUrl}/7Y.png`,
  },
  {
    id: "f8251f67-a40a-4bc4-a80f-40dcfc1b575c",
    iata: "NR",
    airlineName: "Manta Air",
    imgUrl: `${baseUrl}/NR.png`,
  },
  {
    id: "c21972c6-5018-49b4-a487-8009d30fb75d",
    iata: "4M",
    airlineName: "Mavi Gok Airlines",
    imgUrl: `${baseUrl}/4M.png`,
  },
  {
    id: "b9c3596e-9935-49cd-8f97-9f040e176d17",
    iata: "VM",
    airlineName: "Max Air",
    imgUrl: `${baseUrl}/VM.png`,
  },
  {
    id: "4b96d84e-03a7-422b-8fb6-3b64ef1b8418",
    iata: "2M",
    airlineName: "Maya Island Air",
    imgUrl: `${baseUrl}/2M.png`,
  },
  {
    id: "22b5106d-3348-495b-8897-a26fdf891dbf",
    iata: "YV",
    airlineName: "Mesa Airlines",
    imgUrl: `${baseUrl}/YV.png`,
  },
  {
    id: "a5f22e2f-a73a-43b5-a361-01e2fdb84265",
    iata: "ME",
    airlineName: "Middle East Airlines",
    imgUrl: `${baseUrl}/ME.png`,
  },
  {
    id: "4c39152a-d416-4646-bbd6-100def750817",
    iata: "K7",
    airlineName: "Mingalar Aviation Services",
    imgUrl: `${baseUrl}/K7.png`,
  },
  {
    id: "bbcafd16-5dc9-4a13-b795-fc2a7585045d",
    iata: "M9",
    airlineName: "Motor Sich Airlines",
    imgUrl: `${baseUrl}/M9.png`,
  },
  {
    id: "37a0e77f-19e3-46a6-b103-8353b48f28d8",
    iata: "8M",
    airlineName: "Myanmar Airways International",
    imgUrl: `${baseUrl}/8M.png`,
  },
  {
    id: "7ad5e9ea-c0cd-4e74-a31c-7e0158ecae18",
    iata: "UB",
    airlineName: "Myanmar National Airlines",
    imgUrl: `${baseUrl}/UB.png`,
  },
  {
    id: "a92a6643-3279-48b2-8fad-d103434af60b",
    iata: "MJ",
    airlineName: "Myway Airlines",
    imgUrl: `${baseUrl}/MJ.png`,
  },
  {
    id: "c2c87c56-4d43-493a-ab1d-eb8a4dc3b067",
    iata: "IN",
    airlineName: "NAM Air",
    imgUrl: `${baseUrl}/IN.png`,
  },
  {
    id: "26884496-55e4-4cba-9718-39d3ea5b122b",
    iata: "9Y",
    airlineName: "National Airways Ethiopia",
    imgUrl: `${baseUrl}/9Y.png`,
  },
  {
    id: "56d9a285-fe25-4449-845d-804e79ad2f69",
    iata: "ON",
    airlineName: "Nauru Airlines",
    imgUrl: `${baseUrl}/ON.png`,
  },
  {
    id: "9892151e-858e-4e10-af56-a0365103ce2d",
    iata: "NO",
    airlineName: "Neos",
    imgUrl: `${baseUrl}/NO.png`,
  },
  {
    id: "fb7b0bf5-1480-48bf-bf35-d68447b9f51d",
    iata: "RA",
    airlineName: "Nepal Airlines",
    imgUrl: `${baseUrl}/RA.png`,
  },
  {
    id: "847c3f23-fbe1-489e-97c7-ea183f81cc57",
    iata: "NE",
    airlineName: "Nesma Airlines",
    imgUrl: `${baseUrl}/NE.png`,
  },
  {
    id: "34a0c216-deed-4991-a7ac-6087fa6cde3b",
    iata: "7H",
    airlineName: "New Pacific Airlines",
    imgUrl: `${baseUrl}/7H.png`,
  },
  {
    id: "61f93cfe-c895-4193-bec3-347ad7bb9637",
    iata: "6N",
    airlineName: "Niger Airlines",
    imgUrl: `${baseUrl}/6N.png`,
  },
  {
    id: "033372df-8b60-4e70-b879-a543ca9c2c3c",
    iata: "NP",
    airlineName: "Nile Air",
    imgUrl: `${baseUrl}/NP.png`,
  },
  {
    id: "82ffde7f-b020-4c55-bd2c-3670a74b55e2",
    iata: "DD",
    airlineName: "Nok Air",
    imgUrl: `${baseUrl}/DD.png`,
  },
  {
    id: "24223837-ea19-42f2-a67c-d4227db09796",
    iata: "N5",
    airlineName: "Nolinor Aviation",
    imgUrl: `${baseUrl}/N5.png`,
  },
  {
    id: "57f491fc-11db-428e-9c72-a8273b57e9ea",
    iata: "Y7",
    airlineName: "NordStar",
    imgUrl: `${baseUrl}/Y7.png`,
  },
  {
    id: "5abcc95c-5f22-4702-b6d2-f9b2b242be4f",
    iata: "N0",
    airlineName: "Norse Atlantic Airways",
    imgUrl: `${baseUrl}/N0.png`,
  },
  {
    id: "a62c2bb5-8c07-4901-b633-89b0e7907090",
    iata: "Z0",
    airlineName: "Norse Atlantic UK",
    imgUrl: `${baseUrl}/Z0.png`,
  },
  {
    id: "9c7cfd14-40c7-47b5-869b-db40994c2cf2",
    iata: "HW",
    airlineName: "North-Wright Airways",
    imgUrl: `${baseUrl}/HW.png`,
  },
  {
    id: "78d31655-1451-4f33-9c50-6f1ad861cdf7",
    iata: "J3",
    airlineName: "Northwestern Air",
    imgUrl: `${baseUrl}/J3.png`,
  },
  {
    id: "6f22a144-9505-4e22-b731-2a7749129e03",
    iata: "DY",
    airlineName: "Norwegian Air Shuttle",
    imgUrl: `${baseUrl}/DY.png`,
  },
  {
    id: "ace4421f-a9df-47d6-ac3c-17dc52991650",
    iata: "D8",
    airlineName: "Norwegian Air Sweden",
    imgUrl: `${baseUrl}/D8.png`,
  },
  {
    id: "b2f4f2aa-b6b3-4603-a8ba-4e048c9a867b",
    iata: "BJ",
    airlineName: "Nouvelair",
    imgUrl: `${baseUrl}/BJ.png`,
  },
  {
    id: "a9cc9123-8266-4cc8-8ee0-eebe5b910c8a",
    iata: "VQ",
    airlineName: "Novoair",
    imgUrl: `${baseUrl}/VQ.png`,
  },
  {
    id: "fe23068f-5007-4490-a3f0-8931f7e95dbf",
    iata: "OJ",
    airlineName: "NyxAir",
    imgUrl: `${baseUrl}/OJ.png`,
  },
  {
    id: "1e0e570e-f5e0-48d4-9323-92d83084fb1c",
    iata: "BK",
    airlineName: "Okay Airways",
    imgUrl: `${baseUrl}/BK.png`,
  },
  {
    id: "02b46932-e2fd-4b20-8797-55810e49eee0",
    iata: "OA",
    airlineName: "Olympic Air",
    imgUrl: `${baseUrl}/OA.png`,
  },
  {
    id: "542c5841-e32c-4937-971c-b965cbf514df",
    iata: "WY",
    airlineName: "Oman Air",
    imgUrl: `${baseUrl}/WY.png`,
  },
  {
    id: "34e20cdc-c359-4fd5-88a1-0034fbee7f49",
    iata: "OC",
    airlineName: "Oriental Air Bridge",
    imgUrl: `${baseUrl}/OC.png`,
  },
  {
    id: "6e559823-bd54-4d0f-952e-16e2cd777926",
    iata: "OF",
    airlineName: "Overland Airways",
    imgUrl: `${baseUrl}/OF.png`,
  },
  {
    id: "cb8d618d-dd8c-40c0-bc6c-5f1f471afda5",
    iata: "PB",
    airlineName: "PAL Airlines",
    imgUrl: `${baseUrl}/PB.png`,
  },
  {
    id: "db77b48a-2cc8-40fb-9daa-f28d61cdf052",
    iata: "2P",
    airlineName: "PAL Express",
    imgUrl: `${baseUrl}/2P.png`,
  },
  {
    id: "25365719-3e70-42cd-aaeb-7f7127de8152",
    iata: "OG",
    airlineName: "PLAY",
    imgUrl: `${baseUrl}/OG.png`,
  },
  {
    id: "1e205fc5-1b58-4dc6-b115-fcdf504d5057",
    iata: "CG",
    airlineName: "PNG Air",
    imgUrl: `${baseUrl}/CG.png`,
  },
  {
    id: "f0c7f15a-46ae-4a27-80b0-920ab8c40638",
    iata: "P6",
    airlineName: "Pascan Aviation",
    imgUrl: `${baseUrl}/P6.png`,
  },
  {
    id: "12c49d52-ba52-4811-86a8-92023117dc73",
    iata: "OP",
    airlineName: "PassionAir",
    imgUrl: `${baseUrl}/OP.png`,
  },
  {
    id: "2e6ce73e-1a63-4405-a311-8473d84fbceb",
    iata: "MM",
    airlineName: "Peach",
    imgUrl: `${baseUrl}/MM.png`,
  },
  {
    id: "6e9848f1-b81f-41a8-9a41-a46c4658b941",
    iata: "PC",
    airlineName: "Pegasus Airlines",
    imgUrl: `${baseUrl}/PC.png`,
  },
  {
    id: "4a9e906e-f907-4d22-88ca-5ae05394d6f7",
    iata: "IP",
    airlineName: "Pelita Air",
    imgUrl: `${baseUrl}/IP.png`,
  },
  {
    id: "2c15fade-c4e3-4a93-b989-fa25fc0215fc",
    iata: "PE",
    airlineName: "People's",
    imgUrl: `${baseUrl}/PE.png`,
  },
  {
    id: "5b1c02ec-a84b-4883-80bb-a101dd6f4037",
    iata: "JV",
    airlineName: "Perimeter Aviation",
    imgUrl: `${baseUrl}/JV.png`,
  },
  {
    id: "04d32ae1-9fd9-44f3-9b83-4a6d7e55cab8",
    iata: "PR",
    airlineName: "Philippine Airlines",
    imgUrl: `${baseUrl}/PR.png`,
  },
  {
    id: "d2032f24-5bba-4a82-819c-a61794d0d576",
    iata: "Z2",
    airlineName: "Philippines AirAsia",
    imgUrl: `${baseUrl}/Z2.png`,
  },
  {
    id: "8c136e53-34d9-4e0f-be1b-1feca1ecd65b",
    iata: "PH",
    airlineName: "Phoenix Air",
    imgUrl: `${baseUrl}/PH.png`,
  },
  {
    id: "38ec4c49-eac7-46fd-9ab8-f73e28130cfe",
    iata: "PT",
    airlineName: "Piedmont Airlines",
    imgUrl: `${baseUrl}/PT.png`,
  },
  {
    id: "d1c000cf-1a3e-49ff-8f48-7eb70bf54f4a",
    iata: "PU",
    airlineName: "Plus Ultra Lineas Aereas",
    imgUrl: `${baseUrl}/PU.png`,
  },
  {
    id: "627d2071-bd29-4112-b589-820744a00e5c",
    iata: "DP",
    airlineName: "Pobeda",
    imgUrl: `${baseUrl}/DP.png`,
  },
  {
    id: "31b5b9bb-b19c-4b6e-ab75-eb0c3c190f25",
    iata: "PO",
    airlineName: "Polar Airlines",
    imgUrl: `${baseUrl}/PO.png`,
  },
  {
    id: "3918281d-c722-4954-a45c-36e3f04b4863",
    iata: "HP",
    airlineName: "PopulAir",
    imgUrl: `${baseUrl}/HP.png`,
  },
  {
    id: "e0e79a00-7420-46ce-89f9-c5167302b73a",
    iata: "PD",
    airlineName: "Porter Airlines",
    imgUrl: `${baseUrl}/PD.png`,
  },
  {
    id: "0b0c1b63-cb24-41b7-98fc-b147157e5357",
    iata: "NI",
    airlineName: "Portugalia Airlines",
    imgUrl: `${baseUrl}/NI.png`,
  },
  {
    id: "d8697c33-dfba-43de-8bf0-423c2bf00714",
    iata: "PW",
    airlineName: "Precision Air",
    imgUrl: `${baseUrl}/PW.png`,
  },
  {
    id: "4db79620-d925-40c9-bf25-d5f23a39ce44",
    iata: "P0",
    airlineName: "Proflight Zambia",
    imgUrl: `${baseUrl}/P0.png`,
  },
  {
    id: "6253b945-e1fe-448a-ad3c-2c91db9919c9",
    iata: "HH",
    airlineName: "Qanot Sharq",
    imgUrl: `${baseUrl}/HH.png`,
  },
  {
    id: "e6495d2b-eb13-4409-8e84-020c0262ebd6",
    iata: "QF",
    airlineName: "Qantas",
    imgUrl: `${baseUrl}/QF.png`,
  },
  {
    id: "f3715223-501c-4bf9-a2d7-7d3236990bc9",
    iata: "QR",
    airlineName: "Qatar Airways",
    imgUrl: `${baseUrl}/QR.png`,
  },
  {
    id: "74a3f2cc-23fa-4ed1-bf18-fc4eb28d8d26",
    iata: "IQ",
    airlineName: "Qazaq Air",
    imgUrl: `${baseUrl}/IQ.png`,
  },
  {
    id: "7da7c438-f6fe-439f-8bf6-913bcd518d34",
    iata: "QB",
    airlineName: "Qeshm Air",
    imgUrl: `${baseUrl}/QB.png`,
  },
  {
    id: "c7169802-b16d-480f-b9e7-fdf397bb8278",
    iata: "QW",
    airlineName: "Qingdao Airlines",
    imgUrl: `${baseUrl}/QW.png`,
  },
  {
    id: "793586ec-bde9-4eba-b41b-2cfa05564d16",
    iata: "L5",
    airlineName: "Red Air",
    imgUrl: `${baseUrl}/L5.png`,
  },
  {
    id: "f491aa6f-2522-480f-8164-92e03b65af7b",
    iata: "4S",
    airlineName: "Red Sea Airlines",
    imgUrl: `${baseUrl}/4S.png`,
  },
  {
    id: "bd50242d-b33b-49cf-9ba2-84b4492b47bf",
    iata: "WZ",
    airlineName: "Red Wings",
    imgUrl: `${baseUrl}/WZ.png`,
  },
  {
    id: "27063db4-7037-4d00-8872-89848fed3575",
    iata: "8N",
    airlineName: "Regional Air",
    imgUrl: `${baseUrl}/8N.png`,
  },
  {
    id: "a426d0fd-c6c9-4517-be7a-543ec1f3d541",
    iata: "ZL",
    airlineName: "Regional Express",
    imgUrl: `${baseUrl}/ZL.png`,
  },
  {
    id: "a5900e24-4ee5-4c56-b837-e67eaea7bea0",
    iata: "YX",
    airlineName: "Republic Airways",
    imgUrl: `${baseUrl}/YX.png`,
  },
  {
    id: "13696f43-dce6-4725-b9f6-87a1aaf04d31",
    iata: "FV",
    airlineName: "Rossiya Airlines",
    imgUrl: `${baseUrl}/FV.png`,
  },
  {
    id: "5ff91413-2c20-4a23-9edd-d4e819f1a56a",
    iata: "RG",
    airlineName: "Rotana Jet",
    imgUrl: `${baseUrl}/RG.png`,
  },
  {
    id: "f4ab5ce2-6d7d-4e2f-b104-a1de6da53fc7",
    iata: "AT",
    airlineName: "Royal Air Maroc",
    imgUrl: `${baseUrl}/AT.png`,
  },
  {
    id: "6b26c03d-4e99-4fce-9554-4ba8ad965a54",
    iata: "AT",
    airlineName: "Royal Air Maroc Express",
    imgUrl: `${baseUrl}/AT.png`,
  },
  {
    id: "50e6263c-cece-4e0e-b0b9-c084b255098e",
    iata: "2P",
    airlineName: "Royal Air Philippines",
    imgUrl: `${baseUrl}/2P.png`,
  },
  {
    id: "2c159230-d84d-4b54-bff5-079f34907d25",
    iata: "BI",
    airlineName: "Royal Brunei Airlines",
    imgUrl: `${baseUrl}/BI.png`,
  },
  {
    id: "774063ec-755d-4d57-8648-68c6f109578b",
    iata: "RJ",
    airlineName: "Royal Jordanian",
    imgUrl: `${baseUrl}/RJ.png`,
  },
  {
    id: "f3fff5f7-cbec-4ec3-88a4-65aabf456277",
    iata: "DR",
    airlineName: "Ruili Airlines",
    imgUrl: `${baseUrl}/DR.png`,
  },
  {
    id: "55d0de28-fa5a-470d-a95c-59d4cf204ee6",
    iata: "7R",
    airlineName: "RusLine",
    imgUrl: `${baseUrl}/7R.png`,
  },
  {
    id: "e1c6a820-5f38-45a1-9543-7246be8f708b",
    iata: "5R",
    airlineName: "Rutaca Airlines",
    imgUrl: `${baseUrl}/5R.png`,
  },
  {
    id: "e2dc3e3a-5ba8-4b4f-8722-ebcc2fd89a60",
    iata: "WB",
    airlineName: "RwandAir",
    imgUrl: `${baseUrl}/WB.png`,
  },
  {
    id: "bbf1e218-945a-4abe-8ec8-1d042707bf56",
    iata: "FR",
    airlineName: "Ryanair",
    imgUrl: `${baseUrl}/FR.png`,
  },
  {
    id: "78306c9e-6741-497b-8b95-00d005e97850",
    iata: "RK",
    airlineName: "Ryanair UK",
    imgUrl: `${baseUrl}/RK.png`,
  },
  {
    id: "20f6567c-a326-4c1a-9b7f-c785b48efe8d",
    iata: "S7",
    airlineName: "S7 Airlines",
    imgUrl: `${baseUrl}/S7.png`,
  },
  {
    id: "192ae228-bcef-45f6-8750-efabed843b4f",
    iata: "RZ",
    airlineName: "SANSA",
    imgUrl: `${baseUrl}/RZ.png`,
  },
  {
    id: "80ffd852-479e-4da3-ae2b-45d47b0aa300",
    iata: "SL",
    airlineName: "SAS Connect",
    imgUrl: `${baseUrl}/SL.png`,
  },
  {
    id: "07722726-d2e1-4bad-a9f5-4862b952d4ca",
    iata: "SK",
    airlineName: "SAS Scandinavian",
    imgUrl: `${baseUrl}/SK.png`,
  },
  {
    id: "3bf14d18-c089-4347-a410-758bbf6ae22c",
    iata: "SP",
    airlineName: "SATA Air Acores",
    imgUrl: `${baseUrl}/SP.png`,
  },
  {
    id: "98592acd-482b-4c7c-b7dd-c0145224aa47",
    iata: "9R",
    airlineName: "SATENA",
    imgUrl: `${baseUrl}/9R.png`,
  },
  {
    id: "3541765b-7302-47e6-8d22-0b9bb2dd8011",
    iata: "DV",
    airlineName: "SCAT Airlines",
    imgUrl: `${baseUrl}/DV.png`,
  },
  {
    id: "deda0c4b-45b1-46ea-9667-b3c035244532",
    iata: "XO",
    airlineName: "SEAir International",
    imgUrl: `${baseUrl}/XO.png`,
  },
  {
    id: "bde5d97c-d852-4a07-a3ef-df44521c9c48",
    iata: "JX",
    airlineName: "STARLUX Airlines",
    imgUrl: `${baseUrl}/JX.png`,
  },
  {
    id: "58212db6-47b8-45be-92ed-df6dff732552",
    iata: "8F",
    airlineName: "STP Airways",
    imgUrl: `${baseUrl}/8F.png`,
  },
  {
    id: "cc34dcc9-830a-43ef-83d5-f9e1c4127825",
    iata: "LX",
    airlineName: "SWISS",
    imgUrl: `${baseUrl}/LX.png`,
  },
  {
    id: "8b12123f-02e8-4b14-9a58-6994d4cf7a94",
    iata: "F2",
    airlineName: "Safarilink Aviation",
    imgUrl: `${baseUrl}/F2.png`,
  },
  {
    id: "8bb50a9b-77dc-45f0-9b6c-6452e07a8089",
    iata: "OV",
    airlineName: "SalamAir",
    imgUrl: `${baseUrl}/OV.png`,
  },
  {
    id: "b25e902a-c8bd-4d55-81d5-cbbebed4860a",
    iata: "OL",
    airlineName: "Samoa Airways",
    imgUrl: `${baseUrl}/OL.png`,
  },
  {
    id: "46c2d763-0399-4987-9695-2009197ce222",
    iata: "TR",
    airlineName: "Scoot",
    imgUrl: `${baseUrl}/TR.png`,
  },
  {
    id: "9a1b755e-91a3-46e8-9972-a8a32b6adda4",
    iata: "BB",
    airlineName: "Seaborne Airlines",
    imgUrl: `${baseUrl}/BB.png`,
  },
  {
    id: "d8bfa80a-a526-4541-b5e4-b7843d5e4b32",
    iata: "IS",
    airlineName: "Sepehran Airlines",
    imgUrl: `${baseUrl}/IS.png`,
  },
  {
    id: "2d5256e5-76a2-46df-9402-cedb829cc5a9",
    iata: "ER",
    airlineName: "Serene Air",
    imgUrl: `${baseUrl}/ER.png`,
  },
  {
    id: "2197cbf7-69ec-4a02-bcb0-c26d26484a9d",
    iata: "D2",
    airlineName: "Severstal AirCompany",
    imgUrl: `${baseUrl}/D2.png`,
  },
  {
    id: "3b5e9d9d-edea-4155-9323-5f86fbab5a32",
    iata: "SC",
    airlineName: "Shandong Airlines",
    imgUrl: `${baseUrl}/SC.png`,
  },
  {
    id: "99246746-22df-45e1-8444-6ab8a4eec690",
    iata: "FM",
    airlineName: "Shanghai Airlines",
    imgUrl: `${baseUrl}/FM.png`,
  },
  {
    id: "cfc43fac-d249-4085-b040-fcb163b07e0a",
    iata: "SH",
    airlineName: "Sharp Airlines",
    imgUrl: `${baseUrl}/SH.png`,
  },
  {
    id: "551fc446-66a8-4e62-b832-7e1e6f464507",
    iata: "ZH",
    airlineName: "Shenzhen Airlines",
    imgUrl: `${baseUrl}/ZH.png`,
  },
  {
    id: "39d5ee0b-03d6-4720-bc17-25c2ae68d3a0",
    iata: "5G",
    airlineName: "Shirak Avia",
    imgUrl: `${baseUrl}/5G.png`,
  },
  {
    id: "df8990cd-88fc-47f0-b973-7764a8630a16",
    iata: "N9",
    airlineName: "Shree Airlines",
    imgUrl: `${baseUrl}/N9.png`,
  },
  {
    id: "a5e19e1e-2cd0-460c-b395-db432e834301",
    iata: "3U",
    airlineName: "Sichuan Airlines",
    imgUrl: `${baseUrl}/3U.png`,
  },
  {
    id: "4cd053e3-8769-4ad2-9f1e-72357e8467c6",
    iata: "3M",
    airlineName: "Silver Airways",
    imgUrl: `${baseUrl}/3M.png`,
  },
  {
    id: "410ee084-6da8-4ad9-a9f0-86a9771a82fe",
    iata: "SQ",
    airlineName: "Singapore Airlines",
    imgUrl: `${baseUrl}/SQ.png`,
  },
  {
    id: "93ec1856-3f69-4243-bb01-80c7e15c63dd",
    iata: "HK",
    airlineName: "Skippers Aviation",
    imgUrl: `${baseUrl}/HK.png`,
  },
  {
    id: "8f121fa9-239d-4720-9599-bb736e79e208",
    iata: "H2",
    airlineName: "Sky Airline",
    imgUrl: `${baseUrl}/H2.png`,
  },
  {
    id: "11cf38b3-1907-453d-8f12-2c61bef630af",
    iata: "H8",
    airlineName: "Sky Airline Peru",
    imgUrl: `${baseUrl}/H8.png`,
  },
  {
    id: "f6345584-c11e-467c-837c-5c5f59f56f64",
    iata: "ZA",
    airlineName: "Sky Angkor Airlines",
    imgUrl: `${baseUrl}/ZA.png`,
  },
  {
    id: "cf14e893-f41e-459a-a71c-7a322a5595c6",
    iata: "RD",
    airlineName: "Sky Cana",
    imgUrl: `${baseUrl}/RD.png`,
  },
  {
    id: "05c8f201-af33-4816-91c6-bd26dc21e547",
    iata: "GQ",
    airlineName: "Sky Express Greece",
    imgUrl: `${baseUrl}/GQ.png`,
  },
  {
    id: "44f729b0-cb2c-4648-93e8-81694ce60f7b",
    iata: "DO",
    airlineName: "Sky High",
    imgUrl: `${baseUrl}/DO.png`,
  },
  {
    id: "827bac39-140a-459e-8a23-a921fdc347c7",
    iata: "PQ",
    airlineName: "SkyUp Airlines",
    imgUrl: `${baseUrl}/PQ.png`,
  },
  {
    id: "b13b3d76-5cd4-4d92-8ac7-f129e525f08e",
    iata: "OO",
    airlineName: "SkyWest Airlines",
    imgUrl: `${baseUrl}/OO.png`,
  },
  {
    id: "06f30794-3c34-42dc-b744-a7b2ece3a1e4",
    iata: "QU",
    airlineName: "Skyline Express Airline",
    imgUrl: `${baseUrl}/QU.png`,
  },
  {
    id: "eca46293-a761-4c9c-bbb5-f7ac942b1d82",
    iata: "BC",
    airlineName: "Skymark Airlines",
    imgUrl: `${baseUrl}/BC.png`,
  },
  {
    id: "b0b6d41e-5ff2-4906-941d-703eb276cf7e",
    iata: "QN",
    airlineName: "Skytrans",
    imgUrl: `${baseUrl}/QN.png`,
  },
  {
    id: "ec415fd4-b9af-4477-b8e7-db00314d43b7",
    iata: "OW",
    airlineName: "Skyward Express",
    imgUrl: `${baseUrl}/OW.png`,
  },
  {
    id: "00d09a72-e6f9-4d26-923b-9afe5191beb2",
    iata: "5N",
    airlineName: "SmartAvia",
    imgUrl: `${baseUrl}/5N.png`,
  },
  {
    id: "acd59ef5-547f-4c40-98a0-2bc5122da89a",
    iata: "6Y",
    airlineName: "SmartLynx Airlines Estonia",
    imgUrl: `${baseUrl}/6Y.png`,
  },
  {
    id: "c96a8f95-6475-41c7-85cc-775a4d081377",
    iata: "QS",
    airlineName: "SmartWings Czechia",
    imgUrl: `${baseUrl}/QS.png`,
  },
  {
    id: "fa2720fc-5f3c-43c5-b85e-6e580db2d954",
    iata: "7O",
    airlineName: "SmartWings Hungary",
    imgUrl: `${baseUrl}/7O.png`,
  },
  {
    id: "7c7f6bca-e356-49d8-823c-466e29e7ffb4",
    iata: "3Z",
    airlineName: "SmartWings Poland",
    imgUrl: `${baseUrl}/3Z.png`,
  },
  {
    id: "2950139d-0156-47b2-b540-a1c1742534d8",
    iata: "6D",
    airlineName: "SmartWings Slovakia",
    imgUrl: `${baseUrl}/6D.png`,
  },
  {
    id: "78a5da06-e3ca-4adf-b16a-681d9acb40ba",
    iata: "6J",
    airlineName: "Solaseed Air",
    imgUrl: `${baseUrl}/6J.png`,
  },
  {
    id: "e1a76d70-8aff-4db7-8811-8336e84a67c2",
    iata: "IE",
    airlineName: "Solomon Airlines",
    imgUrl: `${baseUrl}/IE.png`,
  },
  {
    id: "55377fa3-f8e7-4a64-bccd-9058f2b48da7",
    iata: "SZ",
    airlineName: "Somon Air",
    imgUrl: `${baseUrl}/SZ.png`,
  },
  {
    id: "0599d6ec-f16d-40e8-9dea-6d8cd5b1bd5f",
    iata: "S8",
    airlineName: "Sounds Air",
    imgUrl: `${baseUrl}/S8.png`,
  },
  {
    id: "f7c996a8-b3b8-4264-b6c5-020692290537",
    iata: "SA",
    airlineName: "South African Airways",
    imgUrl: `${baseUrl}/SA.png`,
  },
  {
    id: "7b4123a8-94c4-4cab-9785-a2303ec31897",
    iata: "9X",
    airlineName: "Southern Airways Express",
    imgUrl: `${baseUrl}/9X.png`,
  },
  {
    id: "eb1dc1db-3442-4a73-b234-1b938a554c08",
    iata: "WN",
    airlineName: "Southwest Airlines",
    imgUrl: `${baseUrl}/WN.png`,
  },
  {
    id: "8a00967a-8457-42e1-87db-bb08cc9c4c4e",
    iata: "2S",
    airlineName: "Southwind airlines",
    imgUrl: `${baseUrl}/2S.png`,
  },
  {
    id: "c8d437b2-a20f-47ee-8e3a-d1c64f51e82f",
    iata: "SG",
    airlineName: "SpiceJet",
    imgUrl: `${baseUrl}/SG.png`,
  },
  {
    id: "d04df673-5c20-4475-8ded-017c01a2c433",
    iata: "NK",
    airlineName: "Spirit Airlines",
    imgUrl: `${baseUrl}/NK.png`,
  },
  {
    id: "11906228-9f83-4233-91cc-edcd5e7ccf55",
    iata: "9C",
    airlineName: "Spring Airlines",
    imgUrl: `${baseUrl}/9C.png`,
  },
  {
    id: "43d1bbc6-377f-4e9a-9a59-74579eab0ed5",
    iata: "IJ",
    airlineName: "Spring Japan",
    imgUrl: `${baseUrl}/IJ.png`,
  },
  {
    id: "9a9e35c1-d61b-4fb0-82f2-df997f3b57d6",
    iata: "P8",
    airlineName: "SprintAir",
    imgUrl: `${baseUrl}/P8.png`,
  },
  {
    id: "7b1770e8-db42-47fe-9316-5db44867d12f",
    iata: "UL",
    airlineName: "SriLankan Airlines",
    imgUrl: `${baseUrl}/UL.png`,
  },
  {
    id: "7f8ad3e2-00e9-4dc7-b10a-d85acfac3714",
    iata: "SJ",
    airlineName: "Sriwijaya Air",
    imgUrl: `${baseUrl}/SJ.png`,
  },
  {
    id: "78a632fa-39db-4fa6-a974-6519796c9c82",
    iata: "PV",
    airlineName: "St Barth Commuter",
    imgUrl: `${baseUrl}/PV.png`,
  },
  {
    id: "93867472-13fd-4ef9-99b4-3d459d24c328",
    iata: "2I",
    airlineName: "Star Peru",
    imgUrl: `${baseUrl}/2I.png`,
  },
  {
    id: "ae315afd-ab94-4606-9d6f-b1faa27c0e94",
    iata: "SD",
    airlineName: "Sudan Airways",
    imgUrl: `${baseUrl}/SD.png`,
  },
  {
    id: "f5f4ba50-484d-4346-96eb-20a965adac7b",
    iata: "SY",
    airlineName: "Sun Country Airlines",
    imgUrl: `${baseUrl}/SY.png`,
  },
  {
    id: "5e417c6c-ba64-4c15-b869-023699fe9bfc",
    iata: "XQ",
    airlineName: "SunExpress",
    imgUrl: `${baseUrl}/XQ.png`,
  },
  {
    id: "04350d15-e66c-42d5-acaa-ba0e6db97fed",
    iata: "DK",
    airlineName: "Sunclass Airlines",
    imgUrl: `${baseUrl}/DK.png`,
  },
  {
    id: "4f2fc3e9-0681-45e3-80d6-3dec14e6fd6e",
    iata: "SR",
    airlineName: "SundAir",
    imgUrl: `${baseUrl}/SR.png`,
  },
  {
    id: "19af5163-c12e-4161-b573-77e7c1efd429",
    iata: "2R",
    airlineName: "Sunlight Air",
    imgUrl: `${baseUrl}/2R.png`,
  },
  {
    id: "c92e3472-5f63-42b5-8e04-5b5d76798f8c",
    iata: "S6",
    airlineName: "Sunrise Airways",
    imgUrl: `${baseUrl}/S6.png`,
  },
  {
    id: "a3359363-db4c-4fc0-82a6-37a28bb4c093",
    iata: "D6",
    airlineName: "Sunrise Dominicana",
    imgUrl: `${baseUrl}/D6.png`,
  },
  {
    id: "2c173f08-1e20-4a15-a752-bc194ea8dd50",
    iata: "WG",
    airlineName: "Sunwing Airlines",
    imgUrl: `${baseUrl}/WG.png`,
  },
  {
    id: "14bc75d5-c746-493d-8cdd-05cae1e2cc00",
    iata: "Y8",
    airlineName: "Suparna Airlines",
    imgUrl: `${baseUrl}/Y8.png`,
  },
  {
    id: "fb7256c1-8540-48ab-b9b8-39a2116277f2",
    iata: "IU",
    airlineName: "Super Air Jet",
    imgUrl: `${baseUrl}/IU.png`,
  },
  {
    id: "4bc98fd5-82ce-4815-b1be-1e8ea63a31d5",
    iata: "PY",
    airlineName: "Surinam Airways",
    imgUrl: `${baseUrl}/PY.png`,
  },
  {
    id: "77bd933e-0f88-4a96-ae9c-4a49a3029f25",
    iata: "7E",
    airlineName: "Sylt Air",
    imgUrl: `${baseUrl}/7E.png`,
  },
  {
    id: "00003746-28cd-4df9-a5e7-0edd431ccd55",
    iata: "TW",
    airlineName: "T'way Air",
    imgUrl: `${baseUrl}/TW.png`,
  },
  {
    id: "856d31d2-7e26-427b-98d4-164e514de0aa",
    iata: "DT",
    airlineName: "TAAG Angola",
    imgUrl: `${baseUrl}/DT.png`,
  },
  {
    id: "7dcaf105-523c-47ef-959b-7eca272f50fd",
    iata: "TP",
    airlineName: "TAP Air Portugal",
    imgUrl: `${baseUrl}/TP.png`,
  },
  {
    id: "8ff97cee-7bff-4d94-8f1b-3980b14b273d",
    iata: "TG",
    airlineName: "THAI Airways",
    imgUrl: `${baseUrl}/TG.png`,
  },
  {
    id: "7b7572af-e9e0-4e7c-b587-50b8659f01d5",
    iata: "BY",
    airlineName: "TUI Airways",
    imgUrl: `${baseUrl}/BY.png`,
  },
  {
    id: "c7eaca7b-75a9-4e43-9eb5-039e26bdfcdc",
    iata: "TB",
    airlineName: "TUI fly Belgium",
    imgUrl: `${baseUrl}/TB.png`,
  },
  {
    id: "d06aa3f0-26dd-4270-9a60-714530f6090e",
    iata: "6B",
    airlineName: "TUIfly Nordiс",
    imgUrl: `${baseUrl}/6B.png`,
  },
  {
    id: "394d60b9-df8a-412c-be27-7a6cc7745a77",
    iata: "7J",
    airlineName: "Tajik Air",
    imgUrl: `${baseUrl}/7J.png`,
  },
  {
    id: "f83a55d5-18f7-4dd0-a3be-e5adedf01f3a",
    iata: "K3",
    airlineName: "Taquan Air",
    imgUrl: `${baseUrl}/K3.png`,
  },
  {
    id: "90e4f222-65cd-412a-97d1-b729b02a1eda",
    iata: "3T",
    airlineName: "Tarco Aviation",
    imgUrl: `${baseUrl}/3T.png`,
  },
  {
    id: "75cc7b42-e0a0-4fbd-acc2-c456a23c074d",
    iata: "RO",
    airlineName: "Tarom",
    imgUrl: `${baseUrl}/RO.png`,
  },
  {
    id: "5ce88f1d-a0dc-41df-b6cf-84763f79a1c4",
    iata: "SF",
    airlineName: "Tassili Airlines",
    imgUrl: `${baseUrl}/SF.png`,
  },
  {
    id: "5593c22f-fab6-47ef-980a-a50cd97ceb28",
    iata: "TD",
    airlineName: "Tbilisi Airways",
    imgUrl: `${baseUrl}/TD.png`,
  },
  {
    id: "e2d35189-a9e1-416a-a29b-78718e2d0545",
    iata: "K9",
    airlineName: "Tez Jet Airlines",
    imgUrl: `${baseUrl}/K9.png`,
  },
  {
    id: "43f4b3b6-8271-4cf1-8e97-a7326f0710a1",
    iata: "FD",
    airlineName: "Thai AirAsia",
    imgUrl: `${baseUrl}/FD.png`,
  },
  {
    id: "14506041-2ab4-4cf2-947e-15b1a5f3453f",
    iata: "XJ",
    airlineName: "Thai AirAsia X",
    imgUrl: `${baseUrl}/XJ.png`,
  },
  {
    id: "777d1540-7933-4e53-86b5-c1b21dc8929e",
    iata: "VZ",
    airlineName: "Thai Vietjet Air",
    imgUrl: `${baseUrl}/VZ.png`,
  },
  {
    id: "e872b3de-78de-4e09-8572-8898e4c8eda6",
    iata: "GS",
    airlineName: "Tianjin Airlines",
    imgUrl: `${baseUrl}/GS.png`,
  },
  {
    id: "0c55edf7-47ba-4946-986f-4e4d294303d7",
    iata: "TV",
    airlineName: "Tibet Airlines",
    imgUrl: `${baseUrl}/TV.png`,
  },
  {
    id: "f7b4892a-6ff2-47f6-9eeb-9a020af04d40",
    iata: "IT",
    airlineName: "Tigerair Taiwan",
    imgUrl: `${baseUrl}/IT.png`,
  },
  {
    id: "d0e3d7eb-c2fe-439b-9d3a-249f6d121162",
    iata: "C3",
    airlineName: "Trade Air",
    imgUrl: `${baseUrl}/C3.png`,
  },
  {
    id: "7204c763-e086-4a62-89f3-2cc361620672",
    iata: "TJ",
    airlineName: "Tradewind Aviation",
    imgUrl: `${baseUrl}/TJ.png`,
  },
  {
    id: "0f647c79-2a58-4687-81be-534a3215a9a4",
    iata: "Q8",
    airlineName: "Trans Air Congo",
    imgUrl: `${baseUrl}/Q8.png`,
  },
  {
    id: "53edd7f8-9477-4bc7-b692-3e260f0b0dd9",
    iata: "HC",
    airlineName: "Transair Senegal",
    imgUrl: `${baseUrl}/HC.png`,
  },
  {
    id: "d2a17956-8c05-4500-9206-f079d7ba08e2",
    iata: "HV",
    airlineName: "Transavia",
    imgUrl: `${baseUrl}/HV.png`,
  },
  {
    id: "7ec8b1f3-09e7-4f7d-8e93-a3a400e33420",
    iata: "TO",
    airlineName: "Transavia France",
    imgUrl: `${baseUrl}/TO.png`,
  },
  {
    id: "b1109999-3e60-44de-a071-e43d04ea2694",
    iata: "8B",
    airlineName: "Transnusa",
    imgUrl: `${baseUrl}/8B.png`,
  },
  {
    id: "cd4270b0-1b7d-4420-a35b-296201d65ad5",
    iata: "IL",
    airlineName: "Trigana Air Service",
    imgUrl: `${baseUrl}/IL.png`,
  },
  {
    id: "9bcc01b5-69d7-4892-bbdc-04206f1be041",
    iata: "9N",
    airlineName: "Tropic Air",
    imgUrl: `${baseUrl}/9N.png`,
  },
  {
    id: "751a4c66-2847-4699-b9e6-c9a835c31b9d",
    iata: "TI",
    airlineName: "Tropic Ocean Airways",
    imgUrl: `${baseUrl}/TI.png`,
  },
  {
    id: "21089c1c-d915-49eb-a2c3-79c814fd86e1",
    iata: "TU",
    airlineName: "Tunisair",
    imgUrl: `${baseUrl}/TU.png`,
  },
  {
    id: "92e17acf-0f42-4cb0-94c7-b58bd7dfa0c0",
    iata: "UG",
    airlineName: "Tunisair Express",
    imgUrl: `${baseUrl}/UG.png`,
  },
  {
    id: "0ce2d8e5-54ef-4032-aa8e-63aa9535c628",
    iata: "TK",
    airlineName: "Turkish Airlines",
    imgUrl: `${baseUrl}/TK.png`,
  },
  {
    id: "2e6e5c68-2a01-48af-a421-2caae38ffb7e",
    iata: "T5",
    airlineName: "Turkmenistan Airlines",
    imgUrl: `${baseUrl}/T5.png`,
  },
  {
    id: "424bdf06-b61b-460c-b6ff-141ea3dd7897",
    iata: "T9",
    airlineName: "Turpial Airlines",
    imgUrl: `${baseUrl}/T9.png`,
  },
  {
    id: "ab6cc6a6-a56d-481c-88ee-8397f2cae674",
    iata: "U8",
    airlineName: "Tus Airways",
    imgUrl: `${baseUrl}/U8.png`,
  },
  {
    id: "29fd9e73-7810-45d6-86c8-de1594e2b42a",
    iata: "T7",
    airlineName: "Twin Jet",
    imgUrl: `${baseUrl}/T7.png`,
  },
  {
    id: "53c9de32-c8d9-4131-a2f7-d0d26b9bb458",
    iata: "PS",
    airlineName: "UIA",
    imgUrl: `${baseUrl}/PS.png`,
  },
  {
    id: "31bb8c96-77ab-40e9-a12d-21edebc1abb4",
    iata: "UD",
    airlineName: "UR Airlines",
    imgUrl: `${baseUrl}/UD.png`,
  },
  {
    id: "1371e8d7-b6ec-4629-9309-951ebf052eb2",
    iata: "BS",
    airlineName: "US-Bangla Airlines",
    imgUrl: `${baseUrl}/BS.png`,
  },
  {
    id: "6f676ba2-11b0-44c9-a919-9789922a2f26",
    iata: "RT",
    airlineName: "UVT Aero",
    imgUrl: `${baseUrl}/RT.png`,
  },
  {
    id: "d38f80ca-9be1-425f-b9b7-a399571de1b8",
    iata: "UR",
    airlineName: "Uganda Airlines",
    imgUrl: `${baseUrl}/UR.png`,
  },
  {
    id: "ebabafdc-6ca8-48b5-ad32-455f61d27217",
    iata: "B7",
    airlineName: "Uni Air",
    imgUrl: `${baseUrl}/B7.png`,
  },
  {
    id: "5b77485e-8a66-4b4a-a006-acea500c01a0",
    iata: "UA",
    airlineName: "United Airlines",
    imgUrl: `${baseUrl}/UA.png`,
  },
  {
    id: "8b844683-b37a-4294-857c-b14593830c38",
    iata: "UN",
    airlineName: "United Nigeria Airlines",
    imgUrl: `${baseUrl}/UN.png`,
  },
  {
    id: "9b01a01f-f259-48df-8c85-883e0928c2a2",
    iata: "U6",
    airlineName: "Ural Airlines",
    imgUrl: `${baseUrl}/U6.png`,
  },
  {
    id: "fdb76830-c871-4aa7-a1cc-ca0d205e8638",
    iata: "UQ",
    airlineName: "Urumqi Air",
    imgUrl: `${baseUrl}/UQ.png`,
  },
  {
    id: "87c15d35-8786-44a3-a8ff-7946f5b2e392",
    iata: "UT",
    airlineName: "Utair",
    imgUrl: `${baseUrl}/UT.png`,
  },
  {
    id: "1d4f549f-6bfe-407c-82a7-f13a65e02d9b",
    iata: "HY",
    airlineName: "Uzbekistan Airways",
    imgUrl: `${baseUrl}/HY.png`,
  },
  {
    id: "5ec4ed7b-1908-4ba4-8f13-5c45c66e65d8",
    iata: "V4",
    airlineName: "Vieques Air Link",
    imgUrl: `${baseUrl}/V4.png`,
  },
  {
    id: "e733c09a-2244-49d1-80ec-b9e371cb44c9",
    iata: "VJ",
    airlineName: "Vietjet",
    imgUrl: `${baseUrl}/VJ.png`,
  },
  {
    id: "2d8ff6e9-5759-4021-8033-6fdd1ae9448d",
    iata: "VN",
    airlineName: "Vietnam Airlines",
    imgUrl: `${baseUrl}/VN.png`,
  },
  {
    id: "08467bd0-b84f-4799-94b2-7f3e28441719",
    iata: "VU",
    airlineName: "Vietravel Airlines",
    imgUrl: `${baseUrl}/VU.png`,
  },
  {
    id: "4f4f291f-9e6f-477a-9264-be41a1d5933d",
    iata: "VP",
    airlineName: "Villa Air",
    imgUrl: `${baseUrl}/VP.png`,
  },
  {
    id: "4cd95e77-9a43-43bd-8995-33ed3450390d",
    iata: "VS",
    airlineName: "Virgin Atlantic",
    imgUrl: `${baseUrl}/VS.png`,
  },
  {
    id: "e1133aed-d540-433b-9ada-72abfae8ef6a",
    iata: "VS",
    airlineName: "Virgin Atlantic International",
    imgUrl: `${baseUrl}/VS.png`,
  },
  {
    id: "b7928786-c05a-4f2c-a03f-16157b8657a6",
    iata: "VA",
    airlineName: "Virgin Australia",
    imgUrl: `${baseUrl}/VA.png`,
  },
  {
    id: "237230dc-ef78-43e8-bc5f-54248b2fad3f",
    iata: "Q6",
    airlineName: "Volaris Costa Rica",
    imgUrl: `${baseUrl}/Q6.png`,
  },
  {
    id: "7c55f5c2-565f-4a27-9cc8-02456c594dc2",
    iata: "N3",
    airlineName: "Volaris El Salvador",
    imgUrl: `${baseUrl}/N3.png`,
  },
  {
    id: "c84114f1-112c-43b4-a222-3d92b07466ea",
    iata: "V7",
    airlineName: "Volotea",
    imgUrl: `${baseUrl}/V7.png`,
  },
  {
    id: "054c3a8f-6fc2-489b-9473-b345177fc29e",
    iata: "VY",
    airlineName: "Vueling",
    imgUrl: `${baseUrl}/VY.png`,
  },
  {
    id: "929d91a0-862c-4ee8-8afd-02168c725277",
    iata: "EB",
    airlineName: "Wamos Air",
    imgUrl: `${baseUrl}/EB.png`,
  },
  {
    id: "55c345a0-e3a4-45ce-a752-91c6cb83968c",
    iata: "4W",
    airlineName: "Warbelows Air",
    imgUrl: `${baseUrl}/4W.png`,
  },
  {
    id: "9304c581-cb58-4c95-859c-38fef00d1485",
    iata: "WP",
    airlineName: "Wasaya Airways",
    imgUrl: `${baseUrl}/WP.png`,
  },
  {
    id: "5004561e-0ab7-45e2-8abc-d1629571aa1e",
    iata: "PN",
    airlineName: "West Air",
    imgUrl: `${baseUrl}/PN.png`,
  },
  {
    id: "45efeebc-e643-41c7-81e0-b58aafdc79c2",
    iata: "WS",
    airlineName: "WestJet",
    imgUrl: `${baseUrl}/WS.png`,
  },
  {
    id: "c92bfff5-24c2-4905-98fd-5c112f2e6ef0",
    iata: "WR",
    airlineName: "WestJet Encore",
    imgUrl: `${baseUrl}/WR.png`,
  },
  {
    id: "486103af-6a60-4413-9c2c-1e8e64be8e00",
    iata: "WU",
    airlineName: "Western Air (Bahamas)",
    imgUrl: `${baseUrl}/WU.png`,
  },
  {
    id: "b56d6b12-ec26-43d9-ab08-6a7295790d09",
    iata: "WI",
    airlineName: "White Airways",
    imgUrl: `${baseUrl}/WI.png`,
  },
  {
    id: "3a8ba5ec-2829-4a39-af57-571d0847117b",
    iata: "WF",
    airlineName: "Widerøe",
    imgUrl: `${baseUrl}/WF.png`,
  },
  {
    id: "7fd82aaa-36c8-40fc-9fb9-2c88b92dc3bf",
    iata: "WM",
    airlineName: "Winair",
    imgUrl: `${baseUrl}/WM.png`,
  },
  {
    id: "40d031e3-b547-45dd-a842-02e81ff33e1d",
    iata: "P5",
    airlineName: "Wingo",
    imgUrl: `${baseUrl}/P5.png`,
  },
  {
    id: "6907e88e-8fbf-4c8b-b401-f74b22b9707b",
    iata: "IW",
    airlineName: "Wings Air",
    imgUrl: `${baseUrl}/IW.png`,
  },
  {
    id: "9643749e-81fe-4d84-8ec0-cca6b3b14f6b",
    iata: "W6",
    airlineName: "Wizz Air",
    imgUrl: `${baseUrl}/W6.png`,
  },
  {
    id: "c4fc8d9a-3f48-4a87-b055-f1093818770c",
    iata: "5W",
    airlineName: "Wizz Air Abu Dhabi",
    imgUrl: `${baseUrl}/5W.png`,
  },
  {
    id: "cfb323f3-522b-4957-9615-c8c8b3569160",
    iata: "W4",
    airlineName: "Wizz Air Malta",
    imgUrl: `${baseUrl}/W4.png`,
  },
  {
    id: "3888317b-61a7-48e3-b8a6-9ce825f7d0a1",
    iata: "W9",
    airlineName: "Wizz Air UK",
    imgUrl: `${baseUrl}/W9.png`,
  },
  {
    id: "d9b85a81-e725-49dc-9fb3-d3ba02243c59",
    iata: "WL",
    airlineName: "World Atlantic Airlines",
    imgUrl: `${baseUrl}/WL.png`,
  },
  {
    id: "21e990eb-8bde-435b-99fc-af6d6108c09f",
    iata: "3P",
    airlineName: "World2Fly (Portugal)",
    imgUrl: `${baseUrl}/3P.png`,
  },
  {
    id: "60e8d9f4-9471-41e7-a255-a3a7caf3e41c",
    iata: "8V",
    airlineName: "Wright Air Service",
    imgUrl: `${baseUrl}/8V.png`,
  },
  {
    id: "c987c9d6-463e-4726-af51-2b1cff49ab0a",
    iata: "MF",
    airlineName: "Xiamen Airlines",
    imgUrl: `${baseUrl}/MF.png`,
  },
  {
    id: "a0f631b5-3342-4627-8639-0e8b9ab2c570",
    iata: "R3",
    airlineName: "Yakutia Airlines",
    imgUrl: `${baseUrl}/R3.png`,
  },
  {
    id: "45b34013-5050-46ce-b621-01bbafb4a3e4",
    iata: "YC",
    airlineName: "Yamal Airlines",
    imgUrl: `${baseUrl}/YC.png`,
  },
  {
    id: "859d2663-a231-4e04-a5c2-47ad36d3fbe9",
    iata: "IY",
    airlineName: "Yemenia",
    imgUrl: `${baseUrl}/IY.png`,
  },
  {
    id: "af45ed6b-f5b7-453b-8030-7ead312cc7c4",
    iata: "YT",
    airlineName: "Yeti Airlines",
    imgUrl: `${baseUrl}/YT.png`,
  },
  {
    id: "9ac2d91e-2dab-4649-a8ea-59690e80a38d",
    iata: "7Z",
    airlineName: "Z Air",
    imgUrl: `${baseUrl}/7Z.png`,
  },
  {
    id: "b0563348-56d2-46e0-98c7-8dda2d47e75b",
    iata: "ZG",
    airlineName: "ZIPAIR",
    imgUrl: `${baseUrl}/ZG.png`,
  },
  {
    id: "5b8864f0-edcd-43ba-9005-99c4e31e78ec",
    iata: "ZN",
    airlineName: "Zambia Airways",
    imgUrl: `${baseUrl}/ZN.png`,
  },
];
