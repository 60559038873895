import React from "react";
import { IoCall, IoLogoWhatsapp, MdEmail } from "../../utils/solidIcons";
import { contactInfo } from "../../dummydata/contactInfo";
import { Link } from "react-router-dom";

const ContactButtons: React.FC = () => {
  const phoneNumber = `${contactInfo.mobileNum}`;
  const whatsappNumber = `${contactInfo.chatNumber}`;
  const email = `${contactInfo.email}`;

  return (
    <div className="fixed w-full lg:hidden bottom-0   z-50 bg-white shadow-[0_0px_10px_5px_rgba(0,0,0,0.3)]; ">
      <div className="flex justify-center ">
        {
          <div className="flex items-center p-2 gap-10 justify-between">
            <div>
              <Link
                to={`tel:${phoneNumber}`}
                className="flex items-center justify-center w-11 h-11 p-3 text-5xl shadow-[0_0px_10px_5px_rgba(0,0,0,0.3)] text-white bg-blue-500 rounded-full hover:bg-blue-600 transition  hover:opacity-100"
              >
                <IoCall />
              </Link>
            </div>
            <div>
              <Link
                to={`https://wa.me/${whatsappNumber}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center w-11 h-11 p-3 text-5xl text-white shadow-[0_0px_10px_5px_rgba(0,0,0,0.3)] bg-green-500 rounded-full hover:bg-green-600 transition  hover:opacity-100"
              >
                <IoLogoWhatsapp />
              </Link>
            </div>
            <div>
              <Link
                to={`mailto:${email}`}
                className="flex items-center justify-center w-11 h-11 p-3 text-5xl text-white shadow-[0_0px_10px_5px_rgba(0,0,0,0.3)] bg-yellow-500 rounded-full hover:bg-yellow-600 transition  hover:opacity-100"
              >
                <MdEmail />
              </Link>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default ContactButtons;
