import React, { useEffect, useState } from "react";
import { Plane2 } from "../utils/icons";
import { IoIosAirplane, IoIosPerson } from "react-icons/io";
import { HiViewList } from "react-icons/hi";
import { IoIosMail } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import Userform from "../components/Form/User.form";
// import PopUpComponent from "../components/FlightDetails.component/PopUp.component";
import PaymentInfoform, { Payment } from "../components/Form/PaymentInfo.form";
import CardBillAddressForm, {
  AddressInfo,
} from "../components/Form/CardBillAddress.form";
import ContactInfoForm, {
  ContactInfo,
} from "../components/Form/ContactInfo.form";
import LoadingComponent from "../components/Loading.component";

import DisclamerCard from "../components/Cards/Disclamer.card";
import FlightDetailsPriceCard from "../components/Cards/FlightDetailsPrice.Card";
import {
  AirportListI,
  PassengerI,
  TravelersI,
} from "../types/flightSearchReducer.types";
import NoDataFoundComponent from "../components/NoDataFound.component";
import {
  AddUserDetails,
  generateBookingNumber,
  validateData,
} from "../utils/ExtraFunction";
import { notifyError, notifySuccess } from "../utils/notifications";
import { FaCreditCard, MdKeyboardBackspace } from "../utils/solidIcons";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet-async";
import { airportList } from "../utils/airportList";

import {
  AirlineImageI,
  AirportI,
  FlightDetailsI,
  FlightSearchDetailsI,
  FlightSegmentsDetailsI,
} from "../types/flightListTypes";
import {
  convertDate,
  convertToAmPmFormat,
  formatDateToStr,
  formatTimeInHoursAndMinutes,
} from "../utils/formatTimeInMinuteToHourandMinute";
import { airlinesImg } from "../utils/airlineImgList";

const ReviewPage = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [noOfTravelers, setNoOfTravelers] = useState<TravelersI>({
    adults: +params.get("adults")!,
    children: +params.get("children")!,
    infants: +params.get("infants")!,
  });

  const CABIN_CLASS_CODE = {
    Economy: "Y",
    PremiumEconomy: "W",
    Business: "C",
    First: "F",
  };

  const [precisePricing, setPrecisePricing] = useState<FlightDetailsI | null>(
    null
  );

  const [userBasicinfo, setUserBasicInfo] = useState<PassengerI[]>([]);
  //TODO: Add payment info if needed
  const [paymentInfo, setPaymentInfo] = useState<Record<string, any>>({});
  const [address, setAddress] = useState<Record<string, any>>({});
  const [contact, setContact] = useState<Record<string, any>>({});
  const [status, setStatus] = useState<
    "loading" | "success" | "error" | "idle"
  >("idle");
  const UserBasicForm = (obj: PassengerI) => {
    setUserBasicInfo((prev: any[]) => {
      // Check if obj already exists to avoid unnecessary state updates
      if (!prev.some((item) => item.passengerIndex === obj.passengerIndex)) {
        return [...prev, obj];
      } else {
        const existingItem = prev.find(
          (item) => item.passengerIndex === obj.passengerIndex
        );

        // Check if the found item has different properties than the new object
        if (
          existingItem &&
          JSON.stringify(existingItem) !== JSON.stringify(obj)
        ) {
          const index = prev.findIndex(
            (item) => item.passengerIndex === obj.passengerIndex
          );

          if (index !== -1) {
            // Create a new array with the updated object, maintaining immutability
            const updatedArray = [...prev];
            updatedArray[index] = { ...obj };
            return updatedArray;
          }
        }
      }
      return prev;
    });
  };

  const handleClick = async () => {
    const results = validateData(contact, address, paymentInfo, userBasicinfo);
    if (results.length !== 0) {
      for (let i = 0; i < results.length; i++) {
        notifyError(results[i]);
        return;
      }
      return;
    }
    const searchDetails =
      localStorage.getItem("flightSearchKey") !== null
        ? JSON.parse(localStorage.getItem("flightSearchKey")!)
        : null;
    const parsedData = searchDetails
      ? JSON.parse(atob(`${searchDetails}`))
      : null;
    const message = await AddUserDetails(
      userBasicinfo,
      paymentInfo,
      address,
      contact,
      parsedData,
      precisePricing
    );
    const userTravelData = {
      bookingNum: generateBookingNumber(),
      ticketIssuedDate: Date.now(),
      userBasicinfo,
      paymentInfo,
      address,
      contact,
      flightData: precisePricing,
    };
    notifySuccess(message);
    Navigate(`/ticket/details?id=${btoa(JSON.stringify(userTravelData))}`);
  };

  // Fetch flight pricing data
  const fetchPricingData = async (
    pricingData: FlightDetailsI,
    searchQuery: FlightSearchDetailsI
  ) => {
    try {
      setStatus("loading");
      let payload = {};
      if (searchQuery.tripType === "oneway") {
        payload = {
          pricing: {
            solutionId: pricingData?.solutionId,
            adults: searchQuery?.noOfTravelers.adults,
            children: searchQuery?.noOfTravelers.children,
            infants: searchQuery?.noOfTravelers.infants,
            cabin:
              CABIN_CLASS_CODE[
                `${searchQuery?.cabinClass}` as keyof typeof CABIN_CLASS_CODE
              ],
            journeys: {
              journey_0:
                pricingData?.outboundTrip && pricingData.outboundTrip.length > 0
                  ? [...pricingData?.outboundTrip]
                  : [],
            },
          },
        };
      } else if (searchQuery.tripType === "roundtrip") {
        payload = {
          pricing: {
            solutionId: pricingData?.solutionId,
            adults: searchQuery?.noOfTravelers.adults,
            children: searchQuery?.noOfTravelers.children,
            infants: searchQuery?.noOfTravelers.infants,
            cabin:
              CABIN_CLASS_CODE[
                `${searchQuery?.cabinClass}` as keyof typeof CABIN_CLASS_CODE
              ],
            journeys: {
              journey_0:
                pricingData?.outboundTrip && pricingData.outboundTrip.length > 0
                  ? [...pricingData?.outboundTrip]
                  : [],
              journey_1:
                pricingData?.inboundTrip && pricingData.inboundTrip.length > 0
                  ? [...pricingData?.inboundTrip]
                  : [],
            },
          },
        };
      }
      const response = await fetch(
        process.env.REACT_APP_FlightPrecisePricingApiRoute as string,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...payload,
          }),
        }
      );
      const responseJson = await response.json();
      // Check if data is present and handle error cases
      if (!responseJson.success || responseJson.data.length === 0) {
        setStatus("error");
        return;
      }
      setPrecisePricing(() => responseJson.data[0]);
      setStatus("success");
    } catch (error) {
      setStatus("error");
      console.error(`${error}`);
    }
  };

  useEffect(() => {
    const pricingData = JSON.parse(`${localStorage.getItem("pricing")}`);
    const searchDetails =
      localStorage.getItem("flightSearchKey") !== null
        ? JSON.parse(localStorage.getItem("flightSearchKey")!)
        : null;
    const parsedData = searchDetails
      ? JSON.parse(atob(`${searchDetails}`))
      : null;
    fetchPricingData(pricingData, parsedData);
  }, [location.search]);

  const handleBackBtnClick = () => {
    const data =
      localStorage.getItem("flightSearchKey") !== null
        ? JSON.parse(localStorage.getItem("flightSearchKey")!)
        : null;

    const parsedData = data ? JSON.parse(atob(`${data}`)) : null;

    if (!parsedData) {
      return;
    }
    if (parsedData.tripType === "roundtrip") {
      Navigate(
        `/flight/search?origin=${parsedData.from.iata_code}&destination=${
          parsedData.to.iata_code
        }&trip-type=${parsedData.tripType}&cabin-class=${
          parsedData.cabinClass
        }&adults=${parsedData.noOfTravelers.adults}&children=${
          parsedData.noOfTravelers.children
        }&infants=${
          parsedData.noOfTravelers.infants
        }&departure-date=${convertDate(
          parsedData.departureDate
        )}&return-date=${convertDate(parsedData.returnDate)}`
      );
    } else if (parsedData.tripType === "oneway") {
      Navigate(
        `/flight/search?origin=${parsedData.from.iata_code}&destination=${
          parsedData.to.iata_code
        }&trip-type=${parsedData.tripType}&cabin-class=${
          parsedData.cabinClass
        }&adults=${parsedData.noOfTravelers.adults}&children=${
          parsedData.noOfTravelers.children
        }&infants=${
          parsedData.noOfTravelers.infants
        }&departure-date=${convertDate(parsedData.departureDate)}`
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Flight Details</title>
        <meta name="description" content="flight details" />
        <meta name="keywords" content="flight,booking,contact us" />
        <meta property="og:title" content="Dreams tavel and tours" />
        <meta
          property="og:description"
          content="contact us for flight booking"
        />
        <meta property="og:type" content="article" />
      </Helmet>
      <ScrollToTop />
      <div className=" w-full relative pt-[5rem] flex flex-col lg:flex-row justify-center">
        <div className=" w-full lg:absolute left-14 lg:w-[65vw] h-full rounded-md px-2">
          <button
            className="flex items-center gap-x-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white px-2 py-2 my-2"
            onClick={handleBackBtnClick}
          >
            <MdKeyboardBackspace />
            <span className="text-xs">back to flight search</span>
          </button>
          {/**flight details  */}

          <div className=" w-full flex flex-col rounded-md border-[1px] items-center shadow-md h-fit">
            <div className="w-full bg-gradient-to-l from-[#ec2027] to-[#c81212] flex items-center px-4 gap-4 h-[3rem] rounded-t-md">
              <img src={Plane2} alt="logo" className="h-[2.5rem] md:h-[2rem]" />
              <h2 className="text-sm md:text-lg font-medium text-white">
                Flight Details
              </h2>
            </div>
            {status === "loading" && <LoadingComponent />}
            {status === "error" && (
              <div className="p-5">
                <NoDataFoundComponent />
              </div>
            )}
            {status === "success" && (
              <div className="flex flex-col w-full px-5">
                <div className="flex flex-col gap-y-5 border-b py-5">
                  {precisePricing?.outboundTrip &&
                    precisePricing.outboundTrip.length > 0 && (
                      <p className="flex items-center gap-x-2 text-xs text-gray-600 capitalize border-b pb-2">
                        <span className="text-sm font-semibold">
                          {
                            airportList.find(
                              (airport: AirportI) =>
                                precisePricing.outboundTrip[0].departure ===
                                airport.iata_code
                            )?.city
                          }{" "}
                          to{" "}
                          {
                            airportList.find(
                              (airport: AirportI) =>
                                precisePricing.outboundTrip[
                                  precisePricing.outboundTrip.length - 1
                                ].arrival === airport.iata_code
                            )?.city
                          }
                        </span>
                        <span>(Departure Trip)</span>
                      </p>
                    )}
                  {precisePricing?.outboundTrip &&
                    precisePricing.outboundTrip.length > 0 &&
                    precisePricing.outboundTrip.map(
                      (details: FlightSegmentsDetailsI) => (
                        <div
                          key={details.segmentId}
                          className="flex flex-col gap-y-5 border-b last:border-b-0 pb-5"
                        >
                          <div>
                            <p className="flex items-center">
                              <span className="text-sm sm:text-lg font-semibold text-gray-700 capitalize">
                                {
                                  airportList.find(
                                    (airport: AirportI) =>
                                      details.departure === airport.iata_code
                                  )?.city
                                }{" "}
                                to{" "}
                                {
                                  airportList.find(
                                    (airport: AirportI) =>
                                      details.arrival === airport.iata_code
                                  )?.city
                                }
                                &nbsp;-&nbsp;
                              </span>
                              <span className="text-sm sm:text-lg font-light text-gray-700 capitalize">
                                {formatDateToStr(details.departureDate)}
                              </span>
                            </p>
                            <p className="flex items-center">
                              <span className="text-xs sm:text-sm font-semibold text-gray-700 capitalize">
                                Travel Time&nbsp;:&nbsp;
                              </span>
                              <span className="text-xs sm:text-sm text-gray-500 capitalize">
                                {formatTimeInHoursAndMinutes(
                                  details.journeyTime
                                )}{" "}
                                , non stop
                              </span>
                            </p>
                          </div>
                          <div className="flex flex-row items-center justify-between">
                            <div className="flex w-[30%] flex-col gap-x-2 text-base sm:text-lg text-gray-700 font-semibold">
                              <p>
                                {convertToAmPmFormat(details.departureTime)}
                              </p>
                              <p className="flex flex-col text-start">
                                <span className="text-gray-600 text-sm font-semibold uppercase">
                                  {details.departure}
                                </span>
                                <span className="text-gray-500 text-sm hidden sm:block font-normal capitalize">
                                  {
                                    airportList.find(
                                      (airport: AirportI) =>
                                        details.departure === airport.iata_code
                                    )?.name
                                  }
                                </span>
                                <span className="text-gray-500 text-xs hidden sm:block font-normal capitalize">
                                  {
                                    airportList.find(
                                      (airport: AirportI) =>
                                        details.departure === airport.iata_code
                                    )?.city
                                  }{" "}
                                  ,{" "}
                                  {
                                    airportList.find(
                                      (airport: AirportI) =>
                                        details.departure === airport.iata_code
                                    )?.country
                                  }
                                </span>
                                {details.departureTerminal && (
                                  <span className="text-gray-500 text-xs font-normal capitalize">
                                    Terminal {details.departureTerminal}
                                  </span>
                                )}
                              </p>
                            </div>
                            <p className="flex w-2/5 items-center justify-center text-xs sm:text-xl text-gray-500">
                              ------ <IoIosAirplane /> ------
                            </p>
                            <div className="flex w-[30%] flex-col gap-x-5 text-end">
                              <p className="text-base sm:text-lg text-gray-700 font-semibold">
                                {convertToAmPmFormat(details.arrivalTime)}
                              </p>
                              <p className="flex flex-col">
                                <span className="text-gray-600 text-sm font-semibold uppercase">
                                  {details.arrival}
                                </span>
                                <span className="text-gray-500 text-sm hidden sm:block font-normal capitalize">
                                  {
                                    airportList.find(
                                      (airport: AirportI) =>
                                        details.arrival === airport.iata_code
                                    )?.name
                                  }
                                </span>
                                <span className="text-gray-500 text-xs hidden sm:block font-normal capitalize">
                                  {
                                    airportList.find(
                                      (airport: AirportI) =>
                                        details.arrival === airport.iata_code
                                    )?.city
                                  }{" "}
                                  ,{" "}
                                  {
                                    airportList.find(
                                      (airport: AirportI) =>
                                        details.arrival === airport.iata_code
                                    )?.country
                                  }
                                </span>
                                {details.arrivalTerminal && (
                                  <span className="text-gray-500 text-xs font-normal capitalize">
                                    Terminal {details.arrivalTerminal}
                                  </span>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-row gap-x-5 sm:px-5 border-b last:border-0 pb-5">
                            <div className="flex items-center gap-x-2">
                              <img
                                src={
                                  airlinesImg.find(
                                    (airline: AirlineImageI) =>
                                      details.airline === airline.iata
                                  )?.imgUrl
                                }
                                alt={details.airline}
                              />
                              <p className="flex flex-col">
                                <span className="text-xs sm:text-sm text-gray-600 font-semibold capitalize">
                                  {
                                    airlinesImg.find(
                                      (airline: AirlineImageI) =>
                                        details.airline === airline.iata
                                    )?.airlineName
                                  }
                                </span>
                                <span className="text-xs sm:text-sm text-gray-500 uppercase">
                                  {details.airline}
                                  {details.flightNum}
                                </span>
                              </p>
                            </div>
                            <div>
                              <p>
                                <span className="text-xs sm:text-sm text-gray-600 font-semibold capitalize">
                                  cabin :{" "}
                                </span>
                                <span className="text-xs sm:text-sm text-gray-500 font-normal capitalize">
                                  {details.cabinClass}
                                </span>
                              </p>
                              {/* <p>
                                <span className="text-xs sm:text-sm text-gray-600 font-semibold capitalize">
                                  equipment :{" "}
                                </span>
                                <span className="text-xs sm:text-sm text-gray-500 font-normal capitalize">
                                  {details.equipment}
                                </span>
                              </p> */}
                            </div>
                          </div>
                          {details.layoverTime && (
                            <div className="text-xs text-white">
                              <p className="bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white px-4 py-2 w-fit mx-auto rounded-full">
                                {details.layoverTime} layover{" "}
                                {
                                  airportList.find(
                                    (el: AirportListI) =>
                                      el.iata_code === details.arrival
                                  )?.city
                                }{" "}
                                ({details.arrival})
                              </p>
                            </div>
                          )}
                        </div>
                      )
                    )}
                </div>
                {precisePricing?.inboundTrip &&
                  precisePricing.inboundTrip.length > 0 && (
                    <div className="flex flex-col gap-y-5 border-b last:border-b-0 py-5">
                      {precisePricing?.inboundTrip &&
                        precisePricing.inboundTrip.length > 0 && (
                          <p className="flex items-center gap-x-2 text-xs text-gray-600 capitalize border-b pb-2">
                            {precisePricing.inboundTrip && (
                              <span className="text-sm font-semibold">
                                {
                                  airportList.find(
                                    (airport: AirportI) =>
                                      precisePricing.inboundTrip &&
                                      precisePricing.inboundTrip[0]
                                        .departure === airport.iata_code
                                  )?.city
                                }{" "}
                                to{" "}
                                {
                                  airportList.find(
                                    (airport: AirportI) =>
                                      precisePricing.inboundTrip &&
                                      precisePricing.inboundTrip[
                                        precisePricing.inboundTrip.length - 1
                                      ].arrival === airport.iata_code
                                  )?.city
                                }
                              </span>
                            )}
                            <span>(Return Trip)</span>
                          </p>
                        )}
                      {precisePricing?.inboundTrip &&
                        precisePricing.inboundTrip.length > 0 &&
                        precisePricing.inboundTrip.map(
                          (details: FlightSegmentsDetailsI) => (
                            <div
                              key={details.segmentId}
                              className="flex flex-col gap-y-5 border-b last:border-b-0 pb-5"
                            >
                              <div>
                                <p className="flex items-center">
                                  <span className="text-sm sm:text-lg font-semibold text-gray-700 capitalize">
                                    {
                                      airportList.find(
                                        (airport: AirportI) =>
                                          details.departure ===
                                          airport.iata_code
                                      )?.city
                                    }{" "}
                                    to{" "}
                                    {
                                      airportList.find(
                                        (airport: AirportI) =>
                                          details.arrival === airport.iata_code
                                      )?.city
                                    }
                                    &nbsp;-&nbsp;
                                  </span>
                                  <span className="text-sm sm:text-lg font-light text-gray-700 capitalize">
                                    {formatDateToStr(details.departureDate)}
                                  </span>
                                </p>
                                <p className="flex items-center">
                                  <span className="text-xs sm:text-sm font-semibold text-gray-700 capitalize">
                                    Travel Time&nbsp;:&nbsp;
                                  </span>
                                  <span className="text-xs sm:text-sm text-gray-500 capitalize">
                                    {formatTimeInHoursAndMinutes(
                                      details.journeyTime
                                    )}{" "}
                                    , non stop
                                  </span>
                                </p>
                              </div>
                              <div className="flex flex-row items-center justify-between">
                                <div className="flex w-[30%] flex-col gap-x-2 text-base sm:text-lg text-gray-700 font-semibold">
                                  <p>
                                    {convertToAmPmFormat(details.departureTime)}
                                  </p>
                                  <p className="flex flex-col">
                                    <span className="text-gray-600 text-sm font-semibold uppercase">
                                      {details.departure}
                                    </span>
                                    <span className="text-gray-500 text-sm hidden sm:block font-normal capitalize">
                                      {
                                        airportList.find(
                                          (airport: AirportI) =>
                                            details.departure ===
                                            airport.iata_code
                                        )?.name
                                      }
                                    </span>
                                    <span className="text-gray-500 text-xs hidden sm:block font-normal capitalize">
                                      {
                                        airportList.find(
                                          (airport: AirportI) =>
                                            details.departure ===
                                            airport.iata_code
                                        )?.city
                                      }{" "}
                                      ,{" "}
                                      {
                                        airportList.find(
                                          (airport: AirportI) =>
                                            details.departure ===
                                            airport.iata_code
                                        )?.country
                                      }
                                    </span>
                                    {details.departureTerminal && (
                                      <span className="text-gray-500 text-xs font-normal capitalize">
                                        Terminal {details.departureTerminal}
                                      </span>
                                    )}
                                  </p>
                                </div>
                                <p className="flex w-2/5 justify-center items-center text-xs sm:text-xl text-gray-500">
                                  ------
                                  <IoIosAirplane />
                                  ------
                                </p>
                                <div className="flex w-[30%] text-end flex-col gap-x-5">
                                  <p className="text-base sm:text-lg text-gray-700 font-semibold">
                                    {convertToAmPmFormat(details.arrivalTime)}
                                  </p>
                                  <p className="flex flex-col">
                                    <span className="text-gray-600 text-sm font-semibold uppercase">
                                      {details.arrival}
                                    </span>
                                    <span className="text-gray-500 text-sm hidden sm:block font-normal capitalize">
                                      {
                                        airportList.find(
                                          (airport: AirportI) =>
                                            details.arrival ===
                                            airport.iata_code
                                        )?.name
                                      }
                                    </span>
                                    <span className="text-gray-500 text-xs hidden sm:block font-normal capitalize">
                                      {
                                        airportList.find(
                                          (airport: AirportI) =>
                                            details.arrival ===
                                            airport.iata_code
                                        )?.city
                                      }{" "}
                                      ,{" "}
                                      {
                                        airportList.find(
                                          (airport: AirportI) =>
                                            details.arrival ===
                                            airport.iata_code
                                        )?.country
                                      }
                                    </span>
                                    {details.arrivalTerminal && (
                                      <span className="text-gray-500 text-xs font-normal capitalize">
                                        Terminal {details.arrivalTerminal}
                                      </span>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className="flex flex-row gap-x-5 sm:px-5 border-b last:border-0 pb-5">
                                <div className="flex items-center gap-x-2">
                                  <img
                                    src={
                                      airlinesImg.find(
                                        (airline: AirlineImageI) =>
                                          details.airline === airline.iata
                                      )?.imgUrl
                                    }
                                    alt={details.airline}
                                  />
                                  <p className="flex flex-col">
                                    <span className="text-xs sm:text-sm text-gray-600 font-semibold capitalize">
                                      {
                                        airlinesImg.find(
                                          (airline: AirlineImageI) =>
                                            details.airline === airline.iata
                                        )?.airlineName
                                      }
                                    </span>
                                    <span className="text-xs sm:text-sm text-gray-500 uppercase">
                                      {details.airline}
                                      {details.flightNum}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <span className="text-xs sm:text-sm text-gray-600 font-semibold capitalize">
                                      cabin :{" "}
                                    </span>
                                    <span className="text-xs sm:text-sm text-gray-500 font-normal capitalize">
                                      {details.cabinClass}
                                    </span>
                                  </p>
                                  {/* <p>
                                    <span className="text-xs sm:text-sm text-gray-600 font-semibold capitalize">
                                      equipment :{" "}
                                    </span>
                                    <span className="text-xs sm:text-sm text-gray-500 font-normal capitalize">
                                      {details.equipment}
                                    </span>
                                  </p> */}
                                </div>
                              </div>
                              {details.layoverTime && (
                                <div className="text-xs text-white">
                                  <p className="bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white px-4 py-2 w-fit mx-auto rounded-full">
                                    {details.layoverTime} layover{" "}
                                    {
                                      airportList.find(
                                        (el: AirportListI) =>
                                          el.iata_code === details.arrival
                                      )?.city
                                    }{" "}
                                    ({details.arrival})
                                  </p>
                                </div>
                              )}
                            </div>
                          )
                        )}
                    </div>
                  )}
              </div>
            )}
          </div>

          {/*********travelers Details********* */}

          {status === "success" && (
            <div className=" w-full my-4 h-fit rounded-md border-[1px] shadow-md">
              <div className="w-full bg-gradient-to-l from-[#ec2027] to-[#c81212] flex items-center px-4 h-[3rem] rounded-t-md gap-x-4">
                <IoIosPerson className="text-2xl text-white" />
                <h2 className="text-sm md:text-lg font-medium text-white">
                  Traveler Details
                </h2>
              </div>
              {noOfTravelers.adults > 0 && (
                <div className="w-full p-2">
                  <h3 className="uppercase text-sm font-bold text-[#c81212]">
                    Adults
                  </h3>
                  {Array.from({
                    length: noOfTravelers.adults,
                  }).map((_, index) => (
                    <Userform
                      key={index}
                      passengerId={index}
                      PsgType={"ADT"}
                      getForm={(obj: PassengerI) => UserBasicForm(obj)}
                    />
                  ))}
                </div>
              )}
              {noOfTravelers.children > 0 && (
                <div className=" w-full p-2 my-4">
                  <h3 className="uppercase text-sm font-bold text-[#c81212]">
                    Children
                  </h3>
                  {Array.from({
                    length: noOfTravelers.children,
                  }).map((_, index) => (
                    <Userform
                      key={index}
                      passengerId={noOfTravelers.adults + index}
                      PsgType={"CHD"}
                      getForm={(obj: PassengerI) => UserBasicForm(obj)}
                    />
                  ))}
                </div>
              )}
              {noOfTravelers.infants > 0 && (
                <div className="w-full p-2 my-4">
                  <h3 className="uppercase text-sm font-bold text-[#c81212]">
                    Infant
                  </h3>
                  {Array.from({
                    length: noOfTravelers.infants,
                  }).map((_, index) => (
                    <Userform
                      passengerId={
                        noOfTravelers.adults + noOfTravelers.children + index
                      }
                      key={index}
                      PsgType={"INF"}
                      adultsLenght={noOfTravelers.adults}
                      getForm={(obj: PassengerI) => UserBasicForm(obj)}
                    />
                  ))}
                </div>
              )}
              {
                <div className="ps-2 -mt-5 mb-2">
                  <p className="font-semibold text-xs text-gray-600">
                    Rules require all travelers to provide their full name, date
                    of birth that match exactly with the Passport or Government
                    issued photo ID.
                  </p>
                </div>
              }
            </div>
          )}

          {/*Payment Info */}

          {status === "success" && (
            <div className=" w-full my-4 h-fit rounded-md border-[1px] shadow-md">
              <div className="w-full bg-gradient-to-l from-[#ec2027] to-[#c81212] flex items-center px-4 h-[3rem] rounded-t-md gap-x-4">
                <HiViewList className="text-2xl text-white" />
                <h2 className="text-sm md:text-lg font-medium text-white">
                  {" "}
                  Payment Info (Secure SSL Encrypted Transaction)
                </h2>
              </div>
              {/* Payment info form */}
              <PaymentInfoform
                getForm={(obj: Payment) => setPaymentInfo(obj)}
              />
            </div>
          )}

          {/*Cart billing & contact information*/}

          {status === "success" && (
            <div className="grid  grid-cols-1 md:grid-cols-2 items-center gap-x-10">
              <div className="w-full h-fit rounded-md border-[1px] shadow-md">
                <div className="w-full bg-gradient-to-l from-[#ec2027] to-[#c81212] flex items-center px-4 h-[3rem] rounded-t-md gap-x-4">
                  <FaCreditCard className="text-2xl text-white" />
                  <h2 className="text-sm md:text-lg font-medium text-white">
                    Card Billing Information
                  </h2>
                </div>
                {/* Card Details */}
                <CardBillAddressForm
                  getForm={(obj: AddressInfo) => setAddress(obj)}
                />
              </div>
              <div className="w-full h-fit rounded-md border-[1px] shadow-md">
                <div className="w-full bg-gradient-to-l from-[#ec2027] to-[#c81212] flex items-center px-4 h-[3rem] rounded-t-md gap-x-4">
                  <IoIosMail className="text-2xl text-white" />
                  <h2 className="text-sm md:text-lg font-medium text-white">
                    Contact Information
                  </h2>
                </div>
                {/* Contact info */}
                <ContactInfoForm
                  getForm={(obj: ContactInfo) => setContact(obj)}
                />
              </div>
            </div>
          )}

          {status === "success" && <DisclamerCard />}
          {status === "success" && (
            <div className="w-full px-3 my-4 h-fit flex flex-col items-center">
              <div className="text-sm font-medium text-gray-500">
                By clicking 'Book Now,' I hereby confirm that I have read and
                accepted the Terms and Conditions and Privacy Policy of Dreams
                Travel and Tours.
              </div>
              <button
                className="w-[10rem] my-4 md:w-[25%] hidden lg:flex items-center justify-center px-2 py-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white rounded-full  shadow-md text-sm md:text-lg font-medium"
                onClick={handleClick}
              >
                Book Now
              </button>
            </div>
          )}
        </div>

        {status === "success" && (
          <div className="lg:w-40%">
            <FlightDetailsPriceCard
              flightDetails={precisePricing!}
              noOfTravelers={noOfTravelers}
            />
          </div>
        )}
        {status === "success" && (
          <button
            className="w-[10rem] my-4 md:w-[25%] flex lg:hidden mx-auto items-center justify-center px-2 py-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] rounded-full shadow-md text-sm md:text-lg font-medium text-white"
            onClick={handleClick}
          >
            Book Now
          </button>
        )}
      </div>
    </>
  );
};

export default ReviewPage;
