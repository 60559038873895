import { Show } from "../utils/Show";
import { Banner } from "../utils/images";
import FilterComponent from "../components/searchComponet/Filter.component";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingComponent from "../components/Loading.component";
import NoDataFoundComponent from "../components/NoDataFound.component";
import { useEffect, useState } from "react";
import { FaCaretDown, IoIosAirplane } from "../utils/solidIcons";
import { AirportListI, TravelersI } from "../types/flightSearchReducer.types";
import ScrollToTop from "../components/ScrollToTop";
import ContactBanner from "../components/Banner/ContactBanner";
import CallBtn from "../components/Buttons/CallBtn";
import { Helmet } from "react-helmet-async";
import FlightSearchInputFields from "../components/Flights/Includes/FlightSearchInputFields";
import {
  AirlineImageI,
  FlightDetailsI,
  FlightSegmentsDetailsI,
} from "../types/flightListTypes";
import { airlinesImg } from "../utils/airlineImgList";
import {
  calculateDiscount,
  calLayoverTotalTime,
  calTotalTime,
  convertDate,
  convertToAmPmFormat,
  formatDateToStr,
  formatTimeInHoursAndMinutes,
} from "../utils/formatTimeInMinuteToHourandMinute";
import { airportList } from "../utils/airportList";
import { AppliedFilterI } from "../types/flightFilters.types";
import { convertTimeToMinutes } from "../utils/filterSystem";
import Backdrop from "../components/Includes/Backdrop";

interface DataI {
  from: AirportListI | null;
  to: AirportListI | null;
  noOfTravelers: TravelersI;
  cabinClass: "Economy" | "PremiumEconomy" | "Business" | "First" | "";
  tripType: "oneway" | "roundtrip" | "";
  departureDate: Date | "";
  returnDate?: Date | "";
}

const FlightSearchPage = () => {
  const Navigation = useNavigate();
  const [status, setStatus] = useState<
    "loading" | "success" | "error" | "idle"
  >("idle");
  const [visibleComponent, setVisibleComponent] = useState<
    "flight details" | "fare summary" | "baggage info"
  >("flight details");
  const [appliedFiltered, setAppliedFiltered] = useState<AppliedFilterI[]>([]);
  const [filteredData, setFilteredData] = useState<FlightDetailsI[]>([]);
  const [flightListsData, setFlightListData] = useState<FlightDetailsI[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);

  const [from, setFrom] = useState<AirportListI | null>({
    name: "",
    city: "",
    country: "",
    iata_code: "",
    _geoloc: {
      lat: 0,
      lng: 0,
    },
    links_count: 0,
    objectID: "",
    uniqueID: "",
  });

  const [to, setTo] = useState<AirportListI | null>({
    name: "",
    city: "",
    country: "",
    iata_code: "",
    _geoloc: {
      lat: 0,
      lng: 0,
    },
    links_count: 0,
    objectID: "",
    uniqueID: "",
  });

  const [departureDate, setDepartureDate] = useState<Date | "">();
  const [returnDate, setReturnDate] = useState<Date | "">();
  const [tripType, setTripType] = useState<"oneway" | "roundtrip" | "">(
    "roundtrip"
  );
  const [cabinClass, setCabinClass] = useState<
    "Economy" | "PremiumEconomy" | "Business" | "First" | ""
  >("Economy");

  const [noOfTravelers, setNoOfTravelers] = useState<TravelersI>({
    adults: 1,
    children: 0,
    infants: 0,
  });

  const location = useLocation();

  const formatDate = (dateString: Date) => {
    const date = new Date(dateString); // Create a Date object from the string
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const showNav = Show();

  const handleVisibilityOfComponent = (
    componentTitle: "flight details" | "fare summary" | "baggage info"
  ) => {
    setVisibleComponent(() => componentTitle);
  };

  const handleFlightDetailsVisible = (num: number) => {
    setActiveIndex(() => num);
    setVisibleComponent(() => "flight details");
  };

  const handleFlightDetailsHide = () => {
    setActiveIndex(() => -1);
    setVisibleComponent(() => "flight details");
  };

  const airportName = (iataCode: string) => {
    if (iataCode) {
      const airportName = airportList.find(
        (airport: AirportListI) => airport.iata_code === iataCode
      )?.name;
      return airportName && airportName?.length > 18
        ? `${airportName.slice(0, 18)}...`
        : airportName;
    } else {
      return "";
    }
  };

  const handleBookNowBtnClick = (tripDetails: FlightDetailsI) => {
    localStorage.setItem("pricing", JSON.stringify(tripDetails));
    if (tripType === "oneway" && departureDate) {
      Navigation(
        `/flight/details?origin=${from!.iata_code}&destination=${
          to!.iata_code
        }&trip-type=${tripType}&cabin-class=${cabinClass}&adults=${
          noOfTravelers.adults
        }&children=${noOfTravelers.children}&infants=${
          noOfTravelers.infants
        }&departure-date=${convertDate(departureDate)}`
      );
    } else if (tripType === "roundtrip" && departureDate && returnDate) {
      Navigation(
        `/flight/details?origin=${from!.iata_code}&destination=${
          to!.iata_code
        }&trip-type=${tripType}&cabin-class=${cabinClass}&adults=${
          noOfTravelers.adults
        }&children=${noOfTravelers.children}&infants=${
          noOfTravelers.infants
        }&departure-date=${convertDate(
          departureDate
        )}&return-date=${convertDate(returnDate)}`
      );
    }
  };

  // Fetch flight list data
  const fetchFlightLists = async (payloadData: any) => {
    try {
      setStatus("loading");
      let payload = {};
      if (payloadData.tripType === "oneway") {
        payload = {
          search: {
            adults: payloadData.noOfTravelers.adults,
            children: payloadData.noOfTravelers.children,
            infants: payloadData.noOfTravelers.infants,
            searchAirLegs: [
              {
                cabinClass: payloadData.cabinClass,
                departureDate: formatDate(payloadData.departureDate),
                destination: `${payloadData.to.iata_code}`,
                origin: `${payloadData.from.iata_code}`,
              },
            ],
          },
        };
      } else if (payloadData.tripType === "roundtrip") {
        payload = {
          search: {
            adults: payloadData.noOfTravelers.adults,
            children: payloadData.noOfTravelers.children,
            infants: payloadData.noOfTravelers.infants,
            searchAirLegs: [
              {
                cabinClass: payloadData.cabinClass,
                departureDate: formatDate(payloadData.departureDate),
                destination: `${payloadData.to.iata_code}`,
                origin: `${payloadData.from.iata_code}`,
              },
              {
                cabinClass: cabinClass,
                departureDate: formatDate(payloadData.returnDate),
                destination: `${payloadData.from.iata_code}`,
                origin: `${payloadData.to.iata_code}`,
              },
            ],
          },
        };
      }
      const response = await fetch(
        process.env.REACT_APP_FlightSearchApiRoute as string,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...payload,
          }),
        }
      );
      const responseJson = await response.json();
      // Check if data is present and handle error cases
      if (!responseJson.success || responseJson.data.length === 0) {
        setStatus("error");
        return;
      }

      setFlightListData(() =>
        responseJson.data.sort(
          (a: FlightDetailsI, b: FlightDetailsI) =>
            a.adtFare + a.adtTax - (b.adtFare + b.adtTax)
        )
      );
      setFilteredData(() =>
        responseJson.data.sort(
          (a: FlightDetailsI, b: FlightDetailsI) =>
            a.adtFare + a.adtTax - (b.adtFare + b.adtTax)
        )
      );

      setStatus("success");
    } catch (error) {
      setStatus("error");
      console.error(`${error}`);
    }
  };

  // Function to check if data is valid
  const hasValues = (obj: any) => {
    return obj && typeof obj === "object" && Object.keys(obj).length > 0;
  };
  useEffect(() => {
    setActiveIndex(() => -1);
    setVisibleComponent(() => "flight details");

    const data =
      localStorage.getItem("flightSearchKey") !== null
        ? JSON.parse(localStorage.getItem("flightSearchKey")!)
        : null;
    let parsedData: DataI = {
      tripType: "",
      cabinClass: "",
      noOfTravelers: {
        adults: 1,
        children: 0,
        infants: 0,
      },
      departureDate: "",
      returnDate: "",
      from: null,
      to: null,
    };
    if (hasValues(data)) {
      parsedData = data ? JSON.parse(atob(`${data}`)) : null;
    } else {
      // Extract query parameters
      const queryParams = new URLSearchParams(location.search);

      const origin = queryParams.get("origin") || ""; // Provide a default value
      const destination = queryParams.get("destination") || ""; // Provide a default value
      parsedData.tripType =
        (queryParams.get("trip-type") as "oneway" | "roundtrip" | "") ||
        "roundtrip";
      parsedData.cabinClass =
        (queryParams.get("cabin-class") as
          | "Economy"
          | "PremiumEconomy"
          | "Business"
          | "First"
          | "") || "";

      // Safely parse numeric values for travelers
      parsedData.noOfTravelers.adults = parseInt(
        queryParams.get("adults") || "0",
        10
      );
      parsedData.noOfTravelers.children = parseInt(
        queryParams.get("children") || "0",
        10
      );
      parsedData.noOfTravelers.infants = parseInt(
        queryParams.get("infants") || "0",
        10
      );

      // Parse and validate dates
      const departureDate = queryParams.get("departure-date");
      parsedData.departureDate = departureDate ? new Date(departureDate) : "";

      const returnDate = queryParams.get("return-date");
      parsedData.returnDate =
        parsedData.tripType === "roundtrip" && returnDate
          ? new Date(returnDate)
          : "";

      // Find matching airports
      parsedData.from =
        airportList.find(
          (airport: AirportListI) => airport.iata_code === origin
        ) || null;

      parsedData.to =
        airportList.find(
          (airport: AirportListI) => airport.iata_code === destination
        ) || null;

      const searchQuery = {
        from: parsedData.from,
        to: parsedData.to,
        departureDate: parsedData.departureDate,
        returnDate:
          parsedData.tripType === "roundtrip" ? parsedData.returnDate : "",
        tripType: parsedData.tripType,
        cabinClass: parsedData.cabinClass,
        noOfTravelers: parsedData.noOfTravelers,
      };

      localStorage.setItem(
        "flightSearchKey",
        JSON.stringify(btoa(JSON.stringify(searchQuery)))
      );
    }

    if (!parsedData) {
      return;
    }
    setFrom(() => parsedData.from);
    setTo(() => parsedData.to);
    setDepartureDate(() => new Date(parsedData.departureDate));
    if (parsedData.tripType === "roundtrip") {
      setReturnDate(() => new Date(parsedData.returnDate || new Date()));
    }
    setTripType(() => parsedData.tripType);
    setCabinClass(() => parsedData.cabinClass);
    setNoOfTravelers(() => ({
      adults: parsedData.noOfTravelers.adults,
      children: parsedData.noOfTravelers.children,
      infants: parsedData.noOfTravelers.infants,
    }));
    fetchFlightLists(parsedData);
  }, [location.search]);

  const departureTimeRanges = {
    before6AM: { start: 0, end: 6 }, // Before 6 AM
    between6And12: { start: 6, end: 12 }, // 6 AM to 12 PM
    between12And6: { start: 12, end: 18 }, // 12 PM to 6 PM
    after6PM: { start: 18, end: 24 }, // After 6 PM
  };

  const filterFlights = () => {
    // Helper to parse time ranges for departure/arrival time filters
    const isTimeInRange = (
      time: string,
      { start, end }: { start: number; end: number }
    ): boolean => {
      const hour = parseInt(time.split(":")[0]);
      return hour >= start && hour <= end;
    };

    // Helper to calculate total journey/layover time
    const calculateTotalTime = (
      trip: FlightSegmentsDetailsI[],
      type: "journey" | "layover"
    ): number =>
      trip.reduce((total, segment) => {
        if (type === "journey") {
          return total + segment.journeyTime;
        }
        if (type === "layover") {
          if (segment.layoverTime) {
            // Only add to total if layoverTime is not null or undefined
            return total + convertTimeToMinutes(segment.layoverTime);
          }
          return total; // Ignore null or undefined layoverTime
        }
        return total;
      }, 0);

    // Filter logic
    return filteredData.filter((flight: FlightDetailsI) => {
      return appliedFiltered.every((filter: AppliedFilterI) => {
        const { id, label, value } = filter;

        // Price range filter
        if (id === "price-range") {
          return flight.adtFare + flight.adtTax <= +label;
        }

        // Outbound/inbound stops
        const tripStops =
          id === "outbound-stop"
            ? flight.outboundTrip.length
            : flight.inboundTrip?.length || 0;

        if (id === "outbound-stop" || id === "inbound-stop") {
          if (label.includes("non-stop")) return tripStops === 1;
          if (label.includes("1-stop")) return tripStops === 2;
          if (label.includes("more-than-1-stop")) return tripStops > 2;
        }

        // Outbound/inbound journey duration
        if (
          id === "outbound-journey-duration" ||
          id === "inbound-journey-duration"
        ) {
          const trip = id.includes("outbound")
            ? flight.outboundTrip
            : flight.inboundTrip || [];
          return calculateTotalTime(trip, "journey") <= +label;
        }

        // Outbound/inbound layover duration
        if (
          id === "outbound-layover-duration" ||
          id === "inbound-layover-duration"
        ) {
          const trip = id.includes("outbound")
            ? flight.outboundTrip
            : flight.inboundTrip || [];
          return calculateTotalTime(trip, "layover") <= +label;
        }

        // Outbound/inbound departure and arrival time
        if (
          [
            "outbound-departure-time",
            "outbound-arrival-time",
            "inbound-departure-time",
            "inbound-arrival-time",
          ].includes(id)
        ) {
          const isOutbound = id.includes("outbound");
          const isArrival = id.includes("arrival");
          const trip = isOutbound
            ? flight.outboundTrip
            : flight.inboundTrip || [];
          const time = isArrival
            ? trip[trip.length - 1]?.arrivalTime
            : trip[0]?.departureTime;

          if (!time) return false; // Handle missing time edge case

          const timeRange =
            value === "Before 6AM"
              ? departureTimeRanges.before6AM
              : value === "6AM to 12PM"
              ? departureTimeRanges.between6And12
              : value === "12PM to 6PM"
              ? departureTimeRanges.between12And6
              : departureTimeRanges.after6PM;

          return isTimeInRange(time, timeRange);
        }

        // Airline filter
        if (id === "airline") {
          return flight.platingCarrier === value;
        }

        return true; // Default case for unhandled filters
      });
    });
  };

  const onHandleFlightFilteredData = (filters: AppliedFilterI[]) => {
    setAppliedFiltered(filters);
    setFilteredData(() => flightListsData);
  };

  useEffect(() => {
    setFilteredData(() => filterFlights());
    setActiveIndex(() => -1);
    setVisibleComponent(() => "flight details");
  }, [appliedFiltered]);
  return (
    <>
      <Helmet>
        <title>{`From ${from!.iata_code} - ${from!.name} to ${
          to!.iata_code
        } - ${to!.name}`}</title>
        <meta
          name="description"
          content={`From ${from!.iata_code} ${from!.name} ${from!.city} ${
            from!.country
          } to ${to!.iata_code} ${to!.name} ${to!.city} ${to!.country}`}
        />
        <meta name="keywords" content="flight,booking,contact us" />
        <meta property="og:title" content="Dreams tavel and tours" />
        <meta
          property="og:description"
          content="contact us for flight booking"
        />
        <meta property="og:type" content="article" />
      </Helmet>
      <ScrollToTop />
      <div
        className={
          !showNav
            ? " md:fixed top-[4rem]  md:h-fit py-3 w-full md:flex items-center justify-center z-20 bg-cover bg-center bg-no-repeat"
            : "bg-cover bg-center bg-no-repeat md:fixed transform h-fit py-3 w-full flex items-center justify-center z-20"
        }
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <FlightSearchInputFields showNav={false} />
      </div>
      <div className="flex items-start justify-center w-full gap-2 lg:gap-4 h-full min-h-screen">
        {status === "loading" && (
          <LoadingComponent classCSS="md:mt-[25rem] lg:mt-[15rem]" />
        )}
        {status === "error" && (
          <div className="w-full md:w-[90%] lg:w-[70%] h-fit p-2 mt-0 md:mt-[25rem] lg:mt-[20rem]">
            <NoDataFoundComponent />
          </div>
        )}
        {status === "success" && (
          <>
            <div className="hidden lg:block w-[25%] h-fit p-2 mt-[17rem] rounded-sm overflow-auto">
              {flightListsData && (
                <FilterComponent
                  flightFilteredData={flightListsData}
                  handleFlightFilterData={onHandleFlightFilteredData}
                />
              )}
            </div>

            <div className="w-full lg:w-[70%] h-fit p-2 mt-[1rem] md:mt-[25rem] lg:mt-[17rem]">
              <p className="text-sm sm:text-lg font-bold text-gray-700">
                {status === "success" && filteredData.length > 0
                  ? filteredData.length
                  : 0}{" "}
                Results Found.{" "}
              </p>

              <div className="flex flex-col gap-4">
                <h1 className="flex sm:gap-2 flex-row items-center text-xs sm:text-xl sm:font-bold text-[#ec2027]">
                  {`From ${from!.iata_code} - ${from!.name} to ${
                    to!.iata_code
                  } - ${to!.name} - `}
                  <span className="text-xs sm:text-sm">
                    ({`${new Date(`${departureDate}`).toDateString()}`}
                    {tripType !== "roundtrip" && ")"}
                  </span>
                  {tripType === "roundtrip" && (
                    <span className="text-xs sm:text-sm">
                      {` - ${new Date(`${returnDate}`).toDateString()}`})
                    </span>
                  )}
                </h1>
                <p className="text-xs sm:text-sm font-medium text-gray-500">
                  <span className="text-xs sm:text-sm font-bold text-gray-700">
                    Note:
                  </span>
                  All the fares displayed are in USD, inclusive of base fare,
                  taxes and service fees. Additional baggage fees may apply as
                  per the airline(s) policies. Some of the flights results shown
                  could either be for other dates or nearby airport(s).
                </p>
                <div className="-z-50 lg:w-[69vw]">
                  <div className="hidden xs:block">
                    <ContactBanner />
                  </div>
                  <div className="xs:hidden">
                    <CallBtn />
                  </div>
                </div>
                <div className="text-end lg:hidden">
                  <button
                    onClick={() => setIsFilterVisible(true)}
                    className="bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white text-xs py-2 font-semibold px-2"
                  >
                    Apply filter
                  </button>
                </div>
                <div className="">
                  <Backdrop
                    isOpen={isFilterVisible}
                    onClose={() => setIsFilterVisible(false)}
                  >
                    <div className={isFilterVisible ? "block mb-20" : "hidden"}>
                      {flightListsData && (
                        <FilterComponent
                          flightFilteredData={flightListsData}
                          handleFlightFilterData={onHandleFlightFilteredData}
                          closeBackdrop={() => setIsFilterVisible(false)}
                        />
                      )}
                    </div>
                  </Backdrop>
                </div>
              </div>
              {/* flight search list */}
              {filteredData.length > 0 && (
                <div>
                  {filteredData.map(
                    (flightData: FlightDetailsI, index: number) => (
                      <div
                        key={flightData.solutionId}
                        className="w-full mb-5 shadow-[0_0_6px_-1.5px_rgba(0,0,0,0.3)]"
                      >
                        <div className="w-full flex md:flex-row flex-col item-center sm:items-center">
                          <div className="w-full md:w-[90%] flex flex-col">
                            {/* oneway journey */}
                            {flightData.outboundTrip.length > 1 && (
                              <div className="flex flex-col md:flex-row max-h-40  px-5 py-5 items-center justify-center sm:justify-between gap-y-2">
                                <div className="w-full flex flex-row items-center gap-x-2">
                                  <img
                                    src={
                                      airlinesImg.find(
                                        (airline: AirlineImageI) =>
                                          airline.iata ===
                                          flightData.outboundTrip[0].airline
                                      )?.imgUrl
                                    }
                                    alt={flightData.outboundTrip[0].airline}
                                    width={40}
                                  />
                                  <p className="text-sm text-gray-700 capitalize">
                                    {
                                      airlinesImg.find(
                                        (airline: AirlineImageI) =>
                                          airline.iata ===
                                          flightData.outboundTrip[0].airline
                                      )?.airlineName
                                    }
                                  </p>
                                </div>
                                <div className="w-full flex flex-row items-center justify-between md:justify-normal gap-x-4">
                                  <div>
                                    <p className="text-sm text-gray-700">
                                      {convertToAmPmFormat(
                                        flightData.outboundTrip[0].departureTime
                                      )}
                                    </p>
                                    <p className="text-sm text-gray-700 uppercase">
                                      {flightData.outboundTrip[0].departure}
                                    </p>
                                  </div>
                                  <div className="flex flex-col justify-center">
                                    <p className="text-center text-xs text-gray-500">
                                      {flightData.outboundTrip.length - 1} stop
                                    </p>
                                    <p className="text-xl flex items-center text-gray-400">
                                      ----
                                      <IoIosAirplane />
                                      ----
                                    </p>
                                    <p className="text-center text-xs text-gray-500">
                                      {formatTimeInHoursAndMinutes(
                                        calTotalTime(flightData.outboundTrip) +
                                          calLayoverTotalTime(
                                            flightData.outboundTrip
                                          )
                                      )}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="text-sm text-gray-700">
                                      {convertToAmPmFormat(
                                        flightData.outboundTrip[
                                          flightData.outboundTrip.length - 1
                                        ].arrivalTime
                                      )}
                                    </p>
                                    <p className="text-sm text-gray-700 uppercase">
                                      {
                                        flightData.outboundTrip[
                                          flightData.outboundTrip.length - 1
                                        ].arrival
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {flightData.outboundTrip.length === 1 && (
                              <div className="flex flex-col md:flex-row max-h-40  px-5 py-5 items-center justify-center sm:justify-between gap-y-2">
                                <div className="w-full  flex flex-row items-center gap-x-2">
                                  <img
                                    src={
                                      airlinesImg.find(
                                        (airline: AirlineImageI) =>
                                          airline.iata ===
                                          flightData.outboundTrip[0].airline
                                      )?.imgUrl
                                    }
                                    alt={flightData.outboundTrip[0].airline}
                                    width={40}
                                  />
                                  <p className="text-sm text-gray-700 capitalize">
                                    {
                                      airlinesImg.find(
                                        (airline: AirlineImageI) =>
                                          airline.iata ===
                                          flightData.outboundTrip[0].airline
                                      )?.airlineName
                                    }
                                  </p>
                                </div>
                                <div className="w-full flex flex-row items-center justify-between md:justify-normal gap-x-4">
                                  <div>
                                    <p className="text-sm text-gray-700">
                                      {convertToAmPmFormat(
                                        flightData.outboundTrip[0].departureTime
                                      )}
                                    </p>
                                    <p className="text-sm text-gray-700 uppercase">
                                      {flightData.outboundTrip[0].departure}
                                    </p>
                                  </div>
                                  <div className="flex flex-col justify-center">
                                    <p className="text-center text-xs text-gray-500">
                                      non-stop
                                    </p>
                                    <p className="text-xl flex items-center text-gray-400">
                                      ----
                                      <IoIosAirplane />
                                      ----
                                    </p>
                                    <p className="text-center text-xs text-gray-500">
                                      {formatTimeInHoursAndMinutes(
                                        calTotalTime(flightData.outboundTrip) +
                                          calLayoverTotalTime(
                                            flightData.outboundTrip
                                          )
                                      )}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="text-sm text-gray-700">
                                      {convertToAmPmFormat(
                                        flightData.outboundTrip[0].arrivalTime
                                      )}
                                    </p>
                                    <p className="text-sm text-gray-700 uppercase">
                                      {flightData.outboundTrip[0].arrival}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* return journey */}
                            {flightData.inboundTrip &&
                              flightData.inboundTrip.length > 1 && (
                                <div className="flex flex-col md:flex-row max-h-40  px-5 py-5 items-center justify-center sm:justify-between gap-y-2">
                                  <div className="w-full  flex flex-row items-center gap-x-2">
                                    <img
                                      src={
                                        airlinesImg.find(
                                          (airline: AirlineImageI) =>
                                            airline.iata ===
                                            flightData.inboundTrip![0].airline
                                        )?.imgUrl
                                      }
                                      alt={flightData.inboundTrip![0].airline}
                                      width={40}
                                    />
                                    <p className="text-sm text-gray-700 capitalize">
                                      {
                                        airlinesImg.find(
                                          (airline: AirlineImageI) =>
                                            airline.iata ===
                                            flightData.inboundTrip![0].airline
                                        )?.airlineName
                                      }
                                    </p>
                                  </div>
                                  <div className="w-full flex flex-row items-center justify-between md:justify-normal gap-x-4">
                                    <div>
                                      <p className="text-sm text-gray-700">
                                        {convertToAmPmFormat(
                                          flightData.inboundTrip[0]
                                            .departureTime
                                        )}
                                      </p>
                                      <p className="text-sm text-gray-700 uppercase">
                                        {flightData.inboundTrip[0].departure}
                                      </p>
                                    </div>
                                    <div className="flex flex-col justify-center">
                                      <p className="text-center text-xs text-gray-500">
                                        {flightData.inboundTrip.length - 1} stop
                                      </p>
                                      <p className="flex items-center text-xl text-gray-400">
                                        ----
                                        <IoIosAirplane />
                                        ----
                                      </p>
                                      <p className="text-center text-xs text-gray-500">
                                        {formatTimeInHoursAndMinutes(
                                          calTotalTime(flightData.inboundTrip) +
                                            calLayoverTotalTime(
                                              flightData.inboundTrip
                                            )
                                        )}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="text-sm text-gray-700">
                                        {convertToAmPmFormat(
                                          flightData.inboundTrip[
                                            flightData.inboundTrip.length - 1
                                          ].arrivalTime
                                        )}
                                      </p>
                                      <p className="text-sm text-gray-700 uppercase">
                                        {
                                          flightData.inboundTrip[
                                            flightData.inboundTrip.length - 1
                                          ].arrival
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {flightData.inboundTrip &&
                              flightData.inboundTrip.length === 1 && (
                                <div className="flex flex-col md:flex-row  max-h-40 px-5 py-5 items-center justify-center sm:justify-between gap-y-2">
                                  <div className="w-full flex flex-row items-center gap-x-2">
                                    <img
                                      src={
                                        airlinesImg.find(
                                          (airline: AirlineImageI) =>
                                            airline.iata ===
                                            flightData.inboundTrip![0].airline
                                        )?.imgUrl
                                      }
                                      alt={flightData.inboundTrip![0].airline}
                                      width={40}
                                    />
                                    <p className="text-sm text-gray-700 capitalize">
                                      {
                                        airlinesImg.find(
                                          (airline: AirlineImageI) =>
                                            airline.iata ===
                                            flightData.inboundTrip![0].airline
                                        )?.airlineName
                                      }
                                    </p>
                                  </div>
                                  <div className="w-full flex flex-row items-center justify-between md:justify-normal gap-x-4">
                                    <div>
                                      <p className="text-sm text-gray-700">
                                        {convertToAmPmFormat(
                                          flightData.inboundTrip[0]
                                            .departureTime
                                        )}
                                      </p>
                                      <p className="text-sm text-gray-700 uppercase">
                                        {flightData.inboundTrip[0].departure}
                                      </p>
                                    </div>
                                    <div className="flex flex-col justify-center">
                                      <p className="text-center text-xs text-gray-500">
                                        non-stop
                                      </p>
                                      <p className="flex items-center text-xl text-gray-400">
                                        ----
                                        <IoIosAirplane />
                                        ----
                                      </p>
                                      <p className="text-center text-xs text-gray-500">
                                        {formatTimeInHoursAndMinutes(
                                          calTotalTime(flightData.inboundTrip) +
                                            calLayoverTotalTime(
                                              flightData.inboundTrip
                                            )
                                        )}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="text-sm text-gray-700">
                                        {convertToAmPmFormat(
                                          flightData.inboundTrip[0].arrivalTime
                                        )}
                                      </p>
                                      <p className="text-sm text-gray-700 uppercase">
                                        {flightData.inboundTrip[0].arrival}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                          {/*Right side flight list */}
                          <div className="w-full md:w-[20%] md:mr-10 flex md:flex-col justify-between sm:gap-y-1 py-5 px-2 sm:px-4 md:px-0">
                            <div className="sm:pl-3 flex flex-col">
                              <p className="text-xl text-gray-600  text-end font-semibold">
                                $
                                {parseFloat(
                                  `${
                                    flightData.adtFare +
                                    flightData.adtTax +
                                    flightData.platformServiceFee
                                  }`
                                ).toFixed(2)}
                              </p>
                              <p className="flex flex-col text-end">
                                <span className="text-xs text-gray-500">
                                  Price per person
                                </span>
                                <span className="text-xs text-gray-500 font-extralight">
                                  (incl. taxes & fees)
                                </span>
                              </p>
                            </div>

                            <button
                              className="w-[50%] xs:w-[40%] sm:w-[30%] md:w-full flex items-center justify-center gap-x-2 text-sm bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white sm:px-2 sm:py-3"
                              onClick={() => handleBookNowBtnClick(flightData)}
                            >
                              Book Now{" "}
                              <span className="text-sm ">
                                <IoIosAirplane />
                              </span>
                            </button>
                          </div>
                        </div>
                        <hr />
                        <div className="flex items-center gap-x-1 bg-clip-text text-transparent font-semibold px-4 py-2 text-xs text-gray-500 cursor-pointer w-fit bg-gradient-to-r from-[#ec2027] to-[#c81212]">
                          {activeIndex !== index && (
                            <p
                              className="flex flex-row items-center justify-between"
                              onClick={() => handleFlightDetailsVisible(index)}
                            >
                              Flight Details
                              <FaCaretDown className="text-red-500 text-base" />
                            </p>
                          )}
                          {activeIndex === index && (
                            <p
                              onClick={handleFlightDetailsHide}
                              className="flex flex-row items-center justify-between"
                            >
                              Hide flight details
                              <FaCaretDown className="text-red-500 text-base" />
                            </p>
                          )}
                        </div>
                        {activeIndex === index && (
                          <div className="">
                            <div className="flex items-center justify-evenly my-1 border-t border-b">
                              <button
                                className={
                                  visibleComponent === "flight details"
                                    ? "text-white text-xs sm:text-sm sm:font-semibold py-1 px-1 sm:px-4 bg-gradient-to-r from-[#ec2027] to-[#c81212]"
                                    : "text-gray-600 text-xs sm:text-sm sm:font-semibold py-1 px-1 sm:px-4 hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] hover:text-white"
                                }
                                onClick={() =>
                                  handleVisibilityOfComponent("flight details")
                                }
                              >
                                Flight Details
                              </button>
                              <button
                                className={
                                  visibleComponent === "fare summary"
                                    ? "text-white text-xs sm:text-sm sm:font-semibold py-1 px-1 sm:px-4 bg-gradient-to-r from-[#ec2027] to-[#c81212]"
                                    : "text-gray-600 text-xs sm:text-sm sm:font-semibold py-1 px-1 sm:px-4 hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] hover:text-white"
                                }
                                onClick={() =>
                                  handleVisibilityOfComponent("fare summary")
                                }
                              >
                                Fare Summary
                              </button>
                              <button
                                className={
                                  visibleComponent === "baggage info"
                                    ? "text-white text-xs sm:text-sm sm:font-semibold py-1 px-1 sm:px-4 bg-gradient-to-r from-[#ec2027] to-[#c81212]"
                                    : "text-gray-600 text-xs sm:text-sm sm:font-semibold py-1 px-1 sm:px-4 hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] hover:text-white"
                                }
                                onClick={() =>
                                  handleVisibilityOfComponent("baggage info")
                                }
                              >
                                Baggage Info
                              </button>
                            </div>
                            {visibleComponent === "flight details" && (
                              <div className="flex flex-col gap-y-2 py-5">
                                {flightData.outboundTrip.length > 0 && (
                                  <div className="text-xs text-gray-700 font-semibold px-4">
                                    Depart{" "}
                                    {formatDateToStr(
                                      flightData.outboundTrip[0].departureDate
                                    )}
                                  </div>
                                )}
                                {flightData.outboundTrip.length > 0 &&
                                  flightData.outboundTrip.map(
                                    (tripDetails: FlightSegmentsDetailsI) => (
                                      <div key={tripDetails.segmentId}>
                                        <div className="flex md:flex-row flex-col w-full  md:items-center px-4 py-2 gap-x-2 gap-y-2 justify-start sm:justify-between">
                                          <div className="flex w-1/5 gap-x-2 justify-start items-center">
                                            <img
                                              src={
                                                airlinesImg.find(
                                                  (airlines: AirlineImageI) =>
                                                    tripDetails.airline ===
                                                    airlines.iata
                                                )?.imgUrl
                                              }
                                              alt={tripDetails.airline}
                                            />
                                            <div className="flex flex-col">
                                              <p className="text-sm text-gray-700 font-semibold">
                                                {
                                                  airlinesImg.find(
                                                    (airline: AirlineImageI) =>
                                                      airline.iata ===
                                                      tripDetails.airline
                                                  )?.airlineName
                                                }
                                              </p>
                                              <p className="text-xs text-gray-500 lowercase">
                                                {tripDetails.cabinClass}
                                              </p>
                                              <p className="text-xs text-gray-500">
                                                {tripDetails.airline}
                                                {tripDetails.flightNum}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="md:flex w-4/5 items-center px-4 py-2 gap-x-3 justify-between hidden">
                                            <div className="w-1/5">
                                              <p className="text-sm text-gray-700 font-semibold">
                                                {
                                                  airportList.find(
                                                    (airport: AirportListI) =>
                                                      airport.iata_code ===
                                                      tripDetails.departure
                                                  )?.city
                                                }{" "}
                                                ({tripDetails.departure})
                                              </p>
                                              <p className="text-xs text-gray-500">
                                                {
                                                  airportList.find(
                                                    (airport: AirportListI) =>
                                                      airport.iata_code ===
                                                      tripDetails.departure
                                                  )?.name
                                                }
                                              </p>
                                              {tripDetails.departureTerminal && (
                                                <p className="text-xs text-gray-500">
                                                  Terminal{" "}
                                                  {
                                                    tripDetails.departureTerminal
                                                  }
                                                </p>
                                              )}
                                            </div>
                                            <div className="flex w-2/5 items-center gap-x-2">
                                              <div>
                                                <p className="text-sm text-gray-700 font-semibold">
                                                  {convertToAmPmFormat(
                                                    tripDetails.departureTime
                                                  )}
                                                </p>
                                                <p className="text-xs text-gray-500">
                                                  {formatDateToStr(
                                                    tripDetails.departureDate
                                                  )}
                                                </p>
                                              </div>

                                              <p className="flex flex-col text-center">
                                                <span className="flex items-center text-gray-500 text-xs">
                                                  ----
                                                  <IoIosAirplane />
                                                  ----
                                                </span>
                                                <span className="text-xs text-gray-500">
                                                  {formatTimeInHoursAndMinutes(
                                                    tripDetails.journeyTime
                                                  )}
                                                </span>
                                              </p>

                                              <div>
                                                <p className="text-sm text-gray-700 font-semibold">
                                                  {convertToAmPmFormat(
                                                    tripDetails.arrivalTime
                                                  )}
                                                </p>
                                                <p className="text-xs text-gray-500">
                                                  {formatDateToStr(
                                                    tripDetails.arrivalDate
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="w-1/5">
                                              <p className="text-sm text-gray-700 font-semibold">
                                                {
                                                  airportList.find(
                                                    (airport: AirportListI) =>
                                                      airport.iata_code ===
                                                      tripDetails.arrival
                                                  )?.city
                                                }{" "}
                                                ({tripDetails.arrival})
                                              </p>
                                              <p className="text-xs text-gray-500">
                                                {
                                                  airportList.find(
                                                    (airport: AirportListI) =>
                                                      airport.iata_code ===
                                                      tripDetails.arrival
                                                  )?.name
                                                }
                                              </p>
                                              {tripDetails.departureTerminal && (
                                                <p className="text-xs text-gray-500">
                                                  Terminal{" "}
                                                  {tripDetails.arrivalTerminal}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          <div className="flex w-full items-center gap-x-1 justify-between md:hidden">
                                            <div className="w-[30%] text-start">
                                              <p className="text-xs text-gray-700 font-semibold">
                                                <div>
                                                  <p className="text-xs text-gray-700 font-semibold">
                                                    {convertToAmPmFormat(
                                                      tripDetails.departureTime
                                                    )}
                                                  </p>
                                                  <p className="text-xs text-gray-500">
                                                    {formatDateToStr(
                                                      tripDetails.departureDate
                                                    )}
                                                  </p>
                                                </div>
                                                {
                                                  airportList.find(
                                                    (airport: AirportListI) =>
                                                      airport.iata_code ===
                                                      tripDetails.departure
                                                  )?.city
                                                }{" "}
                                                ({tripDetails.departure})
                                              </p>
                                              <p className="text-xs text-gray-500">
                                                {airportName(
                                                  tripDetails.departure
                                                )}
                                              </p>
                                              {tripDetails.departureTerminal && (
                                                <p className="text-xs text-gray-500">
                                                  Terminal{" "}
                                                  {
                                                    tripDetails.departureTerminal
                                                  }
                                                </p>
                                              )}
                                            </div>
                                            <div className="flex w-2/5 items-center justify-center">
                                              <p className="flex flex-col text-center">
                                                <span className="flex items-center text-gray-500 text-xs">
                                                  ----
                                                  <IoIosAirplane />
                                                  ----
                                                </span>
                                                <span className="text-xs text-gray-500">
                                                  {formatTimeInHoursAndMinutes(
                                                    tripDetails.journeyTime
                                                  )}
                                                </span>
                                              </p>
                                            </div>
                                            <div className="w-[30%] xs:text-end">
                                              <div>
                                                <p className="text-xs text-gray-700 font-semibold">
                                                  {convertToAmPmFormat(
                                                    tripDetails.arrivalTime
                                                  )}
                                                </p>
                                                <p className="text-xs text-gray-500">
                                                  {formatDateToStr(
                                                    tripDetails.arrivalDate
                                                  )}
                                                </p>
                                              </div>
                                              <p className="text-xs text-gray-700 font-semibold">
                                                {
                                                  airportList.find(
                                                    (airport: AirportListI) =>
                                                      airport.iata_code ===
                                                      tripDetails.arrival
                                                  )?.city
                                                }{" "}
                                                ({tripDetails.arrival})
                                              </p>
                                              <p className="text-xs text-gray-500">
                                                {airportName(
                                                  tripDetails.arrival
                                                )}
                                              </p>
                                              {tripDetails.departureTerminal && (
                                                <p className="text-xs text-gray-500">
                                                  Terminal{" "}
                                                  {tripDetails.arrivalTerminal}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        {tripDetails.layoverTime && (
                                          <div className="text-xs text-white">
                                            <p className="bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white px-4 py-2 w-fit mx-auto rounded-full">
                                              {tripDetails.layoverTime} layover{" "}
                                              {
                                                airportList.find(
                                                  (el: AirportListI) =>
                                                    el.iata_code ===
                                                    tripDetails.arrival
                                                )?.city
                                              }{" "}
                                              ({tripDetails.arrival})
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    )
                                  )}
                                {flightData.inboundTrip &&
                                  flightData.inboundTrip.length > 0 && (
                                    <div className="text-xs text-gray-700 font-semibold px-4">
                                      Return{" "}
                                      {formatDateToStr(
                                        flightData.inboundTrip[0].departureDate
                                      )}
                                    </div>
                                  )}
                                {flightData.inboundTrip &&
                                  flightData.inboundTrip.length > 0 &&
                                  flightData.inboundTrip.map(
                                    (tripDetails: FlightSegmentsDetailsI) => (
                                      <div key={tripDetails.segmentId}>
                                        <div className="flex md:flex-row flex-col w-full  md:items-center px-4 py-2 gap-x-2 gap-y-2 justify-start sm:justify-between">
                                          <div className="flex w-1/5 gap-x-2 justify-start items-center">
                                            <img
                                              src={
                                                airlinesImg.find(
                                                  (airlines: AirlineImageI) =>
                                                    tripDetails.airline ===
                                                    airlines.iata
                                                )?.imgUrl
                                              }
                                              alt={tripDetails.airline}
                                            />
                                            <div>
                                              <p className="text-sm text-gray-700 font-semibold">
                                                {
                                                  airlinesImg.find(
                                                    (airline: AirlineImageI) =>
                                                      airline.iata ===
                                                      tripDetails.airline
                                                  )?.airlineName
                                                }
                                              </p>
                                              <p className="text-xs text-gray-500 lowercase">
                                                {tripDetails.cabinClass}
                                              </p>
                                              <p className="text-xs text-gray-500">
                                                {tripDetails.airline}
                                                {tripDetails.flightNum}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="md:flex w-4/5 items-center px-4 py-2 gap-x-3 justify-between hidden">
                                            <div className="w-1/5">
                                              <p className="text-sm text-gray-700 font-semibold">
                                                {
                                                  airportList.find(
                                                    (airport: AirportListI) =>
                                                      airport.iata_code ===
                                                      tripDetails.departure
                                                  )?.city
                                                }{" "}
                                                ({tripDetails.departure})
                                              </p>
                                              <p className="text-xs text-gray-500">
                                                {
                                                  airportList.find(
                                                    (airport: AirportListI) =>
                                                      airport.iata_code ===
                                                      tripDetails.departure
                                                  )?.name
                                                }
                                              </p>
                                              {tripDetails.departureTerminal && (
                                                <p className="text-xs text-gray-500">
                                                  Terminal{" "}
                                                  {
                                                    tripDetails.departureTerminal
                                                  }
                                                </p>
                                              )}
                                            </div>
                                            <div className="flex w-2/5 items-center gap-x-2">
                                              <div>
                                                <p className="text-sm text-gray-700 font-semibold">
                                                  {convertToAmPmFormat(
                                                    tripDetails.departureTime
                                                  )}
                                                </p>
                                                <p className="text-xs text-gray-500">
                                                  {formatDateToStr(
                                                    tripDetails.departureDate
                                                  )}
                                                </p>
                                              </div>

                                              <p className="flex flex-col text-center">
                                                <span className="flex items-center text-gray-500 text-xs">
                                                  ----
                                                  <IoIosAirplane />
                                                  ----
                                                </span>
                                                <span className="text-xs text-gray-500">
                                                  {formatTimeInHoursAndMinutes(
                                                    tripDetails.journeyTime
                                                  )}
                                                </span>
                                              </p>

                                              <div>
                                                <p className="text-sm text-gray-700 font-semibold">
                                                  {convertToAmPmFormat(
                                                    tripDetails.arrivalTime
                                                  )}
                                                </p>
                                                <p className="text-xs text-gray-500">
                                                  {formatDateToStr(
                                                    tripDetails.arrivalDate
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="w-1/5">
                                              <p className="text-sm text-gray-700 font-semibold">
                                                {
                                                  airportList.find(
                                                    (airport: AirportListI) =>
                                                      airport.iata_code ===
                                                      tripDetails.arrival
                                                  )?.city
                                                }{" "}
                                                ({tripDetails.arrival})
                                              </p>
                                              <p className="text-xs text-gray-500">
                                                {
                                                  airportList.find(
                                                    (airport: AirportListI) =>
                                                      airport.iata_code ===
                                                      tripDetails.arrival
                                                  )?.name
                                                }
                                              </p>
                                              {tripDetails.departureTerminal && (
                                                <p className="text-xs text-gray-500">
                                                  Terminal{" "}
                                                  {tripDetails.arrivalTerminal}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          <div className="flex w-full items-center gap-x-1 justify-between md:hidden">
                                            <div className="w-[30%] text-start">
                                              <div>
                                                <p className="text-xs text-gray-700 font-semibold">
                                                  {convertToAmPmFormat(
                                                    tripDetails.departureTime
                                                  )}
                                                </p>
                                                <p className="text-xs text-gray-500">
                                                  {formatDateToStr(
                                                    tripDetails.departureDate
                                                  )}
                                                </p>
                                              </div>
                                              <p className="text-xs text-gray-700 font-semibold">
                                                {
                                                  airportList.find(
                                                    (airport: AirportListI) =>
                                                      airport.iata_code ===
                                                      tripDetails.departure
                                                  )?.city
                                                }{" "}
                                                ({tripDetails.departure})
                                              </p>
                                              <p className="text-xs text-gray-500">
                                                {airportName(
                                                  tripDetails.departure
                                                )}
                                              </p>
                                              {tripDetails.departureTerminal && (
                                                <p className="text-xs text-gray-500">
                                                  Terminal{" "}
                                                  {
                                                    tripDetails.departureTerminal
                                                  }
                                                </p>
                                              )}
                                            </div>
                                            <div className="flex w-2/5 justify-center items-center gap-x-2">
                                              <p className="flex flex-col text-center">
                                                <span className="flex items-center text-gray-500 text-xs">
                                                  ----
                                                  <IoIosAirplane />
                                                  ----
                                                </span>
                                                <span className="text-xs text-gray-500">
                                                  {formatTimeInHoursAndMinutes(
                                                    tripDetails.journeyTime
                                                  )}
                                                </span>
                                              </p>
                                            </div>
                                            <div className="w-[30%] xs:text-end">
                                              <div>
                                                <p className="text-xs text-gray-700 font-semibold">
                                                  {convertToAmPmFormat(
                                                    tripDetails.arrivalTime
                                                  )}
                                                </p>
                                                <p className="text-xs text-gray-500">
                                                  {formatDateToStr(
                                                    tripDetails.arrivalDate
                                                  )}
                                                </p>
                                              </div>
                                              <p className="text-xs text-gray-700 font-semibold">
                                                {
                                                  airportList.find(
                                                    (airport: AirportListI) =>
                                                      airport.iata_code ===
                                                      tripDetails.arrival
                                                  )?.city
                                                }{" "}
                                                ({tripDetails.arrival})
                                              </p>
                                              <p className="text-xs text-gray-500">
                                                {airportName(
                                                  tripDetails.arrival
                                                )}
                                              </p>
                                              {tripDetails.departureTerminal && (
                                                <p className="text-xs text-gray-500">
                                                  Terminal{" "}
                                                  {tripDetails.arrivalTerminal}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        {tripDetails.layoverTime && (
                                          <div className="text-xs text-white">
                                            <p className="bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white px-4 py-2 w-fit mx-auto rounded-full">
                                              {tripDetails.layoverTime} layover{" "}
                                              {
                                                airportList.find(
                                                  (el: AirportListI) =>
                                                    el.iata_code ===
                                                    tripDetails.arrival
                                                )?.city
                                              }{" "}
                                              ({tripDetails.arrival})
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    )
                                  )}
                              </div>
                            )}
                            {visibleComponent === "fare summary" && (
                              <div className="py-5">
                                <table className="w-[98%] sm:w-[90%] mx-auto text-center text-xs sm:text-sm text-gray-700 border my-2 capitalize">
                                  <thead>
                                    <tr className="border bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white">
                                      <th className="font-extralight">
                                        Traveler Type
                                      </th>
                                      <th className="font-extralight">
                                        Ticket Price
                                      </th>
                                      <th className="font-extralight">
                                        Taxes & fees
                                      </th>
                                      <th className="font-extralight">total</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {noOfTravelers.adults > 0 && (
                                      <tr className="border">
                                        <td>{noOfTravelers.adults} x adult</td>
                                        <td>$ {flightData.adtFare}</td>
                                        <td>$ {flightData.adtTax}</td>
                                        <td>
                                          ${" "}
                                          {parseFloat(
                                            (
                                              (flightData.adtFare +
                                                flightData.adtTax) *
                                              noOfTravelers.adults
                                            ).toFixed(2)
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                    {noOfTravelers.children > 0 && (
                                      <tr className="border">
                                        <td>
                                          {noOfTravelers.children} x children
                                        </td>
                                        <td>$ {flightData.chdFare}</td>
                                        <td>$ {flightData.chdTax}</td>
                                        <td>
                                          ${" "}
                                          {parseFloat(
                                            (
                                              (flightData.chdFare +
                                                flightData.chdTax) *
                                              noOfTravelers.children
                                            ).toFixed(2)
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                    {noOfTravelers.infants > 0 && (
                                      <tr className="border">
                                        <td>
                                          {noOfTravelers.infants} x infant
                                        </td>
                                        <td>$ {flightData.infFare}</td>
                                        <td>$ {flightData.infTax}</td>
                                        <td>
                                          ${" "}
                                          {parseFloat(
                                            (
                                              (flightData.infFare +
                                                flightData.infTax) *
                                              noOfTravelers.infants
                                            ).toFixed(2)
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                    <tr className="border">
                                      <td
                                        colSpan={3}
                                        className="sm:font-semibold text-xs sm:text-sm text-gray-700"
                                      >
                                        sub-total
                                      </td>
                                      <td>
                                        ${" "}
                                        {parseFloat(
                                          (
                                            (flightData.adtFare +
                                              flightData.adtTax) *
                                              noOfTravelers.adults +
                                            (flightData.chdFare +
                                              flightData.chdTax) *
                                              noOfTravelers.children +
                                            (flightData.infFare +
                                              flightData.infTax) *
                                              noOfTravelers.infants
                                          ).toFixed(2)
                                        )}
                                      </td>
                                    </tr>
                                    {flightData.discount !== 0 && (
                                      <tr className="border">
                                        <td
                                          colSpan={3}
                                          className="sm:font-semibold text-xs sm:text-sm text-gray-700"
                                        >
                                          discount
                                        </td>
                                        <td>
                                          ${" "}
                                          {calculateDiscount(
                                            parseFloat(
                                              (
                                                (flightData.adtFare +
                                                  flightData.adtTax) *
                                                  noOfTravelers.adults +
                                                (flightData.chdFare +
                                                  flightData.chdTax) *
                                                  noOfTravelers.children +
                                                (flightData.infFare +
                                                  flightData.infTax) *
                                                  noOfTravelers.infants
                                              ).toFixed(2)
                                            ),
                                            flightData.discount
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                  <tfoot>
                                    <tr className="border">
                                      <td
                                        colSpan={3}
                                        className="sm:font-semibold text-xs sm:text-sm text-gray-700"
                                      >
                                        Total
                                      </td>
                                      <td>
                                        ${" "}
                                        {calculateDiscount(
                                          parseFloat(
                                            (
                                              (flightData.adtFare +
                                                flightData.adtTax) *
                                                noOfTravelers.adults +
                                              (flightData.chdFare +
                                                flightData.chdTax) *
                                                noOfTravelers.children +
                                              (flightData.infFare +
                                                flightData.infTax) *
                                                noOfTravelers.infants
                                            ).toFixed(2)
                                          ),
                                          flightData.discount
                                        )}
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            )}
                            {visibleComponent === "baggage info" && (
                              <div className="py-2">
                                <table className="w-[98%] sm:w-[90%] mx-auto text-center text-xs sm:text-sm text-gray-700 border my-2 capitalize">
                                  <thead>
                                    <tr className="border bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white">
                                      <th className="font-extralight">
                                        Baggage Details
                                      </th>
                                      <th className="font-extralight">Adult</th>
                                      <th className="font-extralight">
                                        Children
                                      </th>
                                      <th className="font-extralight">
                                        Infant
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th className="border bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white font-extralight">
                                        Baggage Amount
                                      </th>
                                      <td className="border">
                                        {flightData.baggageInfoAdt
                                          ?.baggageAmount
                                          ? flightData.baggageInfoAdt
                                              ?.baggageAmount
                                          : "0PC"}
                                      </td>
                                      <td className="border">
                                        {flightData.baggageInfoChd
                                          ?.baggageAmount
                                          ? flightData.baggageInfoChd
                                              ?.baggageAmount
                                          : "0PC"}
                                      </td>
                                      <td className="border">
                                        {flightData.baggageInfoInf
                                          ?.baggageAmount
                                          ? flightData.baggageInfoInf
                                              ?.baggageAmount
                                          : "0PC"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className="border bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white font-extralight">
                                        Baggage Weight
                                      </th>
                                      <td className="border">
                                        {flightData.baggageInfoAdt
                                          ?.baggageWeight
                                          ? flightData.baggageInfoAdt
                                              .baggageWeight
                                          : "0KG"}
                                      </td>
                                      <td className="border">
                                        {flightData.baggageInfoChd
                                          ?.baggageWeight
                                          ? flightData.baggageInfoChd
                                              .baggageWeight
                                          : "0KG"}
                                      </td>
                                      <td className="border">
                                        {flightData.baggageInfoInf
                                          ?.baggageWeight
                                          ? flightData.baggageInfoInf
                                              .baggageWeight
                                          : "0KG"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className="border bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white font-extralight">
                                        Carry on Amount
                                      </th>
                                      <td className="border">
                                        {flightData.baggageInfoAdt
                                          ?.carryOnAmount
                                          ? flightData.baggageInfoAdt
                                              .carryOnAmount
                                          : "0PC"}
                                      </td>
                                      <td className="border">
                                        {flightData.baggageInfoChd
                                          ?.carryOnAmount
                                          ? flightData.baggageInfoChd
                                              .carryOnAmount
                                          : "0PC"}
                                      </td>
                                      <td className="border">
                                        {flightData.baggageInfoInf
                                          ?.carryOnAmount
                                          ? flightData.baggageInfoInf
                                              .carryOnAmount
                                          : "0PC"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className="border bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white font-extralight">
                                        Carry on Weight
                                      </th>
                                      <td className="border">
                                        {flightData.baggageInfoAdt
                                          ?.carryOnWeight
                                          ? flightData.baggageInfoAdt
                                              .carryOnWeight
                                          : "0KG"}
                                      </td>
                                      <td className="border">
                                        {flightData.baggageInfoChd
                                          ?.carryOnWeight
                                          ? flightData.baggageInfoChd
                                              .carryOnWeight
                                          : "0KG"}
                                      </td>
                                      <td className="border">
                                        {flightData.baggageInfoInf
                                          ?.carryOnWeight
                                          ? flightData.baggageInfoInf
                                              .carryOnWeight
                                          : "0KG"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className="border bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white font-extralight">
                                        Carry on Size
                                      </th>
                                      <td className="border">
                                        {flightData.baggageInfoAdt?.carryOnSize
                                          ? flightData.baggageInfoAdt
                                              .carryOnSize
                                          : "0CM"}
                                      </td>
                                      <td className="border">
                                        {flightData.baggageInfoChd?.carryOnSize
                                          ? flightData.baggageInfoChd
                                              .carryOnSize
                                          : "0CM"}
                                      </td>
                                      <td className="border">
                                        {flightData.baggageInfoInf?.carryOnSize
                                          ? flightData.baggageInfoInf
                                              .carryOnSize
                                          : "0CM"}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FlightSearchPage;
