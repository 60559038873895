import { MdPrivacyTip } from "../../utils/solidIcons";

const DisclamerCard = () => {
  return (
    <div className="w-full my-4 h-fit rounded-md border-[1px] shadow-md">
      <div className="w-full bg-gradient-to-l from-[#ec2027] to-[#c81212] flex items-center px-4 h-[3rem] gap-x-4 rounded-t-md">
        <MdPrivacyTip className="text-2xl text-white" />
        <h2 className="text-sm md:text-lg font-medium text-white">
          Disclaimer
        </h2>
      </div>
      <div className="p-3 font-medium text-sm text-gray-700">
        Please confirm that the names of travelers are accurate. Please also
        confirm that the dates and times of flight departures are accurate.
        Tickets are non-transferable and name changes on tickets are not
        permitted. Ticket cost for most airlines is non-refundable (see Fare
        Rules) and our service fees are non-refundable. All our service fees and
        taxes are included in the total ticket cost. However, tickets may be
        refunded if requested within four (4) hours from the time of purchase at
        no cost, and within twenty-four (24) hours from the time of purchase for
        a fee. Date and routing changes will be subject to airline penalties and
        our service fees. Fares are not guaranteed until ticketed.
      </div>
    </div>
  );
};

export default DisclamerCard;
