import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Apple,
  asta,
  clin,
  compliAssure,
  FooterBG,
  Google,
  IATA,
} from "../utils/images";
import { Link } from "react-router-dom";
import Input from "./inputs/input";
import { DTT_LOGO } from "../utils/icons";
import { BlogI } from "../types/blogs.types";
import { useAppDispatch } from "../store";
import {
  setSelectedTopAirlines,
  setTopAirlines,
  setTopDestination,
} from "../store/reducers/flightSearch.reducer";
import { notifySuccess } from "../utils/notifications";

const FooterComponent = () => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // const [routeData, setRouteData] = useState<BlogI[] | null>(null);
  const [email, setEmail] = useState<string>("");
  const dispatch = useAppDispatch();
  const [topFlightDestinationsRoutes, setTopFlightDestinationsRoutes] =
    useState<BlogI[] | null>(null);
  const [topAirlinesRoutes, setTopAirlinesRoutes] = useState<BlogI[] | null>(
    null
  );

  const handleEmailSubscription = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setEmail(() => event.target.value);
  };

  const subscribe = async (email: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SUBSCRIBE}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (data.success) {
        notifySuccess("You have subscribed successfully.");
      }
      setEmail(() => "");
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  const onSubscribeEmail = () => {
    if (emailRegex.test(email)) {
      subscribe(email);
    }
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_GETALLBLOGS as string,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        const responseJson = await response.json();
        // Check if data is present and handle error cases
        if (!responseJson.success) {
          console.error("Error in fetching data:", responseJson.message);
          return;
        }

        setTopFlightDestinationsRoutes(() =>
          responseJson.data.filter(
            (blog: BlogI) => blog.selectedOption === "top-flight-destination"
          )
        );
        dispatch(
          setTopDestination(
            responseJson.data.filter(
              (blog: BlogI) => blog.selectedOption === "top-flight-destination"
            )
          )
        );
        setTopAirlinesRoutes(() =>
          responseJson.data.filter(
            (blog: BlogI) => blog.selectedOption === "top-airlines"
          )
        );
        dispatch(
          setTopAirlines(
            responseJson.data.filter(
              (blog: BlogI) => blog.selectedOption === "top-airlines"
            )
          )
        );
      } catch (error) {
        console.error(`${error}`);
      }
    };
    fetchBlogs();
  }, []);

  const handleTopDestinationRoutes = (id: string) => {
    if (id) {
      dispatch(setSelectedTopAirlines(id));
      localStorage.setItem("blogId", JSON.stringify(id));
    }
  };

  const handleTopAirlinesRoutes = (id: string) => {
    if (id) {
      dispatch(setSelectedTopAirlines(id));
      localStorage.setItem("blogId", JSON.stringify(id));
    }
  };
  return (
    <div
      className="w-full flex flex-col items-center border-t-[8px] h-fit border-[#ec2027] bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${FooterBG})` }}
    >
      <div className="flex flex-col gap-y-4 w-full justify-center bg-black/85 text-white py-4 h-full">
        {topFlightDestinationsRoutes &&
          topFlightDestinationsRoutes?.length > 0 && (
            <div className="w-[95%] lg:w-[80%] mx-auto flex flex-col  gap-y-4">
              <h3 className="text-lg font-bold">Top Flight Destinations</h3>
              <div className="text-xs sm:text-sm font-thin sm:font-normal grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-2">
                {topFlightDestinationsRoutes &&
                  topFlightDestinationsRoutes?.length > 0 &&
                  topFlightDestinationsRoutes
                    .slice(0, 24)
                    .map((blog: BlogI) => (
                      <Link
                        key={blog.flightRoute}
                        to={`/${blog.url.trim().replace(/ /g, "-")}`}
                        onClick={() => handleTopDestinationRoutes(blog._id)}
                      >
                        {blog.flightRoute}
                      </Link>
                    ))}
              </div>
              <Link
                to="/top-flights-destinations"
                className="text-sm font-medium bg-gradient-to-l from-[#ec2027] to-[#c81212] w-fit px-4 py-1"
              >
                View More
              </Link>
            </div>
          )}
        {topAirlinesRoutes && topAirlinesRoutes?.length > 0 && (
          <div className=" w-[95%] lg:w-[80%] mx-auto flex flex-col gap-y-4">
            <h3 className="text-lg font-bold">Top Airlines</h3>
            <div className="text-xs sm:text-sm font-thin sm:font-normal grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4  lg:grid-cols-5 xl:grid-cols-6 gap-2">
              {topAirlinesRoutes &&
                topAirlinesRoutes?.length > 0 &&
                topAirlinesRoutes.slice(0, 24).map((blog: BlogI) => (
                  <Link
                    key={blog.flightRoute}
                    to={`/${blog.url.trim().replace(/ /g, "-")}`}
                    onClick={() => handleTopAirlinesRoutes(blog._id)}
                  >
                    {blog.flightRoute}
                  </Link>
                ))}
            </div>
            <Link
              to="/top-airlines"
              className="text-sm font-medium bg-gradient-to-l from-[#ec2027] to-[#c81212] w-fit px-4 py-1"
            >
              View More
            </Link>
          </div>
        )}
        <div className=" w-[95%] px-1 md:px-4 lg:px-0 lg:w-[80vw] mx-auto flex flex-col items-start justify-center mb-10 md:mb-10 gap-y-4">
          <h2 className="font-bold text-sm md:text-lg">
            Make your travel easy with a wide range of products and services.
          </h2>
          {/* <div className="w-full flex items-start mt-4 gap-5 justify-between">
            <div className="flex flex-col items-start gap-2 text-xs md:text-base">
              <Link to={"/"} className="font-bold">
                Flight
              </Link>
              <Link to={"/"} className="font-bold">
                Bus
              </Link>
              <Link to={"/"} className="font-bold">
                Flight Status
              </Link>
              <Link to={"/"} className="font-bold">
                Check PNR Status
              </Link>
              <Link to={"/"} className="font-bold">
                Corporate Travel
              </Link>
            </div>
            <div className="flex flex-col items-start gap-2 text-xs md:text-base">
              <Link to={"/"} className="font-bold">
                Hotels
              </Link>
              <Link to={"/"} className="font-bold">
                Cabs
              </Link>
              <Link to={"/"} className="font-bold">
                Airlines
              </Link>
              <Link to={"/"} className="font-bold">
                DTT PRO
              </Link>
              <Link to={"/"} className="font-bold">
                Blog
              </Link>
            </div>
            <div className="flex flex-col items-start gap-2 text-xs md:text-base">
              <Link to={"/"} className="font-bold">
                Trains
              </Link>
              <Link to={"/"} className="font-bold">
                Cruise
              </Link>
              <Link to={"/"} className="font-bold">
                Airport
              </Link>
              <Link to={"/"} className="font-bold">
                Activities
              </Link>
              <Link to={"/"} className="font-bold">
                Flight Check-in
              </Link>
            </div>
            <div className="flex flex-col items-start gap-2 text-xs md:text-base">
              <Link to={"/"} className="font-bold">
                Holiday
              </Link>
              <Link to={"/"} className="font-bold">
                Charter
              </Link>
              <Link to={"/"} className="font-bold">
                Travel Guide
              </Link>
              <Link to={"/"} className="font-bold">
                Travel update
              </Link>
            </div>
          </div> */}
          <div className="w-full flex justify-between text-sm font-medium capitalize gap-1">
            <div className="flex flex-col gap-2">
              <h3 className="text-xs md:text-base font-semibold md:font-bold">
                Company
              </h3>
              <Link
                to="/about-us"
                className="text-xs md:text-sm font-thin md:font-semibold"
              >
                about us
              </Link>
              <Link
                to="/"
                className="text-xs md:text-sm font-thin md:font-semibold"
                rel="nofollow"
              >
                career
              </Link>
              <Link
                to="/"
                className="text-xs md:text-sm font-thin md:font-semibold"
                rel="nofollow"
              >
                blog
              </Link>
              <Link
                to="/"
                className="text-xs md:text-sm font-thin md:font-semibold"
                rel="nofollow"
              >
                how we work
              </Link>
            </div>
            <div className="flex flex-col gap-2">
              <h3 className="text-xs md:text-base font-semibold md:font-bold">
                Terms & Policies
              </h3>
              <Link
                to="/privacy"
                className="text-xs md:text-sm font-thin md:font-semibold"
              >
                Privacy
              </Link>
              <Link
                to="/terms-and-conditions"
                className="text-xs md:text-sm font-thin md:font-semibold"
              >
                terms & conditions
              </Link>
              <Link
                to="/refund-policy"
                className="text-xs md:text-sm font-thin md:font-semibold"
              >
                refund policy
              </Link>
              <Link
                to="/disclaimer"
                className="text-xs md:text-sm font-thin md:font-semibold"
              >
                disclaimer
              </Link>
            </div>
            <div className="flex flex-col gap-2">
              <h3 className="text-xs md:text-base font-semibold md:font-bold">
                More
              </h3>
              <Link
                to="/top-flights-destinations"
                className="text-xs md:text-sm font-thin md:font-semibold"
              >
                top flight destinations
              </Link>
              <Link
                to="/top-airlines"
                className="text-xs md:text-sm font-thin md:font-semibold"
              >
                airlines
              </Link>
              <Link
                to="/"
                className="text-xs md:text-sm font-thin md:font-semibold"
                rel="nofollow"
              >
                last minute flight
              </Link>
              <Link
                to="/"
                className="text-xs md:text-sm font-thin md:font-semibold"
                rel="nofollow"
              >
                credit card authorization
              </Link>
            </div>
            <div className="flex flex-col gap-2">
              <h3 className="text-xs md:text-base font-semibold md:font-bold">
                Help
              </h3>
              <Link
                to="/"
                className="text-xs md:text-sm font-thin md:font-semibold"
                rel="nofollow"
              >
                FAQ's
              </Link>
              <Link
                to="/contact-us"
                className="text-xs md:text-sm font-thin md:font-semibold"
              >
                contact us
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full relative flex flex-col items-center justify-center py-2 bg-[#ec2027]/80 text-white">
        <div className="absolute top-[-1.5rem] w-fit h-fit flex justify-center items-center gap-3">
          <Input
            type="email"
            value={email}
            label="Enter Email Address"
            id="Email"
            onCharge={(e: any) => handleEmailSubscription(e)}
          />
          <button
            className="rounded-full bg-[#ec2027] flex items-center justify-center py-1 px-3 md:px-6 shadow-md"
            onClick={onSubscribeEmail}
          >
            Subscribe
          </button>
        </div>
        <div className="w-4/5 flex flex-col md:flex-row items-center justify-between gap-3 md:gap-2 px-4 md:px-0 mt-5 md:my-[2.5rem]">
          <Link to={"/"}>
            <img src={DTT_LOGO} alt="Logo" className="h-[5rem] md:h-[6rem]" />
          </Link>
          <p className="md:w-[65%] lg:w-[50%] font-semibold text-sm text-center">
            From Dreams to Destinations: Let's Explore Together!
          </p>
          <div className="flex md:flex-col gap-2">
            <div
              className=" cursor-pointer w-[135px] h-[40px] bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${Google})` }}
            ></div>
            <div
              className=" cursor-pointer w-[135px] h-[40px] bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${Apple})` }}
            ></div>
          </div>
        </div>
        <div className="grid w-1/2 grid-cols-4 grid-rows-1  place-items-center gap-1 my-2">
          <img
            className="bg-cover bg-no-repeat bg-center"
            src={IATA}
            alt="IATA"
            width={100}
          />
          <img
            className="bg-cover bg-no-repeat bg-center"
            src={clin}
            alt="clin"
            width={100}
          />
          <img
            className="bg-cover bg-no-repeat bg-center"
            src={compliAssure}
            alt="compliAssure secured"
            width={100}
          />
          <img
            className="bg-cover bg-no-repeat bg-center"
            src={asta}
            alt="ASTA"
            width={100}
          />
        </div>
        <hr className="w-full h-[2px] bg-white" />
        <div className="my-2 text-base font-bold">
          Copyright &copy; 2024 Dreams Travel & Tours
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
