import React, { useState } from "react";

interface Day {
  date: Date;
  isCurrentMonth: boolean;
  isToday: boolean;
  isSelected: boolean;
  isSelectable: boolean;
}

interface CalendarProps {
  initialDate: Date;
  dateType: "departureDate" | "returnDate"; // New prop for date type
  onDateChange: (newDate: Date) => void;
  selectedReturnDate?: Date;
}

const Calendar: React.FC<CalendarProps> = ({
  initialDate,
  dateType,
  onDateChange,
  selectedReturnDate,
}) => {
  const today = new Date();
  //today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate() - 1);
  const oneYearLater = new Date(
    today.getFullYear() + 1,
    today.getMonth(),
    today.getDate()
  );

  const validateDate = (date: any): Date => {
    return date instanceof Date && !isNaN(date.getTime()) ? date : new Date();
  };

  const initialDateValidated = validateDate(initialDate);
  const selectedReturnDateValidated = validateDate(selectedReturnDate);

  const [selReturnDate, setSelReturnDate] = useState(
    selectedReturnDateValidated
  );
  const [currentMonth, setCurrentMonth] = useState(
    new Date(selReturnDate.getFullYear(), selReturnDate.getMonth(), 1)
  );
  const [selectedDate, setSelectedDate] = useState<Date>(initialDateValidated);

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Helper functions for generating days
  const getStartOfMonth = (): Date => {
    const start = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      1
    );
    return new Date(start.setDate(start.getDate() - start.getDay())); // Start from the previous Sunday
  };

  const getEndOfMonth = (): Date => {
    const end = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      0
    );
    return new Date(end.setDate(end.getDate() + (6 - end.getDay()))); // End at the next Saturday
  };

  const generateDays = (): Day[] => {
    const days: Day[] = [];
    const start = getStartOfMonth();
    const end = getEndOfMonth();

    for (
      let date = new Date(start);
      date <= end;
      date.setDate(date.getDate() + 1)
    ) {
      const day = new Date(date);
      let isSelectable = false;
      if (day.getMonth() === currentMonth.getMonth()) {
        if (dateType === "departureDate") {
          // If dateType is 'departureDate', allow selecting dates from today onwards
          isSelectable = day >= today && day <= oneYearLater;
        } else if (dateType === "returnDate") {
          // If dateType is 'returnDate', allow selecting dates greater than or equal to initialDate
          isSelectable = day >= initialDate && day <= oneYearLater;
        }

        days.push({
          date: day,
          isCurrentMonth: day.getMonth() === currentMonth.getMonth(),
          isToday: day.toDateString() === today.toDateString(),
          isSelected:
            dateType === "returnDate"
              ? selReturnDate
                ? day.toDateString() === selReturnDate.toDateString()
                : false
              : selectedDate
              ? day.toDateString() === selectedDate.toDateString()
              : false,
          isSelectable,
        });
      } else {
        // Don't add days that are outside the current month
        continue;
      }
    }

    return days;
  };

  // Handlers for navigation
  const handlePreviousMonth = () => {
    const newMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() - 1,
      1
    );
    if (newMonth >= new Date(today.getFullYear(), today.getMonth(), 1)) {
      setCurrentMonth(newMonth);
    }
  };

  const handleNextMonth = () => {
    const newMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      1
    );
    if (newMonth <= new Date(today.getFullYear() + 1, today.getMonth(), 1)) {
      setCurrentMonth(newMonth);
    }
  };

  const handleDayClick = (day: Date) => {
    if (day >= today && day <= oneYearLater) {
      setSelectedDate(() => day);
      if (dateType === "returnDate") {
        setSelReturnDate(() => day);
      }
    }
  };

  const days = generateDays();

  // Disable buttons based on the allowed range
  const isPreviousDisabled =
    currentMonth <= new Date(today.getFullYear(), today.getMonth(), 1);
  const isNextDisabled =
    currentMonth >= new Date(today.getFullYear() + 1, today.getMonth(), 1);

  return (
    <div className="w-full max-w-md mx-auto mt-10 p-5 bg-black/70 shadow-lg backdrop-blur-sm">
      {/* Header */}
      <header className="flex justify-between items-center mb-4">
        <button
          className={`text-white ${
            isPreviousDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={handlePreviousMonth}
          disabled={isPreviousDisabled}
        >
          &larr;
        </button>
        <h2 className="text-sm text-white font-semibold">
          {currentMonth.toLocaleString("default", { month: "long" })}{" "}
          {currentMonth.getFullYear()}
        </h2>
        <button
          className={`text-white ${
            isNextDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={handleNextMonth}
          disabled={isNextDisabled}
        >
          &rarr;
        </button>
      </header>

      {/* Days of the Week */}
      <div className="grid grid-cols-7 gap-1 text-center">
        {daysOfWeek.map((day) => (
          <span key={day} className="text-white text-sm font-medium">
            {day}
          </span>
        ))}
      </div>

      {/* Calendar Days */}
      <div className="grid grid-cols-7 gap-1 mt-2">
        {days.map((day) => (
          <div
            key={day.date.toDateString()}
            className={`p-2 text-center text-sm rounded-lg cursor-pointer ${
              day.isSelectable
                ? day.isSelected
                  ? "bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white text-sm font-bold"
                  : day.isCurrentMonth
                  ? day.isToday
                    ? "bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white text-sm"
                    : "bg-white text-gray-700 hover:bg-red-100"
                  : "bg-gray-100 text-gray-400"
                : "bg-gray-200 text-gray-300 cursor-not-allowed"
            }`}
            onClick={() => day.isSelectable && handleDayClick(day.date)}
          >
            {day.date.getDate()}
          </div>
        ))}
      </div>
      <div className="flex justify-end">
        <button
          className="text-white rounded-md bg-gradient-to-r from-[#ec2027] to-[#c81212] mt-5 px-2 py-1"
          onClick={() => onDateChange(selectedDate)}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default Calendar;
