import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  AirportListI,
  TravelersI,
} from "../../../types/flightSearchReducer.types";
import {
  AiOutlineSwap,
  FaLocationDot,
  FaUser,
  GiAirplaneArrival,
  GiAirplaneDeparture,
  GiCommercialAirplane,
  IoCalendar,
  MdKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  PiMinusCircleFill,
  PiPlusCircleFill,
} from "../../../utils/solidIcons";
import { trie } from "../../../utils/search";
import { notifyError } from "../../../utils/notifications";
import Calendar from "../../Includes/Calendar";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { convertDate } from "../../../utils/formatTimeInMinuteToHourandMinute";
import { airportList } from "../../../utils/airportList";

interface FlightSearchInputFieldsI {
  showNav: boolean;
}

interface DataI {
  from: AirportListI;
  to: AirportListI;
  noOfTravelers: TravelersI;
  cabinClass: "Economy" | "PremiumEconomy" | "Business" | "First";
  tripType: "oneway" | "roundtrip";
  departureDate: Date;
  returnDate?: Date;
}

const FlightSearchInputFields: React.FC<FlightSearchInputFieldsI> = ({
  showNav,
}) => {
  const Navigation = useNavigate();
  const location = useLocation(); // Tracks the current route
  const [fromInput, setFromInput] = useState<string>("");
  const [toInput, setToInput] = useState<string>("");
  const [from, setFrom] = useState<AirportListI>({
    name: "",
    city: "",
    country: "",
    iata_code: "",
    _geoloc: {
      lat: 0,
      lng: 0,
    },
    links_count: 0,
    objectID: "",
    uniqueID: "",
  });

  const [to, setTo] = useState<AirportListI>({
    name: "",
    city: "",
    country: "",
    iata_code: "",
    _geoloc: {
      lat: 0,
      lng: 0,
    },
    links_count: 0,
    objectID: "",
    uniqueID: "",
  });

  const [filteredFrom, setFilteredFrom] = useState<AirportListI[]>([]);
  const [filteredTo, setFilteredTo] = useState<AirportListI[]>([]);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  // Set minimum date to 4 days later than the current date
  const minDepartureDate = new Date(currentDate);
  minDepartureDate.setDate(currentDate.getDate() + 4);

  const minReturnDate = new Date(minDepartureDate);
  minReturnDate.setDate(minDepartureDate.getDate() + 4);

  const [departureDate, setDepartureDate] = useState<Date>(minDepartureDate);
  const [returnDate, setReturnDate] = useState<Date>(minReturnDate);
  const [selectedReturnDate, setSelectedReturnDate] =
    useState<Date>(returnDate);

  const [tripType, setTripType] = useState<"oneway" | "roundtrip">("roundtrip");
  const [cabinClass, setCabinClass] = useState<
    "Economy" | "PremiumEconomy" | "Business" | "First"
  >("Economy");

  const [noOfTravelers, setNoOfTravelers] = useState<TravelersI>({
    adults: 1,
    children: 0,
    infants: 0,
  });

  const dropdownRefs = useRef<Map<string, HTMLDivElement>>(new Map()); // Map of refs
  const [visibleDropdown, setVisibleDropdown] = useState<string | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const isOutside = Array.from(dropdownRefs.current.values()).every(
      (ref) => ref && !ref.contains(event.target as Node)
    );

    if (isOutside) {
      setVisibleDropdown(null); // Close all dropdowns
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = (dropdownName: string) => {
    setVisibleDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    if (dropdownName === "returnCalender" && tripType === "oneway") {
      setTripType(() => "roundtrip");
    }
    setFilteredFrom(() => []);
    setFilteredTo(() => []);
    setFromInput(() => "");
    setToInput(() => "");
  };

  const handleTripTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTripType(event.target.value as "oneway" | "roundtrip");
  };

  const handleCabinClassChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCabinClass(
      event.target.value as "Economy" | "PremiumEconomy" | "Business" | "First"
    );
  };

  // Function to format the date as "6 Dec 2024, Friday"
  const formatDate = (date: Date) => {
    let parsedDate = date;

    if (typeof date === "string") {
      parsedDate = new Date(date); // Convert string to Date
    }

    if (!(parsedDate instanceof Date) || isNaN(parsedDate.getTime())) {
      console.error("Invalid date:", date);
      return "Invalid Date";
    }

    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short", // Abbreviated month (e.g., "Dec")
      year: "numeric", // Full year (e.g., "2024")
      weekday: "long", // Full weekday name (e.g., "Friday")
      // Day of the month (e.g., "6")
    };
    return parsedDate.toLocaleDateString("en-GB", options);
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    destinationType: string
  ) => {
    if (destinationType === "from") {
      setFromInput(event.target.value);
      setFilteredFrom(() => trie.search(event.target.value.toLowerCase()));
    }
    if (destinationType === "to") {
      setToInput(event.target.value);
      setFilteredTo(() => trie.search(event.target.value.toLowerCase()));
    }
  };

  const handleInputClick = (destinationType: string) => {
    if (destinationType === "from") {
      setFilteredTo(() => []);
      setToInput(() => "");
    }
    if (destinationType === "to") {
      setFilteredFrom(() => []);
      setFromInput(() => "");
    }
  };

  const handleAirportSelect = (
    airport: AirportListI,
    destinationType: string
  ) => {
    if (destinationType === "from") {
      if (airport.uniqueID === to!.uniqueID) {
        notifyError("Departure must be different from destination");
      } else {
        setFrom(() => airport);
        setFilteredFrom(() => []);
      }
    }
    if (destinationType === "to") {
      if (airport.uniqueID === from!.uniqueID) {
        notifyError("Destination must be different from departure");
      } else {
        setTo(() => airport);
        setFilteredTo(() => []);
      }
    }
  };

  const handleSwapOriginAndDestination = () => {
    const origin = from;
    setFrom(() => to);
    setTo(() => origin);
  };

  const handleChangeOriginAndDestination = (destinationType: string) => {
    if (destinationType === "from") {
      setFrom({
        name: "",
        city: "",
        country: "",
        iata_code: "",
        _geoloc: {
          lat: 0,
          lng: 0,
        },
        links_count: 0,
        objectID: "",
        uniqueID: "",
      });
      setFromInput(() => "");
      setFilteredTo(() => []);
    }
    if (destinationType === "to") {
      setTo({
        name: "",
        city: "",
        country: "",
        iata_code: "",
        _geoloc: {
          lat: 0,
          lng: 0,
        },
        links_count: 0,
        objectID: "",
        uniqueID: "",
      });
      setToInput(() => "");
      setFilteredFrom(() => []);
    }
  };

  const handleReturnDateChange = (date: Date) => {
    setReturnDate(() => date);
    setSelectedReturnDate(() => date);
  };

  const handleDepartureDateChange = (date: Date) => {
    setDepartureDate(() => date);
  };

  useEffect(() => {
    if (returnDate < departureDate) {
      setReturnDate(departureDate);
      setSelectedReturnDate(
        departureDate instanceof Date ? departureDate : new Date()
      );
    }
  }, [departureDate]);

  const onClickIncrementTravelerBtnHandler = (
    event: React.MouseEvent<HTMLDivElement>,
    travelerType: string
  ) => {
    event.stopPropagation();
    if (
      noOfTravelers.adults + noOfTravelers.children + noOfTravelers.infants >
        0 &&
      noOfTravelers.adults + noOfTravelers.children + noOfTravelers.infants < 9
    ) {
      switch (travelerType) {
        case "adults":
          setNoOfTravelers((prevState) => ({
            ...prevState,
            adults: prevState.adults + 1,
          }));
          break;
        case "children":
          setNoOfTravelers((prevState) => ({
            ...prevState,
            children: prevState.children + 1,
          }));
          break;
        case "infants":
          if (noOfTravelers.adults > noOfTravelers.infants) {
            setNoOfTravelers((prevState) => ({
              ...prevState,
              infants:
                prevState.adults > prevState.infants
                  ? prevState.infants + 1
                  : prevState.infants,
            }));
          } else {
            notifyError(
              "Number of infants should not exceed the number of adults"
            );
          }
          break;
      }
    }
  };

  const onClickDecrementTravelerBtnHandler = (
    event: React.MouseEvent<HTMLDivElement>,
    travelerType: string
  ) => {
    event.stopPropagation();
    if (
      noOfTravelers.adults + noOfTravelers.children + noOfTravelers.infants >
      1
    ) {
      switch (travelerType) {
        case "adults":
          if (noOfTravelers.adults > noOfTravelers.infants) {
            setNoOfTravelers((prevState) => ({
              ...prevState,
              adults: prevState.adults > 1 ? prevState.adults - 1 : 1,
            }));
          } else {
            notifyError(
              "Number of adults should not be less than number of infants."
            );
          }
          break;
        case "children":
          setNoOfTravelers((prevState) => ({
            ...prevState,
            children: prevState.children > 0 ? prevState.children - 1 : 0,
          }));
          break;
        case "infants":
          setNoOfTravelers((prevState) => ({
            ...prevState,
            infants: prevState.infants > 0 ? prevState.infants - 1 : 0,
          }));
          break;
      }
    }
  };

  const submit = () => {
    const searchQuery = {
      from,
      to,
      departureDate,
      returnDate,
      selectedReturnDate,
      tripType,
      cabinClass,
      noOfTravelers,
    };
    if (!from!.uniqueID) {
      notifyError("Please select departure cities ");

      return;
    }
    if (!to!.uniqueID) {
      notifyError("Please select arrival cities");
      return;
    }

    localStorage.setItem(
      "flightSearchKey",
      JSON.stringify(btoa(JSON.stringify(searchQuery)))
    );
    if (tripType === "roundtrip" && departureDate && returnDate) {
      Navigation(
        `/flight/search?origin=${from!.iata_code}&destination=${
          to!.iata_code
        }&trip-type=${tripType}&cabin-class=${cabinClass}&adults=${
          noOfTravelers.adults
        }&children=${noOfTravelers.children}&infants=${
          noOfTravelers.infants
        }&departure-date=${convertDate(
          departureDate
        )}&return-date=${convertDate(returnDate)}`
      );
    } else if (tripType === "oneway" && departureDate) {
      Navigation(
        `/flight/search?origin=${from!.iata_code}&destination=${
          to!.iata_code
        }&trip-type=${tripType}&cabin-class=${cabinClass}&adults=${
          noOfTravelers.adults
        }&children=${noOfTravelers.children}&infants=${
          noOfTravelers.infants
        }&departure-date=${convertDate(departureDate)}`
      );
    }
  };

  // Function to check if data is valid
  const hasValues = (obj: any) => {
    return obj && typeof obj === "object" && Object.keys(obj).length > 0;
  };

  useEffect(() => {
    if (location.pathname === "/") {
      // If the user is on the home route, clear localStorage
      localStorage.clear();
      return;
    }
    const data =
      localStorage.getItem("flightSearchKey") !== null
        ? JSON.parse(localStorage.getItem("flightSearchKey")!)
        : null;

    let parsedData: DataI = {
      tripType: "roundtrip",
      cabinClass: "Economy",
      noOfTravelers: {
        adults: 1,
        children: 0,
        infants: 0,
      },
      departureDate: new Date(departureDate),
      returnDate: new Date(returnDate),
      from: {
        name: "",
        city: "",
        country: "",
        iata_code: "",
        _geoloc: {
          lat: 0,
          lng: 0,
        },
        links_count: 0,
        objectID: "",
        uniqueID: "",
      },
      to: {
        name: "",
        city: "",
        country: "",
        iata_code: "",
        _geoloc: {
          lat: 0,
          lng: 0,
        },
        links_count: 0,
        objectID: "",
        uniqueID: "",
      },
    };

    if (hasValues(data)) {
      parsedData = data ? JSON.parse(atob(`${data}`)) : null;
    } else {
      // Extract query parameters
      const queryParams = new URLSearchParams(location.search);

      const origin = queryParams.get("origin") || ""; // Provide a default value
      const destination = queryParams.get("destination") || ""; // Provide a default value
      parsedData.tripType =
        (queryParams.get("trip-type") as "oneway" | "roundtrip" | "") ||
        "roundtrip";
      parsedData.cabinClass =
        (queryParams.get("cabin-class") as
          | "Economy"
          | "PremiumEconomy"
          | "Business"
          | "First"
          | "") || "Economy";

      // Safely parse numeric values for travelers
      parsedData.noOfTravelers.adults = parseInt(
        queryParams.get("adults") || "1",
        10
      );
      parsedData.noOfTravelers.children = parseInt(
        queryParams.get("children") || "0",
        10
      );
      parsedData.noOfTravelers.infants = parseInt(
        queryParams.get("infants") || "0",
        10
      );

      // Parse and validate dates
      const departureDate = queryParams.get("departure-date");
      parsedData.departureDate = departureDate
        ? new Date(departureDate)
        : new Date(minDepartureDate);

      const returnDate = queryParams.get("return-date");
      parsedData.returnDate =
        parsedData.tripType === "roundtrip" && returnDate
          ? new Date(returnDate)
          : new Date(minReturnDate);

      // Find matching airports
      parsedData.from = airportList.find(
        (airport: AirportListI) => airport.iata_code === origin
      ) || {
        name: "",
        city: "",
        country: "",
        iata_code: "",
        _geoloc: {
          lat: 0,
          lng: 0,
        },
        links_count: 0,
        objectID: "",
        uniqueID: "",
      };

      parsedData.to = airportList.find(
        (airport: AirportListI) => airport.iata_code === destination
      ) || {
        name: "",
        city: "",
        country: "",
        iata_code: "",
        _geoloc: {
          lat: 0,
          lng: 0,
        },
        links_count: 0,
        objectID: "",
        uniqueID: "",
      };
    }
    if (!parsedData) {
      return;
    }
    setFrom(() => parsedData.from);
    setTo(() => parsedData.to);
    setDepartureDate(() => new Date(parsedData.departureDate));
    if (parsedData.tripType === "roundtrip") {
      setReturnDate(() => new Date(parsedData.returnDate || new Date()));
    }
    setSelectedReturnDate(() => new Date(selectedReturnDate));
    setTripType(() => parsedData.tripType);
    setCabinClass(() => parsedData.cabinClass);
    setNoOfTravelers(() => ({
      adults: parsedData.noOfTravelers.adults,
      children: parsedData.noOfTravelers.children,
      infants: parsedData.noOfTravelers.infants,
    }));
  }, [location.pathname]);

  return (
    <div className="flex flex-col gap-y-2 w-[90vw] sm:w-[80vw] rounded-md mx-auto bg-white pb-5 mt-16 md:mt-0">
      {showNav && (
        <div className="bg-gradient-to-r from-[#ec2027] to-[#c81212] rounded-t-md">
          <div className="w-[96%] mx-auto">
            <NavLink
              to={"/"}
              className={({ isActive }) =>
                isActive
                  ? "w-fit flex items-center gap-x-2 px-2 py-2 bg-white text-gray-700 cursor-pointer"
                  : "w-fit flex items-center gap-x-2 px-2 py-2 text-white cursor-pointer"
              }
            >
              <GiCommercialAirplane className="text-sm" />
              <span className="text-sm font-semibold">flights</span>
            </NavLink>
          </div>
        </div>
      )}
      <div className="flex mx-auto w-[96%] divide-x items-center space-x-2">
        <div className="flex items-center gap-x-2 my-2">
          {/* One-way */}
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              value="oneway"
              name="tripType"
              checked={tripType === "oneway"}
              onChange={handleTripTypeChange}
              className="cursor-pointer"
            />
            <span className="text-gray-700 text-sm font-semibold">One-way</span>
          </label>

          {/* Roundtrip */}
          <label className="flex items-center space-x-2">
            <input
              type="radio"
              value="roundtrip"
              name="tripType"
              checked={tripType === "roundtrip"}
              onChange={handleTripTypeChange}
              className="cursor-pointer"
            />
            <span className="text-gray-700 text-sm font-semibold">
              Round-trip
            </span>
          </label>
        </div>
        <div className="hidden sm:block ps-2 relative my-2">
          <p
            className="w-[10rem] flex items-center bg-gradient-to-r from-[#ec2027] to-[#c81212] justify-between capitalize ms-2 text-sm cursor-pointer font-semibold px-4 py-2 text-white"
            onClick={() => toggleDropdown("cabinClassDesktop")}
          >
            {cabinClass}
            {visibleDropdown !== "cabinClassDesktop" && (
              <MdKeyboardArrowDown className="text-xl" />
            )}
            {visibleDropdown === "cabinClassDesktop" && (
              <MdOutlineKeyboardArrowUp className="text-xl" />
            )}
          </p>
          {visibleDropdown === "cabinClassDesktop" && (
            <div
              ref={(el) =>
                el && dropdownRefs.current.set("cabinClassDesktop", el)
              }
              className="w-[10rem] absolute z-20 top-9 ms-2 bg-black/70 text-white py-2 backdrop-blur-sm"
            >
              {/* Economy */}
              <label className="w-full hover:scale-105 cursor-pointer px-4 py-2 flex items-center space-x-2 hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] transition duration-700 ease-in-out">
                <input
                  type="radio"
                  value="Economy"
                  name="cabinClass"
                  checked={cabinClass === "Economy"}
                  onChange={(event) => {
                    handleCabinClassChange(event);
                    toggleDropdown("cabinClassDesktop");
                  }}
                  className="cursor-pointer"
                />
                <span className="text-sm font-semibold">Economy</span>
              </label>

              {/* Business */}
              <label className="w-full hover:scale-105 cursor-pointer px-4 py-2 flex items-center space-x-2 hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] transition duration-700 ease-in-out">
                <input
                  type="radio"
                  value="Business"
                  name="cabinClass"
                  checked={cabinClass === "Business"}
                  onChange={(event) => {
                    handleCabinClassChange(event);
                    toggleDropdown("cabinClassDesktop");
                  }}
                  className="cursor-pointer"
                />
                <span className="text-sm font-semibold">Business</span>
              </label>

              {/* First */}
              <label className="w-full hover:scale-105 cursor-pointer px-4 py-2 flex items-center space-x-2 hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] transition duration-700 ease-in-out">
                <input
                  type="radio"
                  value="First"
                  name="cabinClass"
                  checked={cabinClass === "First"}
                  onChange={(event) => {
                    handleCabinClassChange(event);
                    toggleDropdown("cabinClassDesktop");
                  }}
                  className="cursor-pointer"
                />
                <span className="text-sm font-semibold">First</span>
              </label>
            </div>
          )}
        </div>
      </div>
      <div className="w-[96%] mx-auto relative flex flex-col lg:flex-row items-center lg:items-stretch lg:justify-center ">
        {/*if travelers mobile  view  */}

        <div className="flex sm:hidden w-full divide-x px-2 py-3 relative  items-center justify-start gap-x-2 border border-b-0 cursor-pointer">
          <div className="w-1/2 ps-3 flex items-center gap-x-2">
            <FaUser
              className="text-gray-700 text-base"
              onClick={() => toggleDropdown("travelersMobile")}
            />
            <div onClick={() => toggleDropdown("travelersMobile")}>
              <p className="text-xs text-gray-700 font-semibold">
                {noOfTravelers.adults +
                  noOfTravelers.children +
                  noOfTravelers.infants}
              </p>
              <p className="text-xs text-gray-700">Travelers</p>
            </div>
          </div>
          {visibleDropdown === "travelersMobile" && (
            <div
              ref={(el) =>
                el && dropdownRefs.current.set("travelersMobile", el)
              }
              className="absolute top-14 left-0 z-20 flex flex-col  bg-black/70 text-white w-[10rem] py-2  backdrop-blur-sm"
            >
              <div className="flex items-center justify-around p-2 hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] transition duration-700 ease-in-out hover:scale-105">
                <p className="flex flex-col">
                  <span className="text-xs font-semibold">Adults</span>
                  <span className="text-xs font-thin">12+ years</span>
                </p>
                <PiMinusCircleFill
                  className="text-2xl"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    onClickDecrementTravelerBtnHandler(event, "adults");
                  }}
                />
                <p className="text-sm">{noOfTravelers.adults}</p>
                <PiPlusCircleFill
                  className="text-2xl"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    onClickIncrementTravelerBtnHandler(event, "adults");
                  }}
                />
              </div>
              <div className="flex items-center justify-around p-2 hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] transition duration-700 ease-in-out hover:scale-105">
                <p className="flex flex-col">
                  <span className="text-xs font-semibold">Children</span>
                  <span className="text-xs font-thin">2-11 years</span>
                </p>
                <PiMinusCircleFill
                  className="text-2xl"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    onClickDecrementTravelerBtnHandler(event, "children");
                  }}
                />
                <p className="text-sm">{noOfTravelers.children}</p>
                <PiPlusCircleFill
                  className="text-2xl"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    onClickIncrementTravelerBtnHandler(event, "children");
                  }}
                />
              </div>
              <div className="flex items-center justify-around p-2 hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] transition duration-700 ease-in-out hover:scale-105">
                <p className="flex flex-col">
                  <span className="text-xs font-semibold">Infants</span>
                  <span className="text-xs font-thin">&lt; 2 years</span>
                </p>
                <PiMinusCircleFill
                  className="text-2xl"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    onClickDecrementTravelerBtnHandler(event, "infants");
                  }}
                />
                <p className="text-sm">{noOfTravelers.infants}</p>
                <PiPlusCircleFill
                  className="text-2xl"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    onClickIncrementTravelerBtnHandler(event, "infants");
                  }}
                />
              </div>
              {/* <div className="w-full flex justify-center"> */}
              <button
                className="w-10/12 mx-auto py-2 text-sm bg-gradient-to-r from-[#ec2027] to-[#c81212] my-2"
                onClick={() => toggleDropdown("travelersMobile")}
              >
                Done
              </button>
              {/* </div> */}
            </div>
          )}

          {/* cabinClass mobile view*/}
          <div className="w-1/2  block sm:hidden  relative ">
            <p
              className=" w-[10rem] flex items-center  bg-transparent justify-center capitalize  text-sm cursor-pointer font-semibold   text-gray-700"
              onClick={() => toggleDropdown("cabinClassMobile")}
            >
              {cabinClass}
              {visibleDropdown !== "cabinClassMobile" && (
                <MdKeyboardArrowDown className="text-xl" />
              )}
              {visibleDropdown === "cabinClassMobile" && (
                <MdOutlineKeyboardArrowUp className="text-xl" />
              )}
            </p>
            {visibleDropdown === "cabinClassMobile" && (
              <div
                ref={(el) =>
                  el && dropdownRefs.current.set("cabinClassMobile", el)
                }
                className="w-[8rem] absolute z-20 top-9 ms-2 bg-black/70 text-white py-2 backdrop-blur-sm"
              >
                {/* Economy */}
                <label className="w-full hover:scale-105 cursor-pointer px-4 py-2 flex items-center space-x-2 hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] transition duration-700 ease-in-out">
                  <input
                    type="radio"
                    value="Economy"
                    name="cabinClass"
                    checked={cabinClass === "Economy"}
                    onChange={(event) => {
                      handleCabinClassChange(event);
                      toggleDropdown("cabinClassMobile");
                    }}
                    className="cursor-pointer"
                  />
                  <span className="text-sm font-semibold">Economy</span>
                </label>

                {/* Business */}
                <label className="w-full hover:scale-105 cursor-pointer px-4 py-2 flex items-center space-x-2 hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] transition duration-700 ease-in-out">
                  <input
                    type="radio"
                    value="Business"
                    name="cabinClass"
                    checked={cabinClass === "Business"}
                    onChange={(event) => {
                      handleCabinClassChange(event);
                      toggleDropdown("cabinClassMobile");
                    }}
                    className="cursor-pointer"
                  />
                  <span className="text-sm font-semibold">Business</span>
                </label>

                {/* First */}
                <label className="w-full hover:scale-105 cursor-pointer px-4 py-2 flex items-center space-x-2 hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] transition duration-700 ease-in-out">
                  <input
                    type="radio"
                    value="First"
                    name="cabinClass"
                    checked={cabinClass === "First"}
                    onChange={(event) => {
                      handleCabinClassChange(event);
                      toggleDropdown("cabinClassMobile");
                    }}
                    className="cursor-pointer"
                  />
                  <span className="text-sm font-semibold">First</span>
                </label>
              </div>
            )}
          </div>
        </div>

        <div className="lg:w-1/2 w-full flex flex-col sm:flex-row justify-center  ">
          <div className="w-full sm:w-1/2 flex ps-5  relative items-center  gap-x-2 border p-1">
            <FaLocationDot className="text-gray-700 text-base" />
            {from!.uniqueID && (
              <div
                className="py-2"
                onClick={() => handleChangeOriginAndDestination("from")}
              >
                <p className="text-xs text-gray-700 font-semibold">
                  {from!.iata_code}
                </p>
                <p className="text-xs text-gray-700">
                  {`${from!.name}, ${from!.city}, ${from!.country}`.length > 30
                    ? `${from!.name}, ${from!.city}, ${from!.country}`.slice(
                        0,
                        30
                      )
                    : `${from!.name}, ${from!.city}, ${from!.country}`}
                </p>
              </div>
            )}
            {!from!.uniqueID && (
              <input
                type="text"
                name="from"
                id="from"
                value={fromInput}
                className="w-full px-1 py-3.5 outline-none text-sm bg-transparent text-gray-700"
                placeholder="From"
                onChange={(event) => handleInputChange(event, "from")}
                onClick={() => handleInputClick("from")}
                autoFocus
              />
            )}
            {filteredFrom.length > 0 && (
              <ul className="absolute top-14 py-2 -ms-1 z-20 bg-black/70 text-white lg:w-[25rem] max-h-60 overflow-y-auto overflow-x-hidden backdrop-blur-sm">
                {filteredFrom.map((filteredAirportList: AirportListI) => (
                  <li
                    key={filteredAirportList.uniqueID}
                    className="text-sm px-5 py-2 flex items-center hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] transition duration-700 ease-in-out hover:scale-105 gap-x-3 cursor-pointer"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleAirportSelect(filteredAirportList, "from");
                    }}
                  >
                    <GiAirplaneDeparture className="text-lg" />
                    <div>
                      <p className="text-sm">
                        {filteredAirportList.iata_code} -{" "}
                      </p>
                      <p className="text-xs">
                        {filteredAirportList.name.length > 20
                          ? `${filteredAirportList.name.slice(0, 20)}...`
                          : filteredAirportList.name}
                        , {filteredAirportList.city},{" "}
                        {filteredAirportList.country}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            <div className="absolute  lg:-right-[8%] right-[45%] sm:-right-[5%] top-[65%] sm:top-[25%] rotate-90 sm:rotate-0 bg-white z-10 border w-fit p-2 rounded-full cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] hover:text-white">
              <AiOutlineSwap onClick={handleSwapOriginAndDestination} />
            </div>
          </div>

          <div className="w-full sm:w-1/2 ps-5 relative flex items-center gap-x-1 border sm:border border-t-0 sm:border-l-0 p-1">
            <FaLocationDot className="text-gray-700 text-base" />
            {to!.uniqueID && (
              <div
                className="py-2"
                onClick={() => handleChangeOriginAndDestination("to")}
              >
                <p className="text-xs text-gray-700 font-semibold">
                  {to!.iata_code}
                </p>
                <p className="text-xs text-gray-700">
                  {`${to!.name}, ${to!.city}, ${to!.country}`.length > 30
                    ? `${to!.name}, ${to!.city}, ${to!.country}`.slice(0, 30)
                    : `${to!.name}, ${to!.city}, ${to!.country}`}
                </p>
              </div>
            )}
            {!to!.uniqueID && (
              <input
                type="text"
                name="to"
                id="to"
                placeholder="To"
                value={toInput}
                className="w-full px-1 py-3.5 outline-none text-gray-700 bg-transparent text-sm"
                onChange={(event) => handleInputChange(event, "to")}
                onClick={() => handleInputClick("to")}
                autoFocus
              />
            )}
            {filteredTo.length > 0 && (
              <ul className="absolute py-2 top-14 -ms-5 z-20 bg-black/70 text-white lg:w-[25rem] max-h-60 overflow-y-auto overflow-x-hidden backdrop-blur-sm">
                {filteredTo.map((filteredAirportList: AirportListI) => (
                  <li
                    key={filteredAirportList.uniqueID}
                    className="text-sm px-5 py-2 flex items-center hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] transition duration-700 ease-in-out hover:scale-105 gap-x-3 cursor-pointer"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleAirportSelect(filteredAirportList, "to");
                    }}
                  >
                    <GiAirplaneArrival className="text-lg" />
                    <div>
                      <p className="text-sm">
                        {filteredAirportList.iata_code} -{" "}
                      </p>
                      <p className="text-xs">
                        {filteredAirportList.name.length > 20
                          ? `${filteredAirportList.name.slice(0, 20)}...`
                          : filteredAirportList.name}
                        , {filteredAirportList.city},{" "}
                        {filteredAirportList.country}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="lg:w-1/2 w-full flex flex-col sm:flex-row justify-center ">
          <div className="w-full sm:w-[70%] p-1 relative flex items-center gap-x-1 border border-t-0 sm:border-t  lg:border-l-0 divide-x cursor-pointer">
            <div className="w-1/2 relative gap-x-2 flex items-center justify-center cursor-pointer">
              <div
                className="w-full flex items-center gap-2 justify-center item-center "
                onClick={() => toggleDropdown("departureCalender")}
              >
                <span>
                  <IoCalendar className="text-gray-700 text-base cursor-pointer" />
                </span>

                <span className="py-2  cursor-pointer">
                  <p className="text-xs text-gray-700 font-semibold cursor-pointer">
                    {
                      formatDate(
                        departureDate instanceof Date
                          ? departureDate
                          : new Date()
                      ).split(" ")[1]
                    }{" "}
                    {
                      formatDate(
                        departureDate instanceof Date
                          ? departureDate
                          : new Date()
                      ).split(" ")[2]
                    }{" "}
                    {
                      formatDate(
                        departureDate instanceof Date
                          ? departureDate
                          : new Date()
                      ).split(" ")[3]
                    }
                  </p>
                  <p className="text-xs text-gray-700 cursor-pointer">
                    {
                      formatDate(
                        departureDate instanceof Date
                          ? departureDate
                          : new Date()
                      ).split(" ")[0]
                    }
                  </p>
                </span>
              </div>
              {visibleDropdown === "departureCalender" && (
                <div
                  ref={(el) =>
                    el && dropdownRefs.current.set("departureCalender", el)
                  }
                  className="w-[18rem]  sm:w-[20rem] absolute z-20 -top-52 sm:top-3 left-px"
                >
                  <Calendar
                    initialDate={new Date(departureDate)}
                    selectedReturnDate={new Date(departureDate)}
                    dateType="departureDate"
                    onDateChange={(date) => {
                      handleDepartureDateChange(date);
                      toggleDropdown("departureCalender");
                    }}
                  />
                </div>
              )}
            </div>
            <div className="w-1/2 relative gap-x-2 flex items-center justify-center cursor-pointer">
              <div
                className="w-full flex items-center gap-2 justify-center item-center "
                onClick={() => toggleDropdown("returnCalender")}
              >
                <span>
                  <IoCalendar className="text-gray-700 text-base" />
                </span>
                <span className="py-2 cursor-pointer">
                  {tripType === "roundtrip" && (
                    <>
                      <p className="text-xs text-gray-700 font-semibold cursor-pointer">
                        {
                          formatDate(
                            returnDate instanceof Date ? returnDate : new Date()
                          ).split(" ")[1]
                        }{" "}
                        {
                          formatDate(
                            returnDate instanceof Date ? returnDate : new Date()
                          ).split(" ")[2]
                        }{" "}
                        {
                          formatDate(
                            returnDate instanceof Date ? returnDate : new Date()
                          ).split(" ")[3]
                        }
                      </p>
                      <p className="text-xs text-gray-700 cursor-pointer">
                        {
                          formatDate(
                            returnDate instanceof Date ? returnDate : new Date()
                          ).split(" ")[0]
                        }
                      </p>
                    </>
                  )}
                  <div onClick={() => toggleDropdown("returnCalender")}>
                    {tripType === "oneway" && (
                      <p className="text-xs font-semibold text-gray-700">
                        Return Date
                      </p>
                    )}
                  </div>
                </span>
              </div>

              {tripType === "roundtrip" &&
                visibleDropdown === "returnCalender" && (
                  <div
                    ref={(el) =>
                      el && dropdownRefs.current.set("returnCalender", el)
                    }
                    className="w-[18rem]  sm:w-[20rem] absolute z-20 -top-52 sm:top-3 right-1.5 sm:right-auto"
                  >
                    <Calendar
                      initialDate={new Date(departureDate)}
                      selectedReturnDate={new Date(selectedReturnDate)}
                      dateType="returnDate"
                      onDateChange={(date) => {
                        handleReturnDateChange(date);
                        toggleDropdown("returnCalender");
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
          <div className="hidden sm:flex w-full sm:w-[30%] px-2 py-3 relative  items-center justify-center gap-x-2 border border-l-0 cursor-pointer">
            <FaUser
              className="text-gray-700 text-base"
              onClick={() => toggleDropdown("travelersDesktop")}
            />
            <div onClick={() => toggleDropdown("travelersDesktop")}>
              <p className="text-xs text-gray-700 font-semibold">
                {noOfTravelers.adults +
                  noOfTravelers.children +
                  noOfTravelers.infants}
              </p>
              <p className="text-xs text-gray-700">Travelers</p>
            </div>
            {visibleDropdown === "travelersDesktop" && (
              <div
                ref={(el) =>
                  el && dropdownRefs.current.set("travelersDesktop", el)
                }
                className="absolute top-14 z-10 flex flex-col bg-black/70 text-white w-[10rem] py-2 backdrop-blur-sm"
              >
                <div className="flex items-center justify-around p-2 hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] transition duration-700 ease-in-out hover:scale-105">
                  <p className="flex flex-col">
                    <span className="text-xs font-semibold">Adults</span>
                    <span className="text-xs font-thin">12+ years</span>
                  </p>
                  <PiMinusCircleFill
                    className="text-2xl"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      onClickDecrementTravelerBtnHandler(event, "adults");
                    }}
                  />
                  <p className="text-sm">{noOfTravelers.adults}</p>
                  <PiPlusCircleFill
                    className="text-2xl"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      onClickIncrementTravelerBtnHandler(event, "adults");
                    }}
                  />
                </div>
                <div className="flex items-center justify-around p-2 hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] transition duration-700 ease-in-out hover:scale-105">
                  <p className="flex flex-col">
                    <span className="text-xs font-semibold">Children</span>
                    <span className="text-xs font-thin">2-11 years</span>
                  </p>
                  <PiMinusCircleFill
                    className="text-2xl"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      onClickDecrementTravelerBtnHandler(event, "children");
                    }}
                  />
                  <p className="text-sm">{noOfTravelers.children}</p>
                  <PiPlusCircleFill
                    className="text-2xl"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      onClickIncrementTravelerBtnHandler(event, "children");
                    }}
                  />
                </div>
                <div className="flex items-center justify-around p-2 hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] transition duration-700 ease-in-out hover:scale-105">
                  <p className="flex flex-col">
                    <span className="text-xs font-semibold">Infants</span>
                    <span className="text-xs font-thin">&lt; 2 years</span>
                  </p>
                  <PiMinusCircleFill
                    className="text-2xl"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      onClickDecrementTravelerBtnHandler(event, "infants");
                    }}
                  />
                  <p className="text-sm">{noOfTravelers.infants}</p>
                  <PiPlusCircleFill
                    className="text-2xl"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      onClickIncrementTravelerBtnHandler(event, "infants");
                    }}
                  />
                </div>

                {/* <div className="w-full flex justify-center"> */}
                <button
                  className="w-10/12 mx-auto py-2 text-sm bg-gradient-to-r from-[#ec2027] to-[#c81212] my-2"
                  onClick={() => toggleDropdown("travelersDesktop")}
                >
                  Done
                </button>
                {/* </div> */}
              </div>
            )}
          </div>
        </div>
        <button
          type="button"
          className=" bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white w-full mt-4 lg:mt-0 sm:w-[8rem] p-[.8rem] text-sm font-semibold"
          onClick={submit}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default FlightSearchInputFields;
