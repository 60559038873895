import {
  FlightDetailsI,
  FlightSegmentsDetailsI,
} from "../types/flightListTypes";

export const getAvailableAirlines = (flightsDetails: FlightDetailsI[]) => {
  const uniqueAirlines = new Set<string>();

  flightsDetails.forEach((flightDetail) => {
    // Combine outbound and inbound trips
    const airlines = [
      ...flightDetail.outboundTrip.map(
        (outbound: FlightSegmentsDetailsI) => outbound.airline
      ),
      ...(flightDetail.inboundTrip?.map(
        (inbound: FlightSegmentsDetailsI) => inbound.airline
      ) || []),
    ];

    // Add airlines to the Set (automatically handles duplicates)
    airlines.forEach((airline) => uniqueAirlines.add(airline));
  });

  return Array.from(uniqueAirlines); // Convert Set back to an array
};

export function convertTimeToMinutes(timeString: string) {
  // Extract hours and minutes using a regular expression
  const timeRegex = /(\d+)h\s*(\d*)m?/; // Matches patterns like '7h 5m', '7h', '5m', etc.
  const match = timeString.match(timeRegex);

  if (!match) {
    throw new Error(
      "Invalid time format. Please provide time in 'Xh Ym' format."
    );
  }

  const hours = parseInt(match[1] || "0", 10); // Default to 0 if hours are missing
  const minutes = parseInt(match[2] || "0", 10); // Default to 0 if minutes are missing

  return hours * 60 + minutes; // Convert hours to minutes and add
}

export const getLayoverDuration = (
  flightDetails: FlightDetailsI[],
  tripType: "outbound" | "inbound"
) => {
  const limit: number[] = [];

  flightDetails.forEach((flightDetail: FlightDetailsI) => {
    if (tripType === "outbound") {
      flightDetail.outboundTrip.forEach((outbound: FlightSegmentsDetailsI) => {
        if (outbound.layoverTime) {
          const timeRegex = /(\d+)h\s*(\d*)m?/; // Matches patterns like '7h 5m', '7h', '5m', etc.
          const match = outbound.layoverTime.match(timeRegex);
          if (match) {
            const hours = parseInt(match[1] || "0", 10); // Default to 0 if hours are missing
            const minutes = parseInt(match[2] || "0", 10); // Default to 0 if minutes are missing

            limit.push(hours * 60 + minutes);
          }
        }
      });
    } else if (tripType === "inbound") {
      flightDetail.inboundTrip?.forEach((inbound: FlightSegmentsDetailsI) => {
        if (inbound.layoverTime) {
          const timeRegex = /(\d+)h\s*(\d*)m?/; // Matches patterns like '7h 5m', '7h', '5m', etc.
          const match = inbound.layoverTime.match(timeRegex);
          if (match) {
            const hours = parseInt(match[1] || "0", 10); // Default to 0 if hours are missing
            const minutes = parseInt(match[2] || "0", 10); // Default to 0 if minutes are missing

            limit.push(hours * 60 + minutes);
          }
        }
      });
    }
  });

  // Check if limit is empty
  if (limit.length === 0) {
    return { min: null, max: null }; // Return null or a default value
  }
  return { min: Math.min(...limit), max: Math.max(...limit) };
};

export const getJourneyDuration = (
  flightDetails: FlightDetailsI[],
  tripType: "outbound" | "inbound"
) => {
  const limit: number[] = [];

  flightDetails.forEach((flightDetail: FlightDetailsI) => {
    if (tripType === "outbound") {
      flightDetail.outboundTrip.forEach((outbound: FlightSegmentsDetailsI) => {
        if (outbound.journeyTime) {
          limit.push(outbound.journeyTime);
        }
      });
    } else if (tripType === "inbound") {
      flightDetail.inboundTrip?.forEach((inbound: FlightSegmentsDetailsI) => {
        if (inbound.journeyTime) {
          limit.push(inbound.journeyTime);
        }
      });
    }
  });

  return { min: Math.min(...limit), max: Math.max(...limit) };
};
