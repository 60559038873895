import { Link } from "react-router-dom";
import HeroNav from "../components/homeComponent/HeroNav.component";
import { FlightBanner } from "../utils/images";
import ScrollToTop from "../components/ScrollToTop";
import Breadcrumb from "../components/Includes/BreadCrumbs";

const Privacy = () => {
  return (
    <>
      <ScrollToTop />
      <div
        className={`w-full h-full md:h-[35rem] lg:h-[15rem] bg-cover bg-no-repeat bg-center`}
        style={{ backgroundImage: `url(${FlightBanner})` }}
      >
        <HeroNav />
        <h1 className="text-white text-4xl sm:text-7xl font-bold w-[70vw] mx-auto mt-10">
          Privacy & Policy
        </h1>
      </div>
      <div className="w-[94%] sm:w-[70vw] mx-auto py-2">
        <Breadcrumb />
      </div>
      <div className="flex flex-col gap-y-4 w-[94%] sm:w-[70vw] mx-auto my-2 sm:my-10 text-justify">
        <p className="text-base text-gray-900 font-medium">
          This document describes the privacy policies of{" "}
          <Link to="/" className="font-bold">
            dreamstravelandtours.com
          </Link>{" "}
          that are used to protect your personal information.
        </p>
        <p className="text-base text-gray-900 font-medium">
          We would like to assure you that we follow appropriate standards when
          it comes to shielding your privacy on our websites. Data security is
          our highest priority. Hence, we aspire to safeguard all the
          information, which is provided to us by our customers through
          contacting us directly, over the phone, or website. By visiting or
          using our website, you are agreeing to the following terms &
          conditions:
        </p>
        <ul className="flex flex-col gap-y-2 text-sm text-gray-700 font-medium list-disc ps-5">
          <li>
            Irrespective of various input sources, every detail entered on this
            website is stored by our systems. It contains your identity
            information such as e-mail address, first name, last name, contact
            number. And in a couple of cases, it may include billing information
            (e.g., debit/credit card number, name of the cardholder, and expiry
            date of the debit/credit card, and some personal information). We
            may store your preferences related to meal, seat selection,
            ticketing, or hotel/car rental, etc. Usually, you have an option to
            deny, providing some of your information that is not related to
            registration or any other important task.
          </li>
          <li>
            While booking travel arrangements for someone else through us, you
            will be required to input the personal as well as travel related
            information for that particular person.
          </li>
          <li>
            With the help of third parties and our business partners, we may
            intermittently collect your information and store them in our
            systems. The information may be related to travel preferences or
            customer address, etc.
          </li>
          <li>
            Some of your information is automatically saved in our system during
            your visits to our website (s) through cookies, cache or other web
            methodology.
          </li>
          <li>
            <Link to="/" className="font-bold">
              dreamstravelandtours.com
            </Link>{" "}
            makes use of billing information including name of the cardholder,
            credit/debit card number, expiry date of credit/debit card.
          </li>
          <li>
            Your information may be shared by us with various suppliers who
            fulfil your travel requirements. And, it can also be shared in
            response to court orders or legal processes.
          </li>
          <li>
            We are aimed to make you feel confident regarding our travel
            pricing. Hence, our core responsibility is to safeguard the
            information, which is collected by us. Thus, we use data encryption
            methodology while transmitting your personal information online.
          </li>
          <li>
            The products or services offered by{" "}
            <Link to="/" className="font-bold">
              dreamstravelandtours.com
            </Link>{" "}
            could not be purchased by children.
          </li>
          <li>
            If any link on our website redirects you to any other website, then
            this privacy policy does not get applied on that particular website.
          </li>
          <li>
            In order to get in touch for any query related to privacy policy,
            you can connect with us through our official email id.
          </li>
          <li>
            We do not, and will never ask for the information pertaining to your
            credit card, login name or even password through an unsolicited mode
            of communication.
          </li>
        </ul>
      </div>
    </>
  );
};

export default Privacy;
