import HeroNav from "../components/homeComponent/HeroNav.component";
import ScrollToTop from "../components/ScrollToTop";
import { FlightBanner } from "../utils/images";

const HowWeWork = () => {
  return (
    <>
      <ScrollToTop />
      <div
        className={`w-full h-full md:h-[35rem] lg:h-[15rem] bg-cover bg-no-repeat bg-center`}
        style={{ backgroundImage: `url(${FlightBanner})` }}
      >
        <HeroNav />
        <h1 className="text-white text-7xl font-bold w-[70vw] mx-auto mt-10">
          About US
        </h1>
      </div>
      <div>This is how we work page.....</div>;
    </>
  );
};

export default HowWeWork;
