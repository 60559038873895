/* eslint-disable react/jsx-no-comment-textnodes */
import { useState } from "react";
import HeroNav from "../components/homeComponent/HeroNav.component";
import { Banner, FlightBanner } from "../utils/images";
import { contactInfo } from "../dummydata/contactInfo";
import { Link } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet-async";
import Breadcrumb from "../components/Includes/BreadCrumbs";

type FormData = {
  username: string;
  contactNumber: string;
  email: string;
  query: string;
};

const ContactUs = () => {
  const [formData, setFormData] = useState<FormData>({
    username: "",
    contactNumber: "",
    email: "",
    query: "",
  });

  const [formErrors, setFormErrors] = useState<Partial<FormData>>({});
  const [submitted, setSubmitted] = useState(false);

  const validateForm = (): boolean => {
    const errors: Partial<FormData> = {};
    if (!formData.username.trim()) errors.username = "Username is required";
    if (!formData.contactNumber.trim())
      errors.contactNumber = "Contact number is required";
    if (!/^\d{10}$/.test(formData.contactNumber))
      errors.contactNumber = "Enter a valid 10-digit contact number";
    if (!formData.email.trim()) errors.email = "Email is required";
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email))
      errors.email = "Enter a valid email address";
    if (!formData.query.trim()) errors.query = "Query is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const fetchBlog = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_POSTQUERY}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      fetchBlog();
      setSubmitted(true);

      // Reset the form after submission
      setFormData({ username: "", contactNumber: "", email: "", query: "" });
      setFormErrors({});
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="contact us for flight booking" />
        <meta name="keywords" content="flight,booking,contact us" />
        <meta property="og:title" content="Dreams tavel and tours" />
        <meta
          property="og:description"
          content="contact us for flight booking"
        />
        <meta property="og:type" content="article" />
      </Helmet>
      <ScrollToTop />
      <div
        className={`w-full h-full md:h-[35rem] lg:h-[15rem] bg-cover bg-no-repeat bg-center`}
        style={{ backgroundImage: `url(${FlightBanner})` }}
      >
        <HeroNav />
        <h1 className="text-white text-4xl sm:text-7xl font-bold w-[70vw] mx-auto mt-10">
          Contact Us
        </h1>
      </div>
      <div className="w-[94%] sm:w-[70vw] mx-auto py-2">
        <Breadcrumb />
      </div>
      <div className=" flex lg:flex-row flex-col-reverse w-[100%] sm:w-[75%] gap-x-2 mx-auto sm:my-10">
        <div className=" w-full lg:w-[60%] flex flex-col items-center p-6 bg-white shadow-lg rounded-lg  mx-auto">
          {/* Google Map Embed */}
          <div className="w-full h-64 mb-6">
            <iframe
              title="Google Map Location of Dtt.inc"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3237.068734854578!2d-74.06015612389501!3d40.789734032853005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c257c166029e25%3A0xde3a0d61d2eca281!2s1279%20Paterson%20Plank%20Rd%2C%20Secaucus%2C%20NJ%2007094%2C%20USA!5e1!3m2!1sen!2sin!4v1736322240452!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          {/* Contact Details */}
          <div className="flex flex-col w-full text-left">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Dreams Travel and Tours
            </h2>
            <p className="text-gray-700">
              1279 Paterson Plank Rd
              <br />
              Secaucus NJ 07094
            </p>

            <div className="mt-4 pr-24  sm:pr-12 text-sm xs:text-base">
              <p className="text-gray-800 font-semibold grid grid-cols-2">
                <span>Phone No. :- </span>
                <Link
                  to={`tel:${contactInfo.mobileNum}`}
                  className="text-gray-800 font-semibold"
                >
                  <span className="font-normal">{contactInfo.mobileNum}</span>
                </Link>
              </p>
              <p className="text-gray-800 font-semibold grid grid-cols-2 ">
                <span className="">Email Id :- </span>
                <Link
                  to={`mailto:${contactInfo.email}`}
                  className=" font-normal pr-10"
                >
                  {contactInfo.email}
                </Link>
              </p>
              <p className="text-gray-800 font-semibold grid grid-cols-2">
                <span>Website :- </span>
                <Link
                  to="/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 font-normal"
                >
                  dreamstravelandtours.com
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="lg:w-[40%] w-full mx-auto p-6 bg-white shadow-lg rounded-lg">
          <h2 className="text-2xl font-semibold mb-6 text-gray-800 text-center">
            Contact Us
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-medium mb-2"
                htmlFor="username"
              >
                Full Name
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none"
              />
              {formErrors.username && (
                <p className="text-red-500 text-sm">{formErrors.username}</p>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 font-medium mb-2"
                htmlFor="contactNumber"
              >
                Contact Number
              </label>
              <input
                type="text"
                id="contactNumber"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none"
              />
              {formErrors.contactNumber && (
                <p className="text-red-500 text-sm">
                  {formErrors.contactNumber}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 font-medium mb-2"
                htmlFor="email"
              >
                Email ID
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none"
              />
              {formErrors.email && (
                <p className="text-red-500 text-sm">{formErrors.email}</p>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 font-medium mb-2"
                htmlFor="query"
              >
                Query
              </label>
              <textarea
                id="query"
                name="query"
                value={formData.query}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none"
                rows={4}
              ></textarea>
              {formErrors.query && (
                <p className="text-red-500 text-sm">{formErrors.query}</p>
              )}
            </div>

            <button
              type="submit"
              className="w-full bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            >
              Send Now
            </button>
          </form>
          {submitted && (
            <p className="mt-4 text-green-500 font-semibold text-center">
              Thank you! Your query has been sent.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default ContactUs;
