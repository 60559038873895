export const reviews = [
  {
    id: 1,
    name: "Kola Gaza",
    message: `Thank you Dreams Travel Team for your continued support, it's a delightful experience working with you and appreciate the excellent service and help we are receiving from you.`,
    rating: 5,
  },
  {
    id: 2,
    name: "Puneet Gurnani",
    message: `Prompt service. My call was answered immediately. The agent, Martin was knowledgeable and made sure to follow up with rates and fares quickly. Will do business again!`,
    rating: 5,
  },
  {
    id: 3,
    name: "Varadhan Narasimhan",
    message: `Dreams travel is always my best source of purchasing international tickets for my family. Great service from Praveen and his colleagues.`,
    rating: 5,
  },
  {
    id: 4,
    name: "Assad Iqbal",
    message: `Most reliable flight booking service.All credit goes to martin who is available at all the crucial moments and act very promptly.`,
    rating: 5,
  },
  {
    id: 5,
    name: "Vanitha",
    message: `Every time we book our flight with this company and contact person is Praveen.`,
    rating: 5,
  },
  {
    id: 6,
    name: "Kamil Lamidi",
    message: `Making reservations was seamless and follow up when needed was prompt.`,
    rating: 5,
  },
  {
    id: 7,
    name: "Ade Fash",
    message: `I have been a customer over 10 years now. That says a lot about the company`,
    rating: 5,
  },
];
