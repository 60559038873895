import { useParams } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import { useEffect, useState } from "react";
import { BlogI } from "../types/blogs.types";
import parse from "html-react-parser";
import LoadingComponent from "../components/Loading.component";
import NoDataFoundComponent from "../components/NoDataFound.component";
import { Helmet } from "react-helmet-async";
import { FaMinus, FaPlus } from "react-icons/fa6";
import ContactBanner from "../components/Banner/ContactBanner";
import CallBtn from "../components/Buttons/CallBtn";
import Breadcrumb from "../components/Includes/BreadCrumbs";
import { FlightBanner } from "../utils/images";
import BannerNav from "../components/BannerNav";
import FlightSearchInputFields from "../components/Flights/Includes/FlightSearchInputFields";

const TopAirlines = () => {
  const { url } = useParams<{ id: string; url: string }>();

  const [blog, setBlog] = useState<BlogI | null>(null);
  const [status, setStatus] = useState<
    "loading" | "success" | "error" | "idle"
  >("idle");
  const [active, setActive] = useState(null);

  const toggle = (i: any) => {
    if (active === i) {
      return setActive(null);
    }
    setActive(i);
  };

  const fetchBlog = async (url: string) => {
    try {
      setStatus("loading");
      const response = await fetch(
        `${process.env.REACT_APP_GETBLOGBYURL}?url=${encodeURIComponent(
          url.replace(/-/g, " ")
        )}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (!data.success) {
        setStatus("error");
        return;
      }
      setBlog(() => data.data[0]);
      setStatus("success");
    } catch (error) {
      setStatus("error");
    }
  };

  useEffect(() => {
    if (url) {
      fetchBlog(url);
    }
  }, [url]);

  return (
    <>
      <Helmet>
        <title>{blog?.metaTitle}</title>
        <meta name="description" content={blog?.metaDescription} />
        <meta name="keywords" content={blog?.metaKeyword} />
        <meta property="og:title" content={blog?.metaTitle} />
        <meta property="og:description" content={blog?.metaDescription} />
        <meta property="og:type" content="article" />
      </Helmet>
      <ScrollToTop />
      <div className="relative flex-shrink-0 w-full h-[40rem] sm:h-[30rem] lg:h-[25rem] z-50">
        {/* Banner Image */}
        <img
          src={`${process.env.REACT_APP_AIRLINE_IMAGE_URL}/${blog?.imagePath}`}
          alt="Banner"
          className="w-full h-full object-cover"
          onError={(e) => {
            (e.target as HTMLImageElement).src = FlightBanner; // Handle broken images
          }}
        />

        {/* Navigation */}
        <div className="absolute top-0 left-1/2 -translate-x-1/2 mt-5 sm:mt-2">
          <BannerNav />
        </div>

        {/* Title and Search Fields */}
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 space-y-6 px-4 sm:px-6">
          {/* Page Title */}
          <p className="text-white text-center text-2xl sm:text-3xl lg:text-4xl font-bold drop-shadow-md capitalize">
            {blog?.url}
          </p>

          {/* Flight Search Fields */}
          <FlightSearchInputFields showNav={false} />
        </div>
      </div>

      <div className="w-[94%] sm:w-[70vw] mx-auto py-2">
        <Breadcrumb />
      </div>
      {status === "loading" && <LoadingComponent />}
      {status === "success" && blog && (
        <>
          <div className="flex flex-col prose lg:prose-xl prose-blue mb-10 w-[90vw] mx-auto">
            {blog && parse(blog.content)}
          </div>
          <div className="w-[98vw] lg:w-[80vw] xl:w-[70vw] mx-auto my-5">
            <div className="hidden xs:block">
              <ContactBanner />
            </div>
            <div className=" xs:hidden">
              <CallBtn />
            </div>
          </div>
          {blog.faqs.length > 0 && (
            <div className="flex flex-col justify-center items-center w-[95%] md:w-[58vw] mx-auto">
              <h2 className="text-gray-700 capitalize font-bold text-2xl mb-5">
                <span className="font-thin">Frequently Asked </span>
                <span>Questions</span>
              </h2>
              <div className="w-full">
                {blog.faqs.map((faq, index) => (
                  <div
                    className="bg-gradient-to-r from-[#ec2027] to-[#c81212] mb-2 text-white px-2 py-2 cursor-pointer rounded-lg"
                    key={index}
                    onClick={() => toggle(index)}
                  >
                    <div className="flex items-center justify-between">
                      <h1 className="md:text-lg font-semibold">
                        {faq.question ? faq.question : "Question 1"}
                      </h1>
                      <div className="bg-[#ffffff] rounded-full flex items-center p-1 md:p-2 cursor-pointer">
                        {active === index ? (
                          <FaMinus className="text-black text-sm md:text-base" />
                        ) : (
                          <FaPlus className="text-black text-sm md:text-base" />
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        active === index
                          ? "w-[90%] h-auto max-h-[999px] transition-all text-sm md:text-base duration-300 mt-2"
                          : "w-[90%] max-h-0 overflow-hidden transition-all text-sm md:text-base duration-300"
                      }
                    >
                      {faq.answer
                        ? faq.answer
                        : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse, cupiditate! Aliquid repudiandae, aspernatur totam nobis temporibus officia placeat explicabo tempora incidunt, fugiat mollitia ducimus voluptas ad nulla itaque id autem unde! Fuga suscipit pariatur rem est minus dolores doloremque harum quae molestiae nostrum minima, blanditiis eaque tempore? Odit modi dolorem provident eaque voluptates beatae tempora expedita doloribus eligendi libero."}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
      {status === "error" && (
        <div className="w-[98vw] lg:w-[80vw] xl:w-[70vw] mx-auto my-5">
          <NoDataFoundComponent />
        </div>
      )}
    </>
  );
};

export default TopAirlines;
