import React, { useEffect, useState } from "react";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { FlightDetailsI, TravelersI } from "../../types/flightListTypes";

interface FlightDetailsPriceCardProp {
  flightDetails: FlightDetailsI;
  noOfTravelers: TravelersI;
}

const FlightDetailsPriceCard: React.FC<FlightDetailsPriceCardProp> = ({
  flightDetails,
  noOfTravelers,
}) => {
  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    // Check the scroll position and update the state
    if (window.scrollY > 200) {
      setIsFixed(true); // Change to fixed position after scrolling down 200px
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`${
        isFixed
          ? "md:w-[50vw] lg:w-[25vw] lg:fixed bg-white h-fit right-14 top-2"
          : "md:w-[50vw] lg:w-[25vw] lg:fixed bg-white h-fit right-14"
      } transition-all ease-in-out duration-300`}
    >
      <div className="w-full h-fit shadow-md border-[1px] rounded-md">
        <div className="w-full flex items-center bg-gradient-to-l from-[#ec2027] to-[#c81212] h-[3rem] p-2 gap-3 rounded-t-md">
          <FaMoneyCheckDollar className="text-2xl text-white" />
          <h2 className="text-sm md:text-lg font-medium text-white">
            Price Summary
          </h2>
        </div>
        <div className="border-b-[1px] flex w-full justify-between items-center p-2">
          <p className="text-sm font-medium text-gray-700">
            Adult x {noOfTravelers.adults}
          </p>
          <p className="text-sm font-bold text-gray-700">
            {flightDetails &&
              `$${flightDetails.adtFare * noOfTravelers.adults}`}
          </p>
        </div>
        {flightDetails && noOfTravelers.children > 0 && (
          <div className="border-b-[1px] flex w-full justify-between items-center p-2">
            <p className="text-sm font-medium text-gray-700">
              Children x {noOfTravelers.children}
            </p>
            <p className="text-sm font-medium text-gray-700">{`$${
              flightDetails.chdFare * noOfTravelers.children
            }`}</p>
          </div>
        )}
        {flightDetails && noOfTravelers.infants > 0 && (
          <div className="border-b-[1px] flex w-full justify-between items-center p-2">
            <p className="text-sm font-medium text-gray-700">
              Infants x {noOfTravelers.infants}
            </p>
            <p className="text-sm font-bold text-gray-700">{`$${
              flightDetails.infFare * noOfTravelers.infants
            }`}</p>
          </div>
        )}
        <div className="border-b-[1px] flex w-full justify-between items-center p-2">
          <p className="text-sm font-medium text-gray-700">
            Total Taxes & Fares
          </p>
          <p className="text-sm font-bold text-gray-700">
            $
            {flightDetails
              ? (
                  +flightDetails.adtTax * +noOfTravelers.adults +
                  +flightDetails.chdTax * +noOfTravelers.children +
                  +flightDetails.platformServiceFee +
                  (+flightDetails.tktFee! && +flightDetails.tktFee! > 0
                    ? +flightDetails.tktFee!
                    : 0) +
                  (+flightDetails.infTax > 0
                    ? +flightDetails.infTax * +noOfTravelers.infants
                    : 0)
                ).toFixed(2)
              : 0}
          </p>
        </div>
        <div className="border-b-[1px] flex w-full justify-between items-center p-2">
          <p className="text-[#ec2027] text-sm font-bold">Grand Total</p>
          <p className="text-sm font-bold text-gray-700">{`$${
            flightDetails
              ? (
                  +flightDetails.adtTax * +noOfTravelers.adults +
                  +flightDetails.adtFare * +noOfTravelers.adults +
                  (flightDetails.chdTax
                    ? +flightDetails.chdTax * +noOfTravelers.children
                    : 0) +
                  (flightDetails.chdFare
                    ? +flightDetails.chdFare * +noOfTravelers.children
                    : 0) +
                  (flightDetails.infTax
                    ? +flightDetails.infTax * +noOfTravelers.infants
                    : 0) +
                  (flightDetails.infFare
                    ? +flightDetails.infFare * +noOfTravelers.infants
                    : 0) +
                  +flightDetails.platformServiceFee +
                  (+flightDetails.tktFee! && +flightDetails.tktFee! > 0
                    ? +flightDetails.tktFee!
                    : 0)
                ).toFixed(2)
              : 0
          }`}</p>
        </div>
        <div className="border-b-[1px] flex w-full justify-between items-center p-2">
          <p className="text-sm font-medium text-gray-500">
            Please note: All fares are quoted in USD. some airlines may charge
            baggage fees.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FlightDetailsPriceCard;
