import {
  FlightDetailsI,
  FlightSegmentsDetailsI,
} from "../types/flightListTypes";
import { convertTimeToMinutes } from "./filterSystem";

export const formatTimeInHoursAndMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60); // Calculate hours
  const mins = minutes % 60; // Calculate remaining minutes

  return `${hours}h ${mins}m`;
};

export const calTotalTime = (trips: FlightSegmentsDetailsI[]) => {
  const totalJourneyTime = trips.reduce(
    (prev, trip) => prev + trip.journeyTime,
    0
  );

  return totalJourneyTime;
};

export const calLayoverTotalTime = (trips: FlightSegmentsDetailsI[]) => {
  const totalJourneyTime = trips.reduce(
    (prev, trip) =>
      prev + (trip.layoverTime ? convertTimeToMinutes(trip.layoverTime) : 0),
    0
  );

  return totalJourneyTime;
};

export const convertToAmPmFormat = (time: any) => {
  // Split the time string into hours and minutes
  const [hours, minutes] = time.split(":").map(Number);

  // Determine if it's AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const convertedHours = hours % 12 || 12; // If hours is 0 or 12, set to 12

  // Return the formatted time
  return `${convertedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
};

export const calculateTimeGap = (startTime: string, endTime: string) => {
  // Parse the start and end times
  const [startHours, startMinutes] = startTime.split(":").map(Number);
  const [endHours, endMinutes] = endTime.split(":").map(Number);

  // Convert both times to total minutes
  const startTotalMinutes = startHours * 60 + startMinutes;
  const endTotalMinutes = endHours * 60 + endMinutes;

  // Calculate the difference in minutes
  let diffInMinutes = endTotalMinutes - startTotalMinutes;

  // Handle cases where the end time is on the next day
  if (diffInMinutes < 0) {
    diffInMinutes += 24 * 60; // Add 24 hours in minutes
  }

  // Convert the difference back to hours and minutes
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;

  return `${hours}h ${minutes}m`;
};

export const formatDateToStr = (inputDate: string): string => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Convert the input string to a Date object
  const date = new Date(inputDate);

  // Extract the day, month, and date
  const day = days[date.getDay()];
  const month = months[date.getMonth()];
  const dayOfMonth = date.getDate();

  // Format and return the result
  return `${day}, ${month} ${dayOfMonth}`;
};

export const calculateDiscount = (
  total: number,
  discountPercentage: number
) => {
  if (total < 0 || discountPercentage < 0 || discountPercentage > 100) {
    console.error("Invalid total or discount percentage");
    return total;
  }
  const discountAmount = (total * discountPercentage) / 100;
  return total - discountAmount;
};

export const convertDate = (inputDate: Date): string => {
  // Step 1: Create a Date object from the input string
  const date = new Date(inputDate);

  // Step 2: Extract the year, month, and day
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
  const day = date.getDate();

  // Step 3: Format the date in YYYY-MM-DD format
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  return formattedDate;
};
