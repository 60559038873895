import {
  NewYorkAccra,
  NewYorkAthens,
  NewYorkBarcelona,
  NewYorkHonolulu,
  NewYorkNaples,
  NewYorkOrlando,
  WashingtonLagos,
  WashingtonLondon,
  WashingtonVancouver,
} from "../utils/images";

export const FlightRoutes = [
  {
    imgSrc: WashingtonLagos,
    departure: "DCA",
    arrival: "LOS",
    departureTitle: "Washington DC",
    arrivalTittle: "Lagos",
  },
  {
    imgSrc: WashingtonVancouver,
    departure: "DCA",
    arrival: "YVR",
    departureTitle: "Washington DC",
    arrivalTittle: "Vancouver",
  },
  {
    imgSrc: WashingtonLondon,
    departure: "DCA",
    arrival: "LHR",
    departureTitle: "Washington DC",
    arrivalTittle: "London",
  },
  {
    imgSrc: NewYorkAccra,
    departure: "LAX",
    arrival: "ACC",
    departureTitle: "Los Angeles",
    arrivalTittle: "Accra",
  },
  {
    imgSrc: NewYorkAthens,
    departure: "LAX",
    arrival: "ATH",
    departureTitle: "Los Angeles",
    arrivalTittle: "Athens",
  },
  {
    imgSrc: NewYorkBarcelona,
    departure: "LAX",
    arrival: "BCN",
    departureTitle: "Los Angeles",
    arrivalTittle: "Barcelona",
  },
  {
    imgSrc: NewYorkHonolulu,
    departure: "LAX",
    arrival: "HNL",
    departureTitle: "Los Angeles",
    arrivalTittle: "Honolulu",
  },
  {
    imgSrc: NewYorkNaples,
    departure: "LAX",
    arrival: "NAP",
    departureTitle: "Los Angeles",
    arrivalTittle: "Naples",
  },
  {
    imgSrc: NewYorkOrlando,
    departure: "LAX",
    arrival: "DFW",
    departureTitle: "Los Angeles",
    arrivalTittle: "Texas",
  },
];
