import { Link } from "react-router-dom";

import LoadingComponent from "../components/Loading.component";
import NoDataFoundComponent from "../components/NoDataFound.component";
import ScrollToTop from "../components/ScrollToTop";

import { BlogI } from "../types/blogs.types";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import ContactBanner from "../components/Banner/ContactBanner";
import CallBtn from "../components/Buttons/CallBtn";
import { useAppDispatch } from "../store";
import { setSelectedTopDestination } from "../store/reducers/flightSearch.reducer";
import HeroSection from "../components/Flights/HeroSection";
import Breadcrumb from "../components/Includes/BreadCrumbs";

const TopFlightRoutes = () => {
  const dispatch = useAppDispatch();
  const [blog, setBlog] = useState<BlogI[] | null>(null);
  const [status, setStatus] = useState<
    "loading" | "success" | "error" | "idle"
  >("idle");

  const fetchRouteList = async () => {
    try {
      setStatus("loading");
      const response = await fetch(
        `${process.env.REACT_APP_GETFILTEREDROUTES}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ selectedOption: "top-flight-destination" }),
        }
      );
      const data = await response.json();
      if (!data.success) {
        setStatus("error");
      }
      setBlog(() => data.data);
      setStatus("success");
    } catch (error) {
      setStatus("error");
    }
  };

  const handleTopDestinationRoutes = (id: string) => {
    if (id) {
      localStorage.setItem("blogId", JSON.stringify(id));
      dispatch(setSelectedTopDestination(id));
    }
  };

  useEffect(() => {
    fetchRouteList();
  }, []);
  return (
    <>
      <Helmet>
        <title>Top Flights Destinations</title>
        <meta name="description" content="Top Flights Destinations" />
        <meta name="keywords" content="flight" />
        <meta property="og:title" content="Dreams tavel and tours" />
        <meta property="og:description" content="book flight" />
        <meta property="og:type" content="article" />
      </Helmet>
      <ScrollToTop />
      <HeroSection />
      <div className="w-[94%] sm:w-[70vw] mx-auto py-2">
        <Breadcrumb />
      </div>
      {status === "loading" && <LoadingComponent />}
      {status === "success" && blog && (
        <>
          <div className="w-[98vw] lg:w-[80vw] xl:w-[70vw] mx-auto my-5">
            <div className="hidden xs:block">
              <ContactBanner />
            </div>
            <div className=" xs:hidden">
              <CallBtn />
            </div>
          </div>
          <div className="w-[90%] sm:w-[70%]  mx-auto my-5 flex flex-wrap items-center justify-center gap-2">
            {blog!.map((blog: BlogI, index) => (
              <Link
                key={index}
                to={`/${blog.url.trim().replace(/ /g, "-")}`}
                className="text-gray-700 text-sm border px-2 py-1 rounded-full hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] hover:text-white"
                onClick={() => handleTopDestinationRoutes(blog._id)}
              >
                {blog.flightRoute}
              </Link>
            ))}
          </div>
          <div className="w-[98vw] lg:w-[80vw] xl:w-[70vw] mx-auto my-5">
            <div className="hidden xs:block">
              <ContactBanner />
            </div>
            <div className="xs:hidden">
              <CallBtn />
            </div>
          </div>
        </>
      )}
      {status === "error" && <NoDataFoundComponent />}
    </>
  );
};

export default TopFlightRoutes;
