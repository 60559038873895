import React, { FC } from "react";

interface InputProps {
  id: string;
  onCharge: any;
  value: string;
  label: string;
  type?: string;
}

const Input: FC<InputProps> = ({ id, onCharge, value, label, type }) => {
  return (
    <div className="relative">
      <input
        id={id}
        type={type || "text"}
        value={value}
        onChange={onCharge}
        className="
                block
                rounded-full
                px-3
                py-2
                w-full
                text-md
                text-black
                bg-[#F0F3F4]
                appearance-none
                focus:outline-none
                focus:ring-0
                peer
            "
        placeholder="Enter your Email"
      />
    </div>
  );
};

export default Input;
