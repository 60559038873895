import React, { useState, useCallback, useMemo } from "react";

type MonthName =
  | "January"
  | "February"
  | "March"
  | "April"
  | "May"
  | "June"
  | "July"
  | "August"
  | "September"
  | "October"
  | "November"
  | "December";

const MONTHS: MonthName[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface YearMonthPickerProps {
  yearRange?: number;
  onSelect?: (year: number | null, month: number | null) => void;
}

const YearMonthPicker: React.FC<YearMonthPickerProps> = ({
  yearRange = 10,
  onSelect,
}) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);

  const years = useMemo(
    () => Array.from({ length: yearRange + 1 }, (_, i) => currentYear + i),
    [currentYear, yearRange]
  );

  const availableMonths = useMemo(
    () => (selectedYear === currentYear ? MONTHS.slice(currentMonth) : MONTHS),
    [selectedYear, currentYear, currentMonth]
  );

  const handleYearChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const yearValue = event.target.value;
      const year = yearValue === "" ? null : parseInt(yearValue, 10);
      setSelectedYear(year);
      if (
        year === currentYear &&
        selectedMonth !== null &&
        selectedMonth < currentMonth
      ) {
        setSelectedMonth(null);
      }
      onSelect?.(year, selectedMonth); // Here, year can be null, which is valid now
    },
    [currentYear, currentMonth, selectedMonth, onSelect]
  );

  const handleMonthChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const monthValue = event.target.value;
      const month = monthValue === "" ? null : parseInt(monthValue, 10);
      setSelectedMonth(month);
      onSelect?.(selectedYear, month); // Here, month can be null, which is valid now
    },
    [selectedYear, onSelect]
  );

  return (
    <div className="w-full flex gap-4 items-center">
      <div className="w-1/2">
        <label
          htmlFor="year-select"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Year
        </label>
        <select
          id="year-select"
          value={selectedYear === null ? "" : selectedYear.toString()}
          onChange={handleYearChange}
          className="px-2 py-1 outline-none border-[1px] shadow-md rounded-md w-full"
        >
          <option value="">Select</option>
          {years.map((year) => (
            <option key={year} value={year.toString()}>
              {year}
            </option>
          ))}
        </select>
      </div>

      <div className="w-1/2">
        <label
          htmlFor="month-select"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Month
        </label>
        <select
          id="month-select"
          value={selectedMonth === null ? "" : selectedMonth.toString()}
          onChange={handleMonthChange}
          className="px-2 py-1 outline-none border-[1px] shadow-md rounded-md w-full"
          disabled={selectedYear === null}
        >
          <option value="">Select</option>
          {availableMonths.map((month, index) => {
            const monthValue =
              selectedYear === currentYear ? currentMonth + index : index;
            return (
              <option key={month} value={monthValue.toString()}>
                {month}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default YearMonthPicker;
