import React from "react";
import { NO_DATA_FOUND } from "../utils/icons";
import { Link } from "react-router-dom";
import { MdKeyboardBackspace } from "../utils/solidIcons";

const NotFoundPage = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-y-2">
      <img
        src={NO_DATA_FOUND}
        alt="NO_DATA_FOUND"
        className="w-full h-[35rem]"
      />
      <h1 className="font-bold text-4xl">Page not found</h1>
      <Link
        to={"/"}
        className="flex items-center gap-x-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white px-2 py-2 my-2"
      >
        <MdKeyboardBackspace />
        <span className="text-xs">Back to Home</span>
      </Link>
    </div>
  );
};

export default NotFoundPage;
