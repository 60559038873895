import { Link } from "react-router-dom";
import { contactInfo } from "../dummydata/contactInfo";
import { CustomerSupport } from "../utils/images";
import { IoCall } from "../utils/solidIcons";
import { DTT_LOGO } from "../utils/icons";

const BannerNav = () => {
  return (
    <div className="w-[80vw] mx-auto sm:flex sm:justify-between sm:items-center">
      <Link to={"/"}>
        <img
          src={DTT_LOGO}
          alt="DDT"
          className="h-[4.5rem] w-[4.5rem] mx-auto"
        />
      </Link>
      <div className="lg:flex items-center gap-3 hidden">
        <div className="bg-gray-900/50 text-white border border-[#ec2027] p-1 flex items-center justify-between rounded-full">
          {/* Contact Section */}
          <div className="flex items-center space-x-2">
            <div className="relative">
              <img
                src={CustomerSupport} // Replace with the actual path of the agent image
                alt="customer support agent"
                className="w-10 h-10 rounded-full border-2 border-white"
              />
              <IoCall className="absolute text-2xl text-[#ec2027] -bottom-2 -left-2 animate-ringWithPause" />
            </div>
            <span className="text-sm font-semibold">
              Book Now - Call us 24/7
            </span>
            <a
              href={`tel:${contactInfo.mobileNum}`}
              className="px-3 py-1 rounded-full text-base font-semibold text-white"
            >
              {contactInfo.mobileNum}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerNav;
