import React from "react";
import { Link, useLocation } from "react-router-dom";

interface BreadcrumbItem {
  label: string;
  path: string;
}

const Breadcrumb: React.FC = () => {
  const location = useLocation();
  const paths = location.pathname.split("/").filter((path) => path !== "");

  // Build breadcrumb items
  const breadcrumbItems: BreadcrumbItem[] = paths.map((path, index) => {
    const pathURL = `/${paths.slice(0, index + 1).join("/")}`;
    return {
      label: path.charAt(0).toUpperCase() + path.slice(1),
      path: pathURL,
    };
  });

  return (
    <nav aria-label="breadcrumb" className="">
      <ul className="flex items-center space-x-2">
        <li>
          <Link to="/" className="text-xs text-blue-500 hover:underline">
            Home
          </Link>
        </li>
        {breadcrumbItems.map((item, index) => (
          <React.Fragment key={item.path}>
            <li className="text-xs text-gray-400">/</li>
            <li>
              {index === breadcrumbItems.length - 1 ? (
                <span className="text-xs text-gray-700">{item.label}</span>
              ) : (
                <Link
                  to={item.path}
                  className="text-xs text-blue-500 hover:underline"
                >
                  {item.label}
                </Link>
              )}
            </li>
          </React.Fragment>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
