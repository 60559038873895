import { AirportListI } from "../types/flightSearchReducer.types";
import { airportList } from "./airportList";

class TrieNode {
  children: { [key: string]: TrieNode };
  isEndOfWord: boolean;
  data: { priority: number; airport: AirportListI }[];
  constructor() {
    this.children = {};
    this.isEndOfWord = false;
    this.data = [];
  }
}

class Trie {
  root: TrieNode;
  constructor() {
    this.root = new TrieNode();
  }

  insert(word: string, data: AirportListI, priority: number): void {
    let node = this.root;
    for (let char of word) {
      if (!node.children[char]) {
        node.children[char] = new TrieNode();
      }
      node = node.children[char];
      // Avoid duplicate entries
      if (!node.data.some((item) => item.airport.uniqueID === data.uniqueID)) {
        node.data.push({ priority, airport: data });
        // Sort data based on priority
        node.data.sort((a, b) => a.priority - b.priority);
      }
    }
    node.isEndOfWord = true;
  }

  search(prefix: string): AirportListI[] {
    let node = this.root;
    for (let char of prefix) {
      if (!node.children[char]) {
        return [];
      }
      node = node.children[char];
    }
    // Flatten and return the airport data
    return node.data.map((item) => item.airport);
  }
}

// Initialize Trie and insert data
export const trie = new Trie();

airportList.forEach((airport) => {
  if (airport.city) {
    // Insert city with the highest priority (1)
    trie.insert(airport.city.toLowerCase(), airport, 1);
  }
  if (airport.iata_code) {
    // Insert iata_code with medium priority (2)
    trie.insert(airport.iata_code.toLowerCase(), airport, 2);
  }
  if (airport.country) {
    // Insert country with the lowest priority (3)
    trie.insert(airport.country.toLowerCase(), airport, 3);
  }
});
