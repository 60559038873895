import html2canvas from "html2canvas";
import { contactInfo } from "../../dummydata/contactInfo";
import { DTT_LOGO } from "../../utils/icons";
import {
  AmadeusCertificate,
  AmericanExpressCard,
  ArcCertificate,
  CompliAssureSecured,
  IATAN,
  MaestroCard,
  MasterCard,
  MasterCardSecureCode,
  SaberCertificate,
  TravelPortCertificate,
  VerifiedAndSecuredCertificate,
  VerifiedVisaCard,
  VisaCard,
} from "../../utils/images";
import {
  CgDetailsMore,
  FaCheck,
  FaFileSignature,
  FaMobile,
  FaRegCreditCard,
  GiAirplaneDeparture,
  IoIosAirplane,
  IoPersonSharp,
  PiPrinterFill,
  RiFlightTakeoffLine,
  TbListDetails,
} from "../../utils/solidIcons";
import jsPDF from "jspdf";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { airportList } from "../../utils/airportList";
import { PassengerI } from "../../types/flightSearchReducer.types";
import {
  calculateDiscountAmt,
  calculateDiscountedFare,
} from "../../utils/ExtraFunction";
import { airlinesImg } from "../../utils/airlineImgList";
import {
  AirlineImageI,
  AirportI,
  FlightSegmentsDetailsI,
} from "../../types/flightListTypes";
import {
  convertToAmPmFormat,
  formatDateToStr,
  formatTimeInHoursAndMinutes,
} from "../../utils/formatTimeInMinuteToHourandMinute";
import { notifySuccess } from "../../utils/notifications";

const TicketPDF = () => {
  const [isDiscountAvailable, setIsDiscountAvailable] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const {
    bookingNum,
    contact,
    paymentInfo,
    flightData,
    ticketIssuedDate,
    userBasicinfo,
  } = JSON.parse(atob(`${params.get("id")}`));

  const TotalFare: number = parseFloat(
    (
      +flightData.adtFare * +flightData.adults +
      +flightData.adtTax * +flightData.adults +
      ((+flightData.chdFare ? +flightData.chdFare * +flightData.children : 0) +
        (+flightData.chdTax ? +flightData.chdTax * +flightData.children : 0)) +
      ((+flightData.infFare ? +flightData.infFare * +flightData.infants : 0) +
        (+flightData.infTax ? +flightData.infTax * +flightData.infants : 0))
    ).toFixed(2)
  );
  const contentRef = useRef<HTMLDivElement>(null);

  const handleDownloadPDF = async () => {
    if (!contentRef.current) return;

    // Fix width for consistent rendering
    const originalWidth = contentRef.current.style.width;
    contentRef.current.style.width = "800px";

    // Capture the content as an image
    const canvas = await html2canvas(contentRef.current, {
      scale: 2, // Adjust scale for good quality without excessive size
      useCORS: true,
    });
    contentRef.current.style.width = originalWidth;

    // Compress the image
    const imageData = canvas.toDataURL("image/jpeg", 0.7); // JPEG with moderate quality

    // Initialize jsPDF
    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = pageWidth;
    const imgHeight = (canvas.height * pageWidth) / canvas.width;

    if (imgHeight <= pageHeight) {
      // Content fits on one page
      pdf.addImage(imageData, "JPEG", 0, 0, imgWidth, imgHeight);
    } else {
      // Content spans multiple pages
      let remainingHeight = imgHeight;
      let position = 0;

      while (remainingHeight > 0) {
        const pageContentHeight = Math.min(pageHeight, remainingHeight);
        pdf.addImage(imageData, "JPEG", 0, position, imgWidth, imgHeight);
        remainingHeight -= pageHeight;
        position -= pageHeight;

        if (remainingHeight > 0) {
          pdf.addPage();
        }
      }
    }

    // Save the PDF
    pdf.save("ticket.pdf");
  };

  const sendTicketOnMail = async () => {
    try {
      const sendMailRes = await fetch(
        `${process.env.REACT_APP_AIRLINE_SENDMAIL}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            bookingNum,
            contact,
            paymentInfo,
            flightData,
            ticketIssuedDate,
            userBasicinfo,
          }),
        }
      );

      const convertSendMailResToJson = await sendMailRes.json();
      if (convertSendMailResToJson.success) {
        notifySuccess(convertSendMailResToJson.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    sendTicketOnMail();
  }, []);
  return (
    <div className="-mt-16 ">
      <div className="w-11/12 lg:w-3/4 mx-auto mb-1 flex justify-between ">
        <Link
          to="/"
          className="print:hidden bg-gradient-to-l from-[#ec2027] to-[#c81212] text-xs text-white md:text-sm px-4 py-2 rounded-md"
        >
          Back to Home
        </Link>
        <button
          className="flex items-center gap-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] px-4 py-2 rounded-md"
          onClick={handleDownloadPDF}
        >
          <PiPrinterFill className="text-2xl text-white" />
          <p className="print:hidden text-sm font-medium text-white">Print</p>
        </button>
      </div>
      <div
        ref={contentRef}
        className=" w-11/12 lg:w-3/4 mx-auto flex flex-col  gap-y-3 overflow-x-hidden"
      >
        <div className="flex items-center justify-between mt-5 mx-2">
          <div>
            <img src={DTT_LOGO} alt="dream travel and tours logo" width={100} />
          </div>
          <div className="flex items-center gap-x-2">
            <FaMobile className="text-3xl text-[#c81212]" />
            <div className="flex flex-col items-start">
              <Link
                to={`tel:${contactInfo.mobileNum}`}
                className="text-xs font-medium text-gray-500 mx-2"
              >
                24/7 Telephone Number
              </Link>
              <p className="text-sm font-bold text-gray-700">
                {contactInfo.mobileNum}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col mx-2">
          <h1 className="mx-auto flex items-center gap-2">
            <span className="text-lg font-bold text-green-500">
              Congratulations!
            </span>
            <span className="text-sm font-medium text-gray-700">
              Your flight booking is under process...
            </span>
          </h1>
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-gray-700">
              Your booking number{" "}
              <span className="text-base font-bold">{bookingNum}</span>, Booked
              on{" "}
              <span className="text-base font-bold">
                {new Date(ticketIssuedDate).toLocaleString(
                  "en-US", // Options to format the date
                  {
                    weekday: "short", // "Thu"
                    month: "short", // "Nov"
                    day: "2-digit", // "14"
                    year: "numeric", // "2024"
                    hour: "2-digit", // "06"
                    minute: "2-digit", // "15"
                    second: "2-digit", // "33"
                    hour12: true, // 12-hour format with AM/PM
                  }
                )}
              </span>
            </p>
          </div>
        </div>
        <div className="border flex flex-col rounded-md mx-2">
          <p className="flex items-center gap-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] rounded-t-md py-1 ps-2">
            <FaCheck className="text-base font-bold text-white" />
            <span className="text-xs md:text-base font-bold text-white capitalize">
              Booking Status
            </span>
          </p>
          <p className="text-sm font-medium text-gray-700 p-2">
            Your payment is under process. The confirmation for your itinerary
            will be emailed shortly. This is not an e-ticket hence not valid for
            traveling. E-ticket will be issued and emailed to you after
            successful verification of Credit/Debit Card.
          </p>
        </div>
        <div className="flex flex-col border rounded-md mx-2">
          <p className="flex items-center gap-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] rounded-t-md py-1 ps-2">
            <IoPersonSharp className="text-base font-bold text-white" />
            <span className="text-xs md:text-base font-bold text-white capitalize">
              Customer Information
            </span>
          </p>
          <div className="flex flex-col md:flex-row md:items-center text-sm font-medium text-gray-700 p-2 justify-between">
            <p>
              <span className="font-bold">Traveler : </span>
              <span>{`${userBasicinfo[0].firstName.trim()} ${
                userBasicinfo[0].middleName
                  ? userBasicinfo[0].middleName.trim()
                  : ""
              } ${userBasicinfo[0].lastName.trim()}`}</span>
            </p>
            <p>
              <span className="font-bold">Phone : </span>
              <span>{contact.billingPhone}</span>
            </p>
            <p>
              <span className="font-bold">Email ID : </span>
              <span>{contact.email}</span>
            </p>
          </div>
        </div>

        {/***************Flight details*************/}

        <div className=" flex  flex-col border rounded-md mx-2">
          <p className="flex items-center gap-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] rounded-t-md py-1 ps-2">
            <TbListDetails className="text-base font-bold text-white" />
            <span className="text-xs md:text-base font-bold text-white capitalize">
              Flight details
            </span>
          </p>
          <div className="flex flex-col divide-y p-2">
            {flightData.outboundTrip && flightData.outboundTrip.length > 0 && (
              <div>
                <p className="text-sm text-gray-700 font-semibold py-2 border-b">
                  Onward Journey
                </p>
                {flightData.outboundTrip.length > 0 &&
                  flightData.outboundTrip.map(
                    (outboundTrip: FlightSegmentsDetailsI) => (
                      <div
                        key={outboundTrip.segmentId}
                        className="flex flex-col gap-y-5 py-2 border-b last:border-b-0"
                      >
                        <div className="flex items-center justify-between border-b border-dotted pb-2">
                          <div className="flex flex-col md:flex-row md:items-center gap-x-2">
                            <div className="flex flex-col md:flex-row md:items-center gap-x-1">
                              <span className="text-xs md:text-sm text-gray-700 font-semibold">
                                {
                                  airportList.find(
                                    (airport: AirportI) =>
                                      airport.iata_code ===
                                      outboundTrip.departure
                                  )?.city
                                }
                              </span>
                              <span className="text-xs md:text-sm text-gray-700 font-semibold">
                                {
                                  airportList.find(
                                    (airport: AirportI) =>
                                      airport.iata_code ===
                                      outboundTrip.departure
                                  )?.name
                                }
                              </span>
                            </div>
                            <GiAirplaneDeparture className="text-lg text-gray-700 font-semibold" />
                            <div className="flex flex-col md:flex-row md:items-center gap-x-1">
                              <span className="text-xs md:text-sm text-gray-700 font-semibold">
                                {
                                  airportList.find(
                                    (airport: AirportI) =>
                                      airport.iata_code === outboundTrip.arrival
                                  )?.city
                                }
                              </span>
                              <span className="text-xs md:text-sm text-gray-700 font-semibold">
                                {
                                  airportList.find(
                                    (airport: AirportI) =>
                                      airport.iata_code === outboundTrip.arrival
                                  )?.name
                                }
                              </span>
                            </div>
                            <span className="text-xs text-gray-500 font-semibold">
                              ({formatDateToStr(outboundTrip.departureDate)},
                              {convertToAmPmFormat(outboundTrip.departureTime)})
                            </span>
                          </div>
                          <p className="flex items-center gap-x-1">
                            <span className="text-xs md:text-sm text-gray-700 font-semibold">
                              Trip Time :{" "}
                            </span>
                            <span className="text-xs md:text-sm text-gray-700 font-thin">
                              {formatTimeInHoursAndMinutes(
                                outboundTrip.journeyTime
                              )}
                            </span>
                          </p>
                        </div>
                        <div className="flex flex-col md:flex-row md:items-center justify-between gap-y-1">
                          <div className="flex md:w-[15%] flex-row md:flex-col items-center md:items-start gap-2 md:gap-0">
                            <img
                              src={
                                airlinesImg.find(
                                  (airline: AirlineImageI) =>
                                    airline.iata === outboundTrip.airline
                                )?.imgUrl
                              }
                              alt={outboundTrip.airline}
                            />
                            <p className="text-xs text-gray-700 font-semibold">
                              {
                                airlinesImg.find(
                                  (airline: AirlineImageI) =>
                                    airline.iata === outboundTrip.airline
                                )?.airlineName
                              }
                            </p>
                            <p className="text-xs text-gray-500 font-light">
                              {outboundTrip.airline} {outboundTrip.flightNum}
                            </p>
                          </div>
                          <div className="flex md:w-1/4 flex-col">
                            <p className="text-xs md:text-sm text-gray-500 font-semibold">
                              From
                            </p>
                            <p className="text-xs text-gray-500">
                              {outboundTrip.departure} ,
                              {
                                airportList.find(
                                  (airport: AirportI) =>
                                    airport.iata_code === outboundTrip.departure
                                )?.city
                              }{" "}
                              ,
                              {
                                airportList.find(
                                  (airport: AirportI) =>
                                    airport.iata_code === outboundTrip.departure
                                )?.name
                              }
                            </p>
                            {outboundTrip.departureTerminal && (
                              <p className="flex flex-row gap-x-1">
                                <span className="text-xs text-gray-500">
                                  Terminal :{" "}
                                </span>
                                <span className="text-xs text-gray-500">
                                  {outboundTrip.departureTerminal}
                                </span>
                              </p>
                            )}
                            <p className="text-xs text-gray-500">
                              {formatDateToStr(outboundTrip.departureDate)},
                              {convertToAmPmFormat(outboundTrip.departureTime)}
                            </p>
                          </div>
                          <p className="flex md:w-1/5 items-center text-xs md:text-md text-gray-500 font-semibold">
                            ----
                            <IoIosAirplane />
                            ----
                          </p>
                          <div className="flex md:w-1/4 flex-col">
                            <p className="text-xs md:text-sm text-gray-500 font-semibold">
                              To
                            </p>
                            <p className="text-xs text-gray-500">
                              {outboundTrip.arrival} ,
                              {
                                airportList.find(
                                  (airport: AirportI) =>
                                    airport.iata_code === outboundTrip.arrival
                                )?.city
                              }{" "}
                              ,
                              {
                                airportList.find(
                                  (airport: AirportI) =>
                                    airport.iata_code === outboundTrip.arrival
                                )?.name
                              }
                            </p>
                            {outboundTrip.arrivalTerminal && (
                              <p className="flex items-center">
                                <span className="text-xs text-gray-500">
                                  Terminal :{" "}
                                </span>
                                <span className="text-xs text-gray-500">
                                  {outboundTrip.arrivalTerminal}
                                </span>
                              </p>
                            )}
                            <p className="text-xs text-gray-500">
                              {formatDateToStr(outboundTrip.arrivalDate)},
                              {convertToAmPmFormat(outboundTrip.arrivalTime)}
                            </p>
                          </div>
                          <div className="flex md:w-[15%] md:text-end flex-col">
                            <p className="text-xs text-gray-500">
                              {" "}
                              {formatTimeInHoursAndMinutes(
                                outboundTrip.journeyTime
                              )}
                            </p>
                            <p className="text-xs text-gray-500">
                              {outboundTrip.cabinClass}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            )}
            {flightData.inboundTrip && flightData.inboundTrip.length > 0 && (
              <div>
                <p className="text-xs md:text-sm text-gray-700 font-semibold py-2 border-b">
                  Return Journey
                </p>
                {flightData.inboundTrip &&
                  flightData.inboundTrip.length > 0 &&
                  flightData.inboundTrip.map(
                    (inboundTrip: FlightSegmentsDetailsI) => (
                      <div
                        key={inboundTrip.segmentId}
                        className="flex flex-col gap-y-5 py-2 border-b last:border-b-0"
                      >
                        <div className="flex items-center justify-between border-b border-dotted pb-2">
                          <div className="flex flex-col md:flex-row md:items-center gap-x-2">
                            <div className="flex flex-col md:flex-row md:items-center gap-x-1">
                              <span className="text-xs md:text-sm text-gray-700 font-semibold">
                                {
                                  airportList.find(
                                    (airport: AirportI) =>
                                      airport.iata_code ===
                                      inboundTrip.departure
                                  )?.city
                                }
                              </span>
                              <span className="text-xs md:text-sm text-gray-700 font-semibold">
                                {
                                  airportList.find(
                                    (airport: AirportI) =>
                                      airport.iata_code ===
                                      inboundTrip.departure
                                  )?.name
                                }
                              </span>
                            </div>
                            <GiAirplaneDeparture className="text-lg text-gray-700 font-semibold" />
                            <div className="flex flex-col md:flex-row md:items-center gap-x-1">
                              <span className="text-xs md:text-sm text-gray-700 font-semibold">
                                {
                                  airportList.find(
                                    (airport: AirportI) =>
                                      airport.iata_code === inboundTrip.arrival
                                  )?.city
                                }
                              </span>
                              <span className="text-xs md:text-sm text-gray-700 font-semibold">
                                {
                                  airportList.find(
                                    (airport: AirportI) =>
                                      airport.iata_code === inboundTrip.arrival
                                  )?.name
                                }
                              </span>
                            </div>
                            <span className="text-xs text-gray-500 font-semibold">
                              ({formatDateToStr(inboundTrip.departureDate)},
                              {convertToAmPmFormat(inboundTrip.departureTime)})
                            </span>
                          </div>
                          <p className="flex items-center gap-x-1">
                            <span className="text-xs md:text-sm text-gray-700 font-semibold">
                              Trip Time :{" "}
                            </span>
                            <span className="text-xs md:text-sm text-gray-700 font-thin">
                              {formatTimeInHoursAndMinutes(
                                inboundTrip.journeyTime
                              )}
                            </span>
                          </p>
                        </div>
                        <div className="flex flex-col md:flex-row md:items-center justify-between gap-2">
                          <div className="flex w-[15%] flex-row md:flex-col items-center md:items-start gap-2 md:gap-0">
                            <img
                              src={
                                airlinesImg.find(
                                  (airline: AirlineImageI) =>
                                    airline.iata === inboundTrip.airline
                                )?.imgUrl
                              }
                              alt={inboundTrip.airline}
                            />
                            <p className="text-xs text-gray-700 font-semibold">
                              {
                                airlinesImg.find(
                                  (airline: AirlineImageI) =>
                                    airline.iata === inboundTrip.airline
                                )?.airlineName
                              }
                            </p>
                            <p className="text-xs text-gray-500 font-light">
                              {inboundTrip.airline} {inboundTrip.flightNum}
                            </p>
                          </div>
                          <div className="flex md:w-1/4 flex-col">
                            <p className="text-xs md:text-sm text-gray-500 font-semibold">
                              From
                            </p>
                            <p className="text-xs text-gray-500">
                              {inboundTrip.departure} ,
                              {
                                airportList.find(
                                  (airport: AirportI) =>
                                    airport.iata_code === inboundTrip.departure
                                )?.city
                              }{" "}
                              ,
                              {
                                airportList.find(
                                  (airport: AirportI) =>
                                    airport.iata_code === inboundTrip.departure
                                )?.name
                              }
                            </p>
                            {inboundTrip.departureTerminal && (
                              <p className="flex flex-row gap-x-1">
                                <span className="text-xs text-gray-500">
                                  Terminal :{" "}
                                </span>
                                <span className="text-xs text-gray-500">
                                  {inboundTrip.departureTerminal}
                                </span>
                              </p>
                            )}
                            <p className="text-xs text-gray-500">
                              {formatDateToStr(inboundTrip.departureDate)},
                              {convertToAmPmFormat(inboundTrip.departureTime)}
                            </p>
                          </div>
                          <p className="flex md:w-1/5 items-center text-xs md:text-md text-gray-500 font-semibold">
                            ----
                            <IoIosAirplane />
                            ----
                          </p>
                          <div className="flex md:w-1/4 flex-col">
                            <p className="text-xs md:text-sm text-gray-500 font-semibold">
                              To
                            </p>
                            <p className="text-xs text-gray-500">
                              {inboundTrip.arrival} ,
                              {
                                airportList.find(
                                  (airport: AirportI) =>
                                    airport.iata_code === inboundTrip.arrival
                                )?.city
                              }{" "}
                              ,
                              {
                                airportList.find(
                                  (airport: AirportI) =>
                                    airport.iata_code === inboundTrip.arrival
                                )?.name
                              }
                            </p>
                            {inboundTrip.arrivalTerminal && (
                              <p className="flex items-center">
                                <span className="text-xs text-gray-500">
                                  Terminal :{" "}
                                </span>
                                <span className="text-xs text-gray-500">
                                  {inboundTrip.arrivalTerminal}
                                </span>
                              </p>
                            )}
                            <p className="text-xs text-gray-500">
                              {formatDateToStr(inboundTrip.arrivalDate)},
                              {convertToAmPmFormat(inboundTrip.arrivalTime)}
                            </p>
                          </div>
                          <div className="flex md:w-[15%] md:text-end flex-col">
                            <p className="text-xs text-gray-500">
                              {" "}
                              {formatTimeInHoursAndMinutes(
                                inboundTrip.journeyTime
                              )}
                            </p>
                            <p className="text-xs text-gray-500">
                              {inboundTrip.cabinClass}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            )}
          </div>
          <p className="flex flex-col p-2 text-xs md:text-sm font-medium text-gray-700">
            <span className="font-bold">Please Note:</span>
            <span className="font-normal">
              As Airlines have frequent schedule changes, please call the
              Airline 24 hours prior to scheduled departure to reconfirm your
              flight details.
            </span>
          </p>
        </div>
        <div className="flex flex-col border rounded-md gap-y-1 mx-2">
          <p className="flex items-center gap-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] rounded-t-md py-1 ps-2">
            <FaRegCreditCard className="text-base font-bold text-white" />
            <span className="text-xs md:text-base font-bold text-white capitalize">
              Fare Details
            </span>
          </p>
          <div className="p-2">
            <table className="w-full table-auto border border-collapse">
              <thead>
                <tr className="bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white">
                  <th className="border text-center py-1 text-sm font-medium">
                    Traveller Type
                  </th>
                  <th className="border text-center py-1 text-sm font-medium">
                    Ticket Price
                  </th>
                  <th className="border text-center py-1 text-sm font-medium">
                    Taxes & Fee
                  </th>
                  <th className="border text-center py-1 text-sm font-medium">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    {+flightData.adults} x Adult
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    ${+flightData.adtFare * +flightData.adults}
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    ${+flightData.adtTax * +flightData.adults}
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    $
                    {parseFloat(
                      `${
                        +flightData.adtFare * +flightData.adults +
                        +flightData.adtTax * +flightData.adults
                      }`
                    ).toFixed(2)}
                  </td>
                </tr>
                {flightData.children > 0 && (
                  <tr>
                    <td className="border text-center py-1 text-sm font-normal text-gray-700">
                      {+flightData.children} x Children
                    </td>
                    <td className="border text-center py-1 text-sm font-normal text-gray-700">
                      {`${
                        +flightData.chdFare
                          ? "$" +
                            parseFloat(
                              `${+flightData.chdFare * +flightData.children}`
                            ).toFixed(2)
                          : "-"
                      }`}
                    </td>
                    <td className="border text-center py-1 text-sm font-normal text-gray-700">
                      {`${
                        +flightData.chdTax
                          ? "$" +
                            parseFloat(
                              `${+flightData.chdTax * +flightData.children}`
                            ).toFixed(2)
                          : "-"
                      }`}
                    </td>
                    <td className="border text-center py-1 text-sm font-normal text-gray-700">
                      $
                      {parseFloat(
                        `${
                          +flightData.chdFare * +flightData.children +
                          +flightData.chdTax * +flightData.children
                        }`
                      ).toFixed(2)}
                    </td>
                  </tr>
                )}
                {flightData.infants > 0 && (
                  <tr>
                    <td className="border text-center py-1 text-sm font-normal text-gray-700">
                      {+flightData.infants} x infant
                    </td>
                    <td className="border text-center py-1 text-sm font-normal text-gray-700">
                      {`${
                        +flightData.infFare
                          ? "$" +
                            parseFloat(
                              `${+flightData.infFare * +flightData.infants}`
                            ).toFixed(2)
                          : "-"
                      }`}
                    </td>
                    <td className="border text-center py-1 text-sm font-normal text-gray-700">
                      {`${
                        +flightData.infTax
                          ? "$" +
                            parseFloat(
                              `${+flightData.infTax * +flightData.infants}`
                            ).toFixed(2)
                          : "-"
                      }`}
                    </td>
                    <td className="border text-center py-1 text-sm font-normal text-gray-700">
                      {`${
                        +flightData.infFare
                          ? "$" +
                            parseFloat(
                              `${
                                +flightData.infFare * +flightData.infants +
                                +flightData.infTax * +flightData.infants
                              }`
                            ).toFixed(2)
                          : "-"
                      }`}
                    </td>
                  </tr>
                )}
                <tr>
                  <td
                    className="border text-center text-extrabold py-1 text-sm font-bold text-gray-700"
                    colSpan={3}
                  >
                    Subtotal:
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    ${+TotalFare}
                  </td>
                </tr>
                {isDiscountAvailable && (
                  <tr>
                    <td
                      className="border text-center py-1 text-sm font-bold text-green-400"
                      colSpan={3}
                    >
                      Discount:
                    </td>
                    <td className="border text-center py-1 text-sm font-normal text-green-400">
                      -${+calculateDiscountAmt(TotalFare, 10)}
                    </td>
                  </tr>
                )}
                {isDiscountAvailable && (
                  <tr>
                    <td
                      className="border text-center py-1 text-sm font-bold text-gray-700"
                      colSpan={3}
                    >
                      Booking Amount:
                    </td>
                    <td className="border text-center py-1 text-sm font-normal text-gray-700">
                      ${+calculateDiscountedFare(TotalFare, 10)}
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td
                    className="border text-center py-1 text-sm font-bold text-gray-700"
                    colSpan={2}
                  >
                    Charged on Credit Card
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    VI - (****
                    {`${paymentInfo.cardNum}`.substring(
                      `${paymentInfo.cardNum}`.length - 4,
                      `${paymentInfo.cardNum}`.length
                    )}
                    ){" "}
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    $
                    {isDiscountAvailable
                      ? +calculateDiscountedFare(TotalFare, 10)
                      : +TotalFare}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className=" text-sm text-gray-700 p-2">
            <h3 className="font-bold">Please Note:</h3>
            <ul className="font-extralight text-xs list-disc px-4">
              <li>All fares are quoted in USD.</li>
              <li>Some airlines may charge baggage fees.</li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col border rounded-md gap-y-2 mx-2">
          <p className="flex items-center gap-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] rounded-t-md py-1 ps-2">
            <CgDetailsMore className="text-base font-bold text-white" />
            <span className="text-xs md:text-base font-bold text-white capitalize">
              Traveler details
            </span>
          </p>
          <div className="p-2 overflow-x-auto">
            <table className="w-full table-auto border border-collapse">
              <thead>
                <tr className="bg-gradient-to-l from-[#ec2027] to-[#c81212] text-white">
                  <th className="border text-center py-1 text-sm font-medium">
                    Traveler type
                  </th>
                  <th className="border text-center py-1 text-sm font-medium">
                    Title
                  </th>
                  <th className="border text-center py-1 text-sm font-medium">
                    First Name
                  </th>
                  <th className="border text-center py-1 text-sm font-medium">
                    Middler Name
                  </th>
                  <th className="border text-center py-1 text-sm font-medium">
                    Last Name
                  </th>
                  <th className="border text-center py-1 text-sm font-medium">
                    Date of Birth
                  </th>
                  <th className="border text-center py-1 text-sm font-medium">
                    Gender
                  </th>
                  {/* <th className="border text-center py-1 text-sm font-medium">
                  Meal Preference
                </th>
                <th className="border text-center py-1 text-sm font-medium">
                  Frequent flyer number
                </th> */}
                </tr>
              </thead>
              <tbody>
                {userBasicinfo.map((user: PassengerI, index: number) => (
                  <tr key={index}>
                    <td className="border text-center py-1 text-sm font-normal text-gray-700">
                      {user.psgType === "ADT" && "Adult"}
                      {user.psgType === "CHD" && "Children"}
                      {user.psgType === "INF" && "Infant"}
                    </td>
                    <td className="border text-center py-1 text-sm font-normal text-gray-700">
                      {user.title}
                    </td>
                    <td className="border text-center py-1 text-sm font-normal text-gray-700">
                      {user.firstName}
                    </td>
                    <td className="border text-center py-1 text-sm font-normal text-gray-700">
                      {`${user.middleName ? user.middleName : "-"}`}
                    </td>
                    <td className="border text-center py-1 text-sm font-normal text-gray-700">
                      {user.lastName}
                    </td>
                    <td className="border text-center py-1 text-sm font-normal text-gray-700">
                      {new Date(user.birthday).toLocaleString("en-US", {
                        day: "2-digit", // "14"
                        month: "short", // "Nov"
                        year: "numeric",
                      })}
                    </td>
                    <td className="border text-center py-1 text-sm font-normal text-gray-700">
                      {`${user.sex === "M" ? "Male" : "Female"}`}
                    </td>
                    {/* <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    -
                  </td>
                  <td className="border text-center py-1 text-sm font-normal text-gray-700">
                    -
                  </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex flex-col border rounded-md gap-y-4 mx-2">
          <p className="flex items-center gap-2 bg-gradient-to-l from-[#ec2027] to-[#c81212] rounded-t-md py-1 ps-2">
            <FaFileSignature className="text-base font-bold text-white" />
            <span className="text-xs md:text-base font-bold text-white capitalize">
              Charge authorization, your electronic Signature copy
            </span>
          </p>
          <p className="px-2">
            <input type="checkbox" name="terms" id="terms" checked disabled />
            <span className="ms-2 text-sm font-normal text-gray-700">
              I,
              {paymentInfo.cardHolderName} agree to pay a total amount of $
              {isDiscountAvailable
                ? calculateDiscountedFare(TotalFare, 10)
                : TotalFare}{" "}
              (Credit/Debit Card Number: ****
              {`${paymentInfo.cardNum}`.substring(
                `${paymentInfo.cardNum}`.length - 4,
                `${paymentInfo.cardNum}`.length
              )}
              ) for the above mentioned itinerary. I have verified the dates and
              times of the flights and name(s) of traveler(s) are correct. I
              have read the Terms and Conditions and I understand that:
            </span>
          </p>
          <ul className="px-2 list-disc ms-8 mb-2 text-sm font-normal text-gray-700">
            <li>
              Most fares and taxes are non-refundable and service fee is
              non-refundable, the tickets are non-transferable and name changes
              on the tickets are not permitted.
            </li>
            <li>
              All government taxes and service fees are included in the total
              price.
            </li>
            <li>
              Date and routing changes will be subject to the airlines
              penalties, fare difference and our service fees.
            </li>
            <li>I understand this is to serve as my legal signature.</li>
          </ul>
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-1 md:place-items-center mx-2">
          <div className=" ">
            <h2 className="text-sm font-bold text-gray-700 mb-2">We Accept</h2>
            <div className="flex  items-center justify-between md:justify-start">
              <img
                src={MasterCardSecureCode}
                alt="master card secure"
                width={45}
              />
              <img
                src={VerifiedVisaCard}
                alt="verified visa card logo"
                width={45}
              />
              <img src={MasterCard} alt="master card logo" width={45} />
              <img src={MaestroCard} alt="maestro card logo" width={45} />
              <img
                src={AmericanExpressCard}
                alt="american express card logo"
                width={45}
              />
              <img src={VisaCard} alt="visa card logo" width={45} />
            </div>
          </div>
          <div className=" ">
            <h2 className="text-sm font-bold text-gray-700 mb-2">
              Certification Partners
            </h2>
            <div className="w-full flex items-center  justify-between md:justify-start pr-1">
              <img src={ArcCertificate} alt="arc certificate" width={45} />
              <img
                src={CompliAssureSecured}
                alt="CompliAssure secured certificate"
                width={45}
              />
              {/* <img src={TaaCertificate} alt="TAAI certificate" width={20} /> */}
              <img src={SaberCertificate} alt="saber certificate" width={45} />
              <img
                src={TravelPortCertificate}
                alt="travel port certificate"
                width={45}
              />
              <img
                src={AmadeusCertificate}
                alt="Amadeus certificate"
                width={45}
              />
              <img src={IATAN} alt="IATAN certificate" width={45} />
              <img
                src={VerifiedAndSecuredCertificate}
                alt="verified and secured certificate"
                width={45}
              />
            </div>
          </div>
        </div>
        <p className="mx-auto text-sm font-bold text-gray-700 mb-4 mx-2">
          Copyright © {new Date().getFullYear()} All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default TicketPDF;
