import { useEffect, useState } from "react";
import {
  AirlineImageI,
  AirportI,
  FlightDetailsI,
} from "../../types/flightListTypes";
import {
  BsCloudMoon,
  BsCloudSun,
  PiSunHorizonThin,
  PiSunThin,
  TiDelete,
} from "../../utils/solidIcons";
import {
  getAvailableAirlines,
  getJourneyDuration,
  getLayoverDuration,
} from "../../utils/filterSystem";
import { airlinesImg } from "../../utils/airlineImgList";
import { formatTimeInHoursAndMinutes } from "../../utils/formatTimeInMinuteToHourandMinute";
import { AppliedFilterI } from "../../types/flightFilters.types";

interface FilterComponentProp {
  flightFilteredData: any;
  handleFlightFilterData: (filteredData: any) => void;
  closeBackdrop?: Function;
}

type TimeType = "Before 6AM" | "6AM to 12PM" | "12PM to 6PM" | "After 6PM";

const FilterComponent: React.FC<FilterComponentProp> = ({
  flightFilteredData,
  handleFlightFilterData,
  closeBackdrop,
}) => {
  const [appliedFilters, setAppliedFilters] = useState<AppliedFilterI[]>([]);
  const [airlines, setAirlines] = useState<AirlineImageI[]>([]);
  // State to track selected airlines
  const [from, setFrom] = useState<AirportI>();
  const [to, setTo] = useState<AirportI>();
  const [tripType, setTripType] = useState<"oneway" | "roundtrip">("roundtrip");

  // Define the filters that need to be reset to their initial values
  const resettableFilters = [
    "outbound-journey-duration",
    "inbound-journey-duration",
    "outbound-layover-duration",
    "inbound-layover-duration",
    "price-range",
  ];

  const conversionFilter = [
    "outbound-journey-duration",
    "inbound-journey-duration",
    "outbound-layover-duration",
    "inbound-layover-duration",
  ];

  // Calculate minPrice and maxPrice
  const minPrice = Math.min(
    ...flightFilteredData.map(
      (item: FlightDetailsI) => item.adtFare + item.adtTax
    )
  );
  const maxPrice = Math.max(
    ...flightFilteredData.map(
      (item: FlightDetailsI) => item.adtFare + item.adtTax
    )
  );

  const minOutboundJourneyDuration = getJourneyDuration(
    flightFilteredData,
    "outbound"
  ).min;
  const maxOutboundJourneyDuration = getJourneyDuration(
    flightFilteredData,
    "outbound"
  ).max;
  const minInboundJourneyDuration = getJourneyDuration(
    flightFilteredData,
    "inbound"
  ).min;
  const maxInboundJourneyDuration = getJourneyDuration(
    flightFilteredData,
    "inbound"
  ).max;

  const minOutboundLayoverDuration = getLayoverDuration(
    flightFilteredData,
    "outbound"
  ).min;
  const maxOutboundLayoverDuration = getLayoverDuration(
    flightFilteredData,
    "outbound"
  ).max;
  const minInboundLayoverDuration = getLayoverDuration(
    flightFilteredData,
    "inbound"
  ).min;
  const maxInboundLayoverDuration = getLayoverDuration(
    flightFilteredData,
    "inbound"
  ).max;

  // Use the minPrice and maxPrice as the range for the input
  const [price, setPrice] = useState(maxPrice); // Default price is the min price
  const [outboundJourneyDuration, setOutboundJourneyDuration] =
    useState<number>(maxOutboundJourneyDuration);
  const [inboundJourneyDuration, setInboundJourneyDuration] = useState<number>(
    maxInboundJourneyDuration
  );

  const [outboundLayoverDuration, setOutboundLayoverDuration] =
    useState<number>(
      maxOutboundLayoverDuration ? maxOutboundLayoverDuration : 0
    );
  const [inboundLayoverDuration, setInboundLayoverDuration] = useState<number>(
    maxInboundLayoverDuration ? maxInboundLayoverDuration : 0
  );

  useEffect(() => {
    const data =
      localStorage.getItem("flightSearchKey") !== null
        ? JSON.parse(localStorage.getItem("flightSearchKey")!)
        : null;

    const parsedData = data ? JSON.parse(atob(`${data}`)) : null;

    if (!parsedData) {
      return;
    }
    setFrom(() => parsedData.from);
    setTo(() => parsedData.to);
    setTripType(() => parsedData.tripType);
    const availableAirline = getAvailableAirlines(flightFilteredData);
    setAirlines(() =>
      airlinesImg.filter((airline: AirlineImageI) =>
        availableAirline.includes(airline.iata)
      )
    );
  }, []);

  useEffect(() => {
    // Ensure the price is within the range of minPrice and maxPrice
    if (price < minPrice) {
      setPrice(minPrice);
    } else if (price > maxPrice) {
      setPrice(maxPrice);
    }
  }, [price, minPrice, maxPrice]);

  // Dynamically calculate the step size based on the range
  const calculateStep = (minPrice: number, maxPrice: number) => {
    const priceRange = maxPrice - minPrice;
    // If the range is small, use a smaller step size for better precision
    if (priceRange <= 10) {
      return 0.001; // For ranges less than $10, use smaller steps
    } else if (priceRange <= 100) {
      return 0.01; // For ranges less than $100, use steps of $0.1
    } else {
      return 1; // For larger ranges, use larger steps
    }
  };

  const resetFilterToInitialValue = (filterId: string) => {
    switch (filterId) {
      case "outbound-journey-duration":
        setOutboundJourneyDuration(maxOutboundJourneyDuration);
        break;
      case "inbound-journey-duration":
        setInboundJourneyDuration(maxInboundJourneyDuration);
        break;
      case "outbound-layover-duration":
        if (maxOutboundLayoverDuration) {
          setOutboundLayoverDuration(maxOutboundLayoverDuration);
        }
        break;
      case "inbound-layover-duration":
        if (maxInboundLayoverDuration) {
          setInboundLayoverDuration(maxInboundLayoverDuration);
        }
        break;
      case "price-range":
        setPrice(maxPrice);
        break;
      default:
        break; // No reset required for other filters
    }
  };

  const resetFilterToCurrentValue = (filterId: string, value: number) => {
    switch (filterId) {
      case "outbound-journey-duration":
        setOutboundJourneyDuration(value);
        break;
      case "inbound-journey-duration":
        setInboundJourneyDuration(value);
        break;
      case "outbound-layover-duration":
        setOutboundLayoverDuration(value);
        break;
      case "inbound-layover-duration":
        setInboundLayoverDuration(value);
        break;
      case "price-range":
        setPrice(value);
        break;
      default:
        break; // No reset required for other filters
    }
  };

  const renderedFormattedTime = (filterId: string, value: number) => {
    switch (filterId) {
      case "outbound-journey-duration":
        return formatTimeInHoursAndMinutes(value);
      case "inbound-journey-duration":
        return formatTimeInHoursAndMinutes(value);
      case "outbound-layover-duration":
        return formatTimeInHoursAndMinutes(value);
      case "inbound-layover-duration":
        return formatTimeInHoursAndMinutes(value);
      case "price-range":
        return formatTimeInHoursAndMinutes(value);
      default:
        return;
    }
  };

  // Calculate the dynamic step size
  const stepSize = calculateStep(minPrice, maxPrice);

  // Handle the change in the input range
  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(Number(event.target.value));
    handleFilterChange({ id: "price-range", label: event.target.value });
  };

  const handleOutboundJourneyChangeDuration = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOutboundJourneyDuration(Number(event.target.value));
    handleFilterChange({
      id: "outbound-journey-duration",
      label: event.target.value,
    });
  };

  const handleInboundJourneyChangeDuration = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInboundJourneyDuration(Number(event.target.value));
    handleFilterChange({
      id: "inbound-journey-duration",
      label: event.target.value,
    });
  };

  const handleOutboundLayoverChangeDuration = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOutboundLayoverDuration(Number(event.target.value));
    handleFilterChange({
      id: "outbound-layover-duration",
      label: event.target.value,
    });
  };

  const handleInboundLayoverChangeDuration = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInboundLayoverDuration(Number(event.target.value));
    handleFilterChange({
      id: "inbound-layover-duration",
      label: event.target.value,
    });
  };

  // Handle checkbox change
  const handleCheckboxChange = (airline: string) => {
    const airlineName = airlinesImg.find(
      (flight: AirlineImageI) => flight.iata === airline
    )?.airlineName;
    handleFilterChange({
      id: "airline",
      label: airlineName ? airlineName : airline,
      value: airline,
    });
  };

  // Options for the stop filters
  const stopOptions = [
    { id: "non-stop", label: "Non-Stop" },
    { id: "1-stop", label: "1 Stop" },
    { id: "more-than-1-stop", label: "More than 1 Stop" },
  ];

  const handleOutboundSelectionStop = (value: string) => {
    handleFilterChange({
      id: "outbound-stop",
      label: `${value} from ${from?.city}`,
    });
  };

  const handleInboundSelectionStop = (value: string) => {
    handleFilterChange({
      id: "inbound-stop",
      label: `${value} from ${to?.city}`,
    });
  };

  const handleFilterChange = (filteredData: AppliedFilterI) => {
    setAppliedFilters((prevFilters: AppliedFilterI[]) => {
      const filterIndex = prevFilters.findIndex(
        (filter) => filter.id === filteredData.id
      );

      if (filterIndex > -1) {
        const selectedFilter = prevFilters[filterIndex];
        // If the label is the same, remove the filter
        if (selectedFilter.label === filteredData.label) {
          localStorage.setItem(
            "appliedFilter",
            JSON.stringify(
              prevFilters.filter((_, index) => index !== filterIndex)
            )
          );
          return prevFilters.filter((_, index) => index !== filterIndex);
        } else {
          // Otherwise, update the filter with the new label
          const updatedFilters = [...prevFilters];
          updatedFilters[filterIndex] = {
            ...selectedFilter,
            label: filteredData.label,
            value: filteredData.value ? filteredData.value : "",
          };
          localStorage.setItem("appliedFilter", JSON.stringify(updatedFilters));
          return updatedFilters;
        }
      }

      // If the filter does not exist, add it
      localStorage.setItem(
        "appliedFilter",
        JSON.stringify([...prevFilters, filteredData])
      );
      return [...prevFilters, filteredData];
    });
  };

  const handleOutboundDepartureTimeChange = (value: TimeType) => {
    handleFilterChange({
      id: "outbound-departure-time",
      label: `Departure from ${from?.city} ${value}`,
      value: value,
    });
  };

  const handleOutboundArrivalTimeChange = (value: TimeType) => {
    handleFilterChange({
      id: "outbound-arrival-time",
      label: `Arrival at ${to?.city} ${value}`,
      value: value,
    });
  };

  const handleInboundDepartureTimeChange = (value: TimeType) => {
    handleFilterChange({
      id: "inbound-departure-time",
      label: `Departure from ${to?.city} ${value}`,
      value: value,
    });
  };

  const handleInboundArrivalTimeChange = (value: TimeType) => {
    handleFilterChange({
      id: "inbound-arrival-time",
      label: `Arrival at ${from?.city} ${value}`,
      value: value,
    });
  };

  const handleClearAllBtn = () => {
    localStorage.removeItem("appliedFilter");
    setAppliedFilters(() => []);
    setOutboundJourneyDuration(maxOutboundJourneyDuration);
    setInboundJourneyDuration(maxInboundJourneyDuration);
    if (maxOutboundLayoverDuration) {
      setOutboundLayoverDuration(maxOutboundLayoverDuration);
    }
    if (maxInboundLayoverDuration) {
      setInboundLayoverDuration(maxInboundLayoverDuration);
    }
    setPrice(maxPrice);
  };

  useEffect(() => {
    handleFlightFilterData(appliedFilters);
  }, [appliedFilters]);

  // Load initial appliedFilter from localStorage
  useEffect(() => {
    const savedFilter = localStorage.getItem("appliedFilter");
    if (savedFilter) {
      setAppliedFilters(() => JSON.parse(savedFilter));
      JSON.parse(savedFilter).forEach((filter: AppliedFilterI) => {
        if (resettableFilters.includes(filter.id)) {
          resetFilterToCurrentValue(filter.id, +filter.label);
        }
      });
    }
  }, []);

  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      const savedFilter = localStorage.getItem("appliedFilter");
      if (savedFilter) {
        setAppliedFilters(() => JSON.parse(savedFilter));
        JSON.parse(savedFilter).forEach((filter: AppliedFilterI) => {
          if (resettableFilters.includes(filter.id)) {
            resetFilterToCurrentValue(filter.id, +filter.label);
          }
        });
      }
      setScreenWidth(window.innerWidth);
    };

    // Add event listener on component mount
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleApplyBtnClick = () => {
    if (closeBackdrop) {
      closeBackdrop();
    }
  };

  return (
    <div className="flex flex-col gap-y-4">
      {appliedFilters.length > 0 && (
        <div className="flex flex-col gap-y-2 border p-2 py-2 rounded-md shadow-[0_0_6px_-1.5px_rgba(0,0,0,0.3)] bg-white">
          <div className="flex flex-row justify-between">
            <p className="text-xs text-gray-700 font-semibold px-1 py-2">
              Applied Filters
            </p>
            <button
              className="text-xs text-white font-semibold rounded-full bg-gradient-to-r from-[#ec2027] to-[#c81212] px-3 py-1 capitalize"
              onClick={handleClearAllBtn}
            >
              clear all
            </button>
          </div>
          <div className="flex flex-wrap items-center gap-x-2 gap-y-1">
            {appliedFilters.map(
              (appliedFilter: AppliedFilterI, index: number) => (
                <p
                  key={index}
                  className="flex flex-row items-center cursor-pointer justify-between border rounded-full px-1 gap-x-2"
                >
                  <span className="text-xs text-gray-500">
                    {conversionFilter.includes(appliedFilter.id) &&
                      renderedFormattedTime(
                        appliedFilter.id,
                        Number(appliedFilter.label)
                      )}
                    {!conversionFilter.includes(appliedFilter.id) &&
                      appliedFilter.id === "price-range" &&
                      `$${parseFloat(appliedFilter.label).toFixed(2)}`}
                    {!conversionFilter.includes(appliedFilter.id) &&
                      appliedFilter.id !== "price-range" &&
                      appliedFilter.label}
                  </span>
                  <TiDelete
                    className="text-gray-500"
                    onClick={() => {
                      if (resettableFilters.includes(appliedFilter.id)) {
                        resetFilterToInitialValue(appliedFilter.id);
                      }
                      handleFilterChange(appliedFilter);
                    }}
                  />
                </p>
              )
            )}
          </div>
        </div>
      )}
      <div className="flex flex-col gap-y-2 border p-2 py-4 rounded-md shadow-[0_0_6px_-1.5px_rgba(0,0,0,0.3)] bg-white">
        <p className="text-xs font-semibold text-gray-700">Price range</p>
        <div>
          <div className="flex flex-row items-center justify-between text-xs text-gray-500">
            <p>${minPrice.toFixed(2)}</p>
            <p>${price.toFixed(2)}</p>
            <p>${maxPrice.toFixed(2)}</p>
          </div>
          <input
            type="range"
            name="priceRange"
            id="priceRange"
            value={price}
            min={minPrice}
            max={maxPrice}
            step={stepSize}
            onChange={handlePriceChange}
            className="w-full h-1 bg-gray-200 rounded-lg cursor-pointer focus:outline-none focus:ring-1 focus:ring-red-500"
            style={{
              accentColor: "red",
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-2 border p-2 py-4 rounded-md shadow-[0_0_6px_-1.5px_rgba(0,0,0,0.3)] bg-white">
        <p className="text-xs text-gray-700 font-semibold">Onward Journey</p>
        <div className="px-2">
          <div className="space-y-2">
            <p className="text-xs text-gray-700 font-semibold">
              Stops from {from?.city}
            </p>
            <div className="flex flex-col px-2">
              {stopOptions.map((option) => (
                <div
                  key={`outbound-${option.id}`}
                  className="flex items-center cursor-pointer"
                >
                  <label
                    htmlFor={`outbound-${option.id}`}
                    className={`flex items-center gap-x-2 p-1 border-gray-300 transition-colors bg-white text-gray-500`}
                  >
                    <div
                      className={`w-4 h-4 border-2 rounded-sm transition-colors
                ${
                  appliedFilters.find(
                    (filter: AppliedFilterI) => filter.id === "outbound-stop"
                  )?.label === `${option.id} from ${from?.city}`
                    ? "bg-gradient-to-r from-[#ec2027] to-[#c81212] border-white"
                    : "bg-transparent border-gray-400"
                }
              `}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOutboundSelectionStop(option.id);
                      }}
                    ></div>
                    <span
                      className="text-xs"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOutboundSelectionStop(option.id);
                      }}
                    >
                      {option.label}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="px-2 space-y-2">
          <p className="text-xs text-gray-700 font-semibold">
            Journey Duration
          </p>
          <div>
            <div className="flex flex-row items-center justify-between text-xs text-gray-500">
              <p>{formatTimeInHoursAndMinutes(minOutboundJourneyDuration)}</p>
              <p>{formatTimeInHoursAndMinutes(outboundJourneyDuration)}</p>
              <p>{formatTimeInHoursAndMinutes(maxOutboundJourneyDuration)}</p>
            </div>
            <input
              type="range"
              name="outboundJourneyDuration"
              id="outboundJourneyDuration"
              value={outboundJourneyDuration}
              min={minOutboundJourneyDuration}
              max={maxOutboundJourneyDuration}
              step={1}
              onChange={handleOutboundJourneyChangeDuration}
              className="w-full h-1 bg-gray-200 rounded-lg cursor-pointer focus:outline-none focus:ring-1 focus:ring-red-500"
              style={{
                accentColor: "red",
              }}
            />
          </div>
        </div>
        <div className="px-2 space-y-2">
          <p className="text-xs text-gray-700 font-semibold">
            Departure from {from?.city}
          </p>
          <div className="flex gap-x-2 items-center justify-center">
            <div
              className={
                appliedFilters.find(
                  (filter: AppliedFilterI) =>
                    filter.id === "outbound-departure-time"
                )?.label === `Departure from ${from?.city} Before 6AM`
                  ? "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white transition-colors px-1"
                  : "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] text-gray-500 hover:text-white transition-colors px-1"
              }
              onClick={() => handleOutboundDepartureTimeChange("Before 6AM")}
            >
              <PiSunHorizonThin className="text-md font-normal" />
              <p className="text-[.5rem] font-normal text-center">Before 6AM</p>
            </div>
            <div
              className={
                appliedFilters.find(
                  (filter: AppliedFilterI) =>
                    filter.id === "outbound-departure-time"
                )?.label === `Departure from ${from?.city} 6AM to 12PM`
                  ? "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white transition-colors px-1"
                  : "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] text-gray-500 hover:text-white transition-colors px-1"
              }
              onClick={() => handleOutboundDepartureTimeChange("6AM to 12PM")}
            >
              <PiSunThin className="text-md font-normal" />
              <p className="text-[.5rem] font-normal text-center">
                6AM to 12PM
              </p>
            </div>
            <div
              className={
                appliedFilters.find(
                  (filter: AppliedFilterI) =>
                    filter.id === "outbound-departure-time"
                )?.label === `Departure from ${from?.city} 12PM to 6PM`
                  ? "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white transition-colors px-1"
                  : "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] text-gray-500 hover:text-white transition-colors px-1"
              }
              onClick={() => handleOutboundDepartureTimeChange("12PM to 6PM")}
            >
              <BsCloudSun className="text-md font-normal" />
              <p className="text-[.5rem] font-normal text-center">
                12PM to 6PM
              </p>
            </div>
            <div
              className={
                appliedFilters.find(
                  (filter: AppliedFilterI) =>
                    filter.id === "outbound-departure-time"
                )?.label === `Departure from ${from?.city} After 6PM`
                  ? "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white transition-colors px-1"
                  : "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] text-gray-500 hover:text-white transition-colors px-1"
              }
              onClick={() => handleOutboundDepartureTimeChange("After 6PM")}
            >
              <BsCloudMoon className="text-md font-normal" />
              <p className="text-[.5rem] font-normal text-center">After 6PM</p>
            </div>
          </div>
        </div>
        <div className="px-2 space-y-2">
          <p className="text-xs text-gray-700 font-semibold">
            Arrival at {to?.city}
          </p>
          <div className="flex gap-x-2 items-center justify-center">
            <div
              className={
                appliedFilters.find(
                  (filter: AppliedFilterI) =>
                    filter.id === "outbound-arrival-time"
                )?.label === `Arrival at ${to?.city} Before 6AM`
                  ? "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white transition-colors px-1"
                  : "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] text-gray-500 hover:text-white transition-colors px-1"
              }
              onClick={() => handleOutboundArrivalTimeChange("Before 6AM")}
            >
              <PiSunHorizonThin className="text-md font-normal" />
              <p className="text-[.5rem] font-normal text-center">Before 6AM</p>
            </div>
            <div
              className={
                appliedFilters.find(
                  (filter: AppliedFilterI) =>
                    filter.id === "outbound-arrival-time"
                )?.label === `Arrival at ${to?.city} 6AM to 12PM`
                  ? "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white transition-colors px-1"
                  : "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] text-gray-500 hover:text-white transition-colors px-1"
              }
              onClick={() => handleOutboundArrivalTimeChange("6AM to 12PM")}
            >
              <PiSunThin className="text-md font-normal" />
              <p className="text-[.5rem] font-normal text-center">
                6AM to 12PM
              </p>
            </div>
            <div
              className={
                appliedFilters.find(
                  (filter: AppliedFilterI) =>
                    filter.id === "outbound-arrival-time"
                )?.label === `Arrival at ${to?.city} 12PM to 6PM`
                  ? "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white transition-colors px-1"
                  : "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] text-gray-500 hover:text-white transition-colors px-1"
              }
              onClick={() => handleOutboundArrivalTimeChange("12PM to 6PM")}
            >
              <BsCloudSun className="text-md font-normal" />
              <p className="text-[.5rem] font-normal text-center">
                12PM to 6PM
              </p>
            </div>
            <div
              className={
                appliedFilters.find(
                  (filter: AppliedFilterI) =>
                    filter.id === "outbound-arrival-time"
                )?.label === `Arrival at ${to?.city} After 6PM`
                  ? "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white transition-colors px-1"
                  : "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] text-gray-500 hover:text-white transition-colors px-1"
              }
              onClick={() => handleOutboundArrivalTimeChange("After 6PM")}
            >
              <BsCloudMoon className="text-md font-normal" />
              <p className="text-[.5rem] font-normal text-center">After 6PM</p>
            </div>
          </div>
        </div>
        {maxOutboundLayoverDuration && minOutboundLayoverDuration && (
          <div className="px-2 space-y-2">
            <p className="text-xs text-gray-700 font-semibold">
              Layover Duration
            </p>
            <div>
              <div className="flex flex-row items-center justify-between text-xs text-gray-500">
                <p>{formatTimeInHoursAndMinutes(minOutboundLayoverDuration)}</p>
                <p>{formatTimeInHoursAndMinutes(outboundLayoverDuration)}</p>
                <p>{formatTimeInHoursAndMinutes(maxOutboundLayoverDuration)}</p>
              </div>
              <input
                type="range"
                name="outboundLayoverDuration"
                id="outboundLayoverDuration"
                value={outboundLayoverDuration}
                min={minOutboundLayoverDuration}
                max={maxOutboundLayoverDuration}
                step={1}
                onChange={handleOutboundLayoverChangeDuration}
                className="w-full h-1 bg-gray-200 rounded-lg cursor-pointer focus:outline-none focus:ring-1 focus:ring-red-500"
                style={{
                  accentColor: "red",
                }}
              />
            </div>
          </div>
        )}
      </div>
      {tripType === "roundtrip" && (
        <div className="flex flex-col gap-y-2 border p-2 py-4 rounded-md shadow-[0_0_6px_-1.5px_rgba(0,0,0,0.3)] bg-white">
          <p className="text-xs text-gray-700 font-semibold">Return Journey</p>
          <div className="px-2">
            <div className="space-y-2">
              <p className="text-xs text-gray-700 font-semibold">
                Stops from {to?.city}
              </p>
              <div className="flex flex-col px-2">
                {stopOptions.map((option) => (
                  <div
                    key={`inbound-${option.id}`}
                    className="flex items-center cursor-pointer"
                  >
                    <label
                      htmlFor={`inbound-${option.id}`}
                      className={`flex items-center gap-x-2 p-1 border-gray-300 transition-colors bg-white text-gray-500`}
                    >
                      <div
                        className={`w-4 h-4 border-2 rounded-sm transition-colors
                ${
                  appliedFilters.find(
                    (filter: AppliedFilterI) => filter.id === "inbound-stop"
                  )?.label === `${option.id} from ${to?.city}`
                    ? "bg-gradient-to-r from-[#ec2027] to-[#c81212] border-white"
                    : "bg-transparent border-gray-400"
                }
              `}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleInboundSelectionStop(option.id);
                        }}
                      ></div>
                      <span
                        className="text-xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleInboundSelectionStop(option.id);
                        }}
                      >
                        {option.label}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="px-2 space-y-2">
            <p className="text-xs text-gray-700 font-semibold">
              Journey Duration
            </p>
            <div>
              <div className="flex flex-row items-center justify-between text-xs text-gray-500">
                <p>{formatTimeInHoursAndMinutes(minInboundJourneyDuration)}</p>
                <p>{formatTimeInHoursAndMinutes(inboundJourneyDuration)}</p>
                <p>{formatTimeInHoursAndMinutes(maxInboundJourneyDuration)}</p>
              </div>
              <input
                type="range"
                name="inboundJourneyDuration"
                id="inboundJourneyDuration"
                value={inboundJourneyDuration}
                min={minInboundJourneyDuration}
                max={maxInboundJourneyDuration}
                step={1}
                onChange={handleInboundJourneyChangeDuration}
                className="w-full h-1 bg-gray-200 rounded-lg cursor-pointer focus:outline-none focus:ring-1 focus:ring-red-500"
                style={{
                  accentColor: "red",
                }}
              />
            </div>
          </div>
          <div className="px-2 space-y-2">
            <p className="text-xs text-gray-700 font-semibold">
              Departure from {to?.city}
            </p>
            <div className="flex gap-x-2 items-center justify-center">
              <div
                className={
                  appliedFilters.find(
                    (filter: AppliedFilterI) =>
                      filter.id === "inbound-departure-time"
                  )?.label === `Departure from ${to?.city} Before 6AM`
                    ? "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white transition-colors px-1"
                    : "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] text-gray-500 hover:text-white transition-colors px-1"
                }
                onClick={() => handleInboundDepartureTimeChange("Before 6AM")}
              >
                <PiSunHorizonThin className="text-md font-normal" />
                <p className="text-[.5rem] font-normal text-center">
                  Before 6AM
                </p>
              </div>
              <div
                className={
                  appliedFilters.find(
                    (filter: AppliedFilterI) =>
                      filter.id === "inbound-departure-time"
                  )?.label === `Departure from ${to?.city} 6AM to 12PM`
                    ? "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white transition-colors px-1"
                    : "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] text-gray-500 hover:text-white transition-colors px-1"
                }
                onClick={() => handleInboundDepartureTimeChange("6AM to 12PM")}
              >
                <PiSunThin className="text-md font-normal" />
                <p className="text-[.5rem] font-normal text-center">
                  6AM to 12PM
                </p>
              </div>
              <div
                className={
                  appliedFilters.find(
                    (filter: AppliedFilterI) =>
                      filter.id === "inbound-departure-time"
                  )?.label === `Departure from ${to?.city} 12PM to 6PM`
                    ? "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white transition-colors px-1"
                    : "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] text-gray-500 hover:text-white transition-colors px-1"
                }
                onClick={() => handleInboundDepartureTimeChange("12PM to 6PM")}
              >
                <BsCloudSun className="text-md font-normal" />
                <p className="text-[.5rem] font-normal text-center">
                  12PM to 6PM
                </p>
              </div>
              <div
                className={
                  appliedFilters.find(
                    (filter: AppliedFilterI) =>
                      filter.id === "inbound-departure-time"
                  )?.label === `Departure from ${to?.city} After 6PM`
                    ? "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white transition-colors px-1"
                    : "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] text-gray-500 hover:text-white transition-colors px-1"
                }
                onClick={() => handleInboundDepartureTimeChange("After 6PM")}
              >
                <BsCloudMoon className="text-md font-normal" />
                <p className="text-[.5rem] font-normal text-center">
                  After 6PM
                </p>
              </div>
            </div>
          </div>
          <div className="px-2 space-y-2">
            <p className="text-xs text-gray-700 font-semibold">
              Arrival at {from?.city}
            </p>
            <div className="flex gap-x-2 items-center justify-center">
              <div
                className={
                  appliedFilters.find(
                    (filter: AppliedFilterI) =>
                      filter.id === "inbound-arrival-time"
                  )?.label === `Arrival at ${from?.city} Before 6AM`
                    ? "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white transition-colors px-1"
                    : "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] text-gray-500 hover:text-white transition-colors px-1"
                }
                onClick={() => handleInboundArrivalTimeChange("Before 6AM")}
              >
                <PiSunHorizonThin className="text-md font-normal" />
                <p className="text-[.5rem] font-normal text-center">
                  Before 6AM
                </p>
              </div>
              <div
                className={
                  appliedFilters.find(
                    (filter: AppliedFilterI) =>
                      filter.id === "inbound-arrival-time"
                  )?.label === `Arrival at ${from?.city} 6AM to 12PM`
                    ? "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white transition-colors px-1"
                    : "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] text-gray-500 hover:text-white transition-colors px-1"
                }
                onClick={() => handleInboundArrivalTimeChange("6AM to 12PM")}
              >
                <PiSunThin className="text-md font-normal" />
                <p className="text-[.5rem] font-normal text-center">
                  6AM to 12PM
                </p>
              </div>
              <div
                className={
                  appliedFilters.find(
                    (filter: AppliedFilterI) =>
                      filter.id === "inbound-arrival-time"
                  )?.label === `Arrival at ${from?.city} 12PM to 6PM`
                    ? "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white transition-colors px-1"
                    : "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] text-gray-500 hover:text-white transition-colors px-1"
                }
                onClick={() => handleInboundArrivalTimeChange("12PM to 6PM")}
              >
                <BsCloudSun className="text-md font-normal" />
                <p className="text-[.5rem] font-normal text-center">
                  12PM to 6PM
                </p>
              </div>
              <div
                className={
                  appliedFilters.find(
                    (filter: AppliedFilterI) =>
                      filter.id === "inbound-arrival-time"
                  )?.label === `Arrival at ${from?.city} After 6PM`
                    ? "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white transition-colors px-1"
                    : "w-[4rem] h-[4rem] flex flex-col justify-center items-center border rounded-md gap-y-1 cursor-pointer hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] text-gray-500 hover:text-white transition-colors px-1"
                }
                onClick={() => handleInboundArrivalTimeChange("After 6PM")}
              >
                <BsCloudMoon className="text-md font-normal" />
                <p className="text-[.5rem] font-normal text-center">
                  After 6PM
                </p>
              </div>
            </div>
          </div>
          {maxInboundLayoverDuration && minInboundLayoverDuration && (
            <div className="px-2 space-y-2">
              <p className="text-xs text-gray-700 font-semibold">
                Layover Duration
              </p>
              <div>
                <div className="flex flex-row items-center justify-between text-xs text-gray-500">
                  <p>
                    {formatTimeInHoursAndMinutes(minInboundLayoverDuration)}
                  </p>
                  <p>{formatTimeInHoursAndMinutes(inboundLayoverDuration)}</p>
                  <p>
                    {formatTimeInHoursAndMinutes(maxInboundLayoverDuration)}
                  </p>
                </div>
                <input
                  type="range"
                  name="inboundLayoverDuration"
                  id="inboundLayoverDuration"
                  value={inboundLayoverDuration}
                  min={minInboundLayoverDuration}
                  max={maxInboundLayoverDuration}
                  step={stepSize}
                  onChange={handleInboundLayoverChangeDuration}
                  className="w-full h-1 bg-gray-200 rounded-lg cursor-pointer focus:outline-none focus:ring-1 focus:ring-red-500"
                  style={{
                    accentColor: "red",
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="flex flex-col gap-y-2 border p-2 py-4 rounded-md shadow-[0_0_6px_-1.5px_rgba(0,0,0,0.3)] bg-white">
        <p className="text-xs text-gray-700 font-semibold">Select Airlines</p>
        <div className="space-y-1">
          {airlines.map((airline: AirlineImageI) => (
            <div
              key={airline.id}
              className="flex items-center cursor-pointer px-2"
              onClick={(e) => {
                e.stopPropagation();
                handleCheckboxChange(airline.iata);
              }}
            >
              <label
                htmlFor={airline.iata}
                className="w-full flex items-center justify-between gap-x-2 p-1 rounded-md cursor-pointer transition-colors"
              >
                <div className="flex items-center gap-x-2">
                  <div
                    className={`w-4 h-4 border-2 rounded-sm transition-colors
                ${
                  appliedFilters.some(
                    (filter: AppliedFilterI) => filter.value === airline.iata
                  )
                    ? "bg-gradient-to-r from-[#ec2027] to-[#c81212] border-white"
                    : "bg-transparent border-gray-400"
                }
              `}
                  ></div>
                  <img src={airline.imgUrl} alt={airline.iata} width={15} />
                  <p
                    className="text-xs
                     text-gray-500"
                  >
                    {airline.airlineName}
                  </p>
                </div>
                <p className="text-xs text-gray-500">{airline.iata}</p>
              </label>
            </div>
          ))}
        </div>
      </div>
      <button
        className="lg:hidden bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white text-xs py-2 font-semibold"
        onClick={handleApplyBtnClick}
      >
        Apply
      </button>
    </div>
  );
};

export default FilterComponent;
