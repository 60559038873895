import { AirportListI } from "../types/flightSearchReducer.types";

export const airportList: AirportListI[] = [
  {
    name: "Hartsfield Jackson Atlanta Intl",
    city: "Atlanta",
    country: "United States",
    iata_code: "ATL",
    _geoloc: {
      lat: 33.636719,
      lng: -84.428067,
    },
    links_count: 1826,
    objectID: "3682",
    uniqueID: "1abe2ac1-d9ba-4f4a-93dd-dc74f1c98407",
  },
  {
    name: "Chicago Ohare Intl",
    city: "Chicago",
    country: "United States",
    iata_code: "ORD",
    _geoloc: {
      lat: 41.978603,
      lng: -87.904842,
    },
    links_count: 1108,
    objectID: "3830",
    uniqueID: "b8ea8c32-9d5e-4135-8dcb-9996ff8f0880",
  },
  {
    name: "Capital Intl",
    city: "Beijing",
    country: "China",
    iata_code: "PEK",
    _geoloc: {
      lat: 40.080111,
      lng: 116.584556,
    },
    links_count: 1069,
    objectID: "3364",
    uniqueID: "6c50ff36-b563-4b71-bb86-6e1d35defe68",
  },
  {
    name: "Heathrow",
    city: "London",
    country: "United Kingdom",
    iata_code: "LHR",
    _geoloc: {
      lat: 51.4775,
      lng: -0.461389,
    },
    links_count: 1051,
    objectID: "507",
    uniqueID: "1511378b-5b8b-4a5b-9da9-c54f28cd9189",
  },
  {
    name: "Charles De Gaulle",
    city: "Paris",
    country: "France",
    iata_code: "CDG",
    _geoloc: {
      lat: 49.012779,
      lng: 2.55,
    },
    links_count: 1041,
    objectID: "1382",
    uniqueID: "e80d9f01-2d60-4ba1-8892-b8f0221c0b0f",
  },
  {
    name: "Los Angeles Intl",
    city: "Los Angeles",
    country: "United States",
    iata_code: "LAX",
    _geoloc: {
      lat: 33.942536,
      lng: -118.408075,
    },
    links_count: 990,
    objectID: "3484",
    uniqueID: "8306b52d-b814-4b6c-a48a-f3f5694aa091",
  },
  {
    name: "Frankfurt Main",
    city: "Frankfurt",
    country: "Germany",
    iata_code: "FRA",
    _geoloc: {
      lat: 50.026421,
      lng: 8.543125,
    },
    links_count: 990,
    objectID: "340",
    uniqueID: "cc169162-5d2b-41e3-a569-42110ac6a356",
  },
  {
    name: "Dallas Fort Worth Intl",
    city: "Dallas-Fort Worth",
    country: "United States",
    iata_code: "DFW",
    _geoloc: {
      lat: 32.896828,
      lng: -97.037997,
    },
    links_count: 936,
    objectID: "3670",
    uniqueID: "1762f6ff-9803-485f-97e1-84d558bf6e21",
  },
  {
    name: "John F Kennedy Intl",
    city: "New York",
    country: "United States",
    iata_code: "JFK",
    _geoloc: {
      lat: 40.639751,
      lng: -73.778925,
    },
    links_count: 911,
    objectID: "3797",
    uniqueID: "87ee0dce-931c-4aa9-a140-7710d0a2722f",
  },
  {
    name: "Schiphol",
    city: "Amsterdam",
    country: "Netherlands",
    iata_code: "AMS",
    _geoloc: {
      lat: 52.308613,
      lng: 4.763889,
    },
    links_count: 903,
    objectID: "580",
    uniqueID: "b1f47263-2a0e-4b10-b93f-c22a46ce8703",
  },
  {
    name: "Pudong",
    city: "Shanghai",
    country: "China",
    iata_code: "PVG",
    _geoloc: {
      lat: 31.143378,
      lng: 121.805214,
    },
    links_count: 825,
    objectID: "3406",
    uniqueID: "88d1d4a6-3465-4822-b4f4-66bc4c70759a",
  },
  {
    name: "Changi Intl",
    city: "Singapore",
    country: "Singapore",
    iata_code: "SIN",
    _geoloc: {
      lat: 1.350189,
      lng: 103.994433,
    },
    links_count: 820,
    objectID: "3316",
    uniqueID: "b691a1c8-8184-46f9-8124-f7e60af7585e",
  },
  {
    name: "Barcelona",
    city: "Barcelona",
    country: "Spain",
    iata_code: "BCN",
    _geoloc: {
      lat: 41.297078,
      lng: 2.078464,
    },
    links_count: 783,
    objectID: "1218",
    uniqueID: "802913dc-37f3-4687-bfcc-7af55d46be4d",
  },
  {
    name: "Incheon Intl",
    city: "Seoul",
    country: "South Korea",
    iata_code: "ICN",
    _geoloc: {
      lat: 37.469075,
      lng: 126.450517,
    },
    links_count: 740,
    objectID: "3930",
    uniqueID: "9f9df5c4-20a8-4355-8697-dc6ad6baa53d",
  },
  {
    name: "Denver Intl",
    city: "Denver",
    country: "United States",
    iata_code: "DEN",
    _geoloc: {
      lat: 39.861656,
      lng: -104.673178,
    },
    links_count: 735,
    objectID: "3751",
    uniqueID: "57f0cfd7-9baf-4f22-b43a-70d2cfb3387d",
  },
  {
    name: "Miami Intl",
    city: "Miami",
    country: "United States",
    iata_code: "MIA",
    _geoloc: {
      lat: 25.79325,
      lng: -80.290556,
    },
    links_count: 734,
    objectID: "3576",
    uniqueID: "dd699f96-ea61-49f0-bfac-0f9e149dcf71",
  },
  {
    name: "Franz Josef Strauss",
    city: "Munich",
    country: "Germany",
    iata_code: "MUC",
    _geoloc: {
      lat: 48.353783,
      lng: 11.786086,
    },
    links_count: 728,
    objectID: "346",
    uniqueID: "6cbedf13-73f2-4619-b31d-6ec2104b5b4a",
  },
  {
    name: "Ataturk",
    city: "Istanbul",
    country: "Turkey",
    iata_code: "IST",
    _geoloc: {
      lat: 40.976922,
      lng: 28.814606,
    },
    links_count: 719,
    objectID: "1701",
    uniqueID: "df502e39-bced-4b82-966f-0385ee4eb24b",
  },
  {
    name: "Hong Kong Intl",
    city: "Hong Kong",
    country: "Hong Kong",
    iata_code: "HKG",
    _geoloc: {
      lat: 22.308919,
      lng: 113.914603,
    },
    links_count: 710,
    objectID: "3077",
    uniqueID: "5690f853-f246-4cf6-8e15-96753d851981",
  },
  {
    name: "Dubai Intl",
    city: "Dubai",
    country: "United Arab Emirates",
    iata_code: "DXB",
    _geoloc: {
      lat: 25.252778,
      lng: 55.364444,
    },
    links_count: 710,
    objectID: "2188",
    uniqueID: "42d9457b-386f-47cd-90fb-18e7f7d3fe91",
  },
  {
    name: "Gatwick",
    city: "London",
    country: "United Kingdom",
    iata_code: "LGW",
    _geoloc: {
      lat: 51.148056,
      lng: -0.190278,
    },
    links_count: 708,
    objectID: "502",
    uniqueID: "62cec258-a853-4959-8de1-e68dc961c3c3",
  },
  {
    name: "Baiyun Intl",
    city: "Guangzhou",
    country: "China",
    iata_code: "CAN",
    _geoloc: {
      lat: 23.392436,
      lng: 113.298786,
    },
    links_count: 674,
    objectID: "3370",
    uniqueID: "6d4b40a7-73e2-4155-a2e1-899e121bd52f",
  },
  {
    name: "Fiumicino",
    city: "Rome",
    country: "Italy",
    iata_code: "FCO",
    _geoloc: {
      lat: 41.804475,
      lng: 12.250797,
    },
    links_count: 662,
    objectID: "1555",
    uniqueID: "3268032e-fcf6-4de6-b031-97bbf1c06ffc",
  },
  {
    name: "Barajas",
    city: "Madrid",
    country: "Spain",
    iata_code: "MAD",
    _geoloc: {
      lat: 40.493556,
      lng: -3.566764,
    },
    links_count: 661,
    objectID: "1229",
    uniqueID: "35eaedce-26bf-4177-b61f-2f23fe68ddea",
  },
  {
    name: "Suvarnabhumi Intl",
    city: "Bangkok",
    country: "Thailand",
    iata_code: "BKK",
    _geoloc: {
      lat: 13.681108,
      lng: 100.747283,
    },
    links_count: 656,
    objectID: "3885",
    uniqueID: "1c9a7793-9635-4f26-b8f9-91bb4ee9db6f",
  },
  {
    name: "Domododevo",
    city: "Moscow",
    country: "Russia",
    iata_code: "DME",
    _geoloc: {
      lat: 55.408611,
      lng: 37.906111,
    },
    links_count: 649,
    objectID: "4029",
    uniqueID: "9a40da72-0735-4184-a41c-0e3e274250c1",
  },
  {
    name: "Shuangliu",
    city: "Chengdu",
    country: "China",
    iata_code: "CTU",
    _geoloc: {
      lat: 30.578528,
      lng: 103.947086,
    },
    links_count: 647,
    objectID: "3395",
    uniqueID: "b422da97-b681-4da0-a18e-97d050e7359c",
  },
  {
    name: "Lester B Pearson Intl",
    city: "Toronto",
    country: "Canada",
    iata_code: "YYZ",
    _geoloc: {
      lat: 43.677223,
      lng: -79.630556,
    },
    links_count: 636,
    objectID: "193",
    uniqueID: "4d612d62-dd7b-4e30-a13f-0394620460d7",
  },
  {
    name: "Brussels Natl",
    city: "Brussels",
    country: "Belgium",
    iata_code: "BRU",
    _geoloc: {
      lat: 50.901389,
      lng: 4.484444,
    },
    links_count: 631,
    objectID: "302",
    uniqueID: "f7c341a1-ee88-4a7d-976e-00a3369c4f8a",
  },
  {
    name: "Manchester",
    city: "Manchester",
    country: "United Kingdom",
    iata_code: "MAN",
    _geoloc: {
      lat: 53.353744,
      lng: -2.27495,
    },
    links_count: 627,
    objectID: "478",
    uniqueID: "f9bcca96-6714-4dab-9132-dbe94745171d",
  },
  {
    name: "Schwechat",
    city: "Vienna",
    country: "Austria",
    iata_code: "VIE",
    _geoloc: {
      lat: 48.110278,
      lng: 16.569722,
    },
    links_count: 616,
    objectID: "1613",
    uniqueID: "892e7917-3197-4684-8e2d-be7720715975",
  },
  {
    name: "Charlotte Douglas Intl",
    city: "Charlotte",
    country: "United States",
    iata_code: "CLT",
    _geoloc: {
      lat: 35.214,
      lng: -80.943139,
    },
    links_count: 600,
    objectID: "3876",
    uniqueID: "a59260eb-958f-4ab4-8786-f888c3b1f622",
  },
  {
    name: "Philadelphia Intl",
    city: "Philadelphia",
    country: "United States",
    iata_code: "PHL",
    _geoloc: {
      lat: 39.871944,
      lng: -75.241139,
    },
    links_count: 576,
    objectID: "3752",
    uniqueID: "ae9d1ea1-b947-4496-a9e5-416161e09417",
  },
  {
    name: "Narita Intl",
    city: "Tokyo",
    country: "Japan",
    iata_code: "NRT",
    _geoloc: {
      lat: 35.764722,
      lng: 140.386389,
    },
    links_count: 575,
    objectID: "2279",
    uniqueID: "9ad01715-2cfd-4ebd-8217-cfd7fa9b2c23",
  },
  {
    name: "Dusseldorf",
    city: "Duesseldorf",
    country: "Germany",
    iata_code: "DUS",
    _geoloc: {
      lat: 51.289453,
      lng: 6.766775,
    },
    links_count: 570,
    objectID: "345",
    uniqueID: "c5eb18fe-adb1-46ca-a52b-3b2ee83b1524",
  },
  {
    name: "Xianyang",
    city: "Xi'an",
    country: "China",
    iata_code: "XIY",
    _geoloc: {
      lat: 34.447119,
      lng: 108.751592,
    },
    links_count: 563,
    objectID: "3379",
    uniqueID: "161c9c3e-b792-4c2f-ac68-f1599e8ea242",
  },
  {
    name: "Son Sant Joan",
    city: "Palma de Mallorca",
    country: "Spain",
    iata_code: "PMI",
    _geoloc: {
      lat: 39.55361,
      lng: 2.727778,
    },
    links_count: 548,
    objectID: "3998",
    uniqueID: "f31037d3-3f53-4b3f-9307-bfa069fb9c8b",
  },
  {
    name: "Wujiaba",
    city: "Kunming",
    country: "China",
    iata_code: "KMG",
    _geoloc: {
      lat: 24.992364,
      lng: 102.743536,
    },
    links_count: 545,
    objectID: "3382",
    uniqueID: "42950ef0-3e06-4460-b085-bd8711ce6ba1",
  },
  {
    name: "Jiangbei",
    city: "Chongqing",
    country: "China",
    iata_code: "CKG",
    _geoloc: {
      lat: 29.719217,
      lng: 106.641678,
    },
    links_count: 540,
    objectID: "3393",
    uniqueID: "a37464ac-7be9-4de2-ba5e-5f6c48676fb5",
  },
  {
    name: "Indira Gandhi Intl",
    city: "Delhi",
    country: "India",
    iata_code: "DEL",
    _geoloc: {
      lat: 28.5665,
      lng: 77.103088,
    },
    links_count: 527,
    objectID: "3093",
    uniqueID: "155412c4-c79b-4d7e-bd31-f1d65448223e",
  },
  {
    name: "Taoyuan Intl",
    city: "Taipei",
    country: "Taiwan",
    iata_code: "TPE",
    _geoloc: {
      lat: 25.077731,
      lng: 121.232822,
    },
    links_count: 527,
    objectID: "2276",
    uniqueID: "bdacd108-ebf6-45e1-a6d6-7c8f81b4e1d6",
  },
  {
    name: "Kuala Lumpur Intl",
    city: "Kuala Lumpur",
    country: "Malaysia",
    iata_code: "KUL",
    _geoloc: {
      lat: 2.745578,
      lng: 101.709917,
    },
    links_count: 514,
    objectID: "3304",
    uniqueID: "26707ad5-845f-4d93-9a1b-e7208aef815c",
  },
  {
    name: "Phoenix Sky Harbor Intl",
    city: "Phoenix",
    country: "United States",
    iata_code: "PHX",
    _geoloc: {
      lat: 33.434278,
      lng: -112.011583,
    },
    links_count: 508,
    objectID: "3462",
    uniqueID: "077151fd-55bb-4b1d-a408-4d3f979206e8",
  },
  {
    name: "Xiaoshan",
    city: "Hangzhou",
    country: "China",
    iata_code: "HGH",
    _geoloc: {
      lat: 30.229503,
      lng: 120.434453,
    },
    links_count: 506,
    objectID: "3386",
    uniqueID: "71e77abb-8a18-4712-af9f-f0f705e2eb03",
  },
  {
    name: "Newark Liberty Intl",
    city: "Newark",
    country: "United States",
    iata_code: "EWR",
    _geoloc: {
      lat: 40.6925,
      lng: -74.168667,
    },
    links_count: 505,
    objectID: "3494",
    uniqueID: "d87f93cb-7bfc-404e-9691-877624c559ae",
  },
  {
    name: "San Francisco Intl",
    city: "San Francisco",
    country: "United States",
    iata_code: "SFO",
    _geoloc: {
      lat: 37.618972,
      lng: -122.374889,
    },
    links_count: 499,
    objectID: "3469",
    uniqueID: "65e3f6b3-08d9-46e9-a519-e46908e9aaa1",
  },
  {
    name: "Mc Carran Intl",
    city: "Las Vegas",
    country: "United States",
    iata_code: "LAS",
    _geoloc: {
      lat: 36.080056,
      lng: -115.15225,
    },
    links_count: 498,
    objectID: "3877",
    uniqueID: "ae5fdb3a-8379-43b3-9c4e-283565c1bedb",
  },
  {
    name: "Zurich",
    city: "Zurich",
    country: "Switzerland",
    iata_code: "ZRH",
    _geoloc: {
      lat: 47.464722,
      lng: 8.549167,
    },
    links_count: 494,
    objectID: "1678",
    uniqueID: "90039379-2472-4ef7-a321-e19384ee86e5",
  },
  {
    name: "George Bush Intercontinental",
    city: "Houston",
    country: "United States",
    iata_code: "IAH",
    _geoloc: {
      lat: 29.984433,
      lng: -95.341442,
    },
    links_count: 485,
    objectID: "3550",
    uniqueID: "21d48192-6b58-4922-95c3-3345d5c99c0d",
  },
  {
    name: "Licenciado Benito Juarez Intl",
    city: "Mexico City",
    country: "Mexico",
    iata_code: "MEX",
    _geoloc: {
      lat: 19.436303,
      lng: -99.072097,
    },
    links_count: 485,
    objectID: "1824",
    uniqueID: "b824d473-2b2e-43d5-a18a-4bd9602f042a",
  },
  {
    name: "Abu Dhabi Intl",
    city: "Abu Dhabi",
    country: "United Arab Emirates",
    iata_code: "AUH",
    _geoloc: {
      lat: 24.432972,
      lng: 54.651138,
    },
    links_count: 477,
    objectID: "2179",
    uniqueID: "b10945f2-e9b4-4051-9200-343b6a37c272",
  },
  {
    name: "Orlando Intl",
    city: "Orlando",
    country: "United States",
    iata_code: "MCO",
    _geoloc: {
      lat: 28.429394,
      lng: -81.308994,
    },
    links_count: 475,
    objectID: "3878",
    uniqueID: "adfaf8cd-191f-4413-8f6c-8eb251632387",
  },
  {
    name: "Baoan Intl",
    city: "Shenzhen",
    country: "China",
    iata_code: "SZX",
    _geoloc: {
      lat: 22.639258,
      lng: 113.810664,
    },
    links_count: 467,
    objectID: "3374",
    uniqueID: "00533dd6-3a3d-4f89-837c-2fe59c57af5f",
  },
  {
    name: "Gaoqi",
    city: "Xiamen",
    country: "China",
    iata_code: "XMN",
    _geoloc: {
      lat: 24.544036,
      lng: 118.127739,
    },
    links_count: 466,
    objectID: "3383",
    uniqueID: "75f40091-bd6b-4432-a174-e7752387aacc",
  },
  {
    name: "Kastrup",
    city: "Copenhagen",
    country: "Denmark",
    iata_code: "CPH",
    _geoloc: {
      lat: 55.617917,
      lng: 12.655972,
    },
    links_count: 457,
    objectID: "609",
    uniqueID: "4a697d05-d39b-4aff-ad8a-37925710af3d",
  },
  {
    name: "Chhatrapati Shivaji Intl",
    city: "Mumbai",
    country: "India",
    iata_code: "BOM",
    _geoloc: {
      lat: 19.088686,
      lng: 72.867919,
    },
    links_count: 452,
    objectID: "2997",
    uniqueID: "317e72a1-8ff1-47d4-9f4f-f30f2996280c",
  },
  {
    name: "Arlanda",
    city: "Stockholm",
    country: "Sweden",
    iata_code: "ARN",
    _geoloc: {
      lat: 59.651944,
      lng: 17.918611,
    },
    links_count: 439,
    objectID: "737",
    uniqueID: "e8a93726-94d4-4533-aa2c-c52d066fd1b8",
  },
  {
    name: "Lisboa",
    city: "Lisbon",
    country: "Portugal",
    iata_code: "LIS",
    _geoloc: {
      lat: 38.781311,
      lng: -9.135919,
    },
    links_count: 435,
    objectID: "1638",
    uniqueID: "213fcc26-b86a-459b-aaa8-89d87217ce79",
  },
  {
    name: "Detroit Metro Wayne Co",
    city: "Detroit",
    country: "United States",
    iata_code: "DTW",
    _geoloc: {
      lat: 42.212444,
      lng: -83.353389,
    },
    links_count: 433,
    objectID: "3645",
    uniqueID: "16d53bc7-1f52-44d0-a84d-676ad8e791f9",
  },
  {
    name: "Minneapolis St Paul Intl",
    city: "Minneapolis",
    country: "United States",
    iata_code: "MSP",
    _geoloc: {
      lat: 44.881956,
      lng: -93.221767,
    },
    links_count: 430,
    objectID: "3858",
    uniqueID: "7ecda556-943e-43a8-972a-4248942731c2",
  },
  {
    name: "General Edward Lawrence Logan Intl",
    city: "Boston",
    country: "United States",
    iata_code: "BOS",
    _geoloc: {
      lat: 42.364347,
      lng: -71.005181,
    },
    links_count: 424,
    objectID: "3448",
    uniqueID: "dca3c4d1-391b-491c-b3b1-73f18af14ce8",
  },
  {
    name: "Tegel",
    city: "Berlin",
    country: "Germany",
    iata_code: "TXL",
    _geoloc: {
      lat: 52.559686,
      lng: 13.287711,
    },
    links_count: 418,
    objectID: "351",
    uniqueID: "3d4a1df6-a2a9-4088-9928-eae223ce6c3e",
  },
  {
    name: "Dublin",
    city: "Dublin",
    country: "Ireland",
    iata_code: "DUB",
    _geoloc: {
      lat: 53.421333,
      lng: -6.270075,
    },
    links_count: 414,
    objectID: "599",
    uniqueID: "109665ae-f62b-4e99-a963-bc3900249ba4",
  },
  {
    name: "Hongqiao Intl",
    city: "Shanghai",
    country: "China",
    iata_code: "SHA",
    _geoloc: {
      lat: 31.197875,
      lng: 121.336319,
    },
    links_count: 411,
    objectID: "3391",
    uniqueID: "1c010033-ed63-4e15-bf6b-1b90529adf4d",
  },
  {
    name: "Sydney Intl",
    city: "Sydney",
    country: "Australia",
    iata_code: "SYD",
    _geoloc: {
      lat: -33.946111,
      lng: 151.177222,
    },
    links_count: 410,
    objectID: "3361",
    uniqueID: "59ca1db3-2bdd-4a7b-bc38-660dc1835393",
  },
  {
    name: "Malaga",
    city: "Malaga",
    country: "Spain",
    iata_code: "AGP",
    _geoloc: {
      lat: 36.6749,
      lng: -4.499106,
    },
    links_count: 410,
    objectID: "1230",
    uniqueID: "0d6381f3-afb7-4f8a-a44f-b6d6154cda77",
  },
  {
    name: "Orly",
    city: "Paris",
    country: "France",
    iata_code: "ORY",
    _geoloc: {
      lat: 48.725278,
      lng: 2.359444,
    },
    links_count: 404,
    objectID: "1386",
    uniqueID: "7df51394-8ce7-44f1-98fa-5377cd637959",
  },
  {
    name: "Eleftherios Venizelos Intl",
    city: "Athens",
    country: "Greece",
    iata_code: "ATH",
    _geoloc: {
      lat: 37.936358,
      lng: 23.944467,
    },
    links_count: 403,
    objectID: "3941",
    uniqueID: "954306c0-e086-48dc-a80e-f8b5d7ab1b5b",
  },
  {
    name: "Seattle Tacoma Intl",
    city: "Seattle",
    country: "United States",
    iata_code: "SEA",
    _geoloc: {
      lat: 47.449,
      lng: -122.309306,
    },
    links_count: 403,
    objectID: "3577",
    uniqueID: "f67a63e8-aea2-4f6b-a557-8d90e5c25081",
  },
  {
    name: "Sheremetyevo",
    city: "Moscow",
    country: "Russia",
    iata_code: "SVO",
    _geoloc: {
      lat: 55.972642,
      lng: 37.414589,
    },
    links_count: 397,
    objectID: "2985",
    uniqueID: "501064c8-38ed-4f3d-ad48-644475e9cd0c",
  },
  {
    name: "Ronald Reagan Washington Natl",
    city: "Washington",
    country: "United States",
    iata_code: "DCA",
    _geoloc: {
      lat: 38.852083,
      lng: -77.037722,
    },
    links_count: 388,
    objectID: "3520",
    uniqueID: "5c3ac374-1b7f-4e7c-8197-5741e3babb3b",
  },
  {
    name: "Guarulhos Gov Andre Franco Montouro",
    city: "Sao Paulo",
    country: "Brazil",
    iata_code: "GRU",
    _geoloc: {
      lat: -23.432075,
      lng: -46.469511,
    },
    links_count: 387,
    objectID: "2564",
    uniqueID: "7f869711-e880-4309-b71c-2a892ff21ad6",
  },
  {
    name: "Washington Dulles Intl",
    city: "Washington",
    country: "United States",
    iata_code: "IAD",
    _geoloc: {
      lat: 38.944533,
      lng: -77.455811,
    },
    links_count: 386,
    objectID: "3714",
    uniqueID: "088c1155-1d1b-485e-97cf-39a305d78fd4",
  },
  {
    name: "Ninoy Aquino Intl",
    city: "Manila",
    country: "Philippines",
    iata_code: "MNL",
    _geoloc: {
      lat: 14.508647,
      lng: 121.019581,
    },
    links_count: 386,
    objectID: "2397",
    uniqueID: "83c646d0-e006-4777-bdf0-cab5197afcfb",
  },
  {
    name: "Malpensa",
    city: "Milano",
    country: "Italy",
    iata_code: "MXP",
    _geoloc: {
      lat: 45.630606,
      lng: 8.728111,
    },
    links_count: 386,
    objectID: "1524",
    uniqueID: "fab9fd08-da1e-4534-b57b-37e534d4ddda",
  },
  {
    name: "Huanghua",
    city: "Changcha",
    country: "China",
    iata_code: "CSX",
    _geoloc: {
      lat: 28.189158,
      lng: 113.219633,
    },
    links_count: 377,
    objectID: "3371",
    uniqueID: "e35fa38f-27ab-4485-a98e-d3ac0e93d60b",
  },
  {
    name: "Doha Intl",
    city: "Doha",
    country: "Qatar",
    iata_code: "DOH",
    _geoloc: {
      lat: 25.261125,
      lng: 51.565056,
    },
    links_count: 377,
    objectID: "2241",
    uniqueID: "64df2537-fc7a-40ef-8f2d-70e5bbb568e5",
  },
  {
    name: "King Abdulaziz Intl",
    city: "Jeddah",
    country: "Saudi Arabia",
    iata_code: "JED",
    _geoloc: {
      lat: 21.679564,
      lng: 39.156536,
    },
    links_count: 377,
    objectID: "2072",
    uniqueID: "f074e30d-453b-411d-83ca-3e05589bfe31",
  },
  {
    name: "Liuting",
    city: "Qingdao",
    country: "China",
    iata_code: "TAO",
    _geoloc: {
      lat: 36.266108,
      lng: 120.374436,
    },
    links_count: 376,
    objectID: "3390",
    uniqueID: "a824a3ac-b352-4211-a73c-4bbd1feffb4b",
  },
  {
    name: "Fort Lauderdale Hollywood Intl",
    city: "Fort Lauderdale",
    country: "United States",
    iata_code: "FLL",
    _geoloc: {
      lat: 26.072583,
      lng: -80.15275,
    },
    links_count: 374,
    objectID: "3533",
    uniqueID: "14f8bd69-8211-4faa-bd14-54076b3c465d",
  },
  {
    name: "Tianhe",
    city: "Wuhan",
    country: "China",
    iata_code: "WUH",
    _geoloc: {
      lat: 30.783758,
      lng: 114.2081,
    },
    links_count: 374,
    objectID: "3376",
    uniqueID: "07b43d3b-9df9-48ca-8c39-4a6a2da2c677",
  },
  {
    name: "Pierre Elliott Trudeau Intl",
    city: "Montreal",
    country: "Canada",
    iata_code: "YUL",
    _geoloc: {
      lat: 45.470556,
      lng: -73.740833,
    },
    links_count: 371,
    objectID: "146",
    uniqueID: "e86ad88b-058c-48fd-b6b4-fdd1f70c900d",
  },
  {
    name: "Pulkovo",
    city: "St. Petersburg",
    country: "Russia",
    iata_code: "LED",
    _geoloc: {
      lat: 59.800292,
      lng: 30.262503,
    },
    links_count: 368,
    objectID: "2948",
    uniqueID: "cd770bbc-6a66-4f0a-8c85-7db55258fe60",
  },
  {
    name: "Soekarno Hatta Intl",
    city: "Jakarta",
    country: "Indonesia",
    iata_code: "CGK",
    _geoloc: {
      lat: -6.125567,
      lng: 106.655897,
    },
    links_count: 367,
    objectID: "3275",
    uniqueID: "cd15090c-0854-4e29-8dfd-e73ba174400c",
  },
  {
    name: "Gardermoen",
    city: "Oslo",
    country: "Norway",
    iata_code: "OSL",
    _geoloc: {
      lat: 60.193917,
      lng: 11.100361,
    },
    links_count: 363,
    objectID: "644",
    uniqueID: "2e14ea92-39ba-4b6a-a4d8-5a0f6b4478ac",
  },
  {
    name: "Ruzyne",
    city: "Prague",
    country: "Czech Republic",
    iata_code: "PRG",
    _geoloc: {
      lat: 50.100833,
      lng: 14.26,
    },
    links_count: 361,
    objectID: "1587",
    uniqueID: "098a449a-a8c2-4e70-876b-78831a18fd00",
  },
  {
    name: "Xinzheng",
    city: "Zhengzhou",
    country: "China",
    iata_code: "CGO",
    _geoloc: {
      lat: 34.519672,
      lng: 113.840889,
    },
    links_count: 348,
    objectID: "3375",
    uniqueID: "e2c26872-fac3-4164-8074-98383a5e8bab",
  },
  {
    name: "Stansted",
    city: "London",
    country: "United Kingdom",
    iata_code: "STN",
    _geoloc: {
      lat: 51.885,
      lng: 0.235,
    },
    links_count: 343,
    objectID: "548",
    uniqueID: "d4f63610-328a-490b-83b2-2b58d08238ed",
  },
  {
    name: "Lukou",
    city: "Nanjing",
    country: "China",
    iata_code: "NKG",
    _geoloc: {
      lat: 31.742042,
      lng: 118.862025,
    },
    links_count: 330,
    objectID: "3388",
    uniqueID: "cb43c2b3-f8d4-4d32-8e5e-55b679b7ba0a",
  },
  {
    name: "Geneve Cointrin",
    city: "Geneva",
    country: "Switzerland",
    iata_code: "GVA",
    _geoloc: {
      lat: 46.238064,
      lng: 6.10895,
    },
    links_count: 329,
    objectID: "1665",
    uniqueID: "364e6093-3062-40e1-b0d0-ee45814fdb41",
  },
  {
    name: "Johannesburg Intl",
    city: "Johannesburg",
    country: "South Africa",
    iata_code: "JNB",
    _geoloc: {
      lat: -26.139166,
      lng: 28.246,
    },
    links_count: 321,
    objectID: "813",
    uniqueID: "c54710db-e4a8-4c3d-a362-1fd367e0d93d",
  },
  {
    name: "Hamburg",
    city: "Hamburg",
    country: "Germany",
    iata_code: "HAM",
    _geoloc: {
      lat: 53.630389,
      lng: 9.988228,
    },
    links_count: 321,
    objectID: "342",
    uniqueID: "0dd2e537-8700-45e4-af27-27463bf67662",
  },
  {
    name: "Helsinki Vantaa",
    city: "Helsinki",
    country: "Finland",
    iata_code: "HEL",
    _geoloc: {
      lat: 60.317222,
      lng: 24.963333,
    },
    links_count: 320,
    objectID: "421",
    uniqueID: "8a65aa71-a945-4cec-a3fe-8a415e497d95",
  },
  {
    name: "Tenerife Sur",
    city: "Tenerife",
    country: "Spain",
    iata_code: "TFS",
    _geoloc: {
      lat: 28.044475,
      lng: -16.572489,
    },
    links_count: 318,
    objectID: "1056",
    uniqueID: "75132420-1884-4fd6-be0e-3736a56278a5",
  },
  {
    name: "La Guardia",
    city: "New York",
    country: "United States",
    iata_code: "LGA",
    _geoloc: {
      lat: 40.777245,
      lng: -73.872608,
    },
    links_count: 316,
    objectID: "3697",
    uniqueID: "98beef09-970b-4b2e-addf-862f8342e244",
  },
  {
    name: "Tokyo Intl",
    city: "Tokyo",
    country: "Japan",
    iata_code: "HND",
    _geoloc: {
      lat: 35.552258,
      lng: 139.779694,
    },
    links_count: 315,
    objectID: "2359",
    uniqueID: "384d1031-df1b-4956-9dc2-31cca81eceda",
  },
  {
    name: "Vancouver Intl",
    city: "Vancouver",
    country: "Canada",
    iata_code: "YVR",
    _geoloc: {
      lat: 49.193889,
      lng: -123.184444,
    },
    links_count: 315,
    objectID: "156",
    uniqueID: "8ff83d3e-e378-40fb-bf65-f0049d467d35",
  },
  {
    name: "Alicante",
    city: "Alicante",
    country: "Spain",
    iata_code: "ALC",
    _geoloc: {
      lat: 38.282169,
      lng: -0.558156,
    },
    links_count: 314,
    objectID: "1212",
    uniqueID: "77212717-ca21-41c0-8d30-4ad2bf1c88cf",
  },
  {
    name: "Cancun Intl",
    city: "Cancun",
    country: "Mexico",
    iata_code: "CUN",
    _geoloc: {
      lat: 21.036528,
      lng: -86.877083,
    },
    links_count: 311,
    objectID: "1852",
    uniqueID: "6460310c-d9d0-45bf-b213-d9f87bae50e3",
  },
  {
    name: "Gran Canaria",
    city: "Gran Canaria",
    country: "Spain",
    iata_code: "LPA",
    _geoloc: {
      lat: 27.931886,
      lng: -15.386586,
    },
    links_count: 308,
    objectID: "1054",
    uniqueID: "f564bab5-7c58-4d84-8e09-e0822c71b299",
  },
  {
    name: "Eldorado Intl",
    city: "Bogota",
    country: "Colombia",
    iata_code: "BOG",
    _geoloc: {
      lat: 4.701594,
      lng: -74.146947,
    },
    links_count: 307,
    objectID: "2709",
    uniqueID: "0041b3fc-6af7-4585-9120-699300dcce52",
  },
  {
    name: "Diwopu",
    city: "Urumqi",
    country: "China",
    iata_code: "URC",
    _geoloc: {
      lat: 43.907106,
      lng: 87.474244,
    },
    links_count: 306,
    objectID: "3399",
    uniqueID: "febf98c5-829e-46b1-87af-5edc97fa768e",
  },
  {
    name: "Kansai",
    city: "Osaka",
    country: "Japan",
    iata_code: "KIX",
    _geoloc: {
      lat: 34.4347222,
      lng: 135.244167,
    },
    links_count: 304,
    objectID: "3992",
    uniqueID: "2a2157e3-21a2-4027-83f2-d9f0913bf648",
  },
  {
    name: "Cairo Intl",
    city: "Cairo",
    country: "Egypt",
    iata_code: "CAI",
    _geoloc: {
      lat: 30.121944,
      lng: 31.405556,
    },
    links_count: 303,
    objectID: "1128",
    uniqueID: "b278261a-bdf7-4050-b0b0-dd663d16bbd2",
  },
  {
    name: "Brisbane Intl",
    city: "Brisbane",
    country: "Australia",
    iata_code: "BNE",
    _geoloc: {
      lat: -27.384167,
      lng: 153.1175,
    },
    links_count: 296,
    objectID: "3320",
    uniqueID: "df6fe087-2c67-4fb5-aaf6-1bc1c8bd0422",
  },
  {
    name: "Binhai",
    city: "Tianjin",
    country: "China",
    iata_code: "TSN",
    _geoloc: {
      lat: 39.124353,
      lng: 117.346183,
    },
    links_count: 294,
    objectID: "3368",
    uniqueID: "29e33bb8-2e91-4cd2-b148-53973166a856",
  },
  {
    name: "Sabiha Gokcen",
    city: "Istanbul",
    country: "Turkey",
    iata_code: "SAW",
    _geoloc: {
      lat: 40.898553,
      lng: 29.309219,
    },
    links_count: 290,
    objectID: "4317",
    uniqueID: "f2978dc9-919f-4029-8d5c-7f2f21259ee2",
  },
  {
    name: "Baltimore Washington Intl",
    city: "Baltimore",
    country: "United States",
    iata_code: "BWI",
    _geoloc: {
      lat: 39.175361,
      lng: -76.668333,
    },
    links_count: 286,
    objectID: "3849",
    uniqueID: "dd9c7c1b-0dea-4551-83b2-3bc72cc584d7",
  },
  {
    name: "Zhoushuizi",
    city: "Dalian",
    country: "China",
    iata_code: "DLC",
    _geoloc: {
      lat: 38.965667,
      lng: 121.5386,
    },
    links_count: 285,
    objectID: "3404",
    uniqueID: "0bc1a1bb-4df6-4009-a565-ab94b4ab3490",
  },
  {
    name: "Salt Lake City Intl",
    city: "Salt Lake City",
    country: "United States",
    iata_code: "SLC",
    _geoloc: {
      lat: 40.788389,
      lng: -111.977772,
    },
    links_count: 278,
    objectID: "3536",
    uniqueID: "d43ac233-b9c7-43f7-872b-be580d6be091",
  },
  {
    name: "Tampa Intl",
    city: "Tampa",
    country: "United States",
    iata_code: "TPA",
    _geoloc: {
      lat: 27.975472,
      lng: -82.53325,
    },
    links_count: 276,
    objectID: "3646",
    uniqueID: "500614b4-4c3f-498e-9055-9b2b670627c8",
  },
  {
    name: "King Khaled Intl",
    city: "Riyadh",
    country: "Saudi Arabia",
    iata_code: "RUH",
    _geoloc: {
      lat: 24.95764,
      lng: 46.698776,
    },
    links_count: 276,
    objectID: "2082",
    uniqueID: "0b10e7c7-437f-40d0-b130-24bb835d6b78",
  },
  {
    name: "Saint Exupery",
    city: "Lyon",
    country: "France",
    iata_code: "LYS",
    _geoloc: {
      lat: 45.726387,
      lng: 5.090833,
    },
    links_count: 275,
    objectID: "1335",
    uniqueID: "f4541950-7bee-4af4-b82c-2485471943dc",
  },
  {
    name: "Longdongbao",
    city: "Guiyang",
    country: "China",
    iata_code: "KWE",
    _geoloc: {
      lat: 26.538522,
      lng: 106.800703,
    },
    links_count: 274,
    objectID: "3394",
    uniqueID: "fdd7168f-bd78-44e4-896b-ceedf47a02dc",
  },
  {
    name: "Stuttgart",
    city: "Stuttgart",
    country: "Germany",
    iata_code: "STR",
    _geoloc: {
      lat: 48.689878,
      lng: 9.221964,
    },
    links_count: 273,
    objectID: "350",
    uniqueID: "ac956f49-2ccb-4100-9e19-72a02b2a4a27",
  },
  {
    name: "Shenyang Taoxian International Airport",
    city: "Shenyang",
    country: "China",
    iata_code: "SHE",
    _geoloc: {
      lat: 41.3824,
      lng: 123.2901,
    },
    links_count: 272,
    objectID: "4144",
    uniqueID: "805a86e6-e410-480c-bc44-62693485b8c8",
  },
  {
    name: "Chicago Midway Intl",
    city: "Chicago",
    country: "United States",
    iata_code: "MDW",
    _geoloc: {
      lat: 41.785972,
      lng: -87.752417,
    },
    links_count: 271,
    objectID: "3747",
    uniqueID: "ed150e80-b3e2-4bcf-9a42-bac138ca74b7",
  },
  {
    name: "Ben Gurion",
    city: "Tel-aviv",
    country: "Israel",
    iata_code: "TLV",
    _geoloc: {
      lat: 32.011389,
      lng: 34.886667,
    },
    links_count: 271,
    objectID: "1590",
    uniqueID: "02e93778-e2f2-4b17-8fb5-fc0b0831eeb9",
  },
  {
    name: "Jinan",
    city: "Jinan",
    country: "China",
    iata_code: "TNA",
    _geoloc: {
      lat: 36.857214,
      lng: 117.215992,
    },
    links_count: 270,
    objectID: "4108",
    uniqueID: "d4f34e73-9bc0-4d04-99a0-f68e86e93391",
  },
  {
    name: "Melbourne Intl",
    city: "Melbourne",
    country: "Australia",
    iata_code: "MEL",
    _geoloc: {
      lat: -37.673333,
      lng: 144.843333,
    },
    links_count: 269,
    objectID: "3339",
    uniqueID: "f1e7051e-c104-4152-bfa1-0782c5799738",
  },
  {
    name: "Cote D\\\\'Azur",
    city: "Nice",
    country: "France",
    iata_code: "NCE",
    _geoloc: {
      lat: 43.658411,
      lng: 7.215872,
    },
    links_count: 269,
    objectID: "1354",
    uniqueID: "200383a1-237b-488f-81cb-97516daebe04",
  },
  {
    name: "Changle",
    city: "Fuzhou",
    country: "China",
    iata_code: "FOC",
    _geoloc: {
      lat: 25.935064,
      lng: 119.663272,
    },
    links_count: 266,
    objectID: "3385",
    uniqueID: "32b1c87c-2d05-441b-bb86-38695626e3fa",
  },
  {
    name: "Koln Bonn",
    city: "Cologne",
    country: "Germany",
    iata_code: "CGN",
    _geoloc: {
      lat: 50.865917,
      lng: 7.142744,
    },
    links_count: 265,
    objectID: "344",
    uniqueID: "4923ed59-70e2-4883-bcc8-5294c1b75634",
  },
  {
    name: "Provence",
    city: "Marseille",
    country: "France",
    iata_code: "MRS",
    _geoloc: {
      lat: 43.435555,
      lng: 5.213611,
    },
    links_count: 265,
    objectID: "1353",
    uniqueID: "ba65b4ca-ab3d-4147-89a9-00d512ff01d4",
  },
  {
    name: "Birmingham",
    city: "Birmingham",
    country: "United Kingdom",
    iata_code: "BHX",
    _geoloc: {
      lat: 52.453856,
      lng: -1.748028,
    },
    links_count: 264,
    objectID: "469",
    uniqueID: "7ac7dc8b-b1e5-41a8-b4a2-a77fe6d08635",
  },
  {
    name: "Taiping",
    city: "Harbin",
    country: "China",
    iata_code: "HRB",
    _geoloc: {
      lat: 45.623403,
      lng: 126.250328,
    },
    links_count: 259,
    objectID: "3400",
    uniqueID: "be2184ea-4f8c-414f-9ba0-d41516798b79",
  },
  {
    name: "Jomo Kenyatta International",
    city: "Nairobi",
    country: "Kenya",
    iata_code: "NBO",
    _geoloc: {
      lat: -1.319167,
      lng: 36.9275,
    },
    links_count: 258,
    objectID: "4059",
    uniqueID: "14bac414-a595-4a40-9fce-f10ba9a8e64e",
  },
  {
    name: "Antalya",
    city: "Antalya",
    country: "Turkey",
    iata_code: "AYT",
    _geoloc: {
      lat: 36.898731,
      lng: 30.800461,
    },
    links_count: 253,
    objectID: "1688",
    uniqueID: "8e2f26fb-93fa-4c28-8f36-2242d29dcba1",
  },
  {
    name: "Mohammed V Intl",
    city: "Casablanca",
    country: "Morocco",
    iata_code: "CMN",
    _geoloc: {
      lat: 33.367467,
      lng: -7.589967,
    },
    links_count: 250,
    objectID: "1074",
    uniqueID: "1ee9a57a-a50a-4006-a269-450a6b6589a7",
  },
  {
    name: "Louis Armstrong New Orleans Intl",
    city: "New Orleans",
    country: "United States",
    iata_code: "MSY",
    _geoloc: {
      lat: 29.993389,
      lng: -90.258028,
    },
    links_count: 248,
    objectID: "3861",
    uniqueID: "a5f2b076-c0f3-457e-b286-ae02245ff1b2",
  },
  {
    name: "Venezia Tessera",
    city: "Venice",
    country: "Italy",
    iata_code: "VCE",
    _geoloc: {
      lat: 45.505278,
      lng: 12.351944,
    },
    links_count: 245,
    objectID: "1551",
    uniqueID: "aeb5ad3b-afe2-4934-97f7-7bb9335628e8",
  },
  {
    name: "Okecie",
    city: "Warsaw",
    country: "Poland",
    iata_code: "WAW",
    _geoloc: {
      lat: 52.16575,
      lng: 20.967122,
    },
    links_count: 240,
    objectID: "679",
    uniqueID: "85caff0c-03cb-4d6b-880d-44dab8d07e14",
  },
  {
    name: "Kuwait Intl",
    city: "Kuwait",
    country: "Kuwait",
    iata_code: "KWI",
    _geoloc: {
      lat: 29.226567,
      lng: 47.968928,
    },
    links_count: 239,
    objectID: "2176",
    uniqueID: "23c7cc5e-4e54-457d-a02a-d1e3ec6164bd",
  },
  {
    name: "San Diego Intl",
    city: "San Diego",
    country: "United States",
    iata_code: "SAN",
    _geoloc: {
      lat: 32.733556,
      lng: -117.189667,
    },
    links_count: 238,
    objectID: "3731",
    uniqueID: "51d7d0ed-d388-4a35-998b-20134fe968b1",
  },
  {
    name: "Edinburgh",
    city: "Edinburgh",
    country: "United Kingdom",
    iata_code: "EDI",
    _geoloc: {
      lat: 55.95,
      lng: -3.3725,
    },
    links_count: 237,
    objectID: "535",
    uniqueID: "306ced28-1399-404e-bffa-7109d79283aa",
  },
  {
    name: "Honolulu Intl",
    city: "Honolulu",
    country: "United States",
    iata_code: "HNL",
    _geoloc: {
      lat: 21.318681,
      lng: -157.922428,
    },
    links_count: 233,
    objectID: "3728",
    uniqueID: "5f5809e3-f9ce-480f-9a27-6987c284d5db",
  },
  {
    name: "Catania Fontanarossa",
    city: "Catania",
    country: "Italy",
    iata_code: "CTA",
    _geoloc: {
      lat: 37.466781,
      lng: 15.0664,
    },
    links_count: 233,
    objectID: "1509",
    uniqueID: "b787bf9d-c090-4689-8c03-d0202484799d",
  },
  {
    name: "Lanzarote",
    city: "Las Palmas",
    country: "Spain",
    iata_code: "ACE",
    _geoloc: {
      lat: 28.945464,
      lng: -13.605225,
    },
    links_count: 233,
    objectID: "1055",
    uniqueID: "8b75c58b-2743-48b5-91de-43887c2faffb",
  },
  {
    name: "Portland Intl",
    city: "Portland",
    country: "United States",
    iata_code: "PDX",
    _geoloc: {
      lat: 45.588722,
      lng: -122.5975,
    },
    links_count: 230,
    objectID: "3720",
    uniqueID: "a226810d-e638-42fc-bdaa-c76aa7e1f57b",
  },
  {
    name: "Jorge Chavez Intl",
    city: "Lima",
    country: "Peru",
    iata_code: "LIM",
    _geoloc: {
      lat: -12.021889,
      lng: -77.114319,
    },
    links_count: 230,
    objectID: "2789",
    uniqueID: "28ba5dee-3fed-45c0-b57c-8f78893137e8",
  },
  {
    name: "Wuxu",
    city: "Nanning",
    country: "China",
    iata_code: "NNG",
    _geoloc: {
      lat: 22.608267,
      lng: 108.172442,
    },
    links_count: 228,
    objectID: "3373",
    uniqueID: "37d2ee44-3217-43ac-b3ed-704ee60cb527",
  },
  {
    name: "Calgary Intl",
    city: "Calgary",
    country: "Canada",
    iata_code: "YYC",
    _geoloc: {
      lat: 51.113888,
      lng: -114.020278,
    },
    links_count: 228,
    objectID: "178",
    uniqueID: "3dfa7b24-dbfe-490a-8f49-927c8844d1f2",
  },
  {
    name: "Henri Coanda",
    city: "Bucharest",
    country: "Romania",
    iata_code: "OTP",
    _geoloc: {
      lat: 44.572161,
      lng: 26.102178,
    },
    links_count: 227,
    objectID: "1657",
    uniqueID: "dd240ccc-140d-44c7-aeda-a4cf667124ff",
  },
  {
    name: "Luqa",
    city: "Malta",
    country: "Malta",
    iata_code: "MLA",
    _geoloc: {
      lat: 35.857497,
      lng: 14.4775,
    },
    links_count: 226,
    objectID: "1606",
    uniqueID: "406cd981-028f-4818-b85f-c170bc1b9da0",
  },
  {
    name: "Queen Alia Intl",
    city: "Amman",
    country: "Jordan",
    iata_code: "AMM",
    _geoloc: {
      lat: 31.722556,
      lng: 35.993214,
    },
    links_count: 223,
    objectID: "2170",
    uniqueID: "a71e2d81-87d7-467d-8437-d20a6e8789a5",
  },
  {
    name: "Lambert St Louis Intl",
    city: "St. Louis",
    country: "United States",
    iata_code: "STL",
    _geoloc: {
      lat: 38.748697,
      lng: -90.370028,
    },
    links_count: 221,
    objectID: "3678",
    uniqueID: "8323f3fd-92e1-4201-9415-8dc8891f7fc3",
  },
  {
    name: "Wusu",
    city: "Taiyuan",
    country: "China",
    iata_code: "TYN",
    _geoloc: {
      lat: 37.746897,
      lng: 112.628428,
    },
    links_count: 220,
    objectID: "3369",
    uniqueID: "08f7ae97-592d-4a87-8be3-80c73c76fdde",
  },
  {
    name: "Nikos Kazantzakis",
    city: "Heraklion",
    country: "Greece",
    iata_code: "HER",
    _geoloc: {
      lat: 35.339719,
      lng: 25.180297,
    },
    links_count: 219,
    objectID: "1452",
    uniqueID: "0cb3b830-ba19-482b-8758-d311919af17b",
  },
  {
    name: "Phoenix International",
    city: "Sanya",
    country: "China",
    iata_code: "SYX",
    _geoloc: {
      lat: 18.302897,
      lng: 109.412272,
    },
    links_count: 218,
    objectID: "4030",
    uniqueID: "bd0f3994-fb9e-4e8b-9d29-661ae55379b1",
  },
  {
    name: "Tansonnhat Intl",
    city: "Ho Chi Minh City",
    country: "Vietnam",
    iata_code: "SGN",
    _geoloc: {
      lat: 10.818797,
      lng: 106.651856,
    },
    links_count: 218,
    objectID: "3205",
    uniqueID: "3324faf0-218c-4d81-a16e-3a88a6a5a0b8",
  },
  {
    name: "Meilan",
    city: "Haikou",
    country: "China",
    iata_code: "HAK",
    _geoloc: {
      lat: 19.934856,
      lng: 110.458961,
    },
    links_count: 215,
    objectID: "4120",
    uniqueID: "c9bd4922-3279-44b1-9329-7b3e29f8b2b0",
  },
  {
    name: "Luton",
    city: "London",
    country: "United Kingdom",
    iata_code: "LTN",
    _geoloc: {
      lat: 51.874722,
      lng: -0.368333,
    },
    links_count: 214,
    objectID: "492",
    uniqueID: "eb23eae3-c467-475a-bc79-c86396efda13",
  },
  {
    name: "Liangjiang",
    city: "Guilin",
    country: "China",
    iata_code: "KWL",
    _geoloc: {
      lat: 25.218106,
      lng: 110.039197,
    },
    links_count: 214,
    objectID: "3372",
    uniqueID: "20043e32-3946-4205-977f-e3fac3bdffc3",
  },
  {
    name: "Bole Intl",
    city: "Addis Ababa",
    country: "Ethiopia",
    iata_code: "ADD",
    _geoloc: {
      lat: 8.977889,
      lng: 38.799319,
    },
    links_count: 214,
    objectID: "1107",
    uniqueID: "3db418a0-5823-4597-8445-93c83f5b44f2",
  },
  {
    name: "Auckland Intl",
    city: "Auckland",
    country: "New Zealand",
    iata_code: "AKL",
    _geoloc: {
      lat: -37.008056,
      lng: 174.791667,
    },
    links_count: 213,
    objectID: "2006",
    uniqueID: "d443f517-e9f9-419b-b2b6-01abffb74430",
  },
  {
    name: "Tocumen Intl",
    city: "Panama City",
    country: "Panama",
    iata_code: "PTY",
    _geoloc: {
      lat: 9.071364,
      lng: -79.383453,
    },
    links_count: 212,
    objectID: "1871",
    uniqueID: "8c2fd729-7197-4f54-a5e8-e0a14e68db54",
  },
  {
    name: "Chennai Intl",
    city: "Madras",
    country: "India",
    iata_code: "MAA",
    _geoloc: {
      lat: 12.994414,
      lng: 80.180517,
    },
    links_count: 211,
    objectID: "3144",
    uniqueID: "03440ca7-eb6e-475b-8848-60d648ca7d3d",
  },
  {
    name: "Faro",
    city: "Faro",
    country: "Portugal",
    iata_code: "FAO",
    _geoloc: {
      lat: 37.014425,
      lng: -7.965911,
    },
    links_count: 211,
    objectID: "1626",
    uniqueID: "a39d7b3f-745b-4349-9d62-bb7e4394496a",
  },
  {
    name: "Seeb Intl",
    city: "Muscat",
    country: "Oman",
    iata_code: "MCT",
    _geoloc: {
      lat: 23.593278,
      lng: 58.284444,
    },
    links_count: 208,
    objectID: "2194",
    uniqueID: "1bf07fb6-53ba-4391-aace-3de7a16c7b69",
  },
  {
    name: "Luis Munoz Marin Intl",
    city: "San Juan",
    country: "Puerto Rico",
    iata_code: "SJU",
    _geoloc: {
      lat: 18.439417,
      lng: -66.001833,
    },
    links_count: 204,
    objectID: "2890",
    uniqueID: "334825a8-362f-4033-9a0d-2ab677b3fc69",
  },
  {
    name: "Bandaranaike Intl Colombo",
    city: "Colombo",
    country: "Sri Lanka",
    iata_code: "CMB",
    _geoloc: {
      lat: 7.180756,
      lng: 79.884117,
    },
    links_count: 203,
    objectID: "3024",
    uniqueID: "ae5774a7-c708-401e-b227-d46df6c7d0b2",
  },
  {
    name: "Riga Intl",
    city: "Riga",
    country: "Latvia",
    iata_code: "RIX",
    _geoloc: {
      lat: 56.923611,
      lng: 23.971111,
    },
    links_count: 202,
    objectID: "3953",
    uniqueID: "d81cb793-62bb-4b16-808e-f2dfca0da34e",
  },
  {
    name: "Boryspil Intl",
    city: "Kiev",
    country: "Ukraine",
    iata_code: "KBP",
    _geoloc: {
      lat: 50.345,
      lng: 30.894722,
    },
    links_count: 202,
    objectID: "2939",
    uniqueID: "4963049b-4e94-4df9-a440-b34e9f04e569",
  },
  {
    name: "Porto",
    city: "Porto",
    country: "Portugal",
    iata_code: "OPO",
    _geoloc: {
      lat: 41.248055,
      lng: -8.681389,
    },
    links_count: 202,
    objectID: "1636",
    uniqueID: "df042a9c-fb19-4124-a5be-fff74237b3e1",
  },
  {
    name: "Ibiza",
    city: "Ibiza",
    country: "Spain",
    iata_code: "IBZ",
    _geoloc: {
      lat: 38.872858,
      lng: 1.373117,
    },
    links_count: 202,
    objectID: "1225",
    uniqueID: "d2facca9-68c9-476b-8d7e-f77ee5f0a1d4",
  },
  {
    name: "Bali Ngurah Rai",
    city: "Denpasar",
    country: "Indonesia",
    iata_code: "DPS",
    _geoloc: {
      lat: -8.748169,
      lng: 115.167172,
    },
    links_count: 201,
    objectID: "3940",
    uniqueID: "220cede8-279d-4346-9591-e0b844ac0612",
  },
  {
    name: "Bristol",
    city: "Bristol",
    country: "United Kingdom",
    iata_code: "BRS",
    _geoloc: {
      lat: 51.382669,
      lng: -2.719089,
    },
    links_count: 200,
    objectID: "490",
    uniqueID: "51d74d9e-d483-4347-a9f1-07bc34004ba7",
  },
  {
    name: "Koltsovo",
    city: "Yekaterinburg",
    country: "Russia",
    iata_code: "SVX",
    _geoloc: {
      lat: 56.743108,
      lng: 60.802728,
    },
    links_count: 199,
    objectID: "2975",
    uniqueID: "831a4bca-a83d-43af-b1bc-5523fa74a725",
  },
  {
    name: "Presidente Juscelino Kubitschek",
    city: "Brasilia",
    country: "Brazil",
    iata_code: "BSB",
    _geoloc: {
      lat: -15.8711,
      lng: -47.918625,
    },
    links_count: 197,
    objectID: "2531",
    uniqueID: "32bedb69-1755-4568-a93f-e1061b54696a",
  },
  {
    name: "Glasgow",
    city: "Glasgow",
    country: "United Kingdom",
    iata_code: "GLA",
    _geoloc: {
      lat: 55.871944,
      lng: -4.433056,
    },
    links_count: 196,
    objectID: "534",
    uniqueID: "7b1082c7-e372-4b8b-aa7f-c4f117cc25ba",
  },
  {
    name: "Bangalore",
    city: "Bangalore",
    country: "India",
    iata_code: "BLR",
    _geoloc: {
      lat: 12.949986,
      lng: 77.668206,
    },
    links_count: 195,
    objectID: "3131",
    uniqueID: "93a48e41-541a-42a9-8ad3-83ec5098b8c4",
  },
  {
    name: "Sharjah Intl",
    city: "Sharjah",
    country: "United Arab Emirates",
    iata_code: "SHJ",
    _geoloc: {
      lat: 25.328575,
      lng: 55.51715,
    },
    links_count: 195,
    objectID: "2191",
    uniqueID: "b4d3c5de-a86f-49aa-b41b-fb4bb523ba15",
  },
  {
    name: "Ferihegy",
    city: "Budapest",
    country: "Hungary",
    iata_code: "BUD",
    _geoloc: {
      lat: 47.436933,
      lng: 19.255592,
    },
    links_count: 195,
    objectID: "1489",
    uniqueID: "502a38a2-681a-414c-a400-4677fd4e1eb7",
  },
  {
    name: "Netaji Subhash Chandra Bose Intl",
    city: "Kolkata",
    country: "India",
    iata_code: "CCU",
    _geoloc: {
      lat: 22.654739,
      lng: 88.446722,
    },
    links_count: 194,
    objectID: "3043",
    uniqueID: "06ee2c97-0a1c-45a8-865e-56808454929d",
  },
  {
    name: "Galeao Antonio Carlos Jobim",
    city: "Rio De Janeiro",
    country: "Brazil",
    iata_code: "GIG",
    _geoloc: {
      lat: -22.808903,
      lng: -43.243647,
    },
    links_count: 194,
    objectID: "2560",
    uniqueID: "f793d82e-b598-4f5c-876a-214b80309195",
  },
  {
    name: "Nottingham East Midlands",
    city: "East Midlands",
    country: "United Kingdom",
    iata_code: "EMA",
    _geoloc: {
      lat: 52.831111,
      lng: -1.328056,
    },
    links_count: 192,
    objectID: "523",
    uniqueID: "5fa93cd7-2568-407a-a17c-a840b7e8f4c1",
  },
  {
    name: "Fuerteventura",
    city: "Fuerteventura",
    country: "Spain",
    iata_code: "FUE",
    _geoloc: {
      lat: 28.452717,
      lng: -13.863761,
    },
    links_count: 192,
    objectID: "1051",
    uniqueID: "b3c9ad50-4423-4e7c-bef5-ef5a9e0b415e",
  },
  {
    name: "Larnaca",
    city: "Larnaca",
    country: "Cyprus",
    iata_code: "LCA",
    _geoloc: {
      lat: 34.875117,
      lng: 33.62485,
    },
    links_count: 190,
    objectID: "1197",
    uniqueID: "8b0ebb49-b45f-4f07-b376-20ccb01bcd4d",
  },
  {
    name: "EuroAirport Basel-Mulhouse-Freiburg",
    city: "Basel",
    country: "Switzerland",
    iata_code: "BSL",
    _geoloc: {
      lat: 47.59,
      lng: 7.529167,
    },
    links_count: 189,
    objectID: "4053",
    uniqueID: "e0d08a45-77b6-4584-89cb-c828883f9871",
  },
  {
    name: "Don Miguel Hidalgo Y Costilla Intl",
    city: "Guadalajara",
    country: "Mexico",
    iata_code: "GDL",
    _geoloc: {
      lat: 20.5218,
      lng: -103.311167,
    },
    links_count: 185,
    objectID: "1804",
    uniqueID: "f66d714b-9955-422b-aed8-a36e3e3c5f83",
  },
  {
    name: "Nantes Atlantique",
    city: "Nantes",
    country: "France",
    iata_code: "NTE",
    _geoloc: {
      lat: 47.153189,
      lng: -1.610725,
    },
    links_count: 183,
    objectID: "1418",
    uniqueID: "a866c53b-4ae9-486c-80d0-46831825ef3d",
  },
  {
    name: "Perth Intl",
    city: "Perth",
    country: "Australia",
    iata_code: "PER",
    _geoloc: {
      lat: -31.940278,
      lng: 115.966944,
    },
    links_count: 182,
    objectID: "3351",
    uniqueID: "baae4ee0-c207-4419-976b-cecf8a1ee78c",
  },
  {
    name: "Simon Bolivar Intl",
    city: "Caracas",
    country: "Venezuela",
    iata_code: "CCS",
    _geoloc: {
      lat: 10.603117,
      lng: -66.990583,
    },
    links_count: 181,
    objectID: "2851",
    uniqueID: "98b48662-cfcb-48ba-b875-09db524355b8",
  },
  {
    name: "Tolmachevo",
    city: "Novosibirsk",
    country: "Russia",
    iata_code: "OVB",
    _geoloc: {
      lat: 55.012622,
      lng: 82.650656,
    },
    links_count: 177,
    objectID: "4078",
    uniqueID: "2ce0750c-7cbc-43bb-bbd6-17ab7c0a62cf",
  },
  {
    name: "Houari Boumediene",
    city: "Algier",
    country: "Algeria",
    iata_code: "ALG",
    _geoloc: {
      lat: 36.691014,
      lng: 3.215408,
    },
    links_count: 176,
    objectID: "210",
    uniqueID: "974ae5f4-452d-4b3e-8401-eae3e501fbb1",
  },
  {
    name: "Bologna",
    city: "Bologna",
    country: "Italy",
    iata_code: "BLQ",
    _geoloc: {
      lat: 44.535444,
      lng: 11.288667,
    },
    links_count: 176,
    objectID: "1538",
    uniqueID: "237bb26a-8d42-4ce3-93ab-a34973668fe5",
  },
  {
    name: "Carthage",
    city: "Tunis",
    country: "Tunisia",
    iata_code: "TUN",
    _geoloc: {
      lat: 36.851033,
      lng: 10.227217,
    },
    links_count: 175,
    objectID: "287",
    uniqueID: "633b8013-f02f-471a-b94e-b78986b2ed28",
  },
  {
    name: "Baita Airport",
    city: "Hohhot",
    country: "China",
    iata_code: "HET",
    _geoloc: {
      lat: 40.851422,
      lng: 111.824103,
    },
    links_count: 174,
    objectID: "6345",
    uniqueID: "9671b879-b5f8-4011-9646-1581514244f7",
  },
  {
    name: "Noibai Intl",
    city: "Hanoi",
    country: "Vietnam",
    iata_code: "HAN",
    _geoloc: {
      lat: 21.221192,
      lng: 105.807178,
    },
    links_count: 174,
    objectID: "3199",
    uniqueID: "455b6916-f69b-4eef-954b-15a2415a4398",
  },
  {
    name: "Makedonia",
    city: "Thessaloniki",
    country: "Greece",
    iata_code: "SKG",
    _geoloc: {
      lat: 40.519725,
      lng: 22.97095,
    },
    links_count: 174,
    objectID: "1486",
    uniqueID: "fbdbc8cd-9c98-4e0b-be94-070574a5f197",
  },
  {
    name: "Changbei Intl",
    city: "Nanchang",
    country: "China",
    iata_code: "KHN",
    _geoloc: {
      lat: 28.865,
      lng: 115.9,
    },
    links_count: 172,
    objectID: "3384",
    uniqueID: "0ff6f4f6-a6a0-49be-ac00-58298734a80e",
  },
  {
    name: "Vnukovo",
    city: "Moscow",
    country: "Russia",
    iata_code: "VKO",
    _geoloc: {
      lat: 55.591531,
      lng: 37.261486,
    },
    links_count: 171,
    objectID: "2988",
    uniqueID: "6829c19f-6745-487a-991c-369acf59bc6a",
  },
  {
    name: "Beograd",
    city: "Belgrade",
    country: "Serbia",
    iata_code: "BEG",
    _geoloc: {
      lat: 44.818444,
      lng: 20.309139,
    },
    links_count: 171,
    objectID: "1739",
    uniqueID: "cb40e53c-dd26-466b-be68-043aeb40ed9b",
  },
  {
    name: "Fukuoka",
    city: "Fukuoka",
    country: "Japan",
    iata_code: "FUK",
    _geoloc: {
      lat: 33.585942,
      lng: 130.450686,
    },
    links_count: 170,
    objectID: "2305",
    uniqueID: "b94b199e-4780-4b73-a4a4-a244a2f92b91",
  },
  {
    name: "Bahrain Intl",
    city: "Bahrain",
    country: "Bahrain",
    iata_code: "BAH",
    _geoloc: {
      lat: 26.270834,
      lng: 50.63361,
    },
    links_count: 170,
    objectID: "2057",
    uniqueID: "d43bfa98-998a-4b9f-ad1b-37ed46658084",
  },
  {
    name: "Chubu Centrair Intl",
    city: "Nagoya",
    country: "Japan",
    iata_code: "NGO",
    _geoloc: {
      lat: 34.858414,
      lng: 136.805408,
    },
    links_count: 169,
    objectID: "3942",
    uniqueID: "17605d48-5db7-4a64-80c4-37652277eb16",
  },
  {
    name: "Lanzhou Airport",
    city: "Lanzhou",
    country: "China",
    iata_code: "LHW",
    _geoloc: {
      lat: 36.117,
      lng: 103.617,
    },
    links_count: 168,
    objectID: "6431",
    uniqueID: "b27dc77b-d966-491f-a189-95af395a3d63",
  },
  {
    name: "Lishe",
    city: "Ninbo",
    country: "China",
    iata_code: "NGB",
    _geoloc: {
      lat: 29.826683,
      lng: 121.461906,
    },
    links_count: 168,
    objectID: "3387",
    uniqueID: "f858b7f2-99bd-4254-8f83-0bdf45cf58d2",
  },
  {
    name: "Brussels South",
    city: "Charleroi",
    country: "Belgium",
    iata_code: "CRL",
    _geoloc: {
      lat: 50.459197,
      lng: 4.453817,
    },
    links_count: 168,
    objectID: "304",
    uniqueID: "8f338e7c-9717-4878-a434-750bfa8bda3e",
  },
  {
    name: "Rhodes Diagoras",
    city: "Rhodos",
    country: "Greece",
    iata_code: "RHO",
    _geoloc: {
      lat: 36.405419,
      lng: 28.086192,
    },
    links_count: 168,
    objectID: "1472",
    uniqueID: "98075737-bbd6-4b67-94bc-bd933f5545a8",
  },
  {
    name: "Austin Bergstrom Intl",
    city: "Austin",
    country: "United States",
    iata_code: "AUS",
    _geoloc: {
      lat: 30.194528,
      lng: -97.669889,
    },
    links_count: 167,
    objectID: "3673",
    uniqueID: "0425e261-86c3-458f-8c92-0cc3f553ccbf",
  },
  {
    name: "Raleigh Durham Intl",
    city: "Raleigh-durham",
    country: "United States",
    iata_code: "RDU",
    _geoloc: {
      lat: 35.877639,
      lng: -78.787472,
    },
    links_count: 167,
    objectID: "3626",
    uniqueID: "e9ec85e4-d97c-4bd3-b543-cb77e86ce6d5",
  },
  {
    name: "Newcastle",
    city: "Newcastle",
    country: "United Kingdom",
    iata_code: "NCL",
    _geoloc: {
      lat: 55.0375,
      lng: -1.691667,
    },
    links_count: 165,
    objectID: "521",
    uniqueID: "472c4fee-3b61-441e-9ff1-ff20caa700ce",
  },
  {
    name: "Schonefeld",
    city: "Berlin",
    country: "Germany",
    iata_code: "SXF",
    _geoloc: {
      lat: 52.380001,
      lng: 13.5225,
    },
    links_count: 165,
    objectID: "337",
    uniqueID: "04b49e2e-c7ef-47ff-a002-5134e1cfa347",
  },
  {
    name: "Shijiazhuang Daguocun International Airport",
    city: "Shijiazhuang",
    country: "China",
    iata_code: "SJW",
    _geoloc: {
      lat: 38.280686,
      lng: 114.6973,
    },
    links_count: 164,
    objectID: "6347",
    uniqueID: "ec5a1f17-83d1-4130-8bc4-cfc21a6845ec",
  },
  {
    name: "Nashville Intl",
    city: "Nashville",
    country: "United States",
    iata_code: "BNA",
    _geoloc: {
      lat: 36.124472,
      lng: -86.678194,
    },
    links_count: 164,
    objectID: "3690",
    uniqueID: "28c8d1bf-1897-4063-8f7e-b6d1203debad",
  },
  {
    name: "King Fahd Intl",
    city: "Dammam",
    country: "Saudi Arabia",
    iata_code: "DMM",
    _geoloc: {
      lat: 26.471161,
      lng: 49.79789,
    },
    links_count: 164,
    objectID: "2064",
    uniqueID: "9ec79e69-9040-47fe-a6ba-4942cc07b98b",
  },
  {
    name: "Valencia",
    city: "Valencia",
    country: "Spain",
    iata_code: "VLC",
    _geoloc: {
      lat: 39.489314,
      lng: -0.481625,
    },
    links_count: 163,
    objectID: "1246",
    uniqueID: "d4365c4b-7361-4716-8c1e-4a6d41f2b2a4",
  },
  {
    name: "Wenzhou Yongqiang Airport",
    city: "Wenzhou",
    country: "China",
    iata_code: "WNZ",
    _geoloc: {
      lat: 27.9122,
      lng: 120.852,
    },
    links_count: 162,
    objectID: "6392",
    uniqueID: "e1801ec5-8a3c-439f-a372-74eb538c2d63",
  },
  {
    name: "New Chitose",
    city: "Sapporo",
    country: "Japan",
    iata_code: "CTS",
    _geoloc: {
      lat: 42.7752,
      lng: 141.692283,
    },
    links_count: 162,
    objectID: "2287",
    uniqueID: "7deab4b5-5a53-42f1-99f3-a9ff8c5737b1",
  },
  {
    name: "Bergamo Orio Al Serio",
    city: "Bergamo",
    country: "Italy",
    iata_code: "BGY",
    _geoloc: {
      lat: 45.673889,
      lng: 9.704166,
    },
    links_count: 162,
    objectID: "1525",
    uniqueID: "fcae5ff7-9838-430b-bd15-4fd0b58a0b75",
  },
  {
    name: "Cleveland Hopkins Intl",
    city: "Cleveland",
    country: "United States",
    iata_code: "CLE",
    _geoloc: {
      lat: 41.411689,
      lng: -81.849794,
    },
    links_count: 161,
    objectID: "3486",
    uniqueID: "834abfd3-4beb-4ccf-ada9-e71a9a613ba9",
  },
  {
    name: "Blagnac",
    city: "Toulouse",
    country: "France",
    iata_code: "TLS",
    _geoloc: {
      lat: 43.629075,
      lng: 1.363819,
    },
    links_count: 161,
    objectID: "1273",
    uniqueID: "1e620533-fab7-47e8-b787-1f1ab3a50df9",
  },
  {
    name: "Beijing Nanyuan Airport",
    city: "Beijing",
    country: "China",
    iata_code: "NAY",
    _geoloc: {
      lat: 39.7825,
      lng: 116.387778,
    },
    links_count: 160,
    objectID: "6341",
    uniqueID: "90ddb595-f878-4438-bb31-172d9c2f6551",
  },
  {
    name: "Leeds Bradford",
    city: "Leeds",
    country: "United Kingdom",
    iata_code: "LBA",
    _geoloc: {
      lat: 53.865897,
      lng: -1.660569,
    },
    links_count: 160,
    objectID: "517",
    uniqueID: "bf054eb1-9ff6-42a1-8df8-87ac7a5eeae5",
  },
  {
    name: "Yuzhny",
    city: "Tashkent",
    country: "Uzbekistan",
    iata_code: "TAS",
    _geoloc: {
      lat: 41.257861,
      lng: 69.281186,
    },
    links_count: 160,
    objectID: "2983",
    uniqueID: "51632c2c-7ba3-4d95-9514-3a4da079d127",
  },
  {
    name: "Gimhae Intl",
    city: "Busan",
    country: "South Korea",
    iata_code: "PUS",
    _geoloc: {
      lat: 35.179528,
      lng: 128.938222,
    },
    links_count: 160,
    objectID: "2372",
    uniqueID: "d7a4c9df-b466-468d-8378-57602b07bfeb",
  },
  {
    name: "Kansas City Intl",
    city: "Kansas City",
    country: "United States",
    iata_code: "MCI",
    _geoloc: {
      lat: 39.297606,
      lng: -94.713905,
    },
    links_count: 159,
    objectID: "3458",
    uniqueID: "2a8b7548-0979-4e86-b205-91ee9cfc3570",
  },
  {
    name: "Macau Intl",
    city: "Macau",
    country: "Macau",
    iata_code: "MFM",
    _geoloc: {
      lat: 22.149556,
      lng: 113.591558,
    },
    links_count: 158,
    objectID: "3121",
    uniqueID: "dd304dfb-8f8c-45ed-ad85-06efeb61ca57",
  },
  {
    name: "Arturo Merino Benitez Intl",
    city: "Santiago",
    country: "Chile",
    iata_code: "SCL",
    _geoloc: {
      lat: -33.392975,
      lng: -70.785803,
    },
    links_count: 158,
    objectID: "2650",
    uniqueID: "d6179e72-5c74-41d4-bbd9-08a4517e6642",
  },
  {
    name: "Capodichino",
    city: "Naples",
    country: "Italy",
    iata_code: "NAP",
    _geoloc: {
      lat: 40.886033,
      lng: 14.290781,
    },
    links_count: 158,
    objectID: "1561",
    uniqueID: "b7d373ea-b023-4dfc-931e-b71d41a26388",
  },
  {
    name: "Luogang",
    city: "Hefei",
    country: "China",
    iata_code: "HFE",
    _geoloc: {
      lat: 31.780019,
      lng: 117.298436,
    },
    links_count: 154,
    objectID: "3389",
    uniqueID: "fc1b9e38-8244-4d82-bad5-d0d367a87a25",
  },
  {
    name: "Hannover",
    city: "Hannover",
    country: "Germany",
    iata_code: "HAJ",
    _geoloc: {
      lat: 52.461056,
      lng: 9.685078,
    },
    links_count: 152,
    objectID: "352",
    uniqueID: "f84b3226-90a0-4345-9b0a-f87442b070a8",
  },
  {
    name: "Pisa",
    city: "Pisa",
    country: "Italy",
    iata_code: "PSA",
    _geoloc: {
      lat: 43.683917,
      lng: 10.39275,
    },
    links_count: 152,
    objectID: "1562",
    uniqueID: "edb36ad8-711c-4573-b8f3-12fdfd6fd309",
  },
  {
    name: "Cincinnati Northern Kentucky Intl",
    city: "Cincinnati",
    country: "United States",
    iata_code: "CVG",
    _geoloc: {
      lat: 39.048836,
      lng: -84.667822,
    },
    links_count: 150,
    objectID: "3488",
    uniqueID: "a4cf3980-da70-4251-aab1-ae59341d6609",
  },
  {
    name: "Ministro Pistarini",
    city: "Buenos Aires",
    country: "Argentina",
    iata_code: "EZE",
    _geoloc: {
      lat: -34.822222,
      lng: -58.535833,
    },
    links_count: 149,
    objectID: "3988",
    uniqueID: "b6317bc9-9e30-4ac8-888e-775496443d63",
  },
  {
    name: "William P Hobby",
    city: "Houston",
    country: "United States",
    iata_code: "HOU",
    _geoloc: {
      lat: 29.645419,
      lng: -95.278889,
    },
    links_count: 149,
    objectID: "3566",
    uniqueID: "76b8fbe0-ab67-40ce-8626-ed272040a7b9",
  },
  {
    name: "Juan Santamaria Intl",
    city: "San Jose",
    country: "Costa Rica",
    iata_code: "SJO",
    _geoloc: {
      lat: 9.993861,
      lng: -84.208806,
    },
    links_count: 149,
    objectID: "1885",
    uniqueID: "116d394b-b6ca-4bd7-bb18-e6bd44045e13",
  },
  {
    name: "Pittsburgh Intl",
    city: "Pittsburgh",
    country: "United States",
    iata_code: "PIT",
    _geoloc: {
      lat: 40.491467,
      lng: -80.232872,
    },
    links_count: 148,
    objectID: "3570",
    uniqueID: "8c9bcee1-32f3-470f-80fe-e11056117114",
  },
  {
    name: "Zia Intl",
    city: "Dhaka",
    country: "Bangladesh",
    iata_code: "DAC",
    _geoloc: {
      lat: 23.843333,
      lng: 90.397781,
    },
    links_count: 148,
    objectID: "3076",
    uniqueID: "a201773d-23aa-4f3d-bf40-eac4831bc0e0",
  },
  {
    name: "Hyderabad",
    city: "Hyderabad",
    country: "India",
    iata_code: "HYD",
    _geoloc: {
      lat: 17.453117,
      lng: 78.467586,
    },
    links_count: 147,
    objectID: "3141",
    uniqueID: "ced08a2f-e248-4d96-8daf-dc327218ac25",
  },
  {
    name: "General Mariano Escobedo Intl",
    city: "Monterrey",
    country: "Mexico",
    iata_code: "MTY",
    _geoloc: {
      lat: 25.778489,
      lng: -100.106878,
    },
    links_count: 145,
    objectID: "1825",
    uniqueID: "89169991-5354-41cc-be99-f615a4c5270c",
  },
  {
    name: "Menara",
    city: "Marrakech",
    country: "Morocco",
    iata_code: "RAK",
    _geoloc: {
      lat: 31.606886,
      lng: -8.0363,
    },
    links_count: 145,
    objectID: "1075",
    uniqueID: "69c700af-13dc-4899-8205-a81ce26ac4a4",
  },
  {
    name: "Punta Cana Intl",
    city: "Punta Cana",
    country: "Dominican Republic",
    iata_code: "PUJ",
    _geoloc: {
      lat: 18.567367,
      lng: -68.363431,
    },
    links_count: 143,
    objectID: "1760",
    uniqueID: "569c9453-5517-48e2-9915-e755f481efd1",
  },
  {
    name: "Luxembourg",
    city: "Luxemburg",
    country: "Luxembourg",
    iata_code: "LUX",
    _geoloc: {
      lat: 49.626575,
      lng: 6.211517,
    },
    links_count: 140,
    objectID: "629",
    uniqueID: "74fc1043-c71f-4c5b-ba70-0e4b3ff156c8",
  },
  {
    name: "Edmonton Intl",
    city: "Edmonton",
    country: "Canada",
    iata_code: "YEG",
    _geoloc: {
      lat: 53.309723,
      lng: -113.579722,
    },
    links_count: 139,
    objectID: "49",
    uniqueID: "db0e2d97-229a-4a07-9926-bb596635290e",
  },
  {
    name: "Indianapolis Intl",
    city: "Indianapolis",
    country: "United States",
    iata_code: "IND",
    _geoloc: {
      lat: 39.717331,
      lng: -86.294383,
    },
    links_count: 138,
    objectID: "3585",
    uniqueID: "a6e3e1f7-2228-43e0-8d86-31378c8fd1c3",
  },
  {
    name: "Phuket Intl",
    city: "Phuket",
    country: "Thailand",
    iata_code: "HKT",
    _geoloc: {
      lat: 8.1132,
      lng: 98.316872,
    },
    links_count: 138,
    objectID: "3179",
    uniqueID: "5d9dcd54-d14b-447f-a4de-33c6583ddd62",
  },
  {
    name: "Flesland",
    city: "Bergen",
    country: "Norway",
    iata_code: "BGO",
    _geoloc: {
      lat: 60.293386,
      lng: 5.218142,
    },
    links_count: 137,
    objectID: "636",
    uniqueID: "e231401c-4de5-4a61-bc3a-697ed5a9597e",
  },
  {
    name: "San Antonio Intl",
    city: "San Antonio",
    country: "United States",
    iata_code: "SAT",
    _geoloc: {
      lat: 29.533694,
      lng: -98.469778,
    },
    links_count: 136,
    objectID: "3621",
    uniqueID: "1dbfe7f2-b39c-4383-8d26-825404d2216b",
  },
  {
    name: "Eindhoven",
    city: "Eindhoven",
    country: "Netherlands",
    iata_code: "EIN",
    _geoloc: {
      lat: 51.450139,
      lng: 5.374528,
    },
    links_count: 135,
    objectID: "585",
    uniqueID: "58ee4fcb-3c3d-4ede-a859-d15dc2a434cb",
  },
  {
    name: "Lynden Pindling Intl",
    city: "Nassau",
    country: "Bahamas",
    iata_code: "NAS",
    _geoloc: {
      lat: 25.038958,
      lng: -77.466231,
    },
    links_count: 135,
    objectID: "1953",
    uniqueID: "15d53bb2-cbde-4f5d-b194-3c167ad94ddb",
  },
  {
    name: "Don Muang Intl",
    city: "Bangkok",
    country: "Thailand",
    iata_code: "DMK",
    _geoloc: {
      lat: 13.912583,
      lng: 100.60675,
    },
    links_count: 134,
    objectID: "3157",
    uniqueID: "4df03726-5597-43db-96fd-427c62f0f0c4",
  },
  {
    name: "Almaty",
    city: "Alma-ata",
    country: "Kazakhstan",
    iata_code: "ALA",
    _geoloc: {
      lat: 43.352072,
      lng: 77.040508,
    },
    links_count: 134,
    objectID: "2908",
    uniqueID: "319ffb82-c811-467c-a228-af250f071564",
  },
  {
    name: "Merignac",
    city: "Bordeaux",
    country: "France",
    iata_code: "BOD",
    _geoloc: {
      lat: 44.828335,
      lng: -0.715556,
    },
    links_count: 134,
    objectID: "1264",
    uniqueID: "761fed62-5e1d-4ff0-a629-4a572a9360c5",
  },
  {
    name: "Leopold Sedar Senghor Intl",
    city: "Dakar",
    country: "Senegal",
    iata_code: "DKR",
    _geoloc: {
      lat: 14.739708,
      lng: -17.490225,
    },
    links_count: 134,
    objectID: "1084",
    uniqueID: "280b80f0-7474-4453-a807-28ae5009bfe2",
  },
  {
    name: "Rafic Hariri Intl",
    city: "Beirut",
    country: "Lebanon",
    iata_code: "BEY",
    _geoloc: {
      lat: 33.820931,
      lng: 35.488389,
    },
    links_count: 133,
    objectID: "2177",
    uniqueID: "c9da29bc-ba01-4cea-aec0-9cc72fda0335",
  },
  {
    name: "Adnan Menderes",
    city: "Izmir",
    country: "Turkey",
    iata_code: "ADB",
    _geoloc: {
      lat: 38.292392,
      lng: 27.156953,
    },
    links_count: 133,
    objectID: "1706",
    uniqueID: "232d2c8f-c726-44cf-b6b8-48b0c73273fa",
  },
  {
    name: "Sofia",
    city: "Sofia",
    country: "Bulgaria",
    iata_code: "SOF",
    _geoloc: {
      lat: 42.695194,
      lng: 23.406167,
    },
    links_count: 132,
    objectID: "1194",
    uniqueID: "81d687e6-f546-4a6c-884e-09dfa2e5bb6a",
  },
  {
    name: "Changchun",
    city: "Changchun",
    country: "China",
    iata_code: "CGQ",
    _geoloc: {
      lat: 43.5412,
      lng: 125.1201,
    },
    links_count: 131,
    objectID: "4380",
    uniqueID: "4c3198e0-92fc-4065-9c5c-d1e8d1f4443a",
  },
  {
    name: "Yinchuan",
    city: "Yinchuan",
    country: "China",
    iata_code: "INC",
    _geoloc: {
      lat: 38.481944,
      lng: 106.009167,
    },
    links_count: 131,
    objectID: "4085",
    uniqueID: "424c1dce-0899-4e47-92cb-9dead4446f98",
  },
  {
    name: "Juanda",
    city: "Surabaya",
    country: "Indonesia",
    iata_code: "SUB",
    _geoloc: {
      lat: -7.379831,
      lng: 112.786858,
    },
    links_count: 131,
    objectID: "3928",
    uniqueID: "8c89d3ea-5394-48e5-a423-5ef745ee0699",
  },
  {
    name: "Lijiang Airport",
    city: "Lijiang",
    country: "China",
    iata_code: "LJG",
    _geoloc: {
      lat: 26.883333,
      lng: 100.23333,
    },
    links_count: 129,
    objectID: "4033",
    uniqueID: "20f151a5-4934-4049-b544-0a9aa7701762",
  },
  {
    name: "Kaohsiung Intl",
    city: "Kaohsiung",
    country: "Taiwan",
    iata_code: "KHH",
    _geoloc: {
      lat: 22.577094,
      lng: 120.350006,
    },
    links_count: 129,
    objectID: "2264",
    uniqueID: "372c2a88-fb1d-4eee-952b-1baf54c7bc3b",
  },
  {
    name: "City",
    city: "London",
    country: "United Kingdom",
    iata_code: "LCY",
    _geoloc: {
      lat: 51.505278,
      lng: 0.055278,
    },
    links_count: 128,
    objectID: "503",
    uniqueID: "f6251fbd-8c8c-4d88-886d-1a63053cfee8",
  },
  {
    name: "Mashhad",
    city: "Mashhad",
    country: "Iran",
    iata_code: "MHD",
    _geoloc: {
      lat: 36.234,
      lng: 59.643,
    },
    links_count: 128,
    objectID: "4331",
    uniqueID: "e35b60e3-0c5b-4a7e-a68f-e02b0fde7a61",
  },
  {
    name: "Southwest Florida Intl",
    city: "Fort Myers",
    country: "United States",
    iata_code: "RSW",
    _geoloc: {
      lat: 26.536167,
      lng: -81.755167,
    },
    links_count: 128,
    objectID: "3793",
    uniqueID: "212fe93b-8add-432b-bb24-585dc7541e24",
  },
  {
    name: "Port Columbus Intl",
    city: "Columbus",
    country: "United States",
    iata_code: "CMH",
    _geoloc: {
      lat: 39.997972,
      lng: -82.891889,
    },
    links_count: 128,
    objectID: "3759",
    uniqueID: "791a7ebd-ec04-49a4-b7f9-06872a7e4ef2",
  },
  {
    name: "Heydar Aliyev",
    city: "Baku",
    country: "Azerbaijan",
    iata_code: "GYD",
    _geoloc: {
      lat: 40.4675,
      lng: 50.046667,
    },
    links_count: 128,
    objectID: "2922",
    uniqueID: "9ab3aa57-19b5-4348-956b-0b3451c9f99f",
  },
  {
    name: "Aeroparque Jorge Newbery",
    city: "Buenos Aires",
    country: "Argentina",
    iata_code: "AEP",
    _geoloc: {
      lat: -34.559175,
      lng: -58.415606,
    },
    links_count: 128,
    objectID: "2442",
    uniqueID: "53132d4e-684a-448a-a8f0-4c036647e916",
  },
  {
    name: "Bilbao",
    city: "Bilbao",
    country: "Spain",
    iata_code: "BIO",
    _geoloc: {
      lat: 43.301097,
      lng: -2.910608,
    },
    links_count: 128,
    objectID: "1216",
    uniqueID: "b40d7382-0001-48be-8703-b53256b6d730",
  },
  {
    name: "Sevilla",
    city: "Sevilla",
    country: "Spain",
    iata_code: "SVQ",
    _geoloc: {
      lat: 37.418,
      lng: -5.893106,
    },
    links_count: 127,
    objectID: "1253",
    uniqueID: "d11b987a-2b85-4728-a00f-370de18fad7f",
  },
  {
    name: "Palermo",
    city: "Palermo",
    country: "Italy",
    iata_code: "PMO",
    _geoloc: {
      lat: 38.175958,
      lng: 13.091019,
    },
    links_count: 126,
    objectID: "1512",
    uniqueID: "39119a8d-44c8-4ae7-ad23-e20d61f15d8f",
  },
  {
    name: "General Mitchell Intl",
    city: "Milwaukee",
    country: "United States",
    iata_code: "MKE",
    _geoloc: {
      lat: 42.947222,
      lng: -87.896583,
    },
    links_count: 125,
    objectID: "3717",
    uniqueID: "5791735e-9a5b-45ef-9b03-0020a3549ef8",
  },
  {
    name: "Jinnah Intl",
    city: "Karachi",
    country: "Pakistan",
    iata_code: "KHI",
    _geoloc: {
      lat: 24.906547,
      lng: 67.160797,
    },
    links_count: 125,
    objectID: "2206",
    uniqueID: "5be65d20-87dc-4f5e-9db7-029f784009ea",
  },
  {
    name: "Mehrabad Intl",
    city: "Teheran",
    country: "Iran",
    iata_code: "THR",
    _geoloc: {
      lat: 35.689167,
      lng: 51.313416,
    },
    links_count: 125,
    objectID: "2131",
    uniqueID: "201a0eb8-03b0-4d41-a064-aaecf63a2796",
  },
  {
    name: "Naha",
    city: "Okinawa",
    country: "Japan",
    iata_code: "OKA",
    _geoloc: {
      lat: 26.195814,
      lng: 127.645869,
    },
    links_count: 123,
    objectID: "2384",
    uniqueID: "d5949e84-8e33-47e6-a5f9-2e283d1b398b",
  },
  {
    name: "Imam Khomeini",
    city: "Tehran",
    country: "Iran",
    iata_code: "IKA",
    _geoloc: {
      lat: 35.416111,
      lng: 51.152222,
    },
    links_count: 122,
    objectID: "4330",
    uniqueID: "5b8ee51e-11b8-4d4c-90bb-24ec99ad1162",
  },
  {
    name: "Dubrovnik",
    city: "Dubrovnik",
    country: "Croatia",
    iata_code: "DBV",
    _geoloc: {
      lat: 42.561353,
      lng: 18.268244,
    },
    links_count: 121,
    objectID: "1200",
    uniqueID: "c46798c1-6b14-4e85-a101-255ff9bccf95",
  },
  {
    name: "Norman Y Mineta San Jose Intl",
    city: "San Jose",
    country: "United States",
    iata_code: "SJC",
    _geoloc: {
      lat: 37.3626,
      lng: -121.929022,
    },
    links_count: 120,
    objectID: "3748",
    uniqueID: "096699bd-d386-4c3e-a891-9f5598127bec",
  },
  {
    name: "Linate",
    city: "Milan",
    country: "Italy",
    iata_code: "LIN",
    _geoloc: {
      lat: 45.445103,
      lng: 9.276739,
    },
    links_count: 120,
    objectID: "1529",
    uniqueID: "4cce38a3-9400-4bc8-ad6b-e3019fccd800",
  },
  {
    name: "Tille",
    city: "Beauvais",
    country: "France",
    iata_code: "BVA",
    _geoloc: {
      lat: 49.454444,
      lng: 2.112778,
    },
    links_count: 120,
    objectID: "1367",
    uniqueID: "9532a377-e41b-45c2-bf2f-d2d4530d9762",
  },
  {
    name: "Zhuhai Airport",
    city: "Zhuhai",
    country: "China",
    iata_code: "ZUH",
    _geoloc: {
      lat: 22.0064,
      lng: 113.376,
    },
    links_count: 119,
    objectID: "6355",
    uniqueID: "3b8d9664-bd50-48d3-beb7-ff98ebaf77eb",
  },
  {
    name: "Murtala Muhammed",
    city: "Lagos",
    country: "Nigeria",
    iata_code: "LOS",
    _geoloc: {
      lat: 6.577369,
      lng: 3.321156,
    },
    links_count: 119,
    objectID: "273",
    uniqueID: "0ceeff87-fa70-4f1a-82a3-4e9a0103cebd",
  },
  {
    name: "Ted Stevens Anchorage Intl",
    city: "Anchorage",
    country: "United States",
    iata_code: "ANC",
    _geoloc: {
      lat: 61.174361,
      lng: -149.996361,
    },
    links_count: 118,
    objectID: "3774",
    uniqueID: "55e18d0b-4eec-4204-a461-a9d498ec1d7b",
  },
  {
    name: "Cochin",
    city: "Kochi",
    country: "India",
    iata_code: "COK",
    _geoloc: {
      lat: 10.155556,
      lng: 76.391389,
    },
    links_count: 118,
    objectID: "3136",
    uniqueID: "18618aef-6ad0-4e80-88cf-21d468c68f9c",
  },
  {
    name: "Jeju Intl",
    city: "Cheju",
    country: "South Korea",
    iata_code: "CJU",
    _geoloc: {
      lat: 33.511306,
      lng: 126.493028,
    },
    links_count: 118,
    objectID: "2370",
    uniqueID: "333143cb-75f5-422a-87d9-d324140dd05a",
  },
  {
    name: "Chaklala",
    city: "Islamabad",
    country: "Pakistan",
    iata_code: "ISB",
    _geoloc: {
      lat: 33.616653,
      lng: 73.099233,
    },
    links_count: 117,
    objectID: "2223",
    uniqueID: "85f8153a-5306-4d02-80f4-cd491803f5e6",
  },
  {
    name: "Liverpool",
    city: "Liverpool",
    country: "United Kingdom",
    iata_code: "LPL",
    _geoloc: {
      lat: 53.333611,
      lng: -2.849722,
    },
    links_count: 116,
    objectID: "491",
    uniqueID: "6c99c7e1-80f2-4473-b495-9bd23e2b6d20",
  },
  {
    name: "Vilnius Intl",
    city: "Vilnius",
    country: "Lithuania",
    iata_code: "VNO",
    _geoloc: {
      lat: 54.634133,
      lng: 25.285767,
    },
    links_count: 116,
    objectID: "3959",
    uniqueID: "17179253-4efa-4d3d-bf95-a668c9382c5f",
  },
  {
    name: "Deputado Luis Eduardo Magalhaes",
    city: "Salvador",
    country: "Brazil",
    iata_code: "SSA",
    _geoloc: {
      lat: -12.910994,
      lng: -38.331044,
    },
    links_count: 116,
    objectID: "2621",
    uniqueID: "5cadb58d-c9f4-4877-8699-ef21db6f1b43",
  },
  {
    name: "Dalaman",
    city: "Dalaman",
    country: "Turkey",
    iata_code: "DLM",
    _geoloc: {
      lat: 36.713056,
      lng: 28.7925,
    },
    links_count: 116,
    objectID: "1715",
    uniqueID: "3d5f29e8-c349-48f1-8c70-2553ea53ebbc",
  },
  {
    name: "Balice",
    city: "Krakow",
    country: "Poland",
    iata_code: "KRK",
    _geoloc: {
      lat: 50.077731,
      lng: 19.784836,
    },
    links_count: 114,
    objectID: "669",
    uniqueID: "99b84454-6ee1-4b72-8b69-63d94210b73a",
  },
  {
    name: "Princess Juliana Intl",
    city: "Philipsburg",
    country: "Netherlands Antilles",
    iata_code: "SXM",
    _geoloc: {
      lat: 18.040953,
      lng: -63.1089,
    },
    links_count: 114,
    objectID: "2899",
    uniqueID: "0aca381a-95cd-444b-be83-a32aa6dfeff3",
  },
  {
    name: "Viracopos",
    city: "Campinas",
    country: "Brazil",
    iata_code: "VCP",
    _geoloc: {
      lat: -23.0075,
      lng: -47.134444,
    },
    links_count: 114,
    objectID: "2578",
    uniqueID: "1a5bc7b7-7f1b-4b2d-af01-d920d3278688",
  },
  {
    name: "Memphis Intl",
    city: "Memphis",
    country: "United States",
    iata_code: "MEM",
    _geoloc: {
      lat: 35.042417,
      lng: -89.976667,
    },
    links_count: 112,
    objectID: "3473",
    uniqueID: "1b81aa17-c644-419f-94db-5d0385dd3be9",
  },
  {
    name: "Laishan",
    city: "Yantai",
    country: "China",
    iata_code: "YNT",
    _geoloc: {
      lat: 37.401667,
      lng: 121.371667,
    },
    links_count: 112,
    objectID: "3392",
    uniqueID: "fd653519-4c18-4a13-a092-85d8fe8e6781",
  },
  {
    name: "Hasanuddin",
    city: "Ujung Pandang",
    country: "Indonesia",
    iata_code: "UPG",
    _geoloc: {
      lat: -5.061631,
      lng: 119.554042,
    },
    links_count: 112,
    objectID: "3240",
    uniqueID: "f6cdd6b6-3411-4a9a-b2b7-3ade75414653",
  },
  {
    name: "Metropolitan Oakland Intl",
    city: "Oakland",
    country: "United States",
    iata_code: "OAK",
    _geoloc: {
      lat: 37.721278,
      lng: -122.220722,
    },
    links_count: 111,
    objectID: "3453",
    uniqueID: "dd9ed538-b2de-47bc-8811-301e356a0945",
  },
  {
    name: "Allama Iqbal Intl",
    city: "Lahore",
    country: "Pakistan",
    iata_code: "LHE",
    _geoloc: {
      lat: 31.521564,
      lng: 74.403594,
    },
    links_count: 111,
    objectID: "2207",
    uniqueID: "4a5ebe25-6cb0-40f7-8de5-75dd1f8c37d6",
  },
  {
    name: "Cairns Intl",
    city: "Cairns",
    country: "Australia",
    iata_code: "CNS",
    _geoloc: {
      lat: -16.885833,
      lng: 145.755278,
    },
    links_count: 110,
    objectID: "3322",
    uniqueID: "ac6ee169-89f5-443e-b430-76b010940a6e",
  },
  {
    name: "Tancredo Neves Intl",
    city: "Belo Horizonte",
    country: "Brazil",
    iata_code: "CNF",
    _geoloc: {
      lat: -19.63375,
      lng: -43.968856,
    },
    links_count: 110,
    objectID: "2537",
    uniqueID: "3f8c2359-9cda-497c-8717-ceacc47fc7eb",
  },
  {
    name: "Sacramento Intl",
    city: "Sacramento",
    country: "United States",
    iata_code: "SMF",
    _geoloc: {
      lat: 38.695417,
      lng: -121.590778,
    },
    links_count: 108,
    objectID: "3817",
    uniqueID: "25d2f3ca-cc4e-4992-91c4-bbcd14dfc2d6",
  },
  {
    name: "Minsk 2",
    city: "Minsk 2",
    country: "Belarus",
    iata_code: "MSQ",
    _geoloc: {
      lat: 53.882469,
      lng: 28.030731,
    },
    links_count: 108,
    objectID: "2954",
    uniqueID: "ffb75945-b2a3-4056-9edf-5de802e25402",
  },
  {
    name: "Kotoka Intl",
    city: "Accra",
    country: "Ghana",
    iata_code: "ACC",
    _geoloc: {
      lat: 5.605186,
      lng: -0.166786,
    },
    links_count: 107,
    objectID: "248",
    uniqueID: "5f46af2e-18cf-4d82-aa0f-897c72726813",
  },
  {
    name: "Landvetter",
    city: "Gothenborg",
    country: "Sweden",
    iata_code: "GOT",
    _geoloc: {
      lat: 57.662836,
      lng: 12.279819,
    },
    links_count: 106,
    objectID: "687",
    uniqueID: "f53ca4cf-7188-434a-9802-737b2cf968c3",
  },
  {
    name: "Sola",
    city: "Stavanger",
    country: "Norway",
    iata_code: "SVG",
    _geoloc: {
      lat: 58.876778,
      lng: 5.637856,
    },
    links_count: 106,
    objectID: "666",
    uniqueID: "10f04a7b-40fa-41f6-aa81-f1667f807adc",
  },
  {
    name: "Dushanbe",
    city: "Dushanbe",
    country: "Tajikistan",
    iata_code: "DYU",
    _geoloc: {
      lat: 38.543333,
      lng: 68.825,
    },
    links_count: 106,
    objectID: "2979",
    uniqueID: "74b9debe-c4e1-45b3-a63b-f4024af101d9",
  },
  {
    name: "Firenze",
    city: "Florence",
    country: "Italy",
    iata_code: "FLR",
    _geoloc: {
      lat: 43.809953,
      lng: 11.2051,
    },
    links_count: 106,
    objectID: "1563",
    uniqueID: "8b4bee0a-a4ca-44eb-836b-13821a18aa68",
  },
  {
    name: "El Salvador Intl",
    city: "San Salvador",
    country: "El Salvador",
    iata_code: "SAL",
    _geoloc: {
      lat: 13.440947,
      lng: -89.055728,
    },
    links_count: 105,
    objectID: "1892",
    uniqueID: "286cd906-0c2c-4b4f-948c-1d38d0edba9e",
  },
  {
    name: "Esenboga",
    city: "Ankara",
    country: "Turkey",
    iata_code: "ESB",
    _geoloc: {
      lat: 40.128082,
      lng: 32.995083,
    },
    links_count: 105,
    objectID: "1682",
    uniqueID: "83051cb2-62af-402b-94f7-9f9d452c4883",
  },
  {
    name: "Madeira",
    city: "Funchal",
    country: "Portugal",
    iata_code: "FNC",
    _geoloc: {
      lat: 32.697889,
      lng: -16.774453,
    },
    links_count: 104,
    objectID: "4091",
    uniqueID: "cc85f2a5-31d8-4e54-a9c0-44089470c7db",
  },
  {
    name: "Kota Kinabalu Intl",
    city: "Kota Kinabalu",
    country: "Malaysia",
    iata_code: "BKI",
    _geoloc: {
      lat: 5.937208,
      lng: 116.051181,
    },
    links_count: 104,
    objectID: "3269",
    uniqueID: "8e7e8c84-81a7-44d6-8946-8beb40b9c9b9",
  },
  {
    name: "Astana Intl",
    city: "Tselinograd",
    country: "Kazakhstan",
    iata_code: "TSE",
    _geoloc: {
      lat: 51.022222,
      lng: 71.466944,
    },
    links_count: 104,
    objectID: "2910",
    uniqueID: "0447c0d1-9f55-4912-8129-7503d5350a9c",
  },
  {
    name: "Olbia Costa Smeralda",
    city: "Olbia",
    country: "Italy",
    iata_code: "OLB",
    _geoloc: {
      lat: 40.898661,
      lng: 9.517628,
    },
    links_count: 104,
    objectID: "1520",
    uniqueID: "4e0a023a-d41a-40ab-b7a0-366c684c6d7f",
  },
  {
    name: "Bradley Intl",
    city: "Windsor Locks",
    country: "United States",
    iata_code: "BDL",
    _geoloc: {
      lat: 41.938889,
      lng: -72.683222,
    },
    links_count: 103,
    objectID: "3825",
    uniqueID: "9565b50b-cd81-4802-8c79-97e11b718e47",
  },
  {
    name: "Ioannis Kapodistrias Intl",
    city: "Kerkyra/corfu",
    country: "Greece",
    iata_code: "CFU",
    _geoloc: {
      lat: 39.601944,
      lng: 19.911667,
    },
    links_count: 103,
    objectID: "1460",
    uniqueID: "70bcbeb7-329d-470a-bacf-0466becad779",
  },
  {
    name: "Port Moresby Jacksons Intl",
    city: "Port Moresby",
    country: "Papua New Guinea",
    iata_code: "POM",
    _geoloc: {
      lat: -9.443383,
      lng: 147.22005,
    },
    links_count: 102,
    objectID: "5",
    uniqueID: "a9a04cc6-aaf9-4c97-ac7c-4462f02ab4c0",
  },
  {
    name: "Adelaide Intl",
    city: "Adelaide",
    country: "Australia",
    iata_code: "ADL",
    _geoloc: {
      lat: -34.945,
      lng: 138.530556,
    },
    links_count: 102,
    objectID: "3341",
    uniqueID: "4486a4fa-9521-4d07-ae1e-e2ca107943cb",
  },
  {
    name: "Abidjan Felix Houphouet Boigny Intl",
    city: "Abidjan",
    country: "Cote d'Ivoire",
    iata_code: "ABJ",
    _geoloc: {
      lat: 5.261386,
      lng: -3.926294,
    },
    links_count: 102,
    objectID: "253",
    uniqueID: "e7b0e321-40d3-4e7b-bbec-a70e23fd7a5d",
  },
  {
    name: "Lech Walesa",
    city: "Gdansk",
    country: "Poland",
    iata_code: "GDN",
    _geoloc: {
      lat: 54.377569,
      lng: 18.466222,
    },
    links_count: 101,
    objectID: "668",
    uniqueID: "d8c70f92-675a-4e36-9fe0-80f6b4530d44",
  },
  {
    name: "Los Cabos Intl",
    city: "San Jose Del Cabo",
    country: "Mexico",
    iata_code: "SJD",
    _geoloc: {
      lat: 23.15185,
      lng: -109.721044,
    },
    links_count: 101,
    objectID: "1840",
    uniqueID: "536378ed-fade-4318-9d66-bb08216b15a3",
  },
  {
    name: "Neuenland",
    city: "Bremen",
    country: "Germany",
    iata_code: "BRE",
    _geoloc: {
      lat: 53.0475,
      lng: 8.786667,
    },
    links_count: 100,
    objectID: "353",
    uniqueID: "dbdcfad9-670e-4952-9d3b-87c0b02641b5",
  },
  {
    name: "Ciampino",
    city: "Rome",
    country: "Italy",
    iata_code: "CIA",
    _geoloc: {
      lat: 41.799361,
      lng: 12.594936,
    },
    links_count: 100,
    objectID: "1553",
    uniqueID: "838a69d9-2bd9-444a-9d00-0b6a6453e0f6",
  },
  {
    name: "Bari",
    city: "Bari",
    country: "Italy",
    iata_code: "BRI",
    _geoloc: {
      lat: 41.138856,
      lng: 16.760594,
    },
    links_count: 100,
    objectID: "1501",
    uniqueID: "69422cca-21db-431b-96e2-fd76734952b2",
  },
  {
    name: "Xining Caojiabu Airport",
    city: "Xining",
    country: "China",
    iata_code: "XNN",
    _geoloc: {
      lat: 36.5275,
      lng: 102.043,
    },
    links_count: 99,
    objectID: "6366",
    uniqueID: "0acfc935-b02e-4303-be47-73eb7eadd5d1",
  },
  {
    name: "Yangon Intl",
    city: "Yangon",
    country: "Burma",
    iata_code: "RGN",
    _geoloc: {
      lat: 16.907305,
      lng: 96.133222,
    },
    links_count: 99,
    objectID: "3239",
    uniqueID: "113ec844-ac48-4892-9bb3-6c12f9f20eed",
  },
  {
    name: "Douala",
    city: "Douala",
    country: "Cameroon",
    iata_code: "DLA",
    _geoloc: {
      lat: 4.006081,
      lng: 9.719481,
    },
    links_count: 98,
    objectID: "897",
    uniqueID: "348f1f4d-1250-42e4-9ac1-10b5242b2cba",
  },
  {
    name: "Prince Mohammad Bin Abdulaziz",
    city: "Madinah",
    country: "Saudi Arabia",
    iata_code: "MED",
    _geoloc: {
      lat: 24.553422,
      lng: 39.705061,
    },
    links_count: 98,
    objectID: "2074",
    uniqueID: "96d6b554-deb7-4b67-b57d-25cd3df81aef",
  },
  {
    name: "Girona",
    city: "Gerona",
    country: "Spain",
    iata_code: "GRO",
    _geoloc: {
      lat: 41.900969,
      lng: 2.760547,
    },
    links_count: 98,
    objectID: "1222",
    uniqueID: "2d10e40a-238e-4353-a3ef-9d31f9200ba5",
  },
  {
    name: "Mactan Cebu Intl",
    city: "Cebu",
    country: "Philippines",
    iata_code: "CEB",
    _geoloc: {
      lat: 10.307542,
      lng: 123.979439,
    },
    links_count: 97,
    objectID: "4206",
    uniqueID: "1b2a478c-83e8-452c-abbb-59a7c9fb6bd9",
  },
  {
    name: "Orlando Sanford Intl",
    city: "Sanford",
    country: "United States",
    iata_code: "SFB",
    _geoloc: {
      lat: 28.777639,
      lng: -81.237489,
    },
    links_count: 97,
    objectID: "4167",
    uniqueID: "f2bf8860-e161-4a78-af69-89aaef6c32f2",
  },
  {
    name: "Split",
    city: "Split",
    country: "Croatia",
    iata_code: "SPU",
    _geoloc: {
      lat: 43.538944,
      lng: 16.297964,
    },
    links_count: 97,
    objectID: "1206",
    uniqueID: "e7dffe35-a7d6-4565-b007-80b77bfe6fe5",
  },
  {
    name: "Halifax Intl",
    city: "Halifax",
    country: "Canada",
    iata_code: "YHZ",
    _geoloc: {
      lat: 44.880833,
      lng: -63.50861,
    },
    links_count: 95,
    objectID: "73",
    uniqueID: "88b9da91-0588-4eca-af3c-4c5c995adb3a",
  },
  {
    name: "Chiang Mai Intl",
    city: "Chiang Mai",
    country: "Thailand",
    iata_code: "CNX",
    _geoloc: {
      lat: 18.766847,
      lng: 98.962644,
    },
    links_count: 95,
    objectID: "3931",
    uniqueID: "ed1601c0-932c-4dd3-b784-be9bd3a7cf04",
  },
  {
    name: "Nurnberg",
    city: "Nuernberg",
    country: "Germany",
    iata_code: "NUE",
    _geoloc: {
      lat: 49.4987,
      lng: 11.066897,
    },
    links_count: 95,
    objectID: "347",
    uniqueID: "51c2f8a8-6a23-4346-9437-043b2c725904",
  },
  {
    name: "Tripoli Intl",
    city: "Tripoli",
    country: "Libya",
    iata_code: "TIP",
    _geoloc: {
      lat: 32.663544,
      lng: 13.159011,
    },
    links_count: 95,
    objectID: "1157",
    uniqueID: "fe31898e-4a05-4570-b294-3ecb43da3ff6",
  },
  {
    name: "Wuxi Airport",
    city: "Wuxi",
    country: "China",
    iata_code: "WUX",
    _geoloc: {
      lat: 31.4944,
      lng: 120.429,
    },
    links_count: 94,
    objectID: "6390",
    uniqueID: "4b99f71b-09a9-4b4e-bd02-aa953f17366d",
  },
  {
    name: "Billund",
    city: "Billund",
    country: "Denmark",
    iata_code: "BLL",
    _geoloc: {
      lat: 55.740322,
      lng: 9.151778,
    },
    links_count: 94,
    objectID: "608",
    uniqueID: "62e75d00-1eef-4640-bc78-4e2ecebe5f86",
  },
  {
    name: "Frankfurt Hahn",
    city: "Hahn",
    country: "Germany",
    iata_code: "HHN",
    _geoloc: {
      lat: 49.948672,
      lng: 7.263892,
    },
    links_count: 94,
    objectID: "355",
    uniqueID: "a4ef5cad-b0b6-48a0-94e2-0c7cd868135a",
  },
  {
    name: "Congonhas",
    city: "Sao Paulo",
    country: "Brazil",
    iata_code: "CGH",
    _geoloc: {
      lat: -23.626692,
      lng: -46.655375,
    },
    links_count: 94,
    objectID: "2618",
    uniqueID: "98e193c1-3c36-46ad-a99c-f2b33b4b58c9",
  },
  {
    name: "Las Americas Intl",
    city: "Santo Domingo",
    country: "Dominican Republic",
    iata_code: "SDQ",
    _geoloc: {
      lat: 18.429664,
      lng: -69.668925,
    },
    links_count: 93,
    objectID: "1762",
    uniqueID: "1487ab67-63e3-4389-be1d-311b37e49526",
  },
  {
    name: "Luanda 4 De Fevereiro",
    city: "Luanda",
    country: "Angola",
    iata_code: "LAD",
    _geoloc: {
      lat: -8.858375,
      lng: 13.231178,
    },
    links_count: 92,
    objectID: "951",
    uniqueID: "94d38e2e-3afa-448e-b236-eff4179e75fb",
  },
  {
    name: "Dubai Al Maktoum",
    city: "Dubai",
    country: "United Arab Emirates",
    iata_code: "DWC",
    _geoloc: {
      lat: 24.55056,
      lng: 55.103174,
    },
    links_count: 92,
    objectID: "8076",
    uniqueID: "1eab3a14-d917-4eae-9206-f829baea70df",
  },
  {
    name: "Emelyanovo",
    city: "Krasnoyarsk",
    country: "Russia",
    iata_code: "KJA",
    _geoloc: {
      lat: 56.18,
      lng: 92.475,
    },
    links_count: 92,
    objectID: "4374",
    uniqueID: "1e6e8e33-d5fe-427b-8cc6-7390ff433ba7",
  },
  {
    name: "Licenciado Gustavo Diaz Ordaz Intl",
    city: "Puerto Vallarta",
    country: "Mexico",
    iata_code: "PVR",
    _geoloc: {
      lat: 20.680083,
      lng: -105.254167,
    },
    links_count: 92,
    objectID: "1836",
    uniqueID: "96ade9b3-02e8-4456-9719-3a0da016a388",
  },
  {
    name: "Jacksonville Intl",
    city: "Jacksonville",
    country: "United States",
    iata_code: "JAX",
    _geoloc: {
      lat: 30.494056,
      lng: -81.687861,
    },
    links_count: 91,
    objectID: "3712",
    uniqueID: "7466090c-a2d8-4216-b2e3-89caae52c6b6",
  },
  {
    name: "Pashkovskiy",
    city: "Krasnodar",
    country: "Russia",
    iata_code: "KRR",
    _geoloc: {
      lat: 45.034689,
      lng: 39.170539,
    },
    links_count: 91,
    objectID: "2960",
    uniqueID: "30ea84ff-6cd6-4443-ac10-ba51c9badcba",
  },
  {
    name: "Keflavik International Airport",
    city: "Keflavik",
    country: "Iceland",
    iata_code: "KEF",
    _geoloc: {
      lat: 63.985,
      lng: -22.605556,
    },
    links_count: 91,
    objectID: "16",
    uniqueID: "93b7f9af-8ffb-4ba4-839f-f8e9f4af6f88",
  },
  {
    name: "Enfidha - Zine El Abidine Ben Ali International Airport",
    city: "Enfidha",
    country: "Tunisia",
    iata_code: "NBE",
    _geoloc: {
      lat: 36.075833,
      lng: 10.438611,
    },
    links_count: 90,
    objectID: "7447",
    uniqueID: "fbb158fd-402c-42ac-824a-e2cd708d3d24",
  },
  {
    name: "Darwin Intl",
    city: "Darwin",
    country: "Australia",
    iata_code: "DRW",
    _geoloc: {
      lat: -12.4083333,
      lng: 130.87266,
    },
    links_count: 90,
    objectID: "3999",
    uniqueID: "eeeca019-ab97-4e90-9bf2-d13758643690",
  },
  {
    name: "Zvartnots",
    city: "Yerevan",
    country: "Armenia",
    iata_code: "EVN",
    _geoloc: {
      lat: 40.147275,
      lng: 44.395881,
    },
    links_count: 90,
    objectID: "3964",
    uniqueID: "beb0ad10-5258-48d3-81d5-fb2e70e24a73",
  },
  {
    name: "Manas",
    city: "Bishkek",
    country: "Kyrgyzstan",
    iata_code: "FRU",
    _geoloc: {
      lat: 43.061306,
      lng: 74.477556,
    },
    links_count: 90,
    objectID: "2912",
    uniqueID: "abd103af-06d7-47e3-9d2b-e2543a5f19e6",
  },
  {
    name: "Reina Beatrix Intl",
    city: "Oranjestad",
    country: "Aruba",
    iata_code: "AUA",
    _geoloc: {
      lat: 12.501389,
      lng: -70.015221,
    },
    links_count: 90,
    objectID: "2895",
    uniqueID: "f307d003-1feb-4efc-8c64-2eac50b3f7fe",
  },
  {
    name: "Souda",
    city: "Chania",
    country: "Greece",
    iata_code: "CHQ",
    _geoloc: {
      lat: 35.531747,
      lng: 24.149678,
    },
    links_count: 90,
    objectID: "1474",
    uniqueID: "47ee8ab2-adcf-42b5-a482-a082c3173cc1",
  },
  {
    name: "Kos",
    city: "Kos",
    country: "Greece",
    iata_code: "KGS",
    _geoloc: {
      lat: 36.793335,
      lng: 27.091667,
    },
    links_count: 90,
    objectID: "1458",
    uniqueID: "6c3c652b-71bf-49ec-916c-260b6675c5f0",
  },
  {
    name: "Sangster Intl",
    city: "Montego Bay",
    country: "Jamaica",
    iata_code: "MBJ",
    _geoloc: {
      lat: 18.503717,
      lng: -77.913358,
    },
    links_count: 89,
    objectID: "1780",
    uniqueID: "6d3922e6-a913-432c-b382-9048ad7752df",
  },
  {
    name: "Ottawa Macdonald Cartier Intl",
    city: "Ottawa",
    country: "Canada",
    iata_code: "YOW",
    _geoloc: {
      lat: 45.3225,
      lng: -75.669167,
    },
    links_count: 89,
    objectID: "100",
    uniqueID: "43d175f9-9a2f-450c-9b67-4a9fb530f2c9",
  },
  {
    name: "Quanzhou Airport",
    city: "Quanzhou",
    country: "China",
    iata_code: "JJN",
    _geoloc: {
      lat: 24.7964,
      lng: 118.59,
    },
    links_count: 88,
    objectID: "6386",
    uniqueID: "6c83bee1-e801-443d-9376-bce57324a177",
  },
  {
    name: "Palm Beach Intl",
    city: "West Palm Beach",
    country: "United States",
    iata_code: "PBI",
    _geoloc: {
      lat: 26.683161,
      lng: -80.095589,
    },
    links_count: 88,
    objectID: "3722",
    uniqueID: "42f577b7-64c3-43f1-b08c-1a10655a7e5b",
  },
  {
    name: "Tribhuvan Intl",
    city: "Kathmandu",
    country: "Nepal",
    iata_code: "KTM",
    _geoloc: {
      lat: 27.696583,
      lng: 85.3591,
    },
    links_count: 88,
    objectID: "3125",
    uniqueID: "87d6c9d6-fe9f-4bba-9ed5-ab5d67a841e4",
  },
  {
    name: "Jose Marti Intl",
    city: "Havana",
    country: "Cuba",
    iata_code: "HAV",
    _geoloc: {
      lat: 22.989153,
      lng: -82.409086,
    },
    links_count: 87,
    objectID: "1909",
    uniqueID: "9107c0e7-3c06-4c61-af01-cc6569b8cdeb",
  },
  {
    name: "Cape Town Intl",
    city: "Cape Town",
    country: "South Africa",
    iata_code: "CPT",
    _geoloc: {
      lat: -33.964806,
      lng: 18.601667,
    },
    links_count: 86,
    objectID: "797",
    uniqueID: "2752e876-6abf-46c0-8ce7-8a688539c3f8",
  },
  {
    name: "Vaernes",
    city: "Trondheim",
    country: "Norway",
    iata_code: "TRD",
    _geoloc: {
      lat: 63.457556,
      lng: 10.92425,
    },
    links_count: 86,
    objectID: "665",
    uniqueID: "bde00ffe-0a8a-43db-9c09-37d11a401e06",
  },
  {
    name: "John Wayne Arpt Orange Co",
    city: "Santa Ana",
    country: "United States",
    iata_code: "SNA",
    _geoloc: {
      lat: 33.675667,
      lng: -117.868222,
    },
    links_count: 86,
    objectID: "3867",
    uniqueID: "a4db2819-a7d5-4407-850a-c568471fdc12",
  },
  {
    name: "Salgado Filho",
    city: "Porto Alegre",
    country: "Brazil",
    iata_code: "POA",
    _geoloc: {
      lat: -29.994428,
      lng: -51.171428,
    },
    links_count: 86,
    objectID: "2599",
    uniqueID: "c249e902-b0c0-488a-a31c-9e52b5cfc5d1",
  },
  {
    name: "Afonso Pena",
    city: "Curitiba",
    country: "Brazil",
    iata_code: "CWB",
    _geoloc: {
      lat: -25.528475,
      lng: -49.175775,
    },
    links_count: 86,
    objectID: "2545",
    uniqueID: "45341656-f9d4-40cb-a04c-8182e68a093b",
  },
  {
    name: "Torino",
    city: "Torino",
    country: "Italy",
    iata_code: "TRN",
    _geoloc: {
      lat: 45.200761,
      lng: 7.649631,
    },
    links_count: 86,
    objectID: "1526",
    uniqueID: "074c7c9e-d143-46bd-8ee1-c63c508e8e1e",
  },
  {
    name: "Elmas",
    city: "Cagliari",
    country: "Italy",
    iata_code: "CAG",
    _geoloc: {
      lat: 39.251469,
      lng: 9.054283,
    },
    links_count: 86,
    objectID: "1519",
    uniqueID: "c99e00f4-b7c1-4a30-9d24-d4c8249ee2d8",
  },
  {
    name: "Male Intl",
    city: "Male",
    country: "Maldives",
    iata_code: "MLE",
    _geoloc: {
      lat: 4.191833,
      lng: 73.529128,
    },
    links_count: 85,
    objectID: "3156",
    uniqueID: "1e978e54-abe8-4147-b26e-7776268d54cd",
  },
  {
    name: "Zagreb",
    city: "Zagreb",
    country: "Croatia",
    iata_code: "ZAG",
    _geoloc: {
      lat: 45.742931,
      lng: 16.068778,
    },
    links_count: 85,
    objectID: "1208",
    uniqueID: "b60d9198-c547-42fc-8904-f4e7fa427092",
  },
  {
    name: "Pafos Intl",
    city: "Paphos",
    country: "Cyprus",
    iata_code: "PFO",
    _geoloc: {
      lat: 34.718039,
      lng: 32.485731,
    },
    links_count: 85,
    objectID: "1198",
    uniqueID: "d81fabdc-9fcb-4506-b24c-3881673b279b",
  },
  {
    name: "Kahului",
    city: "Kahului",
    country: "United States",
    iata_code: "OGG",
    _geoloc: {
      lat: 20.89865,
      lng: -156.430458,
    },
    links_count: 84,
    objectID: "3456",
    uniqueID: "90b9a8d5-2ed6-40a0-9910-85f747dadb67",
  },
  {
    name: "Penang Intl",
    city: "Penang",
    country: "Malaysia",
    iata_code: "PEN",
    _geoloc: {
      lat: 5.297139,
      lng: 100.276864,
    },
    links_count: 84,
    objectID: "3308",
    uniqueID: "c71f5445-5492-453c-bba8-eea23b9b5db4",
  },
  {
    name: "Thiruvananthapuram Intl",
    city: "Trivandrum",
    country: "India",
    iata_code: "TRV",
    _geoloc: {
      lat: 8.482122,
      lng: 76.920114,
    },
    links_count: 84,
    objectID: "3153",
    uniqueID: "cfd0603c-c991-4a7c-90bb-4edd0b7aa058",
  },
  {
    name: "Osaka Intl",
    city: "Osaka",
    country: "Japan",
    iata_code: "ITM",
    _geoloc: {
      lat: 34.785528,
      lng: 135.438222,
    },
    links_count: 84,
    objectID: "2334",
    uniqueID: "2b268b6b-1785-471d-a46e-22ef57ebb766",
  },
  {
    name: "Albuquerque International Sunport",
    city: "Albuquerque",
    country: "United States",
    iata_code: "ABQ",
    _geoloc: {
      lat: 35.0402222,
      lng: -106.6091944,
    },
    links_count: 83,
    objectID: "4019",
    uniqueID: "8c7d9261-1887-437c-a9f6-0e6c80475048",
  },
  {
    name: "Louisville International Airport",
    city: "Louisville",
    country: "United States",
    iata_code: "SDF",
    _geoloc: {
      lat: 38.1740858,
      lng: -85.7364989,
    },
    links_count: 83,
    objectID: "4014",
    uniqueID: "b94a1dea-e956-4f86-8d75-f36043ac9b4a",
  },
  {
    name: "Nadi Intl",
    city: "Nandi",
    country: "Fiji",
    iata_code: "NAN",
    _geoloc: {
      lat: -17.755392,
      lng: 177.443378,
    },
    links_count: 83,
    objectID: "1960",
    uniqueID: "c344a5e0-e104-46f6-852a-8b7111d05750",
  },
  {
    name: "General Abelardo L Rodriguez Intl",
    city: "Tijuana",
    country: "Mexico",
    iata_code: "TIJ",
    _geoloc: {
      lat: 32.541064,
      lng: -116.970158,
    },
    links_count: 83,
    objectID: "1847",
    uniqueID: "fc73eb25-9e72-46b5-aba0-6bedf8a94499",
  },
  {
    name: "Sir Seewoosagur Ramgoolam Intl",
    city: "Plaisance",
    country: "Mauritius",
    iata_code: "MRU",
    _geoloc: {
      lat: -20.430235,
      lng: 57.6836,
    },
    links_count: 82,
    objectID: "893",
    uniqueID: "8ded44b1-fffd-4e2a-9fe2-8153f2d07bf6",
  },
  {
    name: "Dyce",
    city: "Aberdeen",
    country: "United Kingdom",
    iata_code: "ABZ",
    _geoloc: {
      lat: 57.201944,
      lng: -2.197778,
    },
    links_count: 82,
    objectID: "532",
    uniqueID: "31107d3a-326c-498b-aea7-016f2eceef91",
  },
  {
    name: "Irkutsk",
    city: "Irkutsk",
    country: "Russia",
    iata_code: "IKT",
    _geoloc: {
      lat: 52.268028,
      lng: 104.388975,
    },
    links_count: 82,
    objectID: "2937",
    uniqueID: "586d0745-8942-43dd-8d0c-3fee1cf4e78f",
  },
  {
    name: "Will Rogers World",
    city: "Oklahoma City",
    country: "United States",
    iata_code: "OKC",
    _geoloc: {
      lat: 35.393089,
      lng: -97.600733,
    },
    links_count: 81,
    objectID: "3863",
    uniqueID: "b4248412-5251-4689-b9b2-12134aee9059",
  },
  {
    name: "Borg El Arab Intl",
    city: "Alexandria",
    country: "Egypt",
    iata_code: "HBE",
    _geoloc: {
      lat: 30.917669,
      lng: 29.696408,
    },
    links_count: 81,
    objectID: "3410",
    uniqueID: "7d6a06fe-940b-4c89-b3d1-b11e0e30cb5f",
  },
  {
    name: "Siem Reap",
    city: "Siem-reap",
    country: "Cambodia",
    iata_code: "REP",
    _geoloc: {
      lat: 13.410666,
      lng: 103.81284,
    },
    links_count: 81,
    objectID: "3035",
    uniqueID: "29debf5e-dd3c-40a1-8573-cf7ea236c5f4",
  },
  {
    name: "Winnipeg Intl",
    city: "Winnipeg",
    country: "Canada",
    iata_code: "YWG",
    _geoloc: {
      lat: 49.910036,
      lng: -97.239886,
    },
    links_count: 81,
    objectID: "160",
    uniqueID: "0c8a91b9-9220-4755-9e08-3928584374de",
  },
  {
    name: "Khartoum",
    city: "Khartoum",
    country: "Sudan",
    iata_code: "KRT",
    _geoloc: {
      lat: 15.589497,
      lng: 32.553161,
    },
    links_count: 81,
    objectID: "1175",
    uniqueID: "58db3137-c5ce-4998-bbb2-79474e5163b3",
  },
  {
    name: "Cadjehoun",
    city: "Cotonou",
    country: "Benin",
    iata_code: "COO",
    _geoloc: {
      lat: 6.357228,
      lng: 2.384353,
    },
    links_count: 80,
    objectID: "245",
    uniqueID: "3aee0183-18e9-49fc-a2b4-af4cb55f2217",
  },
  {
    name: "Sungshan",
    city: "Taipei",
    country: "Taiwan",
    iata_code: "TSA",
    _geoloc: {
      lat: 25.069722,
      lng: 121.5525,
    },
    links_count: 80,
    objectID: "2275",
    uniqueID: "8471a8ef-ab4f-45e9-8cb1-6da103ffec23",
  },
  {
    name: "Lesquin",
    city: "Lille",
    country: "France",
    iata_code: "LIL",
    _geoloc: {
      lat: 50.561942,
      lng: 3.089444,
    },
    links_count: 80,
    objectID: "1399",
    uniqueID: "a9e3a59a-91a0-4fc7-97e1-b7b255f41755",
  },
  {
    name: "Leipzig Halle",
    city: "Leipzig",
    country: "Germany",
    iata_code: "LEJ",
    _geoloc: {
      lat: 51.432447,
      lng: 12.241633,
    },
    links_count: 79,
    objectID: "348",
    uniqueID: "78c89c50-0c7e-41c8-ae5d-708af97fdb7d",
  },
  {
    name: "Hurghada Intl",
    city: "Hurghada",
    country: "Egypt",
    iata_code: "HRG",
    _geoloc: {
      lat: 27.178317,
      lng: 33.799436,
    },
    links_count: 79,
    objectID: "1130",
    uniqueID: "6a499f23-febd-4b84-87e5-417189fa8f8e",
  },
  {
    name: "Mariscal Sucre Intl",
    city: "Quito",
    country: "Ecuador",
    iata_code: "UIO",
    _geoloc: {
      lat: -0.141144,
      lng: -78.488214,
    },
    links_count: 78,
    objectID: "2688",
    uniqueID: "a08be275-9c7d-4743-9688-38478cdab794",
  },
  {
    name: "Gimpo",
    city: "Seoul",
    country: "South Korea",
    iata_code: "GMP",
    _geoloc: {
      lat: 37.558311,
      lng: 126.790586,
    },
    links_count: 78,
    objectID: "2378",
    uniqueID: "60fc0c28-668f-4078-982a-4933e2ceeb85",
  },
  {
    name: "Shiraz Shahid Dastghaib Intl",
    city: "Shiraz",
    country: "Iran",
    iata_code: "SYZ",
    _geoloc: {
      lat: 29.539242,
      lng: 52.589786,
    },
    links_count: 78,
    objectID: "2157",
    uniqueID: "d1f0ac99-c139-400a-8e11-856e98980399",
  },
  {
    name: "Campo Dell Oro",
    city: "Ajaccio",
    country: "France",
    iata_code: "AJA",
    _geoloc: {
      lat: 41.923637,
      lng: 8.802917,
    },
    links_count: 78,
    objectID: "1324",
    uniqueID: "3b8cb80b-7776-4ea3-bc4c-73752c03a95e",
  },
  {
    name: "Kigali Intl",
    city: "Kigali",
    country: "Rwanda",
    iata_code: "KGL",
    _geoloc: {
      lat: -1.968628,
      lng: 30.13945,
    },
    links_count: 78,
    objectID: "1165",
    uniqueID: "e057307f-bb9d-4d84-9a7e-8099301f8f30",
  },
  {
    name: "Faa\\\\'a International",
    city: "Papeete",
    country: "French Polynesia",
    iata_code: "PPT",
    _geoloc: {
      lat: -17.556667,
      lng: -149.611389,
    },
    links_count: 77,
    objectID: "4075",
    uniqueID: "758b3d24-f2b7-4a2b-8d7e-ed1b138722e8",
  },
  {
    name: "Sharm El Sheikh Intl",
    city: "Sharm El Sheikh",
    country: "Egypt",
    iata_code: "SSH",
    _geoloc: {
      lat: 27.977222,
      lng: 34.394722,
    },
    links_count: 77,
    objectID: "4057",
    uniqueID: "9d4f12b3-6190-40eb-bf48-15b2b7c8a53f",
  },
  {
    name: "La Aurora",
    city: "Guatemala City",
    country: "Guatemala",
    iata_code: "GUA",
    _geoloc: {
      lat: 14.583272,
      lng: -90.527475,
    },
    links_count: 77,
    objectID: "1767",
    uniqueID: "3397d9e3-cced-4649-a033-e769c431db8c",
  },
  {
    name: "Villafranca",
    city: "Villafranca",
    country: "Italy",
    iata_code: "VRN",
    _geoloc: {
      lat: 45.395706,
      lng: 10.888533,
    },
    links_count: 77,
    objectID: "1550",
    uniqueID: "3faacac2-5539-47f9-896a-50cb0e0126fb",
  },
  {
    name: "Jersey",
    city: "Jersey",
    country: "Jersey",
    iata_code: "JER",
    _geoloc: {
      lat: 49.207947,
      lng: -2.195508,
    },
    links_count: 76,
    objectID: "499",
    uniqueID: "636d3963-4280-416b-b1b7-88fbef129f5e",
  },
  {
    name: "Niederrhein",
    city: "Weeze",
    country: "Germany",
    iata_code: "NRN",
    _geoloc: {
      lat: 51.602222,
      lng: 6.141944,
    },
    links_count: 76,
    objectID: "4198",
    uniqueID: "7b706d03-4e0c-480c-9152-1f5163817b03",
  },
  {
    name: "Tallinn",
    city: "Tallinn-ulemiste International",
    country: "Estonia",
    iata_code: "TLL",
    _geoloc: {
      lat: 59.413317,
      lng: 24.832844,
    },
    links_count: 76,
    objectID: "415",
    uniqueID: "d8d4d5d8-d010-4928-b4fb-8b88134979b9",
  },
  {
    name: "Richmond Intl",
    city: "Richmond",
    country: "United States",
    iata_code: "RIC",
    _geoloc: {
      lat: 37.505167,
      lng: -77.319667,
    },
    links_count: 76,
    objectID: "3608",
    uniqueID: "b0c7b296-3fad-4c04-ba66-1217f5f11914",
  },
  {
    name: "Yakutsk",
    city: "Yakutsk",
    country: "Russia",
    iata_code: "YKS",
    _geoloc: {
      lat: 62.09325,
      lng: 129.770672,
    },
    links_count: 76,
    objectID: "2923",
    uniqueID: "febc42ef-f985-4c24-84ff-9f06cfa483ac",
  },
  {
    name: "Treviso",
    city: "Treviso",
    country: "Italy",
    iata_code: "TSF",
    _geoloc: {
      lat: 45.6484,
      lng: 12.194422,
    },
    links_count: 76,
    objectID: "1539",
    uniqueID: "c6255a89-43fc-4db3-8841-afe3b05a73d7",
  },
  {
    name: "Sanaa Intl",
    city: "Sanaa",
    country: "Yemen",
    iata_code: "SAH",
    _geoloc: {
      lat: 15.476258,
      lng: 44.219739,
    },
    links_count: 75,
    objectID: "3980",
    uniqueID: "aef2e0f6-9108-43d9-bc57-7afcfd780175",
  },
  {
    name: "Dallas Love Fld",
    city: "Dallas",
    country: "United States",
    iata_code: "DAL",
    _geoloc: {
      lat: 32.847111,
      lng: -96.851778,
    },
    links_count: 75,
    objectID: "3502",
    uniqueID: "3fa24a50-c318-4722-b411-0de192dffb52",
  },
  {
    name: "Novy",
    city: "Khabarovsk",
    country: "Russia",
    iata_code: "KHV",
    _geoloc: {
      lat: 48.528044,
      lng: 135.188361,
    },
    links_count: 75,
    objectID: "2927",
    uniqueID: "f04cff73-8eab-4fd1-abf6-19d9c11099f2",
  },
  {
    name: "Torp",
    city: "Sandefjord",
    country: "Norway",
    iata_code: "TRF",
    _geoloc: {
      lat: 59.186703,
      lng: 10.258628,
    },
    links_count: 74,
    objectID: "664",
    uniqueID: "9fd9c3f1-f9da-4d76-b172-a29e76f61fba",
  },
  {
    name: "Moss",
    city: "Rygge",
    country: "Norway",
    iata_code: "RYG",
    _geoloc: {
      lat: 59.378933,
      lng: 10.785389,
    },
    links_count: 74,
    objectID: "657",
    uniqueID: "80ee7530-e224-4025-9026-8421ceaf9b03",
  },
  {
    name: "Changzhou",
    city: "Changzhou",
    country: "China",
    iata_code: "CZX",
    _geoloc: {
      lat: 31.941667,
      lng: 119.711667,
    },
    links_count: 74,
    objectID: "4109",
    uniqueID: "438950fc-4b94-44b0-9fb6-a1fc0b0ba97d",
  },
  {
    name: "Norfolk Intl",
    city: "Norfolk",
    country: "United States",
    iata_code: "ORF",
    _geoloc: {
      lat: 36.894611,
      lng: -76.201222,
    },
    links_count: 74,
    objectID: "3611",
    uniqueID: "2f00929a-2916-4cd3-9b2e-ddaaec6d28c3",
  },
  {
    name: "Zarzis",
    city: "Djerba",
    country: "Tunisia",
    iata_code: "DJE",
    _geoloc: {
      lat: 33.875031,
      lng: 10.775461,
    },
    links_count: 73,
    objectID: "293",
    uniqueID: "6ee69131-12bc-4ca4-947e-4c02b0aa4d62",
  },
  {
    name: "Sepinggan",
    city: "Balikpapan",
    country: "Indonesia",
    iata_code: "BPN",
    _geoloc: {
      lat: -1.268272,
      lng: 116.894478,
    },
    links_count: 72,
    objectID: "3919",
    uniqueID: "e58f5dbd-44cb-49d8-a7c6-8db2346a18ed",
  },
  {
    name: "Guararapes Gilberto Freyre Intl",
    city: "Recife",
    country: "Brazil",
    iata_code: "REC",
    _geoloc: {
      lat: -8.126794,
      lng: -34.923039,
    },
    links_count: 72,
    objectID: "2610",
    uniqueID: "a786b429-8653-4160-9b70-0a2b7c8c9867",
  },
  {
    name: "Christchurch Intl",
    city: "Christchurch",
    country: "New Zealand",
    iata_code: "CHC",
    _geoloc: {
      lat: -43.489358,
      lng: 172.532225,
    },
    links_count: 72,
    objectID: "2009",
    uniqueID: "9e8ab62b-77d8-4bb3-bf6e-5b7942179711",
  },
  {
    name: "Poretta",
    city: "Bastia",
    country: "France",
    iata_code: "BIA",
    _geoloc: {
      lat: 42.552664,
      lng: 9.483731,
    },
    links_count: 72,
    objectID: "1321",
    uniqueID: "01313ae6-f699-41f9-abc5-ebe01f294f66",
  },
  {
    name: "Tirana Rinas",
    city: "Tirana",
    country: "Albania",
    iata_code: "TIA",
    _geoloc: {
      lat: 41.414742,
      lng: 19.720561,
    },
    links_count: 72,
    objectID: "1190",
    uniqueID: "0279889e-f0a9-4799-8e54-362c09516860",
  },
  {
    name: "Wai Sha Airport",
    city: "Shantou",
    country: "China",
    iata_code: "SWA",
    _geoloc: {
      lat: 23.4,
      lng: 116.683,
    },
    links_count: 71,
    objectID: "4302",
    uniqueID: "969960c8-0942-4503-b3ad-7049bd190694",
  },
  {
    name: "Buffalo Niagara Intl",
    city: "Buffalo",
    country: "United States",
    iata_code: "BUF",
    _geoloc: {
      lat: 42.940525,
      lng: -78.732167,
    },
    links_count: 71,
    objectID: "3820",
    uniqueID: "1e29cfc1-4bda-4587-b32f-f02e5d25db69",
  },
  {
    name: "Birmingham Intl",
    city: "Birmingham",
    country: "United States",
    iata_code: "BHM",
    _geoloc: {
      lat: 33.562942,
      lng: -86.75355,
    },
    links_count: 71,
    objectID: "3811",
    uniqueID: "a21c6603-b10f-477e-b1e8-9438e0c60960",
  },
  {
    name: "Jose Joaquin De Olmedo Intl",
    city: "Guayaquil",
    country: "Ecuador",
    iata_code: "GYE",
    _geoloc: {
      lat: -2.157419,
      lng: -79.883558,
    },
    links_count: 71,
    objectID: "2673",
    uniqueID: "a86eb064-a08a-4e58-8b9c-f25afa92892b",
  },
  {
    name: "Pinto Martins Intl",
    city: "Fortaleza",
    country: "Brazil",
    iata_code: "FOR",
    _geoloc: {
      lat: -3.776283,
      lng: -38.532556,
    },
    links_count: 71,
    objectID: "2559",
    uniqueID: "a5f32bb0-e4d6-41e5-ae2b-e58f6f6ef4b6",
  },
  {
    name: "Belfast Intl",
    city: "Belfast",
    country: "United Kingdom",
    iata_code: "BFS",
    _geoloc: {
      lat: 54.6575,
      lng: -6.215833,
    },
    links_count: 70,
    objectID: "465",
    uniqueID: "1b205d45-f14e-43ee-9304-feacedc62489",
  },
  {
    name: "Ahmedabad",
    city: "Ahmedabad",
    country: "India",
    iata_code: "AMD",
    _geoloc: {
      lat: 23.077242,
      lng: 72.63465,
    },
    links_count: 70,
    objectID: "2994",
    uniqueID: "68ab9903-9c5a-4289-96ee-b88310284e6a",
  },
  {
    name: "Piarco",
    city: "Port-of-spain",
    country: "Trinidad and Tobago",
    iata_code: "POS",
    _geoloc: {
      lat: 10.595369,
      lng: -61.337242,
    },
    links_count: 70,
    objectID: "2902",
    uniqueID: "0670d4ab-3647-446c-90fc-c69ab40e36ba",
  },
  {
    name: "Val De Cans Intl",
    city: "Belem",
    country: "Brazil",
    iata_code: "BEL",
    _geoloc: {
      lat: -1.37925,
      lng: -48.476292,
    },
    links_count: 70,
    objectID: "2526",
    uniqueID: "40d8bed2-e871-4c34-8a9c-f0e9422938ac",
  },
  {
    name: "Guam Intl",
    city: "Agana",
    country: "Guam",
    iata_code: "GUM",
    _geoloc: {
      lat: 13.48345,
      lng: 144.795983,
    },
    links_count: 70,
    objectID: "2246",
    uniqueID: "bc37869b-d70b-40b9-a006-ac78f063fdf6",
  },
  {
    name: "Santiago",
    city: "Santiago",
    country: "Spain",
    iata_code: "SCQ",
    _geoloc: {
      lat: 42.896333,
      lng: -8.415144,
    },
    links_count: 70,
    objectID: "1243",
    uniqueID: "b750b698-06df-44c9-bcac-4b3062b3c8ef",
  },
  {
    name: "Menorca",
    city: "Menorca",
    country: "Spain",
    iata_code: "MAH",
    _geoloc: {
      lat: 39.862597,
      lng: 4.218647,
    },
    links_count: 70,
    objectID: "1231",
    uniqueID: "873be405-428e-432c-b7d8-fa91099a7974",
  },
  {
    name: "Tbilisi",
    city: "Tbilisi",
    country: "Georgia",
    iata_code: "TBS",
    _geoloc: {
      lat: 41.669167,
      lng: 44.954722,
    },
    links_count: 69,
    objectID: "3973",
    uniqueID: "5511c655-a3ba-4caf-983a-7739447678bc",
  },
  {
    name: "Charleston Afb Intl",
    city: "Charleston",
    country: "United States",
    iata_code: "CHS",
    _geoloc: {
      lat: 32.898647,
      lng: -80.040528,
    },
    links_count: 69,
    objectID: "3806",
    uniqueID: "3e69e874-f5b8-4581-baee-fca0215ecb0b",
  },
  {
    name: "Eppley Afld",
    city: "Omaha",
    country: "United States",
    iata_code: "OMA",
    _geoloc: {
      lat: 41.303167,
      lng: -95.894069,
    },
    links_count: 69,
    objectID: "3454",
    uniqueID: "2e0894f8-c505-489d-a99d-9e1c235054fa",
  },
  {
    name: "V C Bird Intl",
    city: "Antigua",
    country: "Antigua and Barbuda",
    iata_code: "ANU",
    _geoloc: {
      lat: 17.136749,
      lng: -61.792667,
    },
    links_count: 69,
    objectID: "2874",
    uniqueID: "128bd91e-cda9-4318-ad4b-43121b17d3b2",
  },
  {
    name: "Salzburg",
    city: "Salzburg",
    country: "Austria",
    iata_code: "SZG",
    _geoloc: {
      lat: 47.793304,
      lng: 13.004333,
    },
    links_count: 69,
    objectID: "1612",
    uniqueID: "0e2cc512-4c66-40dc-9aab-c59484ffb2c9",
  },
  {
    name: "Entzheim",
    city: "Strasbourg",
    country: "France",
    iata_code: "SXB",
    _geoloc: {
      lat: 48.538319,
      lng: 7.628233,
    },
    links_count: 69,
    objectID: "1435",
    uniqueID: "f7ea4002-6b72-4260-b674-4ade31d655dc",
  },
  {
    name: "Cork",
    city: "Cork",
    country: "Ireland",
    iata_code: "ORK",
    _geoloc: {
      lat: 51.841269,
      lng: -8.491111,
    },
    links_count: 68,
    objectID: "596",
    uniqueID: "f136ddb6-03df-4607-b16b-dc53b2128d3f",
  },
  {
    name: "Myrtle Beach Intl",
    city: "Myrtle Beach",
    country: "United States",
    iata_code: "MYR",
    _geoloc: {
      lat: 33.67975,
      lng: -78.928333,
    },
    links_count: 68,
    objectID: "3515",
    uniqueID: "b0247689-fc71-4820-96a1-d85246e62bbd",
  },
  {
    name: "Pune",
    city: "Pune",
    country: "India",
    iata_code: "PNQ",
    _geoloc: {
      lat: 18.582111,
      lng: 73.919697,
    },
    links_count: 68,
    objectID: "3017",
    uniqueID: "994a248d-9b2c-4660-8e12-8e6c7b12a045",
  },
  {
    name: "Es Senia",
    city: "Oran",
    country: "Algeria",
    iata_code: "ORN",
    _geoloc: {
      lat: 35.623858,
      lng: -0.621183,
    },
    links_count: 68,
    objectID: "231",
    uniqueID: "638fd550-921c-4913-b5c1-0c2dd3d1b03b",
  },
  {
    name: "Chisinau Intl",
    city: "Chisinau",
    country: "Moldova",
    iata_code: "KIV",
    _geoloc: {
      lat: 46.927744,
      lng: 28.930978,
    },
    links_count: 68,
    objectID: "1735",
    uniqueID: "72121128-4c4e-4cb5-aae4-c48a5668bfce",
  },
  {
    name: "Lusaka Intl",
    city: "Lusaka",
    country: "Zambia",
    iata_code: "LUN",
    _geoloc: {
      lat: -15.330817,
      lng: 28.452628,
    },
    links_count: 67,
    objectID: "907",
    uniqueID: "763a6889-b20d-4a0f-b6c6-78830b19e404",
  },
  {
    name: "Dortmund",
    city: "Dortmund",
    country: "Germany",
    iata_code: "DTM",
    _geoloc: {
      lat: 51.518314,
      lng: 7.612242,
    },
    links_count: 67,
    objectID: "373",
    uniqueID: "5021ee5f-732c-453b-80f8-5fc8d5453dcc",
  },
  {
    name: "Entebbe Intl",
    city: "Entebbe",
    country: "Uganda",
    iata_code: "EBB",
    _geoloc: {
      lat: 0.042386,
      lng: 32.443503,
    },
    links_count: 67,
    objectID: "1187",
    uniqueID: "0220481f-73c4-47be-9e81-f67f4014786f",
  },
  {
    name: "Mwalimu Julius K Nyerere Intl",
    city: "Dar Es Salaam",
    country: "Tanzania",
    iata_code: "DAR",
    _geoloc: {
      lat: -6.878111,
      lng: 39.202625,
    },
    links_count: 67,
    objectID: "1177",
    uniqueID: "bd83aa86-ffa6-4ba9-a478-fc31c5a007f5",
  },
  {
    name: "Strachowice",
    city: "Wroclaw",
    country: "Poland",
    iata_code: "WRO",
    _geoloc: {
      lat: 51.102683,
      lng: 16.885836,
    },
    links_count: 66,
    objectID: "680",
    uniqueID: "24a08cc8-7963-4a66-87b5-1d8e879d5b13",
  },
  {
    name: "Khudzhand Airport",
    city: "Khudzhand",
    country: "Tajikistan",
    iata_code: "LBD",
    _geoloc: {
      lat: 40.2154,
      lng: 69.6947,
    },
    links_count: 66,
    objectID: "6147",
    uniqueID: "4ff3ddb3-70d8-40e3-b1b5-31f9bf2c0c66",
  },
  {
    name: "Rotterdam",
    city: "Rotterdam",
    country: "Netherlands",
    iata_code: "RTM",
    _geoloc: {
      lat: 51.956944,
      lng: 4.437222,
    },
    links_count: 66,
    objectID: "591",
    uniqueID: "1472a2f2-75b0-4dcd-a89a-b2eb072c5968",
  },
  {
    name: "Southampton",
    city: "Southampton",
    country: "United Kingdom",
    iata_code: "SOU",
    _geoloc: {
      lat: 50.950261,
      lng: -1.356803,
    },
    links_count: 66,
    objectID: "495",
    uniqueID: "b2b816da-4512-407b-b905-127059c592e9",
  },
  {
    name: "Danang Intl",
    city: "Danang",
    country: "Vietnam",
    iata_code: "DAD",
    _geoloc: {
      lat: 16.043917,
      lng: 108.19937,
    },
    links_count: 66,
    objectID: "3196",
    uniqueID: "8528215d-2ca7-4abd-8ca6-a20b98beb32a",
  },
  {
    name: "Cyril E King",
    city: "St. Thomas",
    country: "Virgin Islands",
    iata_code: "STT",
    _geoloc: {
      lat: 18.337306,
      lng: -64.973361,
    },
    links_count: 66,
    objectID: "2883",
    uniqueID: "c2764218-f88a-4083-8e2a-63514b33be96",
  },
  {
    name: "Marechal Rondon",
    city: "Cuiaba",
    country: "Brazil",
    iata_code: "CGB",
    _geoloc: {
      lat: -15.652931,
      lng: -56.116719,
    },
    links_count: 66,
    objectID: "2548",
    uniqueID: "795081fe-e1fa-480b-821b-9724a5940a77",
  },
  {
    name: "Ouagadougou",
    city: "Ouagadougou",
    country: "Burkina Faso",
    iata_code: "OUA",
    _geoloc: {
      lat: 12.353194,
      lng: -1.512417,
    },
    links_count: 66,
    objectID: "246",
    uniqueID: "6369b87b-3293-44ae-ac40-ef71b7ae8fc1",
  },
  {
    name: "Ljubljana",
    city: "Ljubljana",
    country: "Slovenia",
    iata_code: "LJU",
    _geoloc: {
      lat: 46.223686,
      lng: 14.457611,
    },
    links_count: 66,
    objectID: "1569",
    uniqueID: "63b74a1f-467c-4080-b49e-3741ecbd3146",
  },
  {
    name: "Tenerife Norte",
    city: "Tenerife",
    country: "Spain",
    iata_code: "TFN",
    _geoloc: {
      lat: 28.482653,
      lng: -16.341536,
    },
    links_count: 66,
    objectID: "1057",
    uniqueID: "e220e15e-31e8-4c88-8a48-ce108d6a2e56",
  },
  {
    name: "Phoenix-Mesa Gateway",
    city: "Mesa",
    country: "United States",
    iata_code: "AZA",
    _geoloc: {
      lat: 33.307833,
      lng: -111.655,
    },
    links_count: 64,
    objectID: "6505",
    uniqueID: "900e030c-34ad-4731-a388-dfad239b7323",
  },
  {
    name: "James M Cox Dayton Intl",
    city: "Dayton",
    country: "United States",
    iata_code: "DAY",
    _geoloc: {
      lat: 39.902375,
      lng: -84.219375,
    },
    links_count: 64,
    objectID: "3627",
    uniqueID: "977babbb-f140-4887-b34e-83e7c8dc90a8",
  },
  {
    name: "Eduardo Gomes Intl",
    city: "Manaus",
    country: "Brazil",
    iata_code: "MAO",
    _geoloc: {
      lat: -3.038611,
      lng: -60.049721,
    },
    links_count: 64,
    objectID: "2551",
    uniqueID: "e68f6498-4c44-44c9-8e49-b1a41df7868b",
  },
  {
    name: "Wellington Intl",
    city: "Wellington",
    country: "New Zealand",
    iata_code: "WLG",
    _geoloc: {
      lat: -41.327221,
      lng: 174.805278,
    },
    links_count: 64,
    objectID: "2042",
    uniqueID: "4b2f3ca1-ce4f-45b1-a2b2-5ce582b570c9",
  },
  {
    name: "Lokpriya Gopinath Bordoloi International Airport",
    city: "Guwahati",
    country: "India",
    iata_code: "GAU",
    _geoloc: {
      lat: 26.106092,
      lng: 91.585939,
    },
    links_count: 63,
    objectID: "6173",
    uniqueID: "7de97431-098f-4c72-8b97-03d6c1bf3367",
  },
  {
    name: "Cardiff",
    city: "Cardiff",
    country: "United Kingdom",
    iata_code: "CWL",
    _geoloc: {
      lat: 51.396667,
      lng: -3.343333,
    },
    links_count: 63,
    objectID: "488",
    uniqueID: "54d61c28-5320-48c2-9608-17a57673de1a",
  },
  {
    name: "Bodrum - Milas",
    city: "Bodrum",
    country: "Turkey",
    iata_code: "BJV",
    _geoloc: {
      lat: 37.249,
      lng: 27.667,
    },
    links_count: 63,
    objectID: "4315",
    uniqueID: "1a56c164-26de-497b-911b-ebbc5ddebf29",
  },
  {
    name: "Hato",
    city: "Willemstad",
    country: "Netherlands Antilles",
    iata_code: "CUR",
    _geoloc: {
      lat: 12.188853,
      lng: -68.959803,
    },
    links_count: 63,
    objectID: "2897",
    uniqueID: "4aa7f783-284a-4c8f-a28b-4029fc129cbe",
  },
  {
    name: "Skavsta",
    city: "Stockholm",
    country: "Sweden",
    iata_code: "NYO",
    _geoloc: {
      lat: 58.788636,
      lng: 16.912189,
    },
    links_count: 62,
    objectID: "699",
    uniqueID: "d095fc9d-6869-4895-8d75-57549f497270",
  },
  {
    name: "Mc Ghee Tyson",
    city: "Knoxville",
    country: "United States",
    iata_code: "TYS",
    _geoloc: {
      lat: 35.810972,
      lng: -83.994028,
    },
    links_count: 62,
    objectID: "3676",
    uniqueID: "a24a6b2e-4f40-40aa-be72-5e9fdf58c49f",
  },
  {
    name: "Tucson Intl",
    city: "Tucson",
    country: "United States",
    iata_code: "TUS",
    _geoloc: {
      lat: 32.116083,
      lng: -110.941028,
    },
    links_count: 62,
    objectID: "3636",
    uniqueID: "942a5a2c-2da6-43db-801f-adc2ba3c05a0",
  },
  {
    name: "St Petersburg Clearwater Intl",
    city: "St. Petersburg",
    country: "United States",
    iata_code: "PIE",
    _geoloc: {
      lat: 27.910167,
      lng: -82.687389,
    },
    links_count: 62,
    objectID: "3617",
    uniqueID: "b4e35e74-4e03-4758-af5a-8a71f71a2ddd",
  },
  {
    name: "Sendai",
    city: "Sendai",
    country: "Japan",
    iata_code: "SDJ",
    _geoloc: {
      lat: 38.139722,
      lng: 140.916944,
    },
    links_count: 62,
    objectID: "2347",
    uniqueID: "6f0a03fb-6d51-4cd2-8f29-7f4d1e8103e5",
  },
  {
    name: "Providenciales",
    city: "Providenciales",
    country: "Turks and Caicos Islands",
    iata_code: "PLS",
    _geoloc: {
      lat: 21.773625,
      lng: -72.265886,
    },
    links_count: 62,
    objectID: "1754",
    uniqueID: "af01ff71-a661-417b-9cec-9115e8bce8b7",
  },
  {
    name: "Alghero",
    city: "Alghero",
    country: "Italy",
    iata_code: "AHO",
    _geoloc: {
      lat: 40.632133,
      lng: 8.290772,
    },
    links_count: 62,
    objectID: "1517",
    uniqueID: "191771cf-fa04-4cf2-a949-0af59b4cd1f4",
  },
  {
    name: "Calicut",
    city: "Calicut",
    country: "India",
    iata_code: "CCJ",
    _geoloc: {
      lat: 11.136839,
      lng: 75.9553,
    },
    links_count: 61,
    objectID: "3137",
    uniqueID: "95373fda-f53a-4be0-8b8b-e80960b2f31d",
  },
  {
    name: "Phnom Penh Intl",
    city: "Phnom-penh",
    country: "Cambodia",
    iata_code: "PNH",
    _geoloc: {
      lat: 11.546556,
      lng: 104.844139,
    },
    links_count: 61,
    objectID: "3034",
    uniqueID: "0af2cae1-44fc-4105-9a3d-5b9e76e51b00",
  },
  {
    name: "Santos Dumont",
    city: "Rio De Janeiro",
    country: "Brazil",
    iata_code: "SDU",
    _geoloc: {
      lat: -22.910461,
      lng: -43.163133,
    },
    links_count: 61,
    objectID: "2612",
    uniqueID: "dd64462f-5ccd-4539-b5ee-25d35a4ce922",
  },
  {
    name: "Lhasa-Gonggar",
    city: "Lhasa",
    country: "China",
    iata_code: "LXA",
    _geoloc: {
      lat: 29.297778,
      lng: 90.911944,
    },
    links_count: 60,
    objectID: "4097",
    uniqueID: "e29bae6e-7c83-4b90-8987-244f9d091107",
  },
  {
    name: "Erbil Intl",
    city: "Erbil",
    country: "Iraq",
    iata_code: "EBL",
    _geoloc: {
      lat: 36.237611,
      lng: 43.963158,
    },
    links_count: 60,
    objectID: "3989",
    uniqueID: "442c96f7-2de4-40fb-a61c-43738d415fea",
  },
  {
    name: "Albany Intl",
    city: "Albany",
    country: "United States",
    iata_code: "ALB",
    _geoloc: {
      lat: 42.748267,
      lng: -73.801692,
    },
    links_count: 60,
    objectID: "3864",
    uniqueID: "4a5a1e20-2728-468d-a187-ab94f616b0ca",
  },
  {
    name: "Greater Rochester Intl",
    city: "Rochester",
    country: "United States",
    iata_code: "ROC",
    _geoloc: {
      lat: 43.118866,
      lng: -77.672389,
    },
    links_count: 60,
    objectID: "3622",
    uniqueID: "69e07314-9f96-4537-a7cd-916a094e4440",
  },
  {
    name: "Osh",
    city: "Osh",
    country: "Kyrgyzstan",
    iata_code: "OSS",
    _geoloc: {
      lat: 40.608989,
      lng: 72.793269,
    },
    links_count: 60,
    objectID: "2913",
    uniqueID: "216e2662-a6d2-4509-8563-9f496bdb31e1",
  },
  {
    name: "Nnamdi Azikiwe Intl",
    city: "Abuja",
    country: "Nigeria",
    iata_code: "ABV",
    _geoloc: {
      lat: 9.006792,
      lng: 7.263172,
    },
    links_count: 60,
    objectID: "260",
    uniqueID: "6b5d877f-ecc5-4e07-a13c-896aecaf8b63",
  },
  {
    name: "Peshawar Intl",
    city: "Peshawar",
    country: "Pakistan",
    iata_code: "PEW",
    _geoloc: {
      lat: 33.993911,
      lng: 71.514581,
    },
    links_count: 60,
    objectID: "2219",
    uniqueID: "64f9f8f1-2331-4e93-891c-1a8896ec4dfd",
  },
  {
    name: "Zhangxiao",
    city: "Yuncheng",
    country: "China",
    iata_code: "YCU",
    _geoloc: {
      lat: 35.018,
      lng: 110.993,
    },
    links_count: 58,
    objectID: "6430",
    uniqueID: "246605c5-0db7-4d47-9243-0d3c20bf0998",
  },
  {
    name: "Baotou Airport",
    city: "Baotou",
    country: "China",
    iata_code: "BAV",
    _geoloc: {
      lat: 40.56,
      lng: 109.997,
    },
    links_count: 58,
    objectID: "6346",
    uniqueID: "8d5f3fae-b92d-4e3d-8cdd-ce37710eefdc",
  },
  {
    name: "Shannon",
    city: "Shannon",
    country: "Ireland",
    iata_code: "SNN",
    _geoloc: {
      lat: 52.701978,
      lng: -8.924817,
    },
    links_count: 58,
    objectID: "603",
    uniqueID: "cadc7915-4758-4318-98de-72e5dd3146b7",
  },
  {
    name: "Greenville-Spartanburg International",
    city: "Greenville",
    country: "United States",
    iata_code: "GSP",
    _geoloc: {
      lat: 34.895556,
      lng: -82.218889,
    },
    links_count: 58,
    objectID: "4034",
    uniqueID: "ef519c82-0d8a-4775-bde6-860f3e942851",
  },
  {
    name: "Adi Sutjipto",
    city: "Yogyakarta",
    country: "Indonesia",
    iata_code: "JOG",
    _geoloc: {
      lat: -7.788181,
      lng: 110.431758,
    },
    links_count: 58,
    objectID: "3898",
    uniqueID: "9564df3e-8b03-4f73-a48f-e7f931b22a24",
  },
  {
    name: "Adams Fld",
    city: "Little Rock",
    country: "United States",
    iata_code: "LIT",
    _geoloc: {
      lat: 34.729444,
      lng: -92.224306,
    },
    links_count: 58,
    objectID: "3660",
    uniqueID: "f0c2c995-46e4-44fd-a99b-5ef94ed75b6a",
  },
  {
    name: "Subang-Sultan Abdul Aziz Shah Intl",
    city: "Kuala Lumpur",
    country: "Malaysia",
    iata_code: "SZB",
    _geoloc: {
      lat: 3.130583,
      lng: 101.549333,
    },
    links_count: 58,
    objectID: "3408",
    uniqueID: "e639c07f-70b7-4350-92ea-c4a869ac40e0",
  },
  {
    name: "Goa",
    city: "Goa",
    country: "India",
    iata_code: "GOI",
    _geoloc: {
      lat: 15.380833,
      lng: 73.831422,
    },
    links_count: 58,
    objectID: "3007",
    uniqueID: "d8e767af-2f7d-46af-aed6-460391bed39c",
  },
  {
    name: "Le Raizet",
    city: "Pointe-a-pitre",
    country: "Guadeloupe",
    iata_code: "PTP",
    _geoloc: {
      lat: 16.265306,
      lng: -61.531806,
    },
    links_count: 58,
    objectID: "2881",
    uniqueID: "2cdaefcd-d782-4b0f-a265-7f0f76efda2e",
  },
  {
    name: "Ching Chuang Kang",
    city: "Taichung",
    country: "Taiwan",
    iata_code: "RMQ",
    _geoloc: {
      lat: 24.264668,
      lng: 120.62058,
    },
    links_count: 58,
    objectID: "2268",
    uniqueID: "c51255d3-8516-4af9-bf13-424dfa035b19",
  },
  {
    name: "Yellowknife",
    city: "Yellowknife",
    country: "Canada",
    iata_code: "YZF",
    _geoloc: {
      lat: 62.462778,
      lng: -114.440278,
    },
    links_count: 58,
    objectID: "196",
    uniqueID: "4109ee9d-91a3-4bb1-9b61-7c0f99966063",
  },
  {
    name: "Norman Manley Intl",
    city: "Kingston",
    country: "Jamaica",
    iata_code: "KIN",
    _geoloc: {
      lat: 17.935667,
      lng: -76.7875,
    },
    links_count: 58,
    objectID: "1779",
    uniqueID: "f61fb1df-a602-4472-ab53-64f466e04997",
  },
  {
    name: "Des Moines Intl",
    city: "Des Moines",
    country: "United States",
    iata_code: "DSM",
    _geoloc: {
      lat: 41.533972,
      lng: -93.663083,
    },
    links_count: 57,
    objectID: "3729",
    uniqueID: "aab71907-a5d4-489a-b073-f879403ca40a",
  },
  {
    name: "Bethel",
    city: "Bethel",
    country: "United States",
    iata_code: "BET",
    _geoloc: {
      lat: 60.779778,
      lng: -161.838,
    },
    links_count: 57,
    objectID: "3599",
    uniqueID: "759680c5-e0b9-488a-87c7-c0ca3af28ce5",
  },
  {
    name: "Alfonso Bonilla Aragon Intl",
    city: "Cali",
    country: "Colombia",
    iata_code: "CLO",
    _geoloc: {
      lat: 3.543222,
      lng: -76.381583,
    },
    links_count: 57,
    objectID: "2715",
    uniqueID: "2aa778ec-6ae3-4db7-afe5-fa46b458b7ee",
  },
  {
    name: "Quebec Jean Lesage Intl",
    city: "Quebec",
    country: "Canada",
    iata_code: "YQB",
    _geoloc: {
      lat: 46.791111,
      lng: -71.393333,
    },
    links_count: 57,
    objectID: "111",
    uniqueID: "c70ce3f5-dab2-4cfc-b3f8-95648cdd67f4",
  },
  {
    name: "Harare Intl",
    city: "Harare",
    country: "Zimbabwe",
    iata_code: "HRE",
    _geoloc: {
      lat: -17.931806,
      lng: 31.092847,
    },
    links_count: 57,
    objectID: "1005",
    uniqueID: "c28a4dc6-5e82-40c5-befe-b3593fa6b966",
  },
  {
    name: "Pyrzowice",
    city: "Katowice",
    country: "Poland",
    iata_code: "KTW",
    _geoloc: {
      lat: 50.474253,
      lng: 19.080019,
    },
    links_count: 56,
    objectID: "671",
    uniqueID: "b5dcba59-d1b3-4957-ad34-7843610e4b77",
  },
  {
    name: "Reno Tahoe Intl",
    city: "Reno",
    country: "United States",
    iata_code: "RNO",
    _geoloc: {
      lat: 39.499108,
      lng: -119.768108,
    },
    links_count: 56,
    objectID: "3807",
    uniqueID: "5bbe7922-d273-44c2-ae5f-d48bb10ee619",
  },
  {
    name: "Gerald R Ford Intl",
    city: "Grand Rapids",
    country: "United States",
    iata_code: "GRR",
    _geoloc: {
      lat: 42.880833,
      lng: -85.522806,
    },
    links_count: 56,
    objectID: "3685",
    uniqueID: "92f52138-bb8b-4bee-8d33-ff0fd8513a73",
  },
  {
    name: "Gold Coast",
    city: "Coolangatta",
    country: "Australia",
    iata_code: "OOL",
    _geoloc: {
      lat: -28.164444,
      lng: 153.504722,
    },
    links_count: 56,
    objectID: "3321",
    uniqueID: "473e272c-c59c-491f-b142-3b71363e181e",
  },
  {
    name: "Kazan",
    city: "Kazan",
    country: "Russia",
    iata_code: "KZN",
    _geoloc: {
      lat: 55.606186,
      lng: 49.278728,
    },
    links_count: 56,
    objectID: "2990",
    uniqueID: "ea9d20c6-daca-4ce9-8ad6-cef9ef06c862",
  },
  {
    name: "Rostov Na Donu",
    city: "Rostov",
    country: "Russia",
    iata_code: "ROV",
    _geoloc: {
      lat: 47.258208,
      lng: 39.818089,
    },
    links_count: 56,
    objectID: "2964",
    uniqueID: "9a0b62af-8ef3-44c4-9bb1-fcb882fc0a3b",
  },
  {
    name: "Port Vila Bauerfield",
    city: "Port-vila",
    country: "Vanuatu",
    iata_code: "VLI",
    _geoloc: {
      lat: -17.699325,
      lng: 168.319794,
    },
    links_count: 56,
    objectID: "1997",
    uniqueID: "30a1fd2e-0452-4cf3-ae4b-a03a938e443a",
  },
  {
    name: "Philip S W Goldson Intl",
    city: "Belize City",
    country: "Belize",
    iata_code: "BZE",
    _geoloc: {
      lat: 17.539144,
      lng: -88.308203,
    },
    links_count: 56,
    objectID: "1957",
    uniqueID: "721bcea0-cfc6-420e-bac4-d2e397ae2835",
  },
  {
    name: "Trapani Birgi",
    city: "Trapani",
    country: "Italy",
    iata_code: "TPS",
    _geoloc: {
      lat: 37.911403,
      lng: 12.487961,
    },
    links_count: 56,
    objectID: "1515",
    uniqueID: "69969fe3-c545-4c6d-bac9-28c6574125fb",
  },
  {
    name: "Inezgane",
    city: "Agadir",
    country: "Morocco",
    iata_code: "AGA",
    _geoloc: {
      lat: 30.381353,
      lng: -9.546311,
    },
    links_count: 56,
    objectID: "1064",
    uniqueID: "8eb6ac71-7322-4d62-9f70-eac3297ff970",
  },
  {
    name: "Tulsa Intl",
    city: "Tulsa",
    country: "United States",
    iata_code: "TUL",
    _geoloc: {
      lat: 36.198389,
      lng: -95.888111,
    },
    links_count: 55,
    objectID: "3855",
    uniqueID: "a1e69520-0632-4bd1-86f4-9ec8f605d010",
  },
  {
    name: "Theodore Francis Green State",
    city: "Providence",
    country: "United States",
    iata_code: "PVD",
    _geoloc: {
      lat: 41.732581,
      lng: -71.420383,
    },
    links_count: 55,
    objectID: "3641",
    uniqueID: "559f90cd-b9c6-4893-920e-08601c6bd13b",
  },
  {
    name: "Senou",
    city: "Bamako",
    country: "Mali",
    iata_code: "BKO",
    _geoloc: {
      lat: 12.533544,
      lng: -7.949944,
    },
    links_count: 55,
    objectID: "1044",
    uniqueID: "c5439080-cb17-4e8a-9e54-a895ca672a56",
  },
  {
    name: "Ndjili Intl",
    city: "Kinshasa",
    country: "Congo (Kinshasa)",
    iata_code: "FIH",
    _geoloc: {
      lat: -4.38575,
      lng: 15.444569,
    },
    links_count: 55,
    objectID: "1020",
    uniqueID: "95f52570-dae9-4b0c-be27-8859f5aa7ca1",
  },
  {
    name: "Ivato",
    city: "Antananarivo",
    country: "Madagascar",
    iata_code: "TNR",
    _geoloc: {
      lat: -18.79695,
      lng: 47.478806,
    },
    links_count: 54,
    objectID: "918",
    uniqueID: "684ea126-cc94-4d53-b81b-42d0096ca297",
  },
  {
    name: "Bromma",
    city: "Stockholm",
    country: "Sweden",
    iata_code: "BMA",
    _geoloc: {
      lat: 59.354372,
      lng: 17.94165,
    },
    links_count: 54,
    objectID: "738",
    uniqueID: "7182c0c8-c5ed-4de8-b0bf-9ef436e2ce1f",
  },
  {
    name: "Kurumoch",
    city: "Samara",
    country: "Russia",
    iata_code: "KUF",
    _geoloc: {
      lat: 53.5,
      lng: 50.15,
    },
    links_count: 54,
    objectID: "4118",
    uniqueID: "349a3785-33e4-4581-bf7b-e788f0b90c6e",
  },
  {
    name: "Hang Nadim",
    city: "Batam",
    country: "Indonesia",
    iata_code: "BTH",
    _geoloc: {
      lat: 1.121028,
      lng: 104.118753,
    },
    links_count: 54,
    objectID: "3903",
    uniqueID: "f1bbd5ed-3163-40c4-ad45-8a3602896f07",
  },
  {
    name: "Fairbanks Intl",
    city: "Fairbanks",
    country: "United States",
    iata_code: "FAI",
    _geoloc: {
      lat: 64.815114,
      lng: -147.856267,
    },
    links_count: 54,
    objectID: "3832",
    uniqueID: "9b1f64bf-1fc5-4e10-88aa-1a35f67dcc60",
  },
  {
    name: "Kabul Intl",
    city: "Kabul",
    country: "Afghanistan",
    iata_code: "KBL",
    _geoloc: {
      lat: 34.565853,
      lng: 69.212328,
    },
    links_count: 54,
    objectID: "2050",
    uniqueID: "b27affef-e339-4d99-b89c-79f6498d8246",
  },
  {
    name: "Warsaw Modlin",
    city: "Warsaw",
    country: "Poland",
    iata_code: "WMI",
    _geoloc: {
      lat: 52.451111,
      lng: 20.651667,
    },
    links_count: 52,
    objectID: "8414",
    uniqueID: "cdd3e206-9b3c-4952-8337-3a1d2f2a402d",
  },
  {
    name: "Langnes",
    city: "Tromso",
    country: "Norway",
    iata_code: "TOS",
    _geoloc: {
      lat: 69.683333,
      lng: 18.918919,
    },
    links_count: 52,
    objectID: "663",
    uniqueID: "a453f390-59e5-49d5-a0f6-2cf2841e433e",
  },
  {
    name: "Tunxi International Airport",
    city: "Huangshan",
    country: "China",
    iata_code: "TXN",
    _geoloc: {
      lat: 29.7333,
      lng: 118.256,
    },
    links_count: 52,
    objectID: "6387",
    uniqueID: "1977ec75-85e7-4538-8fcd-d18499b324ce",
  },
  {
    name: "Syracuse Hancock Intl",
    city: "Syracuse",
    country: "United States",
    iata_code: "SYR",
    _geoloc: {
      lat: 43.111187,
      lng: -76.106311,
    },
    links_count: 52,
    objectID: "3745",
    uniqueID: "dec4c687-c5d3-427a-bccb-54ba239bd48c",
  },
  {
    name: "Kuching Intl",
    city: "Kuching",
    country: "Malaysia",
    iata_code: "KCH",
    _geoloc: {
      lat: 1.484697,
      lng: 110.346933,
    },
    links_count: 52,
    objectID: "3263",
    uniqueID: "c6ca7428-e6c0-4c0d-8582-df43b8624548",
  },
  {
    name: "Sochi",
    city: "Sochi",
    country: "Russia",
    iata_code: "AER",
    _geoloc: {
      lat: 43.449928,
      lng: 39.956589,
    },
    links_count: 52,
    objectID: "2965",
    uniqueID: "a6249ae0-2f3c-41e4-90d2-3bd698d78e86",
  },
  {
    name: "Knevichi",
    city: "Vladivostok",
    country: "Russia",
    iata_code: "VVO",
    _geoloc: {
      lat: 43.398953,
      lng: 132.148017,
    },
    links_count: 52,
    objectID: "2934",
    uniqueID: "fef6586b-2429-4632-ab0c-1af0a953b594",
  },
  {
    name: "Jose Maria Cordova",
    city: "Rio Negro",
    country: "Colombia",
    iata_code: "MDE",
    _geoloc: {
      lat: 6.164536,
      lng: -75.423119,
    },
    links_count: 52,
    objectID: "2745",
    uniqueID: "8b624f27-ff3c-4a21-a31e-0abfdbd85cb9",
  },
  {
    name: "Zadar",
    city: "Zadar",
    country: "Croatia",
    iata_code: "ZAD",
    _geoloc: {
      lat: 44.108269,
      lng: 15.346697,
    },
    links_count: 52,
    objectID: "1209",
    uniqueID: "822b805f-2f4d-4c6f-bb4b-1fb235a9bf2c",
  },
  {
    name: "Maya Maya",
    city: "Brazzaville",
    country: "Congo (Brazzaville)",
    iata_code: "BZV",
    _geoloc: {
      lat: -4.2517,
      lng: 15.253031,
    },
    links_count: 51,
    objectID: "883",
    uniqueID: "f2a4844c-142c-4424-9c1e-3b23af037274",
  },
  {
    name: "Piedmont Triad",
    city: "Greensboro",
    country: "United States",
    iata_code: "GSO",
    _geoloc: {
      lat: 36.09775,
      lng: -79.937306,
    },
    links_count: 51,
    objectID: "4008",
    uniqueID: "1255d0c1-bdd9-41ac-970b-e36b868db752",
  },
  {
    name: "Palm Springs Intl",
    city: "Palm Springs",
    country: "United States",
    iata_code: "PSP",
    _geoloc: {
      lat: 33.829667,
      lng: -116.506694,
    },
    links_count: 51,
    objectID: "3839",
    uniqueID: "f5c69b38-52c5-4741-a1cc-7e6530858499",
  },
  {
    name: "El Alto Intl",
    city: "La Paz",
    country: "Bolivia",
    iata_code: "LPB",
    _geoloc: {
      lat: -16.513339,
      lng: -68.192256,
    },
    links_count: 51,
    objectID: "2762",
    uniqueID: "6452f022-c2e5-41ae-960f-e14cb4053e44",
  },
  {
    name: "Owen Roberts Intl",
    city: "Georgetown",
    country: "Cayman Islands",
    iata_code: "GCM",
    _geoloc: {
      lat: 19.292778,
      lng: -81.35775,
    },
    links_count: 51,
    objectID: "1926",
    uniqueID: "c77e088d-7640-4227-9855-8b71a23ba003",
  },
  {
    name: "Ordos Ejin Horo",
    city: "Dongsheng",
    country: "China",
    iata_code: "DSN",
    _geoloc: {
      lat: 39.85,
      lng: 110.033,
    },
    links_count: 50,
    objectID: "6434",
    uniqueID: "54817b53-7ecd-49c1-a30a-957627be66e3",
  },
  {
    name: "Ontario Intl",
    city: "Ontario",
    country: "United States",
    iata_code: "ONT",
    _geoloc: {
      lat: 34.056,
      lng: -117.601194,
    },
    links_count: 50,
    objectID: "3734",
    uniqueID: "a72d2d2b-7c95-4431-9146-1760819423e8",
  },
  {
    name: "Wattay Intl",
    city: "Vientiane",
    country: "Laos",
    iata_code: "VTE",
    _geoloc: {
      lat: 17.988322,
      lng: 102.563256,
    },
    links_count: 50,
    objectID: "3120",
    uniqueID: "4eb1d73e-abc7-494a-ac6a-4fa7241ca3ea",
  },
  {
    name: "Lamezia Terme",
    city: "Lamezia",
    country: "Italy",
    iata_code: "SUF",
    _geoloc: {
      lat: 38.905394,
      lng: 16.242269,
    },
    links_count: 50,
    objectID: "1508",
    uniqueID: "049f1493-e420-4e65-93c2-65662aed0229",
  },
  {
    name: "Benina",
    city: "Benghazi",
    country: "Libya",
    iata_code: "BEN",
    _geoloc: {
      lat: 32.096786,
      lng: 20.269472,
    },
    links_count: 50,
    objectID: "1154",
    uniqueID: "7fb63736-9d96-4e23-b65f-73a72d69f8ed",
  },
  {
    name: "Exeter",
    city: "Exeter",
    country: "United Kingdom",
    iata_code: "EXT",
    _geoloc: {
      lat: 50.734444,
      lng: -3.413889,
    },
    links_count: 49,
    objectID: "552",
    uniqueID: "c17a1f0f-8e4f-462f-aa30-629c995741b6",
  },
  {
    name: "Nome",
    city: "Nome",
    country: "United States",
    iata_code: "OME",
    _geoloc: {
      lat: 64.512203,
      lng: -165.445247,
    },
    links_count: 49,
    objectID: "3615",
    uniqueID: "90e5098a-31e1-4768-bf29-195f63987402",
  },
  {
    name: "Leon M Ba",
    city: "Libreville",
    country: "Gabon",
    iata_code: "LBV",
    _geoloc: {
      lat: 0.4586,
      lng: 9.412283,
    },
    links_count: 48,
    objectID: "970",
    uniqueID: "2122fd49-00e4-4c40-9908-5620db65e661",
  },
  {
    name: "Yichang Airport",
    city: "Yichang",
    country: "China",
    iata_code: "YIH",
    _geoloc: {
      lat: 30.671,
      lng: 111.441,
    },
    links_count: 48,
    objectID: "6361",
    uniqueID: "3b1cf4b4-545f-4972-9baf-8c28ad8d31b7",
  },
  {
    name: "NW Arkansas Regional",
    city: "Bentonville",
    country: "United States",
    iata_code: "XNA",
    _geoloc: {
      lat: 36.2818694,
      lng: -94.3068111,
    },
    links_count: 48,
    objectID: "4356",
    uniqueID: "f2d669c8-b2fc-40f2-8eab-15d5c0a78078",
  },
  {
    name: "Honiara International",
    city: "Honiara",
    country: "Solomon Islands",
    iata_code: "HIR",
    _geoloc: {
      lat: -9.428,
      lng: 160.054789,
    },
    links_count: 48,
    objectID: "4074",
    uniqueID: "89b4f9f6-82ab-4a8b-bc7d-6ccedb50fe6d",
  },
  {
    name: "Jinghong",
    city: "Jinghong",
    country: "China",
    iata_code: "JHG",
    _geoloc: {
      lat: 21.973914,
      lng: 100.759611,
    },
    links_count: 48,
    objectID: "4055",
    uniqueID: "700c1ccd-01e2-4798-9e08-a1a31070d3d3",
  },
  {
    name: "Miri",
    city: "Miri",
    country: "Malaysia",
    iata_code: "MYY",
    _geoloc: {
      lat: 4.322014,
      lng: 113.986806,
    },
    links_count: 48,
    objectID: "3266",
    uniqueID: "949242b4-3281-4dd2-925e-79ac5820b71b",
  },
  {
    name: "Viru Viru Intl",
    city: "Santa Cruz",
    country: "Bolivia",
    iata_code: "VVI",
    _geoloc: {
      lat: -17.644756,
      lng: -63.135364,
    },
    links_count: 48,
    objectID: "2771",
    uniqueID: "71b081d0-3765-42c7-a16f-b276ce1f1f6a",
  },
  {
    name: "Ahwaz",
    city: "Ahwaz",
    country: "Iran",
    iata_code: "AWZ",
    _geoloc: {
      lat: 31.337431,
      lng: 48.76195,
    },
    links_count: 48,
    objectID: "2104",
    uniqueID: "09220159-60be-4d7a-8e85-14dd18c351b9",
  },
  {
    name: "Toussaint Louverture Intl",
    city: "Port-au-prince",
    country: "Haiti",
    iata_code: "PAP",
    _geoloc: {
      lat: 18.58005,
      lng: -72.292542,
    },
    links_count: 48,
    objectID: "1897",
    uniqueID: "71350cbd-36b8-46c3-a88e-dda81ad27dfb",
  },
  {
    name: "General Ignacio P Garcia Intl",
    city: "Hermosillo",
    country: "Mexico",
    iata_code: "HMO",
    _geoloc: {
      lat: 29.095858,
      lng: -111.047858,
    },
    links_count: 48,
    objectID: "1807",
    uniqueID: "df4e542a-740d-49d5-8cb8-60bcb54c8dbb",
  },
  {
    name: "Skopje",
    city: "Skopje",
    country: "Macedonia",
    iata_code: "SKP",
    _geoloc: {
      lat: 41.961622,
      lng: 21.621381,
    },
    links_count: 48,
    objectID: "1737",
    uniqueID: "b3effc36-25e7-4882-8def-489df443c3e6",
  },
  {
    name: "Mediterranee",
    city: "Montpellier",
    country: "France",
    iata_code: "MPL",
    _geoloc: {
      lat: 43.576194,
      lng: 3.963014,
    },
    links_count: 48,
    objectID: "1359",
    uniqueID: "e68569e9-0f7b-4446-b91d-cee011599e7b",
  },
  {
    name: "Rankin Inlet",
    city: "Rankin Inlet",
    country: "Canada",
    iata_code: "YRT",
    _geoloc: {
      lat: 62.81139,
      lng: -92.115833,
    },
    links_count: 48,
    objectID: "132",
    uniqueID: "5e4ca2f4-684c-48a4-bd69-15aababc0434",
  },
  {
    name: "El Paso Intl",
    city: "El Paso",
    country: "United States",
    iata_code: "ELP",
    _geoloc: {
      lat: 31.80725,
      lng: -106.377583,
    },
    links_count: 47,
    objectID: "3559",
    uniqueID: "5ee27987-937e-40e9-ac56-f6283426ec51",
  },
  {
    name: "Ponta Delgada",
    city: "Ponta Delgada",
    country: "Portugal",
    iata_code: "PDL",
    _geoloc: {
      lat: 37.741184,
      lng: -25.69787,
    },
    links_count: 47,
    objectID: "1633",
    uniqueID: "cadc9cb0-d83e-42e7-8ba7-4d0c503b1cda",
  },
  {
    name: "Allgau",
    city: "Memmingen",
    country: "Germany",
    iata_code: "FMM",
    _geoloc: {
      lat: 47.988758,
      lng: 10.2395,
    },
    links_count: 46,
    objectID: "3986",
    uniqueID: "cf86d3a4-b082-4040-8b41-ad1e1e15ec97",
  },
  {
    name: "Savannah Hilton Head Intl",
    city: "Savannah",
    country: "United States",
    iata_code: "SAV",
    _geoloc: {
      lat: 32.127583,
      lng: -81.202139,
    },
    links_count: 46,
    objectID: "3613",
    uniqueID: "aa579b7b-cb5d-4984-9e8e-01b6ea37f25c",
  },
  {
    name: "Westchester Co",
    city: "White Plains",
    country: "United States",
    iata_code: "HPN",
    _geoloc: {
      lat: 41.066959,
      lng: -73.707575,
    },
    links_count: 46,
    objectID: "3589",
    uniqueID: "b0252abe-2a1c-425a-a42c-31c2dd90dbe1",
  },
  {
    name: "Brunei Intl",
    city: "Bandar Seri Begawan",
    country: "Brunei",
    iata_code: "BWN",
    _geoloc: {
      lat: 4.9442,
      lng: 114.928353,
    },
    links_count: 46,
    objectID: "3272",
    uniqueID: "5d29e1ed-54e1-4360-88b6-f3a4a8e5c87d",
  },
  {
    name: "Ufa",
    city: "Ufa",
    country: "Russia",
    iata_code: "UFA",
    _geoloc: {
      lat: 54.557511,
      lng: 55.874417,
    },
    links_count: 46,
    objectID: "2992",
    uniqueID: "c5445415-1f3e-48dd-932d-23a7a8aefd44",
  },
  {
    name: "Le Lamentin",
    city: "Fort-de-france",
    country: "Martinique",
    iata_code: "FDF",
    _geoloc: {
      lat: 14.591033,
      lng: -61.003175,
    },
    links_count: 46,
    objectID: "2878",
    uniqueID: "e93d8d14-df4c-46b3-bed1-9e9409e90f44",
  },
  {
    name: "Grantley Adams Intl",
    city: "Bridgetown",
    country: "Barbados",
    iata_code: "BGI",
    _geoloc: {
      lat: 13.074603,
      lng: -59.492456,
    },
    links_count: 46,
    objectID: "2875",
    uniqueID: "97ae10ed-58f2-4500-bf5d-1eba83ff0ada",
  },
  {
    name: "Carrasco Intl",
    city: "Montevideo",
    country: "Uruguay",
    iata_code: "MVD",
    _geoloc: {
      lat: -34.838417,
      lng: -56.030806,
    },
    links_count: 46,
    objectID: "2816",
    uniqueID: "bb449f70-5798-4f07-8cfc-3d6613aece11",
  },
  {
    name: "La Mesa Intl",
    city: "San Pedro Sula",
    country: "Honduras",
    iata_code: "SAP",
    _geoloc: {
      lat: 15.452639,
      lng: -87.923556,
    },
    links_count: 46,
    objectID: "1772",
    uniqueID: "7906660c-e9a2-4937-9b41-eb702d151093",
  },
  {
    name: "Roschino",
    city: "Tyumen",
    country: "Russia",
    iata_code: "TJM",
    _geoloc: {
      lat: 57.189567,
      lng: 65.3243,
    },
    links_count: 45,
    objectID: "4111",
    uniqueID: "861c31f8-2255-420d-a2ee-73faff6272d5",
  },
  {
    name: "Ralph Wien Mem",
    city: "Kotzebue",
    country: "United States",
    iata_code: "OTZ",
    _geoloc: {
      lat: 66.884678,
      lng: -162.59855,
    },
    links_count: 45,
    objectID: "3693",
    uniqueID: "c6f750ea-3d6d-444e-b2fa-d1399f1a1667",
  },
  {
    name: "Daniel Oduber Quiros Intl",
    city: "Liberia",
    country: "Costa Rica",
    iata_code: "LIR",
    _geoloc: {
      lat: 10.593289,
      lng: -85.544408,
    },
    links_count: 45,
    objectID: "1881",
    uniqueID: "22e48600-9b6d-445d-aa44-51dab14f96ed",
  },
  {
    name: "Akron Canton Regional Airport",
    city: "Akron",
    country: "United States",
    iata_code: "CAK",
    _geoloc: {
      lat: 40.9160833,
      lng: -81.4421944,
    },
    links_count: 44,
    objectID: "4112",
    uniqueID: "9485b0e1-7ef0-4fe7-b96d-4aa0f6278a9d",
  },
  {
    name: "Aden Intl",
    city: "Aden",
    country: "Yemen",
    iata_code: "ADE",
    _geoloc: {
      lat: 12.829542,
      lng: 45.028792,
    },
    links_count: 44,
    objectID: "3977",
    uniqueID: "44f9a74c-395e-460c-83f7-bea50a4d1ffa",
  },
  {
    name: "Ketchikan Intl",
    city: "Ketchikan",
    country: "United States",
    iata_code: "KTN",
    _geoloc: {
      lat: 55.355556,
      lng: -131.71375,
    },
    links_count: 44,
    objectID: "3808",
    uniqueID: "9b370f05-d9d4-4826-a860-2732aa82aba6",
  },
  {
    name: "Dresden",
    city: "Dresden",
    country: "Germany",
    iata_code: "DRS",
    _geoloc: {
      lat: 51.132767,
      lng: 13.767161,
    },
    links_count: 44,
    objectID: "338",
    uniqueID: "0ba2825c-765b-4c98-b660-b8171cfdce71",
  },
  {
    name: "Mineralnyye Vody",
    city: "Mineralnye Vody",
    country: "Russia",
    iata_code: "MRV",
    _geoloc: {
      lat: 44.225072,
      lng: 43.081889,
    },
    links_count: 44,
    objectID: "2962",
    uniqueID: "50765ac0-ffa7-46b5-a6f5-174b9825c69f",
  },
  {
    name: "Zhuliany Intl",
    city: "Kiev",
    country: "Ukraine",
    iata_code: "IEV",
    _geoloc: {
      lat: 50.401694,
      lng: 30.449697,
    },
    links_count: 44,
    objectID: "2944",
    uniqueID: "fef22a53-d8c8-4883-94c0-635a157099a2",
  },
  {
    name: "Sept Iles",
    city: "Sept-iles",
    country: "Canada",
    iata_code: "YZV",
    _geoloc: {
      lat: 50.223333,
      lng: -66.265556,
    },
    links_count: 44,
    objectID: "202",
    uniqueID: "33795d05-08eb-41a0-bfc6-ea85476fae53",
  },
  {
    name: "Casale",
    city: "Brindisi",
    country: "Italy",
    iata_code: "BDS",
    _geoloc: {
      lat: 40.657633,
      lng: 17.947033,
    },
    links_count: 44,
    objectID: "1506",
    uniqueID: "54005d5b-f1d6-43e6-99b0-5b5464b388b6",
  },
  {
    name: "Freetown Lungi",
    city: "Freetown",
    country: "Sierra Leone",
    iata_code: "FNA",
    _geoloc: {
      lat: 8.616444,
      lng: -13.195489,
    },
    links_count: 44,
    objectID: "1059",
    uniqueID: "dd6f1503-af1b-42fa-b088-b61b60ff695e",
  },
  {
    name: "Mianyang Airport",
    city: "Mianyang",
    country: "China",
    iata_code: "MIG",
    _geoloc: {
      lat: 31.4281,
      lng: 104.741,
    },
    links_count: 43,
    objectID: "6400",
    uniqueID: "05a012a5-d117-4211-8f1a-4f2ae8d69adc",
  },
  {
    name: "Santa Genoveva",
    city: "Goiania",
    country: "Brazil",
    iata_code: "GYN",
    _geoloc: {
      lat: -16.632033,
      lng: -49.220686,
    },
    links_count: 43,
    objectID: "2562",
    uniqueID: "f344c85e-b445-450c-8f86-15816227744d",
  },
  {
    name: "St Johns Intl",
    city: "St. John's",
    country: "Canada",
    iata_code: "YYT",
    _geoloc: {
      lat: 47.61861,
      lng: -52.751945,
    },
    links_count: 43,
    objectID: "189",
    uniqueID: "effe0c3c-1a48-40f0-93d1-f87227d8810c",
  },
  {
    name: "Jerez",
    city: "Jerez",
    country: "Spain",
    iata_code: "XRY",
    _geoloc: {
      lat: 36.744622,
      lng: -6.060111,
    },
    links_count: 43,
    objectID: "1226",
    uniqueID: "95024a73-43ac-438f-bfe6-8e25220a0c07",
  },
  {
    name: "Durban Intl",
    city: "Durban",
    country: "South Africa",
    iata_code: "DUR",
    _geoloc: {
      lat: -29.970089,
      lng: 30.950519,
    },
    links_count: 42,
    objectID: "799",
    uniqueID: "5ed39630-7728-4ed8-bba9-ebeb41be4eb6",
  },
  {
    name: "Save",
    city: "Gothenborg",
    country: "Sweden",
    iata_code: "GSE",
    _geoloc: {
      lat: 57.774722,
      lng: 11.870372,
    },
    links_count: 42,
    objectID: "691",
    uniqueID: "22936ff2-6a45-4b2e-944b-fe95d570bc44",
  },
  {
    name: "Lawica",
    city: "Poznan",
    country: "Poland",
    iata_code: "POZ",
    _geoloc: {
      lat: 52.421031,
      lng: 16.826325,
    },
    links_count: 42,
    objectID: "674",
    uniqueID: "d56846e4-1d91-408a-a0f6-3a6e21ba81cf",
  },
  {
    name: "Dayong Airport",
    city: "Dayong",
    country: "China",
    iata_code: "DYG",
    _geoloc: {
      lat: 29.1028,
      lng: 110.443,
    },
    links_count: 42,
    objectID: "6353",
    uniqueID: "1dda648d-0eba-424a-8a10-a7887f65c5fb",
  },
  {
    name: "Prestwick",
    city: "Prestwick",
    country: "United Kingdom",
    iata_code: "PIK",
    _geoloc: {
      lat: 55.509444,
      lng: -4.586667,
    },
    links_count: 42,
    objectID: "537",
    uniqueID: "4b11fb93-0aee-4509-9265-cd3e31cfbbc2",
  },
  {
    name: "Belfast City",
    city: "Belfast",
    country: "United Kingdom",
    iata_code: "BHD",
    _geoloc: {
      lat: 54.618056,
      lng: -5.8725,
    },
    links_count: 42,
    objectID: "467",
    uniqueID: "76967996-4222-4e9c-a2e4-82cd68d0c4a3",
  },
  {
    name: "Aktau",
    city: "Aktau",
    country: "Kazakhstan",
    iata_code: "SCO",
    _geoloc: {
      lat: 43.86005,
      lng: 51.091978,
    },
    links_count: 42,
    objectID: "4367",
    uniqueID: "f677fc04-6d20-4343-9dfc-c705804a27f6",
  },
  {
    name: "Husein Sastranegara",
    city: "Bandung",
    country: "Indonesia",
    iata_code: "BDO",
    _geoloc: {
      lat: -6.900625,
      lng: 107.576294,
    },
    links_count: 42,
    objectID: "3896",
    uniqueID: "fa376c65-e028-46c2-a95b-f22115e2fadb",
  },
  {
    name: "Lihue",
    city: "Lihue",
    country: "United States",
    iata_code: "LIH",
    _geoloc: {
      lat: 21.975983,
      lng: -159.338958,
    },
    links_count: 42,
    objectID: "3602",
    uniqueID: "5077617b-2ab8-499f-822f-611a2ab224f2",
  },
  {
    name: "Pensacola Rgnl",
    city: "Pensacola",
    country: "United States",
    iata_code: "PNS",
    _geoloc: {
      lat: 30.473425,
      lng: -87.186611,
    },
    links_count: 42,
    objectID: "3564",
    uniqueID: "4e8f276a-6c45-46d0-97a0-9cf61309b9ab",
  },
  {
    name: "Sam Ratulangi",
    city: "Manado",
    country: "Indonesia",
    iata_code: "MDC",
    _geoloc: {
      lat: 1.549447,
      lng: 124.925878,
    },
    links_count: 42,
    objectID: "3250",
    uniqueID: "96faafa2-d5af-4eea-9df0-00733684b782",
  },
  {
    name: "Hercilio Luz",
    city: "Florianopolis",
    country: "Brazil",
    iata_code: "FLN",
    _geoloc: {
      lat: -27.670489,
      lng: -48.547181,
    },
    links_count: 42,
    objectID: "2555",
    uniqueID: "e94a079d-37d7-489b-afbb-70828733c8ed",
  },
  {
    name: "Pristina",
    city: "Pristina",
    country: "Serbia",
    iata_code: "PRN",
    _geoloc: {
      lat: 42.572778,
      lng: 21.035833,
    },
    links_count: 42,
    objectID: "1742",
    uniqueID: "953d6c31-5f7a-4553-92f7-b4650c33ecb7",
  },
  {
    name: "Cluj Napoca",
    city: "Cluj-napoca",
    country: "Romania",
    iata_code: "CLJ",
    _geoloc: {
      lat: 46.785167,
      lng: 23.686167,
    },
    links_count: 42,
    objectID: "1652",
    uniqueID: "7cab7a4a-9c74-4dad-ab0e-3464ce670449",
  },
  {
    name: "Aalborg",
    city: "Aalborg",
    country: "Denmark",
    iata_code: "AAL",
    _geoloc: {
      lat: 57.092789,
      lng: 9.849164,
    },
    links_count: 41,
    objectID: "628",
    uniqueID: "3a31a7b4-3b5a-43f4-a257-753aca28706d",
  },
  {
    name: "Kona Intl At Keahole",
    city: "Kona",
    country: "United States",
    iata_code: "KOA",
    _geoloc: {
      lat: 19.738767,
      lng: -156.045631,
    },
    links_count: 41,
    objectID: "3514",
    uniqueID: "ce8d890a-a314-43d5-ae2a-4f9ead1e40fc",
  },
  {
    name: "Boise Air Terminal",
    city: "Boise",
    country: "United States",
    iata_code: "BOI",
    _geoloc: {
      lat: 43.564361,
      lng: -116.222861,
    },
    links_count: 41,
    objectID: "3495",
    uniqueID: "1921bc58-dc39-4783-9317-d76711b1cbd2",
  },
  {
    name: "Chinggis Khaan Intl",
    city: "Ulan Bator",
    country: "Mongolia",
    iata_code: "ULN",
    _geoloc: {
      lat: 47.843056,
      lng: 106.766639,
    },
    links_count: 41,
    objectID: "3380",
    uniqueID: "a3f6372d-f300-4a2e-9dbe-4d1bfcf0616e",
  },
  {
    name: "Gnassingbe Eyadema Intl",
    city: "Lome",
    country: "Togo",
    iata_code: "LFW",
    _geoloc: {
      lat: 6.165611,
      lng: 1.254511,
    },
    links_count: 41,
    objectID: "298",
    uniqueID: "24249715-e8b6-492b-bb23-3686bc027f43",
  },
  {
    name: "Kilimanjaro Intl",
    city: "Kilimanjaro",
    country: "Tanzania",
    iata_code: "JRO",
    _geoloc: {
      lat: -3.429406,
      lng: 37.074461,
    },
    links_count: 41,
    objectID: "1180",
    uniqueID: "1c0b9177-8a05-4e3a-90ba-569152e21205",
  },
  {
    name: "St Denis Gillot",
    city: "St.-denis",
    country: "Reunion",
    iata_code: "RUN",
    _geoloc: {
      lat: -20.8871,
      lng: 55.510308,
    },
    links_count: 40,
    objectID: "916",
    uniqueID: "24599ba0-6b72-4eeb-b8d4-5aeb8462b2e0",
  },
  {
    name: "Beihai Airport",
    city: "Beihai",
    country: "China",
    iata_code: "BHY",
    _geoloc: {
      lat: 21.5394,
      lng: 109.294,
    },
    links_count: 40,
    objectID: "6351",
    uniqueID: "6248989b-658b-4a01-9f23-52221aa0fbb8",
  },
  {
    name: "Baden Airpark",
    city: "Karlsruhe/Baden-Baden",
    country: "Germany",
    iata_code: "FKB",
    _geoloc: {
      lat: 48.7793,
      lng: 8.08048,
    },
    links_count: 40,
    objectID: "4166",
    uniqueID: "0359b009-9fa8-4c49-869d-0e1dc3405c51",
  },
  {
    name: "Esfahan Shahid Beheshti Intl",
    city: "Isfahan",
    country: "Iran",
    iata_code: "IFN",
    _geoloc: {
      lat: 32.750836,
      lng: 51.861267,
    },
    links_count: 40,
    objectID: "4150",
    uniqueID: "1c6026de-3faa-46a3-addc-201c0eb8c1e7",
  },
  {
    name: "Xuzhou Guanyin Airport",
    city: "Xuzhou",
    country: "China",
    iata_code: "XUZ",
    _geoloc: {
      lat: 34.16,
      lng: 117.11,
    },
    links_count: 40,
    objectID: "4149",
    uniqueID: "ddb13b1d-4b3e-484e-99cb-577e701f6ab7",
  },
  {
    name: "Ambouli International Airport",
    city: "Djibouti",
    country: "Djibouti",
    iata_code: "JIB",
    _geoloc: {
      lat: 11.5472,
      lng: 43.1594,
    },
    links_count: 40,
    objectID: "4119",
    uniqueID: "3ae868d7-5019-4e8e-95a8-bc4891cdc5e9",
  },
  {
    name: "Blue Grass",
    city: "Lexington KY",
    country: "United States",
    iata_code: "LEX",
    _geoloc: {
      lat: 38.0365,
      lng: -84.605889,
    },
    links_count: 40,
    objectID: "4017",
    uniqueID: "16ab2841-1c19-47fd-8e6b-a49b9bd02a60",
  },
  {
    name: "Manchester Regional Airport",
    city: "Manchester NH",
    country: "United States",
    iata_code: "MHT",
    _geoloc: {
      lat: 42.932556,
      lng: -71.435667,
    },
    links_count: 40,
    objectID: "4011",
    uniqueID: "4ef80bb3-f123-4034-9bd2-3a996f535333",
  },
  {
    name: "Fresno Yosemite Intl",
    city: "Fresno",
    country: "United States",
    iata_code: "FAT",
    _geoloc: {
      lat: 36.776194,
      lng: -119.71814,
    },
    links_count: 40,
    objectID: "3687",
    uniqueID: "54a90fb9-aabe-4ab2-8d93-27f60026a071",
  },
  {
    name: "Juneau Intl",
    city: "Juneau",
    country: "United States",
    iata_code: "JNU",
    _geoloc: {
      lat: 58.354972,
      lng: -134.576278,
    },
    links_count: 40,
    objectID: "3492",
    uniqueID: "537d9b3b-4799-4d6a-b2d2-2903cbc28177",
  },
  {
    name: "Wichita Mid Continent",
    city: "Wichita",
    country: "United States",
    iata_code: "ICT",
    _geoloc: {
      lat: 37.649944,
      lng: -97.433056,
    },
    links_count: 40,
    objectID: "3457",
    uniqueID: "1b7dcf68-701e-4ec4-a48e-8d9cc7f3ad11",
  },
  {
    name: "Balandino",
    city: "Chelyabinsk",
    country: "Russia",
    iata_code: "CEK",
    _geoloc: {
      lat: 55.305836,
      lng: 61.503333,
    },
    links_count: 40,
    objectID: "2968",
    uniqueID: "93c86f0d-244f-4244-b9c1-30601c85f9fd",
  },
  {
    name: "M R Stefanik",
    city: "Bratislava",
    country: "Slovakia",
    iata_code: "BTS",
    _geoloc: {
      lat: 48.170167,
      lng: 17.212667,
    },
    links_count: 40,
    objectID: "1745",
    uniqueID: "5b932f1f-5504-4a61-b85c-a841285dfef8",
  },
  {
    name: "Genova Sestri",
    city: "Genoa",
    country: "Italy",
    iata_code: "GOA",
    _geoloc: {
      lat: 44.413333,
      lng: 8.8375,
    },
    links_count: 40,
    objectID: "1528",
    uniqueID: "19ed6e4e-f1e1-447d-af59-f156f34c2436",
  },
  {
    name: "Saint Catherine",
    city: "Calvi",
    country: "France",
    iata_code: "CLY",
    _geoloc: {
      lat: 42.530753,
      lng: 8.793189,
    },
    links_count: 40,
    objectID: "1322",
    uniqueID: "930e7b7e-e55f-4d66-8670-84a6985e4218",
  },
  {
    name: "Murcia San Javier",
    city: "Murcia",
    country: "Spain",
    iata_code: "MJV",
    _geoloc: {
      lat: 37.774972,
      lng: -0.812389,
    },
    links_count: 40,
    objectID: "1227",
    uniqueID: "48a4d106-c66a-4b5b-8868-3aa3b9a68ef1",
  },
  {
    name: "Harrisburg Intl",
    city: "Harrisburg",
    country: "United States",
    iata_code: "MDT",
    _geoloc: {
      lat: 40.193494,
      lng: -76.763403,
    },
    links_count: 39,
    objectID: "3542",
    uniqueID: "5f4e36e2-a4aa-4548-9f11-60c8367431cd",
  },
  {
    name: "Del Caribe Intl Gen Santiago Marino",
    city: "Porlamar",
    country: "Venezuela",
    iata_code: "PMV",
    _geoloc: {
      lat: 10.912926,
      lng: -63.967581,
    },
    links_count: 39,
    objectID: "2850",
    uniqueID: "ca0bee34-cae0-476a-9e1f-17fa1740222f",
  },
  {
    name: "Reus",
    city: "Reus",
    country: "Spain",
    iata_code: "REU",
    _geoloc: {
      lat: 41.147392,
      lng: 1.167172,
    },
    links_count: 39,
    objectID: "1236",
    uniqueID: "da38ca72-129d-47e1-be45-5f350829b9d1",
  },
  {
    name: "Zhanjiang Airport",
    city: "Zhanjiang",
    country: "China",
    iata_code: "ZHA",
    _geoloc: {
      lat: 21.2144,
      lng: 110.358,
    },
    links_count: 38,
    objectID: "6357",
    uniqueID: "7a803090-6a4f-4403-9ecb-f32b31ee887f",
  },
  {
    name: "Bournemouth",
    city: "Bournemouth",
    country: "United Kingdom",
    iata_code: "BOH",
    _geoloc: {
      lat: 50.78,
      lng: -1.8425,
    },
    links_count: 38,
    objectID: "494",
    uniqueID: "b81db041-428a-4aed-96f8-9294f1ba98ea",
  },
  {
    name: "Yaounde Nsimalen",
    city: "Yaounde",
    country: "Cameroon",
    iata_code: "NSI",
    _geoloc: {
      lat: 3.722556,
      lng: 11.553269,
    },
    links_count: 38,
    objectID: "4161",
    uniqueID: "b00952b5-6d02-4604-9d91-6c4c88f62a9a",
  },
  {
    name: "Windhoek Hosea Kutako International Airport ",
    city: "Windhoek",
    country: "Namibia",
    iata_code: "WDH",
    _geoloc: {
      lat: -22.486667,
      lng: 17.4625,
    },
    links_count: 38,
    objectID: "4105",
    uniqueID: "dff34264-3639-4346-a449-e029914cdff1",
  },
  {
    name: "Kaunas Intl",
    city: "Kaunas",
    country: "Lithuania",
    iata_code: "KUN",
    _geoloc: {
      lat: 54.963919,
      lng: 24.084778,
    },
    links_count: 38,
    objectID: "3956",
    uniqueID: "56bf85a1-a82f-4611-ab0e-1dbd7d6ea60a",
  },
  {
    name: "Leite Lopes",
    city: "Ribeirao Preto",
    country: "Brazil",
    iata_code: "RAO",
    _geoloc: {
      lat: -21.134167,
      lng: -47.774189,
    },
    links_count: 38,
    objectID: "2613",
    uniqueID: "6aaf9a2c-b227-48d1-a8cd-4a18f976561a",
  },
  {
    name: "Bandar Abbass Intl",
    city: "Bandar Abbas",
    country: "Iran",
    iata_code: "BND",
    _geoloc: {
      lat: 27.218317,
      lng: 56.37785,
    },
    links_count: 38,
    objectID: "2134",
    uniqueID: "8ae7f9f2-af1d-40ef-9b00-68523003e7c1",
  },
  {
    name: "Graz",
    city: "Graz",
    country: "Austria",
    iata_code: "GRZ",
    _geoloc: {
      lat: 46.991067,
      lng: 15.439628,
    },
    links_count: 38,
    objectID: "1609",
    uniqueID: "a0ff0eb8-2b13-4934-85ce-af06db1f386f",
  },
  {
    name: "Santander",
    city: "Santander",
    country: "Spain",
    iata_code: "SDR",
    _geoloc: {
      lat: 43.427064,
      lng: -3.820006,
    },
    links_count: 38,
    objectID: "1251",
    uniqueID: "696b252b-a587-47d2-a1b0-e91f564b46d7",
  },
  {
    name: "Ibn Batouta",
    city: "Tanger",
    country: "Morocco",
    iata_code: "TNG",
    _geoloc: {
      lat: 35.726917,
      lng: -5.916889,
    },
    links_count: 38,
    objectID: "1080",
    uniqueID: "654b1812-8b2c-442f-959d-577d42f2bc0d",
  },
  {
    name: "Odesa Intl",
    city: "Odessa",
    country: "Ukraine",
    iata_code: "ODS",
    _geoloc: {
      lat: 46.426767,
      lng: 30.676464,
    },
    links_count: 37,
    objectID: "2947",
    uniqueID: "beb13994-ffef-4bfd-975b-1387a1d2970c",
  },
  {
    name: "Hewanorra Intl",
    city: "Hewandorra",
    country: "Saint Lucia",
    iata_code: "UVF",
    _geoloc: {
      lat: 13.733194,
      lng: -60.952597,
    },
    links_count: 37,
    objectID: "2894",
    uniqueID: "73c8ceaf-7ce3-4ba8-bec7-c59f67a30938",
  },
  {
    name: "Nairobi Wilson",
    city: "Nairobi",
    country: "Kenya",
    iata_code: "WIL",
    _geoloc: {
      lat: -1.321719,
      lng: 36.814833,
    },
    links_count: 37,
    objectID: "1147",
    uniqueID: "c5930e74-2d78-421c-ac70-9467f2abadd8",
  },
  {
    name: "Yangzhou Taizhou Airport",
    city: "Yangzhou",
    country: "China",
    iata_code: "YTY",
    _geoloc: {
      lat: 32.5617,
      lng: 119.715,
    },
    links_count: 36,
    objectID: "8876",
    uniqueID: "4556da5e-1e0b-44ab-8377-33b50b2c5702",
  },
  {
    name: "Pointe Noire",
    city: "Pointe-noire",
    country: "Congo (Brazzaville)",
    iata_code: "PNR",
    _geoloc: {
      lat: -4.816028,
      lng: 11.886597,
    },
    links_count: 36,
    objectID: "886",
    uniqueID: "ac9f42dc-a361-471b-82e0-e9f31c52b901",
  },
  {
    name: "Yiwu Airport",
    city: "Yiwu",
    country: "China",
    iata_code: "YIW",
    _geoloc: {
      lat: 29.3447,
      lng: 120.032,
    },
    links_count: 36,
    objectID: "6394",
    uniqueID: "a4f5f046-2cdc-458e-8471-bed4a0de3dbf",
  },
  {
    name: "Shubuling Airport",
    city: "Linyi",
    country: "China",
    iata_code: "LYI",
    _geoloc: {
      lat: 35.0461,
      lng: 118.412,
    },
    links_count: 36,
    objectID: "6385",
    uniqueID: "342350b8-ebca-4082-a1bd-db6cdf7f8ec3",
  },
  {
    name: "El Aroui Airport",
    city: "El Aroui",
    country: "Morocco",
    iata_code: "NDR",
    _geoloc: {
      lat: 34.9888,
      lng: -3.02821,
    },
    links_count: 36,
    objectID: "5673",
    uniqueID: "5f8c0b48-ef74-4570-89aa-420704bc913a",
  },
  {
    name: "Iqaluit",
    city: "Iqaluit",
    country: "Canada",
    iata_code: "YFB",
    _geoloc: {
      lat: 63.75639,
      lng: -68.555832,
    },
    links_count: 36,
    objectID: "55",
    uniqueID: "925f97a1-e997-481d-b0f2-912c1710f5c3",
  },
  {
    name: "Nantong Airport",
    city: "Nantong",
    country: "China",
    iata_code: "NTG",
    _geoloc: {
      lat: 32.0708,
      lng: 120.976,
    },
    links_count: 36,
    objectID: "5475",
    uniqueID: "54ba013f-d5da-4a54-a19e-cafc84cb3905",
  },
  {
    name: "Guernsey",
    city: "Guernsey",
    country: "Guernsey",
    iata_code: "GCI",
    _geoloc: {
      lat: 49.434956,
      lng: -2.601969,
    },
    links_count: 36,
    objectID: "498",
    uniqueID: "f5836648-9130-4ea9-8a88-0fb6062b7c78",
  },
  {
    name: "Nizhny Novgorod",
    city: "Nizhniy Novgorod",
    country: "Russia",
    iata_code: "GOJ",
    _geoloc: {
      lat: 56.230119,
      lng: 43.784042,
    },
    links_count: 36,
    objectID: "4274",
    uniqueID: "c3a2a995-c3f9-4e75-a5df-4633ffbb74c3",
  },
  {
    name: "Conakry",
    city: "Conakry",
    country: "Guinea",
    iata_code: "CKY",
    _geoloc: {
      lat: 9.576889,
      lng: -13.611961,
    },
    links_count: 36,
    objectID: "4162",
    uniqueID: "4ffb10c4-0b10-49ae-8d96-f96baaa66508",
  },
  {
    name: "Huntsville International Airport-Carl T Jones Field",
    city: "Huntsville",
    country: "United States",
    iata_code: "HSV",
    _geoloc: {
      lat: 34.6371944,
      lng: -86.7750556,
    },
    links_count: 36,
    objectID: "4113",
    uniqueID: "94db5e61-aa6f-4c4f-8958-c426add051eb",
  },
  {
    name: "Bob Hope",
    city: "Burbank",
    country: "United States",
    iata_code: "BUR",
    _geoloc: {
      lat: 34.200667,
      lng: -118.358667,
    },
    links_count: 36,
    objectID: "3644",
    uniqueID: "499ad3ee-1401-49ee-ad75-30eaad5d0282",
  },
  {
    name: "Long Beach",
    city: "Long Beach",
    country: "United States",
    iata_code: "LGB",
    _geoloc: {
      lat: 33.817722,
      lng: -118.151611,
    },
    links_count: 36,
    objectID: "3582",
    uniqueID: "28990750-d82e-4ca3-bd7a-c8472067c429",
  },
  {
    name: "Jackson Evers Intl",
    city: "Jackson",
    country: "United States",
    iata_code: "JAN",
    _geoloc: {
      lat: 32.311167,
      lng: -90.075889,
    },
    links_count: 36,
    objectID: "3580",
    uniqueID: "fd73991b-d735-4b02-8926-f3e9b64a726c",
  },
  {
    name: "Dongshan",
    city: "Hailar",
    country: "China",
    iata_code: "HLD",
    _geoloc: {
      lat: 49.204997,
      lng: 119.825,
    },
    links_count: 36,
    objectID: "3366",
    uniqueID: "d85fd024-9818-465e-b3de-f4f7e17ed6d8",
  },
  {
    name: "Sultan Ismail",
    city: "Johor Bahru",
    country: "Malaysia",
    iata_code: "JHB",
    _geoloc: {
      lat: 1.641308,
      lng: 103.669619,
    },
    links_count: 36,
    objectID: "3303",
    uniqueID: "22adc6cc-0cce-44c3-b234-d906cd62eae1",
  },
  {
    name: "Sultan Syarif Kasim Ii",
    city: "Pekanbaru",
    country: "Indonesia",
    iata_code: "PKU",
    _geoloc: {
      lat: 0.460786,
      lng: 101.444539,
    },
    links_count: 36,
    objectID: "3273",
    uniqueID: "ba682eff-e787-4a70-9657-448cf68d5461",
  },
  {
    name: "Samui",
    city: "Ko Samui",
    country: "Thailand",
    iata_code: "USM",
    _geoloc: {
      lat: 9.547794,
      lng: 100.062272,
    },
    links_count: 36,
    objectID: "3177",
    uniqueID: "cca1bca1-9994-49e5-8097-41046fcec7c1",
  },
  {
    name: "Lucknow",
    city: "Lucknow",
    country: "India",
    iata_code: "LKO",
    _geoloc: {
      lat: 26.760594,
      lng: 80.889339,
    },
    links_count: 36,
    objectID: "3105",
    uniqueID: "c62b6c52-1fd4-48d6-9fac-3ae7ba3d636a",
  },
  {
    name: "Vishakhapatnam",
    city: "Vishakhapatnam",
    country: "India",
    iata_code: "VTZ",
    _geoloc: {
      lat: 17.721167,
      lng: 83.224483,
    },
    links_count: 36,
    objectID: "3066",
    uniqueID: "3039710d-5a93-47c0-9b3f-bb23d5d7f87b",
  },
  {
    name: "Silvio Pettirossi Intl",
    city: "Asuncion",
    country: "Paraguay",
    iata_code: "ASU",
    _geoloc: {
      lat: -25.23985,
      lng: -57.519133,
    },
    links_count: 36,
    objectID: "2699",
    uniqueID: "b734c0b4-1c1e-4aca-ae7d-588de3d2eeb1",
  },
  {
    name: "Kalibo",
    city: "Kalibo",
    country: "Philippines",
    iata_code: "KLO",
    _geoloc: {
      lat: 11.679431,
      lng: 122.376294,
    },
    links_count: 36,
    objectID: "2430",
    uniqueID: "18561e37-11be-4718-8ac3-bb2f1efae26c",
  },
  {
    name: "Kagoshima",
    city: "Kagoshima",
    country: "Japan",
    iata_code: "KOJ",
    _geoloc: {
      lat: 31.803397,
      lng: 130.719408,
    },
    links_count: 36,
    objectID: "2307",
    uniqueID: "36144b93-431b-4025-9d5e-3df8dd68457c",
  },
  {
    name: "Toncontin Intl",
    city: "Tegucigalpa",
    country: "Honduras",
    iata_code: "TGU",
    _geoloc: {
      lat: 14.060883,
      lng: -87.217197,
    },
    links_count: 36,
    objectID: "1776",
    uniqueID: "f8768cc8-ad14-47bd-b748-31dcf6200e61",
  },
  {
    name: "Almeria",
    city: "Almeria",
    country: "Spain",
    iata_code: "LEI",
    _geoloc: {
      lat: 36.843936,
      lng: -2.370097,
    },
    links_count: 36,
    objectID: "1213",
    uniqueID: "ca2ece9d-d9e1-4221-91af-e737357adbab",
  },
  {
    name: "Saiss",
    city: "Fes",
    country: "Morocco",
    iata_code: "FEZ",
    _geoloc: {
      lat: 33.927261,
      lng: -4.977958,
    },
    links_count: 36,
    objectID: "1066",
    uniqueID: "7f3578b1-0568-40fe-b642-73ae874e8470",
  },
  {
    name: "Charlotte County-Punta Gorda Airport",
    city: "Punta Gorda",
    country: "United States",
    iata_code: "PGD",
    _geoloc: {
      lat: 26.919722,
      lng: -81.990556,
    },
    links_count: 35,
    objectID: "7056",
    uniqueID: "46aeaa97-64f8-4201-a073-8cc1bbb69117",
  },
  {
    name: "Bodo",
    city: "Bodo",
    country: "Norway",
    iata_code: "BOO",
    _geoloc: {
      lat: 67.269167,
      lng: 14.365278,
    },
    links_count: 35,
    objectID: "635",
    uniqueID: "d95e4ff3-60a4-486c-bb30-d99bf4a0a25f",
  },
  {
    name: "Cedar Rapids",
    city: "Cedar Rapids",
    country: "United States",
    iata_code: "CID",
    _geoloc: {
      lat: 41.884694,
      lng: -91.710806,
    },
    links_count: 35,
    objectID: "4043",
    uniqueID: "7560434a-73a4-43ca-8b06-018416815a9f",
  },
  {
    name: "Sentani",
    city: "Jayapura",
    country: "Indonesia",
    iata_code: "DJJ",
    _geoloc: {
      lat: -2.576953,
      lng: 140.516372,
    },
    links_count: 35,
    objectID: "3244",
    uniqueID: "89de85fe-78e8-4289-9a00-3a9b4a422cd3",
  },
  {
    name: "Melville Hall",
    city: "Dominica",
    country: "Dominica",
    iata_code: "DOM",
    _geoloc: {
      lat: 15.547028,
      lng: -61.3,
    },
    links_count: 35,
    objectID: "2877",
    uniqueID: "6dcfb8f3-45c9-4e33-97ea-fda2548c18d6",
  },
  {
    name: "Amilcar Cabral Intl",
    city: "Amilcar Cabral",
    country: "Cape Verde",
    iata_code: "SID",
    _geoloc: {
      lat: 16.741389,
      lng: -22.949444,
    },
    links_count: 35,
    objectID: "1102",
    uniqueID: "b2a5a817-57d0-41f9-b921-33ac3f58d184",
  },
  {
    name: "Maputo",
    city: "Maputo",
    country: "Mozambique",
    iata_code: "MPM",
    _geoloc: {
      lat: -25.920836,
      lng: 32.572606,
    },
    links_count: 34,
    objectID: "979",
    uniqueID: "f4238428-2957-45c6-a691-db6adb0c4b37",
  },
  {
    name: "Yancheng Airport",
    city: "Yancheng",
    country: "China",
    iata_code: "YNZ",
    _geoloc: {
      lat: 33.3856,
      lng: 120.125,
    },
    links_count: 34,
    objectID: "6393",
    uniqueID: "f31a27a2-09d6-4dab-ac5c-e23cc4cdf837",
  },
  {
    name: "Praia International Airport",
    city: "Praia",
    country: "Santiago Island",
    iata_code: "RAI",
    _geoloc: {
      lat: 0,
      lng: 14.9245,
    },
    links_count: 34,
    objectID: "5674",
    uniqueID: "47bbb1a6-bd79-49f6-9af9-8e6db4bd9449",
  },
  {
    name: "Achmad Yani",
    city: "Semarang",
    country: "Indonesia",
    iata_code: "SRG",
    _geoloc: {
      lat: -6.971447,
      lng: 110.374122,
    },
    links_count: 34,
    objectID: "3901",
    uniqueID: "13c21ba4-c1db-44f3-ac0f-116d48936da3",
  },
  {
    name: "Columbia Metropolitan",
    city: "Columbia",
    country: "United States",
    iata_code: "CAE",
    _geoloc: {
      lat: 33.938833,
      lng: -81.119528,
    },
    links_count: 34,
    objectID: "3561",
    uniqueID: "b2d83b22-4e56-4eda-8bb1-6309a94e611c",
  },
  {
    name: "Dane Co Rgnl Truax Fld",
    city: "Madison",
    country: "United States",
    iata_code: "MSN",
    _geoloc: {
      lat: 43.139858,
      lng: -89.337514,
    },
    links_count: 34,
    objectID: "3459",
    uniqueID: "3086f3cf-092c-416e-81ec-4c133b76a977",
  },
  {
    name: "Langkawi Intl",
    city: "Pulau",
    country: "Malaysia",
    iata_code: "LGK",
    _geoloc: {
      lat: 6.329728,
      lng: 99.728667,
    },
    links_count: 34,
    objectID: "3305",
    uniqueID: "5724a86d-6a4a-4ab9-9130-b09f4581c8af",
  },
  {
    name: "Coimbatore",
    city: "Coimbatore",
    country: "India",
    iata_code: "CJB",
    _geoloc: {
      lat: 11.030031,
      lng: 77.043383,
    },
    links_count: 34,
    objectID: "3135",
    uniqueID: "5cd613bf-7914-4ab6-ac33-5be34dfd3c6b",
  },
  {
    name: "Jaipur",
    city: "Jaipur",
    country: "India",
    iata_code: "JAI",
    _geoloc: {
      lat: 26.824192,
      lng: 75.812161,
    },
    links_count: 34,
    objectID: "3098",
    uniqueID: "9000f158-f08c-4293-b030-b8212348c2bd",
  },
  {
    name: "Omsk",
    city: "Omsk",
    country: "Russia",
    iata_code: "OMS",
    _geoloc: {
      lat: 54.967042,
      lng: 73.310514,
    },
    links_count: 34,
    objectID: "2958",
    uniqueID: "9ce84916-a09d-4d85-ba27-43658bb2bfa1",
  },
  {
    name: "Lviv Intl",
    city: "Lvov",
    country: "Ukraine",
    iata_code: "LWO",
    _geoloc: {
      lat: 49.8125,
      lng: 23.956111,
    },
    links_count: 34,
    objectID: "2945",
    uniqueID: "d6b79eb3-d6e9-4b49-a4fa-88b17077950e",
  },
  {
    name: "Diori Hamani",
    city: "Niamey",
    country: "Niger",
    iata_code: "NIM",
    _geoloc: {
      lat: 13.481547,
      lng: 2.183614,
    },
    links_count: 34,
    objectID: "280",
    uniqueID: "92a18030-23db-44d0-ad9f-7de9da6710f0",
  },
  {
    name: "Campo Grande",
    city: "Campo Grande",
    country: "Brazil",
    iata_code: "CGR",
    _geoloc: {
      lat: -20.468667,
      lng: -54.6725,
    },
    links_count: 34,
    objectID: "2538",
    uniqueID: "163a1196-0cfb-4912-bdfb-397783beffe1",
  },
  {
    name: "Hiroshima",
    city: "Hiroshima",
    country: "Japan",
    iata_code: "HIJ",
    _geoloc: {
      lat: 34.436111,
      lng: 132.919444,
    },
    links_count: 34,
    objectID: "2326",
    uniqueID: "7fd684c7-f29c-4b68-b882-7501a3234e4e",
  },
  {
    name: "Komatsu",
    city: "Kanazawa",
    country: "Japan",
    iata_code: "KMQ",
    _geoloc: {
      lat: 36.394611,
      lng: 136.406544,
    },
    links_count: 34,
    objectID: "2322",
    uniqueID: "ee45f295-baa8-4670-a6cb-d0ab62a1aff9",
  },
  {
    name: "Nagasaki",
    city: "Nagasaki",
    country: "Japan",
    iata_code: "NGS",
    _geoloc: {
      lat: 32.916944,
      lng: 129.913611,
    },
    links_count: 34,
    objectID: "2313",
    uniqueID: "768f5a5e-74de-49bd-ba98-197b8bd2d609",
  },
  {
    name: "Sioux Lookout",
    city: "Sioux Lookout",
    country: "Canada",
    iata_code: "YXL",
    _geoloc: {
      lat: 50.113889,
      lng: -91.905278,
    },
    links_count: 34,
    objectID: "169",
    uniqueID: "8b3be805-e088-4591-a682-d20e0efd98cb",
  },
  {
    name: "Adana",
    city: "Adana",
    country: "Turkey",
    iata_code: "ADA",
    _geoloc: {
      lat: 36.982166,
      lng: 35.280388,
    },
    links_count: 34,
    objectID: "1685",
    uniqueID: "c254703c-237f-4060-9ffe-7db9a63747c4",
  },
  {
    name: "Guipavas",
    city: "Brest",
    country: "France",
    iata_code: "BES",
    _geoloc: {
      lat: 48.447911,
      lng: -4.418539,
    },
    links_count: 34,
    objectID: "1403",
    uniqueID: "561f0d74-e6aa-43b2-a7b3-c597d07c71fe",
  },
  {
    name: "Asturias",
    city: "Aviles",
    country: "Spain",
    iata_code: "OVD",
    _geoloc: {
      lat: 43.563567,
      lng: -6.034622,
    },
    links_count: 34,
    objectID: "1214",
    uniqueID: "bc636c63-d8c1-427d-bf7e-d6323738e9db",
  },
  {
    name: "Key West Intl",
    city: "Key West",
    country: "United States",
    iata_code: "EYW",
    _geoloc: {
      lat: 24.556111,
      lng: -81.759556,
    },
    links_count: 33,
    objectID: "3875",
    uniqueID: "bee3c4aa-5999-4e8e-a8ca-06ef371a87b4",
  },
  {
    name: "Long Island Mac Arthur",
    city: "Islip",
    country: "United States",
    iata_code: "ISP",
    _geoloc: {
      lat: 40.79525,
      lng: -73.100222,
    },
    links_count: 33,
    objectID: "3857",
    uniqueID: "5ddb7a1e-37d6-4515-950d-11c0cd693fe4",
  },
  {
    name: "Mandalay Intl",
    city: "Mandalay",
    country: "Burma",
    iata_code: "MDL",
    _geoloc: {
      lat: 21.702156,
      lng: 95.977928,
    },
    links_count: 33,
    objectID: "3222",
    uniqueID: "c5571ca4-f80f-45f6-b69c-f0bd4d4189e1",
  },
  {
    name: "Khomutovo",
    city: "Yuzhno-sakhalinsk",
    country: "Russia",
    iata_code: "UUS",
    _geoloc: {
      lat: 46.888672,
      lng: 142.717531,
    },
    links_count: 33,
    objectID: "2933",
    uniqueID: "213e2a28-36ea-45aa-a01c-917b2258e54b",
  },
  {
    name: "Kish Island",
    city: "Kish Island",
    country: "Iran",
    iata_code: "KIH",
    _geoloc: {
      lat: 26.526156,
      lng: 53.980211,
    },
    links_count: 33,
    objectID: "2109",
    uniqueID: "80780d48-417a-4ec8-a761-cf32f1f08070",
  },
  {
    name: "General R Fierro Villalobos Intl",
    city: "Chihuahua",
    country: "Mexico",
    iata_code: "CUU",
    _geoloc: {
      lat: 28.702875,
      lng: -105.964567,
    },
    links_count: 33,
    objectID: "1797",
    uniqueID: "3479da1b-6309-46f2-bffd-d32228392475",
  },
  {
    name: "Dionysios Solomos",
    city: "Zakynthos",
    country: "Greece",
    iata_code: "ZTH",
    _geoloc: {
      lat: 37.750853,
      lng: 20.88425,
    },
    links_count: 33,
    objectID: "1488",
    uniqueID: "f3776849-f1ef-4cb8-b6d2-79f78d55c202",
  },
  {
    name: "Regina Intl",
    city: "Regina",
    country: "Canada",
    iata_code: "YQR",
    _geoloc: {
      lat: 50.431944,
      lng: -104.665833,
    },
    links_count: 33,
    objectID: "120",
    uniqueID: "3e06797d-1928-481c-8a46-48903a26cb07",
  },
  {
    name: "Lombok International Airport",
    city: "Praya",
    country: "Indonesia",
    iata_code: "LOP",
    _geoloc: {
      lat: -8.7573222,
      lng: 116.276675,
    },
    links_count: 32,
    objectID: "8401",
    uniqueID: "3f9fabe9-d409-4cd1-8234-71462315b0ba",
  },
  {
    name: "Sturup",
    city: "Malmoe",
    country: "Sweden",
    iata_code: "MMX",
    _geoloc: {
      lat: 55.530193,
      lng: 13.371639,
    },
    links_count: 32,
    objectID: "708",
    uniqueID: "4490cc97-9a5a-47d5-aebd-28398fe4fc5e",
  },
  {
    name: "Lianyungang Airport",
    city: "Lianyungang",
    country: "China",
    iata_code: "LYG",
    _geoloc: {
      lat: 34.55,
      lng: 119.25,
    },
    links_count: 32,
    objectID: "6383",
    uniqueID: "727d7750-05a5-4118-921d-e29a4c499a4b",
  },
  {
    name: "Jingdezhen Airport",
    city: "Jingdezhen",
    country: "China",
    iata_code: "JDZ",
    _geoloc: {
      lat: 29.3386,
      lng: 117.176,
    },
    links_count: 32,
    objectID: "6380",
    uniqueID: "d0410cb9-2eea-4e37-af37-02831dada617",
  },
  {
    name: "Changzhi Airport",
    city: "Changzhi",
    country: "China",
    iata_code: "CIH",
    _geoloc: {
      lat: 36.2475,
      lng: 113.126,
    },
    links_count: 32,
    objectID: "6343",
    uniqueID: "a99ac3e4-583d-4bba-958c-30fe3ec346d1",
  },
  {
    name: "Niigata",
    city: "Niigata",
    country: "Japan",
    iata_code: "KIJ",
    _geoloc: {
      lat: 37.5711,
      lng: 139.0646,
    },
    links_count: 32,
    objectID: "4381",
    uniqueID: "2da36699-f48b-4eae-b79e-1e8f6d290ebd",
  },
  {
    name: "Novyi Urengoy",
    city: "Novy Urengoy",
    country: "Russia",
    iata_code: "NUX",
    _geoloc: {
      lat: 66.041811,
      lng: 76.313938,
    },
    links_count: 32,
    objectID: "4364",
    uniqueID: "c1217b42-0a5e-47f6-bc47-e724a8c8a3b0",
  },
  {
    name: "Jiuzhaigou Huanglong",
    city: "Jiuzhaigou",
    country: "China",
    iata_code: "JZH",
    _geoloc: {
      lat: 32.857,
      lng: 103.683,
    },
    links_count: 32,
    objectID: "4301",
    uniqueID: "865fbc6c-d801-463a-b737-8d7cbf33d879",
  },
  {
    name: "Fort Wayne",
    city: "Fort Wayne",
    country: "United States",
    iata_code: "FWA",
    _geoloc: {
      lat: 40.978472,
      lng: -85.195139,
    },
    links_count: 32,
    objectID: "4041",
    uniqueID: "05c2f1b1-b78b-4db9-abf2-e25698dbfdb9",
  },
  {
    name: "Lovell Fld",
    city: "Chattanooga",
    country: "United States",
    iata_code: "CHA",
    _geoloc: {
      lat: 35.035278,
      lng: -85.203808,
    },
    links_count: 32,
    objectID: "3578",
    uniqueID: "06ac6d31-ee66-4fe8-9103-9773c806a41c",
  },
  {
    name: "Townsville",
    city: "Townsville",
    country: "Australia",
    iata_code: "TSV",
    _geoloc: {
      lat: -19.2525,
      lng: 146.765278,
    },
    links_count: 32,
    objectID: "3330",
    uniqueID: "8deb0aa0-0ee0-4c6b-b4d4-07d128b52b4c",
  },
  {
    name: "Krabi",
    city: "Krabi",
    country: "Thailand",
    iata_code: "KBV",
    _geoloc: {
      lat: 8.095969,
      lng: 98.988764,
    },
    links_count: 32,
    objectID: "3174",
    uniqueID: "5b2b0d20-3fe1-4227-acb7-ead88e8e563f",
  },
  {
    name: "Shah Amanat Intl",
    city: "Chittagong",
    country: "Bangladesh",
    iata_code: "CGP",
    _geoloc: {
      lat: 22.249611,
      lng: 91.813286,
    },
    links_count: 32,
    objectID: "3069",
    uniqueID: "96877686-376f-44ec-a0b9-398853968144",
  },
  {
    name: "Bagdogra",
    city: "Baghdogra",
    country: "India",
    iata_code: "IXB",
    _geoloc: {
      lat: 26.681206,
      lng: 88.328567,
    },
    links_count: 32,
    objectID: "3040",
    uniqueID: "895c67f6-7469-4fb3-bee2-97e97c662331",
  },
  {
    name: "Devi Ahilyabai Holkar",
    city: "Indore",
    country: "India",
    iata_code: "IDR",
    _geoloc: {
      lat: 22.721786,
      lng: 75.801086,
    },
    links_count: 32,
    objectID: "3008",
    uniqueID: "dd680555-53fe-47cc-9bc4-f2e335b0c841",
  },
  {
    name: "Goiabeiras",
    city: "Vitoria",
    country: "Brazil",
    iata_code: "VIX",
    _geoloc: {
      lat: -20.258056,
      lng: -40.286389,
    },
    links_count: 32,
    objectID: "2638",
    uniqueID: "2980c0fe-ae76-4883-b1a6-54a3ea0f58a8",
  },
  {
    name: "Augusto Severo",
    city: "Natal",
    country: "Brazil",
    iata_code: "NAT",
    _geoloc: {
      lat: -5.911417,
      lng: -35.247717,
    },
    links_count: 32,
    objectID: "2597",
    uniqueID: "5230c730-64e7-4cb6-aa9c-924a44ae2b5c",
  },
  {
    name: "Mohamed Boudiaf Intl",
    city: "Constantine",
    country: "Algeria",
    iata_code: "CZL",
    _geoloc: {
      lat: 36.276028,
      lng: 6.620386,
    },
    links_count: 32,
    objectID: "221",
    uniqueID: "e4c02ae4-e8dd-4d23-ae22-db47ab3447cf",
  },
  {
    name: "General Heriberto Jara Intl",
    city: "Vera Cruz",
    country: "Mexico",
    iata_code: "VER",
    _geoloc: {
      lat: 19.145931,
      lng: -96.187267,
    },
    links_count: 32,
    objectID: "1854",
    uniqueID: "3e9f4952-564f-44c6-8f27-6924279a8fd1",
  },
  {
    name: "Cozumel Intl",
    city: "Cozumel",
    country: "Mexico",
    iata_code: "CZM",
    _geoloc: {
      lat: 20.522403,
      lng: -86.925644,
    },
    links_count: 32,
    objectID: "1800",
    uniqueID: "0e62ce1a-7533-43ff-bce5-3a4c89178f2a",
  },
  {
    name: "Culiacan Intl",
    city: "Culiacan",
    country: "Mexico",
    iata_code: "CUL",
    _geoloc: {
      lat: 24.764547,
      lng: -107.474717,
    },
    links_count: 32,
    objectID: "1792",
    uniqueID: "0d9884cb-7a7c-46af-aa4d-a238403cdee0",
  },
  {
    name: "Podgorica",
    city: "Podgorica",
    country: "Montenegro",
    iata_code: "TGD",
    _geoloc: {
      lat: 42.359392,
      lng: 19.251894,
    },
    links_count: 32,
    objectID: "1741",
    uniqueID: "a4a51eb7-d8db-4525-8041-98d6d2757618",
  },
  {
    name: "Sarajevo",
    city: "Sarajevo",
    country: "Bosnia and Herzegovina",
    iata_code: "SJJ",
    _geoloc: {
      lat: 43.824583,
      lng: 18.331467,
    },
    links_count: 32,
    objectID: "1646",
    uniqueID: "05fdd6a1-13b5-49c6-8f75-8de62a2a3070",
  },
  {
    name: "Monrovia Roberts Intl",
    city: "Monrovia",
    country: "Liberia",
    iata_code: "ROB",
    _geoloc: {
      lat: 6.233789,
      lng: -10.362311,
    },
    links_count: 32,
    objectID: "1063",
    uniqueID: "40dbf385-de33-4f17-a298-17da06f63b2d",
  },
  {
    name: "Mangalore",
    city: "Mangalore",
    country: "India",
    iata_code: "IXE",
    _geoloc: {
      lat: 12.961267,
      lng: 74.890069,
    },
    links_count: 31,
    objectID: "3143",
    uniqueID: "b14ec5bc-6165-4242-96d2-43a2835abdd1",
  },
  {
    name: "Habib Bourguiba Intl",
    city: "Monastir",
    country: "Tunisia",
    iata_code: "MIR",
    _geoloc: {
      lat: 35.758056,
      lng: 10.754722,
    },
    links_count: 31,
    objectID: "286",
    uniqueID: "9e2a5370-6d6b-4962-87e2-5cfbfeb05618",
  },
  {
    name: "Marechal Cunha Machado Intl",
    city: "Sao Luis",
    country: "Brazil",
    iata_code: "SLZ",
    _geoloc: {
      lat: -2.585361,
      lng: -44.234139,
    },
    links_count: 31,
    objectID: "2616",
    uniqueID: "3fc24940-06be-430b-abeb-8d33790fcce1",
  },
  {
    name: "Wabush",
    city: "Wabush",
    country: "Canada",
    iata_code: "YWK",
    _geoloc: {
      lat: 52.921944,
      lng: -66.864444,
    },
    links_count: 31,
    objectID: "161",
    uniqueID: "37a11809-820f-42c4-abc2-31e53a9a8ad2",
  },
  {
    name: "Santorini",
    city: "Thira",
    country: "Greece",
    iata_code: "JTR",
    _geoloc: {
      lat: 36.399169,
      lng: 25.479333,
    },
    links_count: 31,
    objectID: "1479",
    uniqueID: "df7ce28a-d073-41c0-bf49-84ad57ebd7e1",
  },
  {
    name: "Dzaoudzi Pamandzi",
    city: "Dzaoudzi",
    country: "Mayotte",
    iata_code: "DZA",
    _geoloc: {
      lat: -12.804722,
      lng: 45.281113,
    },
    links_count: 30,
    objectID: "915",
    uniqueID: "3216cdd5-92fc-43bc-ae7f-2654296c15db",
  },
  {
    name: "Kelowna",
    city: "Kelowna",
    country: "Canada",
    iata_code: "YLW",
    _geoloc: {
      lat: 49.956112,
      lng: -119.377778,
    },
    links_count: 30,
    objectID: "87",
    uniqueID: "4c8a0303-d95f-4971-8431-2a7e69ce2ffe",
  },
  {
    name: "Nanping Wuyishan Airport",
    city: "Wuyishan",
    country: "China",
    iata_code: "WUS",
    _geoloc: {
      lat: 27.7019,
      lng: 118.001,
    },
    links_count: 30,
    objectID: "6391",
    uniqueID: "656b96bc-bf5f-4d3e-b5f0-d4757ee6e594",
  },
  {
    name: "Chifeng Airport",
    city: "Chifeng",
    country: "China",
    iata_code: "CIF",
    _geoloc: {
      lat: 42.235,
      lng: 118.908,
    },
    links_count: 30,
    objectID: "6342",
    uniqueID: "384d9566-3dc3-435a-8aeb-83d4edeef696",
  },
  {
    name: "Ireland West Knock",
    city: "Connaught",
    country: "Ireland",
    iata_code: "NOC",
    _geoloc: {
      lat: 53.910297,
      lng: -8.818492,
    },
    links_count: 30,
    objectID: "600",
    uniqueID: "14de191d-1126-43ad-bf4e-01c473e9e49a",
  },
  {
    name: "Quad City Intl",
    city: "Moline",
    country: "United States",
    iata_code: "MLI",
    _geoloc: {
      lat: 41.448528,
      lng: -90.507539,
    },
    links_count: 30,
    objectID: "4072",
    uniqueID: "2d8bb0f5-c25f-415c-80ca-6d42b1602944",
  },
  {
    name: "Bermuda Intl",
    city: "Bermuda",
    country: "Bermuda",
    iata_code: "BDA",
    _geoloc: {
      lat: 32.364042,
      lng: -64.678703,
    },
    links_count: 30,
    objectID: "4069",
    uniqueID: "2d348be7-cd19-47d2-8939-668b1310c7e9",
  },
  {
    name: "Peoria Regional",
    city: "Peoria",
    country: "United States",
    iata_code: "PIA",
    _geoloc: {
      lat: 40.664203,
      lng: -89.693258,
    },
    links_count: 30,
    objectID: "4046",
    uniqueID: "11277cbc-c254-494d-954a-256f8b2540ff",
  },
  {
    name: "Roanoke Regional",
    city: "Roanoke VA",
    country: "United States",
    iata_code: "ROA",
    _geoloc: {
      lat: 37.325472,
      lng: -79.975417,
    },
    links_count: 30,
    objectID: "4016",
    uniqueID: "d5713681-92e7-45f4-aace-145357cea1f5",
  },
  {
    name: "Tallahassee Rgnl",
    city: "Tallahassee",
    country: "United States",
    iata_code: "TLH",
    _geoloc: {
      lat: 30.396528,
      lng: -84.350333,
    },
    links_count: 30,
    objectID: "3698",
    uniqueID: "5f72ed4d-2a63-42fa-ada7-d2ace64701a9",
  },
  {
    name: "Spokane Intl",
    city: "Spokane",
    country: "United States",
    iata_code: "GEG",
    _geoloc: {
      lat: 47.619861,
      lng: -117.533833,
    },
    links_count: 30,
    objectID: "3467",
    uniqueID: "6b89d9b1-af30-4193-a222-4406ba5ba1be",
  },
  {
    name: "Dr Ambedkar Intl",
    city: "Nagpur",
    country: "India",
    iata_code: "NAG",
    _geoloc: {
      lat: 21.092192,
      lng: 79.047183,
    },
    links_count: 30,
    objectID: "3015",
    uniqueID: "29f9d181-4e31-4f2d-89ba-5089ddc853fe",
  },
  {
    name: "Ashgabat",
    city: "Ashkhabad",
    country: "Turkmenistan",
    iata_code: "ASB",
    _geoloc: {
      lat: 37.986814,
      lng: 58.360967,
    },
    links_count: 30,
    objectID: "2976",
    uniqueID: "60bbf9db-553c-4c85-bde9-9d50bf8ce264",
  },
  {
    name: "Khrabrovo",
    city: "Kaliningrad",
    country: "Russia",
    iata_code: "KGD",
    _geoloc: {
      lat: 54.89005,
      lng: 20.592633,
    },
    links_count: 30,
    objectID: "2952",
    uniqueID: "1a64f2b1-501f-4d05-b443-1e98f5b798ee",
  },
  {
    name: "Donetsk Intl",
    city: "Donetsk",
    country: "Ukraine",
    iata_code: "DOK",
    _geoloc: {
      lat: 48.073611,
      lng: 37.739722,
    },
    links_count: 30,
    objectID: "2940",
    uniqueID: "10366edf-dda3-413a-8786-97728ca0e606",
  },
  {
    name: "Ten Cel Av Cesar Bombonato",
    city: "Uberlandia",
    country: "Brazil",
    iata_code: "UDI",
    _geoloc: {
      lat: -18.882844,
      lng: -48.225594,
    },
    links_count: 30,
    objectID: "2633",
    uniqueID: "73460437-b93a-4f03-9a74-29ec4f163ea0",
  },
  {
    name: "Tabriz Intl",
    city: "Tabriz",
    country: "Iran",
    iata_code: "TBZ",
    _geoloc: {
      lat: 38.133889,
      lng: 46.235,
    },
    links_count: 30,
    objectID: "2162",
    uniqueID: "ff02a95e-3b0b-4348-889f-e58196c4b0f2",
  },
  {
    name: "Grand Bahama Intl",
    city: "Freeport",
    country: "Bahamas",
    iata_code: "FPO",
    _geoloc: {
      lat: 26.558686,
      lng: -78.695553,
    },
    links_count: 30,
    objectID: "1948",
    uniqueID: "45fc3482-ff7f-4ca2-9a85-94808c3af801",
  },
  {
    name: "Goose Bay",
    city: "Goose Bay",
    country: "Canada",
    iata_code: "YYR",
    _geoloc: {
      lat: 53.319168,
      lng: -60.425833,
    },
    links_count: 30,
    objectID: "188",
    uniqueID: "a193893f-f2c7-428a-a090-e588c869d31f",
  },
  {
    name: "General Rafael Buelna Intl",
    city: "Mazatlan",
    country: "Mexico",
    iata_code: "MZT",
    _geoloc: {
      lat: 23.161356,
      lng: -106.266072,
    },
    links_count: 30,
    objectID: "1826",
    uniqueID: "a6907c29-1272-40af-85fe-215a8cf4d3db",
  },
  {
    name: "Licenciado Manuel Crescencio Rejon Int",
    city: "Merida",
    country: "Mexico",
    iata_code: "MID",
    _geoloc: {
      lat: 20.936981,
      lng: -89.657672,
    },
    links_count: 30,
    objectID: "1819",
    uniqueID: "0eb23545-9f0d-479f-9d71-2c61e3a2771f",
  },
  {
    name: "Saskatoon J G Diefenbaker Intl",
    city: "Saskatoon",
    country: "Canada",
    iata_code: "YXE",
    _geoloc: {
      lat: 52.170834,
      lng: -106.699722,
    },
    links_count: 30,
    objectID: "166",
    uniqueID: "65d3076a-1904-4bd6-9ef9-623492de7702",
  },
  {
    name: "Pula",
    city: "Pula",
    country: "Croatia",
    iata_code: "PUY",
    _geoloc: {
      lat: 44.893533,
      lng: 13.922192,
    },
    links_count: 30,
    objectID: "1203",
    uniqueID: "01b91fd4-cbd5-42b2-b740-d5e31e0038f0",
  },
  {
    name: "Alykel",
    city: "Norilsk",
    country: "Russia",
    iata_code: "NSK",
    _geoloc: {
      lat: 69.311053,
      lng: 87.332183,
    },
    links_count: 29,
    objectID: "4352",
    uniqueID: "053144b8-1d45-4efc-99dc-8614e8844daf",
  },
  {
    name: "Syamsudin Noor",
    city: "Banjarmasin",
    country: "Indonesia",
    iata_code: "BDJ",
    _geoloc: {
      lat: -3.442356,
      lng: 114.762553,
    },
    links_count: 29,
    objectID: "3908",
    uniqueID: "0dda9341-29da-4c85-8422-a8a75cc26ccf",
  },
  {
    name: "Srinagar",
    city: "Srinagar",
    country: "India",
    iata_code: "SXR",
    _geoloc: {
      lat: 33.987139,
      lng: 74.77425,
    },
    links_count: 29,
    objectID: "3112",
    uniqueID: "500a8590-a010-4ef2-ab30-dc0f7f178769",
  },
  {
    name: "Point Salines Intl",
    city: "Point Salines",
    country: "Grenada",
    iata_code: "GND",
    _geoloc: {
      lat: 12.004247,
      lng: -61.786192,
    },
    links_count: 29,
    objectID: "2882",
    uniqueID: "53563d4f-a055-41e8-ad5a-e740463fe736",
  },
  {
    name: "Abha",
    city: "Abha",
    country: "Saudi Arabia",
    iata_code: "AHB",
    _geoloc: {
      lat: 18.240367,
      lng: 42.656625,
    },
    links_count: 29,
    objectID: "2059",
    uniqueID: "0710dfb9-a076-46f9-aaec-df9658519ab8",
  },
  {
    name: "Managua Intl",
    city: "Managua",
    country: "Nicaragua",
    iata_code: "MGA",
    _geoloc: {
      lat: 12.141494,
      lng: -86.168178,
    },
    links_count: 29,
    objectID: "1863",
    uniqueID: "091e6108-aba7-43ee-bdc0-82adccafc1d8",
  },
  {
    name: "Guanajuato Intl",
    city: "Del Bajio",
    country: "Mexico",
    iata_code: "BJX",
    _geoloc: {
      lat: 20.993464,
      lng: -101.480847,
    },
    links_count: 29,
    objectID: "1815",
    uniqueID: "532d9f97-89f2-4a91-88e6-d9f360460136",
  },
  {
    name: "Gregorio Luperon Intl",
    city: "Puerto Plata",
    country: "Dominican Republic",
    iata_code: "POP",
    _geoloc: {
      lat: 19.7579,
      lng: -70.570033,
    },
    links_count: 29,
    objectID: "1761",
    uniqueID: "0964c2d0-eb71-4ee3-ac42-b0463a014835",
  },
  {
    name: "Malabo",
    city: "Malabo",
    country: "Equatorial Guinea",
    iata_code: "SSG",
    _geoloc: {
      lat: 3.755267,
      lng: 8.708717,
    },
    links_count: 28,
    objectID: "891",
    uniqueID: "ce266e2d-216d-4e5a-8ffd-fa66589ffa56",
  },
  {
    name: "Yanji Airport",
    city: "Yanji",
    country: "China",
    iata_code: "YNJ",
    _geoloc: {
      lat: 42.8828,
      lng: 129.451258,
    },
    links_count: 28,
    objectID: "6414",
    uniqueID: "37d1a148-13f4-443f-92ac-419e9799c80f",
  },
  {
    name: "Huangyan Luqiao Airport",
    city: "Huangyan",
    country: "China",
    iata_code: "HYN",
    _geoloc: {
      lat: 28.5622,
      lng: 121.429,
    },
    links_count: 28,
    objectID: "6384",
    uniqueID: "11646d7d-321b-48d5-b1ba-198cce4fd7f0",
  },
  {
    name: "Bailian Airport",
    city: "Liuzhou",
    country: "China",
    iata_code: "LZH",
    _geoloc: {
      lat: 24.2075,
      lng: 109.391,
    },
    links_count: 28,
    objectID: "6356",
    uniqueID: "1b11f4a0-0afc-4d82-87a5-ebe55e6f7677",
  },
  {
    name: "Ercan International Airport",
    city: "Nicosia",
    country: "Cyprus",
    iata_code: "ECN",
    _geoloc: {
      lat: 35.1547,
      lng: 33.4961,
    },
    links_count: 28,
    objectID: "5780",
    uniqueID: "d91160a9-a8af-4c86-89dd-87f2ae2473f0",
  },
  {
    name: "Southend",
    city: "Southend",
    country: "United Kingdom",
    iata_code: "SEN",
    _geoloc: {
      lat: 51.571389,
      lng: 0.695556,
    },
    links_count: 28,
    objectID: "508",
    uniqueID: "c40cadd9-a049-4a1d-9786-384e642c17bf",
  },
  {
    name: "Springfield Branson Natl",
    city: "Springfield",
    country: "United States",
    iata_code: "SGF",
    _geoloc: {
      lat: 37.245667,
      lng: -93.388639,
    },
    links_count: 28,
    objectID: "4348",
    uniqueID: "f88a23f2-198c-4c9c-a04e-ee0d197784ef",
  },
  {
    name: "Hector International Airport",
    city: "Fargo",
    country: "United States",
    iata_code: "FAR",
    _geoloc: {
      lat: 46.92065,
      lng: -96.8157639,
    },
    links_count: 28,
    objectID: "4292",
    uniqueID: "55e93e16-6c21-41a5-a89e-7600319985b4",
  },
  {
    name: "Baghdad International Airport",
    city: "Baghdad",
    country: "Iraq",
    iata_code: "BGW",
    _geoloc: {
      lat: 33.262539,
      lng: 44.234578,
    },
    links_count: 28,
    objectID: "4130",
    uniqueID: "4e6bb5ed-31fb-46de-b5c2-391f5b2f7554",
  },
  {
    name: "Santarem",
    city: "Santarem",
    country: "Brazil",
    iata_code: "STM",
    _geoloc: {
      lat: -2.422431,
      lng: -54.792789,
    },
    links_count: 28,
    objectID: "4092",
    uniqueID: "ace89273-8a49-44f3-a3a5-2cb80b833a19",
  },
  {
    name: "Francisco Bangoy International",
    city: "Davao",
    country: "Philippines",
    iata_code: "DVO",
    _geoloc: {
      lat: 7.125522,
      lng: 125.645778,
    },
    links_count: 28,
    objectID: "4090",
    uniqueID: "8df9fab1-9bad-4ecb-a96e-78b246666f80",
  },
  {
    name: "Trenton Mercer",
    city: "Trenton",
    country: "United States",
    iata_code: "TTN",
    _geoloc: {
      lat: 40.276692,
      lng: -74.813469,
    },
    links_count: 28,
    objectID: "3447",
    uniqueID: "6e6a3f63-30d7-435f-8ac0-c70d20743ea3",
  },
  {
    name: "Sultan Ismail Petra",
    city: "Kota Bahru",
    country: "Malaysia",
    iata_code: "KBR",
    _geoloc: {
      lat: 6.16685,
      lng: 102.293014,
    },
    links_count: 28,
    objectID: "3298",
    uniqueID: "97891f9c-d048-4985-9456-7c6ff68f4010",
  },
  {
    name: "Pattimura",
    city: "Ambon",
    country: "Indonesia",
    iata_code: "AMQ",
    _geoloc: {
      lat: -3.710264,
      lng: 128.089136,
    },
    links_count: 28,
    objectID: "3256",
    uniqueID: "27e8d041-9a76-4046-8278-fc1eb3a1cb0c",
  },
  {
    name: "Surgut",
    city: "Surgut",
    country: "Russia",
    iata_code: "SGC",
    _geoloc: {
      lat: 61.343694,
      lng: 73.401842,
    },
    links_count: 28,
    objectID: "2974",
    uniqueID: "f648a628-4fe0-4a2f-aaa9-87784b391807",
  },
  {
    name: "Nizhnevartovsk",
    city: "Nizhnevartovsk",
    country: "Russia",
    iata_code: "NJC",
    _geoloc: {
      lat: 60.949272,
      lng: 76.483617,
    },
    links_count: 28,
    objectID: "2972",
    uniqueID: "46089c82-8aaa-44c8-901a-85493b417c12",
  },
  {
    name: "Dnipropetrovsk Intl",
    city: "Dnepropetrovsk",
    country: "Ukraine",
    iata_code: "DNK",
    _geoloc: {
      lat: 48.357222,
      lng: 35.100556,
    },
    links_count: 28,
    objectID: "2941",
    uniqueID: "edbcf9e9-4e58-4e08-ae55-aec96c34d97e",
  },
  {
    name: "La Chinita Intl",
    city: "Maracaibo",
    country: "Venezuela",
    iata_code: "MAR",
    _geoloc: {
      lat: 10.558208,
      lng: -71.727856,
    },
    links_count: 28,
    objectID: "2848",
    uniqueID: "12c5c318-1bd2-4bd1-b1eb-28dc5d52cfd1",
  },
  {
    name: "Ishigaki",
    city: "Ishigaki",
    country: "Japan",
    iata_code: "ISG",
    _geoloc: {
      lat: 24.344525,
      lng: 124.186983,
    },
    links_count: 28,
    objectID: "2387",
    uniqueID: "6980150b-3b2f-4c50-b8b6-31b069fea383",
  },
  {
    name: "Quetta",
    city: "Quetta",
    country: "Pakistan",
    iata_code: "UET",
    _geoloc: {
      lat: 30.251369,
      lng: 66.937764,
    },
    links_count: 28,
    objectID: "2221",
    uniqueID: "9bbc7e87-9b13-497c-a12a-b797ada480d5",
  },
  {
    name: "Marsh Harbour",
    city: "Marsh Harbor",
    country: "Bahamas",
    iata_code: "MHH",
    _geoloc: {
      lat: 26.511406,
      lng: -77.083472,
    },
    links_count: 28,
    objectID: "1930",
    uniqueID: "9fc048e9-a7c3-4525-8ff9-a6ff47a77605",
  },
  {
    name: "Juan Manuel Galvez Intl",
    city: "Roatan",
    country: "Honduras",
    iata_code: "RTB",
    _geoloc: {
      lat: 16.316814,
      lng: -86.522961,
    },
    links_count: 28,
    objectID: "1774",
    uniqueID: "6c502c1b-15bf-4379-b50b-fa77004f42c3",
  },
  {
    name: "City Centre",
    city: "Toronto",
    country: "Canada",
    iata_code: "YTZ",
    _geoloc: {
      lat: 43.627499,
      lng: -79.396167,
    },
    links_count: 28,
    objectID: "144",
    uniqueID: "89bb1541-91de-4056-b26b-c714729ba36f",
  },
  {
    name: "Sud Corse",
    city: "Figari",
    country: "France",
    iata_code: "FSC",
    _geoloc: {
      lat: 41.500557,
      lng: 9.097777,
    },
    links_count: 28,
    objectID: "1323",
    uniqueID: "84e47e1e-1a7e-4548-b217-624b9c40531d",
  },
  {
    name: "Anglet",
    city: "Biarritz-bayonne",
    country: "France",
    iata_code: "BIQ",
    _geoloc: {
      lat: 43.468419,
      lng: -1.523325,
    },
    links_count: 28,
    objectID: "1280",
    uniqueID: "eb7710a4-510f-4fcc-b1d2-9e8b777cd070",
  },
  {
    name: "Rabil",
    city: "Boa Vista",
    country: "Cape Verde",
    iata_code: "BVC",
    _geoloc: {
      lat: 16.136531,
      lng: -22.888897,
    },
    links_count: 28,
    objectID: "1103",
    uniqueID: "05ada2a6-5c81-435c-b4a9-652318691730",
  },
  {
    name: "Mahlon Sweet Fld",
    city: "Eugene",
    country: "United States",
    iata_code: "EUG",
    _geoloc: {
      lat: 44.124583,
      lng: -123.211972,
    },
    links_count: 27,
    objectID: "4099",
    uniqueID: "8a3f21d5-c724-4751-bb7b-56059e9e5dc3",
  },
  {
    name: "City Of Colorado Springs Muni",
    city: "Colorado Springs",
    country: "United States",
    iata_code: "COS",
    _geoloc: {
      lat: 38.805805,
      lng: -104.700778,
    },
    links_count: 27,
    objectID: "3819",
    uniqueID: "1043d006-fcfe-434e-9109-1a37980d2da2",
  },
  {
    name: "Burlington Intl",
    city: "Burlington",
    country: "United States",
    iata_code: "BTV",
    _geoloc: {
      lat: 44.471861,
      lng: -73.153278,
    },
    links_count: 27,
    objectID: "3711",
    uniqueID: "8fe8bc78-76f4-4b27-aa47-445e491810a5",
  },
  {
    name: "Sultan Mahmud Badaruddin Ii",
    city: "Palembang",
    country: "Indonesia",
    iata_code: "PLM",
    _geoloc: {
      lat: -2.89825,
      lng: 104.699903,
    },
    links_count: 27,
    objectID: "3289",
    uniqueID: "feaccd64-f7f8-4475-8ec4-23e6b3bf2bdf",
  },
  {
    name: "Rarotonga Intl",
    city: "Avarua",
    country: "Cook Islands",
    iata_code: "RAR",
    _geoloc: {
      lat: -21.202739,
      lng: -159.805556,
    },
    links_count: 27,
    objectID: "1959",
    uniqueID: "f8571e37-1131-48b2-a755-b5fb69897d2a",
  },
  {
    name: "Licenciado Adolfo Lopez Mateos Intl",
    city: "Toluca",
    country: "Mexico",
    iata_code: "TLC",
    _geoloc: {
      lat: 19.337072,
      lng: -99.566008,
    },
    links_count: 27,
    objectID: "1850",
    uniqueID: "1099c6b5-b21f-4ef3-b59d-c1d33e1bbfe6",
  },
  {
    name: "Thunder Bay",
    city: "Thunder Bay",
    country: "Canada",
    iata_code: "YQT",
    _geoloc: {
      lat: 48.371944,
      lng: -89.323889,
    },
    links_count: 27,
    objectID: "121",
    uniqueID: "c89806e5-ea4c-44de-a735-66ddd4ff46ef",
  },
  {
    name: "Bujumbura Intl",
    city: "Bujumbura",
    country: "Burundi",
    iata_code: "BJM",
    _geoloc: {
      lat: -3.324019,
      lng: 29.318519,
    },
    links_count: 27,
    objectID: "1120",
    uniqueID: "dc42ee7b-ab58-4ca1-9626-20c5594e7cf8",
  },
  {
    name: "Seychelles Intl",
    city: "Mahe",
    country: "Seychelles",
    iata_code: "SEZ",
    _geoloc: {
      lat: -4.674342,
      lng: 55.521839,
    },
    links_count: 26,
    objectID: "994",
    uniqueID: "caa5551c-2e08-45ff-bab0-357027aa6f81",
  },
  {
    name: "Luzhou Airport",
    city: "Luzhou",
    country: "China",
    iata_code: "LZO",
    _geoloc: {
      lat: 28.8522,
      lng: 105.393,
    },
    links_count: 26,
    objectID: "6399",
    uniqueID: "400c3026-7871-44cf-8c0f-017e93504dae",
  },
  {
    name: "Zhoushan Airport",
    city: "Zhoushan",
    country: "China",
    iata_code: "HSN",
    _geoloc: {
      lat: 29.9342,
      lng: 122.362,
    },
    links_count: 26,
    objectID: "6395",
    uniqueID: "f19f17ca-c4bf-450a-8fc6-2fbd75596393",
  },
  {
    name: "Ganzhou Airport",
    city: "Ganzhou",
    country: "China",
    iata_code: "KOW",
    _geoloc: {
      lat: 25.8258,
      lng: 114.912,
    },
    links_count: 26,
    objectID: "6379",
    uniqueID: "12aa2302-bdad-4e9f-8017-ae953235a4e4",
  },
  {
    name: "Xiangfan Airport",
    city: "Xiangfan",
    country: "China",
    iata_code: "XFN",
    _geoloc: {
      lat: 32.1506,
      lng: 112.291,
    },
    links_count: 26,
    objectID: "6360",
    uniqueID: "70e250c1-fab1-4264-959a-d8f1f908e744",
  },
  {
    name: "Tongliao Airport",
    city: "Tongliao",
    country: "China",
    iata_code: "TGO",
    _geoloc: {
      lat: 43.5567,
      lng: 122.2,
    },
    links_count: 26,
    objectID: "6348",
    uniqueID: "19a32527-435f-4e68-b637-7ca8378e0843",
  },
  {
    name: "Vigra",
    city: "Alesund",
    country: "Norway",
    iata_code: "AES",
    _geoloc: {
      lat: 62.560372,
      lng: 6.110164,
    },
    links_count: 26,
    objectID: "630",
    uniqueID: "b517dc1c-c58f-4428-984c-3349367238d5",
  },
  {
    name: "Lehigh Valley Intl",
    city: "Allentown",
    country: "United States",
    iata_code: "ABE",
    _geoloc: {
      lat: 40.652083,
      lng: -75.440806,
    },
    links_count: 26,
    objectID: "4355",
    uniqueID: "2c5f26d1-fefa-4d6c-82ac-e298f9ab4e79",
  },
  {
    name: "Diosdado Macapagal International",
    city: "Angeles City",
    country: "Philippines",
    iata_code: "CRK",
    _geoloc: {
      lat: 15.185833,
      lng: 120.560278,
    },
    links_count: 26,
    objectID: "4140",
    uniqueID: "4116001d-8da3-4772-befa-7367ebc4ee89",
  },
  {
    name: "Bishop International",
    city: "Flint",
    country: "United States",
    iata_code: "FNT",
    _geoloc: {
      lat: 42.965424,
      lng: -83.743629,
    },
    links_count: 26,
    objectID: "4089",
    uniqueID: "bfbcca1e-8cd8-4422-997f-9b9a4da099c1",
  },
  {
    name: "Sarasota Bradenton Intl",
    city: "Sarasota",
    country: "United States",
    iata_code: "SRQ",
    _geoloc: {
      lat: 27.395444,
      lng: -82.554389,
    },
    links_count: 26,
    objectID: "4067",
    uniqueID: "35bea60b-4e16-41bd-a28b-a76d2055d9cd",
  },
  {
    name: "Charlottesville-Albemarle",
    city: "Charlottesville VA",
    country: "United States",
    iata_code: "CHO",
    _geoloc: {
      lat: 38.138639,
      lng: -78.452861,
    },
    links_count: 26,
    objectID: "4015",
    uniqueID: "fa99efb4-14b3-453a-824c-0c8f53b07de5",
  },
  {
    name: "Sioux Falls",
    city: "Sioux Falls",
    country: "United States",
    iata_code: "FSD",
    _geoloc: {
      lat: 43.582014,
      lng: -96.741914,
    },
    links_count: 26,
    objectID: "4009",
    uniqueID: "c288c2e1-2269-4ba2-8ec7-d9856192fd3c",
  },
  {
    name: "Wilmington Intl",
    city: "Wilmington",
    country: "United States",
    iata_code: "ILM",
    _geoloc: {
      lat: 34.270615,
      lng: -77.902569,
    },
    links_count: 26,
    objectID: "3845",
    uniqueID: "afe39c62-9329-4ebd-b8f7-092efb1c908c",
  },
  {
    name: "Friedrichshafen",
    city: "Friedrichshafen",
    country: "Germany",
    iata_code: "FDH",
    _geoloc: {
      lat: 47.671317,
      lng: 9.511486,
    },
    links_count: 26,
    objectID: "382",
    uniqueID: "3af5349d-a84f-4d96-8acf-1dfe1400b6a9",
  },
  {
    name: "Kashi",
    city: "Kashi",
    country: "China",
    iata_code: "KHG",
    _geoloc: {
      lat: 39.542922,
      lng: 76.019956,
    },
    links_count: 26,
    objectID: "3397",
    uniqueID: "e6413ed7-cb6b-4166-9399-f1b1b71bd0eb",
  },
  {
    name: "Canberra",
    city: "Canberra",
    country: "Australia",
    iata_code: "CBR",
    _geoloc: {
      lat: -35.306944,
      lng: 149.195,
    },
    links_count: 26,
    objectID: "3355",
    uniqueID: "f6840724-c0f1-45aa-8ca0-89993bfeb82f",
  },
  {
    name: "Mount Isa",
    city: "Mount Isa",
    country: "Australia",
    iata_code: "ISA",
    _geoloc: {
      lat: -20.663889,
      lng: 139.488611,
    },
    links_count: 26,
    objectID: "3324",
    uniqueID: "975b16b7-2987-4d25-9b34-cc009985677a",
  },
  {
    name: "Luang Phabang Intl",
    city: "Luang Prabang",
    country: "Laos",
    iata_code: "LPQ",
    _geoloc: {
      lat: 19.897914,
      lng: 102.160764,
    },
    links_count: 26,
    objectID: "3115",
    uniqueID: "cedbd9c6-9d51-41f5-b1f1-98cd6168fc50",
  },
  {
    name: "Zumbi Dos Palmares",
    city: "Maceio",
    country: "Brazil",
    iata_code: "MCZ",
    _geoloc: {
      lat: -9.510808,
      lng: -35.791678,
    },
    links_count: 26,
    objectID: "2590",
    uniqueID: "a5831e48-2ba0-4e59-8077-7d9a7b4e896c",
  },
  {
    name: "Santa Maria",
    city: "Aracaju",
    country: "Brazil",
    iata_code: "AJU",
    _geoloc: {
      lat: -10.984,
      lng: -37.070333,
    },
    links_count: 26,
    objectID: "2522",
    uniqueID: "5a58a787-71ad-4227-a9c7-7edae07f0881",
  },
  {
    name: "Ambrosio L V Taravella",
    city: "Cordoba",
    country: "Argentina",
    iata_code: "COR",
    _geoloc: {
      lat: -31.323619,
      lng: -64.207953,
    },
    links_count: 26,
    objectID: "2443",
    uniqueID: "d74d17b4-1f70-4534-87f4-22b19bda6ff0",
  },
  {
    name: "Okayama",
    city: "Okayama",
    country: "Japan",
    iata_code: "OKJ",
    _geoloc: {
      lat: 34.756944,
      lng: 133.855278,
    },
    links_count: 26,
    objectID: "2327",
    uniqueID: "439ed869-08dc-4a2c-b5fd-691fa228d00b",
  },
  {
    name: "Taif",
    city: "Taif",
    country: "Saudi Arabia",
    iata_code: "TIF",
    _geoloc: {
      lat: 21.483418,
      lng: 40.544334,
    },
    links_count: 26,
    objectID: "2090",
    uniqueID: "bd06bb9c-77dc-41b5-99de-94f97746574f",
  },
  {
    name: "La Tontouta",
    city: "Noumea",
    country: "New Caledonia",
    iata_code: "NOU",
    _geoloc: {
      lat: -22.014553,
      lng: 166.212972,
    },
    links_count: 26,
    objectID: "2005",
    uniqueID: "70d2a353-6792-4462-9f4c-d204636fcb85",
  },
  {
    name: "C P A Carlos Rovirosa Intl",
    city: "Villahermosa",
    country: "Mexico",
    iata_code: "VSA",
    _geoloc: {
      lat: 17.997,
      lng: -92.817361,
    },
    links_count: 26,
    objectID: "1853",
    uniqueID: "ff919f5e-fa0b-4404-9212-173dc4140ab5",
  },
  {
    name: "Victoria Intl",
    city: "Victoria",
    country: "Canada",
    iata_code: "YYJ",
    _geoloc: {
      lat: 48.646944,
      lng: -123.425833,
    },
    links_count: 26,
    objectID: "184",
    uniqueID: "9a93bf31-bd57-4bd1-933e-346b2615732a",
  },
  {
    name: "Traian Vuia",
    city: "Timisoara",
    country: "Romania",
    iata_code: "TSR",
    _geoloc: {
      lat: 45.809861,
      lng: 21.337861,
    },
    links_count: 26,
    objectID: "1663",
    uniqueID: "10c0ad1e-e35c-42bb-96a8-d6e356e1a11f",
  },
  {
    name: "Mikonos",
    city: "Mykonos",
    country: "Greece",
    iata_code: "JMK",
    _geoloc: {
      lat: 37.435128,
      lng: 25.348103,
    },
    links_count: 26,
    objectID: "1468",
    uniqueID: "e6d6b4f3-c5d9-47de-bf47-23df07eb4ec5",
  },
  {
    name: "St Jacques",
    city: "Rennes",
    country: "France",
    iata_code: "RNS",
    _geoloc: {
      lat: 48.069508,
      lng: -1.734794,
    },
    links_count: 26,
    objectID: "1415",
    uniqueID: "ef686737-91a4-4f93-a974-435141d890de",
  },
  {
    name: "Mombasa Moi Intl",
    city: "Mombasa",
    country: "Kenya",
    iata_code: "MBA",
    _geoloc: {
      lat: -4.034833,
      lng: 39.59425,
    },
    links_count: 26,
    objectID: "1145",
    uniqueID: "0f8b14bf-d378-456f-9d37-57cea5a4d0c6",
  },
  {
    name: "Banjul Intl",
    city: "Banjul",
    country: "Gambia",
    iata_code: "BJL",
    _geoloc: {
      lat: 13.337961,
      lng: -16.652206,
    },
    links_count: 26,
    objectID: "1050",
    uniqueID: "e849ea3e-f1ac-4418-9b05-a805c90fa585",
  },
  {
    name: "Fort Mcmurray",
    city: "Fort Mcmurray",
    country: "Canada",
    iata_code: "YMM",
    _geoloc: {
      lat: 56.653333,
      lng: -111.221944,
    },
    links_count: 25,
    objectID: "90",
    uniqueID: "1d8d2c8f-47f5-4ad0-bb82-e705880d54c9",
  },
  {
    name: "Aniak Airport",
    city: "Aniak",
    country: "United States",
    iata_code: "ANI",
    _geoloc: {
      lat: 61.5816,
      lng: -159.543,
    },
    links_count: 25,
    objectID: "5967",
    uniqueID: "2c284d16-d8b3-474c-ae5c-e0052ab16a1f",
  },
  {
    name: "Tokua Airport",
    city: "Tokua",
    country: "Papua New Guinea",
    iata_code: "RAB",
    _geoloc: {
      lat: -4.34046,
      lng: 152.38,
    },
    links_count: 25,
    objectID: "5435",
    uniqueID: "f4e665cd-9235-4664-a42a-ea8f94b10cd2",
  },
  {
    name: "Patna",
    city: "Patina",
    country: "India",
    iata_code: "PAT",
    _geoloc: {
      lat: 25.591317,
      lng: 85.087992,
    },
    links_count: 25,
    objectID: "3061",
    uniqueID: "dd014bb1-62f2-4f39-ad74-46432d9b9e90",
  },
  {
    name: "Pampulha Carlos Drummond De Andrade",
    city: "Belo Horizonte",
    country: "Brazil",
    iata_code: "PLU",
    _geoloc: {
      lat: -19.851181,
      lng: -43.950628,
    },
    links_count: 25,
    objectID: "2528",
    uniqueID: "ed6ced34-43a5-4b81-886a-d25417dba64b",
  },
  {
    name: "Gassim",
    city: "Gassim",
    country: "Saudi Arabia",
    iata_code: "ELQ",
    _geoloc: {
      lat: 26.302822,
      lng: 43.773911,
    },
    links_count: 25,
    objectID: "2067",
    uniqueID: "0eb8b194-b0d5-43ca-b4d6-9bf50bf07aa2",
  },
  {
    name: "Kamuzu Intl",
    city: "Lilongwe",
    country: "Malawi",
    iata_code: "LLW",
    _geoloc: {
      lat: -13.789378,
      lng: 33.781,
    },
    links_count: 25,
    objectID: "1016",
    uniqueID: "7202ca32-8840-4339-b60f-68f39cce82cf",
  },
  {
    name: "Huai An Lianshui Airport",
    city: "Huai An",
    country: "China",
    iata_code: "HIA",
    _geoloc: {
      lat: 33.7772,
      lng: 119.1478,
    },
    links_count: 24,
    objectID: "7898",
    uniqueID: "a9d72db8-ece6-43e4-8ff7-c29131534d25",
  },
  {
    name: "Karmoy",
    city: "Haugesund",
    country: "Norway",
    iata_code: "HAU",
    _geoloc: {
      lat: 59.345267,
      lng: 5.208364,
    },
    links_count: 24,
    objectID: "645",
    uniqueID: "3b1ff1cc-f84f-48ec-9b86-63e939552600",
  },
  {
    name: "Wanxian Airport",
    city: "Wanxian",
    country: "China",
    iata_code: "WXN",
    _geoloc: {
      lat: 30.8361,
      lng: 108.406,
    },
    links_count: 24,
    objectID: "6403",
    uniqueID: "37bfc923-ec2d-4bc7-89cc-39608b26136a",
  },
  {
    name: "Yulin Airport",
    city: "Yulin",
    country: "China",
    iata_code: "UYN",
    _geoloc: {
      lat: 38.2692,
      lng: 109.731,
    },
    links_count: 24,
    objectID: "6368",
    uniqueID: "7aa97b35-69c7-45af-a9de-b4d5f559edf9",
  },
  {
    name: "Namangan Airport",
    city: "Namangan",
    country: "Uzbekistan",
    iata_code: "NMA",
    _geoloc: {
      lat: 40.9846,
      lng: 71.5567,
    },
    links_count: 24,
    objectID: "6150",
    uniqueID: "566033ba-774c-49b9-9f19-fc3868117ac7",
  },
  {
    name: "Misratah Airport",
    city: "Misratah",
    country: "Libya",
    iata_code: "MRA",
    _geoloc: {
      lat: 32.325,
      lng: 15.061,
    },
    links_count: 24,
    objectID: "5804",
    uniqueID: "4f47faee-e1a8-44df-9f68-9e9f0f647308",
  },
  {
    name: "Aden Adde International Airport",
    city: "Mogadishu",
    country: "Somalia",
    iata_code: "MGQ",
    _geoloc: {
      lat: 2.01444,
      lng: 45.3047,
    },
    links_count: 24,
    objectID: "5687",
    uniqueID: "170ee16b-e66f-4220-8523-8c3287d29e46",
  },
  {
    name: "Tampere Pirkkala",
    city: "Tampere",
    country: "Finland",
    iata_code: "TMP",
    _geoloc: {
      lat: 61.414147,
      lng: 23.604392,
    },
    links_count: 24,
    objectID: "458",
    uniqueID: "d55acb18-91e9-4765-b2b2-d6e08172eb65",
  },
  {
    name: "Broome",
    city: "Broome",
    country: "Australia",
    iata_code: "BME",
    _geoloc: {
      lat: -17.8,
      lng: 122.2,
    },
    links_count: 24,
    objectID: "4319",
    uniqueID: "5b5366a6-de3f-4a71-848f-12e18667fadb",
  },
  {
    name: "Central Illinois Rgnl",
    city: "Bloomington",
    country: "United States",
    iata_code: "BMI",
    _geoloc: {
      lat: 40.477111,
      lng: -88.915917,
    },
    links_count: 24,
    objectID: "4037",
    uniqueID: "236a43c6-1f69-4310-bdfa-ab835e4fe758",
  },
  {
    name: "Billings Logan International Airport",
    city: "Billings",
    country: "United States",
    iata_code: "BIL",
    _geoloc: {
      lat: 45.80921,
      lng: -108.537654,
    },
    links_count: 24,
    objectID: "4021",
    uniqueID: "b34f8e21-f2dd-4bf7-8d5d-96abeea60d3a",
  },
  {
    name: "Asheville Regional Airport",
    city: "Asheville",
    country: "United States",
    iata_code: "AVL",
    _geoloc: {
      lat: 35.436194,
      lng: -82.541806,
    },
    links_count: 24,
    objectID: "4007",
    uniqueID: "a0a741ca-63a8-441e-a30f-469ace341cb6",
  },
  {
    name: "Kobe",
    city: "Kobe",
    country: "Japan",
    iata_code: "UKB",
    _geoloc: {
      lat: 34.632778,
      lng: 135.223889,
    },
    links_count: 24,
    objectID: "3943",
    uniqueID: "231ea7a3-aff8-41cc-9604-64353718f5a5",
  },
  {
    name: "Gainesville Rgnl",
    city: "Gainesville",
    country: "United States",
    iata_code: "GNV",
    _geoloc: {
      lat: 29.690056,
      lng: -82.271778,
    },
    links_count: 24,
    objectID: "3472",
    uniqueID: "a11c1824-c05d-4054-8e53-2c0185145763",
  },
  {
    name: "Alice Springs",
    city: "Alice Springs",
    country: "Australia",
    iata_code: "ASP",
    _geoloc: {
      lat: -23.806667,
      lng: 133.902222,
    },
    links_count: 24,
    objectID: "3319",
    uniqueID: "1e5d1c9d-073b-4960-8205-ff99ff96472d",
  },
  {
    name: "Jammu",
    city: "Jammu",
    country: "India",
    iata_code: "IXJ",
    _geoloc: {
      lat: 32.689142,
      lng: 74.837389,
    },
    links_count: 24,
    objectID: "3100",
    uniqueID: "d39c24a0-600b-4e5c-b08d-54b546c7e1b5",
  },
  {
    name: "Terrance B Lettsome Intl",
    city: "Tortola",
    country: "British Virgin Islands",
    iata_code: "EIS",
    _geoloc: {
      lat: 18.444834,
      lng: -64.542975,
    },
    links_count: 24,
    objectID: "2903",
    uniqueID: "ee981fba-1148-4281-b6b0-0c1a141f4485",
  },
  {
    name: "Rafael Nunez",
    city: "Cartagena",
    country: "Colombia",
    iata_code: "CTG",
    _geoloc: {
      lat: 10.442381,
      lng: -75.512961,
    },
    links_count: 24,
    objectID: "2714",
    uniqueID: "5f2ab12d-e0e5-4b67-8566-fa1c9ac0d94b",
  },
  {
    name: "Cataratas Intl",
    city: "Foz Do Iguacu",
    country: "Brazil",
    iata_code: "IGU",
    _geoloc: {
      lat: -25.59615,
      lng: -54.487206,
    },
    links_count: 24,
    objectID: "2554",
    uniqueID: "1a0c2db9-b444-499e-9a30-7cb3c0c35a71",
  },
  {
    name: "Matsuyama",
    city: "Matsuyama",
    country: "Japan",
    iata_code: "MYJ",
    _geoloc: {
      lat: 33.827222,
      lng: 132.699722,
    },
    links_count: 24,
    objectID: "2333",
    uniqueID: "22f1198a-e355-4808-a67c-80d08cce4cb8",
  },
  {
    name: "Multan Intl",
    city: "Multan",
    country: "Pakistan",
    iata_code: "MUX",
    _geoloc: {
      lat: 30.203222,
      lng: 71.419111,
    },
    links_count: 24,
    objectID: "2214",
    uniqueID: "c2045725-1e93-452a-9019-c5d4e587866e",
  },
  {
    name: "Juan Gualberto Gomez Intl",
    city: "Varadero",
    country: "Cuba",
    iata_code: "VRA",
    _geoloc: {
      lat: 23.034445,
      lng: -81.435278,
    },
    links_count: 24,
    objectID: "1923",
    uniqueID: "dacaa29e-3ba9-4ba7-adbc-bcb9543b8c1c",
  },
  {
    name: "Abraham Gonzalez Intl",
    city: "Ciudad Juarez",
    country: "Mexico",
    iata_code: "CJS",
    _geoloc: {
      lat: 31.636133,
      lng: -106.428667,
    },
    links_count: 24,
    objectID: "1796",
    uniqueID: "551064e1-5bf7-47ca-a3eb-759a19069618",
  },
  {
    name: "Bern Belp",
    city: "Bern",
    country: "Switzerland",
    iata_code: "BRN",
    _geoloc: {
      lat: 46.9141,
      lng: 7.497153,
    },
    links_count: 24,
    objectID: "1676",
    uniqueID: "893caea0-e5c2-419d-8276-237b4b1debce",
  },
  {
    name: "Iasi",
    city: "Iasi",
    country: "Romania",
    iata_code: "IAS",
    _geoloc: {
      lat: 47.178492,
      lng: 27.620631,
    },
    links_count: 24,
    objectID: "1655",
    uniqueID: "dcb1965c-0431-4d41-97a9-42837d6c52e0",
  },
  {
    name: "A Coruna",
    city: "La Coruna",
    country: "Spain",
    iata_code: "LCG",
    _geoloc: {
      lat: 43.302061,
      lng: -8.377256,
    },
    links_count: 24,
    objectID: "1220",
    uniqueID: "7ccc4b3f-39ea-4de7-babf-3b2b5b158d2c",
  },
  {
    name: "Burgas",
    city: "Bourgas",
    country: "Bulgaria",
    iata_code: "BOJ",
    _geoloc: {
      lat: 42.569583,
      lng: 27.515236,
    },
    links_count: 24,
    objectID: "1191",
    uniqueID: "13fc78d4-60dd-4efb-b715-b610452a35c3",
  },
  {
    name: "Zanzibar",
    city: "Zanzibar",
    country: "Tanzania",
    iata_code: "ZNZ",
    _geoloc: {
      lat: -6.222025,
      lng: 39.224886,
    },
    links_count: 24,
    objectID: "1186",
    uniqueID: "3f3a0560-b88c-4a37-bce6-02111561d1b1",
  },
  {
    name: "Juba",
    city: "Juba",
    country: "South Sudan",
    iata_code: "JUB",
    _geoloc: {
      lat: 4.872006,
      lng: 31.601117,
    },
    links_count: 24,
    objectID: "1173",
    uniqueID: "52535297-e129-4986-87a0-3c9698013180",
  },
  {
    name: "Sialkot Airport",
    city: "Sialkot",
    country: "Pakistan",
    iata_code: "SKT",
    _geoloc: {
      lat: 32.5356,
      lng: 74.3639,
    },
    links_count: 23,
    objectID: "6776",
    uniqueID: "cb2ebf52-d526-4fc1-afde-b664861428db",
  },
  {
    name: "Londrina",
    city: "Londrina",
    country: "Brazil",
    iata_code: "LDB",
    _geoloc: {
      lat: -23.333625,
      lng: -51.130072,
    },
    links_count: 23,
    objectID: "2581",
    uniqueID: "8498a864-29af-4eff-b7f9-5d9ec907ae0e",
  },
  {
    name: "Nausori Intl",
    city: "Nausori",
    country: "Fiji",
    iata_code: "SUV",
    _geoloc: {
      lat: -18.043267,
      lng: 178.559228,
    },
    links_count: 23,
    objectID: "1961",
    uniqueID: "8d4ca0e1-d42e-4d16-91f1-c185ffbbf031",
  },
  {
    name: "Linz",
    city: "Linz",
    country: "Austria",
    iata_code: "LNZ",
    _geoloc: {
      lat: 48.233219,
      lng: 14.187511,
    },
    links_count: 23,
    objectID: "1611",
    uniqueID: "7a065f2d-dc1e-4df8-bda9-e2791e16d7c9",
  },
  {
    name: "Bale Mulhouse",
    city: "Mulhouse",
    country: "France",
    iata_code: "MLH",
    _geoloc: {
      lat: 47.589583,
      lng: 7.529914,
    },
    links_count: 23,
    objectID: "1423",
    uniqueID: "7f5dca3d-8b8e-4374-a830-e93514b28696",
  },
  {
    name: "Nouakchott",
    city: "Nouakschott",
    country: "Mauritania",
    iata_code: "NKC",
    _geoloc: {
      lat: 18.097856,
      lng: -15.947956,
    },
    links_count: 23,
    objectID: "1094",
    uniqueID: "33185719-ec0e-4918-9555-b0efe240a628",
  },
  {
    name: "Fuyang Airport",
    city: "Fuyang",
    country: "China",
    iata_code: "FUG",
    _geoloc: {
      lat: 32.9,
      lng: 115.816667,
    },
    links_count: 22,
    objectID: "7503",
    uniqueID: "456e3ab4-006e-4df6-afb1-365f065e11c2",
  },
  {
    name: "Manzhouli",
    city: "Manzhouli",
    country: "China",
    iata_code: "NZH",
    _geoloc: {
      lat: 49.566667,
      lng: 117.329444,
    },
    links_count: 22,
    objectID: "6954",
    uniqueID: "91188bf7-6f01-4290-91bc-c89b88d2caa9",
  },
  {
    name: "Jasionka",
    city: "Rzeszow",
    country: "Poland",
    iata_code: "RZE",
    _geoloc: {
      lat: 50.109958,
      lng: 22.019,
    },
    links_count: 22,
    objectID: "675",
    uniqueID: "a6650bb4-e0ce-42b9-b64b-bf1c0f3dd8d8",
  },
  {
    name: "Kjevik",
    city: "Kristiansand",
    country: "Norway",
    iata_code: "KRS",
    _geoloc: {
      lat: 58.204214,
      lng: 8.085369,
    },
    links_count: 22,
    objectID: "638",
    uniqueID: "14b07149-75e0-4f06-b94d-ff6a31af98a1",
  },
  {
    name: "Datong Airport",
    city: "Datong",
    country: "China",
    iata_code: "DAT",
    _geoloc: {
      lat: 40.0603,
      lng: 113.482,
    },
    links_count: 22,
    objectID: "6344",
    uniqueID: "a7fb4534-e2cd-47cc-b749-131bb0c9f2bf",
  },
  {
    name: "Belgorod International Airport",
    city: "Belgorod",
    country: "Russia",
    iata_code: "EGO",
    _geoloc: {
      lat: 50.6438,
      lng: 36.5901,
    },
    links_count: 22,
    objectID: "6156",
    uniqueID: "c6120ad5-2480-49cb-9544-8f452097547f",
  },
  {
    name: "Santo Pekoa International Airport",
    city: "Santo",
    country: "Vanuatu",
    iata_code: "SON",
    _geoloc: {
      lat: -15.505033,
      lng: 167.219742,
    },
    links_count: 22,
    objectID: "5908",
    uniqueID: "27877771-3a8f-4cdd-8cbc-da8ab4ee829a",
  },
  {
    name: "Kirkwall",
    city: "Kirkwall",
    country: "United Kingdom",
    iata_code: "KOI",
    _geoloc: {
      lat: 58.957778,
      lng: -2.905,
    },
    links_count: 22,
    objectID: "529",
    uniqueID: "ddb17320-a80e-47f4-8c25-fa08c6fc0510",
  },
  {
    name: "Isle Of Man",
    city: "Isle Of Man",
    country: "Isle of Man",
    iata_code: "IOM",
    _geoloc: {
      lat: 54.083333,
      lng: -4.623889,
    },
    links_count: 22,
    objectID: "520",
    uniqueID: "4b882189-6a59-4178-9fcf-5ffc71a9dbca",
  },
  {
    name: "South Bend Rgnl",
    city: "South Bend",
    country: "United States",
    iata_code: "SBN",
    _geoloc: {
      lat: 41.708661,
      lng: -86.31725,
    },
    links_count: 22,
    objectID: "4359",
    uniqueID: "ccd4dad8-893b-45d9-8528-9ab9385a6d1d",
  },
  {
    name: "San Pedro",
    city: "San Pedro",
    country: "Belize",
    iata_code: "SPR",
    _geoloc: {
      lat: 17.913936,
      lng: -87.971075,
    },
    links_count: 22,
    objectID: "4279",
    uniqueID: "48810cb9-7842-4d1d-8bcd-579a40805572",
  },
  {
    name: "Nadzab",
    city: "Nadzab",
    country: "Papua New Guinea",
    iata_code: "LAE",
    _geoloc: {
      lat: -6.569828,
      lng: 146.726242,
    },
    links_count: 22,
    objectID: "4",
    uniqueID: "b29b32b3-d3cd-4a85-8eb5-8085b997c6f2",
  },
  {
    name: "Riyan",
    city: "Mukalla",
    country: "Yemen",
    iata_code: "RIY",
    _geoloc: {
      lat: 14.662639,
      lng: 49.375028,
    },
    links_count: 22,
    objectID: "3974",
    uniqueID: "bc93ad39-1362-4871-bcaa-c94031ebc6b6",
  },
  {
    name: "Chiang Rai Intl",
    city: "Chiang Rai",
    country: "Thailand",
    iata_code: "CEI",
    _geoloc: {
      lat: 19.952342,
      lng: 99.882928,
    },
    links_count: 22,
    objectID: "3932",
    uniqueID: "b248aab1-f647-40a6-9302-929476b516ec",
  },
  {
    name: "Paderborn Lippstadt",
    city: "Paderborn",
    country: "Germany",
    iata_code: "PAD",
    _geoloc: {
      lat: 51.614089,
      lng: 8.616317,
    },
    links_count: 22,
    objectID: "371",
    uniqueID: "e55ef03c-276a-4121-8bc9-dce485c1b1ca",
  },
  {
    name: "Munster Osnabruck",
    city: "Munster",
    country: "Germany",
    iata_code: "FMO",
    _geoloc: {
      lat: 52.134642,
      lng: 7.684831,
    },
    links_count: 22,
    objectID: "341",
    uniqueID: "3a966f83-b38d-42d4-b37c-c629c84325f8",
  },
  {
    name: "Port Blair",
    city: "Port Blair",
    country: "India",
    iata_code: "IXZ",
    _geoloc: {
      lat: 11.641161,
      lng: 92.729744,
    },
    links_count: 22,
    objectID: "3146",
    uniqueID: "1c17d069-a0ee-46fe-bb39-93b3e3741c06",
  },
  {
    name: "Raipur",
    city: "Raipur",
    country: "India",
    iata_code: "RPR",
    _geoloc: {
      lat: 21.180406,
      lng: 81.738753,
    },
    links_count: 22,
    objectID: "3020",
    uniqueID: "a2d4604a-358a-4258-8320-4c9f5c90111f",
  },
  {
    name: "Mount Hagen",
    city: "Mount Hagen",
    country: "Papua New Guinea",
    iata_code: "HGU",
    _geoloc: {
      lat: -5.826789,
      lng: 144.295861,
    },
    links_count: 22,
    objectID: "3",
    uniqueID: "8f3f45da-0a9b-4011-b86e-bb848a18b8a3",
  },
  {
    name: "Mukhino",
    city: "Ulan-ude",
    country: "Russia",
    iata_code: "UUD",
    _geoloc: {
      lat: 51.807764,
      lng: 107.437644,
    },
    links_count: 22,
    objectID: "2938",
    uniqueID: "614c0c1b-5770-4600-b881-768fba4d0053",
  },
  {
    name: "Kadala",
    city: "Chita",
    country: "Russia",
    iata_code: "HTA",
    _geoloc: {
      lat: 52.026317,
      lng: 113.305556,
    },
    links_count: 22,
    objectID: "2935",
    uniqueID: "fc0502f6-d4cc-4303-a10d-b1ba7010272c",
  },
  {
    name: "Robert L Bradshaw",
    city: "Basse Terre",
    country: "Saint Kitts and Nevis",
    iata_code: "SKB",
    _geoloc: {
      lat: 17.311194,
      lng: -62.718667,
    },
    links_count: 22,
    objectID: "2891",
    uniqueID: "86d9358e-72a6-4781-94ee-e596535de45b",
  },
  {
    name: "General Manuel Carlos Piar",
    city: "Guayana",
    country: "Venezuela",
    iata_code: "PZO",
    _geoloc: {
      lat: 8.288528,
      lng: -62.760361,
    },
    links_count: 22,
    objectID: "2856",
    uniqueID: "5f01c41a-2ee3-439b-b7b4-f577078ab06d",
  },
  {
    name: "General Jose Antonio Anzoategui Intl",
    city: "Barcelona",
    country: "Venezuela",
    iata_code: "BLA",
    _geoloc: {
      lat: 10.107139,
      lng: -64.689161,
    },
    links_count: 22,
    objectID: "2821",
    uniqueID: "819560e2-fba4-44e5-b87a-2735bd29df13",
  },
  {
    name: "Teniente Alejandro Velasco Astete Intl",
    city: "Cuzco",
    country: "Peru",
    iata_code: "CUZ",
    _geoloc: {
      lat: -13.535722,
      lng: -71.938781,
    },
    links_count: 22,
    objectID: "2812",
    uniqueID: "f214dcfb-d62e-4956-a0be-462fb0c353ba",
  },
  {
    name: "Gustavo Rojas Pinilla",
    city: "San Andres Island",
    country: "Colombia",
    iata_code: "ADZ",
    _geoloc: {
      lat: 12.583594,
      lng: -81.711192,
    },
    links_count: 22,
    objectID: "2749",
    uniqueID: "6f7ce8b3-b79e-4547-be9b-2eaccdf8471e",
  },
  {
    name: "Olaya Herrera",
    city: "Medellin",
    country: "Colombia",
    iata_code: "EOH",
    _geoloc: {
      lat: 6.219958,
      lng: -75.590519,
    },
    links_count: 22,
    objectID: "2729",
    uniqueID: "1916a9f3-3c79-48da-b91f-df9f96edaa8c",
  },
  {
    name: "Cerro Moreno Intl",
    city: "Antofagasta",
    country: "Chile",
    iata_code: "ANF",
    _geoloc: {
      lat: -23.444478,
      lng: -70.4451,
    },
    links_count: 22,
    objectID: "2651",
    uniqueID: "92e632c0-77cc-48c0-8a4b-28e478893080",
  },
  {
    name: "Senador Petronio Portella",
    city: "Teresina",
    country: "Brazil",
    iata_code: "THE",
    _geoloc: {
      lat: -5.059942,
      lng: -42.823478,
    },
    links_count: 22,
    objectID: "2623",
    uniqueID: "11bbeef2-3409-43a0-b874-86a0dd9e14a5",
  },
  {
    name: "Maraba",
    city: "Maraba",
    country: "Brazil",
    iata_code: "MAB",
    _geoloc: {
      lat: -5.368589,
      lng: -49.138025,
    },
    links_count: 22,
    objectID: "2584",
    uniqueID: "a07acaee-875c-41e9-ab3e-c063d04ca9e0",
  },
  {
    name: "Presidente Castro Pinto",
    city: "Joao Pessoa",
    country: "Brazil",
    iata_code: "JPA",
    _geoloc: {
      lat: -7.148381,
      lng: -34.950681,
    },
    links_count: 22,
    objectID: "2575",
    uniqueID: "acfe1f79-1d40-44db-b572-5153fb7c7303",
  },
  {
    name: "Miyazaki",
    city: "Miyazaki",
    country: "Japan",
    iata_code: "KMI",
    _geoloc: {
      lat: 31.877222,
      lng: 131.448611,
    },
    links_count: 22,
    objectID: "2308",
    uniqueID: "81062516-c762-429d-8c78-cfde316cb908",
  },
  {
    name: "Zahedan Intl",
    city: "Zahedan",
    country: "Iran",
    iata_code: "ZAH",
    _geoloc: {
      lat: 29.475686,
      lng: 60.906189,
    },
    links_count: 22,
    objectID: "2167",
    uniqueID: "b08c31ac-4966-4350-9277-6ca105699465",
  },
  {
    name: "Cibao Intl",
    city: "Santiago",
    country: "Dominican Republic",
    iata_code: "STI",
    _geoloc: {
      lat: 19.406092,
      lng: -70.604689,
    },
    links_count: 22,
    objectID: "1764",
    uniqueID: "20218ea2-5882-4ec9-afd3-17652b942177",
  },
  {
    name: "Tivat",
    city: "Tivat",
    country: "Montenegro",
    iata_code: "TIV",
    _geoloc: {
      lat: 42.404664,
      lng: 18.723286,
    },
    links_count: 22,
    objectID: "1743",
    uniqueID: "d120cdaa-0d7a-430c-93b9-368a475bd0cd",
  },
  {
    name: "Lajes",
    city: "Lajes (terceira Island)",
    country: "Portugal",
    iata_code: "TER",
    _geoloc: {
      lat: 38.761842,
      lng: -27.090797,
    },
    links_count: 22,
    objectID: "1629",
    uniqueID: "aee8e560-f634-4f81-98fd-013b320e68b4",
  },
  {
    name: "Ronchi Dei Legionari",
    city: "Ronchi De Legionari",
    country: "Italy",
    iata_code: "TRS",
    _geoloc: {
      lat: 45.8275,
      lng: 13.472222,
    },
    links_count: 22,
    objectID: "1545",
    uniqueID: "f746018a-54bc-4958-b395-98a2dbfce78b",
  },
  {
    name: "Aktio",
    city: "Preveza",
    country: "Greece",
    iata_code: "PVK",
    _geoloc: {
      lat: 38.925467,
      lng: 20.765311,
    },
    links_count: 22,
    objectID: "1470",
    uniqueID: "d1267fd0-a9de-4b25-baa8-670a95a57ec6",
  },
  {
    name: "Bellegarde",
    city: "Limoges",
    country: "France",
    iata_code: "LIG",
    _geoloc: {
      lat: 45.862778,
      lng: 1.179444,
    },
    links_count: 22,
    objectID: "1270",
    uniqueID: "2a5c325f-a198-49e2-9be6-545d26d56535",
  },
  {
    name: "Roumaniere",
    city: "Bergerac",
    country: "France",
    iata_code: "EGC",
    _geoloc: {
      lat: 44.825279,
      lng: 0.518611,
    },
    links_count: 22,
    objectID: "1265",
    uniqueID: "f5d9fdc0-3e0c-40b0-bee3-53e66ffbb3bf",
  },
  {
    name: "Vigo",
    city: "Vigo",
    country: "Spain",
    iata_code: "VGO",
    _geoloc: {
      lat: 42.2318,
      lng: -8.626775,
    },
    links_count: 22,
    objectID: "1250",
    uniqueID: "f497ec15-e0f4-4daa-9e11-9afc0ea3b26f",
  },
  {
    name: "Sale",
    city: "Rabat",
    country: "Morocco",
    iata_code: "RBA",
    _geoloc: {
      lat: 34.051467,
      lng: -6.751519,
    },
    links_count: 22,
    objectID: "1072",
    uniqueID: "1f0784c0-ef7b-4221-b0b4-48165556cb9e",
  },
  {
    name: "Al Najaf International Airport",
    city: "Najaf",
    country: "Iraq",
    iata_code: "NJF",
    _geoloc: {
      lat: 31.991667,
      lng: 44.404167,
    },
    links_count: 21,
    objectID: "7490",
    uniqueID: "9442c338-c9d5-479b-a854-db17f90434c1",
  },
  {
    name: "Saint Barthelemy",
    city: "Gustavia",
    country: "France",
    iata_code: "SBH",
    _geoloc: {
      lat: 17.9023,
      lng: -62.8324,
    },
    links_count: 21,
    objectID: "6460",
    uniqueID: "fd4af591-1f89-42d6-935c-283bfa79a0cb",
  },
  {
    name: "Inverness",
    city: "Inverness",
    country: "United Kingdom",
    iata_code: "INV",
    _geoloc: {
      lat: 57.5425,
      lng: -4.0475,
    },
    links_count: 21,
    objectID: "533",
    uniqueID: "6cb90703-40e3-4a31-959f-4a536fddc6df",
  },
  {
    name: "Yeager",
    city: "Charleston",
    country: "United States",
    iata_code: "CRW",
    _geoloc: {
      lat: 38.373147,
      lng: -81.593189,
    },
    links_count: 21,
    objectID: "4285",
    uniqueID: "964f2303-a864-4eeb-9d6a-5a5b7f44c764",
  },
  {
    name: "Aeroporto de Porto Seguro",
    city: "Porto Seguro",
    country: "Brazil",
    iata_code: "BPS",
    _geoloc: {
      lat: -16.438611,
      lng: -39.080833,
    },
    links_count: 21,
    objectID: "4209",
    uniqueID: "1287915e-10aa-484d-8d12-447e45d87379",
  },
  {
    name: "El Tari",
    city: "Kupang",
    country: "Indonesia",
    iata_code: "KOE",
    _geoloc: {
      lat: -10.171583,
      lng: 123.671136,
    },
    links_count: 21,
    objectID: "3916",
    uniqueID: "2bd87dc4-9975-441a-92c0-c303a5b34100",
  },
  {
    name: "Eglin Afb",
    city: "Valparaiso",
    country: "United States",
    iata_code: "VPS",
    _geoloc: {
      lat: 30.48325,
      lng: -86.5254,
    },
    links_count: 21,
    objectID: "3873",
    uniqueID: "be176950-96cd-4b53-aea4-48a7f9daa59f",
  },
  {
    name: "Birsa Munda",
    city: "Ranchi",
    country: "India",
    iata_code: "IXR",
    _geoloc: {
      lat: 23.31425,
      lng: 85.321675,
    },
    links_count: 21,
    objectID: "3063",
    uniqueID: "4af020bf-6e0b-4b1c-be04-1f8a7ff77adc",
  },
  {
    name: "Shymkent",
    city: "Chimkent",
    country: "Kazakhstan",
    iata_code: "CIT",
    _geoloc: {
      lat: 42.364167,
      lng: 69.478889,
    },
    links_count: 21,
    objectID: "2914",
    uniqueID: "f058ccd9-af50-47bb-97cf-a753a973d08d",
  },
  {
    name: "George F L Charles",
    city: "Castries",
    country: "Saint Lucia",
    iata_code: "SLU",
    _geoloc: {
      lat: 14.020228,
      lng: -60.992936,
    },
    links_count: 21,
    objectID: "2893",
    uniqueID: "8cbcc2c4-51af-4260-9653-b242b8af9a66",
  },
  {
    name: "El Plumerillo",
    city: "Mendoza",
    country: "Argentina",
    iata_code: "MDZ",
    _geoloc: {
      lat: -32.831717,
      lng: -68.792856,
    },
    links_count: 21,
    objectID: "2452",
    uniqueID: "c9351375-9ebd-4429-aac6-a2153e79cabb",
  },
  {
    name: "Prince Said Ibrahim",
    city: "Moroni",
    country: "Comoros",
    iata_code: "HAH",
    _geoloc: {
      lat: -11.533661,
      lng: 43.27185,
    },
    links_count: 20,
    objectID: "912",
    uniqueID: "2ed71d33-770c-4a24-adb7-e5adc4c59e63",
  },
  {
    name: "Ndola",
    city: "Ndola",
    country: "Zambia",
    iata_code: "NLA",
    _geoloc: {
      lat: -12.998139,
      lng: 28.664944,
    },
    links_count: 20,
    objectID: "910",
    uniqueID: "6fadc31a-6801-4812-9f5c-bcd21fdfabc4",
  },
  {
    name: "Unalakleet Airport",
    city: "Unalakleet",
    country: "United States",
    iata_code: "UNK",
    _geoloc: {
      lat: 63.888333,
      lng: -160.798889,
    },
    links_count: 20,
    objectID: "7088",
    uniqueID: "3deffd66-6a99-4c37-8dcf-858e719e0697",
  },
  {
    name: "Jining Airport ",
    city: "Jining",
    country: "China",
    iata_code: "JNG",
    _geoloc: {
      lat: 35.417,
      lng: 116.533,
    },
    links_count: 20,
    objectID: "6944",
    uniqueID: "ab9fbad4-a8d7-47c8-9c54-bcbafe3598f8",
  },
  {
    name: "Hami Airport",
    city: "Hami",
    country: "China",
    iata_code: "HMI",
    _geoloc: {
      lat: 42.8414,
      lng: 93.6692,
    },
    links_count: 20,
    objectID: "6743",
    uniqueID: "257b1e19-7d60-465e-906b-228cfdea89a9",
  },
  {
    name: "La Rochelle-Ile de Re",
    city: "La Rochelle",
    country: "France",
    iata_code: "LRH",
    _geoloc: {
      lat: 46.1792,
      lng: -1.19528,
    },
    links_count: 20,
    objectID: "6493",
    uniqueID: "05deb96a-40ec-42c7-b38c-0f446b1a8bd8",
  },
  {
    name: "Mt. Fuji Shizuoka Airport",
    city: "Shizuoka",
    country: "Japan",
    iata_code: "FSZ",
    _geoloc: {
      lat: 34.796111,
      lng: 138.189444,
    },
    links_count: 20,
    objectID: "6476",
    uniqueID: "e722155d-5578-463f-8b73-48e466b4b6cc",
  },
  {
    name: "Jiamusi Airport",
    city: "Jiamusi",
    country: "China",
    iata_code: "JMU",
    _geoloc: {
      lat: 46.843394,
      lng: 130.465389,
    },
    links_count: 20,
    objectID: "6411",
    uniqueID: "1a428f63-9e03-4e5f-ab70-f00a2e02df21",
  },
  {
    name: "Korla Airport",
    city: "Korla",
    country: "China",
    iata_code: "KRL",
    _geoloc: {
      lat: 41.6978,
      lng: 86.1289,
    },
    links_count: 20,
    objectID: "6407",
    uniqueID: "f521fda1-6c52-4140-a272-86e9bc0c72c5",
  },
  {
    name: "Alta",
    city: "Alta",
    country: "Norway",
    iata_code: "ALF",
    _geoloc: {
      lat: 69.976111,
      lng: 23.371667,
    },
    links_count: 20,
    objectID: "632",
    uniqueID: "526b077e-8a98-420e-84b4-76559f2c0571",
  },
  {
    name: "Sulaymaniyah International Airport",
    city: "Sulaymaniyah",
    country: "Iraq",
    iata_code: "ISU",
    _geoloc: {
      lat: 35.5608,
      lng: 45.3147,
    },
    links_count: 20,
    objectID: "5952",
    uniqueID: "d5895ea7-e692-456b-9af0-bd3161836f70",
  },
  {
    name: "Norwich",
    city: "Norwich",
    country: "United Kingdom",
    iata_code: "NWI",
    _geoloc: {
      lat: 52.675833,
      lng: 1.282778,
    },
    links_count: 20,
    objectID: "547",
    uniqueID: "dd0b4052-779e-4c05-9521-fa003dcd27c6",
  },
  {
    name: "Atyrau",
    city: "Atyrau",
    country: "Kazakhstan",
    iata_code: "GUW",
    _geoloc: {
      lat: 47.121944,
      lng: 51.821389,
    },
    links_count: 20,
    objectID: "4357",
    uniqueID: "07a0813e-e6cf-4efc-a3af-7ea357c0adea",
  },
  {
    name: "Mudanjiang",
    city: "Mudanjiang",
    country: "China",
    iata_code: "MDG",
    _geoloc: {
      lat: 44.523889,
      lng: 129.568889,
    },
    links_count: 20,
    objectID: "4334",
    uniqueID: "b0918a6c-99c4-49fd-bb1d-9bc9bc8a2b89",
  },
  {
    name: "Falconara",
    city: "Ancona",
    country: "Italy",
    iata_code: "AOI",
    _geoloc: {
      lat: 43.616389,
      lng: 13.362222,
    },
    links_count: 20,
    objectID: "4309",
    uniqueID: "44015ea8-2d15-445f-822f-2e90785d68de",
  },
  {
    name: "Yibin",
    city: "Yibin",
    country: "China",
    iata_code: "YBP",
    _geoloc: {
      lat: 28.800278,
      lng: 104.544444,
    },
    links_count: 20,
    objectID: "4110",
    uniqueID: "2920d65a-3d5b-4877-bc12-b3e3a2d846a4",
  },
  {
    name: "Monterey Peninsula",
    city: "Monterey",
    country: "United States",
    iata_code: "MRY",
    _geoloc: {
      lat: 36.587,
      lng: -121.842944,
    },
    links_count: 20,
    objectID: "3948",
    uniqueID: "5defbb9e-3e12-4a30-b2de-89f0012668c4",
  },
  {
    name: "Portland Intl Jetport",
    city: "Portland",
    country: "United States",
    iata_code: "PWM",
    _geoloc: {
      lat: 43.646161,
      lng: -70.309281,
    },
    links_count: 20,
    objectID: "3862",
    uniqueID: "7ece523d-50aa-4fa3-8cf8-c82c1eeecf18",
  },
  {
    name: "Bellingham Intl",
    city: "Bellingham",
    country: "United States",
    iata_code: "BLI",
    _geoloc: {
      lat: 48.792694,
      lng: -122.537528,
    },
    links_count: 20,
    objectID: "3777",
    uniqueID: "d9f52da0-bb42-4040-aef7-50c03737bba5",
  },
  {
    name: "Atlantic City Intl",
    city: "Atlantic City",
    country: "United States",
    iata_code: "ACY",
    _geoloc: {
      lat: 39.457583,
      lng: -74.577167,
    },
    links_count: 20,
    objectID: "3524",
    uniqueID: "dbf2a2e1-ffa7-4044-a14a-cfc09ee4b1a1",
  },
  {
    name: "Varanasi",
    city: "Varanasi",
    country: "India",
    iata_code: "VNS",
    _geoloc: {
      lat: 25.452358,
      lng: 82.859342,
    },
    links_count: 20,
    objectID: "3084",
    uniqueID: "fc81977e-b83d-4ac7-8e4f-a8d628e6311b",
  },
  {
    name: "Gumrak",
    city: "Volgograd",
    country: "Russia",
    iata_code: "VOG",
    _geoloc: {
      lat: 48.782528,
      lng: 44.345544,
    },
    links_count: 20,
    objectID: "2967",
    uniqueID: "de066a24-d8b3-47bc-bc4a-f46c2dbeb7f3",
  },
  {
    name: "Thyna",
    city: "Sfax",
    country: "Tunisia",
    iata_code: "SFA",
    _geoloc: {
      lat: 34.717953,
      lng: 10.690972,
    },
    links_count: 20,
    objectID: "295",
    uniqueID: "cf736c51-347b-454e-b0b9-d6a8190037f1",
  },
  {
    name: "Arturo Michelena Intl",
    city: "Valencia",
    country: "Venezuela",
    iata_code: "VLN",
    _geoloc: {
      lat: 10.149733,
      lng: -67.9284,
    },
    links_count: 20,
    objectID: "2868",
    uniqueID: "0b9b8f2d-ac75-46db-b486-a273680344e9",
  },
  {
    name: "Rodriguez Ballon",
    city: "Arequipa",
    country: "Peru",
    iata_code: "AQP",
    _geoloc: {
      lat: -16.341072,
      lng: -71.583083,
    },
    links_count: 20,
    objectID: "2802",
    uniqueID: "3a7bbc8b-98fa-4b2d-9e19-e3d31a6a053d",
  },
  {
    name: "Johan A Pengel Intl",
    city: "Zandery",
    country: "Suriname",
    iata_code: "PBM",
    _geoloc: {
      lat: 5.452831,
      lng: -55.187783,
    },
    links_count: 20,
    objectID: "2773",
    uniqueID: "b4e76691-480f-4f97-b4b6-0f5c0c9610e2",
  },
  {
    name: "Ernesto Cortissoz",
    city: "Barranquilla",
    country: "Colombia",
    iata_code: "BAQ",
    _geoloc: {
      lat: 10.889589,
      lng: -74.780819,
    },
    links_count: 20,
    objectID: "2710",
    uniqueID: "2770a743-d124-4452-896a-dffd6fdaa2b1",
  },
  {
    name: "El Tepual Intl",
    city: "Puerto Montt",
    country: "Chile",
    iata_code: "PMC",
    _geoloc: {
      lat: -41.438886,
      lng: -73.093953,
    },
    links_count: 20,
    objectID: "2665",
    uniqueID: "863dc84d-cbca-40e0-8497-35907c4e8f8a",
  },
  {
    name: "Regional De Maringa Silvio Name Junior",
    city: "Maringa",
    country: "Brazil",
    iata_code: "MGF",
    _geoloc: {
      lat: -23.476392,
      lng: -52.016406,
    },
    links_count: 20,
    objectID: "2586",
    uniqueID: "0f845423-530d-4275-b3a3-27e85ec54a62",
  },
  {
    name: "Iloilo",
    city: "Iloilo",
    country: "Philippines",
    iata_code: "ILO",
    _geoloc: {
      lat: 10.713044,
      lng: 122.545297,
    },
    links_count: 20,
    objectID: "2429",
    uniqueID: "ee10c15c-9bf7-40f8-971e-660c8f4eed17",
  },
  {
    name: "Hakodate",
    city: "Hakodate",
    country: "Japan",
    iata_code: "HKD",
    _geoloc: {
      lat: 41.77,
      lng: 140.821944,
    },
    links_count: 20,
    objectID: "2288",
    uniqueID: "e78c2acc-d42c-49ac-93db-0b68b176cfdf",
  },
  {
    name: "Francisco C Ada Saipan Intl",
    city: "Saipan",
    country: "Northern Mariana Islands",
    iata_code: "SPN",
    _geoloc: {
      lat: 15.119003,
      lng: 145.729356,
    },
    links_count: 20,
    objectID: "2244",
    uniqueID: "551bc3f8-d141-46c3-b220-f7e7d027c9f8",
  },
  {
    name: "Rasht",
    city: "Rasht",
    country: "Iran",
    iata_code: "RAS",
    _geoloc: {
      lat: 37.325314,
      lng: 49.605817,
    },
    links_count: 20,
    objectID: "2123",
    uniqueID: "635ac69e-3213-49a9-8c19-fa721d98b847",
  },
  {
    name: "Frank Pais Intl",
    city: "Holguin",
    country: "Cuba",
    iata_code: "HOG",
    _geoloc: {
      lat: 20.785589,
      lng: -76.315108,
    },
    links_count: 20,
    objectID: "1910",
    uniqueID: "33bd0ed7-0ecd-4dcc-956e-5557b8a4564c",
  },
  {
    name: "Torreon Intl",
    city: "Torreon",
    country: "Mexico",
    iata_code: "TRC",
    _geoloc: {
      lat: 25.568278,
      lng: -103.410583,
    },
    links_count: 20,
    objectID: "1845",
    uniqueID: "ea9ca783-86d8-47df-bdef-0481e233f29d",
  },
  {
    name: "General Francisco J Mujica Intl",
    city: "Morelia",
    country: "Mexico",
    iata_code: "MLM",
    _geoloc: {
      lat: 19.849944,
      lng: -101.0255,
    },
    links_count: 20,
    objectID: "1821",
    uniqueID: "9e504e8f-672f-4409-9977-63a6204bd5ec",
  },
  {
    name: "General Manuel Marquez De Leon Intl",
    city: "La Paz",
    country: "Mexico",
    iata_code: "LAP",
    _geoloc: {
      lat: 24.072694,
      lng: -110.362475,
    },
    links_count: 20,
    objectID: "1816",
    uniqueID: "51a45d61-f768-41d8-ac7b-47ee80f87057",
  },
  {
    name: "Casa De Campo Intl",
    city: "La Romana",
    country: "Dominican Republic",
    iata_code: "LRM",
    _geoloc: {
      lat: 18.450711,
      lng: -68.911833,
    },
    links_count: 20,
    objectID: "1759",
    uniqueID: "b36e6bca-d51e-4147-a005-06f8aaa3059f",
  },
  {
    name: "Trabzon",
    city: "Trabzon",
    country: "Turkey",
    iata_code: "TZX",
    _geoloc: {
      lat: 40.995108,
      lng: 39.789728,
    },
    links_count: 20,
    objectID: "1726",
    uniqueID: "454bc508-c1a4-4648-8af0-416371ddcc9a",
  },
  {
    name: "Prince George",
    city: "Prince George",
    country: "Canada",
    iata_code: "YXS",
    _geoloc: {
      lat: 53.889444,
      lng: -122.678889,
    },
    links_count: 20,
    objectID: "172",
    uniqueID: "d8e88ed9-02d0-4ae1-ba83-e83b10af9a9e",
  },
  {
    name: "Mitilini",
    city: "Mytilini",
    country: "Greece",
    iata_code: "MJT",
    _geoloc: {
      lat: 39.056667,
      lng: 26.598333,
    },
    links_count: 20,
    objectID: "1469",
    uniqueID: "6e75a6cd-09cc-4891-b990-41f749641b37",
  },
  {
    name: "Kalamata",
    city: "Kalamata",
    country: "Greece",
    iata_code: "KLX",
    _geoloc: {
      lat: 37.068319,
      lng: 22.025525,
    },
    links_count: 20,
    objectID: "1456",
    uniqueID: "6e1b81ba-eed6-4b94-bd3d-df0a596e8e4f",
  },
  {
    name: "Salvaza",
    city: "Carcassonne",
    country: "France",
    iata_code: "CCF",
    _geoloc: {
      lat: 43.215978,
      lng: 2.306317,
    },
    links_count: 20,
    objectID: "1352",
    uniqueID: "632075e8-e2fe-44e1-be21-c6d57419373f",
  },
  {
    name: "Greater Moncton Intl",
    city: "Moncton",
    country: "Canada",
    iata_code: "YQM",
    _geoloc: {
      lat: 46.112221,
      lng: -64.678611,
    },
    links_count: 20,
    objectID: "117",
    uniqueID: "7ebb0cb9-758b-496f-b207-101dddeb795a",
  },
  {
    name: "Luxor Intl",
    city: "Luxor",
    country: "Egypt",
    iata_code: "LXR",
    _geoloc: {
      lat: 25.671028,
      lng: 32.706583,
    },
    links_count: 20,
    objectID: "1132",
    uniqueID: "e1e4a8ff-c70b-485c-a300-0c3630402e57",
  },
  {
    name: "Angads",
    city: "Oujda",
    country: "Morocco",
    iata_code: "OUD",
    _geoloc: {
      lat: 34.78715,
      lng: -1.923986,
    },
    links_count: 20,
    objectID: "1070",
    uniqueID: "0134ee16-be62-465f-a2b8-71850775cbcf",
  },
  {
    name: "Lubumbashi Intl",
    city: "Lubumashi",
    country: "Congo (Kinshasa)",
    iata_code: "FBM",
    _geoloc: {
      lat: -11.591333,
      lng: 27.530889,
    },
    links_count: 20,
    objectID: "1038",
    uniqueID: "3e3551ba-3f52-4e5d-9d06-d38c8618fb76",
  },
  {
    name: "Ndjamena Hassan Djamous",
    city: "N'djamena",
    country: "Chad",
    iata_code: "NDJ",
    _geoloc: {
      lat: 12.133689,
      lng: 15.034019,
    },
    links_count: 19,
    objectID: "999",
    uniqueID: "33e7e87d-1c95-4214-ab29-d3b947278d2a",
  },
  {
    name: "Osnova International Airport",
    city: "Kharkov",
    country: "Ukraine",
    iata_code: "HRK",
    _geoloc: {
      lat: 49.924786,
      lng: 36.289986,
    },
    links_count: 19,
    objectID: "6105",
    uniqueID: "95f72910-5983-4355-8db0-bb8d867fe3ab",
  },
  {
    name: "Maastricht",
    city: "Maastricht",
    country: "Netherlands",
    iata_code: "MST",
    _geoloc: {
      lat: 50.911658,
      lng: 5.770144,
    },
    links_count: 19,
    objectID: "582",
    uniqueID: "beefdf1b-4a83-4104-81ad-442fcd2f3f33",
  },
  {
    name: "Wilkes Barre Scranton Intl",
    city: "Scranton",
    country: "United States",
    iata_code: "AVP",
    _geoloc: {
      lat: 41.338478,
      lng: -75.723403,
    },
    links_count: 19,
    objectID: "4286",
    uniqueID: "2eaa0329-3f1f-41ef-893e-60b00c045a15",
  },
  {
    name: "Rick Husband Amarillo Intl",
    city: "Amarillo",
    country: "United States",
    iata_code: "AMA",
    _geoloc: {
      lat: 35.219369,
      lng: -101.705931,
    },
    links_count: 19,
    objectID: "3840",
    uniqueID: "dde8b185-a291-4adf-aa46-b1a1b6539133",
  },
  {
    name: "Lubbock Preston Smith Intl",
    city: "Lubbock",
    country: "United States",
    iata_code: "LBB",
    _geoloc: {
      lat: 33.663639,
      lng: -101.822778,
    },
    links_count: 19,
    objectID: "3829",
    uniqueID: "bf7c3084-9a4b-4166-8a10-4c57325f22ee",
  },
  {
    name: "Jorge Wilsterman",
    city: "Cochabamba",
    country: "Bolivia",
    iata_code: "CBB",
    _geoloc: {
      lat: -17.421058,
      lng: -66.177114,
    },
    links_count: 19,
    objectID: "2759",
    uniqueID: "a5f18c34-94f4-4e63-8df5-c8eab53158e2",
  },
  {
    name: "Diego Aracena Intl",
    city: "Iquique",
    country: "Chile",
    iata_code: "IQQ",
    _geoloc: {
      lat: -20.535222,
      lng: -70.181275,
    },
    links_count: 19,
    objectID: "2649",
    uniqueID: "5e109d3f-0f98-44f1-85a4-1c0eb6aca0ee",
  },
  {
    name: "Babelthuap",
    city: "Babelthuap",
    country: "Palau",
    iata_code: "ROR",
    _geoloc: {
      lat: 7.367303,
      lng: 134.544278,
    },
    links_count: 19,
    objectID: "2256",
    uniqueID: "297b752b-e777-4138-ae17-efd11c7fe77b",
  },
  {
    name: "Yenbo",
    city: "Yenbo",
    country: "Saudi Arabia",
    iata_code: "YNB",
    _geoloc: {
      lat: 24.144244,
      lng: 38.06335,
    },
    links_count: 19,
    objectID: "2096",
    uniqueID: "f35637c1-5f66-450c-b741-378201da7397",
  },
  {
    name: "Kuujjuaq",
    city: "Quujjuaq",
    country: "Canada",
    iata_code: "YVP",
    _geoloc: {
      lat: 58.096111,
      lng: -68.426944,
    },
    links_count: 19,
    objectID: "154",
    uniqueID: "609cf7fb-56e4-44e7-a77a-b63ee28a7fc4",
  },
  {
    name: "Nampula",
    city: "Nampula",
    country: "Mozambique",
    iata_code: "APL",
    _geoloc: {
      lat: -15.105611,
      lng: 39.2818,
    },
    links_count: 18,
    objectID: "984",
    uniqueID: "ae8225d5-2bd5-4f9b-b714-110091fcd9b4",
  },
  {
    name: "Sir Seretse Khama Intl",
    city: "Gaberone",
    country: "Botswana",
    iata_code: "GBE",
    _geoloc: {
      lat: -24.555225,
      lng: 25.918208,
    },
    links_count: 18,
    objectID: "881",
    uniqueID: "5a6d3654-6aee-41ce-aec2-e270e18b1602",
  },
  {
    name: "Port Elizabeth Intl",
    city: "Port Elizabeth",
    country: "South Africa",
    iata_code: "PLZ",
    _geoloc: {
      lat: -33.984919,
      lng: 25.617275,
    },
    links_count: 18,
    objectID: "838",
    uniqueID: "edfd6128-d6e0-4b05-b989-685391c1dc2b",
  },
  {
    name: "Sohag International",
    city: "Sohag",
    country: "Egypt",
    iata_code: "HMB",
    _geoloc: {
      lat: 26.341189,
      lng: 31.742983,
    },
    links_count: 18,
    objectID: "8235",
    uniqueID: "8451f960-1cbb-412d-9f77-1081558c30b5",
  },
  {
    name: "Nuuk",
    city: "Godthaab",
    country: "Greenland",
    iata_code: "GOH",
    _geoloc: {
      lat: 64.190922,
      lng: -51.678064,
    },
    links_count: 18,
    objectID: "8",
    uniqueID: "241a2c8f-25f6-47e6-8bdf-f7a73ff4205d",
  },
  {
    name: "Hatay Airport",
    city: "Hatay",
    country: "Turkey",
    iata_code: "HTY",
    _geoloc: {
      lat: 36.362778,
      lng: 36.282222,
    },
    links_count: 18,
    objectID: "7453",
    uniqueID: "6a0aa813-0c15-4869-a9bf-2b67156ec9ff",
  },
  {
    name: "Jing Gang Shan Airport",
    city: "Ji An",
    country: "China",
    iata_code: "JGS",
    _geoloc: {
      lat: 26.8997,
      lng: 114.7375,
    },
    links_count: 18,
    objectID: "6428",
    uniqueID: "9804f57a-c858-4c49-8b86-5438352970d8",
  },
  {
    name: "Evenes",
    city: "Harstad/Narvik",
    country: "Norway",
    iata_code: "EVE",
    _geoloc: {
      lat: 68.4913,
      lng: 16.678108,
    },
    links_count: 18,
    objectID: "641",
    uniqueID: "21911aa1-042d-40af-8028-dcf0c5b83834",
  },
  {
    name: "Dachuan Airport",
    city: "Dazhou",
    country: "China",
    iata_code: "DAX",
    _geoloc: {
      lat: 31.3,
      lng: 107.5,
    },
    links_count: 18,
    objectID: "6397",
    uniqueID: "78d58c0e-60c8-40cc-b80b-e9f091823c84",
  },
  {
    name: "Cheongju International Airport",
    city: "Chongju",
    country: "South Korea",
    iata_code: "CJJ",
    _geoloc: {
      lat: 36.7166,
      lng: 127.499119,
    },
    links_count: 18,
    objectID: "6008",
    uniqueID: "fe0199b9-073a-4b58-abaf-a61d62c615eb",
  },
  {
    name: "Robin Hood Doncaster Sheffield Airport",
    city: "Doncaster",
    country: "Sheffield",
    iata_code: "DSA",
    _geoloc: {
      lat: 0,
      lng: 53.474722,
    },
    links_count: 18,
    objectID: "5562",
    uniqueID: "034251de-2b14-4563-86a0-0185a85dbae7",
  },
  {
    name: "Upernavik Airport",
    city: "Upernavik",
    country: "Greenland",
    iata_code: "JUV",
    _geoloc: {
      lat: 72.7902,
      lng: -56.1306,
    },
    links_count: 18,
    objectID: "5448",
    uniqueID: "aacb5321-834b-4172-b2af-bc956fb7597b",
  },
  {
    name: "Blackpool",
    city: "Blackpool",
    country: "United Kingdom",
    iata_code: "BLK",
    _geoloc: {
      lat: 53.771667,
      lng: -3.028611,
    },
    links_count: 18,
    objectID: "514",
    uniqueID: "05fb8285-6510-4a62-94d7-8456c345cfe5",
  },
  {
    name: "Talagi",
    city: "Arkhangelsk",
    country: "Russia",
    iata_code: "ARH",
    _geoloc: {
      lat: 64.360281,
      lng: 40.430167,
    },
    links_count: 18,
    objectID: "4362",
    uniqueID: "b92e7467-f669-430b-a5a5-d0c67f582146",
  },
  {
    name: "Luoyang Airport",
    city: "Luoyang",
    country: "China",
    iata_code: "LYA",
    _geoloc: {
      lat: 34.41,
      lng: 112.28,
    },
    links_count: 18,
    objectID: "4148",
    uniqueID: "dbadee42-8ecd-4be9-b25e-e86fe386bf3b",
  },
  {
    name: "Rogue Valley Intl Medford",
    city: "Medford",
    country: "United States",
    iata_code: "MFR",
    _geoloc: {
      lat: 42.374228,
      lng: -122.8735,
    },
    links_count: 18,
    objectID: "4101",
    uniqueID: "0b53aa27-2c0c-482f-a2fc-2ff507e2acef",
  },
  {
    name: "Appleton",
    city: "Appleton",
    country: "United States",
    iata_code: "ATW",
    _geoloc: {
      lat: 44.257526,
      lng: -88.507576,
    },
    links_count: 18,
    objectID: "4047",
    uniqueID: "27e69324-f00f-4e34-8808-fd40e2ddcedd",
  },
  {
    name: "Gulfport-Biloxi",
    city: "Gulfport",
    country: "United States",
    iata_code: "GPT",
    _geoloc: {
      lat: 30.407278,
      lng: -89.070111,
    },
    links_count: 18,
    objectID: "4038",
    uniqueID: "f8f3e9be-eb26-4087-a483-b26decff5907",
  },
  {
    name: "Evansville Regional",
    city: "Evansville",
    country: "United States",
    iata_code: "EVV",
    _geoloc: {
      lat: 38.036997,
      lng: -87.532364,
    },
    links_count: 18,
    objectID: "4018",
    uniqueID: "7c3e1d26-fe0e-42be-b076-6d8b94e38f32",
  },
  {
    name: "Mobile Rgnl",
    city: "Mobile",
    country: "United States",
    iata_code: "MOB",
    _geoloc: {
      lat: 30.691231,
      lng: -88.242814,
    },
    links_count: 18,
    objectID: "3782",
    uniqueID: "b4b365ac-fc6f-4e36-9fcf-a21aebe40f17",
  },
  {
    name: "Four Corners Rgnl",
    city: "Farmington",
    country: "United States",
    iata_code: "FMN",
    _geoloc: {
      lat: 36.74125,
      lng: -108.229944,
    },
    links_count: 18,
    objectID: "3743",
    uniqueID: "e87d5874-3f10-4ca6-8072-155ede1a9e38",
  },
  {
    name: "Newport News Williamsburg Intl",
    city: "Newport News",
    country: "United States",
    iata_code: "PHF",
    _geoloc: {
      lat: 37.131894,
      lng: -76.492989,
    },
    links_count: 18,
    objectID: "3633",
    uniqueID: "9c99289b-1614-4916-82eb-2437c73d9756",
  },
  {
    name: "Saarbrucken",
    city: "Saarbruecken",
    country: "Germany",
    iata_code: "SCN",
    _geoloc: {
      lat: 49.214553,
      lng: 7.109508,
    },
    links_count: 18,
    objectID: "349",
    uniqueID: "6bbba807-7151-4f86-804a-3c14fe3e35bc",
  },
  {
    name: "Hobart",
    city: "Hobart",
    country: "Australia",
    iata_code: "HBA",
    _geoloc: {
      lat: -42.836111,
      lng: 147.510278,
    },
    links_count: 18,
    objectID: "3336",
    uniqueID: "596a7c73-069b-4715-bd46-445290c8dc10",
  },
  {
    name: "Sibu",
    city: "Sibu",
    country: "Malaysia",
    iata_code: "SBW",
    _geoloc: {
      lat: 2.261603,
      lng: 111.985322,
    },
    links_count: 18,
    objectID: "3267",
    uniqueID: "73bbf29a-0085-44e4-85f4-cba23008a739",
  },
  {
    name: "Trichy",
    city: "Tiruchirappalli",
    country: "India",
    iata_code: "TRZ",
    _geoloc: {
      lat: 10.765364,
      lng: 78.709722,
    },
    links_count: 18,
    objectID: "3152",
    uniqueID: "0c64fa82-96e0-4e21-ab0a-616d97daf600",
  },
  {
    name: "Chandigarh",
    city: "Chandigarh",
    country: "India",
    iata_code: "IXC",
    _geoloc: {
      lat: 30.673469,
      lng: 76.788542,
    },
    links_count: 18,
    objectID: "3089",
    uniqueID: "466c24cb-774e-40dd-9bee-05be5ca46ea2",
  },
  {
    name: "Amritsar",
    city: "Amritsar",
    country: "India",
    iata_code: "ATQ",
    _geoloc: {
      lat: 31.709594,
      lng: 74.797264,
    },
    links_count: 18,
    objectID: "3081",
    uniqueID: "63b65692-d481-4b25-8585-568bab256112",
  },
  {
    name: "Imphal",
    city: "Imphal",
    country: "India",
    iata_code: "IMF",
    _geoloc: {
      lat: 24.75995,
      lng: 93.896697,
    },
    links_count: 18,
    objectID: "3050",
    uniqueID: "5b01d60d-bc4d-46f6-8026-3a07dcb7f1c9",
  },
  {
    name: "Syktyvkar",
    city: "Syktyvkar",
    country: "Russia",
    iata_code: "SCW",
    _geoloc: {
      lat: 61.64705,
      lng: 50.84505,
    },
    links_count: 18,
    objectID: "2989",
    uniqueID: "4ca0b650-e112-4471-aeb0-2f66c2b0a545",
  },
  {
    name: "Flamingo",
    city: "Kralendijk",
    country: "Netherlands Antilles",
    iata_code: "BON",
    _geoloc: {
      lat: 12.131044,
      lng: -68.268511,
    },
    links_count: 18,
    objectID: "2896",
    uniqueID: "ee4e79d9-7f96-48d1-8f7e-23c8a21dd046",
  },
  {
    name: "Henry E Rohlsen",
    city: "St. Croix Island",
    country: "Virgin Islands",
    iata_code: "STX",
    _geoloc: {
      lat: 17.701889,
      lng: -64.798556,
    },
    links_count: 18,
    objectID: "2884",
    uniqueID: "9909a1b5-5481-4da0-bde5-1f6401c33346",
  },
  {
    name: "Governador Jorge Teixeira De Oliveira",
    city: "Porto Velho",
    country: "Brazil",
    iata_code: "PVH",
    _geoloc: {
      lat: -8.709294,
      lng: -63.902281,
    },
    links_count: 18,
    objectID: "2607",
    uniqueID: "17971de9-46a8-4412-8c0e-45ea26760e35",
  },
  {
    name: "Ilheus",
    city: "Ilheus",
    country: "Brazil",
    iata_code: "IOS",
    _geoloc: {
      lat: -14.815964,
      lng: -39.033197,
    },
    links_count: 18,
    objectID: "2569",
    uniqueID: "e150df68-b52c-48b1-b33b-454f2440d574",
  },
  {
    name: "Puerto Princesa",
    city: "Puerto Princesa",
    country: "Philippines",
    iata_code: "PPS",
    _geoloc: {
      lat: 9.742119,
      lng: 118.758731,
    },
    links_count: 18,
    objectID: "2433",
    uniqueID: "3ef84020-a78c-4a5a-8f4e-202a8629a00a",
  },
  {
    name: "Toyama",
    city: "Toyama",
    country: "Japan",
    iata_code: "TOY",
    _geoloc: {
      lat: 36.648333,
      lng: 137.1875,
    },
    links_count: 18,
    objectID: "2324",
    uniqueID: "64d66200-7878-4c16-adfa-c8892b5593cf",
  },
  {
    name: "Kumamoto",
    city: "Kumamoto",
    country: "Japan",
    iata_code: "KMJ",
    _geoloc: {
      lat: 32.837319,
      lng: 130.85505,
    },
    links_count: 18,
    objectID: "2312",
    uniqueID: "8e49be42-1e1b-40d1-8f14-6937ffae828e",
  },
  {
    name: "Oita",
    city: "Oita",
    country: "Japan",
    iata_code: "OIT",
    _geoloc: {
      lat: 33.479444,
      lng: 131.737222,
    },
    links_count: 18,
    objectID: "2310",
    uniqueID: "78937eed-9d43-4139-b58d-180fdfbc52c8",
  },
  {
    name: "Annaba",
    city: "Annaba",
    country: "Algeria",
    iata_code: "AAE",
    _geoloc: {
      lat: 36.822225,
      lng: 7.809167,
    },
    links_count: 18,
    objectID: "220",
    uniqueID: "b1bf0aad-2c8d-48c5-b707-4c8e5561bca5",
  },
  {
    name: "Tabuk",
    city: "Tabuk",
    country: "Saudi Arabia",
    iata_code: "TUU",
    _geoloc: {
      lat: 28.365417,
      lng: 36.618889,
    },
    links_count: 18,
    objectID: "2089",
    uniqueID: "901dbf0c-9aa6-43e9-9640-8dd8c55b4329",
  },
  {
    name: "Exuma Intl",
    city: "Great Exuma",
    country: "Bahamas",
    iata_code: "GGT",
    _geoloc: {
      lat: 23.562631,
      lng: -75.877958,
    },
    links_count: 18,
    objectID: "1941",
    uniqueID: "05ca49ab-9b9a-4fa9-a515-9160061bff95",
  },
  {
    name: "Abel Santamaria",
    city: "Santa Clara",
    country: "Cuba",
    iata_code: "SNU",
    _geoloc: {
      lat: 22.492192,
      lng: -79.943611,
    },
    links_count: 18,
    objectID: "1918",
    uniqueID: "3d1355d7-5554-4d70-9cb5-b3be0fc49b5d",
  },
  {
    name: "Marcos A Gelabert Intl",
    city: "Panama",
    country: "Panama",
    iata_code: "PAC",
    _geoloc: {
      lat: 8.973339,
      lng: -79.555583,
    },
    links_count: 18,
    objectID: "1869",
    uniqueID: "1d763ef1-f734-413d-93d0-59c3b7ca8cb0",
  },
  {
    name: "Ixtapa Zihuatanejo Intl",
    city: "Zihuatanejo",
    country: "Mexico",
    iata_code: "ZIH",
    _geoloc: {
      lat: 17.601569,
      lng: -101.460536,
    },
    links_count: 18,
    objectID: "1856",
    uniqueID: "c34f0e31-4c23-4e6c-b025-c47db6dcb9f3",
  },
  {
    name: "Jesus Teran Intl",
    city: "Aguascalientes",
    country: "Mexico",
    iata_code: "AGU",
    _geoloc: {
      lat: 21.705558,
      lng: -102.317858,
    },
    links_count: 18,
    objectID: "1785",
    uniqueID: "bdeaf3c6-9a0a-46bb-9bca-ef616156cf07",
  },
  {
    name: "Oguzeli",
    city: "Gaziantep",
    country: "Turkey",
    iata_code: "GZT",
    _geoloc: {
      lat: 36.947183,
      lng: 37.478683,
    },
    links_count: 18,
    objectID: "1689",
    uniqueID: "132eb4cc-f8d5-443b-aabd-614c2339b5a6",
  },
  {
    name: "Pescara",
    city: "Pescara",
    country: "Italy",
    iata_code: "PSR",
    _geoloc: {
      lat: 42.431656,
      lng: 14.181067,
    },
    links_count: 18,
    objectID: "1505",
    uniqueID: "c18417a0-bef9-4db6-8a09-70bacca61194",
  },
  {
    name: "Samos",
    city: "Samos",
    country: "Greece",
    iata_code: "SMI",
    _geoloc: {
      lat: 37.689999,
      lng: 26.911667,
    },
    links_count: 18,
    objectID: "1476",
    uniqueID: "3b7ae45b-db7a-4c2f-8cfd-3526de8138ac",
  },
  {
    name: "Vias",
    city: "Beziers",
    country: "France",
    iata_code: "BZR",
    _geoloc: {
      lat: 43.323522,
      lng: 3.353903,
    },
    links_count: 18,
    objectID: "1360",
    uniqueID: "29245cc2-f13d-4924-b55c-a383ab97fc60",
  },
  {
    name: "Auvergne",
    city: "Clermont-Ferrand",
    country: "France",
    iata_code: "CFE",
    _geoloc: {
      lat: 45.786661,
      lng: 3.169169,
    },
    links_count: 18,
    objectID: "1330",
    uniqueID: "b93595e3-f692-4ccc-b721-f9888d0c915f",
  },
  {
    name: "Granada",
    city: "Granada",
    country: "Spain",
    iata_code: "GRX",
    _geoloc: {
      lat: 37.188731,
      lng: -3.777356,
    },
    links_count: 18,
    objectID: "1223",
    uniqueID: "f3551dfe-51c1-4072-830b-a76c357b3883",
  },
  {
    name: "Varna",
    city: "Varna",
    country: "Bulgaria",
    iata_code: "VAR",
    _geoloc: {
      lat: 43.232072,
      lng: 27.825106,
    },
    links_count: 18,
    objectID: "1196",
    uniqueID: "b365c8fd-fd89-47b9-8b43-485adc01da0e",
  },
  {
    name: "Belize City Municipal Airport",
    city: "Belize",
    country: "Belize",
    iata_code: "TZA",
    _geoloc: {
      lat: 17.5344,
      lng: -88.298,
    },
    links_count: 17,
    objectID: "6463",
    uniqueID: "2d1174b0-1ae9-4a22-9c67-e89d6418bba4",
  },
  {
    name: "Vance Winkworth Amory International Airport",
    city: "Charlestown",
    country: "Saint Kitts and Nevis",
    iata_code: "NEV",
    _geoloc: {
      lat: 17.205678,
      lng: -62.589869,
    },
    links_count: 17,
    objectID: "6079",
    uniqueID: "db67aae3-58e8-4d21-8563-cc838b230a32",
  },
  {
    name: "Midland Intl",
    city: "Midland",
    country: "United States",
    iata_code: "MAF",
    _geoloc: {
      lat: 31.942528,
      lng: -102.201914,
    },
    links_count: 17,
    objectID: "3652",
    uniqueID: "9938f5f1-a345-4360-8054-c7c23e7b8af5",
  },
  {
    name: "Kodiak",
    city: "Kodiak",
    country: "United States",
    iata_code: "ADQ",
    _geoloc: {
      lat: 57.749967,
      lng: -152.493856,
    },
    links_count: 17,
    objectID: "3531",
    uniqueID: "bec5c926-6566-40f3-97a9-b06725d52e75",
  },
  {
    name: "Liege",
    city: "Liege",
    country: "Belgium",
    iata_code: "LGG",
    _geoloc: {
      lat: 50.637417,
      lng: 5.443222,
    },
    links_count: 17,
    objectID: "309",
    uniqueID: "e9413ccb-569b-4142-8720-833e4f1afbab",
  },
  {
    name: "Bhubaneshwar",
    city: "Bhubaneswar",
    country: "India",
    iata_code: "BBI",
    _geoloc: {
      lat: 20.244364,
      lng: 85.817781,
    },
    links_count: 17,
    objectID: "3042",
    uniqueID: "86c3d623-e2a3-4401-86b5-20280696ea6a",
  },
  {
    name: "Mirny",
    city: "Mirnyj",
    country: "Russia",
    iata_code: "MJZ",
    _geoloc: {
      lat: 62.534689,
      lng: 114.038928,
    },
    links_count: 17,
    objectID: "2925",
    uniqueID: "77e7e9fe-e77f-4dba-9b39-cccf4ad565f3",
  },
  {
    name: "Queenstown",
    city: "Queenstown International",
    country: "New Zealand",
    iata_code: "ZQN",
    _geoloc: {
      lat: -45.021111,
      lng: 168.739167,
    },
    links_count: 17,
    objectID: "2030",
    uniqueID: "2e91330b-c65f-4ec3-99db-7108e6c25ac8",
  },
  {
    name: "Magenta",
    city: "Noumea",
    country: "New Caledonia",
    iata_code: "GEA",
    _geoloc: {
      lat: -22.258278,
      lng: 166.472806,
    },
    links_count: 17,
    objectID: "2001",
    uniqueID: "e8f57083-69af-4740-97a3-92a506c7b13a",
  },
  {
    name: "General Juan N Alvarez Intl",
    city: "Acapulco",
    country: "Mexico",
    iata_code: "ACA",
    _geoloc: {
      lat: 16.757061,
      lng: -99.753953,
    },
    links_count: 17,
    objectID: "1783",
    uniqueID: "8c100f08-9174-48c3-b620-3a7efba8ba96",
  },
  {
    name: "Pemba",
    city: "Pemba",
    country: "Mozambique",
    iata_code: "POL",
    _geoloc: {
      lat: -12.986753,
      lng: 40.522492,
    },
    links_count: 16,
    objectID: "985",
    uniqueID: "124ccb67-89c2-4b58-aab3-3c730a69d418",
  },
  {
    name: "Comiso",
    city: "Comiso",
    country: "Italy",
    iata_code: "CIY",
    _geoloc: {
      lat: 37,
      lng: 14.6144,
    },
    links_count: 16,
    objectID: "9327",
    uniqueID: "bb01e07c-f372-4481-8808-eb956f5bc3b4",
  },
  {
    name: "Bijie Feixiong Airport",
    city: "Bijie",
    country: "China",
    iata_code: "BFJ",
    _geoloc: {
      lat: 27.253,
      lng: 105.426,
    },
    links_count: 16,
    objectID: "9025",
    uniqueID: "00146353-6577-440e-9c71-511840b8570c",
  },
  {
    name: "Sondre Stromfjord",
    city: "Sondrestrom",
    country: "Greenland",
    iata_code: "SFJ",
    _geoloc: {
      lat: 67.016969,
      lng: -50.689325,
    },
    links_count: 16,
    objectID: "9",
    uniqueID: "7da52e9f-c9e7-453f-b885-4b0af8ed37ae",
  },
  {
    name: "Visby",
    city: "Visby",
    country: "Sweden",
    iata_code: "VBY",
    _geoloc: {
      lat: 57.662797,
      lng: 18.346211,
    },
    links_count: 16,
    objectID: "746",
    uniqueID: "16aa2a95-2d63-4a4a-b3db-1c5d60e9e6b3",
  },
  {
    name: "Umea",
    city: "Umea",
    country: "Sweden",
    iata_code: "UME",
    _geoloc: {
      lat: 63.791828,
      lng: 20.282758,
    },
    links_count: 16,
    objectID: "728",
    uniqueID: "daa2cb3d-e63f-4dc4-88ef-bb4bf36f893d",
  },
  {
    name: "Wuhai",
    city: "Wuhai",
    country: "China",
    iata_code: "WUA",
    _geoloc: {
      lat: 39.794444,
      lng: 106.799444,
    },
    links_count: 16,
    objectID: "6955",
    uniqueID: "43a1619f-9c92-4f5f-82f0-a8dbf57fd87d",
  },
  {
    name: "Ain Arnat Airport",
    city: "Setif",
    country: "Algeria",
    iata_code: "QSF",
    _geoloc: {
      lat: 36.1781,
      lng: 5.32449,
    },
    links_count: 16,
    objectID: "6492",
    uniqueID: "2fcdce4b-4ddb-486e-83bf-074e99eec77d",
  },
  {
    name: "Qiqihar Sanjiazi Airport",
    city: "Qiqihar",
    country: "China",
    iata_code: "NDG",
    _geoloc: {
      lat: 47.239628,
      lng: 123.918131,
    },
    links_count: 16,
    objectID: "6413",
    uniqueID: "c0e8b4e2-8ce7-457e-baed-4464b82228be",
  },
  {
    name: "Guangyuan Airport",
    city: "Guangyuan",
    country: "China",
    iata_code: "GYS",
    _geoloc: {
      lat: 32.3911,
      lng: 105.702,
    },
    links_count: 16,
    objectID: "6398",
    uniqueID: "f687f823-891c-4b2e-bc21-4132deb5a1b3",
  },
  {
    name: "Nanyang Airport",
    city: "Nanyang",
    country: "China",
    iata_code: "NNY",
    _geoloc: {
      lat: 32.9808,
      lng: 112.615,
    },
    links_count: 16,
    objectID: "6359",
    uniqueID: "c82a5fc6-d5bc-4c73-99fb-59b54a3ac516",
  },
  {
    name: "Changde Airport",
    city: "Changde",
    country: "China",
    iata_code: "CGD",
    _geoloc: {
      lat: 28.9189,
      lng: 111.64,
    },
    links_count: 16,
    objectID: "6352",
    uniqueID: "0c625062-ea09-4eb4-9764-fdc4cecb975b",
  },
  {
    name: "Nadym Airport",
    city: "Nadym",
    country: "Russia",
    iata_code: "NYM",
    _geoloc: {
      lat: 65.4809,
      lng: 72.6989,
    },
    links_count: 16,
    objectID: "6143",
    uniqueID: "8f11007a-243d-4c35-9d13-03481e8d0d32",
  },
  {
    name: "Khanty Mansiysk Airport",
    city: "Khanty-Mansiysk",
    country: "Russia",
    iata_code: "HMA",
    _geoloc: {
      lat: 61.028479,
      lng: 69.086067,
    },
    links_count: 16,
    objectID: "6138",
    uniqueID: "f28ec221-90f2-44f9-8b17-1360c7f22572",
  },
  {
    name: "Salekhard Airport",
    city: "Salekhard",
    country: "Russia",
    iata_code: "SLY",
    _geoloc: {
      lat: 66.590753,
      lng: 66.611042,
    },
    links_count: 16,
    objectID: "6137",
    uniqueID: "0592f8dd-b9f3-48b0-a588-4ceb63a39290",
  },
  {
    name: "Tri Cities Airport",
    city: "Pasco",
    country: "United States",
    iata_code: "PSC",
    _geoloc: {
      lat: 46.2647,
      lng: -119.119,
    },
    links_count: 16,
    objectID: "6133",
    uniqueID: "22e3ed40-decc-42d3-b7da-a5d89a1c3c8b",
  },
  {
    name: "Ust Kamenogorsk Airport",
    city: "Ust Kamenogorsk",
    country: "Kazakhstan",
    iata_code: "UKK",
    _geoloc: {
      lat: 50.0366,
      lng: 82.4942,
    },
    links_count: 16,
    objectID: "6086",
    uniqueID: "87e8c7e5-d2da-42a1-bc9b-a22aa2f7f2ab",
  },
  {
    name: "Aarhus",
    city: "Aarhus",
    country: "Denmark",
    iata_code: "AAR",
    _geoloc: {
      lat: 56.300017,
      lng: 10.619008,
    },
    links_count: 16,
    objectID: "607",
    uniqueID: "9833b3d2-0d72-49b9-a02d-87d11f829c38",
  },
  {
    name: "Pierre Regional Airport",
    city: "Pierre",
    country: "United States",
    iata_code: "PIR",
    _geoloc: {
      lat: 44.3827,
      lng: -100.286,
    },
    links_count: 16,
    objectID: "5761",
    uniqueID: "1e15ec8e-ea5b-4ce7-a06d-8d48066e1bec",
  },
  {
    name: "Fayetteville Regional Grannis Field",
    city: "Fayetteville",
    country: "United States",
    iata_code: "FAY",
    _geoloc: {
      lat: 34.9912,
      lng: -78.8803,
    },
    links_count: 16,
    objectID: "5738",
    uniqueID: "5efd9c3f-396f-472f-ba39-d0f807de3b7b",
  },
  {
    name: "Gheshm Airport",
    city: "Gheshm",
    country: "Iran",
    iata_code: "GSM",
    _geoloc: {
      lat: 26.9487,
      lng: 56.2688,
    },
    links_count: 16,
    objectID: "5712",
    uniqueID: "a235e5ee-0632-4926-9f8c-9902df8ff44c",
  },
  {
    name: "Red Lake Airport",
    city: "Red Lake",
    country: "Canada",
    iata_code: "YRL",
    _geoloc: {
      lat: 51.0669,
      lng: -93.7931,
    },
    links_count: 16,
    objectID: "5527",
    uniqueID: "efd3f6ea-28b3-4878-b000-730380dc7f28",
  },
  {
    name: "Inuvik Mike Zubko",
    city: "Inuvik",
    country: "Canada",
    iata_code: "YEV",
    _geoloc: {
      lat: 68.304167,
      lng: -133.482778,
    },
    links_count: 16,
    objectID: "54",
    uniqueID: "c2810d84-109e-4d96-b7b8-5e96ee74ed40",
  },
  {
    name: "Turku",
    city: "Turku",
    country: "Finland",
    iata_code: "TKU",
    _geoloc: {
      lat: 60.514142,
      lng: 22.262808,
    },
    links_count: 16,
    objectID: "460",
    uniqueID: "77fadd14-d54f-4875-8bc4-549c6471aa62",
  },
  {
    name: "Hammerfest Airport",
    city: "Hammerfest",
    country: "Norway",
    iata_code: "HFT",
    _geoloc: {
      lat: 70.679722,
      lng: 23.668889,
    },
    links_count: 16,
    objectID: "4325",
    uniqueID: "d99655d3-d5af-4a4b-8a2b-bbcd07c3a495",
  },
  {
    name: "Newcastle Airport",
    city: "Newcastle",
    country: "Australia",
    iata_code: "NTL",
    _geoloc: {
      lat: -32.78,
      lng: 151.83,
    },
    links_count: 16,
    objectID: "4320",
    uniqueID: "4ecfecb0-1e7f-4ddb-a4da-40b676d337e3",
  },
  {
    name: "Cheddi Jagan Intl",
    city: "Georgetown",
    country: "Guyana",
    iata_code: "GEO",
    _geoloc: {
      lat: 6.498553,
      lng: -58.254119,
    },
    links_count: 16,
    objectID: "4304",
    uniqueID: "b7fa5e81-d5aa-4ff7-99c1-6fd8cd9bfd8f",
  },
  {
    name: "Roberts Fld",
    city: "Redmond-Bend",
    country: "United States",
    iata_code: "RDM",
    _geoloc: {
      lat: 44.254066,
      lng: -121.149964,
    },
    links_count: 16,
    objectID: "4103",
    uniqueID: "31e86d26-9db1-460e-a854-658f88a46db4",
  },
  {
    name: "Westerland Sylt",
    city: "Westerland",
    country: "Germany",
    iata_code: "GWT",
    _geoloc: {
      lat: 54.91325,
      lng: 8.340472,
    },
    links_count: 16,
    objectID: "410",
    uniqueID: "cc6faf06-52fd-46fb-b09e-d43ff95b3386",
  },
  {
    name: "Rapid City Regional Airport",
    city: "Rapid City",
    country: "United States",
    iata_code: "RAP",
    _geoloc: {
      lat: 44.045278,
      lng: -103.057222,
    },
    links_count: 16,
    objectID: "4087",
    uniqueID: "211d5d1c-7b2f-44f0-845c-3a02e82733cf",
  },
  {
    name: "Grand Junction Regional",
    city: "Grand Junction",
    country: "United States",
    iata_code: "GJT",
    _geoloc: {
      lat: 39.122413,
      lng: -108.526735,
    },
    links_count: 16,
    objectID: "4063",
    uniqueID: "d206a0ea-dc6e-405b-a399-531c036af6b7",
  },
  {
    name: "Gallatin Field",
    city: "Bozeman",
    country: "United States",
    iata_code: "BZN",
    _geoloc: {
      lat: 45.777643,
      lng: -111.160151,
    },
    links_count: 16,
    objectID: "4020",
    uniqueID: "9cf9cff7-a682-4c37-8cdd-4e1fc07a4f65",
  },
  {
    name: "Kopitnari",
    city: "Kutaisi",
    country: "Georgia",
    iata_code: "KUT",
    _geoloc: {
      lat: 42.176653,
      lng: 42.482583,
    },
    links_count: 16,
    objectID: "3972",
    uniqueID: "c384bcec-e28c-4efe-a43e-c39442258515",
  },
  {
    name: "Santa Barbara Muni",
    city: "Santa Barbara",
    country: "United States",
    iata_code: "SBA",
    _geoloc: {
      lat: 34.426211,
      lng: -119.840372,
    },
    links_count: 16,
    objectID: "3949",
    uniqueID: "83fbcd9e-07b7-4f16-a73e-54e1e8a36c5e",
  },
  {
    name: "Adi Sumarmo Wiryokusumo",
    city: "Solo City",
    country: "Indonesia",
    iata_code: "SOC",
    _geoloc: {
      lat: -7.516089,
      lng: 110.756892,
    },
    links_count: 16,
    objectID: "3929",
    uniqueID: "2cf24efd-fe09-4963-9ada-c31f9988d152",
  },
  {
    name: "Baton Rouge Metro Ryan Fld",
    city: "Baton Rouge",
    country: "United States",
    iata_code: "BTR",
    _geoloc: {
      lat: 30.533167,
      lng: -91.149639,
    },
    links_count: 16,
    objectID: "3846",
    uniqueID: "235d2879-7d15-4239-872e-75495551ae46",
  },
  {
    name: "Austin Straubel Intl",
    city: "Green Bay",
    country: "United States",
    iata_code: "GRB",
    _geoloc: {
      lat: 44.485072,
      lng: -88.129589,
    },
    links_count: 16,
    objectID: "3653",
    uniqueID: "bb015848-1cf7-4400-a4ff-b30e41c7161b",
  },
  {
    name: "Shreveport Rgnl",
    city: "Shreveport",
    country: "United States",
    iata_code: "SHV",
    _geoloc: {
      lat: 32.446629,
      lng: -93.8256,
    },
    links_count: 16,
    objectID: "3609",
    uniqueID: "6d8cb4b4-f576-40f8-af11-f4ca8fe00cea",
  },
  {
    name: "Capital City",
    city: "Lansing",
    country: "United States",
    iata_code: "LAN",
    _geoloc: {
      lat: 42.7787,
      lng: -84.587357,
    },
    links_count: 16,
    objectID: "3544",
    uniqueID: "db54389d-cefa-4303-b877-fe0cde2e01f4",
  },
  {
    name: "Bangor Intl",
    city: "Bangor",
    country: "United States",
    iata_code: "BGR",
    _geoloc: {
      lat: 44.807444,
      lng: -68.828139,
    },
    links_count: 16,
    objectID: "3463",
    uniqueID: "3f193520-68da-4af2-af5e-455a3735372b",
  },
  {
    name: "Port Hedland Intl",
    city: "Port Hedland",
    country: "Australia",
    iata_code: "PHE",
    _geoloc: {
      lat: -20.377778,
      lng: 118.626389,
    },
    links_count: 16,
    objectID: "3349",
    uniqueID: "a6f9e931-1d9c-4830-b4fc-d45bd9c8352b",
  },
  {
    name: "Kununurra",
    city: "Kununurra",
    country: "Australia",
    iata_code: "KNX",
    _geoloc: {
      lat: -15.778056,
      lng: 128.7075,
    },
    links_count: 16,
    objectID: "3347",
    uniqueID: "b5a9a8c3-8947-40e5-ac58-27816cc21469",
  },
  {
    name: "Launceston",
    city: "Launceston",
    country: "Australia",
    iata_code: "LST",
    _geoloc: {
      lat: -41.545278,
      lng: 147.214167,
    },
    links_count: 16,
    objectID: "3337",
    uniqueID: "74519033-1699-4dc6-b547-2b0029b8290e",
  },
  {
    name: "Jefman",
    city: "Sorong",
    country: "Indonesia",
    iata_code: "SOQ",
    _geoloc: {
      lat: -0.926358,
      lng: 131.121194,
    },
    links_count: 16,
    objectID: "3261",
    uniqueID: "9eb880f1-eaaf-4b96-97fb-721d62dd126d",
  },
  {
    name: "Sultan Babullah",
    city: "Ternate",
    country: "Indonesia",
    iata_code: "TTE",
    _geoloc: {
      lat: 0.831414,
      lng: 127.381486,
    },
    links_count: 16,
    objectID: "3253",
    uniqueID: "49f1bcfd-11e3-4ff2-bf34-8c51c4d8cf30",
  },
  {
    name: "Heho",
    city: "Heho",
    country: "Burma",
    iata_code: "HEH",
    _geoloc: {
      lat: 20.747036,
      lng: 96.792044,
    },
    links_count: 16,
    objectID: "3213",
    uniqueID: "170c6173-24af-4172-99a7-3a3d4952c073",
  },
  {
    name: "Madurai",
    city: "Madurai",
    country: "India",
    iata_code: "IXM",
    _geoloc: {
      lat: 9.834508,
      lng: 78.093378,
    },
    links_count: 16,
    objectID: "3142",
    uniqueID: "5f1f16ad-07fa-45fe-ac63-cce6bb2e2493",
  },
  {
    name: "Agartala",
    city: "Agartala",
    country: "India",
    iata_code: "IXA",
    _geoloc: {
      lat: 23.886978,
      lng: 91.24045,
    },
    links_count: 16,
    objectID: "3038",
    uniqueID: "5be17845-7c1a-499e-b85a-bbea33acd7bd",
  },
  {
    name: "Chertovitskoye",
    city: "Voronezh",
    country: "Russia",
    iata_code: "VOZ",
    _geoloc: {
      lat: 51.814211,
      lng: 39.229589,
    },
    links_count: 16,
    objectID: "2987",
    uniqueID: "a110c99f-eb34-47c5-a42d-8f0fdc8b1c07",
  },
  {
    name: "Astrakhan",
    city: "Astrakhan",
    country: "Russia",
    iata_code: "ASF",
    _geoloc: {
      lat: 46.283333,
      lng: 48.006278,
    },
    links_count: 16,
    objectID: "2966",
    uniqueID: "bc5be064-82ca-48b3-8e1c-4db78dde4151",
  },
  {
    name: "Tte Av Jorge Henrich Arauz",
    city: "Trinidad",
    country: "Bolivia",
    iata_code: "TDD",
    _geoloc: {
      lat: -14.818739,
      lng: -64.918019,
    },
    links_count: 16,
    objectID: "2769",
    uniqueID: "858be341-2047-4ce7-82a1-5ee9e1524e45",
  },
  {
    name: "Capitan Oriel Lea Plaza",
    city: "Tarija",
    country: "Bolivia",
    iata_code: "TJA",
    _geoloc: {
      lat: -21.555736,
      lng: -64.701325,
    },
    links_count: 16,
    objectID: "2768",
    uniqueID: "54247bcf-fb32-446d-9a85-c77e5a1142bf",
  },
  {
    name: "Vanguardia",
    city: "Villavicencio",
    country: "Colombia",
    iata_code: "VVC",
    _geoloc: {
      lat: 4.167875,
      lng: -73.613761,
    },
    links_count: 16,
    objectID: "2756",
    uniqueID: "f1af6b21-e27a-4fc4-bf83-89893db65b6c",
  },
  {
    name: "Port Harcourt Intl",
    city: "Port Hartcourt",
    country: "Nigeria",
    iata_code: "PHC",
    _geoloc: {
      lat: 5.015494,
      lng: 6.949594,
    },
    links_count: 16,
    objectID: "275",
    uniqueID: "c405aaaf-ede9-4b06-ad82-183251cabd81",
  },
  {
    name: "Sao Jose Do Rio Preto",
    city: "Sao Jose Do Rio Preto",
    country: "Brazil",
    iata_code: "SJP",
    _geoloc: {
      lat: -20.816567,
      lng: -49.406511,
    },
    links_count: 16,
    objectID: "2619",
    uniqueID: "0ffdcdb5-5dea-4402-8ad6-f6815d9fbfc5",
  },
  {
    name: "Akita",
    city: "Akita",
    country: "Japan",
    iata_code: "AXT",
    _geoloc: {
      lat: 39.615556,
      lng: 140.218611,
    },
    links_count: 16,
    objectID: "2345",
    uniqueID: "ea02c53a-fcb3-44d5-a7cd-1808524a06a4",
  },
  {
    name: "Takamatsu",
    city: "Takamatsu",
    country: "Japan",
    iata_code: "TAK",
    _geoloc: {
      lat: 34.214167,
      lng: 134.015556,
    },
    links_count: 16,
    objectID: "2337",
    uniqueID: "f3aa1256-c623-400d-b315-aaf09dc8f700",
  },
  {
    name: "Yonago Kitaro",
    city: "Miho",
    country: "Japan",
    iata_code: "YGJ",
    _geoloc: {
      lat: 35.492222,
      lng: 133.236389,
    },
    links_count: 16,
    objectID: "2330",
    uniqueID: "27bf28d2-6924-4b26-ada3-56cbec9916f4",
  },
  {
    name: "Basrah Intl",
    city: "Basrah",
    country: "Iraq",
    iata_code: "BSR",
    _geoloc: {
      lat: 30.549069,
      lng: 47.662142,
    },
    links_count: 16,
    objectID: "2234",
    uniqueID: "5874f23b-f4ae-48d5-a416-be0371580e88",
  },
  {
    name: "Madang",
    city: "Madang",
    country: "Papua New Guinea",
    iata_code: "MAG",
    _geoloc: {
      lat: -5.207083,
      lng: 145.7887,
    },
    links_count: 16,
    objectID: "2",
    uniqueID: "daa322c2-3c1d-476f-b4ad-b353825af317",
  },
  {
    name: "North Eleuthera",
    city: "North Eleuthera",
    country: "Bahamas",
    iata_code: "ELH",
    _geoloc: {
      lat: 25.474861,
      lng: -76.683489,
    },
    links_count: 16,
    objectID: "1943",
    uniqueID: "3cce9586-9383-41df-8358-97775d81e216",
  },
  {
    name: "Mont Joli",
    city: "Mont Joli",
    country: "Canada",
    iata_code: "YYY",
    _geoloc: {
      lat: 48.608612,
      lng: -68.208056,
    },
    links_count: 16,
    objectID: "192",
    uniqueID: "e4981e34-6065-4fd2-9c59-9b1fef2dba57",
  },
  {
    name: "Churchill",
    city: "Churchill",
    country: "Canada",
    iata_code: "YYQ",
    _geoloc: {
      lat: 58.739167,
      lng: -94.065,
    },
    links_count: 16,
    objectID: "187",
    uniqueID: "f016c507-a115-49fc-8fbf-810535ad5944",
  },
  {
    name: "Angel Albino Corzo",
    city: "Tuxtla Gutierrez",
    country: "Mexico",
    iata_code: "TGZ",
    _geoloc: {
      lat: 16.561822,
      lng: -93.026081,
    },
    links_count: 16,
    objectID: "1846",
    uniqueID: "0502250a-2565-4e90-9867-79a9f76d3481",
  },
  {
    name: "Ponciano Arriaga Intl",
    city: "San Luis Potosi",
    country: "Mexico",
    iata_code: "SLP",
    _geoloc: {
      lat: 22.254303,
      lng: -100.930806,
    },
    links_count: 16,
    objectID: "1842",
    uniqueID: "4c6865fd-c034-4e5c-a1fb-bd2f509acc88",
  },
  {
    name: "General Lucio Blanco Intl",
    city: "Reynosa",
    country: "Mexico",
    iata_code: "REX",
    _geoloc: {
      lat: 26.008908,
      lng: -98.228513,
    },
    links_count: 16,
    objectID: "1839",
    uniqueID: "11d815f9-a909-4087-8d0b-c712598fdec2",
  },
  {
    name: "Innsbruck",
    city: "Innsbruck",
    country: "Austria",
    iata_code: "INN",
    _geoloc: {
      lat: 47.260219,
      lng: 11.343964,
    },
    links_count: 16,
    objectID: "1610",
    uniqueID: "54776c8a-56b4-4bae-a158-429e46ab5725",
  },
  {
    name: "Zaragoza Ab",
    city: "Zaragoza",
    country: "Spain",
    iata_code: "ZAZ",
    _geoloc: {
      lat: 41.666242,
      lng: -1.041553,
    },
    links_count: 16,
    objectID: "1252",
    uniqueID: "4b58bc53-7478-40b7-be38-75cea9c34fdd",
  },
  {
    name: "La Palma",
    city: "Santa Cruz De La Palma",
    country: "Spain",
    iata_code: "SPC",
    _geoloc: {
      lat: 28.626478,
      lng: -17.755611,
    },
    links_count: 16,
    objectID: "1053",
    uniqueID: "962dd158-3f7d-4dfa-a865-7804d87c4e4e",
  },
  {
    name: "Kronoberg",
    city: "Vaxjo",
    country: "Sweden",
    iata_code: "VXO",
    _geoloc: {
      lat: 56.929144,
      lng: 14.727994,
    },
    links_count: 15,
    objectID: "711",
    uniqueID: "356af200-46ec-4bef-8384-c432fc57142c",
  },
  {
    name: "Kvernberget",
    city: "Kristiansund",
    country: "Norway",
    iata_code: "KSU",
    _geoloc: {
      lat: 63.111781,
      lng: 7.824522,
    },
    links_count: 15,
    objectID: "647",
    uniqueID: "589e217c-8345-4c8a-8373-6542611cd44a",
  },
  {
    name: "Urgench Airport",
    city: "Urgench",
    country: "Uzbekistan",
    iata_code: "UGC",
    _geoloc: {
      lat: 41.5843,
      lng: 60.6417,
    },
    links_count: 15,
    objectID: "6152",
    uniqueID: "89a33369-ea42-4273-ac14-d92c1ac801d5",
  },
  {
    name: "Deer Lake",
    city: "Deer Lake",
    country: "Canada",
    iata_code: "YDF",
    _geoloc: {
      lat: 49.210833,
      lng: -57.391388,
    },
    links_count: 15,
    objectID: "45",
    uniqueID: "3512e67f-d5d3-4e0a-9267-72265a4f2d7d",
  },
  {
    name: "Asmara Intl",
    city: "Asmara",
    country: "Eritrea",
    iata_code: "ASM",
    _geoloc: {
      lat: 15.291853,
      lng: 38.910667,
    },
    links_count: 15,
    objectID: "3967",
    uniqueID: "eb74e6f5-5a57-40f3-a40c-9007417ffbbc",
  },
  {
    name: "Valley Intl",
    city: "Harlingen",
    country: "United States",
    iata_code: "HRL",
    _geoloc: {
      lat: 26.2285,
      lng: -97.654389,
    },
    links_count: 15,
    objectID: "3560",
    uniqueID: "38fa42f2-be84-46fd-9417-1e68ccf6c440",
  },
  {
    name: "Mutiara",
    city: "Palu",
    country: "Indonesia",
    iata_code: "PLW",
    _geoloc: {
      lat: -0.918542,
      lng: 119.909642,
    },
    links_count: 15,
    objectID: "3249",
    uniqueID: "6be732c6-ea85-4dc6-8f2a-18502c2dcc16",
  },
  {
    name: "Orenburg",
    city: "Orenburg",
    country: "Russia",
    iata_code: "REN",
    _geoloc: {
      lat: 51.795786,
      lng: 55.456744,
    },
    links_count: 15,
    objectID: "2991",
    uniqueID: "0ced74c5-9856-4d4c-8477-eafcd6d8452c",
  },
  {
    name: "Samarkand",
    city: "Samarkand",
    country: "Uzbekistan",
    iata_code: "SKD",
    _geoloc: {
      lat: 39.700547,
      lng: 66.983829,
    },
    links_count: 15,
    objectID: "2981",
    uniqueID: "85f2ba51-efb6-41f7-ab8b-3a80b6ea0c7a",
  },
  {
    name: "Goloson Intl",
    city: "La Ceiba",
    country: "Honduras",
    iata_code: "LCE",
    _geoloc: {
      lat: 15.742481,
      lng: -86.853036,
    },
    links_count: 15,
    objectID: "1771",
    uniqueID: "f3cfafc8-c8ce-4d37-b8e3-4514fb8c5279",
  },
  {
    name: "Erkilet",
    city: "Kayseri",
    country: "Turkey",
    iata_code: "ASR",
    _geoloc: {
      lat: 38.770386,
      lng: 35.495428,
    },
    links_count: 15,
    objectID: "1696",
    uniqueID: "2d3b0ed3-4dc7-4f9f-9305-ec02443a71b3",
  },
  {
    name: "Berbera",
    city: "Berbera",
    country: "Somalia",
    iata_code: "BBO",
    _geoloc: {
      lat: 10.389167,
      lng: 44.941106,
    },
    links_count: 15,
    objectID: "1122",
    uniqueID: "cc1996e3-97ac-4508-a3a6-ea2aee108389",
  },
  {
    name: "Egal Intl",
    city: "Hargeisa",
    country: "Somalia",
    iata_code: "HGA",
    _geoloc: {
      lat: 9.518167,
      lng: 44.088758,
    },
    links_count: 15,
    objectID: "1121",
    uniqueID: "309e890e-7558-44a6-b15f-95724cee8fa7",
  },
  {
    name: "Tete Chingodzi",
    city: "Tete",
    country: "Mozambique",
    iata_code: "TET",
    _geoloc: {
      lat: -16.104817,
      lng: 33.640181,
    },
    links_count: 14,
    objectID: "988",
    uniqueID: "ca31d226-4bf1-4cd9-a6b4-028472d8429c",
  },
  {
    name: "Beira",
    city: "Beira",
    country: "Mozambique",
    iata_code: "BEW",
    _geoloc: {
      lat: -19.796419,
      lng: 34.907556,
    },
    links_count: 14,
    objectID: "974",
    uniqueID: "1562bece-27cd-42fe-bc58-6052fed03b49",
  },
  {
    name: "Livingstone",
    city: "Livingstone",
    country: "Zambia",
    iata_code: "LVI",
    _geoloc: {
      lat: -17.821756,
      lng: 25.822692,
    },
    links_count: 14,
    objectID: "906",
    uniqueID: "6a9cbae1-a6cc-48d5-9d7a-95458f4b7209",
  },
  {
    name: "Maun",
    city: "Maun",
    country: "Botswana",
    iata_code: "MUB",
    _geoloc: {
      lat: -19.972564,
      lng: 23.431086,
    },
    links_count: 14,
    objectID: "880",
    uniqueID: "e9a2d32e-0690-4ca9-97ae-644dc72e8f89",
  },
  {
    name: "Zhoubai",
    city: "Qianjiang",
    country: "China",
    iata_code: "JIQ",
    _geoloc: {
      lat: 29.515,
      lng: 108.83,
    },
    links_count: 14,
    objectID: "8781",
    uniqueID: "9528a3f7-03aa-4a0a-90ca-0d4bc8d6d7d4",
  },
  {
    name: "Uummannaq Heliport",
    city: "Uummannaq",
    country: "Greenland",
    iata_code: "UMD",
    _geoloc: {
      lat: 70.4047,
      lng: 52.0702,
    },
    links_count: 14,
    objectID: "8628",
    uniqueID: "e44f8e38-6475-4e92-b911-5d9d317b4447",
  },
  {
    name: "Panama City-NW Florida Bea.",
    city: "Panama City",
    country: "United States",
    iata_code: "ECP",
    _geoloc: {
      lat: 30.3417,
      lng: -85.7973,
    },
    links_count: 14,
    objectID: "7669",
    uniqueID: "33679b86-fe91-4f33-8c7c-b980feb34f94",
  },
  {
    name: "Dangriga Airport",
    city: "Dangriga",
    country: "Belize",
    iata_code: "DGA",
    _geoloc: {
      lat: 16.966667,
      lng: -88.216667,
    },
    links_count: 14,
    objectID: "7287",
    uniqueID: "ce26e60d-d01c-4024-a1b6-d75fc21726e3",
  },
  {
    name: "Begishevo",
    city: "Nizhnekamsk",
    country: "Russia",
    iata_code: "NBC",
    _geoloc: {
      lat: 55.34,
      lng: 52.06,
    },
    links_count: 14,
    objectID: "6969",
    uniqueID: "2fdaf585-3ffb-44fe-aa56-1c19b723f3b6",
  },
  {
    name: "Goleniow",
    city: "Szczecin",
    country: "Poland",
    iata_code: "SZZ",
    _geoloc: {
      lat: 53.584731,
      lng: 14.902206,
    },
    links_count: 14,
    objectID: "676",
    uniqueID: "5a3d44c0-1b09-4e71-ac82-82cb8d7fb7f8",
  },
  {
    name: "Aro",
    city: "Molde",
    country: "Norway",
    iata_code: "MOL",
    _geoloc: {
      lat: 62.744722,
      lng: 7.2625,
    },
    links_count: 14,
    objectID: "651",
    uniqueID: "759f635f-7011-46ca-9f1b-100ca758f57a",
  },
  {
    name: "Jiayuguan Airport",
    city: "Jiayuguan",
    country: "China",
    iata_code: "JGN",
    _geoloc: {
      lat: 39.8569,
      lng: 98.3414,
    },
    links_count: 14,
    objectID: "6432",
    uniqueID: "43195930-7b3a-47d8-9a4b-a525d266370e",
  },
  {
    name: "Shanhaiguan Airport",
    city: "Qinhuangdao",
    country: "China",
    iata_code: "SHP",
    _geoloc: {
      lat: 39.9681,
      lng: 119.731,
    },
    links_count: 14,
    objectID: "6429",
    uniqueID: "da39ccfd-c2dc-45e6-a21c-db3e7470643f",
  },
  {
    name: "Anqing Airport",
    city: "Anqing",
    country: "China",
    iata_code: "AQG",
    _geoloc: {
      lat: 30.5822,
      lng: 117.0502,
    },
    links_count: 14,
    objectID: "6427",
    uniqueID: "1f4a8c17-2583-477f-866b-9a34aefa714c",
  },
  {
    name: "Weifang Airport",
    city: "Weifang",
    country: "China",
    iata_code: "WEF",
    _geoloc: {
      lat: 36.6467,
      lng: 119.119,
    },
    links_count: 14,
    objectID: "6388",
    uniqueID: "6be578ab-2825-4f20-9c1e-0b245854556d",
  },
  {
    name: "Diqing Airport",
    city: "Shangri-La",
    country: "China",
    iata_code: "DIG",
    _geoloc: {
      lat: 27.7936,
      lng: 99.6772,
    },
    links_count: 14,
    objectID: "6375",
    uniqueID: "fb609187-aa2f-4d18-998a-74210bc45ccf",
  },
  {
    name: "Xilinhot Airport",
    city: "Xilinhot",
    country: "China",
    iata_code: "XIL",
    _geoloc: {
      lat: 43.9156,
      lng: 115.964,
    },
    links_count: 14,
    objectID: "6350",
    uniqueID: "87b6ac7b-d8ad-4d2d-811f-5a5af88a36c2",
  },
  {
    name: "Ulanhot Airport",
    city: "Ulanhot",
    country: "China",
    iata_code: "HLH",
    _geoloc: {
      lat: 46.083,
      lng: 122.017,
    },
    links_count: 14,
    objectID: "6349",
    uniqueID: "d7c83752-b6e3-456f-9ba1-82fb71229c14",
  },
  {
    name: "Hamilton Island Airport",
    city: "Hamilton Island",
    country: "Australia",
    iata_code: "HTI",
    _geoloc: {
      lat: -20.3581,
      lng: 148.952,
    },
    links_count: 14,
    objectID: "6242",
    uniqueID: "865ca195-8bdc-4c3f-943a-5d48f0a1a493",
  },
  {
    name: "Vinh Airport",
    city: "Vinh",
    country: "Vietnam",
    iata_code: "VII",
    _geoloc: {
      lat: 18.737569,
      lng: 105.670764,
    },
    links_count: 14,
    objectID: "6195",
    uniqueID: "ea533152-25f0-40d8-998b-f39a494eb748",
  },
  {
    name: "Cam Ranh Airport",
    city: "Nha Trang",
    country: "Vietnam",
    iata_code: "CXR",
    _geoloc: {
      lat: 11.998153,
      lng: 109.219372,
    },
    links_count: 14,
    objectID: "6189",
    uniqueID: "c36bfb23-250e-4ddd-a70c-fa43747cec4d",
  },
  {
    name: "Buon Ma Thuot Airport",
    city: "Buonmethuot",
    country: "Vietnam",
    iata_code: "BMV",
    _geoloc: {
      lat: 12.668311,
      lng: 108.120272,
    },
    links_count: 14,
    objectID: "6187",
    uniqueID: "9a8e1290-559b-415c-a0a9-8f2e6186a2f8",
  },
  {
    name: "Fergana Airport",
    city: "Fergana",
    country: "Uzbekistan",
    iata_code: "FEG",
    _geoloc: {
      lat: 40.3588,
      lng: 71.745,
    },
    links_count: 14,
    objectID: "6149",
    uniqueID: "eb9777ea-9283-49cf-b512-70aa35013361",
  },
  {
    name: "Cherepovets Airport",
    city: "Cherepovets",
    country: "Russia",
    iata_code: "CEE",
    _geoloc: {
      lat: 59.2736,
      lng: 38.0158,
    },
    links_count: 14,
    objectID: "6111",
    uniqueID: "b21504d6-64e3-4f4e-be20-5b5862f6efe4",
  },
  {
    name: "Cayo Coco Airport",
    city: "Cayo Coco",
    country: "Cuba",
    iata_code: "CCC",
    _geoloc: {
      lat: 22.5132,
      lng: -78.511,
    },
    links_count: 14,
    objectID: "5852",
    uniqueID: "01306d72-82e2-40d7-b17f-1a915139500a",
  },
  {
    name: "Osvaldo Vieira International Airport",
    city: "Bissau",
    country: "Guinea-Bissau",
    iata_code: "OXB",
    _geoloc: {
      lat: 11.89485,
      lng: -15.653681,
    },
    links_count: 14,
    objectID: "5665",
    uniqueID: "f1fd3b55-beb8-4f4f-a8c1-4bc0800b4e48",
  },
  {
    name: "Kuujjuarapik Airport",
    city: "Kuujjuarapik",
    country: "Canada",
    iata_code: "YGW",
    _geoloc: {
      lat: 55.2819,
      lng: -77.7653,
    },
    links_count: 14,
    objectID: "5496",
    uniqueID: "9f6a81ce-76fc-410a-82fa-2e391a23a5d1",
  },
  {
    name: "Qaqortoq Heliport",
    city: "Qaqortoq",
    country: "Greenland",
    iata_code: "JJU",
    _geoloc: {
      lat: 60.7158,
      lng: -46.0294,
    },
    links_count: 14,
    objectID: "5442",
    uniqueID: "0384203c-3c83-49b0-8598-c297e4648867",
  },
  {
    name: "Arviat",
    city: "Eskimo Point",
    country: "Canada",
    iata_code: "YEK",
    _geoloc: {
      lat: 61.094166,
      lng: -94.070833,
    },
    links_count: 14,
    objectID: "50",
    uniqueID: "65ad1557-83d8-4d5f-b6ce-302860fb5f4e",
  },
  {
    name: "Albert J Ellis",
    city: "Jacksonville NC",
    country: "United States",
    iata_code: "OAJ",
    _geoloc: {
      lat: 34.829164,
      lng: -77.612139,
    },
    links_count: 14,
    objectID: "4386",
    uniqueID: "ffea8f63-baf3-40ba-8556-b0b693e0d01c",
  },
  {
    name: "Sary-Arka",
    city: "Karaganda",
    country: "Kazakhstan",
    iata_code: "KGF",
    _geoloc: {
      lat: 49.670833,
      lng: 73.334444,
    },
    links_count: 14,
    objectID: "4375",
    uniqueID: "1ee8281e-83bb-4f1b-9ea1-810335871812",
  },
  {
    name: "Airport",
    city: "Vadso",
    country: "Norway",
    iata_code: "VDS",
    _geoloc: {
      lat: 70.065,
      lng: 29.844,
    },
    links_count: 14,
    objectID: "4328",
    uniqueID: "4b2969d6-0063-401b-8be9-8e35911e5998",
  },
  {
    name: "Woerthersee International Airport",
    city: "Klagenfurt",
    country: "Austria",
    iata_code: "KLU",
    _geoloc: {
      lat: 46.642514,
      lng: 14.337739,
    },
    links_count: 14,
    objectID: "4322",
    uniqueID: "b540a258-3d8f-4b26-be34-89f1ae666859",
  },
  {
    name: "Dunhuang Airport",
    city: "Dunhuang",
    country: "China",
    iata_code: "DNH",
    _geoloc: {
      lat: 40.094,
      lng: 94.4818,
    },
    links_count: 14,
    objectID: "4308",
    uniqueID: "24ff7d79-497c-415b-b66c-301a20e8302f",
  },
  {
    name: "Santa Cruz",
    city: "Santa Cruz",
    country: "Bolivia",
    iata_code: "SRZ",
    _geoloc: {
      lat: -17.8,
      lng: -63.166667,
    },
    links_count: 14,
    objectID: "4247",
    uniqueID: "719c6224-f70c-413b-b085-74087dc98c93",
  },
  {
    name: "Missoula Intl",
    city: "Missoula",
    country: "United States",
    iata_code: "MSO",
    _geoloc: {
      lat: 46.916306,
      lng: -114.090556,
    },
    links_count: 14,
    objectID: "4216",
    uniqueID: "c6bf4a11-0201-4f75-8276-997ac4680be5",
  },
  {
    name: "Palmas",
    city: "Palmas",
    country: "Brazil",
    iata_code: "PMW",
    _geoloc: {
      lat: -10.241667,
      lng: -48.35278,
    },
    links_count: 14,
    objectID: "4214",
    uniqueID: "45f6bb1b-ca1c-44a3-a9cf-859cb3f4f8c9",
  },
  {
    name: "Tri-Cities Regional Airport",
    city: "BRISTOL",
    country: "United States",
    iata_code: "TRI",
    _geoloc: {
      lat: 36.4752222,
      lng: -82.4074167,
    },
    links_count: 14,
    objectID: "4116",
    uniqueID: "fe597bac-1ef9-47d1-8f26-4e4e39e22e17",
  },
  {
    name: "Montgomery Regional Airport ",
    city: "MONTGOMERY",
    country: "United States",
    iata_code: "MGM",
    _geoloc: {
      lat: 32.3006389,
      lng: -86.3939722,
    },
    links_count: 14,
    objectID: "4115",
    uniqueID: "b86ba243-bc82-4a4f-af27-c4f46ab885db",
  },
  {
    name: "Dali",
    city: "Dali",
    country: "China",
    iata_code: "DLU",
    _geoloc: {
      lat: 25.649444,
      lng: 100.319444,
    },
    links_count: 14,
    objectID: "4054",
    uniqueID: "19ed123c-b839-45fa-a938-43d8bbdfbac2",
  },
  {
    name: "Taiz Intl",
    city: "Taiz",
    country: "Yemen",
    iata_code: "TAI",
    _geoloc: {
      lat: 13.685964,
      lng: 44.139056,
    },
    links_count: 14,
    objectID: "3975",
    uniqueID: "911507b0-27a6-4a17-b385-ed9d020db58d",
  },
  {
    name: "Juwata",
    city: "Taraken",
    country: "Indonesia",
    iata_code: "TRK",
    _geoloc: {
      lat: 3.326694,
      lng: 117.565569,
    },
    links_count: 14,
    objectID: "3920",
    uniqueID: "75b839b4-cb07-4f9f-9721-45125b71b172",
  },
  {
    name: "Iskandar",
    city: "Pangkalan Bun",
    country: "Indonesia",
    iata_code: "PKN",
    _geoloc: {
      lat: -2.705197,
      lng: 111.673208,
    },
    links_count: 14,
    objectID: "3910",
    uniqueID: "ba3690f2-42b0-452f-8519-bc9c0cad0d97",
  },
  {
    name: "Depati Amir",
    city: "Pangkal Pinang",
    country: "Indonesia",
    iata_code: "PGK",
    _geoloc: {
      lat: -2.1622,
      lng: 106.139064,
    },
    links_count: 14,
    objectID: "3905",
    uniqueID: "2a0fa399-1fe4-4a76-910a-acb5b8a2c3c5",
  },
  {
    name: "Great Falls Intl",
    city: "Great Falls",
    country: "United States",
    iata_code: "GTF",
    _geoloc: {
      lat: 47.482,
      lng: -111.370689,
    },
    links_count: 14,
    objectID: "3880",
    uniqueID: "62fe5a61-5f85-45d9-8174-63afe6f089ac",
  },
  {
    name: "King Salmon",
    city: "King Salmon",
    country: "United States",
    iata_code: "AKN",
    _geoloc: {
      lat: 58.676778,
      lng: -156.649278,
    },
    links_count: 14,
    objectID: "3794",
    uniqueID: "ff86650c-3237-4b80-947b-4637d402fdb4",
  },
  {
    name: "Plattsburgh Intl",
    city: "Plattsburgh",
    country: "United States",
    iata_code: "PBG",
    _geoloc: {
      lat: 44.650944,
      lng: -73.468139,
    },
    links_count: 14,
    objectID: "3771",
    uniqueID: "8eed2be4-e5db-47ce-9034-102a8476febe",
  },
  {
    name: "Edward G Pitka Sr",
    city: "Galena",
    country: "United States",
    iata_code: "GAL",
    _geoloc: {
      lat: 64.736178,
      lng: -156.937417,
    },
    links_count: 14,
    objectID: "3764",
    uniqueID: "0dfab0e1-75e8-4e86-ac97-de2803493eb3",
  },
  {
    name: "Augusta Rgnl At Bush Fld",
    city: "Bush Field",
    country: "United States",
    iata_code: "AGS",
    _geoloc: {
      lat: 33.369944,
      lng: -81.9645,
    },
    links_count: 14,
    objectID: "3658",
    uniqueID: "457e497e-4e34-4231-b265-05c1d84504f8",
  },
  {
    name: "Karratha",
    city: "Karratha",
    country: "Australia",
    iata_code: "KTA",
    _geoloc: {
      lat: -20.712222,
      lng: 116.773333,
    },
    links_count: 14,
    objectID: "3345",
    uniqueID: "98d501e6-3ed3-4a21-821e-1e5284d3798c",
  },
  {
    name: "Mackay",
    city: "Mackay",
    country: "Australia",
    iata_code: "MKY",
    _geoloc: {
      lat: -21.171667,
      lng: 149.179722,
    },
    links_count: 14,
    objectID: "3326",
    uniqueID: "d84d3384-1d5c-4824-9235-4f31034f07eb",
  },
  {
    name: "Sultan Mahmud",
    city: "Kuala Terengganu",
    country: "Malaysia",
    iata_code: "TGG",
    _geoloc: {
      lat: 5.382639,
      lng: 103.10336,
    },
    links_count: 14,
    objectID: "3307",
    uniqueID: "031fa4e0-fb5e-4ba2-b574-a98bb3e45dff",
  },
  {
    name: "Minangkabau",
    city: "Padang",
    country: "Indonesia",
    iata_code: "PDG",
    _geoloc: {
      lat: -0.874989,
      lng: 100.351881,
    },
    links_count: 14,
    objectID: "3278",
    uniqueID: "e22e0375-a4eb-4e8f-ba49-bde563ce48ce",
  },
  {
    name: "Tawau",
    city: "Tawau",
    country: "Malaysia",
    iata_code: "TWU",
    _geoloc: {
      lat: 4.313369,
      lng: 118.121953,
    },
    links_count: 14,
    objectID: "3271",
    uniqueID: "0be6d1eb-8258-47c7-849e-609790c53252",
  },
  {
    name: "Bintulu",
    city: "Bintulu",
    country: "Malaysia",
    iata_code: "BTU",
    _geoloc: {
      lat: 3.12385,
      lng: 113.020472,
    },
    links_count: 14,
    objectID: "3262",
    uniqueID: "0cba6e86-ce0b-437e-823d-9e937bcf7263",
  },
  {
    name: "Rendani",
    city: "Manokwari",
    country: "Indonesia",
    iata_code: "MKW",
    _geoloc: {
      lat: -0.891833,
      lng: 134.049183,
    },
    links_count: 14,
    objectID: "3260",
    uniqueID: "22d6d4cb-dddc-4aa9-a343-ba1a31521f48",
  },
  {
    name: "Silchar",
    city: "Silchar",
    country: "India",
    iata_code: "IXS",
    _geoloc: {
      lat: 24.912928,
      lng: 92.978742,
    },
    links_count: 14,
    objectID: "3055",
    uniqueID: "78d1b4f8-f8dd-47d0-a862-a585c6daefa3",
  },
  {
    name: "Udaipur",
    city: "Udaipur",
    country: "India",
    iata_code: "UDR",
    _geoloc: {
      lat: 24.617697,
      lng: 73.8961,
    },
    links_count: 14,
    objectID: "3023",
    uniqueID: "6717966b-69aa-4c16-ad91-e8592a4b2648",
  },
  {
    name: "Bhopal",
    city: "Bhopal",
    country: "India",
    iata_code: "BHO",
    _geoloc: {
      lat: 23.287467,
      lng: 77.337375,
    },
    links_count: 14,
    objectID: "3002",
    uniqueID: "56b0e2ec-8fcb-4b86-8734-72253d1a3b2b",
  },
  {
    name: "Murmansk",
    city: "Murmansk",
    country: "Russia",
    iata_code: "MMK",
    _geoloc: {
      lat: 68.781672,
      lng: 32.750822,
    },
    links_count: 14,
    objectID: "2949",
    uniqueID: "e901d6cf-3e15-4a48-84e3-f2807dbecf12",
  },
  {
    name: "Simferopol Intl",
    city: "Simferopol",
    country: "Ukraine",
    iata_code: "SIP",
    _geoloc: {
      lat: 45.052222,
      lng: 33.975139,
    },
    links_count: 14,
    objectID: "2942",
    uniqueID: "b30f3ebc-e9f0-4084-a574-3582397a6019",
  },
  {
    name: "Aktyubinsk",
    city: "Aktyubinsk",
    country: "Kazakhstan",
    iata_code: "AKX",
    _geoloc: {
      lat: 50.245833,
      lng: 57.206667,
    },
    links_count: 14,
    objectID: "2920",
    uniqueID: "8a830b60-3c2f-4891-a6bb-4e4f94fbb564",
  },
  {
    name: "Coronel Francisco Secada Vignetta Intl",
    city: "Iquitos",
    country: "Peru",
    iata_code: "IQT",
    _geoloc: {
      lat: -3.784739,
      lng: -73.308806,
    },
    links_count: 14,
    objectID: "2801",
    uniqueID: "9d95d1d0-5d26-4834-8a8f-f01040807e6f",
  },
  {
    name: "Rochambeau",
    city: "Cayenne",
    country: "French Guiana",
    iata_code: "CAY",
    _geoloc: {
      lat: 4.819808,
      lng: -52.360447,
    },
    links_count: 14,
    objectID: "2774",
    uniqueID: "0ac033a9-f13c-46f3-b3f1-e883d7184429",
  },
  {
    name: "Mallam Aminu Intl",
    city: "Kano",
    country: "Nigeria",
    iata_code: "KAN",
    _geoloc: {
      lat: 12.047589,
      lng: 8.524622,
    },
    links_count: 14,
    objectID: "270",
    uniqueID: "f17eb613-1238-41b3-9e4b-05ec6a3a7227",
  },
  {
    name: "La Florida",
    city: "La Serena",
    country: "Chile",
    iata_code: "LSC",
    _geoloc: {
      lat: -29.916233,
      lng: -71.199522,
    },
    links_count: 14,
    objectID: "2662",
    uniqueID: "7c79b8c9-9572-400d-9a2e-f2bd522f5022",
  },
  {
    name: "Ministro Victor Konder Intl",
    city: "Navegantes",
    country: "Brazil",
    iata_code: "NVT",
    _geoloc: {
      lat: -26.879999,
      lng: -48.65139,
    },
    links_count: 14,
    objectID: "2595",
    uniqueID: "3093901b-6be0-43b4-b1e0-b8f334ac9d6b",
  },
  {
    name: "Prefeito Renato Moreira",
    city: "Imperatriz",
    country: "Brazil",
    iata_code: "IMP",
    _geoloc: {
      lat: -5.531292,
      lng: -47.46005,
    },
    links_count: 14,
    objectID: "2572",
    uniqueID: "9b8c0b8c-8fdd-4b85-b35a-32bca642ef18",
  },
  {
    name: "San Carlos De Bariloche",
    city: "San Carlos De Bariloche",
    country: "Argentina",
    iata_code: "BRC",
    _geoloc: {
      lat: -41.151172,
      lng: -71.157542,
    },
    links_count: 14,
    objectID: "2513",
    uniqueID: "8ec37cb7-9e8f-44d8-a6c7-af54596e12a8",
  },
  {
    name: "Comodoro Rivadavia",
    city: "Comodoro Rivadavia",
    country: "Argentina",
    iata_code: "CRD",
    _geoloc: {
      lat: -45.785347,
      lng: -67.465508,
    },
    links_count: 14,
    objectID: "2487",
    uniqueID: "440495f7-b788-4bd1-a70c-337de3d170c5",
  },
  {
    name: "Rosario",
    city: "Rosario",
    country: "Argentina",
    iata_code: "ROS",
    _geoloc: {
      lat: -32.903611,
      lng: -60.785,
    },
    links_count: 14,
    objectID: "2440",
    uniqueID: "bbedee77-02af-4625-864c-745e1808884f",
  },
  {
    name: "Cagayan De Oro",
    city: "Ladag",
    country: "Philippines",
    iata_code: "CGY",
    _geoloc: {
      lat: 8.415619,
      lng: 124.611219,
    },
    links_count: 14,
    objectID: "2400",
    uniqueID: "561f9f21-c7a3-4240-ae3b-1372718d7dea",
  },
  {
    name: "Asahikawa",
    city: "Asahikawa",
    country: "Japan",
    iata_code: "AKJ",
    _geoloc: {
      lat: 43.670833,
      lng: 142.4475,
    },
    links_count: 14,
    objectID: "2299",
    uniqueID: "4d64e9d2-4ca5-4eb9-8710-97b23a469cd3",
  },
  {
    name: "Salalah",
    city: "Salalah",
    country: "Oman",
    iata_code: "SLL",
    _geoloc: {
      lat: 17.038719,
      lng: 54.091297,
    },
    links_count: 14,
    objectID: "2195",
    uniqueID: "74a20262-875f-41cf-af31-edcb2ef8a70e",
  },
  {
    name: "Yazd Shahid Sadooghi",
    city: "Yazd",
    country: "Iran",
    iata_code: "AZD",
    _geoloc: {
      lat: 31.904908,
      lng: 54.276503,
    },
    links_count: 14,
    objectID: "2164",
    uniqueID: "222ba437-6e35-4626-bb79-c1a89446ad32",
  },
  {
    name: "Playa De Oro Intl",
    city: "Manzanillo",
    country: "Mexico",
    iata_code: "ZLO",
    _geoloc: {
      lat: 19.144778,
      lng: -104.558631,
    },
    links_count: 14,
    objectID: "1858",
    uniqueID: "ef940cd0-37d0-43e3-9b7f-beef3c2187b9",
  },
  {
    name: "General Leobardo C Ruiz Intl",
    city: "Zacatecas",
    country: "Mexico",
    iata_code: "ZCL",
    _geoloc: {
      lat: 22.897112,
      lng: -102.68689,
    },
    links_count: 14,
    objectID: "1855",
    uniqueID: "7d5e95ea-bb5f-4ec0-9a2a-bd963f5c5d45",
  },
  {
    name: "General Francisco Javier Mina Intl",
    city: "Tampico",
    country: "Mexico",
    iata_code: "TAM",
    _geoloc: {
      lat: 22.29645,
      lng: -97.865931,
    },
    links_count: 14,
    objectID: "1848",
    uniqueID: "85916e81-d7dc-4f8c-a27c-25b60bcddc82",
  },
  {
    name: "Queretaro Intercontinental",
    city: "Queretaro",
    country: "Mexico",
    iata_code: "QRO",
    _geoloc: {
      lat: 20.617289,
      lng: -100.185658,
    },
    links_count: 14,
    objectID: "1838",
    uniqueID: "d6d3c90f-b47a-45dd-9dac-266e6792e1b0",
  },
  {
    name: "Hermanos Serdan Intl",
    city: "Puebla",
    country: "Mexico",
    iata_code: "PBC",
    _geoloc: {
      lat: 19.158144,
      lng: -98.371447,
    },
    links_count: 14,
    objectID: "1831",
    uniqueID: "cd47f182-a6a7-4766-9931-dd45a0aa31ac",
  },
  {
    name: "Diyarbakir",
    city: "Diyabakir",
    country: "Turkey",
    iata_code: "DIY",
    _geoloc: {
      lat: 37.893897,
      lng: 40.201019,
    },
    links_count: 14,
    objectID: "1722",
    uniqueID: "f0700168-1e8d-41ca-bcbd-83b46671db19",
  },
  {
    name: "Val D Or",
    city: "Val D'or",
    country: "Canada",
    iata_code: "YVO",
    _geoloc: {
      lat: 48.053333,
      lng: -77.782778,
    },
    links_count: 14,
    objectID: "153",
    uniqueID: "b4e5f298-241f-43f3-9764-e2f62627c088",
  },
  {
    name: "Biard",
    city: "Poitiers",
    country: "France",
    iata_code: "PIS",
    _geoloc: {
      lat: 46.587745,
      lng: 0.306666,
    },
    links_count: 14,
    objectID: "1268",
    uniqueID: "9b8204c3-4f31-42a9-adf6-ab5df37cbef2",
  },
  {
    name: "Rijeka",
    city: "Rijeka",
    country: "Croatia",
    iata_code: "RJK",
    _geoloc: {
      lat: 45.216889,
      lng: 14.570267,
    },
    links_count: 14,
    objectID: "1205",
    uniqueID: "7a224c91-d19a-4e79-8518-ca3b415f7124",
  },
  {
    name: "Comox",
    city: "Comox",
    country: "Canada",
    iata_code: "YQQ",
    _geoloc: {
      lat: 49.710833,
      lng: -124.886667,
    },
    links_count: 14,
    objectID: "119",
    uniqueID: "e2557e5e-3781-4db3-8418-8029ca788f4b",
  },
  {
    name: "Victoria Falls Intl",
    city: "Victoria Falls",
    country: "Zimbabwe",
    iata_code: "VFA",
    _geoloc: {
      lat: -18.095881,
      lng: 25.839006,
    },
    links_count: 14,
    objectID: "1004",
    uniqueID: "e5a09b85-fb39-45e4-83d9-1d920a382eb5",
  },
  {
    name: "Toamasina",
    city: "Toamasina",
    country: "Madagascar",
    iata_code: "TMM",
    _geoloc: {
      lat: -18.109517,
      lng: 49.392536,
    },
    links_count: 13,
    objectID: "921",
    uniqueID: "49833a24-b73e-4b69-93c0-637404956e65",
  },
  {
    name: "Mattala Rajapaksa Intl.",
    city: "Mattala",
    country: "Sri Lanka",
    iata_code: "HRI",
    _geoloc: {
      lat: 6.284467,
      lng: 81.124128,
    },
    links_count: 13,
    objectID: "8949",
    uniqueID: "b767f67e-9840-4287-9b34-f9597ab9086c",
  },
  {
    name: "Kulob Airport",
    city: "Kulyab",
    country: "Tajikistan",
    iata_code: "TJU",
    _geoloc: {
      lat: 37.981667,
      lng: 69.799444,
    },
    links_count: 13,
    objectID: "7563",
    uniqueID: "cde8dfe3-a4f6-49c5-876b-b0f00c6441af",
  },
  {
    name: "Placencia Airport",
    city: "Placencia",
    country: "Belize",
    iata_code: "PLJ",
    _geoloc: {
      lat: 16.536944,
      lng: -88.361667,
    },
    links_count: 13,
    objectID: "7288",
    uniqueID: "86d70b38-c76b-4b11-8aaf-60d0f7e2d189",
  },
  {
    name: "Hoybuktmoen",
    city: "Kirkenes",
    country: "Norway",
    iata_code: "KKN",
    _geoloc: {
      lat: 69.725781,
      lng: 29.891295,
    },
    links_count: 13,
    objectID: "649",
    uniqueID: "8dc6ecc0-e704-42fd-9c13-9d305eb000f2",
  },
  {
    name: "Eelde",
    city: "Groningen",
    country: "Netherlands",
    iata_code: "GRQ",
    _geoloc: {
      lat: 53.11972,
      lng: 6.579444,
    },
    links_count: 13,
    objectID: "586",
    uniqueID: "01bec2fc-7548-4672-94c3-f98e9964be70",
  },
  {
    name: "Bagan Intl",
    city: "Nyuang U",
    country: "Burma",
    iata_code: "NYU",
    _geoloc: {
      lat: 21.173833266,
      lng: 94.9246666,
    },
    links_count: 13,
    objectID: "4001",
    uniqueID: "72fd693b-b97b-4267-99aa-d12e19fcc62f",
  },
  {
    name: "Wiley Post Will Rogers Mem",
    city: "Barrow",
    country: "United States",
    iata_code: "BRW",
    _geoloc: {
      lat: 71.285446,
      lng: -156.766003,
    },
    links_count: 13,
    objectID: "3571",
    uniqueID: "4eb625ca-e230-4a4f-8427-e38ff96896d7",
  },
  {
    name: "Fort Yukon",
    city: "Fort Yukon",
    country: "United States",
    iata_code: "FYU",
    _geoloc: {
      lat: 66.571492,
      lng: -145.250417,
    },
    links_count: 13,
    objectID: "3420",
    uniqueID: "e6488160-d0c1-448a-812a-75620956bf37",
  },
  {
    name: "Bukhara",
    city: "Bukhara",
    country: "Uzbekistan",
    iata_code: "BHK",
    _geoloc: {
      lat: 39.775,
      lng: 64.483333,
    },
    links_count: 13,
    objectID: "2980",
    uniqueID: "8bd477a1-93c9-4ecc-bb0f-e4480f9e04f9",
  },
  {
    name: "Juliaca",
    city: "Juliaca",
    country: "Peru",
    iata_code: "JUL",
    _geoloc: {
      lat: -15.467103,
      lng: -70.158169,
    },
    links_count: 13,
    objectID: "2792",
    uniqueID: "9d4b27f2-4ddd-4965-b930-11258a923f01",
  },
  {
    name: "Carlos Ibanez Del Campo Intl",
    city: "Punta Arenas",
    country: "Chile",
    iata_code: "PUQ",
    _geoloc: {
      lat: -53.002642,
      lng: -70.854586,
    },
    links_count: 13,
    objectID: "2647",
    uniqueID: "4e3b38c8-3ae6-4bd4-8e06-1d01b67f4030",
  },
  {
    name: "El Loa",
    city: "Calama",
    country: "Chile",
    iata_code: "CJC",
    _geoloc: {
      lat: -22.498175,
      lng: -68.903575,
    },
    links_count: 13,
    objectID: "2645",
    uniqueID: "5a058ac6-c2eb-49d8-b283-2150c6c3fe9e",
  },
  {
    name: "Karpathos",
    city: "Karpathos",
    country: "Greece",
    iata_code: "AOK",
    _geoloc: {
      lat: 35.421408,
      lng: 27.146008,
    },
    links_count: 13,
    objectID: "1459",
    uniqueID: "1f815c3b-efdb-4580-8e6c-6cef67e2b4ac",
  },
  {
    name: "Kefallinia",
    city: "Keffallinia",
    country: "Greece",
    iata_code: "EFL",
    _geoloc: {
      lat: 38.120069,
      lng: 20.500481,
    },
    links_count: 13,
    objectID: "1455",
    uniqueID: "96c481ea-e352-4e10-a6f4-23492af0c7a8",
  },
  {
    name: "Thompson",
    city: "Thompson",
    country: "Canada",
    iata_code: "YTH",
    _geoloc: {
      lat: 55.801111,
      lng: -97.864166,
    },
    links_count: 13,
    objectID: "141",
    uniqueID: "79bf6203-0f2b-4417-ac0c-cf4c8787d6ac",
  },
  {
    name: "Natashquan",
    city: "Natashquan",
    country: "Canada",
    iata_code: "YNA",
    _geoloc: {
      lat: 50.19,
      lng: -61.789167,
    },
    links_count: 12,
    objectID: "94",
    uniqueID: "d92c8d13-e1a8-46de-a6f6-dab3e9ad4911",
  },
  {
    name: "Yichun Mingyueshan Airport",
    city: "Yichun",
    country: "China",
    iata_code: "YIC",
    _geoloc: {
      lat: 27.8033,
      lng: 114.3081,
    },
    links_count: 12,
    objectID: "9107",
    uniqueID: "6054ad9e-b10c-4d08-9933-0a5de42e2aba",
  },
  {
    name: "Lensk",
    city: "Lensk",
    country: "Russia",
    iata_code: "ULK",
    _geoloc: {
      lat: 60.723,
      lng: 114.825,
    },
    links_count: 12,
    objectID: "9026",
    uniqueID: "9780ad63-135c-420b-a798-c717d397de6f",
  },
  {
    name: "Sannvhe",
    city: "Tangshan",
    country: "China",
    iata_code: "TVS",
    _geoloc: {
      lat: 39.717444,
      lng: 118.002389,
    },
    links_count: 12,
    objectID: "8858",
    uniqueID: "62a01c58-2ff9-4141-bca6-95e87db5ec49",
  },
  {
    name: "Lublin",
    city: "Lublin",
    country: "Poland",
    iata_code: "LUZ",
    _geoloc: {
      lat: 51.239333,
      lng: 22.714083,
    },
    links_count: 12,
    objectID: "8832",
    uniqueID: "acffa86c-a970-442f-b149-348cabe7500a",
  },
  {
    name: "Jixi Airport",
    city: "Jixi",
    country: "China",
    iata_code: "JXA",
    _geoloc: {
      lat: 45.30611,
      lng: 130.99667,
    },
    links_count: 12,
    objectID: "8417",
    uniqueID: "b2b4c1b7-1d0e-4ac1-8ec8-c4d2f55c1136",
  },
  {
    name: "Hebei Handan Airport",
    city: "Handan",
    country: "China",
    iata_code: "HDG",
    _geoloc: {
      lat: 36.524,
      lng: 114.43,
    },
    links_count: 12,
    objectID: "8407",
    uniqueID: "1f3727ad-6403-488e-90b6-b5ef49325041",
  },
  {
    name: "Gazipasa Airport",
    city: "Alanya",
    country: "Turkey",
    iata_code: "GZP",
    _geoloc: {
      lat: 36.2993,
      lng: 32.3014,
    },
    links_count: 12,
    objectID: "8266",
    uniqueID: "1ddb6657-7456-40ec-9cff-7583462bfd82",
  },
  {
    name: "Xingyi Airport",
    city: "Xingyi",
    country: "China",
    iata_code: "ACX",
    _geoloc: {
      lat: 25.0882,
      lng: 104.9587,
    },
    links_count: 12,
    objectID: "7506",
    uniqueID: "f53d059c-4915-4abd-888a-01220794b2d7",
  },
  {
    name: "Barreiras Airport",
    city: "Barreiras",
    country: "Brazil",
    iata_code: "BRA",
    _geoloc: {
      lat: -12.083333,
      lng: -45,
    },
    links_count: 12,
    objectID: "7373",
    uniqueID: "f22fbd09-dac5-4638-b0f6-56f38bb8ec0f",
  },
  {
    name: "Kallax",
    city: "Lulea",
    country: "Sweden",
    iata_code: "LLA",
    _geoloc: {
      lat: 65.543758,
      lng: 22.121989,
    },
    links_count: 12,
    objectID: "734",
    uniqueID: "e48c8db9-5ec2-4de4-9959-9f5bf8ab52a9",
  },
  {
    name: "Stebbins Airport",
    city: "Stebbins",
    country: "United States",
    iata_code: "WBB",
    _geoloc: {
      lat: 63.515833,
      lng: -162.278056,
    },
    links_count: 12,
    objectID: "7193",
    uniqueID: "b339ce2a-14bb-46f3-b800-c6153ee00308",
  },
  {
    name: "Gallivare",
    city: "Gallivare",
    country: "Sweden",
    iata_code: "GEV",
    _geoloc: {
      lat: 67.132408,
      lng: 20.814636,
    },
    links_count: 12,
    objectID: "715",
    uniqueID: "30670fb6-6d42-45f5-8d68-3fc4d945d686",
  },
  {
    name: "Show Low Regional Airport",
    city: "Show Low",
    country: "United States",
    iata_code: "SOW",
    _geoloc: {
      lat: 34.265556,
      lng: -110.005556,
    },
    links_count: 12,
    objectID: "7078",
    uniqueID: "60851411-d469-402d-8831-696c34d1b3a7",
  },
  {
    name: "San Luis Valley Regional Airport",
    city: "Alamosa",
    country: "United States",
    iata_code: "ALS",
    _geoloc: {
      lat: 37.435,
      lng: -105.866667,
    },
    links_count: 12,
    objectID: "7073",
    uniqueID: "cf705c62-6827-434e-8008-82aaed3d57ca",
  },
  {
    name: "Charles M Schulz Sonoma Co",
    city: "Santa Rosa",
    country: "United States",
    iata_code: "STS",
    _geoloc: {
      lat: 38.508978,
      lng: -122.81288,
    },
    links_count: 12,
    objectID: "6992",
    uniqueID: "7cde063e-ed0f-4196-9033-ae30ec5e0cde",
  },
  {
    name: "Vieques Airport",
    city: "Vieques Island",
    country: "Puerto Rico",
    iata_code: "VQS",
    _geoloc: {
      lat: 18.1158,
      lng: -65.4227,
    },
    links_count: 12,
    objectID: "6788",
    uniqueID: "c5759758-41af-4d5f-8320-3b166ed7a579",
  },
  {
    name: "Gorgan Airport",
    city: "Gorgan",
    country: "Iran",
    iata_code: "GBT",
    _geoloc: {
      lat: 36.909381,
      lng: 54.401339,
    },
    links_count: 12,
    objectID: "6747",
    uniqueID: "e9b9b9d8-41ed-456b-a2eb-268ee8903dfe",
  },
  {
    name: "Navoi Airport",
    city: "Navoi",
    country: "Uzbekistan",
    iata_code: "NVI",
    _geoloc: {
      lat: 40.1172,
      lng: 65.1708,
    },
    links_count: 12,
    objectID: "6485",
    uniqueID: "040244bd-785c-4c79-9e0f-0047580f491b",
  },
  {
    name: "Dibrugarh Airport",
    city: "Dibrugarh",
    country: "India",
    iata_code: "DIB",
    _geoloc: {
      lat: 27.4839,
      lng: 95.0169,
    },
    links_count: 12,
    objectID: "6443",
    uniqueID: "330a3a87-5de4-448c-b270-a72c26a6bf9f",
  },
  {
    name: "Nanchong Airport",
    city: "Nanchong",
    country: "China",
    iata_code: "NAO",
    _geoloc: {
      lat: 30.754,
      lng: 106.062,
    },
    links_count: 12,
    objectID: "6401",
    uniqueID: "6639e0ee-6b14-4ac7-99b2-adade4248d56",
  },
  {
    name: "Quzhou Airport",
    city: "Quzhou",
    country: "China",
    iata_code: "JUZ",
    _geoloc: {
      lat: 28.9658,
      lng: 118.899,
    },
    links_count: 12,
    objectID: "6382",
    uniqueID: "70a50e3f-682d-4141-a935-d6b2da0c7778",
  },
  {
    name: "Jiujiang Lushan Airport",
    city: "Jiujiang",
    country: "China",
    iata_code: "JIU",
    _geoloc: {
      lat: 29.733,
      lng: 115.983,
    },
    links_count: 12,
    objectID: "6381",
    uniqueID: "810bd1b7-b3b0-403b-87e0-ee22b44801d0",
  },
  {
    name: "Mangshi Airport",
    city: "Luxi",
    country: "China",
    iata_code: "LUM",
    _geoloc: {
      lat: 24.4011,
      lng: 98.5317,
    },
    links_count: 12,
    objectID: "6376",
    uniqueID: "885a945a-c5b4-4d97-96cf-3c8c4a1ffedf",
  },
  {
    name: "Qingyang Airport",
    city: "Qingyang",
    country: "China",
    iata_code: "IQN",
    _geoloc: {
      lat: 35.7997,
      lng: 107.603,
    },
    links_count: 12,
    objectID: "6365",
    uniqueID: "e218b488-e5fb-4973-8b8c-7a8a2620107e",
  },
  {
    name: "Port Macquarie Airport",
    city: "Port Macquarie",
    country: "Australia",
    iata_code: "PQQ",
    _geoloc: {
      lat: -31.4358,
      lng: 152.863,
    },
    links_count: 12,
    objectID: "6320",
    uniqueID: "412dea41-32e5-4755-9f83-b1ad5be0d794",
  },
  {
    name: "Mildura Airport",
    city: "Mildura",
    country: "Australia",
    iata_code: "MQL",
    _geoloc: {
      lat: -34.2292,
      lng: 142.086,
    },
    links_count: 12,
    objectID: "6298",
    uniqueID: "3407bc76-fcab-44d2-b277-dcfa697bbedc",
  },
  {
    name: "Vitória da Conquista Airport",
    city: "Vitória Da Conquista",
    country: "Brazil",
    iata_code: "VDC",
    _geoloc: {
      lat: -14.862761,
      lng: -40.863106,
    },
    links_count: 12,
    objectID: "6039",
    uniqueID: "34628ca7-96f2-4307-b867-cfea4a6a9d37",
  },
  {
    name: "Kerry",
    city: "Kerry",
    country: "Ireland",
    iata_code: "KIR",
    _geoloc: {
      lat: 52.180878,
      lng: -9.523783,
    },
    links_count: 12,
    objectID: "601",
    uniqueID: "49e373a1-3529-4f01-9a41-6f0d736524ed",
  },
  {
    name: "Wewak Intl",
    city: "Wewak",
    country: "Papua New Guinea",
    iata_code: "WWK",
    _geoloc: {
      lat: -3.583828,
      lng: 143.669186,
    },
    links_count: 12,
    objectID: "6",
    uniqueID: "b72867ca-7d0c-4280-b5b0-e9575f58376b",
  },
  {
    name: "Nagoya Airport",
    city: "Nagoya",
    country: "Japan",
    iata_code: "NKM",
    _geoloc: {
      lat: 35.255,
      lng: 136.924,
    },
    links_count: 12,
    objectID: "5997",
    uniqueID: "17ec46e5-7622-4b93-8cea-e5782a5d1ff5",
  },
  {
    name: "McGrath Airport",
    city: "Mcgrath",
    country: "United States",
    iata_code: "MCG",
    _geoloc: {
      lat: 62.9529,
      lng: -155.606,
    },
    links_count: 12,
    objectID: "5965",
    uniqueID: "85cbb2c6-545b-491c-b147-4b637881a2b4",
  },
  {
    name: "Lar Airport",
    city: "Lar",
    country: "Iran",
    iata_code: "LRR",
    _geoloc: {
      lat: 27.674725,
      lng: 54.383278,
    },
    links_count: 12,
    objectID: "5934",
    uniqueID: "013e4703-084c-479e-8f1a-a868d20d9a15",
  },
  {
    name: "Sari Dasht E Naz Airport",
    city: "Dasht-e-naz",
    country: "Iran",
    iata_code: "SRY",
    _geoloc: {
      lat: 36.635833,
      lng: 53.193611,
    },
    links_count: 12,
    objectID: "5933",
    uniqueID: "ba577ae9-16ba-4eea-b6f2-eba0fddcb365",
  },
  {
    name: "Samaná El Catey International Airport",
    city: "Samana",
    country: "Dominican Republic",
    iata_code: "AZS",
    _geoloc: {
      lat: 19.267,
      lng: -69.742,
    },
    links_count: 12,
    objectID: "5810",
    uniqueID: "7f595a7a-1da1-45a6-97d8-23a9586deec0",
  },
  {
    name: "Asyut International Airport",
    city: "Asyut",
    country: "Egypt",
    iata_code: "ATZ",
    _geoloc: {
      lat: 27.046508,
      lng: 31.011983,
    },
    links_count: 12,
    objectID: "5691",
    uniqueID: "d4d320c8-80b0-438e-af9c-ca959cdb404d",
  },
  {
    name: "Ondjiva Pereira Airport",
    city: "Ondjiva",
    country: "Angola",
    iata_code: "VPE",
    _geoloc: {
      lat: -17.043464,
      lng: 15.683822,
    },
    links_count: 12,
    objectID: "5632",
    uniqueID: "37282fbb-4655-49f4-8a53-b15c9846b5b7",
  },
  {
    name: "Waskaganish Airport",
    city: "Waskaganish",
    country: "Canada",
    iata_code: "YKQ",
    _geoloc: {
      lat: 51.4733,
      lng: -78.7583,
    },
    links_count: 12,
    objectID: "5507",
    uniqueID: "01deedc8-4714-4dd0-8d9e-28e63a75def4",
  },
  {
    name: "Chesterfield Inlet Airport",
    city: "Chesterfield Inlet",
    country: "Canada",
    iata_code: "YCS",
    _geoloc: {
      lat: 63.3469,
      lng: -90.7311,
    },
    links_count: 12,
    objectID: "5487",
    uniqueID: "48406efd-e400-4a61-8e92-7a74b027683f",
  },
  {
    name: "Caye Caulker Airport",
    city: "Caye Caulker",
    country: "Belize",
    iata_code: "CUK",
    _geoloc: {
      lat: 17.7347,
      lng: -88.0325,
    },
    links_count: 12,
    objectID: "5454",
    uniqueID: "e3cd7f63-70c6-4f83-af7a-c33cf00f05da",
  },
  {
    name: "Kimbe Airport",
    city: "Hoskins",
    country: "Papua New Guinea",
    iata_code: "HKN",
    _geoloc: {
      lat: -5.46217,
      lng: 150.405,
    },
    links_count: 12,
    objectID: "5424",
    uniqueID: "e44e1f7b-cb48-4e67-8018-f3091e4fa676",
  },
  {
    name: "St Mawgan",
    city: "Newquai",
    country: "United Kingdom",
    iata_code: "NQY",
    _geoloc: {
      lat: 50.440558,
      lng: -4.995408,
    },
    links_count: 12,
    objectID: "481",
    uniqueID: "1c708d20-104e-487d-b12a-496ffb234b01",
  },
  {
    name: "Marsa Alam Intl",
    city: "Marsa Alam",
    country: "Egypt",
    iata_code: "RMF",
    _geoloc: {
      lat: 25.557111,
      lng: 34.583711,
    },
    links_count: 12,
    objectID: "4197",
    uniqueID: "9ab44146-6763-427f-837a-831b02c6f5b2",
  },
  {
    name: "Kalymnos Island",
    city: "Kalymnos",
    country: "Greece",
    iata_code: "JKL",
    _geoloc: {
      lat: 36.963333,
      lng: 26.940556,
    },
    links_count: 12,
    objectID: "4192",
    uniqueID: "ae8ee0ae-0bf3-483a-8ebb-1b3d053b99cb",
  },
  {
    name: "Muan",
    city: "Muan",
    country: "South Korea",
    iata_code: "MWX",
    _geoloc: {
      lat: 34.991389,
      lng: 126.382778,
    },
    links_count: 12,
    objectID: "4189",
    uniqueID: "a6d02aa2-652e-4655-980e-bc3a926dce00",
  },
  {
    name: "Dongying Airport",
    city: "Dongying",
    country: "China",
    iata_code: "DOY",
    _geoloc: {
      lat: 37.2716,
      lng: 118.2819,
    },
    links_count: 12,
    objectID: "4145",
    uniqueID: "bff2413e-cdae-411b-9270-35d7f741c15e",
  },
  {
    name: "Sandakan",
    city: "Sandakan",
    country: "Malaysia",
    iata_code: "SDK",
    _geoloc: {
      lat: 5.900897,
      lng: 118.059486,
    },
    links_count: 12,
    objectID: "4141",
    uniqueID: "120537f2-44ac-4da0-82f9-649c6f7b92b3",
  },
  {
    name: "Page Municipal Airport",
    city: "Page",
    country: "United States",
    iata_code: "PGA",
    _geoloc: {
      lat: 36.9261,
      lng: -111.4483,
    },
    links_count: 12,
    objectID: "4124",
    uniqueID: "ecf78c10-9374-4375-8ff0-8e5da567947d",
  },
  {
    name: "Bismarck Municipal Airport",
    city: "Bismarck",
    country: "United States",
    iata_code: "BIS",
    _geoloc: {
      lat: 46.775842,
      lng: -100.757931,
    },
    links_count: 12,
    objectID: "4083",
    uniqueID: "2f218906-ced2-4315-9005-22603edd356d",
  },
  {
    name: "Mulu",
    city: "Mulu",
    country: "Malaysia",
    iata_code: "MZV",
    _geoloc: {
      lat: 4.048333,
      lng: 114.805,
    },
    links_count: 12,
    objectID: "4056",
    uniqueID: "7d506d06-9b54-4b6b-8e5c-66f21de5f5e1",
  },
  {
    name: "Kalamazoo",
    city: "Kalamazoo",
    country: "United States",
    iata_code: "AZO",
    _geoloc: {
      lat: 42.234875,
      lng: -85.552058,
    },
    links_count: 12,
    objectID: "4039",
    uniqueID: "e7bd7413-3df1-4082-80d7-7e10ba1d058c",
  },
  {
    name: "Batumi",
    city: "Batumi",
    country: "Georgia",
    iata_code: "BUS",
    _geoloc: {
      lat: 41.610278,
      lng: 41.599694,
    },
    links_count: 12,
    objectID: "3971",
    uniqueID: "e621c144-fb89-4e83-89e2-70a5a3044e58",
  },
  {
    name: "Elmira Corning Rgnl",
    city: "Elmira",
    country: "United States",
    iata_code: "ELM",
    _geoloc: {
      lat: 42.159889,
      lng: -76.891611,
    },
    links_count: 12,
    objectID: "3946",
    uniqueID: "4e3814f4-dc1b-40b5-915c-de97a602d34a",
  },
  {
    name: "New Castle",
    city: "Wilmington",
    country: "United States",
    iata_code: "ILG",
    _geoloc: {
      lat: 39.678722,
      lng: -75.606528,
    },
    links_count: 12,
    objectID: "3859",
    uniqueID: "a63612a7-99b8-4543-8bd0-3f64763b2de2",
  },
  {
    name: "Alexandria Intl",
    city: "Alexandria",
    country: "United States",
    iata_code: "AEX",
    _geoloc: {
      lat: 31.3274,
      lng: -92.549833,
    },
    links_count: 12,
    objectID: "3852",
    uniqueID: "c4a468c9-24d6-4540-abb4-19e5125d915d",
  },
  {
    name: "Mc Allen Miller Intl",
    city: "Mcallen",
    country: "United States",
    iata_code: "MFE",
    _geoloc: {
      lat: 26.175833,
      lng: -98.238611,
    },
    links_count: 12,
    objectID: "3826",
    uniqueID: "9a55310a-2b0d-43c3-87ec-f8be8617c11c",
  },
  {
    name: "Cheyenne Rgnl Jerry Olson Fld",
    city: "Cheyenne",
    country: "United States",
    iata_code: "CYS",
    _geoloc: {
      lat: 41.155722,
      lng: -104.811839,
    },
    links_count: 12,
    objectID: "3804",
    uniqueID: "dcdfc32d-3672-4833-8761-55415cc936c1",
  },
  {
    name: "Santa Fe Muni",
    city: "Santa Fe",
    country: "United States",
    iata_code: "SAF",
    _geoloc: {
      lat: 35.617108,
      lng: -106.089422,
    },
    links_count: 12,
    objectID: "3784",
    uniqueID: "aa728400-f7ec-4c78-bb5c-e6dd7e6681d5",
  },
  {
    name: "Robert Gray Aaf",
    city: "Killeen",
    country: "United States",
    iata_code: "GRK",
    _geoloc: {
      lat: 31.06725,
      lng: -97.828917,
    },
    links_count: 12,
    objectID: "3775",
    uniqueID: "dfde4061-5032-43fb-9852-5d71bb7bd9fa",
  },
  {
    name: "Monroe Rgnl",
    city: "Monroe",
    country: "United States",
    iata_code: "MLU",
    _geoloc: {
      lat: 32.510864,
      lng: -92.037689,
    },
    links_count: 12,
    objectID: "3732",
    uniqueID: "e792c383-9c68-4e1a-b0b6-eb85eec4c0da",
  },
  {
    name: "Stewart Intl",
    city: "Newburgh",
    country: "United States",
    iata_code: "SWF",
    _geoloc: {
      lat: 41.504094,
      lng: -74.104839,
    },
    links_count: 12,
    objectID: "3661",
    uniqueID: "40b7a9a0-9efe-4de8-b06b-171253d72b73",
  },
  {
    name: "Meadows Fld",
    city: "Bakersfield",
    country: "United States",
    iata_code: "BFL",
    _geoloc: {
      lat: 35.433598,
      lng: -119.05677,
    },
    links_count: 12,
    objectID: "3558",
    uniqueID: "3f2a8646-e32d-48b3-9804-0fe19ccd3ce2",
  },
  {
    name: "Nantucket Mem",
    city: "Nantucket",
    country: "United States",
    iata_code: "ACK",
    _geoloc: {
      lat: 41.253053,
      lng: -70.060181,
    },
    links_count: 12,
    objectID: "3517",
    uniqueID: "84d12349-19f7-4c05-8b68-eb5060eec457",
  },
  {
    name: "Lafayette Rgnl",
    city: "Lafayette",
    country: "United States",
    iata_code: "LFT",
    _geoloc: {
      lat: 30.205278,
      lng: -91.987611,
    },
    links_count: 12,
    objectID: "3493",
    uniqueID: "89acd903-b1bb-425c-8501-183f1e6532bb",
  },
  {
    name: "Hotan",
    city: "Hotan",
    country: "China",
    iata_code: "HTN",
    _geoloc: {
      lat: 37.038522,
      lng: 79.864933,
    },
    links_count: 12,
    objectID: "3398",
    uniqueID: "38e1ca76-02aa-4fb7-a838-d354d9286d62",
  },
  {
    name: "Erfurt",
    city: "Erfurt",
    country: "Germany",
    iata_code: "ERF",
    _geoloc: {
      lat: 50.979811,
      lng: 10.958106,
    },
    links_count: 12,
    objectID: "339",
    uniqueID: "51c4f603-9124-4fa6-8519-331d57806c28",
  },
  {
    name: "Sultan Thaha",
    city: "Jambi",
    country: "Indonesia",
    iata_code: "DJB",
    _geoloc: {
      lat: -1.638017,
      lng: 103.644378,
    },
    links_count: 12,
    objectID: "3287",
    uniqueID: "a500c344-b63c-4fa2-915a-578627e2c241",
  },
  {
    name: "Supadio",
    city: "Pontianak",
    country: "Indonesia",
    iata_code: "PNK",
    _geoloc: {
      lat: -0.150711,
      lng: 109.403892,
    },
    links_count: 12,
    objectID: "3284",
    uniqueID: "0c6d82c8-4c44-4d99-891d-7b04ca8102d4",
  },
  {
    name: "Marudi",
    city: "Marudi",
    country: "Malaysia",
    iata_code: "MUR",
    _geoloc: {
      lat: 4.1775,
      lng: 114.321944,
    },
    links_count: 12,
    objectID: "3265",
    uniqueID: "7a246367-9774-4d6e-bd39-759c33d87769",
  },
  {
    name: "Cambridge Bay",
    city: "Cambridge Bay",
    country: "Canada",
    iata_code: "YCB",
    _geoloc: {
      lat: 69.108055,
      lng: -105.138333,
    },
    links_count: 12,
    objectID: "32",
    uniqueID: "7f1d723b-4b9d-413c-8613-ca5f5a4ff539",
  },
  {
    name: "Udon Thani",
    city: "Udon Thani",
    country: "Thailand",
    iata_code: "UTH",
    _geoloc: {
      lat: 17.386436,
      lng: 102.788247,
    },
    links_count: 12,
    objectID: "3183",
    uniqueID: "0a67636e-9a45-40d3-88e5-d6143e6299bd",
  },
  {
    name: "Hat Yai Intl",
    city: "Hat Yai",
    country: "Thailand",
    iata_code: "HDY",
    _geoloc: {
      lat: 6.933206,
      lng: 100.392975,
    },
    links_count: 12,
    objectID: "3181",
    uniqueID: "d509b31f-cdfe-4f7e-a287-987655e38fea",
  },
  {
    name: "Leh",
    city: "Leh",
    country: "India",
    iata_code: "IXL",
    _geoloc: {
      lat: 34.135872,
      lng: 77.546514,
    },
    links_count: 12,
    objectID: "3104",
    uniqueID: "e8966947-8164-4c52-9a63-2b2802c23bfb",
  },
  {
    name: "Jodhpur",
    city: "Jodhpur",
    country: "India",
    iata_code: "JDH",
    _geoloc: {
      lat: 26.251092,
      lng: 73.048869,
    },
    links_count: 12,
    objectID: "3097",
    uniqueID: "cf4be690-d398-479d-a75d-ddfddcd6b717",
  },
  {
    name: "Aizawl",
    city: "Aizwal",
    country: "India",
    iata_code: "AJL",
    _geoloc: {
      lat: 23.746603,
      lng: 92.802767,
    },
    links_count: 12,
    objectID: "3039",
    uniqueID: "fff2e8ab-d607-44da-990a-b7b40538bcba",
  },
  {
    name: "Campbell River",
    city: "Campbell River",
    country: "Canada",
    iata_code: "YBL",
    _geoloc: {
      lat: 49.950832,
      lng: -125.270833,
    },
    links_count: 12,
    objectID: "30",
    uniqueID: "5c254006-41a2-4ec0-9230-7b431d97c7ce",
  },
  {
    name: "Bolshoye Savino",
    city: "Perm",
    country: "Russia",
    iata_code: "PEE",
    _geoloc: {
      lat: 57.914517,
      lng: 56.021214,
    },
    links_count: 12,
    objectID: "2973",
    uniqueID: "a7332761-54d5-4448-8a06-753cfec16d6a",
  },
  {
    name: "Yelizovo",
    city: "Petropavlovsk",
    country: "Russia",
    iata_code: "PKC",
    _geoloc: {
      lat: 53.167889,
      lng: 158.453669,
    },
    links_count: 12,
    objectID: "2932",
    uniqueID: "7f4c218e-fc27-4bb5-b530-9d148b52062f",
  },
  {
    name: "Sokol",
    city: "Magadan",
    country: "Russia",
    iata_code: "GDX",
    _geoloc: {
      lat: 59.910989,
      lng: 150.720439,
    },
    links_count: 12,
    objectID: "2930",
    uniqueID: "d2df5486-a890-444f-b67d-9895b74367d5",
  },
  {
    name: "Wallblake",
    city: "The Valley",
    country: "Anguilla",
    iata_code: "AXA",
    _geoloc: {
      lat: 18.204834,
      lng: -63.055084,
    },
    links_count: 12,
    objectID: "2900",
    uniqueID: "12860a01-a0ce-4e5d-9670-4638de818cb4",
  },
  {
    name: "Baker Lake",
    city: "Baker Lake",
    country: "Canada",
    iata_code: "YBK",
    _geoloc: {
      lat: 64.298889,
      lng: -96.077778,
    },
    links_count: 12,
    objectID: "29",
    uniqueID: "a3311766-7055-4f35-b235-a38cd6f474da",
  },
  {
    name: "Cadete Guillermo Del Castillo Paredes",
    city: "Tarapoto",
    country: "Peru",
    iata_code: "TPP",
    _geoloc: {
      lat: -6.508742,
      lng: -76.373247,
    },
    links_count: 12,
    objectID: "2806",
    uniqueID: "e744428a-40b6-4184-876c-bec5a7779616",
  },
  {
    name: "Cap Fap David Abenzur Rengifo Intl",
    city: "Pucallpa",
    country: "Peru",
    iata_code: "PCL",
    _geoloc: {
      lat: -8.377939,
      lng: -74.574297,
    },
    links_count: 12,
    objectID: "2781",
    uniqueID: "2021b0e5-453d-46ec-a038-63830b5202c4",
  },
  {
    name: "Juana Azurduy De Padilla",
    city: "Sucre",
    country: "Bolivia",
    iata_code: "SRE",
    _geoloc: {
      lat: -19.007083,
      lng: -65.288747,
    },
    links_count: 12,
    objectID: "2767",
    uniqueID: "7404587f-54f3-4d19-9cec-03774ac9502a",
  },
  {
    name: "El Carano",
    city: "Quibdo",
    country: "Colombia",
    iata_code: "UIB",
    _geoloc: {
      lat: 5.690758,
      lng: -76.641181,
    },
    links_count: 12,
    objectID: "2753",
    uniqueID: "ddd4f510-aa1f-45c6-9eac-6c7e0cacbb0a",
  },
  {
    name: "Matecana",
    city: "Pereira",
    country: "Colombia",
    iata_code: "PEI",
    _geoloc: {
      lat: 4.812675,
      lng: -75.739519,
    },
    links_count: 12,
    objectID: "2739",
    uniqueID: "e08d6b49-66eb-43b8-857e-11065ec16625",
  },
  {
    name: "Alfredo Vasquez Cobo",
    city: "Leticia",
    country: "Colombia",
    iata_code: "LET",
    _geoloc: {
      lat: -4.193549,
      lng: -69.943163,
    },
    links_count: 12,
    objectID: "2728",
    uniqueID: "14074375-480d-401d-9cdb-c396cad88a89",
  },
  {
    name: "Baie Comeau",
    city: "Baie Comeau",
    country: "Canada",
    iata_code: "YBC",
    _geoloc: {
      lat: 49.1325,
      lng: -68.204444,
    },
    links_count: 12,
    objectID: "27",
    uniqueID: "29a7e70e-2d99-4214-ab82-d4c9f2e0cc90",
  },
  {
    name: "Presidente Peron",
    city: "Neuquen",
    country: "Argentina",
    iata_code: "NQN",
    _geoloc: {
      lat: -38.949,
      lng: -68.155711,
    },
    links_count: 12,
    objectID: "2509",
    uniqueID: "9420f8e8-ca38-4a85-9789-d2a601aced22",
  },
  {
    name: "Salta",
    city: "Salta",
    country: "Argentina",
    iata_code: "SLA",
    _geoloc: {
      lat: -24.855978,
      lng: -65.486169,
    },
    links_count: 12,
    objectID: "2476",
    uniqueID: "97e38966-d0c9-42eb-be7a-69d3c6372467",
  },
  {
    name: "Bacolod",
    city: "Bacolod",
    country: "Philippines",
    iata_code: "BCD",
    _geoloc: {
      lat: 10.642511,
      lng: 122.929617,
    },
    links_count: 12,
    objectID: "2423",
    uniqueID: "b1dc0de1-219e-43e5-90c4-df347e81336a",
  },
  {
    name: "Zamboanga Intl",
    city: "Zamboanga",
    country: "Philippines",
    iata_code: "ZAM",
    _geoloc: {
      lat: 6.922419,
      lng: 122.059633,
    },
    links_count: 12,
    objectID: "2404",
    uniqueID: "88a0de55-1575-43cc-b876-74ca2c53754a",
  },
  {
    name: "Daegu Ab",
    city: "Taegu",
    country: "South Korea",
    iata_code: "TAE",
    _geoloc: {
      lat: 35.894108,
      lng: 128.658856,
    },
    links_count: 12,
    objectID: "2381",
    uniqueID: "e2998d4e-f1c8-497f-a2fb-fb994484df4c",
  },
  {
    name: "Oued Irara",
    city: "Hassi Messaoud",
    country: "Algeria",
    iata_code: "HME",
    _geoloc: {
      lat: 31.672972,
      lng: 6.140444,
    },
    links_count: 12,
    objectID: "238",
    uniqueID: "f6e1374a-6811-48cb-8abe-37bdc01d5170",
  },
  {
    name: "Zenata",
    city: "Tlemcen",
    country: "Algeria",
    iata_code: "TLM",
    _geoloc: {
      lat: 35.016667,
      lng: -1.45,
    },
    links_count: 12,
    objectID: "230",
    uniqueID: "243a2ef1-dc45-4593-a70b-ccae43965505",
  },
  {
    name: "Memanbetsu",
    city: "Memanbetsu",
    country: "Japan",
    iata_code: "MMB",
    _geoloc: {
      lat: 43.880606,
      lng: 144.164053,
    },
    links_count: 12,
    objectID: "2290",
    uniqueID: "2cc6c42e-a660-4040-8d98-64a50947863c",
  },
  {
    name: "Chah Bahar",
    city: "Chah Bahar",
    country: "Iran",
    iata_code: "ZBR",
    _geoloc: {
      lat: 25.44335,
      lng: 60.382114,
    },
    links_count: 12,
    objectID: "2166",
    uniqueID: "9d4aa117-ab23-425e-b7af-f57171b6251b",
  },
  {
    name: "Shahid Ashrafi Esfahani",
    city: "Bakhtaran",
    country: "Iran",
    iata_code: "KSH",
    _geoloc: {
      lat: 34.345853,
      lng: 47.158128,
    },
    links_count: 12,
    objectID: "2114",
    uniqueID: "52a3d24b-27d4-47e4-bdf9-550808234ddd",
  },
  {
    name: "Abadan",
    city: "Abadan",
    country: "Iran",
    iata_code: "ABD",
    _geoloc: {
      lat: 30.371111,
      lng: 48.228333,
    },
    links_count: 12,
    objectID: "2097",
    uniqueID: "ec72df83-f11d-4536-a3c5-3303c046a68f",
  },
  {
    name: "Soummam",
    city: "Bejaja",
    country: "Algeria",
    iata_code: "BJA",
    _geoloc: {
      lat: 36.711997,
      lng: 5.069922,
    },
    links_count: 12,
    objectID: "209",
    uniqueID: "3364979f-82d0-432e-aed0-4b28bb134cb5",
  },
  {
    name: "King Abdullah Bin Abdulaziz",
    city: "Gizan",
    country: "Saudi Arabia",
    iata_code: "GIZ",
    _geoloc: {
      lat: 16.901111,
      lng: 42.585833,
    },
    links_count: 12,
    objectID: "2066",
    uniqueID: "efdbb436-4e84-4223-970a-2a9025373854",
  },
  {
    name: "Herat",
    city: "Herat",
    country: "Afghanistan",
    iata_code: "HEA",
    _geoloc: {
      lat: 34.210017,
      lng: 62.2283,
    },
    links_count: 12,
    objectID: "2048",
    uniqueID: "49bee326-5732-4175-9e85-3dc566cab6cc",
  },
  {
    name: "Bora Bora",
    city: "Bora Bora",
    country: "French Polynesia",
    iata_code: "BOB",
    _geoloc: {
      lat: -16.444378,
      lng: -151.751286,
    },
    links_count: 12,
    objectID: "1989",
    uniqueID: "3a213ccd-e821-470e-80de-ddbfd6a31cb8",
  },
  {
    name: "Tajin",
    city: "Poza Rico",
    country: "Mexico",
    iata_code: "PAZ",
    _geoloc: {
      lat: 20.602671,
      lng: -97.460839,
    },
    links_count: 12,
    objectID: "1830",
    uniqueID: "2978225b-35f7-4fd2-9545-58e853b0424e",
  },
  {
    name: "Xoxocotlan Intl",
    city: "Oaxaca",
    country: "Mexico",
    iata_code: "OAX",
    _geoloc: {
      lat: 16.999906,
      lng: -96.726639,
    },
    links_count: 12,
    objectID: "1829",
    uniqueID: "f12368ad-72f7-4bcd-8258-e1aa7febc523",
  },
  {
    name: "London",
    city: "London",
    country: "Canada",
    iata_code: "YXU",
    _geoloc: {
      lat: 43.033056,
      lng: -81.151111,
    },
    links_count: 12,
    objectID: "174",
    uniqueID: "e4af70c1-feb0-47f5-a757-95f8351cd315",
  },
  {
    name: "Gibraltar",
    city: "Gibraltar",
    country: "Gibraltar",
    iata_code: "GIB",
    _geoloc: {
      lat: 36.151219,
      lng: -5.349664,
    },
    links_count: 12,
    objectID: "1738",
    uniqueID: "92210520-a8ef-4d5f-bf20-5a88ec7ce05b",
  },
  {
    name: "Elazig",
    city: "Elazig",
    country: "Turkey",
    iata_code: "EZS",
    _geoloc: {
      lat: 38.606925,
      lng: 39.291417,
    },
    links_count: 12,
    objectID: "1721",
    uniqueID: "5a13ed6d-ca1c-4f7c-b829-c929d651f908",
  },
  {
    name: "Fort St John",
    city: "Fort Saint John",
    country: "Canada",
    iata_code: "YXJ",
    _geoloc: {
      lat: 56.238056,
      lng: -120.740278,
    },
    links_count: 12,
    objectID: "168",
    uniqueID: "480c53dd-023d-4c65-9b49-e7f305bbde4d",
  },
  {
    name: "Horta",
    city: "Horta",
    country: "Portugal",
    iata_code: "HOR",
    _geoloc: {
      lat: 38.519894,
      lng: -28.715872,
    },
    links_count: 12,
    objectID: "1628",
    uniqueID: "e214c7cb-94f6-49c4-8517-2d99e31e1aee",
  },
  {
    name: "Norman Wells",
    city: "Norman Wells",
    country: "Canada",
    iata_code: "YVQ",
    _geoloc: {
      lat: 65.281617,
      lng: -126.798219,
    },
    links_count: 12,
    objectID: "155",
    uniqueID: "9b84e9e0-f039-4c81-8a97-717bc7ce8fae",
  },
  {
    name: "Reggio Calabria",
    city: "Reggio Calabria",
    country: "Italy",
    iata_code: "REG",
    _geoloc: {
      lat: 38.071206,
      lng: 15.651556,
    },
    links_count: 12,
    objectID: "1514",
    uniqueID: "d6570bb8-e6c6-4967-82eb-d5cae2d535f1",
  },
  {
    name: "Leros",
    city: "Leros",
    country: "Greece",
    iata_code: "LRS",
    _geoloc: {
      lat: 37.184903,
      lng: 26.800289,
    },
    links_count: 12,
    objectID: "1464",
    uniqueID: "73707b9c-a6ba-47a2-a34b-ece0aa10799b",
  },
  {
    name: "Megas Alexandros Intl",
    city: "Kavala",
    country: "Greece",
    iata_code: "KVA",
    _geoloc: {
      lat: 40.913306,
      lng: 24.619223,
    },
    links_count: 12,
    objectID: "1462",
    uniqueID: "715c50b6-2110-4f4e-aa4b-63aa354f57cf",
  },
  {
    name: "Chios",
    city: "Chios",
    country: "Greece",
    iata_code: "JKH",
    _geoloc: {
      lat: 38.343175,
      lng: 26.140572,
    },
    links_count: 12,
    objectID: "1450",
    uniqueID: "ae6a80e4-5027-4786-8e3a-c29ba9f19b75",
  },
  {
    name: "Mwanza",
    city: "Mwanza",
    country: "Tanzania",
    iata_code: "MWZ",
    _geoloc: {
      lat: -2.444486,
      lng: 32.932667,
    },
    links_count: 12,
    objectID: "1183",
    uniqueID: "f8eac732-d11f-4ed3-8a01-d6e11d28091d",
  },
  {
    name: "Wajir",
    city: "Wajir",
    country: "Kenya",
    iata_code: "WJR",
    _geoloc: {
      lat: 1.733239,
      lng: 40.091606,
    },
    links_count: 12,
    objectID: "1149",
    uniqueID: "d6095a62-de98-4e8a-9730-02b14e622527",
  },
  {
    name: "Sao Pedro",
    city: "Sao Vicente Island",
    country: "Cape Verde",
    iata_code: "VXE",
    _geoloc: {
      lat: 16.833689,
      lng: -25.054661,
    },
    links_count: 12,
    objectID: "1106",
    uniqueID: "ce377405-0d82-4750-b8ec-55b34e7ffbc0",
  },
  {
    name: "Shaktoolik Airport",
    city: "Shaktoolik",
    country: "United States",
    iata_code: "SKK",
    _geoloc: {
      lat: 64.371111,
      lng: -161.223889,
    },
    links_count: 11,
    objectID: "7192",
    uniqueID: "4d140f93-2d59-4e91-96f1-a37ef7bebf5a",
  },
  {
    name: "Zweibruecken",
    city: "Zweibruecken",
    country: "Germany",
    iata_code: "ZQW",
    _geoloc: {
      lat: 49.209445,
      lng: 7.401323,
    },
    links_count: 11,
    objectID: "6510",
    uniqueID: "ef6e61b0-0a9a-4d04-9ff9-7f912cacb5d4",
  },
  {
    name: "Aksu Airport",
    city: "Aksu",
    country: "China",
    iata_code: "AKU",
    _geoloc: {
      lat: 41.2625,
      lng: 80.2917,
    },
    links_count: 11,
    objectID: "6404",
    uniqueID: "e8b2da67-5222-4233-a4b3-7fe0e09a0838",
  },
  {
    name: "Yan'an Airport",
    city: "Yan'an",
    country: "China",
    iata_code: "ENY",
    _geoloc: {
      lat: 36.6369,
      lng: 109.554,
    },
    links_count: 11,
    objectID: "6367",
    uniqueID: "b79e968d-3b4b-46d5-8792-96a482049fb9",
  },
  {
    name: "Radin Inten II (Branti) Airport",
    city: "Bandar Lampung-Sumatra Island",
    country: "Indonesia",
    iata_code: "TKG",
    _geoloc: {
      lat: -5.242339,
      lng: 105.178939,
    },
    links_count: 11,
    objectID: "6223",
    uniqueID: "7642a7a4-f1f7-410b-b37b-13f11febc288",
  },
  {
    name: "Turbat International Airport",
    city: "Turbat",
    country: "Pakistan",
    iata_code: "TUK",
    _geoloc: {
      lat: 25.986369,
      lng: 63.030167,
    },
    links_count: 11,
    objectID: "5951",
    uniqueID: "df150292-9ece-41c7-8898-0d24c4193039",
  },
  {
    name: "Bahawalpur Airport",
    city: "Bahawalpur",
    country: "Pakistan",
    iata_code: "BHV",
    _geoloc: {
      lat: 29.3481,
      lng: 71.717981,
    },
    links_count: 11,
    objectID: "5939",
    uniqueID: "0765f248-7493-43a6-9484-83d6e5e64676",
  },
  {
    name: "Whale Cove Airport",
    city: "Whale Cove",
    country: "Canada",
    iata_code: "YXN",
    _geoloc: {
      lat: 62.24,
      lng: -92.5981,
    },
    links_count: 11,
    objectID: "5534",
    uniqueID: "9080a74c-1eaa-45cc-9445-68b6a156cf95",
  },
  {
    name: "Gurney Airport",
    city: "Gurney",
    country: "Papua New Guinea",
    iata_code: "GUR",
    _geoloc: {
      lat: -10.3115,
      lng: 150.334,
    },
    links_count: 11,
    objectID: "5422",
    uniqueID: "fd68ea23-9746-4d2a-814d-31d3a5f06bfa",
  },
  {
    name: "Fairmont Hot Springs",
    city: "Coral Harbour",
    country: "Canada",
    iata_code: "YZS",
    _geoloc: {
      lat: 64.193333,
      lng: -83.359444,
    },
    links_count: 11,
    objectID: "41",
    uniqueID: "5472ec40-d318-408e-a188-32e10e230117",
  },
  {
    name: "Deadhorse",
    city: "Deadhorse",
    country: "United States",
    iata_code: "SCC",
    _geoloc: {
      lat: 70.19475,
      lng: -148.465167,
    },
    links_count: 11,
    objectID: "3620",
    uniqueID: "da7111e5-1d34-479c-a02b-c784862c4a23",
  },
  {
    name: "Carriel Sur Intl",
    city: "Concepcion",
    country: "Chile",
    iata_code: "CCP",
    _geoloc: {
      lat: -36.77265,
      lng: -73.063106,
    },
    links_count: 11,
    objectID: "2656",
    uniqueID: "9c4bcf30-a6f5-433a-afee-c8995454901e",
  },
  {
    name: "Rio Gallegos",
    city: "Rio Gallegos",
    country: "Argentina",
    iata_code: "RGL",
    _geoloc: {
      lat: -51.608875,
      lng: -69.312636,
    },
    links_count: 11,
    objectID: "2496",
    uniqueID: "a136aaa3-3790-4ef9-bfd8-6f1641619089",
  },
  {
    name: "Almirante Zar",
    city: "Trelew",
    country: "Argentina",
    iata_code: "REL",
    _geoloc: {
      lat: -43.2105,
      lng: -65.270319,
    },
    links_count: 11,
    objectID: "2490",
    uniqueID: "217b0d60-2a9e-486d-a1da-56625ffb6e36",
  },
  {
    name: "Ouargla",
    city: "Ouargla",
    country: "Algeria",
    iata_code: "OGX",
    _geoloc: {
      lat: 31.917223,
      lng: 5.412778,
    },
    links_count: 11,
    objectID: "243",
    uniqueID: "4c76f495-b300-4bbb-a80e-97cb53498735",
  },
  {
    name: "Touat Cheikh Sidi Mohamed Belkebir",
    city: "Adrar",
    country: "Algeria",
    iata_code: "AZR",
    _geoloc: {
      lat: 27.837589,
      lng: -0.186414,
    },
    links_count: 11,
    objectID: "234",
    uniqueID: "70e67e29-1b15-4fb9-9d2d-5fb3c37a0518",
  },
  {
    name: "Sukkur",
    city: "Sukkur",
    country: "Pakistan",
    iata_code: "SKZ",
    _geoloc: {
      lat: 27.721989,
      lng: 68.791683,
    },
    links_count: 11,
    objectID: "2227",
    uniqueID: "b3d91cad-00a7-48e0-abac-a3393d0dcb6a",
  },
  {
    name: "Rangiroa",
    city: "Rangiroa",
    country: "French Polynesia",
    iata_code: "RGI",
    _geoloc: {
      lat: -14.954283,
      lng: -147.6608,
    },
    links_count: 11,
    objectID: "1990",
    uniqueID: "72564681-e040-4fab-87c8-ac2ebf49d3e1",
  },
  {
    name: "Faleolo Intl",
    city: "Faleolo",
    country: "Samoa",
    iata_code: "APW",
    _geoloc: {
      lat: -13.829969,
      lng: -172.008336,
    },
    links_count: 11,
    objectID: "1969",
    uniqueID: "fc2060e5-d89e-428d-88cc-5bc2dc374c9b",
  },
  {
    name: "Antonio Maceo Intl",
    city: "Santiago De Cuba",
    country: "Cuba",
    iata_code: "SCU",
    _geoloc: {
      lat: 19.969769,
      lng: -75.835414,
    },
    links_count: 11,
    objectID: "1905",
    uniqueID: "86c6b159-2006-4288-b0bf-fa40f4646c4a",
  },
  {
    name: "Kosice",
    city: "Kosice",
    country: "Slovakia",
    iata_code: "KSC",
    _geoloc: {
      lat: 48.663055,
      lng: 21.241112,
    },
    links_count: 11,
    objectID: "1746",
    uniqueID: "af9fa709-dd75-42ab-9ef1-30f89d84cc90",
  },
  {
    name: "Timmins",
    city: "Timmins",
    country: "Canada",
    iata_code: "YTS",
    _geoloc: {
      lat: 48.569721,
      lng: -81.376667,
    },
    links_count: 11,
    objectID: "143",
    uniqueID: "e4e4d6f1-cb1f-4a87-8535-c39b0ee40e7e",
  },
  {
    name: "Sudbury",
    city: "Sudbury",
    country: "Canada",
    iata_code: "YSB",
    _geoloc: {
      lat: 46.625,
      lng: -80.798889,
    },
    links_count: 11,
    objectID: "133",
    uniqueID: "463b9379-4bbe-450c-bf3f-d705d788287d",
  },
  {
    name: "La Roche",
    city: "Brive",
    country: "France",
    iata_code: "BVE",
    _geoloc: {
      lat: 45.150833,
      lng: 1.469167,
    },
    links_count: 11,
    objectID: "1278",
    uniqueID: "5569a16b-4600-4201-a67b-ae1fada747fc",
  },
  {
    name: "Arrachart",
    city: "Antsiranana",
    country: "Madagascar",
    iata_code: "DIE",
    _geoloc: {
      lat: -12.3494,
      lng: 49.291747,
    },
    links_count: 10,
    objectID: "923",
    uniqueID: "b2c164fd-ada2-4692-8018-93b13b88307a",
  },
  {
    name: "Sanliurfa GAP",
    city: "Sanliurfa",
    country: "Turkey",
    iata_code: "GNY",
    _geoloc: {
      lat: 37.45,
      lng: 38.9,
    },
    links_count: 10,
    objectID: "9044",
    uniqueID: "f1c43e2c-0617-46a5-93b0-ec3c49e4a5b5",
  },
  {
    name: "Changbaishan Airport",
    city: "Baishan",
    country: "China",
    iata_code: "NBS",
    _geoloc: {
      lat: 42.088056,
      lng: 127.548889,
    },
    links_count: 10,
    objectID: "8826",
    uniqueID: "08394d4e-19db-4020-a0f1-789bda8caacb",
  },
  {
    name: "Bayannur",
    city: "Bayannur",
    country: "China",
    iata_code: "RLK",
    _geoloc: {
      lat: 40.926389,
      lng: 107.738889,
    },
    links_count: 10,
    objectID: "8630",
    uniqueID: "dcd925e3-871d-46bb-89f6-87129b3e03db",
  },
  {
    name: "Hector Silva Airstrip",
    city: "Belmopan",
    country: "Belize",
    iata_code: "BCV",
    _geoloc: {
      lat: 17.269444,
      lng: -88.776111,
    },
    links_count: 10,
    objectID: "8227",
    uniqueID: "edf4c939-401b-4a90-9274-1df4062b36a4",
  },
  {
    name: "George",
    city: "George",
    country: "South Africa",
    iata_code: "GRJ",
    _geoloc: {
      lat: -34.005553,
      lng: 22.378889,
    },
    links_count: 10,
    objectID: "804",
    uniqueID: "2740232a-39f2-4d21-8544-7345c173033a",
  },
  {
    name: "East London",
    city: "East London",
    country: "South Africa",
    iata_code: "ELS",
    _geoloc: {
      lat: -33.035569,
      lng: 27.825939,
    },
    links_count: 10,
    objectID: "800",
    uniqueID: "c6fd99c7-d67f-4709-9575-d376e46b330b",
  },
  {
    name: "Schefferville",
    city: "Schefferville",
    country: "Canada",
    iata_code: "YKL",
    _geoloc: {
      lat: 54.805278,
      lng: -66.805278,
    },
    links_count: 10,
    objectID: "80",
    uniqueID: "bcf476da-c441-446d-83fa-ec03c9955c80",
  },
  {
    name: "Tengchong Tuofeng Airport",
    city: "Tengchong",
    country: "China",
    iata_code: "TCZ",
    _geoloc: {
      lat: 24.938651,
      lng: 98.483591,
    },
    links_count: 10,
    objectID: "7862",
    uniqueID: "de7d32dd-7af5-4330-a5d0-3738c57ad3ab",
  },
  {
    name: "Kamloops",
    city: "Kamloops",
    country: "Canada",
    iata_code: "YKA",
    _geoloc: {
      lat: 50.702222,
      lng: -120.444444,
    },
    links_count: 10,
    objectID: "78",
    uniqueID: "3ef75f26-2db7-4164-ae4a-46c70633a3fe",
  },
  {
    name: "Laage",
    city: "Laage",
    country: "Germany",
    iata_code: "RLG",
    _geoloc: {
      lat: 53.918167,
      lng: 12.278333,
    },
    links_count: 10,
    objectID: "772",
    uniqueID: "a84a0167-c1ad-4207-9837-f969bafe945a",
  },
  {
    name: "Tasiilaq",
    city: "Angmagssalik",
    country: "Greenland",
    iata_code: "AGM",
    _geoloc: {
      lat: 65.612222,
      lng: -37.618333,
    },
    links_count: 10,
    objectID: "7642",
    uniqueID: "3a111f4f-681c-48ce-97f0-060c2a80ff22",
  },
  {
    name: "Persian Gulf Airport",
    city: "Khalije Fars",
    country: "Iran",
    iata_code: "PGU",
    _geoloc: {
      lat: 27.379444,
      lng: 52.7375,
    },
    links_count: 10,
    objectID: "7546",
    uniqueID: "d20fba25-7d27-4be2-a711-b1e80524fcbc",
  },
  {
    name: "Sundsvall Harnosand",
    city: "Sundsvall",
    country: "Sweden",
    iata_code: "SDL",
    _geoloc: {
      lat: 62.528125,
      lng: 17.443928,
    },
    links_count: 10,
    objectID: "721",
    uniqueID: "e7d1155e-3386-4cd3-93cd-bc1a66bc6fb7",
  },
  {
    name: "Koyuk Alfred Adams Airport",
    city: "Koyuk",
    country: "United States",
    iata_code: "KKA",
    _geoloc: {
      lat: 64.939444,
      lng: -161.154167,
    },
    links_count: 10,
    objectID: "7190",
    uniqueID: "8a3335fa-b952-404c-a422-602079c295be",
  },
  {
    name: "Hollis Seaplane Base",
    city: "Hollis",
    country: "United States",
    iata_code: "HYL",
    _geoloc: {
      lat: 55.481667,
      lng: -132.646111,
    },
    links_count: 10,
    objectID: "7145",
    uniqueID: "5eaec576-207d-4289-9ac3-ae401398560e",
  },
  {
    name: "Craig Seaplane Base",
    city: "Craig",
    country: "United States",
    iata_code: "CGA",
    _geoloc: {
      lat: 55.478889,
      lng: -133.147778,
    },
    links_count: 10,
    objectID: "7144",
    uniqueID: "dbf441da-53f8-4c6d-aa1a-a2417a490c8f",
  },
  {
    name: "Halmstad",
    city: "Halmstad",
    country: "Sweden",
    iata_code: "HAD",
    _geoloc: {
      lat: 56.691128,
      lng: 12.820211,
    },
    links_count: 10,
    objectID: "709",
    uniqueID: "aaa44a71-2452-4e7e-be4b-ca948d68c8b5",
  },
  {
    name: "Narsarsuaq",
    city: "Narssarssuaq",
    country: "Greenland",
    iata_code: "UAK",
    _geoloc: {
      lat: 61.160517,
      lng: -45.425978,
    },
    links_count: 10,
    objectID: "7",
    uniqueID: "5fd36f91-ed23-41be-bd8c-b77f33963508",
  },
  {
    name: "Tongren",
    city: "Tongren",
    country: "China",
    iata_code: "TEN",
    _geoloc: {
      lat: 27.884,
      lng: 109.31,
    },
    links_count: 10,
    objectID: "6963",
    uniqueID: "72996dba-b6a6-4e05-b1b3-82d57462f0d5",
  },
  {
    name: "Mardin Airport",
    city: "Mardin",
    country: "Turkey",
    iata_code: "MQM",
    _geoloc: {
      lat: 37.2233,
      lng: 40.6317,
    },
    links_count: 10,
    objectID: "6753",
    uniqueID: "1ba52a04-82df-4f19-88f3-39e2dd5254c7",
  },
  {
    name: "St Marys Airport",
    city: "St Mary's",
    country: "United States",
    iata_code: "KSM",
    _geoloc: {
      lat: 62.0605,
      lng: -163.302,
    },
    links_count: 10,
    objectID: "6718",
    uniqueID: "cba2b2f6-1312-4093-a734-d2598dfeb6c3",
  },
  {
    name: "Dandong",
    city: "Dandong",
    country: "China",
    iata_code: "DDG",
    _geoloc: {
      lat: 40.0255,
      lng: 124.2866,
    },
    links_count: 10,
    objectID: "6433",
    uniqueID: "ebacc28d-aad5-4624-81ab-556035348ba3",
  },
  {
    name: "Jinzhou Airport",
    city: "Jinzhou",
    country: "China",
    iata_code: "JNZ",
    _geoloc: {
      lat: 41.1014,
      lng: 121.062,
    },
    links_count: 10,
    objectID: "6412",
    uniqueID: "592cff59-dcd0-4ed9-8cff-98482fa7146e",
  },
  {
    name: "Nyingchi Airport",
    city: "Nyingchi",
    country: "China",
    iata_code: "LZY",
    _geoloc: {
      lat: 29.3033,
      lng: 94.3353,
    },
    links_count: 10,
    objectID: "6402",
    uniqueID: "8a0103cf-3cde-46a2-b61a-feeff4aed36e",
  },
  {
    name: "Meixian Airport",
    city: "Meixian",
    country: "China",
    iata_code: "MXZ",
    _geoloc: {
      lat: 24.35,
      lng: 116.133,
    },
    links_count: 10,
    objectID: "6354",
    uniqueID: "02ec8c20-dc56-48c5-8b24-7882634b5b9b",
  },
  {
    name: "Bronnoy",
    city: "Bronnoysund",
    country: "Norway",
    iata_code: "BNN",
    _geoloc: {
      lat: 65.461111,
      lng: 12.2175,
    },
    links_count: 10,
    objectID: "634",
    uniqueID: "f1c4217a-9b4c-438c-b880-2d08234ea28a",
  },
  {
    name: "Gove Airport",
    city: "Gove",
    country: "Australia",
    iata_code: "GOV",
    _geoloc: {
      lat: -12.2694,
      lng: 136.818,
    },
    links_count: 10,
    objectID: "6316",
    uniqueID: "4c12acf8-3d24-4337-a6bb-6c9aa030a897",
  },
  {
    name: "Cloncurry Airport",
    city: "Cloncurry",
    country: "Australia",
    iata_code: "CNJ",
    _geoloc: {
      lat: -20.6686,
      lng: 140.504,
    },
    links_count: 10,
    objectID: "6255",
    uniqueID: "30e98c92-cbfe-48b3-99f5-eaf61d945708",
  },
  {
    name: "Karshi Khanabad Airport",
    city: "Khanabad",
    country: "Uzbekistan",
    iata_code: "KSQ",
    _geoloc: {
      lat: 38.8336,
      lng: 65.9215,
    },
    links_count: 10,
    objectID: "6153",
    uniqueID: "77f68210-5d58-45c9-a01a-260e10aa3a63",
  },
  {
    name: "Sovetsky Tyumenskaya Airport",
    city: "Sovetskiy",
    country: "Russia",
    iata_code: "OVS",
    _geoloc: {
      lat: 61.32,
      lng: 63.6044,
    },
    links_count: 10,
    objectID: "6140",
    uniqueID: "2286f6d3-4912-47e5-9625-1e2c0a73fbd9",
  },
  {
    name: "Poliarny Airport",
    city: "Yakutia",
    country: "Russia",
    iata_code: "PYJ",
    _geoloc: {
      lat: 66.400431,
      lng: 112.030325,
    },
    links_count: 10,
    objectID: "6092",
    uniqueID: "ce9d4b84-157e-4b70-82c4-edfeb523c725",
  },
  {
    name: "Orlando Bezerra de Menezes Airport",
    city: "Juazeiro Do Norte",
    country: "Brazil",
    iata_code: "JDO",
    _geoloc: {
      lat: -7.218958,
      lng: -39.2701,
    },
    links_count: 10,
    objectID: "6034",
    uniqueID: "4ed682b7-1007-4c19-8c82-8a42e7534af1",
  },
  {
    name: "General Santos International Airport",
    city: "General Santos City",
    country: "Philippines",
    iata_code: "GES",
    _geoloc: {
      lat: 6.106439,
      lng: 125.2353,
    },
    links_count: 10,
    objectID: "6011",
    uniqueID: "8375783c-1e7b-4a2c-8eb9-b8531648d44b",
  },
  {
    name: "Kushiro Airport",
    city: "Kushiro",
    country: "Japan",
    iata_code: "KUH",
    _geoloc: {
      lat: 43.041,
      lng: 144.193,
    },
    links_count: 10,
    objectID: "5994",
    uniqueID: "b4cd2826-0841-4631-99f9-03c7af19db93",
  },
  {
    name: "Sayun International Airport",
    city: "Sayun Intl",
    country: "Yemen",
    iata_code: "GXF",
    _geoloc: {
      lat: 15.966111,
      lng: 48.7883,
    },
    links_count: 10,
    objectID: "5954",
    uniqueID: "478f7d6c-4e6a-43a8-8fff-78921c628b46",
  },
  {
    name: "Norsup Airport",
    city: "Norsup",
    country: "Vanuatu",
    iata_code: "NUS",
    _geoloc: {
      lat: -16.0797,
      lng: 167.401,
    },
    links_count: 10,
    objectID: "5905",
    uniqueID: "f21de334-308f-4b40-b2fa-b7ba16410e71",
  },
  {
    name: "Tri State Milton J Ferguson Field",
    city: "Huntington",
    country: "United States",
    iata_code: "HTS",
    _geoloc: {
      lat: 38.3667,
      lng: -82.558,
    },
    links_count: 10,
    objectID: "5742",
    uniqueID: "559a3d07-61bc-40f7-9944-03d3248b9b07",
  },
  {
    name: "Dodge City Regional Airport",
    city: "Dodge City",
    country: "United States",
    iata_code: "DDC",
    _geoloc: {
      lat: 37.7634,
      lng: -99.9656,
    },
    links_count: 10,
    objectID: "5733",
    uniqueID: "b229828f-e6f2-4dfd-af04-6209c96f2425",
  },
  {
    name: "Port Sudan New International Airport",
    city: "Port Sudan",
    country: "Sudan",
    iata_code: "PZU",
    _geoloc: {
      lat: 19.4336,
      lng: 37.2341,
    },
    links_count: 10,
    objectID: "5702",
    uniqueID: "b008a688-9c98-4c48-9492-1f21edf8a247",
  },
  {
    name: "Malindi Airport",
    city: "Malindi",
    country: "Kenya",
    iata_code: "MYD",
    _geoloc: {
      lat: -3.22931,
      lng: 40.1017,
    },
    links_count: 10,
    objectID: "5694",
    uniqueID: "51f78c27-d8be-4000-b83f-672fd9d84bd6",
  },
  {
    name: "Heringsdorf Airport",
    city: "Heringsdorf",
    country: "Germany",
    iata_code: "HDF",
    _geoloc: {
      lat: 53.878706,
      lng: 14.152347,
    },
    links_count: 10,
    objectID: "5557",
    uniqueID: "8c68da84-d8bb-4ac5-a4c2-f545833681a0",
  },
  {
    name: "The Pas Airport",
    city: "The Pas",
    country: "Canada",
    iata_code: "YQD",
    _geoloc: {
      lat: 53.9714,
      lng: -101.091,
    },
    links_count: 10,
    objectID: "5524",
    uniqueID: "de6a9adb-8d91-436d-bbd4-a83e4d2738a4",
  },
  {
    name: "Igloolik Airport",
    city: "Igloolik",
    country: "Canada",
    iata_code: "YGT",
    _geoloc: {
      lat: 69.3647,
      lng: -81.8161,
    },
    links_count: 10,
    objectID: "5495",
    uniqueID: "48803b6b-5e18-4de2-bfeb-8e47f06f7c0a",
  },
  {
    name: "Momote Airport",
    city: "Momote",
    country: "Papua New Guinea",
    iata_code: "MAS",
    _geoloc: {
      lat: -2.06189,
      lng: 147.424,
    },
    links_count: 10,
    objectID: "5430",
    uniqueID: "5a2f8a93-58f0-4bdd-8124-408748b5eec2",
  },
  {
    name: "Girua Airport",
    city: "Girua",
    country: "Papua New Guinea",
    iata_code: "PNP",
    _geoloc: {
      lat: -8.80454,
      lng: 148.309,
    },
    links_count: 10,
    objectID: "5423",
    uniqueID: "e2633192-75a9-4aa6-966f-cba8d2a7946f",
  },
  {
    name: "Stornoway",
    city: "Stornoway",
    country: "United Kingdom",
    iata_code: "SYY",
    _geoloc: {
      lat: 58.215556,
      lng: -6.331111,
    },
    links_count: 10,
    objectID: "541",
    uniqueID: "1091c8b2-ddea-4451-a572-67806b984271",
  },
  {
    name: "Sumburgh",
    city: "Sumburgh",
    country: "United Kingdom",
    iata_code: "LSI",
    _geoloc: {
      lat: 59.878889,
      lng: -1.295556,
    },
    links_count: 10,
    objectID: "530",
    uniqueID: "33273939-187f-4091-9504-074db4b3123f",
  },
  {
    name: "Humberside",
    city: "Humberside",
    country: "United Kingdom",
    iata_code: "HUY",
    _geoloc: {
      lat: 53.574444,
      lng: -0.350833,
    },
    links_count: 10,
    objectID: "515",
    uniqueID: "46e90744-91df-4068-91bb-f6b52c09a269",
  },
  {
    name: "City of Derry",
    city: "Londonderry",
    country: "United Kingdom",
    iata_code: "LDY",
    _geoloc: {
      lat: 55.042778,
      lng: -7.161111,
    },
    links_count: 10,
    objectID: "468",
    uniqueID: "a1fe69ae-e553-4673-971e-ee2027de1c12",
  },
  {
    name: "Vaasa",
    city: "Vaasa",
    country: "Finland",
    iata_code: "VAA",
    _geoloc: {
      lat: 63.05065,
      lng: 21.762175,
    },
    links_count: 10,
    objectID: "462",
    uniqueID: "dde0dcf3-c4eb-4f8d-a95b-b182409db98e",
  },
  {
    name: "Noyabrsk",
    city: "Noyabrsk",
    country: "Russia",
    iata_code: "NOJ",
    _geoloc: {
      lat: 63.110079,
      lng: 75.162243,
    },
    links_count: 10,
    objectID: "4365",
    uniqueID: "98838dd5-3eea-4c0c-9668-d38da5c9a0d0",
  },
  {
    name: "Central",
    city: "Saratov",
    country: "Russia",
    iata_code: "RTW",
    _geoloc: {
      lat: 51.334366,
      lng: 46.022952,
    },
    links_count: 10,
    objectID: "4363",
    uniqueID: "dce3332d-a093-4dd9-b669-b4af1e73217a",
  },
  {
    name: "Liberal Muni",
    city: "Liberal",
    country: "United States",
    iata_code: "LBL",
    _geoloc: {
      lat: 37.044222,
      lng: -100.95986,
    },
    links_count: 10,
    objectID: "4341",
    uniqueID: "51ffe154-7e40-4cbe-865f-8e34565e0ab8",
  },
  {
    name: "Mehamn",
    city: "Mehamn",
    country: "Norway",
    iata_code: "MEH",
    _geoloc: {
      lat: 71.029722,
      lng: 27.826667,
    },
    links_count: 10,
    objectID: "4327",
    uniqueID: "349f8828-16a4-471f-9afa-9778eee7c7bd",
  },
  {
    name: "University Park Airport",
    city: "State College Pennsylvania",
    country: "United States",
    iata_code: "SCE",
    _geoloc: {
      lat: 40.849278,
      lng: -77.848694,
    },
    links_count: 10,
    objectID: "4318",
    uniqueID: "bf0b9815-6a86-4915-8503-a63d2b22d83e",
  },
  {
    name: "Tomsk Bogashevo Airport",
    city: "Tomsk",
    country: "Russia",
    iata_code: "TOF",
    _geoloc: {
      lat: 56.380278,
      lng: 85.208333,
    },
    links_count: 10,
    objectID: "4297",
    uniqueID: "1dcf235c-5cf9-44e4-9d50-80c031cedeb5",
  },
  {
    name: "Ballina Byron Gateway",
    city: "Ballina Byron Bay",
    country: "Australia",
    iata_code: "BNK",
    _geoloc: {
      lat: -28.833889,
      lng: 153.5625,
    },
    links_count: 10,
    objectID: "4291",
    uniqueID: "512f99d3-370d-45c6-9a10-62ffd4ae0df2",
  },
  {
    name: "Kulusuk",
    city: "Kulusuk",
    country: "Greenland",
    iata_code: "KUS",
    _geoloc: {
      lat: 65.566667,
      lng: -37.1166667,
    },
    links_count: 10,
    objectID: "4248",
    uniqueID: "f157909c-09c3-4995-9e9f-f1600c45e5f4",
  },
  {
    name: "Tanna island",
    city: "Tanna",
    country: "Vanuatu",
    iata_code: "TAH",
    _geoloc: {
      lat: -19.455198,
      lng: 169.22394,
    },
    links_count: 10,
    objectID: "4242",
    uniqueID: "31f9fef3-9acd-414e-a6c5-e5f6af6f53bf",
  },
  {
    name: "Duong Dong Airport",
    city: "Phu Quoc",
    country: "Vietnam",
    iata_code: "PQC",
    _geoloc: {
      lat: 10.227025,
      lng: 103.967169,
    },
    links_count: 10,
    objectID: "4168",
    uniqueID: "77374fe8-eb79-43dd-b136-d56dd1d26011",
  },
  {
    name: "Dalat",
    city: "Dalat",
    country: "Vietnam",
    iata_code: "DLI",
    _geoloc: {
      lat: 11.75,
      lng: 108.367,
    },
    links_count: 10,
    objectID: "4153",
    uniqueID: "71d55a12-f222-4c61-a39f-2eb289cd25da",
  },
  {
    name: "Mbs Intl",
    city: "Saginaw",
    country: "United States",
    iata_code: "MBS",
    _geoloc: {
      lat: 43.532913,
      lng: -84.079647,
    },
    links_count: 10,
    objectID: "4128",
    uniqueID: "40005330-54ca-44f6-9a9b-8d82da1228d0",
  },
  {
    name: "Glacier Park Intl",
    city: "Kalispell",
    country: "United States",
    iata_code: "FCA",
    _geoloc: {
      lat: 48.310472,
      lng: -114.256,
    },
    links_count: 10,
    objectID: "4127",
    uniqueID: "da3ae07d-bf23-482a-8827-c3f8846e7e29",
  },
  {
    name: "Central Wisconsin",
    city: "Wassau",
    country: "United States",
    iata_code: "CWA",
    _geoloc: {
      lat: 44.772726,
      lng: -89.646635,
    },
    links_count: 10,
    objectID: "4045",
    uniqueID: "203d73f6-789f-44c4-9308-1ddc388a08c5",
  },
  {
    name: "Toledo",
    city: "Toledo",
    country: "United States",
    iata_code: "TOL",
    _geoloc: {
      lat: 41.586806,
      lng: -83.807833,
    },
    links_count: 10,
    objectID: "4040",
    uniqueID: "6d8946b3-93af-4d26-9dce-e5d5b8f7496e",
  },
  {
    name: "Chicago Rockford International Airport ",
    city: "Rockford",
    country: "United States",
    iata_code: "RFD",
    _geoloc: {
      lat: 42.1953611,
      lng: -89.0972222,
    },
    links_count: 10,
    objectID: "4028",
    uniqueID: "257c1558-b6bb-4058-82fb-e23bfd98248a",
  },
  {
    name: "Cherry Capital Airport",
    city: "Traverse City",
    country: "United States",
    iata_code: "TVC",
    _geoloc: {
      lat: 44.741445,
      lng: -85.582235,
    },
    links_count: 10,
    objectID: "4023",
    uniqueID: "4fd34b4f-f42f-49cb-960e-7d104d921c3e",
  },
  {
    name: "Ilulissat",
    city: "Ilulissat",
    country: "Greenland",
    iata_code: "JAV",
    _geoloc: {
      lat: 69.23444,
      lng: -51.05111,
    },
    links_count: 10,
    objectID: "3995",
    uniqueID: "9828de91-9db5-4961-a259-d0b73b8aa76a",
  },
  {
    name: "Daytona Beach Intl",
    city: "Daytona Beach",
    country: "United States",
    iata_code: "DAB",
    _geoloc: {
      lat: 29.179917,
      lng: -81.058056,
    },
    links_count: 10,
    objectID: "3950",
    uniqueID: "d40d9746-4689-4849-aeec-791ba446fece",
  },
  {
    name: "Cold Bay",
    city: "Cold Bay",
    country: "United States",
    iata_code: "CDB",
    _geoloc: {
      lat: 55.206061,
      lng: -162.725436,
    },
    links_count: 10,
    objectID: "3854",
    uniqueID: "0b191fc9-aace-4dbd-bbdb-24437a818b94",
  },
  {
    name: "Corpus Christi Intl",
    city: "Corpus Christi",
    country: "United States",
    iata_code: "CRP",
    _geoloc: {
      lat: 27.770361,
      lng: -97.501222,
    },
    links_count: 10,
    objectID: "3744",
    uniqueID: "ce4ca73c-53ab-457e-8493-4e231a789e0e",
  },
  {
    name: "Craven Co Rgnl",
    city: "New Bern",
    country: "United States",
    iata_code: "EWN",
    _geoloc: {
      lat: 35.072972,
      lng: -77.042944,
    },
    links_count: 10,
    objectID: "3730",
    uniqueID: "a919de04-349b-456e-9d22-e2be0dc65112",
  },
  {
    name: "Durango La Plata Co",
    city: "Durango",
    country: "United States",
    iata_code: "DRO",
    _geoloc: {
      lat: 37.151516,
      lng: -107.75377,
    },
    links_count: 10,
    objectID: "3713",
    uniqueID: "1a6912b0-a679-4cfe-b6e4-c6c0cf204e18",
  },
  {
    name: "Kugluktuk",
    city: "Coppermine",
    country: "Canada",
    iata_code: "YCO",
    _geoloc: {
      lat: 67.816667,
      lng: -115.143889,
    },
    links_count: 10,
    objectID: "37",
    uniqueID: "cac8e70c-438e-42bc-b1e9-b6baf7b6d0ae",
  },
  {
    name: "Melbourne Intl",
    city: "Melbourne",
    country: "United States",
    iata_code: "MLB",
    _geoloc: {
      lat: 28.102753,
      lng: -80.645258,
    },
    links_count: 10,
    objectID: "3671",
    uniqueID: "df2efea7-f574-4b72-913f-dee30116bb8a",
  },
  {
    name: "Niagara Falls Intl",
    city: "Niagara Falls",
    country: "United States",
    iata_code: "IAG",
    _geoloc: {
      lat: 43.107333,
      lng: -78.946194,
    },
    links_count: 10,
    objectID: "3630",
    uniqueID: "44b03531-cdde-4da5-999a-7c0ac13f976c",
  },
  {
    name: "Minot Intl",
    city: "Minot",
    country: "United States",
    iata_code: "MOT",
    _geoloc: {
      lat: 48.259378,
      lng: -101.280333,
    },
    links_count: 10,
    objectID: "3498",
    uniqueID: "be3c4aca-ebee-424f-bef2-90d677130416",
  },
  {
    name: "Grand Forks Intl",
    city: "Grand Forks",
    country: "United States",
    iata_code: "GFK",
    _geoloc: {
      lat: 47.949256,
      lng: -97.176111,
    },
    links_count: 10,
    objectID: "3442",
    uniqueID: "c649b9e7-e87c-4aef-b63c-78e59e7f488b",
  },
  {
    name: "Fort Smith Rgnl",
    city: "Fort Smith",
    country: "United States",
    iata_code: "FSM",
    _geoloc: {
      lat: 35.336583,
      lng: -94.367444,
    },
    links_count: 10,
    objectID: "3437",
    uniqueID: "0c06fbf0-7871-48b8-b125-cececf5a4be2",
  },
  {
    name: "Qingshan",
    city: "Xichang",
    country: "China",
    iata_code: "XIC",
    _geoloc: {
      lat: 27.989083,
      lng: 102.184361,
    },
    links_count: 10,
    objectID: "3396",
    uniqueID: "e6cf1545-bdec-4dbb-8945-2cb9369ea5cd",
  },
  {
    name: "Pyongyang Intl",
    city: "Pyongyang",
    country: "Korea",
    iata_code: "FNJ",
    _geoloc: {
      lat: 39.224061,
      lng: 125.67015,
    },
    links_count: 10,
    objectID: "3377",
    uniqueID: "77ec693b-810f-472b-aa30-81f4c0c5440b",
  },
  {
    name: "Kalgoorlie Boulder",
    city: "Kalgoorlie",
    country: "Australia",
    iata_code: "KGI",
    _geoloc: {
      lat: -30.789444,
      lng: 121.461667,
    },
    links_count: 10,
    objectID: "3346",
    uniqueID: "e38a1ebe-2db7-438c-a1e2-e6e9d4339f25",
  },
  {
    name: "Rockhampton",
    city: "Rockhampton",
    country: "Australia",
    iata_code: "ROK",
    _geoloc: {
      lat: -23.381944,
      lng: 150.475278,
    },
    links_count: 10,
    objectID: "3329",
    uniqueID: "24989260-d325-4200-92e6-8ea28957cdd3",
  },
  {
    name: "Sunshine Coast",
    city: "Maroochydore",
    country: "Australia",
    iata_code: "MCY",
    _geoloc: {
      lat: -26.603333,
      lng: 153.091111,
    },
    links_count: 10,
    objectID: "3325",
    uniqueID: "2bb73a9c-e04f-4838-9fe5-37fcca18ca5c",
  },
  {
    name: "Kuantan",
    city: "Kuantan",
    country: "Malaysia",
    iata_code: "KUA",
    _geoloc: {
      lat: 3.775389,
      lng: 103.209056,
    },
    links_count: 10,
    objectID: "3299",
    uniqueID: "6c0bfb9e-4ae0-4e03-aa02-7cc589d5e815",
  },
  {
    name: "Sultan Abdul Halim",
    city: "Alor Setar",
    country: "Malaysia",
    iata_code: "AOR",
    _geoloc: {
      lat: 6.189667,
      lng: 100.398183,
    },
    links_count: 10,
    objectID: "3296",
    uniqueID: "6f961296-7f76-47ec-83a7-dc880c1e1f3b",
  },
  {
    name: "Sultan Iskandarmuda",
    city: "Banda Aceh",
    country: "Indonesia",
    iata_code: "BTJ",
    _geoloc: {
      lat: 5.523522,
      lng: 95.420372,
    },
    links_count: 10,
    objectID: "3294",
    uniqueID: "015f2039-df68-4fdc-99ba-df7638509b62",
  },
  {
    name: "Fatmawati Soekarno",
    city: "Bengkulu",
    country: "Indonesia",
    iata_code: "BKS",
    _geoloc: {
      lat: -3.8637,
      lng: 102.339036,
    },
    links_count: 10,
    objectID: "3288",
    uniqueID: "9e218d94-0440-4d2b-a77f-0b38e04d6f7d",
  },
  {
    name: "Paro",
    city: "Thimphu",
    country: "Bhutan",
    iata_code: "PBH",
    _geoloc: {
      lat: 27.403192,
      lng: 89.424606,
    },
    links_count: 10,
    objectID: "3155",
    uniqueID: "08437d16-2690-410e-aa55-cb62aca70513",
  },
  {
    name: "Pakse",
    city: "Pakse",
    country: "Laos",
    iata_code: "PKZ",
    _geoloc: {
      lat: 15.132053,
      lng: 105.781417,
    },
    links_count: 10,
    objectID: "3116",
    uniqueID: "c3b8da1f-993d-4ca6-84a6-2875d792a144",
  },
  {
    name: "Dehradun",
    city: "Dehra Dun",
    country: "India",
    iata_code: "DED",
    _geoloc: {
      lat: 30.189689,
      lng: 78.180256,
    },
    links_count: 10,
    objectID: "3092",
    uniqueID: "0c8265f4-c104-40a1-8df0-2d8ebf123dfe",
  },
  {
    name: "Osmany Intl",
    city: "Sylhet Osmani",
    country: "Bangladesh",
    iata_code: "ZYL",
    _geoloc: {
      lat: 24.963242,
      lng: 91.866783,
    },
    links_count: 10,
    objectID: "3074",
    uniqueID: "50a5cb98-d1aa-4396-8e38-f94ab2c1b9bf",
  },
  {
    name: "Vadodara",
    city: "Baroda",
    country: "India",
    iata_code: "BDQ",
    _geoloc: {
      lat: 22.336164,
      lng: 73.226289,
    },
    links_count: 10,
    objectID: "3001",
    uniqueID: "ba6d4621-bc28-4122-9b14-1fd2a2714783",
  },
  {
    name: "Aurangabad",
    city: "Aurangabad",
    country: "India",
    iata_code: "IXU",
    _geoloc: {
      lat: 19.862728,
      lng: 75.398114,
    },
    links_count: 10,
    objectID: "2996",
    uniqueID: "99848f28-15b0-4360-894d-c227f43f5aff",
  },
  {
    name: "Ignatyevo",
    city: "Blagoveschensk",
    country: "Russia",
    iata_code: "BQS",
    _geoloc: {
      lat: 50.425394,
      lng: 127.412478,
    },
    links_count: 10,
    objectID: "2926",
    uniqueID: "b6dd0cd3-736e-426b-a423-06d6c70ff95f",
  },
  {
    name: "Barquisimeto Intl",
    city: "Barquisimeto",
    country: "Venezuela",
    iata_code: "BRM",
    _geoloc: {
      lat: 10.042747,
      lng: -69.358619,
    },
    links_count: 10,
    objectID: "2824",
    uniqueID: "70d86bbc-317b-48df-804b-b67a49bf7d94",
  },
  {
    name: "Palonegro",
    city: "Bucaramanga",
    country: "Colombia",
    iata_code: "BGA",
    _geoloc: {
      lat: 7.1265,
      lng: -73.184778,
    },
    links_count: 10,
    objectID: "2708",
    uniqueID: "06744eef-7d05-4a4d-bd87-96f25c6d7c21",
  },
  {
    name: "Tefe",
    city: "Tefe",
    country: "Brazil",
    iata_code: "TFF",
    _geoloc: {
      lat: -3.382944,
      lng: -64.724056,
    },
    links_count: 10,
    objectID: "2624",
    uniqueID: "b7d6f1bb-6e17-4bc6-9ecd-51ecc0bd9d5a",
  },
  {
    name: "PlÃ¡cido de Castro",
    city: "Rio Branco",
    country: "Brazil",
    iata_code: "RBR",
    _geoloc: {
      lat: -9.583,
      lng: -67.4836,
    },
    links_count: 10,
    objectID: "2609",
    uniqueID: "b32ecf60-d6b8-415c-8015-cd420fbc0cc7",
  },
  {
    name: "Senador Nilo Coelho",
    city: "Petrolina",
    country: "Brazil",
    iata_code: "PNZ",
    _geoloc: {
      lat: -9.362411,
      lng: -40.569097,
    },
    links_count: 10,
    objectID: "2604",
    uniqueID: "4a8db9e4-3e61-43ec-8850-e8cf6988854c",
  },
  {
    name: "Lauro Kurtz",
    city: "Passo Fundo",
    country: "Brazil",
    iata_code: "PFB",
    _geoloc: {
      lat: -28.243989,
      lng: -52.326558,
    },
    links_count: 10,
    objectID: "2602",
    uniqueID: "169c31ef-b0be-4faf-ae40-64132b59ea61",
  },
  {
    name: "Lauro Carneiro De Loyola",
    city: "Joinville",
    country: "Brazil",
    iata_code: "JOI",
    _geoloc: {
      lat: -26.224453,
      lng: -48.797364,
    },
    links_count: 10,
    objectID: "2576",
    uniqueID: "65fa8723-ba7d-474c-9193-61299eee36fa",
  },
  {
    name: "Chapeco",
    city: "Chapeco",
    country: "Brazil",
    iata_code: "XAP",
    _geoloc: {
      lat: -27.134219,
      lng: -52.656553,
    },
    links_count: 10,
    objectID: "2539",
    uniqueID: "240365a4-8f8a-402c-9a1b-508bf3a84658",
  },
  {
    name: "Ushuaia Malvinas Argentinas",
    city: "Ushuaia",
    country: "Argentina",
    iata_code: "USH",
    _geoloc: {
      lat: -54.843278,
      lng: -68.29575,
    },
    links_count: 10,
    objectID: "2497",
    uniqueID: "c060fd36-9f88-4471-b2b4-6a4fa14c1a8b",
  },
  {
    name: "Daniel Z Romualdez",
    city: "Tacloban",
    country: "Philippines",
    iata_code: "TAC",
    _geoloc: {
      lat: 11.227628,
      lng: 125.027758,
    },
    links_count: 10,
    objectID: "2422",
    uniqueID: "5adacf19-3992-4231-b99d-7fa4ac76457d",
  },
  {
    name: "Miyako",
    city: "Miyako",
    country: "Japan",
    iata_code: "MMY",
    _geoloc: {
      lat: 24.782833,
      lng: 125.295111,
    },
    links_count: 10,
    objectID: "2390",
    uniqueID: "00f60dee-4a78-41ba-8760-de549d5dafec",
  },
  {
    name: "Aomori",
    city: "Aomori",
    country: "Japan",
    iata_code: "AOJ",
    _geoloc: {
      lat: 40.734722,
      lng: 140.690833,
    },
    links_count: 10,
    objectID: "2340",
    uniqueID: "133baf2d-cc73-44f6-80c7-237e6403a0bb",
  },
  {
    name: "Hyakuri",
    city: "Ibaraki",
    country: "Japan",
    iata_code: "IBR",
    _geoloc: {
      lat: 36.181083,
      lng: 140.415444,
    },
    links_count: 10,
    objectID: "2281",
    uniqueID: "fb0453e8-6623-426c-af93-fe77e3d1696f",
  },
  {
    name: "Magong",
    city: "Makung",
    country: "Taiwan",
    iata_code: "MZG",
    _geoloc: {
      lat: 23.568669,
      lng: 119.628311,
    },
    links_count: 10,
    objectID: "2272",
    uniqueID: "e8c771e9-3497-4199-9224-943ef708694a",
  },
  {
    name: "Shang Yi",
    city: "Kinmen",
    country: "Taiwan",
    iata_code: "KNH",
    _geoloc: {
      lat: 24.427892,
      lng: 118.359197,
    },
    links_count: 10,
    objectID: "2259",
    uniqueID: "2b8a950a-a702-4100-a698-b1c5c8f9b0d4",
  },
  {
    name: "Bushehr",
    city: "Bushehr",
    country: "Iran",
    iata_code: "BUZ",
    _geoloc: {
      lat: 28.944811,
      lng: 50.834637,
    },
    links_count: 10,
    objectID: "2106",
    uniqueID: "0ed09f05-3e41-482b-b66d-9dbf1227d347",
  },
  {
    name: "Hail",
    city: "Hail",
    country: "Saudi Arabia",
    iata_code: "HAS",
    _geoloc: {
      lat: 27.437917,
      lng: 41.686292,
    },
    links_count: 10,
    objectID: "2069",
    uniqueID: "8475b0c6-96cf-4ac5-a6ac-f9926d8200fa",
  },
  {
    name: "Kandahar",
    city: "Kandahar",
    country: "Afghanistan",
    iata_code: "KDH",
    _geoloc: {
      lat: 31.505756,
      lng: 65.847822,
    },
    links_count: 10,
    objectID: "2051",
    uniqueID: "5e65cc53-3496-4061-ba02-39b0622bb4c1",
  },
  {
    name: "Palmerston North",
    city: "Palmerston North",
    country: "New Zealand",
    iata_code: "PMR",
    _geoloc: {
      lat: -40.320556,
      lng: 175.616944,
    },
    links_count: 10,
    objectID: "2028",
    uniqueID: "9e08d56d-67f5-48cf-872d-b4d5de182d5c",
  },
  {
    name: "Dunedin",
    city: "Dunedin",
    country: "New Zealand",
    iata_code: "DUD",
    _geoloc: {
      lat: -45.928055,
      lng: 170.198333,
    },
    links_count: 10,
    objectID: "2011",
    uniqueID: "13ff3707-265b-4fc6-95fb-09c611a74cbe",
  },
  {
    name: "Fua Amotu Intl",
    city: "Tongatapu",
    country: "Tonga",
    iata_code: "TBU",
    _geoloc: {
      lat: -21.241214,
      lng: -175.149644,
    },
    links_count: 10,
    objectID: "1963",
    uniqueID: "67b6a18c-538c-4f25-9ec5-4543ddf1d751",
  },
  {
    name: "Governors Harbour",
    city: "Governor's Harbor",
    country: "Bahamas",
    iata_code: "GHB",
    _geoloc: {
      lat: 25.284706,
      lng: -76.331011,
    },
    links_count: 10,
    objectID: "1944",
    uniqueID: "e115278f-f28d-481d-ba48-bbc610dece92",
  },
  {
    name: "Valle Del Fuerte Intl",
    city: "Los Mochis",
    country: "Mexico",
    iata_code: "LMM",
    _geoloc: {
      lat: 25.685194,
      lng: -109.080806,
    },
    links_count: 10,
    objectID: "1814",
    uniqueID: "f889098d-e3f2-4319-8b79-3294f6978503",
  },
  {
    name: "Durango Intl",
    city: "Durango",
    country: "Mexico",
    iata_code: "DGO",
    _geoloc: {
      lat: 24.124194,
      lng: -104.528014,
    },
    links_count: 10,
    objectID: "1801",
    uniqueID: "9912e9e9-12a4-45f0-a6f5-1ede2b707d0a",
  },
  {
    name: "Reykjavik",
    city: "Reykjavik",
    country: "Iceland",
    iata_code: "RKV",
    _geoloc: {
      lat: 64.13,
      lng: -21.940556,
    },
    links_count: 10,
    objectID: "18",
    uniqueID: "72f567d0-d468-472c-941f-74dfb3ab3ee8",
  },
  {
    name: "Ciudad Obregon Intl",
    city: "Ciudad Obregon",
    country: "Mexico",
    iata_code: "CEN",
    _geoloc: {
      lat: 27.392639,
      lng: -109.833111,
    },
    links_count: 10,
    objectID: "1794",
    uniqueID: "e974f27c-9ab4-42c9-af92-c25fa0a57cf9",
  },
  {
    name: "Whitehorse Intl",
    city: "Whitehorse",
    country: "Canada",
    iata_code: "YXY",
    _geoloc: {
      lat: 60.709553,
      lng: -135.067269,
    },
    links_count: 10,
    objectID: "176",
    uniqueID: "60ec2344-30a9-4ef5-a776-2ace559cf6b4",
  },
  {
    name: "Terrace",
    city: "Terrace",
    country: "Canada",
    iata_code: "YXT",
    _geoloc: {
      lat: 54.468508,
      lng: -128.576219,
    },
    links_count: 10,
    objectID: "173",
    uniqueID: "7fee2b92-f385-4cda-bc16-b9ee2ad71d08",
  },
  {
    name: "Van",
    city: "Van",
    country: "Turkey",
    iata_code: "VAN",
    _geoloc: {
      lat: 38.468219,
      lng: 43.3323,
    },
    links_count: 10,
    objectID: "1728",
    uniqueID: "bd6158e1-118b-494a-b316-a3728b3d9813",
  },
  {
    name: "Erzurum",
    city: "Erzurum",
    country: "Turkey",
    iata_code: "ERZ",
    _geoloc: {
      lat: 39.956501,
      lng: 41.170166,
    },
    links_count: 10,
    objectID: "1724",
    uniqueID: "53d69beb-3ab4-4910-bc65-b0bce7cda8da",
  },
  {
    name: "Williams Lake",
    city: "Williams Lake",
    country: "Canada",
    iata_code: "YWL",
    _geoloc: {
      lat: 52.183056,
      lng: -122.054167,
    },
    links_count: 10,
    objectID: "162",
    uniqueID: "629376e8-ed05-43b6-aef2-3c903430737d",
  },
  {
    name: "Eilat",
    city: "Elat",
    country: "Israel",
    iata_code: "ETH",
    _geoloc: {
      lat: 29.561281,
      lng: 34.960081,
    },
    links_count: 10,
    objectID: "1594",
    uniqueID: "82270804-84de-4401-be12-58ed61cc62a7",
  },
  {
    name: "Karlovy Vary",
    city: "Karlovy Vary",
    country: "Czech Republic",
    iata_code: "KLV",
    _geoloc: {
      lat: 50.202978,
      lng: 12.914983,
    },
    links_count: 10,
    objectID: "1579",
    uniqueID: "0212070e-6cde-412c-a688-d750d6cbd131",
  },
  {
    name: "Perugia",
    city: "Perugia",
    country: "Italy",
    iata_code: "PEG",
    _geoloc: {
      lat: 43.095906,
      lng: 12.513222,
    },
    links_count: 10,
    objectID: "1567",
    uniqueID: "0837087b-dc40-405b-af94-67579dccb6e6",
  },
  {
    name: "Marina Di Campo",
    city: "Marina Di Campo",
    country: "Italy",
    iata_code: "EBA",
    _geoloc: {
      lat: 42.760277,
      lng: 10.239445,
    },
    links_count: 10,
    objectID: "1558",
    uniqueID: "74832409-770c-476e-9e8d-dc271dad89b7",
  },
  {
    name: "Levaldigi",
    city: "Cuneo",
    country: "Italy",
    iata_code: "CUF",
    _geoloc: {
      lat: 44.547019,
      lng: 7.623217,
    },
    links_count: 10,
    objectID: "1534",
    uniqueID: "e2b22be8-cc73-44a5-a3e4-dd21572e2942",
  },
  {
    name: "Rouyn Noranda",
    city: "Rouyn",
    country: "Canada",
    iata_code: "YUY",
    _geoloc: {
      lat: 48.206111,
      lng: -78.835556,
    },
    links_count: 10,
    objectID: "149",
    uniqueID: "9fdea898-734a-4b6a-a8d8-690fbb3b4133",
  },
  {
    name: "Sitia",
    city: "Sitia",
    country: "Greece",
    iata_code: "JSH",
    _geoloc: {
      lat: 35.216108,
      lng: 26.101325,
    },
    links_count: 10,
    objectID: "1480",
    uniqueID: "2d7d0d62-f1c8-4ef5-8a61-b7679f031c4c",
  },
  {
    name: "Limnos",
    city: "Limnos",
    country: "Greece",
    iata_code: "LXS",
    _geoloc: {
      lat: 39.917072,
      lng: 25.236308,
    },
    links_count: 10,
    objectID: "1465",
    uniqueID: "f67ba87b-f7d2-4516-82ad-bb43869e86d5",
  },
  {
    name: "Nea Anchialos",
    city: "Nea Anghialos",
    country: "Greece",
    iata_code: "VOL",
    _geoloc: {
      lat: 39.219619,
      lng: 22.794339,
    },
    links_count: 10,
    objectID: "1448",
    uniqueID: "4fdbb094-aec2-4d2b-90d5-e4ba26eaa438",
  },
  {
    name: "Val De Loire",
    city: "Tours",
    country: "France",
    iata_code: "TUF",
    _geoloc: {
      lat: 47.432222,
      lng: 0.727606,
    },
    links_count: 10,
    objectID: "1376",
    uniqueID: "77807b25-8c08-427f-9882-fafbac4a7e5c",
  },
  {
    name: "Rivesaltes",
    city: "Perpignan",
    country: "France",
    iata_code: "PGF",
    _geoloc: {
      lat: 42.740442,
      lng: 2.870667,
    },
    links_count: 10,
    objectID: "1356",
    uniqueID: "7c0e4a2d-3f7f-4f1b-a6a0-ce72e3c3ed9f",
  },
  {
    name: "Boutheon",
    city: "St-Etienne",
    country: "France",
    iata_code: "EBU",
    _geoloc: {
      lat: 45.540554,
      lng: 4.296389,
    },
    links_count: 10,
    objectID: "1350",
    uniqueID: "97da111c-230f-45ae-997e-828bdb48c1d9",
  },
  {
    name: "Metz Nancy Lorraine",
    city: "Metz",
    country: "France",
    iata_code: "ETZ",
    _geoloc: {
      lat: 48.982142,
      lng: 6.251319,
    },
    links_count: 10,
    objectID: "1320",
    uniqueID: "4cec3251-1163-428c-9b02-032e099a3848",
  },
  {
    name: "Lourdes",
    city: "Tarbes",
    country: "France",
    iata_code: "LDE",
    _geoloc: {
      lat: 43.178675,
      lng: -0.006439,
    },
    links_count: 10,
    objectID: "1276",
    uniqueID: "83421aa3-e5f8-40b9-b597-4eb1a472338b",
  },
  {
    name: "Pau Pyrenees",
    city: "Pau",
    country: "France",
    iata_code: "PUF",
    _geoloc: {
      lat: 43.38,
      lng: -0.418611,
    },
    links_count: 10,
    objectID: "1274",
    uniqueID: "e9c2088c-0ab3-45c0-95a8-85f8b884cda5",
  },
  {
    name: "Melilla",
    city: "Melilla",
    country: "Spain",
    iata_code: "MLN",
    _geoloc: {
      lat: 35.279817,
      lng: -2.956256,
    },
    links_count: 10,
    objectID: "1058",
    uniqueID: "ad725bfa-e26b-40dc-9e28-42a3ef222a5a",
  },
  {
    name: "Chileka Intl",
    city: "Blantyre",
    country: "Malawi",
    iata_code: "BLZ",
    _geoloc: {
      lat: -15.679053,
      lng: 34.974014,
    },
    links_count: 10,
    objectID: "1013",
    uniqueID: "ef83cb5f-f9e4-444a-867b-4f14db287ceb",
  },
  {
    name: "Goroka",
    city: "Goroka",
    country: "Papua New Guinea",
    iata_code: "GKA",
    _geoloc: {
      lat: -6.081689,
      lng: 145.391881,
    },
    links_count: 10,
    objectID: "1",
    uniqueID: "1ceab699-8c6b-42fa-9c23-3c3f8a696469",
  },
  {
    name: "Quinhagak Airport",
    city: "Quinhagak",
    country: "United States",
    iata_code: "KWN",
    _geoloc: {
      lat: 59.755,
      lng: -161.845278,
    },
    links_count: 9,
    objectID: "7206",
    uniqueID: "3de87964-6908-4789-ba38-05d41cefe3a2",
  },
  {
    name: "Wales Airport",
    city: "Wales",
    country: "United States",
    iata_code: "WAA",
    _geoloc: {
      lat: 65.6225,
      lng: -168.095,
    },
    links_count: 9,
    objectID: "7187",
    uniqueID: "fae12a84-926a-41ee-8da5-0a978258c360",
  },
  {
    name: "Elim Airport",
    city: "Elim",
    country: "United States",
    iata_code: "ELI",
    _geoloc: {
      lat: 64.615,
      lng: -162.270556,
    },
    links_count: 9,
    objectID: "7184",
    uniqueID: "2e36d30b-107a-4f5e-be19-942988e7bf20",
  },
  {
    name: "Tanana Airport",
    city: "Tanana",
    country: "United States",
    iata_code: "TAL",
    _geoloc: {
      lat: 65.179556,
      lng: -152.075833,
    },
    links_count: 9,
    objectID: "7113",
    uniqueID: "f94f2ca9-73d2-4081-8b08-9397e1a78f5b",
  },
  {
    name: "Gjoa Haven",
    city: "Gjoa Haven",
    country: "Canada",
    iata_code: "YHK",
    _geoloc: {
      lat: 68.635556,
      lng: -95.849722,
    },
    links_count: 9,
    objectID: "69",
    uniqueID: "6f6db447-0475-4cf1-abc4-1568424fdda9",
  },
  {
    name: "Ruby Airport",
    city: "Ruby",
    country: "United States",
    iata_code: "RBY",
    _geoloc: {
      lat: 64.7272,
      lng: -155.47,
    },
    links_count: 9,
    objectID: "6722",
    uniqueID: "8b7f2d5d-aa95-4e34-bebd-f20f833989b8",
  },
  {
    name: "Stokka",
    city: "Sandnessjoen",
    country: "Norway",
    iata_code: "SSJ",
    _geoloc: {
      lat: 65.956828,
      lng: 12.468944,
    },
    links_count: 9,
    objectID: "662",
    uniqueID: "9bf22ae9-09fb-47ee-933f-2d2caec91d7c",
  },
  {
    name: "Kjaerstad",
    city: "Mosjoen",
    country: "Norway",
    iata_code: "MJF",
    _geoloc: {
      lat: 65.783997,
      lng: 13.214914,
    },
    links_count: 9,
    objectID: "652",
    uniqueID: "fc2b463b-c893-4039-b2fc-b6dbe3ef6e23",
  },
  {
    name: "Floro",
    city: "Floro",
    country: "Norway",
    iata_code: "FRO",
    _geoloc: {
      lat: 61.583611,
      lng: 5.024722,
    },
    links_count: 9,
    objectID: "643",
    uniqueID: "da636f27-152b-482a-a317-d61cb2bdd703",
  },
  {
    name: "Ganja Airport",
    city: "Ganja",
    country: "Azerbaijan",
    iata_code: "KVD",
    _geoloc: {
      lat: 40.7377,
      lng: 46.3176,
    },
    links_count: 9,
    objectID: "6089",
    uniqueID: "5256e5bc-ccd2-42ce-85c6-3fa8e9b723f4",
  },
  {
    name: "Flin Flon",
    city: "Flin Flon",
    country: "Canada",
    iata_code: "YFO",
    _geoloc: {
      lat: 54.678055,
      lng: -101.681667,
    },
    links_count: 9,
    objectID: "58",
    uniqueID: "86c8982a-d16b-4bf2-b428-5fbc61e138f3",
  },
  {
    name: "Gamal Abdel Nasser Airport",
    city: "Tobruk",
    country: "Libya",
    iata_code: "TOB",
    _geoloc: {
      lat: 31.861,
      lng: 23.907,
    },
    links_count: 9,
    objectID: "5697",
    uniqueID: "d88d7f20-f1f3-4853-ae7e-41d27bf8e21b",
  },
  {
    name: "Røssvoll Airport",
    city: "Mo i Rana",
    country: "Norway",
    iata_code: "MQN",
    _geoloc: {
      lat: 66.3639,
      lng: 14.3014,
    },
    links_count: 9,
    objectID: "5582",
    uniqueID: "7aaafc60-bf9b-4c5c-9fb2-77d229f9a1ff",
  },
  {
    name: "Big Trout Lake Airport",
    city: "Big Trout Lake",
    country: "Canada",
    iata_code: "YTL",
    _geoloc: {
      lat: 53.8178,
      lng: -89.8969,
    },
    links_count: 9,
    objectID: "5531",
    uniqueID: "ce00da42-8d21-4f94-a810-52bc29ea434f",
  },
  {
    name: "Kiunga Airport",
    city: "Kiunga",
    country: "Papua New Guinea",
    iata_code: "UNG",
    _geoloc: {
      lat: -6.12571,
      lng: 141.282,
    },
    links_count: 9,
    objectID: "5425",
    uniqueID: "a75b3dcb-1548-4472-8fff-456e7dcf0007",
  },
  {
    name: "Nusatupe Airport",
    city: "Gizo",
    country: "Solomon Islands",
    iata_code: "GZO",
    _geoloc: {
      lat: -8.09778,
      lng: 156.864,
    },
    links_count: 9,
    objectID: "5412",
    uniqueID: "1a4cdbb7-1047-47fb-84da-50d8aaf4e4c7",
  },
  {
    name: "Hodeidah Intl",
    city: "Hodeidah",
    country: "Yemen",
    iata_code: "HOD",
    _geoloc: {
      lat: 14.753,
      lng: 42.976336,
    },
    links_count: 9,
    objectID: "3976",
    uniqueID: "0d373446-4ef6-4bde-9c97-93a1ab62860a",
  },
  {
    name: "Helena Rgnl",
    city: "Helena",
    country: "United States",
    iata_code: "HLN",
    _geoloc: {
      lat: 46.606806,
      lng: -111.98275,
    },
    links_count: 9,
    objectID: "3504",
    uniqueID: "6c9accec-b448-4eef-8a2e-3d35b9b49279",
  },
  {
    name: "Bettles",
    city: "Bettles",
    country: "United States",
    iata_code: "BTT",
    _geoloc: {
      lat: 66.913944,
      lng: -151.529056,
    },
    links_count: 9,
    objectID: "3417",
    uniqueID: "e96a4d0d-a266-4364-a884-cd6b918ce5be",
  },
  {
    name: "Tachileik",
    city: "Tachilek",
    country: "Burma",
    iata_code: "THL",
    _geoloc: {
      lat: 20.483831,
      lng: 99.935353,
    },
    links_count: 9,
    objectID: "3237",
    uniqueID: "32b16207-7e32-419a-abc3-ad9065170107",
  },
  {
    name: "E T Joshua",
    city: "Kingstown",
    country: "Saint Vincent and the Grenadines",
    iata_code: "SVD",
    _geoloc: {
      lat: 13.144306,
      lng: -61.210861,
    },
    links_count: 9,
    objectID: "2907",
    uniqueID: "9ca3a75d-4706-4a8b-9963-4b21ae53d72b",
  },
  {
    name: "Crown Point",
    city: "Scarborough",
    country: "Trinidad and Tobago",
    iata_code: "TAB",
    _geoloc: {
      lat: 11.149658,
      lng: -60.832194,
    },
    links_count: 9,
    objectID: "2901",
    uniqueID: "9dd5861d-e2b0-478a-b6c0-08c2c22440f1",
  },
  {
    name: "Chacalluta",
    city: "Arica",
    country: "Chile",
    iata_code: "ARI",
    _geoloc: {
      lat: -18.348531,
      lng: -70.338742,
    },
    links_count: 9,
    objectID: "2641",
    uniqueID: "6020bf2e-3d3b-4cdf-adf6-61039295e26c",
  },
  {
    name: "Raiatea",
    city: "Raiatea Island",
    country: "French Polynesia",
    iata_code: "RFP",
    _geoloc: {
      lat: -16.722861,
      lng: -151.465856,
    },
    links_count: 9,
    objectID: "1995",
    uniqueID: "e78e4ee7-0bec-4bda-9d1d-b2f2346b2533",
  },
  {
    name: "La Managua",
    city: "Quepos",
    country: "Costa Rica",
    iata_code: "XQP",
    _geoloc: {
      lat: 9.443164,
      lng: -84.129772,
    },
    links_count: 9,
    objectID: "1889",
    uniqueID: "610a48df-a1f9-4ef7-80e8-b3c0fac79eec",
  },
  {
    name: "Sibiu",
    city: "Sibiu",
    country: "Romania",
    iata_code: "SBZ",
    _geoloc: {
      lat: 45.785597,
      lng: 24.091342,
    },
    links_count: 9,
    objectID: "1658",
    uniqueID: "fd84d97d-57f1-45aa-95db-52f0b4584ae8",
  },
  {
    name: "Vilankulo",
    city: "Vilankulu",
    country: "Mozambique",
    iata_code: "VNX",
    _geoloc: {
      lat: -22.018431,
      lng: 35.313297,
    },
    links_count: 8,
    objectID: "990",
    uniqueID: "86f0a019-4501-4801-b071-1fbca5675ca2",
  },
  {
    name: "Maroantsetra",
    city: "Maroantsetra",
    country: "Madagascar",
    iata_code: "WMN",
    _geoloc: {
      lat: -15.436666,
      lng: 49.688332,
    },
    links_count: 8,
    objectID: "932",
    uniqueID: "cac14b54-df48-4603-8aa7-953b629368bd",
  },
  {
    name: "Yading Daocheng",
    city: "Daocheng",
    country: "China",
    iata_code: "DCY",
    _geoloc: {
      lat: 29.3231,
      lng: 100.0533,
    },
    links_count: 8,
    objectID: "9310",
    uniqueID: "44985a80-582c-44dc-ac24-8b54b771150f",
  },
  {
    name: "Fascene",
    city: "Nosy-be",
    country: "Madagascar",
    iata_code: "NOS",
    _geoloc: {
      lat: -13.312067,
      lng: 48.314822,
    },
    links_count: 8,
    objectID: "930",
    uniqueID: "4b056329-6b20-4fc5-a48b-3f8f5f8326df",
  },
  {
    name: "Jiagedaqi Airport",
    city: "Jiagedaqi District",
    country: "China",
    iata_code: "JGD",
    _geoloc: {
      lat: 50.375,
      lng: 124.117,
    },
    links_count: 8,
    objectID: "8979",
    uniqueID: "62e33543-1db9-4ad9-aeb2-5f44c99e0fed",
  },
  {
    name: "Kasane",
    city: "Kasane",
    country: "Botswana",
    iata_code: "BBK",
    _geoloc: {
      lat: -17.832875,
      lng: 25.1624,
    },
    links_count: 8,
    objectID: "879",
    uniqueID: "6e86f6f3-a90d-47a5-99a9-55f3e87905c6",
  },
  {
    name: "Beloyarsky",
    city: "Beloyarsky",
    country: "Russia",
    iata_code: "EYK",
    _geoloc: {
      lat: 63.683056,
      lng: 66.683056,
    },
    links_count: 8,
    objectID: "8428",
    uniqueID: "889f9f6b-d586-4d37-a3d3-27785d0cabef",
  },
  {
    name: "Lanseria",
    city: "Johannesburg",
    country: "South Africa",
    iata_code: "HLA",
    _geoloc: {
      lat: -25.938514,
      lng: 27.926133,
    },
    links_count: 8,
    objectID: "820",
    uniqueID: "36c4d6ed-9677-4d5d-896c-aaa19882d0fc",
  },
  {
    name: "Bloemfontein Intl",
    city: "Bloemfontein",
    country: "South Africa",
    iata_code: "BFN",
    _geoloc: {
      lat: -29.092722,
      lng: 26.302444,
    },
    links_count: 8,
    objectID: "794",
    uniqueID: "8573505d-6286-4b3c-8421-29a7726884b5",
  },
  {
    name: "Carajas Airport",
    city: "Parauapebas",
    country: "Brazil",
    iata_code: "CKS",
    _geoloc: {
      lat: -6.11781,
      lng: -50.0035,
    },
    links_count: 8,
    objectID: "7532",
    uniqueID: "6f55ab03-c389-44ad-a91a-e31d0c5efbfd",
  },
  {
    name: "Araguaina Airport",
    city: "Araguaina",
    country: "Brazil",
    iata_code: "AUX",
    _geoloc: {
      lat: -7.228333,
      lng: -48.240833,
    },
    links_count: 8,
    objectID: "7376",
    uniqueID: "b223d1c9-2d48-4746-8c6d-933f3027b953",
  },
  {
    name: "Vilhelmina",
    city: "Vilhelmina",
    country: "Sweden",
    iata_code: "VHM",
    _geoloc: {
      lat: 64.579083,
      lng: 16.833575,
    },
    links_count: 8,
    objectID: "729",
    uniqueID: "55a1c1ac-2e92-4db7-978e-033344dadd41",
  },
  {
    name: "Postville Airport",
    city: "Postville",
    country: "Canada",
    iata_code: "YSO",
    _geoloc: {
      lat: 54.910278,
      lng: -59.785278,
    },
    links_count: 8,
    objectID: "7252",
    uniqueID: "1508f18a-0ef7-4581-8dd7-c5e5107b9c08",
  },
  {
    name: "St. Michael Airport",
    city: "St. Michael",
    country: "United States",
    iata_code: "SMK",
    _geoloc: {
      lat: 63.49,
      lng: -162.110278,
    },
    links_count: 8,
    objectID: "7191",
    uniqueID: "6edba810-cd2d-46c4-9bdd-a60a48435b66",
  },
  {
    name: "Lycksele",
    city: "Lycksele",
    country: "Sweden",
    iata_code: "LYC",
    _geoloc: {
      lat: 64.548322,
      lng: 18.716219,
    },
    links_count: 8,
    objectID: "719",
    uniqueID: "80ac6d05-2e31-4296-8f23-7f854b9e1a5a",
  },
  {
    name: "White Mountain Airport",
    city: "White Mountain",
    country: "United States",
    iata_code: "WMO",
    _geoloc: {
      lat: 64.689167,
      lng: -163.412778,
    },
    links_count: 8,
    objectID: "7188",
    uniqueID: "67cdbdef-4fc7-4232-a474-6cfe1e7ce640",
  },
  {
    name: "Golovin Airport",
    city: "Golovin",
    country: "United States",
    iata_code: "GLV",
    _geoloc: {
      lat: 64.550556,
      lng: -163.007222,
    },
    links_count: 8,
    objectID: "7185",
    uniqueID: "d9d0c71b-084f-4907-ac5e-a2e39acb4975",
  },
  {
    name: "Brevig Mission Airport",
    city: "Brevig Mission",
    country: "United States",
    iata_code: "KTS",
    _geoloc: {
      lat: 65.331389,
      lng: -166.465833,
    },
    links_count: 8,
    objectID: "7183",
    uniqueID: "201485ee-df74-4d95-9ad4-b3cf4e4ef698",
  },
  {
    name: "Robert Curtis Memorial Airport",
    city: "Noorvik",
    country: "United States",
    iata_code: "ORV",
    _geoloc: {
      lat: 66.8175,
      lng: -161.022222,
    },
    links_count: 8,
    objectID: "7181",
    uniqueID: "082c63b2-1512-4817-b4e6-452ff0b05a2d",
  },
  {
    name: "Kramfors Solleftea",
    city: "Kramfors",
    country: "Sweden",
    iata_code: "KRF",
    _geoloc: {
      lat: 63.048597,
      lng: 17.768856,
    },
    links_count: 8,
    objectID: "718",
    uniqueID: "6dc9af58-aaab-48e1-a996-48364a491849",
  },
  {
    name: "Bob Baker Memorial Airport",
    city: "Kiana",
    country: "United States",
    iata_code: "IAN",
    _geoloc: {
      lat: 66.975833,
      lng: -160.436667,
    },
    links_count: 8,
    objectID: "7179",
    uniqueID: "b8087577-88a0-47e9-b389-8fe667815d19",
  },
  {
    name: "Buckland Airport",
    city: "Buckland",
    country: "United States",
    iata_code: "BKC",
    _geoloc: {
      lat: 65.981667,
      lng: -161.149167,
    },
    links_count: 8,
    objectID: "7178",
    uniqueID: "31f2e0a5-ec33-46c9-9e81-308d64ebe47e",
  },
  {
    name: "Ambler Airport",
    city: "Ambler",
    country: "United States",
    iata_code: "ABL",
    _geoloc: {
      lat: 67.106389,
      lng: -157.8575,
    },
    links_count: 8,
    objectID: "7177",
    uniqueID: "84bc9aac-1753-449c-8dbb-f0f2746917a4",
  },
  {
    name: "Thorne Bay Seaplane Base",
    city: "Thorne Bay",
    country: "United States",
    iata_code: "KTB",
    _geoloc: {
      lat: 55.688056,
      lng: -132.536667,
    },
    links_count: 8,
    objectID: "7147",
    uniqueID: "cb4b5537-3685-4f7f-8d95-a9d10d89d211",
  },
  {
    name: "Toowoomba",
    city: "Toowoomba",
    country: "Australia",
    iata_code: "TWB",
    _geoloc: {
      lat: -27.542778,
      lng: 151.916389,
    },
    links_count: 8,
    objectID: "7128",
    uniqueID: "47d0f113-953f-4519-ac2e-fd1d9587d6ac",
  },
  {
    name: "Huslia Airport",
    city: "Huslia",
    country: "United States",
    iata_code: "HSL",
    _geoloc: {
      lat: 65.697778,
      lng: -156.351389,
    },
    links_count: 8,
    objectID: "7108",
    uniqueID: "0abb6c0e-378d-4868-9595-da94fc5421da",
  },
  {
    name: "St Hubert",
    city: "Montreal",
    country: "Canada",
    iata_code: "YHU",
    _geoloc: {
      lat: 45.5175,
      lng: -73.416944,
    },
    links_count: 8,
    objectID: "71",
    uniqueID: "3c047855-3939-4f60-a592-d962a28092f2",
  },
  {
    name: "Russian Mission Airport",
    city: "Russian Mission",
    country: "United States",
    iata_code: "RSH",
    _geoloc: {
      lat: 61.775,
      lng: -161.319444,
    },
    links_count: 8,
    objectID: "7098",
    uniqueID: "411a1e8b-7be7-40f7-960b-8e10e130740c",
  },
  {
    name: "Hilton Head",
    city: "Hilton Head",
    country: "United States",
    iata_code: "HHH",
    _geoloc: {
      lat: 32.216,
      lng: -80.752,
    },
    links_count: 8,
    objectID: "7002",
    uniqueID: "74eba55b-73bc-4782-ab50-347ea31aec55",
  },
  {
    name: "Hamilton",
    city: "Hamilton",
    country: "Canada",
    iata_code: "YHM",
    _geoloc: {
      lat: 43.173611,
      lng: -79.935,
    },
    links_count: 8,
    objectID: "70",
    uniqueID: "5419a0dc-3897-4c10-9398-256df6ec8471",
  },
  {
    name: "Branson LLC",
    city: "Branson",
    country: "United States",
    iata_code: "BKG",
    _geoloc: {
      lat: 36.531994,
      lng: -93.200556,
    },
    links_count: 8,
    objectID: "6962",
    uniqueID: "5ac06560-db8a-410e-978f-238d82046b65",
  },
  {
    name: "Choibalsan Airport",
    city: "Choibalsan",
    country: "Mongolia",
    iata_code: "COQ",
    _geoloc: {
      lat: 48.1357,
      lng: 114.646,
    },
    links_count: 8,
    objectID: "6795",
    uniqueID: "4458cf82-d642-403d-9bcf-084ff35d7f2b",
  },
  {
    name: "St Augustin Airport",
    city: "St-Augustin",
    country: "Canada",
    iata_code: "YIF",
    _geoloc: {
      lat: 51.2117,
      lng: -58.6583,
    },
    links_count: 8,
    objectID: "6789",
    uniqueID: "b058ae50-a966-4fdf-8064-8ba8daf9175b",
  },
  {
    name: "Deering Airport",
    city: "Deering",
    country: "United States",
    iata_code: "DRG",
    _geoloc: {
      lat: 66.0696,
      lng: -162.766,
    },
    links_count: 8,
    objectID: "6755",
    uniqueID: "39f6693c-c712-4a15-a74b-a3ef7eb3e471",
  },
  {
    name: "Puvirnituq Airport",
    city: "Puvirnituq",
    country: "Canada",
    iata_code: "YPX",
    _geoloc: {
      lat: 60.0506,
      lng: -77.2869,
    },
    links_count: 8,
    objectID: "6727",
    uniqueID: "1bf5b0ee-759b-4c81-95b2-2dffaf2ad121",
  },
  {
    name: "Savoonga Airport",
    city: "Savoonga",
    country: "United States",
    iata_code: "SVA",
    _geoloc: {
      lat: 63.6864,
      lng: -170.493,
    },
    links_count: 8,
    objectID: "6724",
    uniqueID: "4b983e1b-52de-426d-b632-95cea8c4f7b8",
  },
  {
    name: "Point Hope Airport",
    city: "Point Hope",
    country: "United States",
    iata_code: "PHO",
    _geoloc: {
      lat: 68.3488,
      lng: -166.799,
    },
    links_count: 8,
    objectID: "6721",
    uniqueID: "22e222d3-758d-4248-92b0-9f13c49885f5",
  },
  {
    name: "Gambell Airport",
    city: "Gambell",
    country: "United States",
    iata_code: "GAM",
    _geoloc: {
      lat: 63.7668,
      lng: -171.733,
    },
    links_count: 8,
    objectID: "6715",
    uniqueID: "5c7a4049-cca2-494e-8aab-bc82a3c82c9d",
  },
  {
    name: "Panzhihua",
    city: "Panzhihua",
    country: "China",
    iata_code: "PZI",
    _geoloc: {
      lat: 26.54,
      lng: 101.799,
    },
    links_count: 8,
    objectID: "6435",
    uniqueID: "477e508c-9d60-42d1-a58d-16ac71773947",
  },
  {
    name: "Yining Airport",
    city: "Yining",
    country: "China",
    iata_code: "YIN",
    _geoloc: {
      lat: 43.9558,
      lng: 81.3303,
    },
    links_count: 8,
    objectID: "6409",
    uniqueID: "02fa0579-a2ce-4356-86a7-000dcd866221",
  },
  {
    name: "Batsfjord",
    city: "Batsfjord",
    country: "Norway",
    iata_code: "BJF",
    _geoloc: {
      lat: 70.600278,
      lng: 29.6925,
    },
    links_count: 8,
    objectID: "637",
    uniqueID: "e6e11d91-889e-497e-ab04-3267740c41ad",
  },
  {
    name: "Enshi Airport",
    city: "Enshi",
    country: "China",
    iata_code: "ENH",
    _geoloc: {
      lat: 30.3203,
      lng: 109.485,
    },
    links_count: 8,
    objectID: "6358",
    uniqueID: "c5bcaa09-2eb6-47ac-ba72-7880eedfdced",
  },
  {
    name: "Groote Eylandt Airport",
    city: "Groote Eylandt",
    country: "Australia",
    iata_code: "GTE",
    _geoloc: {
      lat: -13.975,
      lng: 136.46,
    },
    links_count: 8,
    objectID: "6270",
    uniqueID: "ff37dd82-f005-40c7-97d4-fc84cf64d707",
  },
  {
    name: "Gladstone Airport",
    city: "Gladstone",
    country: "Australia",
    iata_code: "GLT",
    _geoloc: {
      lat: -23.8697,
      lng: 151.223,
    },
    links_count: 8,
    objectID: "6269",
    uniqueID: "23f6fd10-58d9-47d8-afb3-8560d0b39742",
  },
  {
    name: "Vagar",
    city: "Vagar",
    country: "Faroe Islands",
    iata_code: "FAE",
    _geoloc: {
      lat: 62.063628,
      lng: -7.277219,
    },
    links_count: 8,
    objectID: "625",
    uniqueID: "afff7dbf-aa3a-4e03-ace5-a3b32cd989a4",
  },
  {
    name: "Broken Hill Airport",
    city: "Broken Hill",
    country: "Australia",
    iata_code: "BHQ",
    _geoloc: {
      lat: -32.0014,
      lng: 141.472,
    },
    links_count: 8,
    objectID: "6241",
    uniqueID: "bfbfd21f-8f08-4348-b9db-7d2d85268144",
  },
  {
    name: "Cat Bi International Airport",
    city: "Haiphong",
    country: "Vietnam",
    iata_code: "HPH",
    _geoloc: {
      lat: 20.819386,
      lng: 106.724989,
    },
    links_count: 8,
    objectID: "6188",
    uniqueID: "c7dd585e-c68f-4d0c-9d88-da827c80a155",
  },
  {
    name: "Bugulma Airport",
    city: "Bugulma",
    country: "Russia",
    iata_code: "UUA",
    _geoloc: {
      lat: 54.64,
      lng: 52.8017,
    },
    links_count: 8,
    objectID: "6160",
    uniqueID: "bf7c053f-762f-42d5-b469-c2268e85d394",
  },
  {
    name: "Nukus Airport",
    city: "Nukus",
    country: "Uzbekistan",
    iata_code: "NCU",
    _geoloc: {
      lat: 42.4884,
      lng: 59.6233,
    },
    links_count: 8,
    objectID: "6151",
    uniqueID: "b83531da-a794-463e-adc2-2983ebd0ded6",
  },
  {
    name: "Izhevsk Airport",
    city: "Izhevsk",
    country: "Russia",
    iata_code: "IJK",
    _geoloc: {
      lat: 56.8281,
      lng: 53.4575,
    },
    links_count: 8,
    objectID: "6141",
    uniqueID: "a5566cf5-7c30-4cf5-87da-ed7302054543",
  },
  {
    name: "Nyagan Airport",
    city: "Nyagan",
    country: "Russia",
    iata_code: "NYA",
    _geoloc: {
      lat: 62.11,
      lng: 65.615,
    },
    links_count: 8,
    objectID: "6139",
    uniqueID: "ba818fff-e3b1-4718-b638-21b356f61e0c",
  },
  {
    name: "Kingman Airport",
    city: "Kingman",
    country: "United States",
    iata_code: "IGM",
    _geoloc: {
      lat: 35.2595,
      lng: -113.938,
    },
    links_count: 8,
    objectID: "6132",
    uniqueID: "3630d254-a320-41da-8ef5-79d5bee09177",
  },
  {
    name: "Virgin Gorda Airport",
    city: "Spanish Town",
    country: "British Virgin Islands",
    iata_code: "VIJ",
    _geoloc: {
      lat: 18.4464,
      lng: -64.4275,
    },
    links_count: 8,
    objectID: "6080",
    uniqueID: "7479cba5-1fd2-4aaf-9ec0-1a62464face0",
  },
  {
    name: "San Cristóbal Airport",
    city: "San Cristóbal",
    country: "Ecuador",
    iata_code: "SCY",
    _geoloc: {
      lat: -0.910206,
      lng: -89.61745,
    },
    links_count: 8,
    objectID: "6045",
    uniqueID: "88556070-9c81-4c3c-bb70-24c4b2c3e078",
  },
  {
    name: "Santa Maria Airport",
    city: "Santa Maria",
    country: "Brazil",
    iata_code: "RIA",
    _geoloc: {
      lat: -29.711358,
      lng: -53.688153,
    },
    links_count: 8,
    objectID: "6040",
    uniqueID: "197fa0b8-1f01-4a8a-b35c-b3b0dfe38122",
  },
  {
    name: "Macaé Airport",
    city: "Macaé",
    country: "Brazil",
    iata_code: "MEA",
    _geoloc: {
      lat: -22.343,
      lng: -41.766,
    },
    links_count: 8,
    objectID: "6037",
    uniqueID: "efe6b035-4b32-47a3-89d4-ba7180b78ccb",
  },
  {
    name: "Fukushima Airport",
    city: "Fukushima",
    country: "Japan",
    iata_code: "FKS",
    _geoloc: {
      lat: 37.2274,
      lng: 140.431,
    },
    links_count: 8,
    objectID: "5999",
    uniqueID: "c0aeaad5-f4a9-4e47-9e4a-182af7f17f83",
  },
  {
    name: "Londolovit Airport",
    city: "Londolovit",
    country: "Papua New Guinea",
    iata_code: "LNV",
    _geoloc: {
      lat: -3.04361,
      lng: 152.629,
    },
    links_count: 8,
    objectID: "5979",
    uniqueID: "2f8c4cdd-9b0b-47f6-91d7-e6e00c9b95c7",
  },
  {
    name: "Kalskag Airport",
    city: "Kalskag",
    country: "United States",
    iata_code: "KLG",
    _geoloc: {
      lat: 61.5363,
      lng: -160.341,
    },
    links_count: 8,
    objectID: "5964",
    uniqueID: "4edda1c3-7f2d-4d25-b89f-25bb31635815",
  },
  {
    name: "Hiva Oa-Atuona Airport",
    city: "Hiva-oa",
    country: "French Polynesia",
    iata_code: "AUQ",
    _geoloc: {
      lat: -9.768794,
      lng: -139.011256,
    },
    links_count: 8,
    objectID: "5890",
    uniqueID: "88085c1b-6165-417f-bd41-fc89a0e84bdc",
  },
  {
    name: "Puerto Jimenez Airport",
    city: "Puerto Jimenez",
    country: "Costa Rica",
    iata_code: "PJM",
    _geoloc: {
      lat: 8.53333,
      lng: -83.3,
    },
    links_count: 8,
    objectID: "5847",
    uniqueID: "066413ea-8ea6-411d-9ab9-215298e0d3ae",
  },
  {
    name: "Cabo Velas Airport",
    city: "Nicoya",
    country: "Costa Rica",
    iata_code: "TNO",
    _geoloc: {
      lat: 10.3557,
      lng: -85.852892,
    },
    links_count: 8,
    objectID: "5844",
    uniqueID: "3855e70e-1cd5-4eb4-a4c6-68ee7ae9aea7",
  },
  {
    name: "JAGS McCartney International Airport",
    city: "Cockburn Town",
    country: "Turks and Caicos Islands",
    iata_code: "GDT",
    _geoloc: {
      lat: 21.4445,
      lng: -71.1423,
    },
    links_count: 8,
    objectID: "5807",
    uniqueID: "dd34b1c9-d0df-473a-8ea4-b1182e13cc88",
  },
  {
    name: "San Luis County Regional Airport",
    city: "San Luis Obispo",
    country: "United States",
    iata_code: "SBP",
    _geoloc: {
      lat: 35.2368,
      lng: -120.642,
    },
    links_count: 8,
    objectID: "5768",
    uniqueID: "5ede827b-e71a-456c-8647-5adef1743c30",
  },
  {
    name: "Central Nebraska Regional Airport",
    city: "Grand Island",
    country: "United States",
    iata_code: "GRI",
    _geoloc: {
      lat: 40.9675,
      lng: -98.3096,
    },
    links_count: 8,
    objectID: "5740",
    uniqueID: "a8a018b6-8f6a-429f-a79a-578f8eb97ed0",
  },
  {
    name: "Southwest Georgia Regional Airport",
    city: "Albany",
    country: "United States",
    iata_code: "ABY",
    _geoloc: {
      lat: 31.5355,
      lng: -84.1945,
    },
    links_count: 8,
    objectID: "5715",
    uniqueID: "e9c9fd45-1c23-4b68-bf09-55eebb6acdc8",
  },
  {
    name: "Nanyuki Civil Airport",
    city: "Nanyuki",
    country: "Kenya",
    iata_code: "NYK",
    _geoloc: {
      lat: -0.067,
      lng: 37.033,
    },
    links_count: 8,
    objectID: "5695",
    uniqueID: "5aa83577-429b-41b0-b586-7c138494c90a",
  },
  {
    name: "Galcaio Airport",
    city: "Galcaio",
    country: "Somalia",
    iata_code: "GLK",
    _geoloc: {
      lat: 6.78083,
      lng: 47.4547,
    },
    links_count: 8,
    objectID: "5688",
    uniqueID: "2ea59b8f-ae9b-448f-87d2-90bc30d83b9d",
  },
  {
    name: "Bosaso Airport",
    city: "Bosaso",
    country: "Somalia",
    iata_code: "BSA",
    _geoloc: {
      lat: 11.2753,
      lng: 49.1494,
    },
    links_count: 8,
    objectID: "5686",
    uniqueID: "4ada4d32-4bef-4edb-8cec-3771fe69929d",
  },
  {
    name: "Hassan I Airport",
    city: "El Aaiún",
    country: "Western Sahara",
    iata_code: "EUN",
    _geoloc: {
      lat: 27.1517,
      lng: -13.2192,
    },
    links_count: 8,
    objectID: "5672",
    uniqueID: "12e5bad9-7556-4336-8fe1-140d9c4b11ea",
  },
  {
    name: "Kruger Mpumalanga International Airport",
    city: "Mpumalanga",
    country: "South Africa",
    iata_code: "MQP",
    _geoloc: {
      lat: -25.3832,
      lng: 31.1056,
    },
    links_count: 8,
    objectID: "5604",
    uniqueID: "865dca1f-8641-4bbe-8da9-7b2d9fa16fa5",
  },
  {
    name: "Fredericton",
    city: "Fredericton",
    country: "Canada",
    iata_code: "YFC",
    _geoloc: {
      lat: 45.868889,
      lng: -66.537222,
    },
    links_count: 8,
    objectID: "56",
    uniqueID: "9fca79df-e76c-41f4-9b55-a70e4db63834",
  },
  {
    name: "Sogndal Airport",
    city: "Sogndal",
    country: "Norway",
    iata_code: "SOG",
    _geoloc: {
      lat: 61.1561,
      lng: 7.13778,
    },
    links_count: 8,
    objectID: "5586",
    uniqueID: "46787bcc-17aa-44ba-bca9-b92b6dce34a4",
  },
  {
    name: "Kumasi Airport",
    city: "Kumasi",
    country: "Ghana",
    iata_code: "KMS",
    _geoloc: {
      lat: 6.71456,
      lng: -1.59082,
    },
    links_count: 8,
    objectID: "5556",
    uniqueID: "cb347820-a0e1-4362-9b33-a9ac622261bd",
  },
  {
    name: "Batna Airport",
    city: "Batna",
    country: "Algeria",
    iata_code: "BLJ",
    _geoloc: {
      lat: 35.752106,
      lng: 6.308589,
    },
    links_count: 8,
    objectID: "5552",
    uniqueID: "1c9323bc-109a-4f05-ae76-a2bcf4f77c55",
  },
  {
    name: "Muskrat Dam Airport",
    city: "Muskrat Dam",
    country: "Canada",
    iata_code: "MSA",
    _geoloc: {
      lat: 53.4414,
      lng: -91.7628,
    },
    links_count: 8,
    objectID: "5544",
    uniqueID: "855b0919-4d61-4d61-98dc-08bbf4c21006",
  },
  {
    name: "Chevery Airport",
    city: "Chevery",
    country: "Canada",
    iata_code: "YHR",
    _geoloc: {
      lat: 50.4689,
      lng: -59.6367,
    },
    links_count: 8,
    objectID: "5503",
    uniqueID: "9a5b0f88-0b48-48b6-83e5-1b56b550da15",
  },
  {
    name: "Hopedale Airport",
    city: "Hopedale",
    country: "Canada",
    iata_code: "YHO",
    _geoloc: {
      lat: 55.4483,
      lng: -60.2286,
    },
    links_count: 8,
    objectID: "5502",
    uniqueID: "50f57fe7-b78a-4b3a-beee-75783ba79c94",
  },
  {
    name: "Makkovik Airport",
    city: "Makkovik",
    country: "Canada",
    iata_code: "YMN",
    _geoloc: {
      lat: 55.0769,
      lng: -59.1864,
    },
    links_count: 8,
    objectID: "5492",
    uniqueID: "dc19e776-32b5-4dbe-a943-057815f4c86a",
  },
  {
    name: "Nain Airport",
    city: "Nain",
    country: "Canada",
    iata_code: "YDP",
    _geoloc: {
      lat: 56.5492,
      lng: -61.6803,
    },
    links_count: 8,
    objectID: "5488",
    uniqueID: "8b5475c2-674e-4029-b022-85f7c7d07b79",
  },
  {
    name: "Apartadó Airport",
    city: "Apartadó",
    country: "Colombia",
    iata_code: "APO",
    _geoloc: {
      lat: 7.033,
      lng: -77.2,
    },
    links_count: 8,
    objectID: "5476",
    uniqueID: "45d1e516-5113-4009-a178-e574ccc51814",
  },
  {
    name: "Chisasibi Airport",
    city: "Chisasibi",
    country: "Canada",
    iata_code: "YKU",
    _geoloc: {
      lat: 53.8056,
      lng: -78.9169,
    },
    links_count: 8,
    objectID: "5472",
    uniqueID: "41d9635e-df4a-4434-957b-1d5a40367212",
  },
  {
    name: "Bearskin Lake Airport",
    city: "Bearskin Lake",
    country: "Canada",
    iata_code: "XBE",
    _geoloc: {
      lat: 53.9656,
      lng: -91.0272,
    },
    links_count: 8,
    objectID: "5468",
    uniqueID: "476a2a92-1e65-44a2-87ec-94dab5dc7081",
  },
  {
    name: "Nanortalik Heliport",
    city: "Nanortalik",
    country: "Greenland",
    iata_code: "JNN",
    _geoloc: {
      lat: 60.14,
      lng: -45.2317,
    },
    links_count: 8,
    objectID: "5444",
    uniqueID: "573e5430-1b73-4d4e-98e3-44135cc0661c",
  },
  {
    name: "Tabubil Airport",
    city: "Tabubil",
    country: "Papua New Guinea",
    iata_code: "TBG",
    _geoloc: {
      lat: -5.27861,
      lng: 141.226,
    },
    links_count: 8,
    objectID: "5434",
    uniqueID: "6cb225bc-7311-43b6-ac09-c45032487080",
  },
  {
    name: "Kavieng Airport",
    city: "Kavieng",
    country: "Papua New Guinea",
    iata_code: "KVG",
    _geoloc: {
      lat: -2.5794,
      lng: 150.808,
    },
    links_count: 8,
    objectID: "5428",
    uniqueID: "9f68373a-0e3b-47bc-ab34-c399106f5221",
  },
  {
    name: "Daru Airport",
    city: "Daru",
    country: "Papua New Guinea",
    iata_code: "DAU",
    _geoloc: {
      lat: -9.08676,
      lng: 143.208,
    },
    links_count: 8,
    objectID: "5421",
    uniqueID: "c5587794-886b-49ee-bf72-fb17aa96e5a3",
  },
  {
    name: "Buka Airport",
    city: "Buka Island",
    country: "Papua New Guinea",
    iata_code: "BUA",
    _geoloc: {
      lat: -5.42232,
      lng: 154.673,
    },
    links_count: 8,
    objectID: "5419",
    uniqueID: "c817ccfd-ea74-4906-b21f-a77a1350b4c8",
  },
  {
    name: "Munda Airport",
    city: "Munda",
    country: "Solomon Islands",
    iata_code: "MUA",
    _geoloc: {
      lat: -8.32797,
      lng: 157.263,
    },
    links_count: 8,
    objectID: "5411",
    uniqueID: "567eb2c4-5ae3-4bbf-b2d5-101ec0a0ad0a",
  },
  {
    name: "Oulu",
    city: "Oulu",
    country: "Finland",
    iata_code: "OUL",
    _geoloc: {
      lat: 64.930061,
      lng: 25.354564,
    },
    links_count: 8,
    objectID: "446",
    uniqueID: "8caeb92b-330f-46ba-b2b1-2021aa17a240",
  },
  {
    name: "Mariehamn",
    city: "Mariehamn",
    country: "Finland",
    iata_code: "MHQ",
    _geoloc: {
      lat: 60.122203,
      lng: 19.898156,
    },
    links_count: 8,
    objectID: "442",
    uniqueID: "3b800de1-582c-4ebe-b3ab-44353adf4ea3",
  },
  {
    name: "Naryan-Mar",
    city: "Naryan-Mar",
    country: "Russia",
    iata_code: "NNM",
    _geoloc: {
      lat: 67.380537,
      lng: 53.051016,
    },
    links_count: 8,
    objectID: "4371",
    uniqueID: "a9e0d8b4-9c51-471c-826e-bb6ec44519eb",
  },
  {
    name: "Ukhta",
    city: "Ukhta",
    country: "Russia",
    iata_code: "UCT",
    _geoloc: {
      lat: 63.340297,
      lng: 53.482592,
    },
    links_count: 8,
    objectID: "4368",
    uniqueID: "8b80d212-2120-4913-a764-5f6a4cf1bbfa",
  },
  {
    name: "Kzyl-Orda",
    city: "Kzyl-Orda",
    country: "Kazakhstan",
    iata_code: "KZO",
    _geoloc: {
      lat: 44.709,
      lng: 65.591,
    },
    links_count: 8,
    objectID: "4358",
    uniqueID: "99c361c5-7b7c-40f7-913a-c6d35bbbd732",
  },
  {
    name: "Berlevag",
    city: "Berlevag",
    country: "Norway",
    iata_code: "BVG",
    _geoloc: {
      lat: 70.866667,
      lng: 29,
    },
    links_count: 8,
    objectID: "4350",
    uniqueID: "7c232399-d7ea-4f6b-a020-cb687820aa9b",
  },
  {
    name: "Hovden",
    city: "Orsta-Volda",
    country: "Norway",
    iata_code: "HOV",
    _geoloc: {
      lat: 62.18,
      lng: 6.0742,
    },
    links_count: 8,
    objectID: "4345",
    uniqueID: "68b912db-9cab-4900-9dc1-3ead1c1ff05f",
  },
  {
    name: "Copiapo",
    city: "Copiapo",
    country: "Chile",
    iata_code: "CPO",
    _geoloc: {
      lat: -27,
      lng: -70,
    },
    links_count: 8,
    objectID: "4312",
    uniqueID: "9bf7de03-a198-4f9d-a97e-8f6d001f30b8",
  },
  {
    name: "Ciudad del Este",
    city: "Ciudad del Este",
    country: "Paraguay",
    iata_code: "AGT",
    _geoloc: {
      lat: -25.4555,
      lng: -54.843592,
    },
    links_count: 8,
    objectID: "4305",
    uniqueID: "829edcd6-8673-4093-a4f1-b8df155687c1",
  },
  {
    name: "Erie Intl Tom Ridge Fld",
    city: "Erie",
    country: "United States",
    iata_code: "ERI",
    _geoloc: {
      lat: 42.082022,
      lng: -80.176217,
    },
    links_count: 8,
    objectID: "4276",
    uniqueID: "19786185-b4dd-4c04-904e-c51a9875af3e",
  },
  {
    name: "Martha\\\\'s Vineyard",
    city: "Vineyard Haven MA",
    country: "United States",
    iata_code: "MVY",
    _geoloc: {
      lat: 41.391667,
      lng: -70.615278,
    },
    links_count: 8,
    objectID: "4265",
    uniqueID: "9a32ade9-9f54-4874-a227-887d0b1a4c23",
  },
  {
    name: "Ozamis",
    city: "Ozamis",
    country: "Philippines",
    iata_code: "OZC",
    _geoloc: {
      lat: 8.178508,
      lng: 123.841731,
    },
    links_count: 8,
    objectID: "4204",
    uniqueID: "b42de09f-59b2-48b4-b539-3bb6a4927d87",
  },
  {
    name: "Legazpi",
    city: "Legazpi",
    country: "Philippines",
    iata_code: "LGP",
    _geoloc: {
      lat: 13.157064,
      lng: 123.746247,
    },
    links_count: 8,
    objectID: "4203",
    uniqueID: "b988ec32-9e72-4c4c-9045-8044acbf6922",
  },
  {
    name: "Dipolog",
    city: "Dipolog",
    country: "Philippines",
    iata_code: "DPL",
    _geoloc: {
      lat: 8.601261,
      lng: 123.334481,
    },
    links_count: 8,
    objectID: "4201",
    uniqueID: "b80e0511-9635-40e5-bb82-55db1080ce53",
  },
  {
    name: "Butuan",
    city: "Butuan",
    country: "Philippines",
    iata_code: "BXU",
    _geoloc: {
      lat: 8.951322,
      lng: 125.477972,
    },
    links_count: 8,
    objectID: "4200",
    uniqueID: "99e53f6d-678a-428d-a567-9166df7783a4",
  },
  {
    name: "Astypalaia",
    city: "Astypalaia",
    country: "Greece",
    iata_code: "JTY",
    _geoloc: {
      lat: 36.579886,
      lng: 26.375822,
    },
    links_count: 8,
    objectID: "4190",
    uniqueID: "abffddc8-088f-470e-8eea-fb1bf608e1d3",
  },
  {
    name: "Greater Binghamton Edwin A Link Fld",
    city: "Binghamton",
    country: "United States",
    iata_code: "BGM",
    _geoloc: {
      lat: 42.208689,
      lng: -75.979839,
    },
    links_count: 8,
    objectID: "4129",
    uniqueID: "a6247c67-4158-4d8c-855d-fd66d9a30cc8",
  },
  {
    name: "Idaho Falls Rgnl",
    city: "Idaho Falls",
    country: "United States",
    iata_code: "IDA",
    _geoloc: {
      lat: 43.514556,
      lng: -112.07075,
    },
    links_count: 8,
    objectID: "4100",
    uniqueID: "7f630049-2a48-430d-815d-d38b6683a8df",
  },
  {
    name: "Phu Bai",
    city: "Hue",
    country: "Vietnam",
    iata_code: "HUI",
    _geoloc: {
      lat: 16.401499,
      lng: 107.702614,
    },
    links_count: 8,
    objectID: "4082",
    uniqueID: "c83cc232-23d8-4538-b566-c6955175325c",
  },
  {
    name: "El Calafate",
    city: "El Calafate",
    country: "Argentina",
    iata_code: "FTE",
    _geoloc: {
      lat: -50.280322,
      lng: -72.053103,
    },
    links_count: 8,
    objectID: "4061",
    uniqueID: "ca87001a-0b75-4355-bd7c-fba9206994c4",
  },
  {
    name: "Manhattan Reigonal",
    city: "Manhattan",
    country: "United States",
    iata_code: "MHK",
    _geoloc: {
      lat: 39.140972,
      lng: -96.670833,
    },
    links_count: 8,
    objectID: "4050",
    uniqueID: "3a26946f-76ba-44df-b111-ce3e68df5eff",
  },
  {
    name: "Champaign",
    city: "Champaign",
    country: "United States",
    iata_code: "CMI",
    _geoloc: {
      lat: 40.03925,
      lng: -88.278056,
    },
    links_count: 8,
    objectID: "4049",
    uniqueID: "b5713e83-8827-4df0-963a-3d07b69fd21e",
  },
  {
    name: "Rochester",
    city: "Rochester",
    country: "United States",
    iata_code: "RST",
    _geoloc: {
      lat: 43.908283,
      lng: -92.500014,
    },
    links_count: 8,
    objectID: "4048",
    uniqueID: "144e7b47-7ecc-4d99-8fe8-2bc4ed2695e0",
  },
  {
    name: "Ayers Rock",
    city: "Uluru",
    country: "Australia",
    iata_code: "AYQ",
    _geoloc: {
      lat: -25.186111,
      lng: 130.975556,
    },
    links_count: 8,
    objectID: "4010",
    uniqueID: "72789548-39e3-4d76-9af1-eca445d0151e",
  },
  {
    name: "Godofredo P",
    city: "Caticlan",
    country: "Philippines",
    iata_code: "MPH",
    _geoloc: {
      lat: 11.9214999,
      lng: 121.953,
    },
    links_count: 8,
    objectID: "4002",
    uniqueID: "ef74b790-1ee2-40fa-a8af-93aa46203fc7",
  },
  {
    name: "Surat Thani",
    city: "Surat Thani",
    country: "Thailand",
    iata_code: "URT",
    _geoloc: {
      lat: 9.1325,
      lng: 99.135556,
    },
    links_count: 8,
    objectID: "4000",
    uniqueID: "6597fb4f-e76f-481f-8ce8-7fa527affdb4",
  },
  {
    name: "Clyde River",
    city: "Clyde River",
    country: "Canada",
    iata_code: "YCY",
    _geoloc: {
      lat: 70.486111,
      lng: -68.516667,
    },
    links_count: 8,
    objectID: "40",
    uniqueID: "3fce350c-668e-4fe2-a071-2233b17c7fec",
  },
  {
    name: "Ithaca Tompkins Rgnl",
    city: "Ithaca",
    country: "United States",
    iata_code: "ITH",
    _geoloc: {
      lat: 42.491028,
      lng: -76.458444,
    },
    links_count: 8,
    objectID: "3947",
    uniqueID: "d18259a9-717d-406c-b7db-8efbbf845a3e",
  },
  {
    name: "Lewiston Nez Perce Co",
    city: "Lewiston",
    country: "United States",
    iata_code: "LWS",
    _geoloc: {
      lat: 46.3745,
      lng: -117.015389,
    },
    links_count: 8,
    objectID: "3945",
    uniqueID: "c05b8f37-440d-4120-82c4-c1c9ce340344",
  },
  {
    name: "Mutiara Ii",
    city: "Labuhan Bajo",
    country: "Indonesia",
    iata_code: "LBJ",
    _geoloc: {
      lat: -8.486656,
      lng: 119.88905,
    },
    links_count: 8,
    objectID: "3917",
    uniqueID: "91c27c54-2200-4e36-a0d0-5b2f6d7fac18",
  },
  {
    name: "H Hasan Aroeboesman",
    city: "Ende",
    country: "Indonesia",
    iata_code: "ENE",
    _geoloc: {
      lat: -8.849294,
      lng: 121.660644,
    },
    links_count: 8,
    objectID: "3914",
    uniqueID: "5b3dbf39-8254-4d41-b5a3-3fac6fe47ce8",
  },
  {
    name: "H As Hanandjoeddin",
    city: "Tanjung Pandan",
    country: "Indonesia",
    iata_code: "TJQ",
    _geoloc: {
      lat: -2.745722,
      lng: 107.754917,
    },
    links_count: 8,
    objectID: "3904",
    uniqueID: "8a6285a7-10e2-4757-878a-95210f03e1b3",
  },
  {
    name: "Wolter Monginsidi",
    city: "Kendari",
    country: "Indonesia",
    iata_code: "KDI",
    _geoloc: {
      lat: -4.081608,
      lng: 122.418231,
    },
    links_count: 8,
    objectID: "3890",
    uniqueID: "4eb44212-f163-488e-9aff-c07122e98f28",
  },
  {
    name: "Youngstown Warren Rgnl",
    city: "Youngstown",
    country: "United States",
    iata_code: "YNG",
    _geoloc: {
      lat: 41.260736,
      lng: -80.679097,
    },
    links_count: 8,
    objectID: "3881",
    uniqueID: "7c468853-9fd9-4987-b2da-47caa9065118",
  },
  {
    name: "Watertown Intl",
    city: "Watertown",
    country: "United States",
    iata_code: "ART",
    _geoloc: {
      lat: 43.991922,
      lng: -76.021739,
    },
    links_count: 8,
    objectID: "3838",
    uniqueID: "b233afb7-ea60-4672-b72b-f37200b6f4e5",
  },
  {
    name: "Columbia Rgnl",
    city: "Columbia",
    country: "United States",
    iata_code: "COU",
    _geoloc: {
      lat: 38.818094,
      lng: -92.219631,
    },
    links_count: 8,
    objectID: "3719",
    uniqueID: "07b28d97-3cf1-41e2-9f8e-750422e0aac7",
  },
  {
    name: "Laredo Intl",
    city: "Laredo",
    country: "United States",
    iata_code: "LRD",
    _geoloc: {
      lat: 27.54375,
      lng: -99.461556,
    },
    links_count: 8,
    objectID: "3691",
    uniqueID: "84e5d33b-f2be-48b5-9536-6b9c3ef61b75",
  },
  {
    name: "Salisbury Ocean City Wicomico Rgnl",
    city: "Salisbury",
    country: "United States",
    iata_code: "SBY",
    _geoloc: {
      lat: 38.340525,
      lng: -75.510289,
    },
    links_count: 8,
    objectID: "3642",
    uniqueID: "ddcd0a63-aabb-42b5-b6a5-8cc16ff6dad3",
  },
  {
    name: "Lubeck Blankensee",
    city: "Luebeck",
    country: "Germany",
    iata_code: "LBC",
    _geoloc: {
      lat: 53.805367,
      lng: 10.719222,
    },
    links_count: 8,
    objectID: "364",
    uniqueID: "6b8b95bb-e487-4f77-be98-12fdff5171bc",
  },
  {
    name: "Duluth Intl",
    city: "Duluth",
    country: "United States",
    iata_code: "DLH",
    _geoloc: {
      lat: 46.842091,
      lng: -92.193649,
    },
    links_count: 8,
    objectID: "3598",
    uniqueID: "3260e233-32b5-4b6c-9774-4178500a1805",
  },
  {
    name: "Hilo Intl",
    city: "Hilo",
    country: "United States",
    iata_code: "ITO",
    _geoloc: {
      lat: 19.721375,
      lng: -155.048469,
    },
    links_count: 8,
    objectID: "3415",
    uniqueID: "c9714b1c-5ad0-4197-af13-486d80b22e63",
  },
  {
    name: "Coffs Harbour",
    city: "Coff's Harbour",
    country: "Australia",
    iata_code: "CFS",
    _geoloc: {
      lat: -30.320556,
      lng: 153.116389,
    },
    links_count: 8,
    objectID: "3356",
    uniqueID: "0bd23b2b-b174-4a80-a6fa-a33b10a1365e",
  },
  {
    name: "Albury",
    city: "Albury",
    country: "Australia",
    iata_code: "ABX",
    _geoloc: {
      lat: -36.067778,
      lng: 146.958056,
    },
    links_count: 8,
    objectID: "3333",
    uniqueID: "613e3fe6-804d-4a31-b87b-b80b8d39d85a",
  },
  {
    name: "Charleville",
    city: "Charlieville",
    country: "Australia",
    iata_code: "CTL",
    _geoloc: {
      lat: -26.413334,
      lng: 146.2625,
    },
    links_count: 8,
    objectID: "3323",
    uniqueID: "bc8e4505-1408-4eb6-b367-b8d65c084f38",
  },
  {
    name: "Presidente Nicolau Lobato Intl",
    city: "Dili",
    country: "East Timor",
    iata_code: "DIL",
    _geoloc: {
      lat: -8.546553,
      lng: 125.524719,
    },
    links_count: 8,
    objectID: "3310",
    uniqueID: "cc006203-47c5-4a41-b3c7-1f3999bd5217",
  },
  {
    name: "Labuan",
    city: "Labuan",
    country: "Malaysia",
    iata_code: "LBU",
    _geoloc: {
      lat: 5.300683,
      lng: 115.250181,
    },
    links_count: 8,
    objectID: "3270",
    uniqueID: "1c7f5b1d-3e35-440b-9394-45a37bdc73b2",
  },
  {
    name: "Jalaluddin",
    city: "Gorontalo",
    country: "Indonesia",
    iata_code: "GTO",
    _geoloc: {
      lat: 0.637119,
      lng: 122.849858,
    },
    links_count: 8,
    objectID: "3247",
    uniqueID: "01841c58-e9e0-4d08-a6ac-3ec15984a1b1",
  },
  {
    name: "Moses Kilangin",
    city: "Timika",
    country: "Indonesia",
    iata_code: "TIM",
    _geoloc: {
      lat: -4.528275,
      lng: 136.887375,
    },
    links_count: 8,
    objectID: "3243",
    uniqueID: "8867ff87-61a6-42d7-8958-5e944fd99a4c",
  },
  {
    name: "Frans Kaisiepo",
    city: "Biak",
    country: "Indonesia",
    iata_code: "BIK",
    _geoloc: {
      lat: -1.190017,
      lng: 136.107997,
    },
    links_count: 8,
    objectID: "3241",
    uniqueID: "1cda2373-20fe-45a2-ac65-210cbfc0d2c2",
  },
  {
    name: "Myitkyina",
    city: "Myitkyina",
    country: "Burma",
    iata_code: "MYT",
    _geoloc: {
      lat: 25.383636,
      lng: 97.351919,
    },
    links_count: 8,
    objectID: "3224",
    uniqueID: "2a2cad20-44a8-4cae-8e30-7838f45dda4c",
  },
  {
    name: "Shpakovskoye",
    city: "Stavropol",
    country: "Russia",
    iata_code: "STW",
    _geoloc: {
      lat: 45.109165,
      lng: 42.112778,
    },
    links_count: 8,
    objectID: "2963",
    uniqueID: "cb47b59a-8596-4892-aa1b-a73b886b6f95",
  },
  {
    name: "Uytash",
    city: "Makhachkala",
    country: "Russia",
    iata_code: "MCX",
    _geoloc: {
      lat: 42.816822,
      lng: 47.652294,
    },
    links_count: 8,
    objectID: "2961",
    uniqueID: "ff4e957c-259d-480a-89e4-5ada5422098f",
  },
  {
    name: "Abakan",
    city: "Abakan",
    country: "Russia",
    iata_code: "ABA",
    _geoloc: {
      lat: 53.74,
      lng: 91.385,
    },
    links_count: 8,
    objectID: "2955",
    uniqueID: "f9c2fa86-e5dc-4ada-afa8-75fe02b47cb4",
  },
  {
    name: "Bratsk",
    city: "Bratsk",
    country: "Russia",
    iata_code: "BTK",
    _geoloc: {
      lat: 56.370556,
      lng: 101.698331,
    },
    links_count: 8,
    objectID: "2936",
    uniqueID: "3c55cdd1-b852-46fa-b1fd-a026f03ceecb",
  },
  {
    name: "Rafael Hernandez",
    city: "Aguadilla",
    country: "Puerto Rico",
    iata_code: "BQN",
    _geoloc: {
      lat: 18.494861,
      lng: -67.129444,
    },
    links_count: 8,
    objectID: "2885",
    uniqueID: "e9e6652f-4f57-4c24-8948-79984c2ab610",
  },
  {
    name: "Grand Case",
    city: "St. Martin",
    country: "Guadeloupe",
    iata_code: "SFG",
    _geoloc: {
      lat: 18.099914,
      lng: -63.047197,
    },
    links_count: 8,
    objectID: "2879",
    uniqueID: "63b5b308-8192-41d6-9c0c-bb096a7ee47b",
  },
  {
    name: "Mayor Buenaventura Vivas",
    city: "Santo Domingo",
    country: "Venezuela",
    iata_code: "STD",
    _geoloc: {
      lat: 7.565111,
      lng: -72.035125,
    },
    links_count: 8,
    objectID: "2861",
    uniqueID: "d45edd44-b5da-4d2a-895b-5386ed585cdd",
  },
  {
    name: "Maturin",
    city: "Maturin",
    country: "Venezuela",
    iata_code: "MUN",
    _geoloc: {
      lat: 9.749067,
      lng: -63.1534,
    },
    links_count: 8,
    objectID: "2852",
    uniqueID: "5473a2ef-a21b-4517-b820-a5bd222dfd86",
  },
  {
    name: "Josefa Camejo",
    city: "Paraguana",
    country: "Venezuela",
    iata_code: "LSP",
    _geoloc: {
      lat: 11.780775,
      lng: -70.151497,
    },
    links_count: 8,
    objectID: "2844",
    uniqueID: "2d64b482-fa49-42d5-8166-e055d78538ba",
  },
  {
    name: "Bagotville",
    city: "Bagotville",
    country: "Canada",
    iata_code: "YBG",
    _geoloc: {
      lat: 48.330555,
      lng: -70.996391,
    },
    links_count: 8,
    objectID: "28",
    uniqueID: "3718299f-03ed-4f93-b2f6-6634bd93d98f",
  },
  {
    name: "Yacuiba",
    city: "Yacuiba",
    country: "Bolivia",
    iata_code: "BYC",
    _geoloc: {
      lat: -21.960925,
      lng: -63.651669,
    },
    links_count: 8,
    objectID: "2772",
    uniqueID: "9e659f3c-7b12-4567-b02b-e644c0327d01",
  },
  {
    name: "Simon Bolivar",
    city: "Santa Marta",
    country: "Colombia",
    iata_code: "SMR",
    _geoloc: {
      lat: 11.11965,
      lng: -74.230647,
    },
    links_count: 8,
    objectID: "2748",
    uniqueID: "ecfcef13-eac2-4e9f-bee3-f8460f33ea6f",
  },
  {
    name: "Camilo Daza",
    city: "Cucuta",
    country: "Colombia",
    iata_code: "CUC",
    _geoloc: {
      lat: 7.927567,
      lng: -72.511547,
    },
    links_count: 8,
    objectID: "2713",
    uniqueID: "a72cc56a-bef3-42dd-af0c-82d628a5fdb3",
  },
  {
    name: "Tres De Mayo",
    city: "Puerto Asis",
    country: "Colombia",
    iata_code: "PUU",
    _geoloc: {
      lat: 0.505228,
      lng: -76.500836,
    },
    links_count: 8,
    objectID: "2706",
    uniqueID: "e3b03685-3efd-4133-b02d-ba362a93bbf7",
  },
  {
    name: "Balmaceda",
    city: "Balmaceda",
    country: "Chile",
    iata_code: "BBA",
    _geoloc: {
      lat: -45.916058,
      lng: -71.689475,
    },
    links_count: 8,
    objectID: "2642",
    uniqueID: "ce686a59-8e0f-4ae8-8faa-663c76ce870c",
  },
  {
    name: "Uberaba",
    city: "Uberaba",
    country: "Brazil",
    iata_code: "UBA",
    _geoloc: {
      lat: -19.765,
      lng: -47.964778,
    },
    links_count: 8,
    objectID: "2635",
    uniqueID: "67be2c81-a3ec-493c-bdb5-01f67fa28771",
  },
  {
    name: "Comandante Espora",
    city: "Bahia Blanca",
    country: "Argentina",
    iata_code: "BHI",
    _geoloc: {
      lat: -38.724967,
      lng: -62.169317,
    },
    links_count: 8,
    objectID: "2501",
    uniqueID: "659ac0c7-956a-4617-b78d-e06b353fcd20",
  },
  {
    name: "Cataratas Del Iguazu",
    city: "Iguazu Falls",
    country: "Argentina",
    iata_code: "IGR",
    _geoloc: {
      lat: -25.737281,
      lng: -54.473444,
    },
    links_count: 8,
    objectID: "2471",
    uniqueID: "a0001d8b-bee2-4706-84a8-98b5b8c5f54d",
  },
  {
    name: "Dumaguete",
    city: "Dumaguete",
    country: "Philippines",
    iata_code: "DGT",
    _geoloc: {
      lat: 9.333714,
      lng: 123.300472,
    },
    links_count: 8,
    objectID: "2425",
    uniqueID: "08c7e906-a4a1-4476-a14d-f5c8410ff998",
  },
  {
    name: "Noumerat",
    city: "Ghardaia",
    country: "Algeria",
    iata_code: "GHA",
    _geoloc: {
      lat: 32.384106,
      lng: 3.794114,
    },
    links_count: 8,
    objectID: "237",
    uniqueID: "429eaf4c-3ad4-4410-ac24-4c31b123f8fd",
  },
  {
    name: "Gwangju",
    city: "Kwangju",
    country: "South Korea",
    iata_code: "KWJ",
    _geoloc: {
      lat: 35.126389,
      lng: 126.808889,
    },
    links_count: 8,
    objectID: "2361",
    uniqueID: "9a0a5130-7f3a-4728-9ce2-65721b052c81",
  },
  {
    name: "Biskra",
    city: "Biskra",
    country: "Algeria",
    iata_code: "BSK",
    _geoloc: {
      lat: 34.793289,
      lng: 5.738231,
    },
    links_count: 8,
    objectID: "235",
    uniqueID: "ada11e16-c543-4fa5-905b-b3b100b2b755",
  },
  {
    name: "Hanamaki",
    city: "Hanamaki",
    country: "Japan",
    iata_code: "HNA",
    _geoloc: {
      lat: 39.428611,
      lng: 141.135278,
    },
    links_count: 8,
    objectID: "2344",
    uniqueID: "4d96bbf3-14f3-405e-bdf6-718d3cab3820",
  },
  {
    name: "Kochi",
    city: "Kochi",
    country: "Japan",
    iata_code: "KCZ",
    _geoloc: {
      lat: 33.546111,
      lng: 133.669444,
    },
    links_count: 8,
    objectID: "2332",
    uniqueID: "f7cfa8be-53b5-4619-b628-324c5104d1a0",
  },
  {
    name: "Fukue",
    city: "Fukue",
    country: "Japan",
    iata_code: "FUJ",
    _geoloc: {
      lat: 32.666269,
      lng: 128.832808,
    },
    links_count: 8,
    objectID: "2304",
    uniqueID: "1bdf1006-1dd3-47b9-afaf-340f33f132d7",
  },
  {
    name: "Hualien",
    city: "Hualien",
    country: "Taiwan",
    iata_code: "HUN",
    _geoloc: {
      lat: 24.023725,
      lng: 121.616906,
    },
    links_count: 8,
    objectID: "2278",
    uniqueID: "89143d47-68d1-4fbf-afde-60a7df85b415",
  },
  {
    name: "Cassidy Intl",
    city: "Kiritimati",
    country: "Kiribati",
    iata_code: "CXI",
    _geoloc: {
      lat: 1.986161,
      lng: -157.349778,
    },
    links_count: 8,
    objectID: "2252",
    uniqueID: "89e98f7e-8427-4cd2-87c3-ce2cfa70f7c6",
  },
  {
    name: "Marshall Islands Intl",
    city: "Majuro",
    country: "Marshall Islands",
    iata_code: "MAJ",
    _geoloc: {
      lat: 7.064758,
      lng: 171.272022,
    },
    links_count: 8,
    objectID: "2249",
    uniqueID: "dda7bbce-6f8f-43a4-8b23-047bb3e169a1",
  },
  {
    name: "Bateen",
    city: "Abu Dhabi",
    country: "United Arab Emirates",
    iata_code: "AZI",
    _geoloc: {
      lat: 24.428333,
      lng: 54.458084,
    },
    links_count: 8,
    objectID: "2180",
    uniqueID: "383647a7-7ef2-41b7-8be9-fe46f6d431dc",
  },
  {
    name: "Tamanrasset",
    city: "Tamanrasset",
    country: "Algeria",
    iata_code: "TMR",
    _geoloc: {
      lat: 22.811461,
      lng: 5.451075,
    },
    links_count: 8,
    objectID: "216",
    uniqueID: "ee32703b-14cd-4949-93bb-82f9a152582c",
  },
  {
    name: "Kerman",
    city: "Kerman",
    country: "Iran",
    iata_code: "KER",
    _geoloc: {
      lat: 30.274444,
      lng: 56.951111,
    },
    links_count: 8,
    objectID: "2136",
    uniqueID: "8659e151-b1a2-4966-846f-b6d5ae165a1c",
  },
  {
    name: "Sault Ste Marie",
    city: "Sault Sainte Marie",
    country: "Canada",
    iata_code: "YAM",
    _geoloc: {
      lat: 46.485001,
      lng: -84.509445,
    },
    links_count: 8,
    objectID: "21",
    uniqueID: "673fb910-57c6-43a1-a4bd-29f34e35d6a1",
  },
  {
    name: "Mazar I Sharif",
    city: "Mazar-i-sharif",
    country: "Afghanistan",
    iata_code: "MZR",
    _geoloc: {
      lat: 36.706914,
      lng: 67.209678,
    },
    links_count: 8,
    objectID: "2053",
    uniqueID: "5249bccf-ae29-48e5-8e9b-d1a3ab5d9b48",
  },
  {
    name: "Nelson",
    city: "Nelson",
    country: "New Zealand",
    iata_code: "NSN",
    _geoloc: {
      lat: -41.298333,
      lng: 173.221111,
    },
    links_count: 8,
    objectID: "2024",
    uniqueID: "67b64442-af00-4273-bfe2-2377b7bbe55f",
  },
  {
    name: "Hamilton",
    city: "Hamilton",
    country: "New Zealand",
    iata_code: "HLZ",
    _geoloc: {
      lat: -37.866661,
      lng: 175.332056,
    },
    links_count: 8,
    objectID: "2015",
    uniqueID: "d3f4a7f9-ba54-4d12-9123-bde828489e9e",
  },
  {
    name: "Huahine",
    city: "Huahine Island",
    country: "French Polynesia",
    iata_code: "HUH",
    _geoloc: {
      lat: -16.687242,
      lng: -151.021667,
    },
    links_count: 8,
    objectID: "1991",
    uniqueID: "2d5647c2-d00e-47bf-a452-13052a7237c2",
  },
  {
    name: "Nuku Hiva",
    city: "Nuku Hiva",
    country: "French Polynesia",
    iata_code: "NHV",
    _geoloc: {
      lat: -8.795603,
      lng: -140.228789,
    },
    links_count: 8,
    objectID: "1987",
    uniqueID: "7af0d5d4-0e30-4241-b448-942b318e6ed4",
  },
  {
    name: "San Salvador",
    city: "Cockburn Town",
    country: "Bahamas",
    iata_code: "ZSA",
    _geoloc: {
      lat: 24.063275,
      lng: -74.523967,
    },
    links_count: 8,
    objectID: "1956",
    uniqueID: "4c1e7159-581f-45e2-859a-00eec0a12176",
  },
  {
    name: "Taloyoak",
    city: "Spence Bay",
    country: "Canada",
    iata_code: "YYH",
    _geoloc: {
      lat: 69.546667,
      lng: -93.576667,
    },
    links_count: 8,
    objectID: "183",
    uniqueID: "913d70a3-3505-46da-861c-4ea4490cf30a",
  },
  {
    name: "General Rodolfo Sanchez Taboada Intl",
    city: "Mexicali",
    country: "Mexico",
    iata_code: "MXL",
    _geoloc: {
      lat: 32.630634,
      lng: -115.241637,
    },
    links_count: 8,
    objectID: "1820",
    uniqueID: "540bc580-27fd-429c-bff5-4d71678904b2",
  },
  {
    name: "Charlottetown",
    city: "Charlottetown",
    country: "Canada",
    iata_code: "YYG",
    _geoloc: {
      lat: 46.290001,
      lng: -63.121111,
    },
    links_count: 8,
    objectID: "182",
    uniqueID: "cb951a33-4172-48a0-ac12-2e8865fb39d2",
  },
  {
    name: "Smithers",
    city: "Smithers",
    country: "Canada",
    iata_code: "YYD",
    _geoloc: {
      lat: 54.824722,
      lng: -127.182778,
    },
    links_count: 8,
    objectID: "179",
    uniqueID: "28db2837-8afa-46cc-a6d3-8bc204a37d62",
  },
  {
    name: "Pangnirtung",
    city: "Pangnirtung",
    country: "Canada",
    iata_code: "YXP",
    _geoloc: {
      lat: 66.145,
      lng: -65.713611,
    },
    links_count: 8,
    objectID: "170",
    uniqueID: "d36b6228-d6ee-40a1-b514-d600b9afbec8",
  },
  {
    name: "Erhac",
    city: "Malatya",
    country: "Turkey",
    iata_code: "MLX",
    _geoloc: {
      lat: 38.435347,
      lng: 38.091006,
    },
    links_count: 8,
    objectID: "1695",
    uniqueID: "b01cc33d-acb5-4a5b-903d-ad8ac568a1f7",
  },
  {
    name: "Sivas",
    city: "Sivas",
    country: "Turkey",
    iata_code: "VAS",
    _geoloc: {
      lat: 39.813828,
      lng: 36.903497,
    },
    links_count: 8,
    objectID: "1694",
    uniqueID: "5a73ce54-4959-463e-a4d1-fd8c5f9e714f",
  },
  {
    name: "Canadian Rockies Intl",
    city: "Cranbrook",
    country: "Canada",
    iata_code: "YXC",
    _geoloc: {
      lat: 49.612222,
      lng: -115.781944,
    },
    links_count: 8,
    objectID: "164",
    uniqueID: "712e4b4f-2d64-4b12-a881-4f84774e8031",
  },
  {
    name: "Pico",
    city: "Pico",
    country: "Portugal",
    iata_code: "PIX",
    _geoloc: {
      lat: 38.554333,
      lng: -28.441333,
    },
    links_count: 8,
    objectID: "1634",
    uniqueID: "e84f0104-45e1-45b3-b878-7157355a8a96",
  },
  {
    name: "Flores",
    city: "Flores",
    country: "Portugal",
    iata_code: "FLW",
    _geoloc: {
      lat: 39.455272,
      lng: -31.131361,
    },
    links_count: 8,
    objectID: "1625",
    uniqueID: "96bf3de9-6c11-41ce-b7cb-93cb383cc8ec",
  },
  {
    name: "Mosnov",
    city: "Ostrava",
    country: "Czech Republic",
    iata_code: "OSR",
    _geoloc: {
      lat: 49.696292,
      lng: 18.111053,
    },
    links_count: 8,
    objectID: "1582",
    uniqueID: "9dba60bd-4f9a-4f37-b726-d1b00e66c1df",
  },
  {
    name: "Parma",
    city: "Parma",
    country: "Italy",
    iata_code: "PMF",
    _geoloc: {
      lat: 44.824483,
      lng: 10.296367,
    },
    links_count: 8,
    objectID: "1531",
    uniqueID: "2f675834-d4a3-4061-a779-6e5bbb81b5d2",
  },
  {
    name: "Hall Beach",
    city: "Hall Beach",
    country: "Canada",
    iata_code: "YUX",
    _geoloc: {
      lat: 68.776111,
      lng: -81.243611,
    },
    links_count: 8,
    objectID: "148",
    uniqueID: "666b3996-bc6b-4893-9bf7-8d97dc219b89",
  },
  {
    name: "Repulse Bay",
    city: "Repulse Bay",
    country: "Canada",
    iata_code: "YUT",
    _geoloc: {
      lat: 66.521389,
      lng: -86.224722,
    },
    links_count: 8,
    objectID: "147",
    uniqueID: "61f1eff9-7cdd-491d-90f1-62571de371aa",
  },
  {
    name: "Kasos",
    city: "Kasos",
    country: "Greece",
    iata_code: "KSJ",
    _geoloc: {
      lat: 35.421358,
      lng: 26.910047,
    },
    links_count: 8,
    objectID: "1461",
    uniqueID: "a677c350-4b4f-48c8-9bb0-a1b2d6cafd4c",
  },
  {
    name: "Kithira",
    city: "Kithira",
    country: "Greece",
    iata_code: "KIT",
    _geoloc: {
      lat: 36.274258,
      lng: 23.016978,
    },
    links_count: 8,
    objectID: "1454",
    uniqueID: "0f5766be-44c0-40b4-bc91-8c48831fa347",
  },
  {
    name: "Garons",
    city: "Nimes",
    country: "France",
    iata_code: "FNI",
    _geoloc: {
      lat: 43.757444,
      lng: 4.416347,
    },
    links_count: 8,
    objectID: "1439",
    uniqueID: "5e2431a7-fff4-4001-8b52-a627ab67a694",
  },
  {
    name: "Carpiquet",
    city: "Caen",
    country: "France",
    iata_code: "CFR",
    _geoloc: {
      lat: 49.173333,
      lng: -0.45,
    },
    links_count: 8,
    objectID: "1412",
    uniqueID: "bd4db19b-408d-4c6c-90d9-c6ad51566da1",
  },
  {
    name: "Fort Smith",
    city: "Fort Smith",
    country: "Canada",
    iata_code: "YSM",
    _geoloc: {
      lat: 60.020278,
      lng: -111.961944,
    },
    links_count: 8,
    objectID: "136",
    uniqueID: "9abc1472-ae91-457f-abd4-776c6fa13ac0",
  },
  {
    name: "Marcillac",
    city: "Rodez",
    country: "France",
    iata_code: "RDZ",
    _geoloc: {
      lat: 44.407869,
      lng: 2.482672,
    },
    links_count: 8,
    objectID: "1289",
    uniqueID: "deaa789c-0f30-4374-b1f0-5dd4f66e2720",
  },
  {
    name: "Quesnel",
    city: "Quesnel",
    country: "Canada",
    iata_code: "YQZ",
    _geoloc: {
      lat: 53.026112,
      lng: -122.510278,
    },
    links_count: 8,
    objectID: "127",
    uniqueID: "27343f7c-4881-4554-8aed-90ddf268900c",
  },
  {
    name: "Grande Prairie",
    city: "Grande Prairie",
    country: "Canada",
    iata_code: "YQU",
    _geoloc: {
      lat: 55.179722,
      lng: -118.885,
    },
    links_count: 8,
    objectID: "122",
    uniqueID: "66ab1517-7f1e-4d9b-aa98-9a1a1887f541",
  },
  {
    name: "Osijek",
    city: "Osijek",
    country: "Croatia",
    iata_code: "OSI",
    _geoloc: {
      lat: 45.462667,
      lng: 18.810156,
    },
    links_count: 8,
    objectID: "1202",
    uniqueID: "a8bde7ba-798f-46d1-a389-1bfe6aa0bfad",
  },
  {
    name: "Lamu Manda",
    city: "Lamu",
    country: "Kenya",
    iata_code: "LAU",
    _geoloc: {
      lat: -2.252417,
      lng: 40.913097,
    },
    links_count: 8,
    objectID: "1144",
    uniqueID: "a457ba95-5fdc-4c70-8356-7636218b779e",
  },
  {
    name: "Aswan Intl",
    city: "Aswan",
    country: "Egypt",
    iata_code: "ASW",
    _geoloc: {
      lat: 23.964356,
      lng: 32.819975,
    },
    links_count: 8,
    objectID: "1136",
    uniqueID: "568a0258-c83c-4da7-9c01-7f0f56b3e4b2",
  },
  {
    name: "Nouadhibou",
    city: "Nouadhibou",
    country: "Mauritania",
    iata_code: "NDB",
    _geoloc: {
      lat: 20.933067,
      lng: -17.029956,
    },
    links_count: 8,
    objectID: "1097",
    uniqueID: "9a28faec-2184-4506-916e-d88f40eb1d30",
  },
  {
    name: "J M Nkomo Intl",
    city: "Bulawayo",
    country: "Zimbabwe",
    iata_code: "BUQ",
    _geoloc: {
      lat: -20.017431,
      lng: 28.617869,
    },
    links_count: 8,
    objectID: "1001",
    uniqueID: "8c3a6ecb-dcf5-43c3-8de9-fc2fd8c2e452",
  },
  {
    name: "Sao Tome Intl",
    city: "Sao Tome",
    country: "Sao Tome and Principe",
    iata_code: "TMS",
    _geoloc: {
      lat: 0.378175,
      lng: 6.712153,
    },
    links_count: 7,
    objectID: "973",
    uniqueID: "50dcfce1-fa06-4b57-b7ba-239bf85eb1ac",
  },
  {
    name: "Mara Serena Airport",
    city: "Masai Mara",
    country: "Kenya",
    iata_code: "MRE",
    _geoloc: {
      lat: -1.406111,
      lng: 35.008056,
    },
    links_count: 7,
    objectID: "7424",
    uniqueID: "03c8b925-9a89-49c1-84b0-182e54fedf63",
  },
  {
    name: "Kotlik Airport",
    city: "Kotlik",
    country: "United States",
    iata_code: "KOT",
    _geoloc: {
      lat: 63.030556,
      lng: -163.532778,
    },
    links_count: 7,
    objectID: "7207",
    uniqueID: "eb5902d5-3f7c-4178-bc06-e25ab95caae1",
  },
  {
    name: "Kwigillingok Airport",
    city: "Kwigillingok",
    country: "United States",
    iata_code: "KWK",
    _geoloc: {
      lat: 59.876389,
      lng: -163.168611,
    },
    links_count: 7,
    objectID: "7096",
    uniqueID: "7882b9d9-b706-476c-a470-667505c374e2",
  },
  {
    name: "Emmonak Airport",
    city: "Emmonak",
    country: "United States",
    iata_code: "EMK",
    _geoloc: {
      lat: 62.786111,
      lng: -164.490833,
    },
    links_count: 7,
    objectID: "7087",
    uniqueID: "ebac7119-ceb6-4102-a59c-e8a8e5a651fc",
  },
  {
    name: "Bonaventure Airport",
    city: "Bonaventure",
    country: "Canada",
    iata_code: "YVB",
    _geoloc: {
      lat: 48.0711,
      lng: -65.4603,
    },
    links_count: 7,
    objectID: "6775",
    uniqueID: "b87d4dcb-8c81-40d9-aa47-19c2860ffda9",
  },
  {
    name: "Samburu South Airport",
    city: "Samburu South",
    country: "Kenya",
    iata_code: "UAS",
    _geoloc: {
      lat: 0.530583,
      lng: 37.5342,
    },
    links_count: 7,
    objectID: "6773",
    uniqueID: "ec522163-077c-4e08-bac2-dd3ed061af52",
  },
  {
    name: "Sege Airport",
    city: "Sege",
    country: "Solomon Islands",
    iata_code: "EGM",
    _geoloc: {
      lat: -8.57889,
      lng: 157.876,
    },
    links_count: 7,
    objectID: "6758",
    uniqueID: "779e49f5-e775-4b7c-a7c2-325b33446b63",
  },
  {
    name: "Tambor Airport",
    city: "Nicoya",
    country: "Costa Rica",
    iata_code: "TMU",
    _geoloc: {
      lat: 9.73852,
      lng: -85.0138,
    },
    links_count: 7,
    objectID: "6732",
    uniqueID: "0d0bf8e0-7e76-4605-b7e3-1358403c56e1",
  },
  {
    name: "Anaktuvuk Pass Airport",
    city: "Anaktuvuk Pass",
    country: "United States",
    iata_code: "AKP",
    _geoloc: {
      lat: 68.1336,
      lng: -151.743,
    },
    links_count: 7,
    objectID: "6712",
    uniqueID: "3b94f4bb-082c-4269-93b0-9135d1dbdc25",
  },
  {
    name: "Dryden Rgnl",
    city: "Dryden",
    country: "Canada",
    iata_code: "YHD",
    _geoloc: {
      lat: 49.831667,
      lng: -92.744167,
    },
    links_count: 7,
    objectID: "67",
    uniqueID: "282d079c-45fa-4c44-821d-cb38bb82c6fa",
  },
  {
    name: "Andenes",
    city: "Andoya",
    country: "Norway",
    iata_code: "ANX",
    _geoloc: {
      lat: 69.2925,
      lng: 16.144167,
    },
    links_count: 7,
    objectID: "631",
    uniqueID: "adc2d343-9ed9-46ff-9e73-efefbecbf72c",
  },
  {
    name: "Longreach Airport",
    city: "Longreach",
    country: "Australia",
    iata_code: "LRE",
    _geoloc: {
      lat: -23.4342,
      lng: 144.28,
    },
    links_count: 7,
    objectID: "6289",
    uniqueID: "3d0525fe-b69a-48c3-8e7c-670b42f78489",
  },
  {
    name: "Haines Airport",
    city: "Haines",
    country: "United States",
    iata_code: "HNS",
    _geoloc: {
      lat: 59.2438,
      lng: -135.524,
    },
    links_count: 7,
    objectID: "5963",
    uniqueID: "e4a8b2b1-f77a-4013-a25f-b2235aa0ebfc",
  },
  {
    name: "Skagway Airport",
    city: "Skagway",
    country: "United States",
    iata_code: "SGY",
    _geoloc: {
      lat: 59.4601,
      lng: -135.316,
    },
    links_count: 7,
    objectID: "5961",
    uniqueID: "1efd3417-4ad1-4ce0-a327-47388ffd4610",
  },
  {
    name: "Craig Cove Airport",
    city: "Craig Cove",
    country: "Vanuatu",
    iata_code: "CCV",
    _geoloc: {
      lat: -16.265,
      lng: 167.924,
    },
    links_count: 7,
    objectID: "5897",
    uniqueID: "cd0e84e2-ce52-4dbc-a2ef-dd68778e28ea",
  },
  {
    name: "Pikangikum Airport",
    city: "Pikangikum",
    country: "Canada",
    iata_code: "YPM",
    _geoloc: {
      lat: 51.8197,
      lng: -93.9733,
    },
    links_count: 7,
    objectID: "5521",
    uniqueID: "46453a43-1eb1-4a06-9f35-742925679767",
  },
  {
    name: "Inukjuak Airport",
    city: "Inukjuak",
    country: "Canada",
    iata_code: "YPH",
    _geoloc: {
      lat: 58.4719,
      lng: -78.0769,
    },
    links_count: 7,
    objectID: "5520",
    uniqueID: "675cf7e4-84f5-4a6b-abc3-6d73696a491e",
  },
  {
    name: "Skagen",
    city: "Stokmarknes",
    country: "Norway",
    iata_code: "SKN",
    _geoloc: {
      lat: 68.580833,
      lng: 15.026111,
    },
    links_count: 7,
    objectID: "4252",
    uniqueID: "68ed629f-37d6-4d72-a6dc-4534c9c273a1",
  },
  {
    name: "Ikaria",
    city: "Ikaria",
    country: "Greece",
    iata_code: "JIK",
    _geoloc: {
      lat: 37.682717,
      lng: 26.347061,
    },
    links_count: 7,
    objectID: "4191",
    uniqueID: "a1f5eae7-98a9-4b77-b8e3-e1051d2b456b",
  },
  {
    name: "Lanai",
    city: "Lanai",
    country: "United States",
    iata_code: "LNY",
    _geoloc: {
      lat: 20.785611,
      lng: -156.951419,
    },
    links_count: 7,
    objectID: "3851",
    uniqueID: "7fc7d736-a3f0-4d43-8fc7-f4638c6d3bc1",
  },
  {
    name: "Molokai",
    city: "Molokai",
    country: "United States",
    iata_code: "MKK",
    _geoloc: {
      lat: 21.152886,
      lng: -157.096256,
    },
    links_count: 7,
    objectID: "3705",
    uniqueID: "af9c711a-244a-4bb0-975d-c1775407faf4",
  },
  {
    name: "Fak Fak",
    city: "Fak Fak",
    country: "Indonesia",
    iata_code: "FKQ",
    _geoloc: {
      lat: -2.920192,
      lng: 132.267031,
    },
    links_count: 7,
    objectID: "3257",
    uniqueID: "f2f4ed31-416f-46c5-8205-1063ba859187",
  },
  {
    name: "Taraz",
    city: "Dzhambul",
    country: "Kazakhstan",
    iata_code: "DMB",
    _geoloc: {
      lat: 42.853611,
      lng: 71.303611,
    },
    links_count: 7,
    objectID: "2911",
    uniqueID: "1ba7e67f-4942-419a-a50b-486efc9299a8",
  },
  {
    name: "Pelotas",
    city: "Pelotas",
    country: "Brazil",
    iata_code: "PET",
    _geoloc: {
      lat: -31.718353,
      lng: -52.327689,
    },
    links_count: 7,
    objectID: "2603",
    uniqueID: "1a341630-190d-47f1-971b-f250987f417d",
  },
  {
    name: "Altamira",
    city: "Altamira",
    country: "Brazil",
    iata_code: "ATM",
    _geoloc: {
      lat: -3.253906,
      lng: -52.253978,
    },
    links_count: 7,
    objectID: "2566",
    uniqueID: "ce9d9893-2a8c-4f41-80bd-16cc6e1c22e7",
  },
  {
    name: "Bartolomeu Lisandro",
    city: "Campos",
    country: "Brazil",
    iata_code: "CAW",
    _geoloc: {
      lat: -21.698333,
      lng: -41.301669,
    },
    links_count: 7,
    objectID: "2543",
    uniqueID: "08694dfb-c94a-4871-b92b-157dcd91668c",
  },
  {
    name: "Moorea",
    city: "Moorea",
    country: "French Polynesia",
    iata_code: "MOZ",
    _geoloc: {
      lat: -17.489972,
      lng: -149.761869,
    },
    links_count: 7,
    objectID: "1992",
    uniqueID: "a5d8a54d-f9cd-42bc-a5f7-1ee382abebbb",
  },
  {
    name: "Golfito",
    city: "Golfito",
    country: "Costa Rica",
    iata_code: "GLF",
    _geoloc: {
      lat: 8.653775,
      lng: -83.180544,
    },
    links_count: 7,
    objectID: "1879",
    uniqueID: "f92aa84e-bfee-46cd-8d62-c558553acea8",
  },
  {
    name: "Konya",
    city: "Konya",
    country: "Turkey",
    iata_code: "KYA",
    _geoloc: {
      lat: 37.979,
      lng: 32.561861,
    },
    links_count: 7,
    objectID: "1691",
    uniqueID: "04e3faf8-cd70-4303-9703-17078659bc26",
  },
  {
    name: "Transilvania Targu Mures",
    city: "Tirgu Mures",
    country: "Romania",
    iata_code: "TGM",
    _geoloc: {
      lat: 46.467714,
      lng: 24.412525,
    },
    links_count: 7,
    objectID: "1662",
    uniqueID: "f1f3fd6f-c249-4dd2-bf73-f94899af8aaa",
  },
  {
    name: "Valladolid",
    city: "Valladolid",
    country: "Spain",
    iata_code: "VLL",
    _geoloc: {
      lat: 41.706111,
      lng: -4.851944,
    },
    links_count: 7,
    objectID: "1247",
    uniqueID: "fc93e821-3e15-4697-a164-5aebae2e4ef0",
  },
  {
    name: "Eldoret Intl",
    city: "Eldoret",
    country: "Kenya",
    iata_code: "EDL",
    _geoloc: {
      lat: 0.404458,
      lng: 35.238928,
    },
    links_count: 7,
    objectID: "1138",
    uniqueID: "061a9078-8a67-4ab1-84d3-cae80fc8a89a",
  },
  {
    name: "Quelimane",
    city: "Quelimane",
    country: "Mozambique",
    iata_code: "UEL",
    _geoloc: {
      lat: -17.8555,
      lng: 36.869106,
    },
    links_count: 6,
    objectID: "986",
    uniqueID: "b32b4d80-f5a8-4fda-b305-40c377619700",
  },
  {
    name: "Inhambane",
    city: "Inhambane",
    country: "Mozambique",
    iata_code: "INH",
    _geoloc: {
      lat: -23.876431,
      lng: 35.408544,
    },
    links_count: 6,
    objectID: "976",
    uniqueID: "ea77eacc-a01f-47d2-9a7a-c8b1826a16f1",
  },
  {
    name: "Lubango",
    city: "Lubango",
    country: "Angola",
    iata_code: "SDD",
    _geoloc: {
      lat: -14.924733,
      lng: 13.575022,
    },
    links_count: 6,
    objectID: "959",
    uniqueID: "db617895-4bc8-4ac3-ab8a-2498c808d90f",
  },
  {
    name: "Soyo",
    city: "Soyo",
    country: "Angola",
    iata_code: "SZA",
    _geoloc: {
      lat: -6.141086,
      lng: 12.371764,
    },
    links_count: 6,
    objectID: "958",
    uniqueID: "2d774256-6158-4f34-97c3-d537403d8cc8",
  },
  {
    name: "Menongue",
    city: "Menongue",
    country: "Angola",
    iata_code: "SPP",
    _geoloc: {
      lat: -14.657583,
      lng: 17.719833,
    },
    links_count: 6,
    objectID: "953",
    uniqueID: "78f8f87c-ea9e-419d-9f4e-2da302ece16c",
  },
  {
    name: "Huambo",
    city: "Huambo",
    country: "Angola",
    iata_code: "NOV",
    _geoloc: {
      lat: -12.808878,
      lng: 15.760547,
    },
    links_count: 6,
    objectID: "948",
    uniqueID: "179ec23f-a182-4904-9074-a6ac24403a71",
  },
  {
    name: "Toliara",
    city: "Toliara",
    country: "Madagascar",
    iata_code: "TLE",
    _geoloc: {
      lat: -23.383369,
      lng: 43.728453,
    },
    links_count: 6,
    objectID: "943",
    uniqueID: "291b28f3-9ea0-4a66-b67e-102c907c55f7",
  },
  {
    name: "Sambava",
    city: "Sambava",
    country: "Madagascar",
    iata_code: "SVB",
    _geoloc: {
      lat: -14.278611,
      lng: 50.174721,
    },
    links_count: 6,
    objectID: "933",
    uniqueID: "72f8b932-8c03-4b91-8498-98ed91f7e88e",
  },
  {
    name: "Philibert Tsiranana",
    city: "Mahajanga",
    country: "Madagascar",
    iata_code: "MJN",
    _geoloc: {
      lat: -15.667144,
      lng: 46.351828,
    },
    links_count: 6,
    objectID: "929",
    uniqueID: "1db55669-3d87-485d-ab65-2a7a37e76f56",
  },
  {
    name: "Antsirabato",
    city: "Antalaha",
    country: "Madagascar",
    iata_code: "ANM",
    _geoloc: {
      lat: -14.999411,
      lng: 50.320233,
    },
    links_count: 6,
    objectID: "927",
    uniqueID: "51343c54-b50f-4ed2-8038-ff47e93def31",
  },
  {
    name: "Sigiriya Airport",
    city: "Sigiriya",
    country: "Sri Lanka",
    iata_code: "GIU",
    _geoloc: {
      lat: 7.57212,
      lng: 80.43412,
    },
    links_count: 6,
    objectID: "9229",
    uniqueID: "c3a1da3f-c694-4615-96c7-572e5cc3557e",
  },
  {
    name: "Sainte Marie",
    city: "Sainte Marie",
    country: "Madagascar",
    iata_code: "SMS",
    _geoloc: {
      lat: -17.093889,
      lng: 49.815834,
    },
    links_count: 6,
    objectID: "920",
    uniqueID: "11d4e026-6351-49af-b9d8-16e5931edc91",
  },
  {
    name: "St Pierre Pierrefonds",
    city: "St.-pierre",
    country: "Reunion",
    iata_code: "ZSE",
    _geoloc: {
      lat: -21.320039,
      lng: 55.423581,
    },
    links_count: 6,
    objectID: "917",
    uniqueID: "c5a74bfa-5057-44ac-84b9-3c3bf9967cfa",
  },
  {
    name: "Moosonee",
    city: "Moosonee",
    country: "Canada",
    iata_code: "YMO",
    _geoloc: {
      lat: 51.291111,
      lng: -80.607778,
    },
    links_count: 6,
    objectID: "91",
    uniqueID: "ab0e3c84-f91e-4678-9631-c24eb56327da",
  },
  {
    name: "Brusselton",
    city: "Brusselton",
    country: "Australia",
    iata_code: "BQB",
    _geoloc: {
      lat: -33.687222,
      lng: 115.400278,
    },
    links_count: 6,
    objectID: "9090",
    uniqueID: "1b1c83de-292b-4180-a958-5450a18a30b2",
  },
  {
    name: "Bangui M Poko",
    city: "Bangui",
    country: "Central African Republic",
    iata_code: "BGF",
    _geoloc: {
      lat: 4.398475,
      lng: 18.518786,
    },
    links_count: 6,
    objectID: "888",
    uniqueID: "d3604434-2cee-40e5-8246-c20431ecd2c3",
  },
  {
    name: "Yongzhou Lingling Airport",
    city: "Yongzhou",
    country: "China",
    iata_code: "LLF",
    _geoloc: {
      lat: 26.345556,
      lng: 111.612222,
    },
    links_count: 6,
    objectID: "8845",
    uniqueID: "6a02e8c0-ffc0-4966-8021-ed1311c6927a",
  },
  {
    name: "Bauru-Arealva",
    city: "Bauru",
    country: "Brazil",
    iata_code: "JTC",
    _geoloc: {
      lat: -22.157778,
      lng: -49.068333,
    },
    links_count: 6,
    objectID: "8238",
    uniqueID: "e9ebbda4-d151-4aed-b6b5-0a08ae060c41",
  },
  {
    name: "Toksook Bay Airport",
    city: "Toksook Bay",
    country: "United States",
    iata_code: "OOK",
    _geoloc: {
      lat: 60.541389,
      lng: -165.087222,
    },
    links_count: 6,
    objectID: "8200",
    uniqueID: "1eebe490-62cb-4f98-a9cb-48b02aa2cd08",
  },
  {
    name: "Tianyang",
    city: "Baise",
    country: "China",
    iata_code: "AEB",
    _geoloc: {
      lat: 23.72,
      lng: 106.96,
    },
    links_count: 6,
    objectID: "8082",
    uniqueID: "a168c5e8-f1e2-422b-9018-77f057109696",
  },
  {
    name: "Mackenzie Airport",
    city: "Mackenzie British Columbia",
    country: "Canada",
    iata_code: "YZY",
    _geoloc: {
      lat: 55.29944,
      lng: -123.08333,
    },
    links_count: 6,
    objectID: "7987",
    uniqueID: "b01256f5-2598-4369-9a07-dd5dd6e277ef",
  },
  {
    name: "Ukunda Airport",
    city: "Ukunda",
    country: "Kenya",
    iata_code: "UKA",
    _geoloc: {
      lat: -4.29694,
      lng: 39.5714,
    },
    links_count: 6,
    objectID: "7976",
    uniqueID: "e167b426-e0d4-4dd7-8752-0e16c556b90f",
  },
  {
    name: "Waterloo",
    city: "Waterloo",
    country: "Canada",
    iata_code: "YKF",
    _geoloc: {
      lat: 43.460833,
      lng: -80.378611,
    },
    links_count: 6,
    objectID: "79",
    uniqueID: "835bebf3-5296-43ec-8d06-349900010163",
  },
  {
    name: "Provo Municipal Airport",
    city: "Provo",
    country: "United States",
    iata_code: "PVU",
    _geoloc: {
      lat: 40.21805555,
      lng: -111.72222222,
    },
    links_count: 6,
    objectID: "7579",
    uniqueID: "abc2aad4-bafe-49dc-8f25-3848a97193b1",
  },
  {
    name: "Zhijiang Airport",
    city: "Zhijiang",
    country: "China",
    iata_code: "HJJ",
    _geoloc: {
      lat: 27.441389,
      lng: 109.699722,
    },
    links_count: 6,
    objectID: "7519",
    uniqueID: "7cbde608-a5e0-4972-b8d7-3de5be0eca4c",
  },
  {
    name: "Liping Airport",
    city: "Liping",
    country: "China",
    iata_code: "HZH",
    _geoloc: {
      lat: 26.206,
      lng: 109.039,
    },
    links_count: 6,
    objectID: "7508",
    uniqueID: "4bae9ee7-f748-4103-a9e6-38257ddf091c",
  },
  {
    name: "Pond Inlet",
    city: "Pond Inlet",
    country: "Canada",
    iata_code: "YIO",
    _geoloc: {
      lat: 72.683334,
      lng: -77.966667,
    },
    links_count: 6,
    objectID: "75",
    uniqueID: "1e926e13-e3d3-4755-94f4-07009d0b24ab",
  },
  {
    name: "Jijiga Airport",
    city: "Jijiga",
    country: "Ethiopia",
    iata_code: "JIJ",
    _geoloc: {
      lat: 9.359722,
      lng: 42.7875,
    },
    links_count: 6,
    objectID: "7417",
    uniqueID: "8873e681-6c84-405a-8ffa-9ddec7f38a4c",
  },
  {
    name: "Joacaba Airport",
    city: "Joacaba",
    country: "Brazil",
    iata_code: "JCB",
    _geoloc: {
      lat: -27.172778,
      lng: -51.500833,
    },
    links_count: 6,
    objectID: "7384",
    uniqueID: "2e2471bf-be18-403c-9f20-20e2a0c9c0b1",
  },
  {
    name: "Dourados Airport",
    city: "Dourados",
    country: "Brazil",
    iata_code: "DOU",
    _geoloc: {
      lat: -22.220833,
      lng: -54.805833,
    },
    links_count: 6,
    objectID: "7380",
    uniqueID: "bca196f4-6217-4310-a38b-53b2ea210338",
  },
  {
    name: "Sinop Airport",
    city: "Sinop",
    country: "Brazil",
    iata_code: "OPS",
    _geoloc: {
      lat: -11.885,
      lng: -55.586,
    },
    links_count: 6,
    objectID: "7367",
    uniqueID: "9d174b8c-0ad3-4a0e-8117-9fef4ad753a9",
  },
  {
    name: "Cabo Frio International Airport",
    city: "Cabo Frio",
    country: "Brazil",
    iata_code: "CFB",
    _geoloc: {
      lat: -22.921667,
      lng: -42.074167,
    },
    links_count: 6,
    objectID: "7364",
    uniqueID: "049e98d2-10c0-4cd2-88d5-81a8e3c017db",
  },
  {
    name: "Vasteras",
    city: "Vasteras",
    country: "Sweden",
    iata_code: "VST",
    _geoloc: {
      lat: 59.589444,
      lng: 16.633611,
    },
    links_count: 6,
    objectID: "733",
    uniqueID: "c9116e3a-6563-4a0f-8e9c-243f3486bfcc",
  },
  {
    name: "Bella Bella Airport",
    city: "Bella Bella",
    country: "Canada",
    iata_code: "ZEL",
    _geoloc: {
      lat: 52.139722,
      lng: -128.063611,
    },
    links_count: 6,
    objectID: "7275",
    uniqueID: "3b4a71df-1063-4ae1-8506-c30444442176",
  },
  {
    name: "Skelleftea",
    city: "Skelleftea",
    country: "Sweden",
    iata_code: "SFT",
    _geoloc: {
      lat: 64.624772,
      lng: 21.076892,
    },
    links_count: 6,
    objectID: "726",
    uniqueID: "a24d18ca-06a3-4f83-9893-268c1a9cd5c4",
  },
  {
    name: "Havre Saint-Pierre Airport",
    city: "Havre-Saint-Pierre",
    country: "Canada",
    iata_code: "YGV",
    _geoloc: {
      lat: 50.281944,
      lng: -63.611389,
    },
    links_count: 6,
    objectID: "7255",
    uniqueID: "cf9949d0-d0c5-4458-aac8-4dc62d878df0",
  },
  {
    name: "St. George Airport",
    city: "St. George",
    country: "United States",
    iata_code: "STG",
    _geoloc: {
      lat: 56.577222,
      lng: -169.663611,
    },
    links_count: 6,
    objectID: "7247",
    uniqueID: "d7945a88-7609-457f-a3da-5f2bb28b1470",
  },
  {
    name: "Kiruna",
    city: "Kiruna",
    country: "Sweden",
    iata_code: "KRN",
    _geoloc: {
      lat: 67.821986,
      lng: 20.336764,
    },
    links_count: 6,
    objectID: "724",
    uniqueID: "00aaf68a-31a6-4186-8252-4789f8ac465c",
  },
  {
    name: "Grayling Airport",
    city: "Grayling",
    country: "United States",
    iata_code: "KGX",
    _geoloc: {
      lat: 62.894444,
      lng: -160.065,
    },
    links_count: 6,
    objectID: "7219",
    uniqueID: "1ada97d2-6312-47a7-8f0f-cbf7089070c9",
  },
  {
    name: "Tuluksak Airport",
    city: "Tuluksak",
    country: "United States",
    iata_code: "TLT",
    _geoloc: {
      lat: 61.096944,
      lng: -160.969444,
    },
    links_count: 6,
    objectID: "7218",
    uniqueID: "62ff0ea1-0c55-48dd-b3f2-0a3113a9bafe",
  },
  {
    name: "Akiak Airport",
    city: "Akiak",
    country: "United States",
    iata_code: "AKI",
    _geoloc: {
      lat: 60.902778,
      lng: -161.230556,
    },
    links_count: 6,
    objectID: "7217",
    uniqueID: "ee788914-d8a0-4750-8514-f5ed860a8ff0",
  },
  {
    name: "Pilot Station Airport",
    city: "Pilot Station",
    country: "United States",
    iata_code: "PQS",
    _geoloc: {
      lat: 61.934444,
      lng: -162.899444,
    },
    links_count: 6,
    objectID: "7216",
    uniqueID: "0d432327-e935-4bf0-8f4d-6d24e286d320",
  },
  {
    name: "Kongiganak Airport",
    city: "Kongiganak",
    country: "United States",
    iata_code: "KKH",
    _geoloc: {
      lat: 59.960833,
      lng: -162.881111,
    },
    links_count: 6,
    objectID: "7213",
    uniqueID: "8e78d0f4-9072-4363-bcae-08ee53c7b8df",
  },
  {
    name: "Klawock Airport",
    city: "Klawock",
    country: "United States",
    iata_code: "KLW",
    _geoloc: {
      lat: 55.579167,
      lng: -133.076111,
    },
    links_count: 6,
    objectID: "7205",
    uniqueID: "9d8bc029-31e2-4d17-9765-9249d156f70f",
  },
  {
    name: "Kipnuk Airport",
    city: "Kipnuk",
    country: "United States",
    iata_code: "KPN",
    _geoloc: {
      lat: 59.933056,
      lng: -164.030556,
    },
    links_count: 6,
    objectID: "7201",
    uniqueID: "db5798ee-9370-4207-b07b-aa3f3a874392",
  },
  {
    name: "Hay River",
    city: "Hay River",
    country: "Canada",
    iata_code: "YHY",
    _geoloc: {
      lat: 60.839722,
      lng: -115.782778,
    },
    links_count: 6,
    objectID: "72",
    uniqueID: "48d21a43-35e0-4e6f-b9c1-841c99c0cabb",
  },
  {
    name: "Teller Airport",
    city: "Teller",
    country: "United States",
    iata_code: "TLA",
    _geoloc: {
      lat: 65.240278,
      lng: -166.339444,
    },
    links_count: 6,
    objectID: "7186",
    uniqueID: "8ebec9a3-101d-436e-b6a8-dad3976ce24f",
  },
  {
    name: "Kobuk Airport",
    city: "Kobuk",
    country: "United States",
    iata_code: "OBU",
    _geoloc: {
      lat: 66.912222,
      lng: -156.897222,
    },
    links_count: 6,
    objectID: "7180",
    uniqueID: "82b11da6-5bbf-41a5-9463-7ce10c56aef8",
  },
  {
    name: "Metlakatla Seaplane Base",
    city: "Metakatla",
    country: "United States",
    iata_code: "MTM",
    _geoloc: {
      lat: 55.131111,
      lng: -131.578056,
    },
    links_count: 6,
    objectID: "7146",
    uniqueID: "312214ee-1335-4a42-ad46-6203c169a9ff",
  },
  {
    name: "Hoonah Airport",
    city: "Hoonah",
    country: "United States",
    iata_code: "HNH",
    _geoloc: {
      lat: 58.096111,
      lng: -135.409722,
    },
    links_count: 6,
    objectID: "7142",
    uniqueID: "9a4574f9-b9ff-410e-ae12-f1fd28e9bfa1",
  },
  {
    name: "Shungnak Airport",
    city: "Shungnak",
    country: "United States",
    iata_code: "SHG",
    _geoloc: {
      lat: 66.888056,
      lng: -157.1625,
    },
    links_count: 6,
    objectID: "7117",
    uniqueID: "773ae3c0-3c79-410b-8789-a8d46dc3205e",
  },
  {
    name: "Nulato Airport",
    city: "Nulato",
    country: "United States",
    iata_code: "NUL",
    _geoloc: {
      lat: 64.729444,
      lng: -158.074167,
    },
    links_count: 6,
    objectID: "7111",
    uniqueID: "14ba879b-a705-4fa6-8f67-1a911ab56964",
  },
  {
    name: "Kwethluk Airport",
    city: "Kwethluk",
    country: "United States",
    iata_code: "KWT",
    _geoloc: {
      lat: 60.790278,
      lng: -161.443611,
    },
    links_count: 6,
    objectID: "7095",
    uniqueID: "04c771a2-0468-4504-b8db-8432e22c8fce",
  },
  {
    name: "Eek Airport",
    city: "Eek",
    country: "United States",
    iata_code: "EEK",
    _geoloc: {
      lat: 60.213611,
      lng: -162.043889,
    },
    links_count: 6,
    objectID: "7093",
    uniqueID: "9b05a86d-8c5f-41c0-91ec-9e01db843654",
  },
  {
    name: "Shageluk Airport",
    city: "Shageluk",
    country: "United States",
    iata_code: "SHX",
    _geoloc: {
      lat: 62.692222,
      lng: -159.569167,
    },
    links_count: 6,
    objectID: "7090",
    uniqueID: "75ec87d8-3bcf-4d0c-b670-a32d0c941313",
  },
  {
    name: "Kalmar",
    city: "Kalkmar",
    country: "Sweden",
    iata_code: "KLR",
    _geoloc: {
      lat: 56.685531,
      lng: 16.287578,
    },
    links_count: 6,
    objectID: "707",
    uniqueID: "a3f117aa-718a-497d-ac64-1be6b536462d",
  },
  {
    name: "Alliance Municipal Airport",
    city: "Alliance",
    country: "United States",
    iata_code: "AIA",
    _geoloc: {
      lat: 42.053333,
      lng: -102.803889,
    },
    links_count: 6,
    objectID: "7067",
    uniqueID: "95257df4-5eb2-40d7-83ce-141abe43bd3d",
  },
  {
    name: "Chadron Municipal Airport",
    city: "Chadron",
    country: "United States",
    iata_code: "CDR",
    _geoloc: {
      lat: 42.8375,
      lng: -103.095556,
    },
    links_count: 6,
    objectID: "7066",
    uniqueID: "09cd0371-57b6-4d27-bdfa-29f9e91a6ef7",
  },
  {
    name: "Municipal",
    city: "Corozal",
    country: "Belize",
    iata_code: "CZH",
    _geoloc: {
      lat: 18.3822,
      lng: -88.4119,
    },
    links_count: 6,
    objectID: "7029",
    uniqueID: "24b8098e-a465-4402-ad07-9a72dbe43fb5",
  },
  {
    name: "Chefornak Airport",
    city: "Chefornak",
    country: "United States",
    iata_code: "CYF",
    _geoloc: {
      lat: 60.149167,
      lng: -164.285556,
    },
    links_count: 6,
    objectID: "6960",
    uniqueID: "c3b1fce7-e31d-4358-abdc-7b56f05656f0",
  },
  {
    name: "Drake Bay Airport",
    city: "Puntarenas",
    country: "Costa Rica",
    iata_code: "DRK",
    _geoloc: {
      lat: 8.71889,
      lng: -83.6417,
    },
    links_count: 6,
    objectID: "6945",
    uniqueID: "5ae83e3d-1df4-4d74-a5cd-e2ac47ce4da5",
  },
  {
    name: "Heilongjiang Mohe Airport",
    city: "Mohe County",
    country: "China",
    iata_code: "OHE",
    _geoloc: {
      lat: 52.915,
      lng: 122.427,
    },
    links_count: 6,
    objectID: "6943",
    uniqueID: "c8023a9b-afc2-49a2-a63e-126e807e3b6b",
  },
  {
    name: "Ronneby",
    city: "Ronneby",
    country: "Sweden",
    iata_code: "RNB",
    _geoloc: {
      lat: 56.266667,
      lng: 15.265,
    },
    links_count: 6,
    objectID: "685",
    uniqueID: "cd499e99-054e-46cf-b444-b1c26bd54ed7",
  },
  {
    name: "Sawyer International Airport",
    city: "Marquette",
    country: "United States",
    iata_code: "MQT",
    _geoloc: {
      lat: 46.353611,
      lng: -87.395278,
    },
    links_count: 6,
    objectID: "6838",
    uniqueID: "c3cfda39-a0c7-4f19-bcf6-1b98ad8dd219",
  },
  {
    name: "Ulukhaktok Holman",
    city: "Holman Island",
    country: "Canada",
    iata_code: "YHI",
    _geoloc: {
      lat: 70.762778,
      lng: -117.806111,
    },
    links_count: 6,
    objectID: "68",
    uniqueID: "b51f5a0e-42a5-4e41-be75-9214032dc190",
  },
  {
    name: "Mus Airport",
    city: "Mus",
    country: "Turkey",
    iata_code: "MSR",
    _geoloc: {
      lat: 38.747769,
      lng: 41.661236,
    },
    links_count: 6,
    objectID: "6782",
    uniqueID: "6b2b8164-a791-489f-986e-e4022648e18e",
  },
  {
    name: "Walvis Bay Airport",
    city: "Walvis Bay",
    country: "Namibia",
    iata_code: "WVB",
    _geoloc: {
      lat: -22.9799,
      lng: 14.6453,
    },
    links_count: 6,
    objectID: "6778",
    uniqueID: "e120ba24-cdfb-4cff-9f6d-065e2d220298",
  },
  {
    name: "Capitan Corbeta C A Curbelo International Airport",
    city: "Punta del Este",
    country: "Uruguay",
    iata_code: "PDP",
    _geoloc: {
      lat: -34.855139,
      lng: -55.094278,
    },
    links_count: 6,
    objectID: "6777",
    uniqueID: "63ffca5f-4dc0-439a-a0aa-4a8771672cb5",
  },
  {
    name: "Chaoyang Airport",
    city: "Chaoyang",
    country: "China",
    iata_code: "CHG",
    _geoloc: {
      lat: 41.5381,
      lng: 120.435,
    },
    links_count: 6,
    objectID: "6772",
    uniqueID: "7a93ada3-c988-47cb-bf16-7fe75238ba3b",
  },
  {
    name: "Changzhoudao Airport",
    city: "Wuzhou",
    country: "China",
    iata_code: "WUZ",
    _geoloc: {
      lat: 23.4567,
      lng: 111.248,
    },
    links_count: 6,
    objectID: "6744",
    uniqueID: "b8241c11-56b1-42c6-bccb-a560b7ba7450",
  },
  {
    name: "Arenal Airport",
    city: "La Fortuna/San Carlos",
    country: "Costa Rica",
    iata_code: "FON",
    _geoloc: {
      lat: 10.478,
      lng: -84.6345,
    },
    links_count: 6,
    objectID: "6731",
    uniqueID: "35c8faaf-6353-47e3-863b-f8cf7a29fe89",
  },
  {
    name: "Shishmaref Airport",
    city: "Shishmaref",
    country: "United States",
    iata_code: "SHH",
    _geoloc: {
      lat: 66.2496,
      lng: -166.089,
    },
    links_count: 6,
    objectID: "6723",
    uniqueID: "5eeb8891-c6dd-4026-80b7-ee8662112430",
  },
  {
    name: "Kivalina Airport",
    city: "Kivalina",
    country: "United States",
    iata_code: "KVL",
    _geoloc: {
      lat: 67.7362,
      lng: -164.563,
    },
    links_count: 6,
    objectID: "6719",
    uniqueID: "8245cdc3-8dcc-4c10-ac6f-84aea07078bd",
  },
  {
    name: "Kaltag Airport",
    city: "Kaltag",
    country: "United States",
    iata_code: "KAL",
    _geoloc: {
      lat: 64.3191,
      lng: -158.741,
    },
    links_count: 6,
    objectID: "6717",
    uniqueID: "c61701ba-9e3f-4df5-a882-ac4b065148a3",
  },
  {
    name: "Anvik Airport",
    city: "Anvik",
    country: "United States",
    iata_code: "ANV",
    _geoloc: {
      lat: 62.6467,
      lng: -160.191,
    },
    links_count: 6,
    objectID: "6713",
    uniqueID: "1d8a1005-f0b4-4b1b-ac27-c4fe78a7edf6",
  },
  {
    name: "Sorstokken",
    city: "Stord",
    country: "Norway",
    iata_code: "SRP",
    _geoloc: {
      lat: 59.791925,
      lng: 5.34085,
    },
    links_count: 6,
    objectID: "660",
    uniqueID: "e3bc5fdf-d67f-4e3a-baa2-ebffa287e952",
  },
  {
    name: "Longyear",
    city: "Svalbard",
    country: "Norway",
    iata_code: "LYR",
    _geoloc: {
      lat: 78.246111,
      lng: 15.465556,
    },
    links_count: 6,
    objectID: "658",
    uniqueID: "678b6320-7879-429f-ad66-019b4bafdce9",
  },
  {
    name: "Iles De La Madeleine",
    city: "Iles De La Madeleine",
    country: "Canada",
    iata_code: "YGR",
    _geoloc: {
      lat: 47.424721,
      lng: -61.778056,
    },
    links_count: 6,
    objectID: "65",
    uniqueID: "7be3318c-2d66-4329-ac44-6e8372af8756",
  },
  {
    name: "Heihe Airport",
    city: "Heihe",
    country: "China",
    iata_code: "HEK",
    _geoloc: {
      lat: 50.25,
      lng: 127.3,
    },
    links_count: 6,
    objectID: "6410",
    uniqueID: "86450197-5c70-42c7-9384-90a5d2bf686b",
  },
  {
    name: "Qamdo Bangda Airport",
    city: "Bangda",
    country: "China",
    iata_code: "BPX",
    _geoloc: {
      lat: 30.5536,
      lng: 97.1083,
    },
    links_count: 6,
    objectID: "6396",
    uniqueID: "d4db93ca-eab8-40ad-a652-b70091d8a540",
  },
  {
    name: "Zhaotong Airport",
    city: "Zhaotong",
    country: "China",
    iata_code: "ZAT",
    _geoloc: {
      lat: 27.3256,
      lng: 103.755,
    },
    links_count: 6,
    objectID: "6378",
    uniqueID: "8652dbed-ff65-45c5-ac02-a7295ef69eab",
  },
  {
    name: "Simao Airport",
    city: "Simao",
    country: "China",
    iata_code: "SYM",
    _geoloc: {
      lat: 22.7933,
      lng: 100.959,
    },
    links_count: 6,
    objectID: "6377",
    uniqueID: "98dc416c-79d6-4bb1-8bbf-88870db4e951",
  },
  {
    name: "Wynyard Airport",
    city: "Burnie",
    country: "Australia",
    iata_code: "BWT",
    _geoloc: {
      lat: -40.9989,
      lng: 145.731,
    },
    links_count: 6,
    objectID: "6338",
    uniqueID: "1959766a-ac69-43e3-b4f2-73e9b9e20153",
  },
  {
    name: "Roma Airport",
    city: "Roma",
    country: "Australia",
    iata_code: "RMA",
    _geoloc: {
      lat: -26.545,
      lng: 148.775,
    },
    links_count: 6,
    objectID: "6324",
    uniqueID: "9c84f81d-c639-4dfb-8924-29cb6ba13705",
  },
  {
    name: "Paraburdoo Airport",
    city: "Paraburdoo",
    country: "Australia",
    iata_code: "PBO",
    _geoloc: {
      lat: -23.1711,
      lng: 117.745,
    },
    links_count: 6,
    objectID: "6314",
    uniqueID: "178e0384-8055-40a3-bf4e-ca222310ceae",
  },
  {
    name: "Mornington Island Airport",
    city: "Mornington Island",
    country: "Australia",
    iata_code: "ONG",
    _geoloc: {
      lat: -16.6625,
      lng: 139.178,
    },
    links_count: 6,
    objectID: "6304",
    uniqueID: "1b3c6b7d-264f-4b6e-9308-e1ac4124aefc",
  },
  {
    name: "Moranbah Airport",
    city: "Moranbah",
    country: "Australia",
    iata_code: "MOV",
    _geoloc: {
      lat: -22.0578,
      lng: 148.077,
    },
    links_count: 6,
    objectID: "6301",
    uniqueID: "f49bac40-8d17-4afc-99ad-4264eba3f971",
  },
  {
    name: "Maningrida Airport",
    city: "Maningrida",
    country: "Australia",
    iata_code: "MNG",
    _geoloc: {
      lat: -12.0561,
      lng: 134.234,
    },
    links_count: 6,
    objectID: "6296",
    uniqueID: "860df0b8-8819-49fe-ace0-16dee0417e41",
  },
  {
    name: "Merimbula Airport",
    city: "Merimbula",
    country: "Australia",
    iata_code: "MIM",
    _geoloc: {
      lat: -36.9086,
      lng: 149.901,
    },
    links_count: 6,
    objectID: "6294",
    uniqueID: "f464e564-bb7f-440b-965b-31dff6f5707e",
  },
  {
    name: "Lord Howe Island Airport",
    city: "Lord Howe Island",
    country: "Australia",
    iata_code: "LDH",
    _geoloc: {
      lat: -31.5383,
      lng: 159.077,
    },
    links_count: 6,
    objectID: "6285",
    uniqueID: "ea5053e4-1c18-4db3-b8fb-dc659e843ff6",
  },
  {
    name: "Geraldton Airport",
    city: "Geraldton",
    country: "Australia",
    iata_code: "GET",
    _geoloc: {
      lat: -28.7961,
      lng: 114.707,
    },
    links_count: 6,
    objectID: "6268",
    uniqueID: "0a3fb87f-dd59-4209-9f57-77983a8f5717",
  },
  {
    name: "Doomadgee Airport",
    city: "Doomadgee",
    country: "Australia",
    iata_code: "DMD",
    _geoloc: {
      lat: -17.9403,
      lng: 138.822,
    },
    links_count: 6,
    objectID: "6262",
    uniqueID: "66f18d13-0873-4657-a8b2-96e4fa5aa33c",
  },
  {
    name: "Albany Airport",
    city: "Albany",
    country: "Australia",
    iata_code: "ALH",
    _geoloc: {
      lat: -34.9433,
      lng: 117.809,
    },
    links_count: 6,
    objectID: "6235",
    uniqueID: "cece0332-c454-4669-8818-b529a0f504bd",
  },
  {
    name: "Lawas Airport",
    city: "Lawas",
    country: "Malaysia",
    iata_code: "LWY",
    _geoloc: {
      lat: 4.84917,
      lng: 115.408,
    },
    links_count: 6,
    objectID: "6219",
    uniqueID: "6665bb94-e1dc-4cfc-9aab-03077a04552b",
  },
  {
    name: "Dumatubun Airport",
    city: "Langgur-Kei Islands",
    country: "Indonesia",
    iata_code: "LUV",
    _geoloc: {
      lat: -5.661619,
      lng: 132.731431,
    },
    links_count: 6,
    objectID: "6212",
    uniqueID: "c7ea2a0b-084b-45cd-8010-6de0daaddde1",
  },
  {
    name: "Barau(Kalimaru) Airport",
    city: "Tanjung Redep-Borneo Island",
    country: "Indonesia",
    iata_code: "BEJ",
    _geoloc: {
      lat: 2.155497,
      lng: 117.432256,
    },
    links_count: 6,
    objectID: "6209",
    uniqueID: "a02bc683-1821-4dc4-9544-ff99ea8b4c03",
  },
  {
    name: "Tambolaka Airport",
    city: "Waikabubak-Sumba Island",
    country: "Indonesia",
    iata_code: "TMC",
    _geoloc: {
      lat: -9.409717,
      lng: 119.244494,
    },
    links_count: 6,
    objectID: "6204",
    uniqueID: "5ce25f71-c396-4248-bab0-cc5f14b5255a",
  },
  {
    name: "La Grande Riviere",
    city: "La Grande Riviere",
    country: "Canada",
    iata_code: "YGL",
    _geoloc: {
      lat: 53.625278,
      lng: -77.704167,
    },
    links_count: 6,
    objectID: "62",
    uniqueID: "c9a9cf61-a65b-4bea-a7ab-19cf1f95aac7",
  },
  {
    name: "Pleiku Airport",
    city: "Pleiku",
    country: "Vietnam",
    iata_code: "PXU",
    _geoloc: {
      lat: 14.004522,
      lng: 108.017158,
    },
    links_count: 6,
    objectID: "6194",
    uniqueID: "925f3029-0a6c-4ac5-a63e-614687839592",
  },
  {
    name: "Phu Cat Airport",
    city: "Phucat",
    country: "Vietnam",
    iata_code: "UIH",
    _geoloc: {
      lat: 13.954986,
      lng: 109.042267,
    },
    links_count: 6,
    objectID: "6193",
    uniqueID: "36135906-cbf9-4a39-8936-0cc6f183842e",
  },
  {
    name: "Trà Nóc Airport",
    city: "Can Tho",
    country: "Vietnam",
    iata_code: "VCA",
    _geoloc: {
      lat: 10.085119,
      lng: 105.711922,
    },
    links_count: 6,
    objectID: "6191",
    uniqueID: "b11e0268-bc73-4320-bbad-ff83e8db7168",
  },
  {
    name: "Gan Island Airport",
    city: "Gan Island",
    country: "Maldives",
    iata_code: "GAN",
    _geoloc: {
      lat: -0.693342,
      lng: 73.1556,
    },
    links_count: 6,
    objectID: "6183",
    uniqueID: "0f58ee8d-c587-40c6-bb91-ca91f9b69083",
  },
  {
    name: "Andizhan Airport",
    city: "Andizhan",
    country: "Uzbekistan",
    iata_code: "AZN",
    _geoloc: {
      lat: 40.7277,
      lng: 72.294,
    },
    links_count: 6,
    objectID: "6148",
    uniqueID: "3d6f2ba2-16c0-40dc-9d08-085144d49367",
  },
  {
    name: "Pobedilovo Airport",
    city: "Kirov",
    country: "Russia",
    iata_code: "KVX",
    _geoloc: {
      lat: 58.5033,
      lng: 49.3483,
    },
    links_count: 6,
    objectID: "6142",
    uniqueID: "60f8116f-d534-459e-8eb2-a7b72f5d4603",
  },
  {
    name: "Kostanay West Airport",
    city: "Kostanay",
    country: "Kazakhstan",
    iata_code: "KSN",
    _geoloc: {
      lat: 53.206944,
      lng: 63.550278,
    },
    links_count: 6,
    objectID: "6088",
    uniqueID: "975f3d9e-ec6d-4341-bab3-ebde36e94209",
  },
  {
    name: "Juan Pablo Pérez Alfonso Airport",
    city: "El Vigía",
    country: "Venezuela",
    iata_code: "VIG",
    _geoloc: {
      lat: 8.6241,
      lng: -71.672819,
    },
    links_count: 6,
    objectID: "6072",
    uniqueID: "a57b15b4-d0bd-4037-bd75-f29049eb5c6d",
  },
  {
    name: "Obando Airport",
    city: "Puerto Inírida",
    country: "Colombia",
    iata_code: "PDA",
    _geoloc: {
      lat: 3.85,
      lng: -67.91,
    },
    links_count: 6,
    objectID: "6054",
    uniqueID: "bd883afc-4900-4928-ba20-b3816f18fc07",
  },
  {
    name: "General Rivadeneira Airport",
    city: "Esmeraldas",
    country: "Ecuador",
    iata_code: "ESM",
    _geoloc: {
      lat: 0.978519,
      lng: -79.6266,
    },
    links_count: 6,
    objectID: "6047",
    uniqueID: "41b67da3-dbfe-4570-98e3-645eececdb4f",
  },
  {
    name: "Roxas Airport",
    city: "Roxas City",
    country: "Philippines",
    iata_code: "RXS",
    _geoloc: {
      lat: 11.597669,
      lng: 122.751669,
    },
    links_count: 6,
    objectID: "6026",
    uniqueID: "0983b480-f2b0-49c6-adb8-a84cd466bc90",
  },
  {
    name: "Tuguegarao Airport",
    city: "Tuguegarao",
    country: "Philippines",
    iata_code: "TUG",
    _geoloc: {
      lat: 17.638311,
      lng: 121.730614,
    },
    links_count: 6,
    objectID: "6021",
    uniqueID: "4e9188da-0fda-494e-8926-5f0b9f632da8",
  },
  {
    name: "Surigao Airport",
    city: "Sangley Point",
    country: "Philippines",
    iata_code: "SUG",
    _geoloc: {
      lat: 9.757567,
      lng: 125.479328,
    },
    links_count: 6,
    objectID: "6016",
    uniqueID: "d1c436c5-dddd-4abe-8747-0fa03b7930a5",
  },
  {
    name: "Sacheon Air Base",
    city: "Sacheon",
    country: "South Korea",
    iata_code: "HIN",
    _geoloc: {
      lat: 35.088543,
      lng: 128.07037,
    },
    links_count: 6,
    objectID: "6007",
    uniqueID: "c652098c-c787-4b1a-9de0-347887c7a4ae",
  },
  {
    name: "Holy Cross Airport",
    city: "Holy Cross",
    country: "United States",
    iata_code: "HCR",
    _geoloc: {
      lat: 62.1883,
      lng: -159.775,
    },
    links_count: 6,
    objectID: "5962",
    uniqueID: "5f4bc463-5955-4589-a3df-42ae479190ba",
  },
  {
    name: "Gustavus Airport",
    city: "Gustavus",
    country: "United States",
    iata_code: "GST",
    _geoloc: {
      lat: 58.4253,
      lng: -135.707,
    },
    links_count: 6,
    objectID: "5960",
    uniqueID: "3b94aba8-cd7e-48d2-9fba-43e135d13967",
  },
  {
    name: "Uromiyeh Airport",
    city: "Uromiyeh",
    country: "Iran",
    iata_code: "OMH",
    _geoloc: {
      lat: 37.6681,
      lng: 45.0687,
    },
    links_count: 6,
    objectID: "5936",
    uniqueID: "5347ee0e-1eea-4f53-af3e-7954d5c9e1f9",
  },
  {
    name: "Ardabil Airport",
    city: "Ardabil",
    country: "Iran",
    iata_code: "ADU",
    _geoloc: {
      lat: 38.325678,
      lng: 48.424356,
    },
    links_count: 6,
    objectID: "5935",
    uniqueID: "c9499b6d-e8c5-4da3-9949-bc6cc2730ae2",
  },
  {
    name: "Ipota Airport",
    city: "Ipota",
    country: "Vanuatu",
    iata_code: "IPA",
    _geoloc: {
      lat: -18.8783,
      lng: 169.308,
    },
    links_count: 6,
    objectID: "5918",
    uniqueID: "9748e917-3ce7-4107-979f-00582dc7f594",
  },
  {
    name: "Gaua Island Airport",
    city: "Gaua Island",
    country: "Vanuatu",
    iata_code: "ZGU",
    _geoloc: {
      lat: -14.2181,
      lng: 167.587,
    },
    links_count: 6,
    objectID: "5906",
    uniqueID: "d2a09c7c-d94b-41ef-b85d-596a7877419d",
  },
  {
    name: "Lonorore Airport",
    city: "Lonorore",
    country: "Vanuatu",
    iata_code: "LNE",
    _geoloc: {
      lat: -15.8656,
      lng: 168.172,
    },
    links_count: 6,
    objectID: "5904",
    uniqueID: "fa6205fc-ae97-4fbc-83df-70433cd8b341",
  },
  {
    name: "Torres Airstrip",
    city: "Loh/Linua",
    country: "Vanuatu",
    iata_code: "TOH",
    _geoloc: {
      lat: -13.328,
      lng: 166.638,
    },
    links_count: 6,
    objectID: "5895",
    uniqueID: "971b983b-c290-4414-ab55-30dfa7647f8d",
  },
  {
    name: "Sola Airport",
    city: "Sola",
    country: "Vanuatu",
    iata_code: "SLH",
    _geoloc: {
      lat: -13.8517,
      lng: 167.537,
    },
    links_count: 6,
    objectID: "5894",
    uniqueID: "4850a26a-b2e4-4078-a8a7-6768c1cd6c1e",
  },
  {
    name: "Mota Lava Airport",
    city: "Ablow",
    country: "Vanuatu",
    iata_code: "MTV",
    _geoloc: {
      lat: -13.666,
      lng: 167.712,
    },
    links_count: 6,
    objectID: "5893",
    uniqueID: "698f6514-81d2-466f-8a3c-296e17a13b95",
  },
  {
    name: "Ua Huka Airport",
    city: "Ua Huka",
    country: "French Polynesia",
    iata_code: "UAH",
    _geoloc: {
      lat: -8.93611,
      lng: -139.552,
    },
    links_count: 6,
    objectID: "5892",
    uniqueID: "50dea3c4-e7a8-43a0-8e4e-25690a5eb8b5",
  },
  {
    name: "Ua Pou Airport",
    city: "Ua Pou",
    country: "French Polynesia",
    iata_code: "UAP",
    _geoloc: {
      lat: -9.35167,
      lng: -140.078,
    },
    links_count: 6,
    objectID: "5891",
    uniqueID: "afa02d25-316b-4865-8b3a-1f3b0afeba16",
  },
  {
    name: "Savusavu Airport",
    city: "Savusavu",
    country: "Fiji",
    iata_code: "SVU",
    _geoloc: {
      lat: -16.8028,
      lng: 179.341,
    },
    links_count: 6,
    objectID: "5878",
    uniqueID: "7b90f8b6-69bd-499f-811a-c322896481ad",
  },
  {
    name: "Matei Airport",
    city: "Matei",
    country: "Fiji",
    iata_code: "TVU",
    _geoloc: {
      lat: -16.6906,
      lng: -179.877,
    },
    links_count: 6,
    objectID: "5875",
    uniqueID: "8384a687-d2bf-4cf5-909d-8eefa2edef45",
  },
  {
    name: "Arthurs Town Airport",
    city: "Arthur's Town",
    country: "Bahamas",
    iata_code: "ATC",
    _geoloc: {
      lat: 24.629417,
      lng: -75.673775,
    },
    links_count: 6,
    objectID: "5857",
    uniqueID: "074e3103-8ae0-40bf-83f2-55694f377a19",
  },
  {
    name: "Dr Joaquin Balaguer International Airport",
    city: "La Isabela",
    country: "Dominican Republic",
    iata_code: "JBQ",
    _geoloc: {
      lat: 18.5725,
      lng: -69.9856,
    },
    links_count: 6,
    objectID: "5811",
    uniqueID: "469c3243-dea3-48e5-a278-d48bc911e801",
  },
  {
    name: "Kahramanmaras Airport",
    city: "Kahramanmaras",
    country: "Turkey",
    iata_code: "KCM",
    _geoloc: {
      lat: 37.539,
      lng: 36.9534,
    },
    links_count: 6,
    objectID: "5798",
    uniqueID: "d47cbf98-159f-4368-b15d-61f51a8feeca",
  },
  {
    name: "Kars Airport",
    city: "Kars",
    country: "Turkey",
    iata_code: "KSY",
    _geoloc: {
      lat: 40.562222,
      lng: 43.115002,
    },
    links_count: 6,
    objectID: "5796",
    uniqueID: "34429f26-1253-45fc-9eb3-10e631d1f20c",
  },
  {
    name: "Banja Luka International Airport",
    city: "Banja Luka",
    country: "Bosnia and Herzegovina",
    iata_code: "BNX",
    _geoloc: {
      lat: 44.941444,
      lng: 17.297501,
    },
    links_count: 6,
    objectID: "5794",
    uniqueID: "73f7104d-532d-4bdc-b198-1f8900a02841",
  },
  {
    name: "Worland Municipal Airport",
    city: "Worland",
    country: "United States",
    iata_code: "WRL",
    _geoloc: {
      lat: 43.9657,
      lng: -107.951,
    },
    links_count: 6,
    objectID: "5777",
    uniqueID: "6aac799f-6610-44d9-bf84-b022ef331efa",
  },
  {
    name: "Valdosta Regional Airport",
    city: "Valdosta",
    country: "United States",
    iata_code: "VLD",
    _geoloc: {
      lat: 30.7825,
      lng: -83.2767,
    },
    links_count: 6,
    objectID: "5776",
    uniqueID: "6ffbdeca-10c5-4f9d-b8dc-229be4eb9ec3",
  },
  {
    name: "Rock Springs Sweetwater County Airport",
    city: "Rock Springs",
    country: "United States",
    iata_code: "RKS",
    _geoloc: {
      lat: 41.5942,
      lng: -109.065,
    },
    links_count: 6,
    objectID: "5766",
    uniqueID: "b295270a-fcfd-4162-ab9f-cd40520b305d",
  },
  {
    name: "Arnold Palmer Regional Airport",
    city: "Latrobe",
    country: "United States",
    iata_code: "LBE",
    _geoloc: {
      lat: 40.2759,
      lng: -79.4048,
    },
    links_count: 6,
    objectID: "5747",
    uniqueID: "4b54d97c-9dfa-4b73-b6de-db657dde9758",
  },
  {
    name: "Brunswick Golden Isles Airport",
    city: "Brunswick",
    country: "United States",
    iata_code: "BQK",
    _geoloc: {
      lat: 31.2588,
      lng: -81.4665,
    },
    links_count: 6,
    objectID: "5725",
    uniqueID: "65978684-00f4-49c9-bc4e-73fcbe247a36",
  },
  {
    name: "Watertown Regional Airport",
    city: "Watertown",
    country: "United States",
    iata_code: "ATY",
    _geoloc: {
      lat: 44.914,
      lng: -97.1547,
    },
    links_count: 6,
    objectID: "5721",
    uniqueID: "e5132091-e5dc-426f-bca0-12ef62e440bc",
  },
  {
    name: "La Abraq Airport",
    city: "Al Bayda'",
    country: "Libya",
    iata_code: "LAQ",
    _geoloc: {
      lat: 32.788673,
      lng: 21.964333,
    },
    links_count: 6,
    objectID: "5699",
    uniqueID: "10407f85-ae35-45cb-82d4-7ccce446f29d",
  },
  {
    name: "Mogador Airport",
    city: "Essadouira",
    country: "Morocco",
    iata_code: "ESU",
    _geoloc: {
      lat: 31.3975,
      lng: -9.681667,
    },
    links_count: 6,
    objectID: "5671",
    uniqueID: "dcd6cbb5-177f-4920-9ba8-c72a76913912",
  },
  {
    name: "Dakhla Airport",
    city: "Dakhla",
    country: "Western Sahara",
    iata_code: "VIL",
    _geoloc: {
      lat: 23.7183,
      lng: -15.932,
    },
    links_count: 6,
    objectID: "5670",
    uniqueID: "8501121d-fde5-4c2f-b638-fa3ff52f4259",
  },
  {
    name: "Eros Airport",
    city: "Windhoek",
    country: "Namibia",
    iata_code: "ERS",
    _geoloc: {
      lat: -22.6122,
      lng: 17.0804,
    },
    links_count: 6,
    objectID: "5645",
    uniqueID: "838d6457-9a3c-4012-ac05-5a0d4744e298",
  },
  {
    name: "Ängelholm-Helsingborg Airport",
    city: "Ängelholm",
    country: "Sweden",
    iata_code: "AGH",
    _geoloc: {
      lat: 56.2961,
      lng: 12.8471,
    },
    links_count: 6,
    objectID: "5597",
    uniqueID: "1f27616c-b644-4952-afe4-6fd329c2f43c",
  },
  {
    name: "Östersund Airport",
    city: "Östersund",
    country: "Sweden",
    iata_code: "OSD",
    _geoloc: {
      lat: 63.1944,
      lng: 14.5003,
    },
    links_count: 6,
    objectID: "5593",
    uniqueID: "dea88208-4c4e-4704-bddf-443460f0862a",
  },
  {
    name: "Bydgoszcz Ignacy Jan Paderewski Airport",
    city: "Bydgoszcz",
    country: "Poland",
    iata_code: "BZG",
    _geoloc: {
      lat: 53.0968,
      lng: 17.9777,
    },
    links_count: 6,
    objectID: "5591",
    uniqueID: "83720d8d-75cd-44c5-a365-8f01c78eb87d",
  },
  {
    name: "Svartnes Airport",
    city: "Vardø",
    country: "Norway",
    iata_code: "VAW",
    _geoloc: {
      lat: 70.355392,
      lng: 31.044889,
    },
    links_count: 6,
    objectID: "5589",
    uniqueID: "b1f70f3f-b3ea-4c43-bab8-7b64bdfc4fac",
  },
  {
    name: "Svolvær Helle Airport",
    city: "Svolvær",
    country: "Norway",
    iata_code: "SVJ",
    _geoloc: {
      lat: 68.2433,
      lng: 14.6692,
    },
    links_count: 6,
    objectID: "5587",
    uniqueID: "e15664de-b5f9-4213-86a3-52f8f3bb8746",
  },
  {
    name: "Ryum Airport",
    city: "Rørvik",
    country: "Norway",
    iata_code: "RVK",
    _geoloc: {
      lat: 64.8383,
      lng: 11.1461,
    },
    links_count: 6,
    objectID: "5583",
    uniqueID: "435ae40d-e327-44bb-9dd8-4130d1e289f7",
  },
  {
    name: "Namsos Høknesøra Airport",
    city: "Namsos",
    country: "Norway",
    iata_code: "OSY",
    _geoloc: {
      lat: 64.4722,
      lng: 11.5786,
    },
    links_count: 6,
    objectID: "5581",
    uniqueID: "6fa706f7-8804-44b1-b950-f0bcc0a6087a",
  },
  {
    name: "Leknes Airport",
    city: "Leknes",
    country: "Norway",
    iata_code: "LKN",
    _geoloc: {
      lat: 68.1525,
      lng: 13.6094,
    },
    links_count: 6,
    objectID: "5580",
    uniqueID: "0902d907-eae5-4ce6-9f9f-25289969bd30",
  },
  {
    name: "Papa Westray Airport",
    city: "Papa Westray",
    country: "United Kingdom",
    iata_code: "PPW",
    _geoloc: {
      lat: 59.3517,
      lng: -2.90028,
    },
    links_count: 6,
    objectID: "5567",
    uniqueID: "499588b5-0f03-41d5-a57c-222eff59248e",
  },
  {
    name: "North Ronaldsay Airport",
    city: "North Ronaldsay",
    country: "United Kingdom",
    iata_code: "NRL",
    _geoloc: {
      lat: 59.3675,
      lng: -2.43444,
    },
    links_count: 6,
    objectID: "5566",
    uniqueID: "1c55776a-746b-4d48-90ce-2aa736fc9fab",
  },
  {
    name: "Sandy Lake Airport",
    city: "Sandy Lake",
    country: "Canada",
    iata_code: "ZSJ",
    _geoloc: {
      lat: 53.0642,
      lng: -93.3444,
    },
    links_count: 6,
    objectID: "5548",
    uniqueID: "dfa79192-a1c9-48ec-8990-ea34c93fc37e",
  },
  {
    name: "Sanikiluaq Airport",
    city: "Sanikiluaq",
    country: "Canada",
    iata_code: "YSK",
    _geoloc: {
      lat: 56.5378,
      lng: -79.2467,
    },
    links_count: 6,
    objectID: "5529",
    uniqueID: "5f449b9a-c476-4025-bc6c-caf1cd18bfa9",
  },
  {
    name: "Umiujaq Airport",
    city: "Umiujaq",
    country: "Canada",
    iata_code: "YUD",
    _geoloc: {
      lat: 56.5361,
      lng: -76.5183,
    },
    links_count: 6,
    objectID: "5515",
    uniqueID: "96dcc970-cb3a-4ca9-8090-bf7f9b7f7fdf",
  },
  {
    name: "Chapais Airport",
    city: "Chibougamau",
    country: "Canada",
    iata_code: "YMT",
    _geoloc: {
      lat: 49.7719,
      lng: -74.5281,
    },
    links_count: 6,
    objectID: "5514",
    uniqueID: "5b741242-fa68-45ff-a533-3e78426c8e43",
  },
  {
    name: "Aupaluk Airport",
    city: "Aupaluk",
    country: "Canada",
    iata_code: "YPJ",
    _geoloc: {
      lat: 59.2967,
      lng: -69.5997,
    },
    links_count: 6,
    objectID: "5508",
    uniqueID: "9f2c2366-1cef-4897-aa73-868de4d7ec69",
  },
  {
    name: "Fort Hope Airport",
    city: "Fort Hope",
    country: "Canada",
    iata_code: "YFH",
    _geoloc: {
      lat: 51.5619,
      lng: -87.9078,
    },
    links_count: 6,
    objectID: "5491",
    uniqueID: "c9e80b49-c8d6-458b-8eb7-dbd1d6958997",
  },
  {
    name: "Lourdes De Blanc Sablon Airport",
    city: "Lourdes-De-Blanc-Sablon",
    country: "Canada",
    iata_code: "YBX",
    _geoloc: {
      lat: 51.4436,
      lng: -57.1853,
    },
    links_count: 6,
    objectID: "5485",
    uniqueID: "082e3950-6ec7-4156-bf51-fe5cda15473d",
  },
  {
    name: "Kangirsuk Airport",
    city: "Kangirsuk",
    country: "Canada",
    iata_code: "YKG",
    _geoloc: {
      lat: 60.0272,
      lng: -69.9992,
    },
    links_count: 6,
    objectID: "5481",
    uniqueID: "508b715d-d5fc-4f61-b493-023afbbc1bf0",
  },
  {
    name: "Sisimiut Airport",
    city: "Sisimiut",
    country: "Greenland",
    iata_code: "JHS",
    _geoloc: {
      lat: 66.9513,
      lng: -53.7293,
    },
    links_count: 6,
    objectID: "5447",
    uniqueID: "d39660bd-a522-4199-9cb5-ec0608c8b807",
  },
  {
    name: "Qaanaaq Airport",
    city: "Qaanaaq",
    country: "Greenland",
    iata_code: "NAQ",
    _geoloc: {
      lat: 77.4886,
      lng: -69.3887,
    },
    links_count: 6,
    objectID: "5446",
    uniqueID: "5ff9bf45-6c04-4d94-beff-cb13ea5412d5",
  },
  {
    name: "Vanimo Airport",
    city: "Vanimo",
    country: "Papua New Guinea",
    iata_code: "VAI",
    _geoloc: {
      lat: -2.69717,
      lng: 141.302,
    },
    links_count: 6,
    objectID: "5436",
    uniqueID: "8250b5a6-f647-401c-afd8-3741d1051c5b",
  },
  {
    name: "Tari Airport",
    city: "Tari",
    country: "Papua New Guinea",
    iata_code: "TIZ",
    _geoloc: {
      lat: -5.845,
      lng: 142.948,
    },
    links_count: 6,
    objectID: "5433",
    uniqueID: "dd1b5667-9b2f-477c-9e9d-d3bd5a965fca",
  },
  {
    name: "Moro Airport",
    city: "Moro",
    country: "Papua New Guinea",
    iata_code: "MXH",
    _geoloc: {
      lat: -6.36333,
      lng: 143.238,
    },
    links_count: 6,
    objectID: "5431",
    uniqueID: "f523dc9f-2f21-4dd7-bf6b-5ad7aaa7734f",
  },
  {
    name: "Ngorangora Airport",
    city: "Kirakira",
    country: "Solomon Islands",
    iata_code: "IRA",
    _geoloc: {
      lat: -10.4497,
      lng: 161.898,
    },
    links_count: 6,
    objectID: "5409",
    uniqueID: "6f7968ee-a394-4d09-a06c-94c763923bb8",
  },
  {
    name: "Durham Tees Valley Airport",
    city: "Teesside",
    country: "United Kingdom",
    iata_code: "MME",
    _geoloc: {
      lat: 54.509189,
      lng: -1.429406,
    },
    links_count: 6,
    objectID: "522",
    uniqueID: "e6f781c1-bae0-46fb-bb5a-e730c884b8da",
  },
  {
    name: "Pori",
    city: "Pori",
    country: "Finland",
    iata_code: "POR",
    _geoloc: {
      lat: 61.461686,
      lng: 21.799983,
    },
    links_count: 6,
    objectID: "448",
    uniqueID: "9ed6534f-3c6e-4060-9c70-1461696c8f2d",
  },
  {
    name: "Lappeenranta",
    city: "Lappeenranta",
    country: "Finland",
    iata_code: "LPP",
    _geoloc: {
      lat: 61.044553,
      lng: 28.144397,
    },
    links_count: 6,
    objectID: "441",
    uniqueID: "b2d4319e-64db-4b1d-b607-f1d495d27afb",
  },
  {
    name: "Arcata",
    city: "Arcata CA",
    country: "United States",
    iata_code: "ACV",
    _geoloc: {
      lat: 40.978111,
      lng: -124.108611,
    },
    links_count: 6,
    objectID: "4384",
    uniqueID: "32b0b44f-3fa6-4b19-b097-8feef660e38a",
  },
  {
    name: "Uraj",
    city: "Uraj",
    country: "Russia",
    iata_code: "URJ",
    _geoloc: {
      lat: 60.1,
      lng: 64.83,
    },
    links_count: 6,
    objectID: "4377",
    uniqueID: "1b4e4fbf-397f-4091-8ff8-d46d767c6824",
  },
  {
    name: "Vityazevo",
    city: "Anapa",
    country: "Russia",
    iata_code: "AAQ",
    _geoloc: {
      lat: 45.002097,
      lng: 37.347272,
    },
    links_count: 6,
    objectID: "4353",
    uniqueID: "9b9db4c0-d524-470f-867e-8d9f1cb3b4dd",
  },
  {
    name: "Kruunupyy",
    city: "Kruunupyy",
    country: "Finland",
    iata_code: "KOK",
    _geoloc: {
      lat: 63.721172,
      lng: 23.143131,
    },
    links_count: 6,
    objectID: "435",
    uniqueID: "f610c4c3-0434-46e6-ab02-7475b1d80a2a",
  },
  {
    name: "ISLES OF SCILLY",
    city: "ST MARY\\\\'S",
    country: "United Kingdom",
    iata_code: "ISC",
    _geoloc: {
      lat: 49.919,
      lng: -6.3075,
    },
    links_count: 6,
    objectID: "4347",
    uniqueID: "ec01ed0c-0808-4d8e-bf3e-b239ae421208",
  },
  {
    name: "Gillette-Campbell County Airport",
    city: "Gillette",
    country: "United States",
    iata_code: "GCC",
    _geoloc: {
      lat: 44.3489167,
      lng: -105.5393611,
    },
    links_count: 6,
    objectID: "4296",
    uniqueID: "ec92078f-fc28-44d8-8782-d1685c1f7cd4",
  },
  {
    name: "Golden Triangle Regional Airport",
    city: "Columbus Mississippi",
    country: "United States",
    iata_code: "GTR",
    _geoloc: {
      lat: 33.450333,
      lng: -88.591361,
    },
    links_count: 6,
    objectID: "4273",
    uniqueID: "816c2a3e-f903-4c61-b5eb-744dd0c3daf3",
  },
  {
    name: "Columbus Metropolitan Airport",
    city: "Columbus",
    country: "United States",
    iata_code: "CSG",
    _geoloc: {
      lat: 32.5163333,
      lng: -84.9388611,
    },
    links_count: 6,
    objectID: "4255",
    uniqueID: "8c608609-03cb-4e4e-837b-ed9740f35bb3",
  },
  {
    name: "Busuanga",
    city: "Busuanga",
    country: "Philippines",
    iata_code: "USU",
    _geoloc: {
      lat: 12.121458,
      lng: 120.100031,
    },
    links_count: 6,
    objectID: "4199",
    uniqueID: "3dc1dd2e-4196-4e57-953d-210810fb4b9b",
  },
  {
    name: "Napier",
    city: "NAPIER",
    country: "New Zealand",
    iata_code: "NPE",
    _geoloc: {
      lat: -39.465833,
      lng: 176.87,
    },
    links_count: 6,
    objectID: "4095",
    uniqueID: "893baae3-72b9-4e3d-aefd-c949c8cf651b",
  },
  {
    name: "Nauru Intl",
    city: "Nauru",
    country: "Nauru",
    iata_code: "INU",
    _geoloc: {
      lat: -0.547458,
      lng: 166.9191,
    },
    links_count: 6,
    objectID: "4076",
    uniqueID: "c9c291de-d6e1-43a1-a374-54cd2f956f44",
  },
  {
    name: "La Crosse Municipal",
    city: "La Crosse",
    country: "United States",
    iata_code: "LSE",
    _geoloc: {
      lat: 43.878986,
      lng: -91.256711,
    },
    links_count: 6,
    objectID: "4044",
    uniqueID: "d84a58d3-ad65-47be-9e81-dee90788c1e4",
  },
  {
    name: "Mundo Maya International",
    city: "Flores",
    country: "Guatemala",
    iata_code: "FRS",
    _geoloc: {
      lat: 16.913819,
      lng: -89.866383,
    },
    links_count: 6,
    objectID: "4024",
    uniqueID: "5ed2be8a-4113-4865-9207-539c7966b31b",
  },
  {
    name: "Aasiaat",
    city: "Aasiaat",
    country: "Greenland",
    iata_code: "JEG",
    _geoloc: {
      lat: 68.7,
      lng: -52.75,
    },
    links_count: 6,
    objectID: "3997",
    uniqueID: "bb752b74-f09a-4cc1-beac-72da0b040acd",
  },
  {
    name: "Tagbilaran",
    city: "Tagbilaran",
    country: "Philippines",
    iata_code: "TAG",
    _geoloc: {
      lat: 9.66408056,
      lng: 123.853247,
    },
    links_count: 6,
    objectID: "3994",
    uniqueID: "68e98c03-e9ef-49f6-a312-fb8728a3b59a",
  },
  {
    name: "Palanga Intl",
    city: "Palanga",
    country: "Lithuania",
    iata_code: "PLQ",
    _geoloc: {
      lat: 55.973228,
      lng: 21.093856,
    },
    links_count: 6,
    objectID: "3958",
    uniqueID: "685d1a5c-2ed1-444c-9fb7-8f9f837c6b32",
  },
  {
    name: "Ubon Ratchathani",
    city: "Ubon Ratchathani",
    country: "Thailand",
    iata_code: "UBP",
    _geoloc: {
      lat: 15.251278,
      lng: 104.870231,
    },
    links_count: 6,
    objectID: "3937",
    uniqueID: "ed2a3ab9-7fa9-4433-8b90-920a2a0f0d9e",
  },
  {
    name: "Muhammad Salahuddin",
    city: "Bima",
    country: "Indonesia",
    iata_code: "BMU",
    _geoloc: {
      lat: -8.539647,
      lng: 118.687322,
    },
    links_count: 6,
    objectID: "3924",
    uniqueID: "21f8dad4-da27-4c88-9b16-2b57ae3fc5c3",
  },
  {
    name: "Tjilik Riwut",
    city: "Palangkaraya",
    country: "Indonesia",
    iata_code: "PKY",
    _geoloc: {
      lat: -2.225128,
      lng: 113.942661,
    },
    links_count: 6,
    objectID: "3911",
    uniqueID: "1ab7d363-94db-4d4d-b71c-83df9f8b18bc",
  },
  {
    name: "Kijang",
    city: "Tanjung Pinang",
    country: "Indonesia",
    iata_code: "TNJ",
    _geoloc: {
      lat: 0.922683,
      lng: 104.532311,
    },
    links_count: 6,
    objectID: "3906",
    uniqueID: "20d25de4-ca12-48e5-8f13-fb446046e821",
  },
  {
    name: "Abdul Rachman Saleh",
    city: "Malang",
    country: "Indonesia",
    iata_code: "MLG",
    _geoloc: {
      lat: -7.926556,
      lng: 112.714514,
    },
    links_count: 6,
    objectID: "3894",
    uniqueID: "2f693fe7-cbbe-412c-96d8-adf47c361da3",
  },
  {
    name: "Natrona Co Intl",
    city: "Casper",
    country: "United States",
    iata_code: "CPR",
    _geoloc: {
      lat: 42.908,
      lng: -106.464417,
    },
    links_count: 6,
    objectID: "3872",
    uniqueID: "14a4bba7-0e88-4d7c-a3d1-4e010f93dc6e",
  },
  {
    name: "Unalaska",
    city: "Unalaska",
    country: "United States",
    iata_code: "DUT",
    _geoloc: {
      lat: 53.900139,
      lng: -166.5435,
    },
    links_count: 6,
    objectID: "3860",
    uniqueID: "8241bb93-1956-4621-8796-2fde168f67d2",
  },
  {
    name: "Sitka Rocky Gutierrez",
    city: "Sitka",
    country: "United States",
    iata_code: "SIT",
    _geoloc: {
      lat: 57.047138,
      lng: -135.361611,
    },
    links_count: 6,
    objectID: "3856",
    uniqueID: "5c1718bb-4bb8-499c-8026-6c6ab180f485",
  },
  {
    name: "Tyler Pounds Rgnl",
    city: "Tyler",
    country: "United States",
    iata_code: "TYR",
    _geoloc: {
      lat: 32.354139,
      lng: -95.402386,
    },
    links_count: 6,
    objectID: "3848",
    uniqueID: "00c0e327-2b84-439a-8534-67218de6b6a0",
  },
  {
    name: "Dothan Rgnl",
    city: "Dothan",
    country: "United States",
    iata_code: "DHN",
    _geoloc: {
      lat: 31.321339,
      lng: -85.449628,
    },
    links_count: 6,
    objectID: "3739",
    uniqueID: "59e9d0a1-84a4-4f3b-89d6-9d403d1b13a8",
  },
  {
    name: "Brownsville South Padre Island Intl",
    city: "Brownsville",
    country: "United States",
    iata_code: "BRO",
    _geoloc: {
      lat: 25.906833,
      lng: -97.425861,
    },
    links_count: 6,
    objectID: "3738",
    uniqueID: "a4cc2557-66aa-4fcd-8624-a5f1653b8c04",
  },
  {
    name: "Easterwood Fld",
    city: "College Station",
    country: "United States",
    iata_code: "CLL",
    _geoloc: {
      lat: 30.588583,
      lng: -96.363833,
    },
    links_count: 6,
    objectID: "3715",
    uniqueID: "8e52d757-99c5-4efc-a451-8c461b65209c",
  },
  {
    name: "Cincinnati Muni Lunken Fld",
    city: "Cincinnati",
    country: "United States",
    iata_code: "LUK",
    _geoloc: {
      lat: 39.103333,
      lng: -84.418611,
    },
    links_count: 6,
    objectID: "3681",
    uniqueID: "8fc59deb-32e9-45c8-a039-a74a1b792561",
  },
  {
    name: "Kenai Muni",
    city: "Kenai",
    country: "United States",
    iata_code: "ENA",
    _geoloc: {
      lat: 60.573111,
      lng: -151.245,
    },
    links_count: 6,
    objectID: "3628",
    uniqueID: "693b9983-8437-47fc-a4c6-aaa82d1beead",
  },
  {
    name: "Merle K Mudhole Smith",
    city: "Cordova",
    country: "United States",
    iata_code: "CDV",
    _geoloc: {
      lat: 60.491778,
      lng: -145.477556,
    },
    links_count: 6,
    objectID: "3610",
    uniqueID: "72780b12-5e6e-4efe-9341-dc14d5e05993",
  },
  {
    name: "Yuma Mcas Yuma Intl",
    city: "Yuma",
    country: "United States",
    iata_code: "YUM",
    _geoloc: {
      lat: 32.656578,
      lng: -114.60598,
    },
    links_count: 6,
    objectID: "3596",
    uniqueID: "5d04f2ef-fcd2-4978-b276-3bba99da0877",
  },
  {
    name: "Lincoln",
    city: "Lincoln",
    country: "United States",
    iata_code: "LNK",
    _geoloc: {
      lat: 40.850971,
      lng: -96.75925,
    },
    links_count: 6,
    objectID: "3543",
    uniqueID: "0a2b86ab-5496-4065-bf0e-d3182241bedd",
  },
  {
    name: "Lake Charles Rgnl",
    city: "Lake Charles",
    country: "United States",
    iata_code: "LCH",
    _geoloc: {
      lat: 30.126112,
      lng: -93.223335,
    },
    links_count: 6,
    objectID: "3513",
    uniqueID: "fddf331e-42cb-4ccd-8b2a-18ffc8cc9563",
  },
  {
    name: "Huron Rgnl",
    city: "Huron",
    country: "United States",
    iata_code: "HON",
    _geoloc: {
      lat: 44.3852,
      lng: -98.228542,
    },
    links_count: 6,
    objectID: "3491",
    uniqueID: "54c1a9e2-fe08-4f9b-9c53-3eca48905d56",
  },
  {
    name: "Dillingham",
    city: "Dillingham",
    country: "United States",
    iata_code: "DLG",
    _geoloc: {
      lat: 59.044667,
      lng: -158.5055,
    },
    links_count: 6,
    objectID: "3460",
    uniqueID: "bbdafa64-bfb7-48e6-846a-91c15d5795e8",
  },
  {
    name: "St Paul Island",
    city: "St. Paul Island",
    country: "United States",
    iata_code: "SNP",
    _geoloc: {
      lat: 57.167333,
      lng: -170.220444,
    },
    links_count: 6,
    objectID: "3426",
    uniqueID: "9cfbeca1-cf2e-4389-a06e-8e634a0410b2",
  },
  {
    name: "Wagga Wagga",
    city: "Wagga Wagga",
    country: "Australia",
    iata_code: "WGA",
    _geoloc: {
      lat: -35.165278,
      lng: 147.466389,
    },
    links_count: 6,
    objectID: "3363",
    uniqueID: "89580f78-ce0f-43fc-b158-0f9bf54603b6",
  },
  {
    name: "Dubbo",
    city: "Dubbo",
    country: "Australia",
    iata_code: "DBO",
    _geoloc: {
      lat: -32.216667,
      lng: 148.574722,
    },
    links_count: 6,
    objectID: "3358",
    uniqueID: "2de4aff3-a9dc-4ada-b6b1-2b8078c735cf",
  },
  {
    name: "Learmonth",
    city: "Learmonth",
    country: "Australia",
    iata_code: "LEA",
    _geoloc: {
      lat: -22.235556,
      lng: 114.088611,
    },
    links_count: 6,
    objectID: "3348",
    uniqueID: "1839e2fb-30d5-4460-8bb2-fe65ce2d3e39",
  },
  {
    name: "Proserpine Whitsunday Coast",
    city: "Prosserpine",
    country: "Australia",
    iata_code: "PPP",
    _geoloc: {
      lat: -20.495,
      lng: 148.552222,
    },
    links_count: 6,
    objectID: "3328",
    uniqueID: "f3b8d42b-9292-4f55-a48f-f7fbaf944677",
  },
  {
    name: "Mopah",
    city: "Merauke",
    country: "Indonesia",
    iata_code: "MKQ",
    _geoloc: {
      lat: -8.520294,
      lng: 140.418453,
    },
    links_count: 6,
    objectID: "3246",
    uniqueID: "7113dae5-f793-4ea6-86d5-783d09eb77b3",
  },
  {
    name: "Nabire",
    city: "Nabire",
    country: "Indonesia",
    iata_code: "NBX",
    _geoloc: {
      lat: -3.368183,
      lng: 135.496406,
    },
    links_count: 6,
    objectID: "3242",
    uniqueID: "45b8f037-c92d-41fc-9f3c-3b15dfd774ce",
  },
  {
    name: "Sittwe",
    city: "Sittwe",
    country: "Burma",
    iata_code: "AKY",
    _geoloc: {
      lat: 20.132708,
      lng: 92.872628,
    },
    links_count: 6,
    objectID: "3235",
    uniqueID: "28c5c129-f34b-41d8-9ff6-d0d72d4f23c4",
  },
  {
    name: "Phitsanulok",
    city: "Phitsanulok",
    country: "Thailand",
    iata_code: "PHS",
    _geoloc: {
      lat: 16.782939,
      lng: 100.279122,
    },
    links_count: 6,
    objectID: "3170",
    uniqueID: "169afedf-4e35-4a3f-bd7a-a233e5ce3ea3",
  },
  {
    name: "Rajahmundry",
    city: "Rajahmundry",
    country: "India",
    iata_code: "RJA",
    _geoloc: {
      lat: 17.110361,
      lng: 81.818208,
    },
    links_count: 6,
    objectID: "3148",
    uniqueID: "715f2a9f-66d2-445b-a674-24ad41a95678",
  },
  {
    name: "Vijayawada",
    city: "Vijayawada",
    country: "India",
    iata_code: "VGA",
    _geoloc: {
      lat: 16.530433,
      lng: 80.796847,
    },
    links_count: 6,
    objectID: "3134",
    uniqueID: "195f9ed1-50a0-4dc2-ac58-296c42e3be36",
  },
  {
    name: "Savannakhet",
    city: "Savannakhet",
    country: "Laos",
    iata_code: "ZVK",
    _geoloc: {
      lat: 16.556594,
      lng: 104.759531,
    },
    links_count: 6,
    objectID: "3118",
    uniqueID: "6878f42f-a835-4d43-b242-5eac6952a03b",
  },
  {
    name: "Jessore",
    city: "Jessore",
    country: "Bangladesh",
    iata_code: "JSR",
    _geoloc: {
      lat: 23.1838,
      lng: 89.160833,
    },
    links_count: 6,
    objectID: "3071",
    uniqueID: "06ac7760-7152-43e4-8f04-66f45831af1c",
  },
  {
    name: "Coxs Bazar",
    city: "Cox's Bazar",
    country: "Bangladesh",
    iata_code: "CXB",
    _geoloc: {
      lat: 21.452194,
      lng: 91.963889,
    },
    links_count: 6,
    objectID: "3068",
    uniqueID: "023d6bc6-3c19-4cb4-8a93-00bbb5359b70",
  },
  {
    name: "Surat",
    city: "Surat",
    country: "India",
    iata_code: "STV",
    _geoloc: {
      lat: 21.114061,
      lng: 72.741792,
    },
    links_count: 6,
    objectID: "3022",
    uniqueID: "a4ebd05a-c847-4057-b4a9-112b18aa108d",
  },
  {
    name: "Rajkot",
    city: "Rajkot",
    country: "India",
    iata_code: "RAJ",
    _geoloc: {
      lat: 22.309183,
      lng: 70.779525,
    },
    links_count: 6,
    objectID: "3019",
    uniqueID: "aefab48b-e6a2-4f12-bf3e-8a534487b5e2",
  },
  {
    name: "Jabalpur",
    city: "Jabalpur",
    country: "India",
    iata_code: "JLR",
    _geoloc: {
      lat: 23.177817,
      lng: 80.052047,
    },
    links_count: 6,
    objectID: "3009",
    uniqueID: "dd4e3c1b-164d-46d4-a910-b5e47d3f1347",
  },
  {
    name: "Nefta",
    city: "Tozeur",
    country: "Tunisia",
    iata_code: "TOE",
    _geoloc: {
      lat: 33.939722,
      lng: 8.110556,
    },
    links_count: 6,
    objectID: "296",
    uniqueID: "bfc2d5a1-e3e7-4c1e-8b94-e6b7d2fcdf2d",
  },
  {
    name: "Kemerovo",
    city: "Kemorovo",
    country: "Russia",
    iata_code: "KEJ",
    _geoloc: {
      lat: 55.270094,
      lng: 86.107208,
    },
    links_count: 6,
    objectID: "2957",
    uniqueID: "2f0b2f44-0a93-4616-a68e-5535a377875f",
  },
  {
    name: "Barnaul",
    city: "Barnaul",
    country: "Russia",
    iata_code: "BAX",
    _geoloc: {
      lat: 53.363775,
      lng: 83.538533,
    },
    links_count: 6,
    objectID: "2956",
    uniqueID: "0eae7b90-ad12-41e8-b4c5-b135971f7bea",
  },
  {
    name: "Semipalatinsk",
    city: "Semiplatinsk",
    country: "Kazakhstan",
    iata_code: "PLX",
    _geoloc: {
      lat: 50.3513,
      lng: 80.2344,
    },
    links_count: 6,
    objectID: "2918",
    uniqueID: "b8ad300c-6069-4bfe-b0a8-5d7a1f2252f7",
  },
  {
    name: "Pavlodar",
    city: "Pavlodar",
    country: "Kazakhstan",
    iata_code: "PWQ",
    _geoloc: {
      lat: 52.195,
      lng: 77.073889,
    },
    links_count: 6,
    objectID: "2917",
    uniqueID: "5cec2ee8-692c-46b2-84a8-124aceccd3fe",
  },
  {
    name: "Uralsk",
    city: "Uralsk",
    country: "Kazakhstan",
    iata_code: "URA",
    _geoloc: {
      lat: 51.150833,
      lng: 51.543056,
    },
    links_count: 6,
    objectID: "2916",
    uniqueID: "1da70c06-c179-405f-943b-45ca053fece3",
  },
  {
    name: "Padre Aldamiz",
    city: "Puerto Maldonado",
    country: "Peru",
    iata_code: "PEM",
    _geoloc: {
      lat: -12.613611,
      lng: -69.228611,
    },
    links_count: 6,
    objectID: "2808",
    uniqueID: "0ba9e849-02e0-45b6-b027-5dfff287ab0a",
  },
  {
    name: "Antonio Narino",
    city: "Pasto",
    country: "Colombia",
    iata_code: "PSO",
    _geoloc: {
      lat: 1.396247,
      lng: -77.291478,
    },
    links_count: 6,
    objectID: "2742",
    uniqueID: "fdb499f0-44ce-4939-b88c-34347b0c8baa",
  },
  {
    name: "Puerto Carreno",
    city: "Puerto Carreno",
    country: "Colombia",
    iata_code: "PCR",
    _geoloc: {
      lat: 6.184717,
      lng: -67.493164,
    },
    links_count: 6,
    objectID: "2738",
    uniqueID: "b6b8575f-d7ef-4724-ba82-b43db6f91c45",
  },
  {
    name: "Fabio Alberto Leon Bentley",
    city: "Mitu",
    country: "Colombia",
    iata_code: "MVP",
    _geoloc: {
      lat: 1.253664,
      lng: -70.233878,
    },
    links_count: 6,
    objectID: "2732",
    uniqueID: "2d096047-e9e6-4114-a743-97fcf35be197",
  },
  {
    name: "Los Garzones",
    city: "Monteria",
    country: "Colombia",
    iata_code: "MTR",
    _geoloc: {
      lat: 8.823744,
      lng: -75.825831,
    },
    links_count: 6,
    objectID: "2731",
    uniqueID: "b01ba153-cad5-431d-bb1d-fa2de7587dfe",
  },
  {
    name: "Perales",
    city: "Ibague",
    country: "Colombia",
    iata_code: "IBE",
    _geoloc: {
      lat: 4.421608,
      lng: -75.1333,
    },
    links_count: 6,
    objectID: "2724",
    uniqueID: "03543efe-b561-42e4-bfaa-c1e04bccde77",
  },
  {
    name: "Mariscal Lamar",
    city: "Cuenca",
    country: "Ecuador",
    iata_code: "CUE",
    _geoloc: {
      lat: -2.889467,
      lng: -78.984397,
    },
    links_count: 6,
    objectID: "2671",
    uniqueID: "d1e5b51c-9b2c-4448-bf11-46458f1746a1",
  },
  {
    name: "Francisco De Orellana",
    city: "Coca",
    country: "Ecuador",
    iata_code: "OCC",
    _geoloc: {
      lat: -0.462886,
      lng: -76.986842,
    },
    links_count: 6,
    objectID: "2670",
    uniqueID: "426429af-3f8f-41bc-814a-0a44161f5325",
  },
  {
    name: "Maquehue",
    city: "Temuco",
    country: "Chile",
    iata_code: "ZCO",
    _geoloc: {
      lat: -38.766819,
      lng: -72.637097,
    },
    links_count: 6,
    objectID: "2664",
    uniqueID: "13f8a730-c098-460a-9427-4df3facb171e",
  },
  {
    name: "Enugu",
    city: "Enugu",
    country: "Nigeria",
    iata_code: "ENU",
    _geoloc: {
      lat: 6.474272,
      lng: 7.561961,
    },
    links_count: 6,
    objectID: "264",
    uniqueID: "fb4cfb6f-3d19-45f3-8d18-5cbb4aad9cb8",
  },
  {
    name: "Professor Urbano Ernesto Stumpf",
    city: "Sao Jose Dos Campos",
    country: "Brazil",
    iata_code: "SJK",
    _geoloc: {
      lat: -23.228172,
      lng: -45.862739,
    },
    links_count: 6,
    objectID: "2615",
    uniqueID: "e44c8775-4730-4a7b-8c6a-4512a9e42852",
  },
  {
    name: "Macapa",
    city: "Macapa",
    country: "Brazil",
    iata_code: "MCP",
    _geoloc: {
      lat: 0.050664,
      lng: -51.072178,
    },
    links_count: 6,
    objectID: "2591",
    uniqueID: "2893f570-a31a-49e5-a3b5-86c58520aaeb",
  },
  {
    name: "Mario Ribeiro",
    city: "Montes Claros",
    country: "Brazil",
    iata_code: "MOC",
    _geoloc: {
      lat: -16.706925,
      lng: -43.8189,
    },
    links_count: 6,
    objectID: "2587",
    uniqueID: "63d65c88-03f1-4359-bb2b-24a711ecff38",
  },
  {
    name: "Usiminas",
    city: "Ipatinga",
    country: "Brazil",
    iata_code: "IPN",
    _geoloc: {
      lat: -19.470722,
      lng: -42.487583,
    },
    links_count: 6,
    objectID: "2570",
    uniqueID: "a4b64f56-e96e-40f5-baba-1740f9707c41",
  },
  {
    name: "Fernando De Noronha",
    city: "Fernando Do Noronha",
    country: "Brazil",
    iata_code: "FEN",
    _geoloc: {
      lat: -3.854928,
      lng: -32.423336,
    },
    links_count: 6,
    objectID: "2556",
    uniqueID: "b430b855-888d-4187-9f27-d52252c5aefc",
  },
  {
    name: "Campo Dos Bugres",
    city: "Caxias Do Sul",
    country: "Brazil",
    iata_code: "CXJ",
    _geoloc: {
      lat: -29.197064,
      lng: -51.187536,
    },
    links_count: 6,
    objectID: "2547",
    uniqueID: "7932f7be-1f64-47f5-9d2c-bd341ac59368",
  },
  {
    name: "Cascavel",
    city: "Cascavel",
    country: "Brazil",
    iata_code: "CAC",
    _geoloc: {
      lat: -25.000339,
      lng: -53.500764,
    },
    links_count: 6,
    objectID: "2535",
    uniqueID: "978b766d-f376-4a2d-b06b-726cd7a2139c",
  },
  {
    name: "Boa Vista",
    city: "Boa Vista",
    country: "Brazil",
    iata_code: "BVB",
    _geoloc: {
      lat: 2.846311,
      lng: -60.690069,
    },
    links_count: 6,
    objectID: "2533",
    uniqueID: "116ebdcb-528b-43aa-b41d-e000bd68b39a",
  },
  {
    name: "Mar Del Plata",
    city: "Mar Del Plata",
    country: "Argentina",
    iata_code: "MDQ",
    _geoloc: {
      lat: -37.934167,
      lng: -57.573333,
    },
    links_count: 6,
    objectID: "2508",
    uniqueID: "d65b12cd-4294-4b7e-814a-8eb75000aff8",
  },
  {
    name: "Tamale",
    city: "Tamale",
    country: "Ghana",
    iata_code: "TML",
    _geoloc: {
      lat: 9.557192,
      lng: -0.863214,
    },
    links_count: 6,
    objectID: "249",
    uniqueID: "fd3b4636-2fe9-497c-8988-56a28e44b10c",
  },
  {
    name: "Esquel",
    city: "Esquel",
    country: "Argentina",
    iata_code: "EQS",
    _geoloc: {
      lat: -42.90795,
      lng: -71.139472,
    },
    links_count: 6,
    objectID: "2488",
    uniqueID: "68cc17b0-fc60-40eb-9f46-fd099ba03cd7",
  },
  {
    name: "St Anthony",
    city: "St. Anthony",
    country: "Canada",
    iata_code: "YAY",
    _geoloc: {
      lat: 51.391944,
      lng: -56.083056,
    },
    links_count: 6,
    objectID: "24",
    uniqueID: "fd495b0b-8071-4f69-ad3a-cb35f25a4559",
  },
  {
    name: "Cotabato",
    city: "Cotabato",
    country: "Philippines",
    iata_code: "CBO",
    _geoloc: {
      lat: 7.165242,
      lng: 124.209619,
    },
    links_count: 6,
    objectID: "2399",
    uniqueID: "9240b3ee-dd93-4616-81aa-8c40e50e6204",
  },
  {
    name: "Pohang",
    city: "Pohang",
    country: "South Korea",
    iata_code: "KPO",
    _geoloc: {
      lat: 35.987858,
      lng: 129.420486,
    },
    links_count: 6,
    objectID: "2380",
    uniqueID: "3998c45a-1a85-498d-9831-27cc0cc9b0ad",
  },
  {
    name: "Ulsan",
    city: "Ulsan",
    country: "South Korea",
    iata_code: "USN",
    _geoloc: {
      lat: 35.593494,
      lng: 129.351722,
    },
    links_count: 6,
    objectID: "2374",
    uniqueID: "af3c7521-3195-48a9-a6fe-b337cae52f7c",
  },
  {
    name: "Yeosu",
    city: "Yeosu",
    country: "South Korea",
    iata_code: "RSU",
    _geoloc: {
      lat: 34.842328,
      lng: 127.61685,
    },
    links_count: 6,
    objectID: "2365",
    uniqueID: "6867d88a-4912-49d9-b1c9-2bf501c4063d",
  },
  {
    name: "Yamagata",
    city: "Yamagata",
    country: "Japan",
    iata_code: "GAJ",
    _geoloc: {
      lat: 38.411894,
      lng: 140.371331,
    },
    links_count: 6,
    objectID: "2341",
    uniqueID: "b7a62213-80a1-4986-b177-6ad54850c6a2",
  },
  {
    name: "New Kitakyushu",
    city: "Kitakyushu",
    country: "Japan",
    iata_code: "KKJ",
    _geoloc: {
      lat: 33.845942,
      lng: 131.034689,
    },
    links_count: 6,
    objectID: "2311",
    uniqueID: "53e975e2-864e-4aa6-9866-f921b36266ce",
  },
  {
    name: "Tsushima",
    city: "Tsushima",
    country: "Japan",
    iata_code: "TSJ",
    _geoloc: {
      lat: 34.284889,
      lng: 129.33055,
    },
    links_count: 6,
    objectID: "2297",
    uniqueID: "56138de2-e786-423d-a93c-64f916a0e27c",
  },
  {
    name: "Yamaguchi Ube",
    city: "Yamaguchi",
    country: "Japan",
    iata_code: "UBJ",
    _geoloc: {
      lat: 33.93,
      lng: 131.278611,
    },
    links_count: 6,
    objectID: "2296",
    uniqueID: "c4dcbcbe-77b0-4833-9343-802090c7d81d",
  },
  {
    name: "Obihiro",
    city: "Obihiro",
    country: "Japan",
    iata_code: "OBO",
    _geoloc: {
      lat: 42.733333,
      lng: 143.217222,
    },
    links_count: 6,
    objectID: "2286",
    uniqueID: "9afe57bc-f4e1-47bc-9f53-3cfabed84ee5",
  },
  {
    name: "Pohnpei Intl",
    city: "Pohnpei",
    country: "Micronesia",
    iata_code: "PNI",
    _geoloc: {
      lat: 6.9851,
      lng: 158.208989,
    },
    links_count: 6,
    objectID: "2255",
    uniqueID: "9e29f544-0087-4d89-9b0c-c4455e321ee9",
  },
  {
    name: "Bucholz Aaf",
    city: "Kwajalein",
    country: "Marshall Islands",
    iata_code: "KWA",
    _geoloc: {
      lat: 8.720122,
      lng: 167.731661,
    },
    links_count: 6,
    objectID: "2251",
    uniqueID: "a559fea9-1a5d-471a-bc9e-b77cda83539f",
  },
  {
    name: "Sheikh Zayed",
    city: "Rahim Yar Khan",
    country: "Pakistan",
    iata_code: "RYK",
    _geoloc: {
      lat: 28.3839,
      lng: 70.279572,
    },
    links_count: 6,
    objectID: "2222",
    uniqueID: "c42c40d2-3779-4a3d-94b4-78efe635701a",
  },
  {
    name: "Gwadar",
    city: "Gwadar",
    country: "Pakistan",
    iata_code: "GWD",
    _geoloc: {
      lat: 25.233308,
      lng: 62.329494,
    },
    links_count: 6,
    objectID: "2203",
    uniqueID: "c7e81aeb-e30a-4931-a881-1f268744f52f",
  },
  {
    name: "Nejran",
    city: "Nejran",
    country: "Saudi Arabia",
    iata_code: "EAM",
    _geoloc: {
      lat: 17.611436,
      lng: 44.419169,
    },
    links_count: 6,
    objectID: "2075",
    uniqueID: "450fc9c9-d445-4e0d-ab84-54daf7d1b1b7",
  },
  {
    name: "Bisha",
    city: "Bisha",
    country: "Saudi Arabia",
    iata_code: "BHH",
    _geoloc: {
      lat: 19.98435,
      lng: 42.620881,
    },
    links_count: 6,
    objectID: "2062",
    uniqueID: "ba431573-2bb7-4e42-9b1e-783f6f194329",
  },
  {
    name: "Al Baha",
    city: "El-baha",
    country: "Saudi Arabia",
    iata_code: "ABT",
    _geoloc: {
      lat: 20.296139,
      lng: 41.634277,
    },
    links_count: 6,
    objectID: "2061",
    uniqueID: "6fdbd10f-87dc-41c0-b126-2333bfb5debe",
  },
  {
    name: "Al Ahsa",
    city: "Al-ahsa",
    country: "Saudi Arabia",
    iata_code: "HOF",
    _geoloc: {
      lat: 25.285306,
      lng: 49.485189,
    },
    links_count: 6,
    objectID: "2060",
    uniqueID: "0d9e4ba1-3ab9-4778-83de-51a1c148d7ae",
  },
  {
    name: "Woodbourne",
    city: "Woodbourne",
    country: "New Zealand",
    iata_code: "BHE",
    _geoloc: {
      lat: -41.518333,
      lng: 173.870278,
    },
    links_count: 6,
    objectID: "2037",
    uniqueID: "a007e76c-35a8-4400-a197-9a4afea93d97",
  },
  {
    name: "Tauranga",
    city: "Tauranga",
    country: "New Zealand",
    iata_code: "TRG",
    _geoloc: {
      lat: -37.671944,
      lng: 176.19611,
    },
    links_count: 6,
    objectID: "2034",
    uniqueID: "6af5b55f-6381-454e-9836-809ea7dde682",
  },
  {
    name: "Rotorua",
    city: "Rotorua",
    country: "New Zealand",
    iata_code: "ROT",
    _geoloc: {
      lat: -38.109167,
      lng: 176.317222,
    },
    links_count: 6,
    objectID: "2031",
    uniqueID: "af3cddd9-09c8-42c9-a9c1-649ee579a30b",
  },
  {
    name: "New Plymouth",
    city: "New Plymouth",
    country: "New Zealand",
    iata_code: "NPL",
    _geoloc: {
      lat: -39.008611,
      lng: 174.179167,
    },
    links_count: 6,
    objectID: "2023",
    uniqueID: "6c5a6d8b-f2e9-4073-aa18-1d87f985e934",
  },
  {
    name: "Lifou",
    city: "Lifou",
    country: "New Caledonia",
    iata_code: "LIF",
    _geoloc: {
      lat: -20.7748,
      lng: 167.239864,
    },
    links_count: 6,
    objectID: "2000",
    uniqueID: "ff1ae308-79b4-4017-8c0c-4a3a5ac76a27",
  },
  {
    name: "Maupiti",
    city: "Maupiti",
    country: "French Polynesia",
    iata_code: "MAU",
    _geoloc: {
      lat: -16.426486,
      lng: -152.243669,
    },
    links_count: 6,
    objectID: "1994",
    uniqueID: "889400b9-6165-46c7-b2b4-5adfe0260258",
  },
  {
    name: "Manihi",
    city: "Manihi",
    country: "French Polynesia",
    iata_code: "XMH",
    _geoloc: {
      lat: -14.436764,
      lng: -146.070056,
    },
    links_count: 6,
    objectID: "1978",
    uniqueID: "794aeb5b-0059-4b5b-8b8a-0b08f5a988e0",
  },
  {
    name: "Wallis",
    city: "Wallis",
    country: "Wallis and Futuna",
    iata_code: "WLS",
    _geoloc: {
      lat: -13.238281,
      lng: -176.199228,
    },
    links_count: 6,
    objectID: "1968",
    uniqueID: "c61d2333-7398-4f6d-a80d-6aec34e90f7a",
  },
  {
    name: "Bonriki Intl",
    city: "Tarawa",
    country: "Kiribati",
    iata_code: "TRW",
    _geoloc: {
      lat: 1.381636,
      lng: 173.147036,
    },
    links_count: 6,
    objectID: "1965",
    uniqueID: "06dbb23d-3460-42fb-99c6-47174b0e9659",
  },
  {
    name: "Ignacio Agramonte Intl",
    city: "Camaguey",
    country: "Cuba",
    iata_code: "CMW",
    _geoloc: {
      lat: 21.420428,
      lng: -77.847433,
    },
    links_count: 6,
    objectID: "1904",
    uniqueID: "5052b906-8dc5-4b0e-a712-bf11318da7ff",
  },
  {
    name: "Cap Haitien Intl",
    city: "Cap Haitien",
    country: "Haiti",
    iata_code: "CAP",
    _geoloc: {
      lat: 19.732989,
      lng: -72.194739,
    },
    links_count: 6,
    objectID: "1895",
    uniqueID: "05b6bcca-185a-4739-aee2-35b78872b8db",
  },
  {
    name: "Palmar Sur",
    city: "Palmar Sur",
    country: "Costa Rica",
    iata_code: "PMZ",
    _geoloc: {
      lat: 8.951025,
      lng: -83.468583,
    },
    links_count: 6,
    objectID: "1887",
    uniqueID: "8477c86c-0d2a-4834-872b-94d385773f1c",
  },
  {
    name: "Puerto Escondido Intl",
    city: "Puerto Escondido",
    country: "Mexico",
    iata_code: "PXM",
    _geoloc: {
      lat: 15.876861,
      lng: -97.089117,
    },
    links_count: 6,
    objectID: "1837",
    uniqueID: "1b449ee8-441e-43f4-b337-584edaeb717c",
  },
  {
    name: "Colima",
    city: "Colima",
    country: "Mexico",
    iata_code: "CLQ",
    _geoloc: {
      lat: 19.277011,
      lng: -103.577397,
    },
    links_count: 6,
    objectID: "1808",
    uniqueID: "f5a789b8-95d1-4c30-ac65-a716184f1e79",
  },
  {
    name: "Tepic",
    city: "Tepic",
    country: "Mexico",
    iata_code: "TPQ",
    _geoloc: {
      lat: 21.419453,
      lng: -104.842581,
    },
    links_count: 6,
    objectID: "1802",
    uniqueID: "f51be23b-4915-4b65-8d4b-8587549ff2b6",
  },
  {
    name: "Fort Nelson",
    city: "Fort Nelson",
    country: "Canada",
    iata_code: "YYE",
    _geoloc: {
      lat: 58.836389,
      lng: -122.596944,
    },
    links_count: 6,
    objectID: "180",
    uniqueID: "d0e44f4b-9e9a-421e-9b68-954bfd1c4eaf",
  },
  {
    name: "Ciudad Del Carmen Intl",
    city: "Ciudad Del Carmen",
    country: "Mexico",
    iata_code: "CME",
    _geoloc: {
      lat: 18.653739,
      lng: -91.799017,
    },
    links_count: 6,
    objectID: "1789",
    uniqueID: "b278f9c4-c41b-433a-82ed-ed708e3dbd4a",
  },
  {
    name: "Bahias De Huatulco Intl",
    city: "Huatulco",
    country: "Mexico",
    iata_code: "HUX",
    _geoloc: {
      lat: 15.775317,
      lng: -96.262572,
    },
    links_count: 6,
    objectID: "1786",
    uniqueID: "cf0466dd-0b34-441f-8cbe-c2a177609b25",
  },
  {
    name: "Batman",
    city: "Batman",
    country: "Turkey",
    iata_code: "BAL",
    _geoloc: {
      lat: 37.928969,
      lng: 41.116583,
    },
    links_count: 6,
    objectID: "1729",
    uniqueID: "40759160-7278-4e46-a259-79289f9df448",
  },
  {
    name: "Erzincan",
    city: "Erzincan",
    country: "Turkey",
    iata_code: "ERC",
    _geoloc: {
      lat: 39.710203,
      lng: 39.527003,
    },
    links_count: 6,
    objectID: "1723",
    uniqueID: "e3dbafc9-08ff-49ec-8a2d-861ceeb42910",
  },
  {
    name: "Porto Santo",
    city: "Porto Santo",
    country: "Portugal",
    iata_code: "PXO",
    _geoloc: {
      lat: 33.073386,
      lng: -16.349975,
    },
    links_count: 6,
    objectID: "1637",
    uniqueID: "96bc96b3-ee0e-4959-bb96-fe6c9331f305",
  },
  {
    name: "Santa Maria",
    city: "Santa Maria (island)",
    country: "Portugal",
    iata_code: "SMA",
    _geoloc: {
      lat: 36.97139,
      lng: -25.170639,
    },
    links_count: 6,
    objectID: "1617",
    uniqueID: "02a2dc98-177c-403d-aeba-8de5f22edd1d",
  },
  {
    name: "Sde Dov",
    city: "Tel-aviv",
    country: "Israel",
    iata_code: "SDV",
    _geoloc: {
      lat: 32.114661,
      lng: 34.782239,
    },
    links_count: 6,
    objectID: "1605",
    uniqueID: "f3195c59-4790-438e-96ad-124975fab2a0",
  },
  {
    name: "Rimini",
    city: "Rimini",
    country: "Italy",
    iata_code: "RMI",
    _geoloc: {
      lat: 44.020292,
      lng: 12.611747,
    },
    links_count: 6,
    objectID: "1546",
    uniqueID: "0899f03f-1c88-432c-be1d-d97d7b650aa1",
  },
  {
    name: "Pantelleria",
    city: "Pantelleria",
    country: "Italy",
    iata_code: "PNL",
    _geoloc: {
      lat: 36.816519,
      lng: 11.968864,
    },
    links_count: 6,
    objectID: "1511",
    uniqueID: "78fb7e76-759a-488c-b13d-0d7e4d0f121f",
  },
  {
    name: "Lampedusa",
    city: "Lampedusa",
    country: "Italy",
    iata_code: "LMP",
    _geoloc: {
      lat: 35.497914,
      lng: 12.618083,
    },
    links_count: 6,
    objectID: "1510",
    uniqueID: "66589d70-2f0e-4e96-a48a-4cb90f463fcb",
  },
  {
    name: "Skiros",
    city: "Skiros",
    country: "Greece",
    iata_code: "SKU",
    _geoloc: {
      lat: 38.967553,
      lng: 24.487228,
    },
    links_count: 6,
    objectID: "1482",
    uniqueID: "677d08ae-5a06-482d-8c32-97dc484f977d",
  },
  {
    name: "Araxos",
    city: "Patras",
    country: "Greece",
    iata_code: "GPA",
    _geoloc: {
      lat: 38.151111,
      lng: 21.425556,
    },
    links_count: 6,
    objectID: "1473",
    uniqueID: "f3c7372c-d142-4115-be9b-e584d2c1babc",
  },
  {
    name: "Dimokritos",
    city: "Alexandroupolis",
    country: "Greece",
    iata_code: "AXD",
    _geoloc: {
      lat: 40.855869,
      lng: 25.956264,
    },
    links_count: 6,
    objectID: "1446",
    uniqueID: "11913923-c9e3-4043-bda7-77e79f35b5e9",
  },
  {
    name: "St Pierre",
    city: "St.-pierre",
    country: "Saint Pierre and Miquelon",
    iata_code: "FSP",
    _geoloc: {
      lat: 46.762904,
      lng: -56.173088,
    },
    links_count: 6,
    objectID: "1441",
    uniqueID: "6e128fa3-f74a-412c-8a4a-4a9a3f16052d",
  },
  {
    name: "Le Palyvestre",
    city: "Hyeres",
    country: "France",
    iata_code: "TLN",
    _geoloc: {
      lat: 43.0973,
      lng: 6.14603,
    },
    links_count: 6,
    objectID: "1438",
    uniqueID: "5f47c1d2-f974-4748-b1b4-e7142452bfac",
  },
  {
    name: "Pleurtuit",
    city: "Dinard",
    country: "France",
    iata_code: "DNR",
    _geoloc: {
      lat: 48.587683,
      lng: -2.079958,
    },
    links_count: 6,
    objectID: "1405",
    uniqueID: "b9556b44-687b-4111-ac73-3ea537a216fb",
  },
  {
    name: "Cape Dorset",
    city: "Cape Dorset",
    country: "Canada",
    iata_code: "YTE",
    _geoloc: {
      lat: 64.23,
      lng: -76.526667,
    },
    links_count: 6,
    objectID: "140",
    uniqueID: "af475458-6502-435e-973a-12a42524905a",
  },
  {
    name: "Saint John",
    city: "St. John",
    country: "Canada",
    iata_code: "YSJ",
    _geoloc: {
      lat: 45.316111,
      lng: -65.890278,
    },
    links_count: 6,
    objectID: "135",
    uniqueID: "dd1b79ca-3dec-43ba-a14e-088dabe4a783",
  },
  {
    name: "Tavaux",
    city: "Dole",
    country: "France",
    iata_code: "DLE",
    _geoloc: {
      lat: 47.039014,
      lng: 5.42725,
    },
    links_count: 6,
    objectID: "1309",
    uniqueID: "ea83d8f9-20df-4308-b6ed-f1ad77e4fb45",
  },
  {
    name: "Mazamet",
    city: "Castres",
    country: "France",
    iata_code: "DCM",
    _geoloc: {
      lat: 43.55625,
      lng: 2.289183,
    },
    links_count: 6,
    objectID: "1285",
    uniqueID: "f9f9f3a3-cce5-4a84-b765-4b67f1b74d22",
  },
  {
    name: "Gander Intl",
    city: "Gander",
    country: "Canada",
    iata_code: "YQX",
    _geoloc: {
      lat: 48.936944,
      lng: -54.568056,
    },
    links_count: 6,
    objectID: "125",
    uniqueID: "7c29d428-177c-45e8-bf2f-ce6cdbb8705e",
  },
  {
    name: "San Sebastian",
    city: "San Sebastian",
    country: "Spain",
    iata_code: "EAS",
    _geoloc: {
      lat: 43.356519,
      lng: -1.790611,
    },
    links_count: 6,
    objectID: "1242",
    uniqueID: "903835d3-b69e-4d23-b64e-1c9921a90628",
  },
  {
    name: "Kufra",
    city: "Kufra",
    country: "Libya",
    iata_code: "AKF",
    _geoloc: {
      lat: 24.178728,
      lng: 23.313958,
    },
    links_count: 6,
    objectID: "1153",
    uniqueID: "c98543e3-3811-4fc2-a4f0-320c6ff7f2b1",
  },
  {
    name: "Kenora",
    city: "Kenora",
    country: "Canada",
    iata_code: "YQK",
    _geoloc: {
      lat: 49.788334,
      lng: -94.363056,
    },
    links_count: 6,
    objectID: "115",
    uniqueID: "9dbb8562-ea28-4e0e-b951-2172d16cded7",
  },
  {
    name: "Windsor",
    city: "Windsor",
    country: "Canada",
    iata_code: "YQG",
    _geoloc: {
      lat: 42.275556,
      lng: -82.955556,
    },
    links_count: 6,
    objectID: "113",
    uniqueID: "f2afb705-d983-445a-9864-469c16466da4",
  },
  {
    name: "Red Deer Regional",
    city: "Red Deer Industrial",
    country: "Canada",
    iata_code: "YQF",
    _geoloc: {
      lat: 52.182222,
      lng: -113.894444,
    },
    links_count: 6,
    objectID: "112",
    uniqueID: "4a83eec6-9085-4aa9-bac2-e801850304ec",
  },
  {
    name: "Makale",
    city: "Makale",
    country: "Ethiopia",
    iata_code: "MQX",
    _geoloc: {
      lat: 13.467367,
      lng: 39.533464,
    },
    links_count: 6,
    objectID: "1118",
    uniqueID: "a6fc0a22-a64c-477e-a729-c4ed7bd130cc",
  },
  {
    name: "Lalibella",
    city: "Lalibella",
    country: "Ethiopia",
    iata_code: "LLI",
    _geoloc: {
      lat: 11.975014,
      lng: 38.979969,
    },
    links_count: 6,
    objectID: "1117",
    uniqueID: "a34cd609-18eb-41c2-8f9e-e03ba3522b9f",
  },
  {
    name: "Dire Dawa Intl",
    city: "Dire Dawa",
    country: "Ethiopia",
    iata_code: "DIR",
    _geoloc: {
      lat: 9.6247,
      lng: 41.854203,
    },
    links_count: 6,
    objectID: "1112",
    uniqueID: "ed6a0864-b893-40c5-8023-23048867cbfc",
  },
  {
    name: "Hierro",
    city: "Hierro",
    country: "Spain",
    iata_code: "VDE",
    _geoloc: {
      lat: 27.814847,
      lng: -17.887056,
    },
    links_count: 6,
    objectID: "1052",
    uniqueID: "ebef2811-8336-4fe2-b05b-8f41886cc780",
  },
  {
    name: "Kananga",
    city: "Kananga",
    country: "Congo (Kinshasa)",
    iata_code: "KGA",
    _geoloc: {
      lat: -5.900055,
      lng: 22.469166,
    },
    links_count: 6,
    objectID: "1042",
    uniqueID: "d02d7ad0-0c4c-44f0-9990-a2db45578980",
  },
  {
    name: "Goma",
    city: "Goma",
    country: "Congo (Kinshasa)",
    iata_code: "GOM",
    _geoloc: {
      lat: -1.670814,
      lng: 29.238464,
    },
    links_count: 6,
    objectID: "1036",
    uniqueID: "6d5ea4b5-50bd-4c34-93f0-93b5408be406",
  },
  {
    name: "Cabinda",
    city: "Cabinda",
    country: "Angola",
    iata_code: "CAB",
    _geoloc: {
      lat: -5.596992,
      lng: 12.188353,
    },
    links_count: 5,
    objectID: "946",
    uniqueID: "3097551a-e49c-464f-a46b-5bc39cf5c949",
  },
  {
    name: "Tununak Airport",
    city: "Tununak",
    country: "United States",
    iata_code: "TNK",
    _geoloc: {
      lat: 60.575556,
      lng: -165.271667,
    },
    links_count: 5,
    objectID: "8201",
    uniqueID: "0ca2acdb-42cc-4a90-8b4c-51bd7cc71ce6",
  },
  {
    name: "Nightmute Airport",
    city: "Nightmute",
    country: "United States",
    iata_code: "NME",
    _geoloc: {
      lat: 60.471111,
      lng: -164.700833,
    },
    links_count: 5,
    objectID: "8199",
    uniqueID: "70042d3f-a648-42cf-bb29-d6d7c18b544e",
  },
  {
    name: "Ravensthorpe Airport",
    city: "Ravensthorpe",
    country: "Australia",
    iata_code: "RVT",
    _geoloc: {
      lat: -33.797222,
      lng: 120.208056,
    },
    links_count: 5,
    objectID: "7577",
    uniqueID: "c615f557-6247-4772-8c6a-f91e4302ca96",
  },
  {
    name: "Allakaket Airport",
    city: "Allakaket",
    country: "United States",
    iata_code: "AET",
    _geoloc: {
      lat: 66.5519,
      lng: -152.6222,
    },
    links_count: 5,
    objectID: "6839",
    uniqueID: "4f9379e5-5340-42d3-ad3a-537bc2223f7c",
  },
  {
    name: "Noatak Airport",
    city: "Noatak",
    country: "United States",
    iata_code: "WTK",
    _geoloc: {
      lat: 67.5661,
      lng: -162.975,
    },
    links_count: 5,
    objectID: "6725",
    uniqueID: "f8330dfd-8c01-4eb8-8c9d-be8e7d023ae1",
  },
  {
    name: "Erechim Airport",
    city: "Erechim",
    country: "Brazil",
    iata_code: "ERM",
    _geoloc: {
      lat: -27.6619,
      lng: -52.2683,
    },
    links_count: 5,
    objectID: "6477",
    uniqueID: "b16c859d-629a-487b-b367-70162dd0a6ce",
  },
  {
    name: "Kenmore Air Harbor Seaplane Base",
    city: "Seattle",
    country: "United States",
    iata_code: "LKE",
    _geoloc: {
      lat: 47.629,
      lng: -122.339,
    },
    links_count: 5,
    objectID: "6457",
    uniqueID: "7d9ee1d7-e08f-4b4c-8c3d-063cd16c339b",
  },
  {
    name: "Esperance Airport",
    city: "Esperance",
    country: "Australia",
    iata_code: "EPR",
    _geoloc: {
      lat: -33.6844,
      lng: 121.823,
    },
    links_count: 5,
    objectID: "6266",
    uniqueID: "378856bc-2bcc-4030-b79a-624c58c65ba6",
  },
  {
    name: "Saga Airport",
    city: "Saga",
    country: "Japan",
    iata_code: "HSG",
    _geoloc: {
      lat: 33.1497,
      lng: 130.302,
    },
    links_count: 5,
    objectID: "5996",
    uniqueID: "e27be591-6456-44bb-a231-c81cc9d35341",
  },
  {
    name: "Dera Ghazi Khan Airport",
    city: "Dera Ghazi Khan",
    country: "Pakistan",
    iata_code: "DEA",
    _geoloc: {
      lat: 29.961011,
      lng: 70.485925,
    },
    links_count: 5,
    objectID: "5942",
    uniqueID: "15ac4ca2-adf2-4924-b317-a85e749fb070",
  },
  {
    name: "Uléi Airport",
    city: "Ambryn Island",
    country: "Vanuatu",
    iata_code: "ULB",
    _geoloc: {
      lat: -16.333,
      lng: 168.283,
    },
    links_count: 5,
    objectID: "5910",
    uniqueID: "daa3bc60-c489-4b22-8dcd-9fc6059343c3",
  },
  {
    name: "Ahe Airport",
    city: "Ahe",
    country: "French Polynesia",
    iata_code: "AHE",
    _geoloc: {
      lat: -14.4281,
      lng: -146.257,
    },
    links_count: 5,
    objectID: "5889",
    uniqueID: "6169b359-6555-413d-bc42-2ee4f5ea2d87",
  },
  {
    name: "New Bedford Regional Airport",
    city: "New Bedford",
    country: "United States",
    iata_code: "EWB",
    _geoloc: {
      lat: 41.6761,
      lng: -70.9569,
    },
    links_count: 5,
    objectID: "5737",
    uniqueID: "31b67623-da86-4ebb-bbba-0e785ed322a4",
  },
  {
    name: "Harrison Marion Regional Airport",
    city: "Clarksburg",
    country: "United States",
    iata_code: "CKB",
    _geoloc: {
      lat: 39.2966,
      lng: -80.2281,
    },
    links_count: 5,
    objectID: "5730",
    uniqueID: "af49b2af-a3ab-4ced-bcb6-828a6200a427",
  },
  {
    name: "Sorkjosen Airport",
    city: "Sorkjosen",
    country: "Norway",
    iata_code: "SOJ",
    _geoloc: {
      lat: 69.7868,
      lng: 20.9594,
    },
    links_count: 5,
    objectID: "5588",
    uniqueID: "cc62f9b9-2b6e-434a-9e49-c4586afe0662",
  },
  {
    name: "Sanday Airport",
    city: "Sanday",
    country: "United Kingdom",
    iata_code: "NDY",
    _geoloc: {
      lat: 59.2503,
      lng: -2.57667,
    },
    links_count: 5,
    objectID: "5569",
    uniqueID: "b35fab87-1bf5-4386-952c-684eddc7fb18",
  },
  {
    name: "Round Lake (Weagamow Lake) Airport",
    city: "Round Lake",
    country: "Canada",
    iata_code: "ZRJ",
    _geoloc: {
      lat: 52.9436,
      lng: -91.3128,
    },
    links_count: 5,
    objectID: "5547",
    uniqueID: "9b50d7c1-c9aa-4f3a-92c4-5863b6901a52",
  },
  {
    name: "Fort Severn Airport",
    city: "Fort Severn",
    country: "Canada",
    iata_code: "YER",
    _geoloc: {
      lat: 56.0189,
      lng: -87.6761,
    },
    links_count: 5,
    objectID: "5489",
    uniqueID: "e4aa048d-c85d-4ab3-a998-ae03180d7a3d",
  },
  {
    name: "Fort Frances Municipal Airport",
    city: "Fort Frances",
    country: "Canada",
    iata_code: "YAG",
    _geoloc: {
      lat: 48.6542,
      lng: -93.4397,
    },
    links_count: 5,
    objectID: "5479",
    uniqueID: "0a1d97f9-b4ea-4e74-87da-cc905a31df70",
  },
  {
    name: "Wunnumin Lake Airport",
    city: "Wunnumin Lake",
    country: "Canada",
    iata_code: "WNN",
    _geoloc: {
      lat: 52.8939,
      lng: -89.2892,
    },
    links_count: 5,
    objectID: "5466",
    uniqueID: "04b1c52b-1f08-4569-837b-f75f2eb50e37",
  },
  {
    name: "Wapekeka Airport",
    city: "Angling Lake",
    country: "Canada",
    iata_code: "YAX",
    _geoloc: {
      lat: 53.8492,
      lng: -89.5794,
    },
    links_count: 5,
    objectID: "5465",
    uniqueID: "1a436156-7629-4d66-bce0-f9d618e66950",
  },
  {
    name: "Punta Gorda Airport",
    city: "Punta Gorda",
    country: "Belize",
    iata_code: "PND",
    _geoloc: {
      lat: 16.1024,
      lng: -88.8083,
    },
    links_count: 5,
    objectID: "5455",
    uniqueID: "655fb662-7eca-4d3c-b0e8-16ad0d7d8209",
  },
  {
    name: "Narsaq Heliport",
    city: "Narsaq",
    country: "Greenland",
    iata_code: "JNS",
    _geoloc: {
      lat: 60.9167,
      lng: -46.0586,
    },
    links_count: 5,
    objectID: "5445",
    uniqueID: "6192ba46-6f2e-4bb5-92e4-6926f18ebae9",
  },
  {
    name: "Forde Bringeland",
    city: "Forde",
    country: "Norway",
    iata_code: "FDE",
    _geoloc: {
      lat: 61.392,
      lng: 5.763,
    },
    links_count: 5,
    objectID: "4389",
    uniqueID: "b1f70bb7-123f-466c-a726-0d08095cca44",
  },
  {
    name: "Abraham Lincoln Capital",
    city: "Springfield",
    country: "United States",
    iata_code: "SPI",
    _geoloc: {
      lat: 39.8441,
      lng: -89.677889,
    },
    links_count: 5,
    objectID: "4336",
    uniqueID: "629e9591-e46d-4d4e-b225-04ec2b72aa14",
  },
  {
    name: "Valan",
    city: "Honningsvag",
    country: "Norway",
    iata_code: "HVG",
    _geoloc: {
      lat: 70.99,
      lng: 25.83,
    },
    links_count: 5,
    objectID: "4326",
    uniqueID: "d1b18344-eb06-48a8-a227-7d39042f1d3d",
  },
  {
    name: "Kemi Tornio",
    city: "Kemi",
    country: "Finland",
    iata_code: "KEM",
    _geoloc: {
      lat: 65.781889,
      lng: 24.5991,
    },
    links_count: 5,
    objectID: "432",
    uniqueID: "b3f98ddb-a4ca-48d2-96c2-74e32764ad9d",
  },
  {
    name: "Jyvaskyla",
    city: "Jyvaskyla",
    country: "Finland",
    iata_code: "JYV",
    _geoloc: {
      lat: 62.399453,
      lng: 25.678253,
    },
    links_count: 5,
    objectID: "430",
    uniqueID: "0cfb700f-d4cb-4cb8-8a2c-a8dd5d5a3254",
  },
  {
    name: "Barnstable Muni Boardman Polando Fld",
    city: "Barnstable",
    country: "United States",
    iata_code: "HYA",
    _geoloc: {
      lat: 41.669336,
      lng: -70.280356,
    },
    links_count: 5,
    objectID: "4278",
    uniqueID: "d44fde95-c67f-484c-a206-60f02567021f",
  },
  {
    name: "Wai Oti",
    city: "Maumere",
    country: "Indonesia",
    iata_code: "MOF",
    _geoloc: {
      lat: -8.640647,
      lng: 122.236889,
    },
    links_count: 5,
    objectID: "3913",
    uniqueID: "97c0719f-7486-4067-bf2a-1739fcb5d277",
  },
  {
    name: "South Arkansas Rgnl At Goodwin Fld",
    city: "El Dorado",
    country: "United States",
    iata_code: "ELD",
    _geoloc: {
      lat: 33.220972,
      lng: -92.813278,
    },
    links_count: 5,
    objectID: "3696",
    uniqueID: "7e2e7954-958d-486f-9dfb-2b29efda31e9",
  },
  {
    name: "Norfolk Island Intl",
    city: "Norfolk Island",
    country: "Norfolk Island",
    iata_code: "NLK",
    _geoloc: {
      lat: -29.041625,
      lng: 167.938742,
    },
    links_count: 5,
    objectID: "3359",
    uniqueID: "e5218882-590a-41d7-9f65-eb6d8d32fd65",
  },
  {
    name: "Kaimana",
    city: "Kaimana",
    country: "Indonesia",
    iata_code: "KNG",
    _geoloc: {
      lat: -3.644517,
      lng: 133.695553,
    },
    links_count: 5,
    objectID: "3258",
    uniqueID: "e85b375e-c492-4584-8dae-e403d6cf0413",
  },
  {
    name: "Heroes Del Acre",
    city: "Cobija",
    country: "Bolivia",
    iata_code: "CIJ",
    _geoloc: {
      lat: -11.040436,
      lng: -68.782972,
    },
    links_count: 5,
    objectID: "2761",
    uniqueID: "478c1c02-5e2d-44c4-b355-54affed9e205",
  },
  {
    name: "In Salah",
    city: "In Salah",
    country: "Algeria",
    iata_code: "INZ",
    _geoloc: {
      lat: 27.251022,
      lng: 2.512017,
    },
    links_count: 5,
    objectID: "239",
    uniqueID: "d42fa8dd-eebf-4580-9b4f-8a5d75a3dddf",
  },
  {
    name: "Takaroa",
    city: "Takaroa",
    country: "French Polynesia",
    iata_code: "TKX",
    _geoloc: {
      lat: -14.455781,
      lng: -145.024542,
    },
    links_count: 5,
    objectID: "1986",
    uniqueID: "5e120af9-b96a-4029-9caf-acbde90462c0",
  },
  {
    name: "Tikehau",
    city: "Tikehau",
    country: "French Polynesia",
    iata_code: "TIH",
    _geoloc: {
      lat: -15.119617,
      lng: -148.230697,
    },
    links_count: 5,
    objectID: "1975",
    uniqueID: "6f887e44-2f83-4947-8f30-7be8ed930dd3",
  },
  {
    name: "Tubuai",
    city: "Tubuai",
    country: "French Polynesia",
    iata_code: "TUB",
    _geoloc: {
      lat: -23.365353,
      lng: -149.524072,
    },
    links_count: 5,
    objectID: "1972",
    uniqueID: "01ce095a-13d1-4095-bbc3-7c3fe6c65947",
  },
  {
    name: "Rurutu",
    city: "Rurutu",
    country: "French Polynesia",
    iata_code: "RUR",
    _geoloc: {
      lat: -22.434069,
      lng: -151.360614,
    },
    links_count: 5,
    objectID: "1971",
    uniqueID: "75286ff7-d642-4dad-be15-4854da49bde2",
  },
  {
    name: "Aitutaki",
    city: "Aitutaki",
    country: "Cook Islands",
    iata_code: "AIT",
    _geoloc: {
      lat: -18.830922,
      lng: -159.764233,
    },
    links_count: 5,
    objectID: "1958",
    uniqueID: "47b55e6e-5cc5-4094-aadc-b47d0d94257b",
  },
  {
    name: "Gerrard Smith Intl",
    city: "Cayman Barac",
    country: "Cayman Islands",
    iata_code: "CYB",
    _geoloc: {
      lat: 19.686981,
      lng: -79.882789,
    },
    links_count: 5,
    objectID: "1925",
    uniqueID: "0ca6a92f-21e3-43b4-895b-608377702aa3",
  },
  {
    name: "Vilo Acuna Intl",
    city: "Cayo Largo del Sur",
    country: "Cuba",
    iata_code: "CYO",
    _geoloc: {
      lat: 21.616453,
      lng: -81.545989,
    },
    links_count: 5,
    objectID: "1903",
    uniqueID: "27d3a8b6-5b99-4692-98fb-198d6b5c2b3c",
  },
  {
    name: "Bocas Del Toro Intl",
    city: "Bocas Del Toro",
    country: "Panama",
    iata_code: "BOC",
    _geoloc: {
      lat: 9.340853,
      lng: -82.250842,
    },
    links_count: 5,
    objectID: "1865",
    uniqueID: "61274b3d-cdd0-4a04-a82b-f68802e9ba4c",
  },
  {
    name: "Kisumu",
    city: "Kisumu",
    country: "Kenya",
    iata_code: "KIS",
    _geoloc: {
      lat: -0.086139,
      lng: 34.728892,
    },
    links_count: 5,
    objectID: "1140",
    uniqueID: "48dc28e7-4264-48a3-b963-da422c7085a2",
  },
  {
    name: "Kisangani Simisini",
    city: "Kisangani",
    country: "Congo (Kinshasa)",
    iata_code: "FKI",
    _geoloc: {
      lat: 0.5175,
      lng: 25.155014,
    },
    links_count: 5,
    objectID: "1031",
    uniqueID: "4ae8c36c-afb6-4a53-820b-24fa9f5c2330",
  },
  {
    name: "Mbandaka",
    city: "Mbandaka",
    country: "Congo (Kinshasa)",
    iata_code: "MDK",
    _geoloc: {
      lat: 0.0226,
      lng: 18.288744,
    },
    links_count: 5,
    objectID: "1026",
    uniqueID: "31e3f9c0-dc10-4f95-b821-40ca76e8600c",
  },
  {
    name: "High Level",
    city: "High Level",
    country: "Canada",
    iata_code: "YOJ",
    _geoloc: {
      lat: 58.621389,
      lng: -117.164722,
    },
    links_count: 4,
    objectID: "99",
    uniqueID: "92fd1183-5542-4182-8d09-740dcc89f5f5",
  },
  {
    name: "Lichinga",
    city: "Lichinga",
    country: "Mozambique",
    iata_code: "VXC",
    _geoloc: {
      lat: -13.273986,
      lng: 35.266262,
    },
    links_count: 4,
    objectID: "977",
    uniqueID: "5796375a-587a-4943-9f33-a6e2a13eb1c3",
  },
  {
    name: "Saurimo",
    city: "Saurimo",
    country: "Angola",
    iata_code: "VHC",
    _geoloc: {
      lat: -9.689067,
      lng: 20.431875,
    },
    links_count: 4,
    objectID: "957",
    uniqueID: "920aed42-8d33-4e44-842e-1fa8d80a7934",
  },
  {
    name: "Malanje",
    city: "Malanje",
    country: "Angola",
    iata_code: "MEG",
    _geoloc: {
      lat: -9.525086,
      lng: 16.312406,
    },
    links_count: 4,
    objectID: "952",
    uniqueID: "f58fecc5-b5fc-4dce-ad07-eb687e7b6694",
  },
  {
    name: "Kuito",
    city: "Kuito",
    country: "Angola",
    iata_code: "SVP",
    _geoloc: {
      lat: -12.404633,
      lng: 16.947414,
    },
    links_count: 4,
    objectID: "949",
    uniqueID: "c4996eb1-0f9a-44fa-b2de-13658b1d1c11",
  },
  {
    name: "Tolagnaro",
    city: "Tolagnaro",
    country: "Madagascar",
    iata_code: "FTU",
    _geoloc: {
      lat: -25.038056,
      lng: 46.956111,
    },
    links_count: 4,
    objectID: "937",
    uniqueID: "27ed5481-b1ae-403a-84fe-0fed51508ca9",
  },
  {
    name: "Gannan",
    city: "Xiahe city",
    country: "China",
    iata_code: "GXH",
    _geoloc: {
      lat: 34.4909,
      lng: 102.3719,
    },
    links_count: 4,
    objectID: "9311",
    uniqueID: "1e83a38d-9152-4b26-9d16-d1419c121b3f",
  },
  {
    name: "Bingol",
    city: "Bingol",
    country: "Turkey",
    iata_code: "BGG",
    _geoloc: {
      lat: 38.86111,
      lng: 40.5925,
    },
    links_count: 4,
    objectID: "9272",
    uniqueID: "705dd271-e963-4a24-8789-4da3c6603a68",
  },
  {
    name: "Morondava",
    city: "Morondava",
    country: "Madagascar",
    iata_code: "MOQ",
    _geoloc: {
      lat: -20.28475,
      lng: 44.317614,
    },
    links_count: 4,
    objectID: "922",
    uniqueID: "c43f520c-7d9b-49d1-a733-82ab034e54dc",
  },
  {
    name: "Igdir",
    city: "Igdir",
    country: "Turkey",
    iata_code: "IGD",
    _geoloc: {
      lat: 39.983056,
      lng: 43.866389,
    },
    links_count: 4,
    objectID: "9043",
    uniqueID: "2899bc53-423e-4815-aeeb-05f6b9a07619",
  },
  {
    name: "Garoua",
    city: "Garoua",
    country: "Cameroon",
    iata_code: "GOU",
    _geoloc: {
      lat: 9.335892,
      lng: 13.370103,
    },
    links_count: 4,
    objectID: "901",
    uniqueID: "c1673b49-6e9e-4409-91cd-c6724f2ef18b",
  },
  {
    name: "Sir Bani Yas Island",
    city: "Sir Bani Yas Island",
    country: "United Arab Emirates",
    iata_code: "XSB",
    _geoloc: {
      lat: 24.2856083,
      lng: 52.5783472,
    },
    links_count: 4,
    objectID: "8961",
    uniqueID: "896fd8da-fa3a-4871-9e9a-87245304e495",
  },
  {
    name: "Bodaibo",
    city: "Bodaibo",
    country: "Russia",
    iata_code: "ODO",
    _geoloc: {
      lat: 57.5137,
      lng: 114.1348,
    },
    links_count: 4,
    objectID: "8944",
    uniqueID: "786df704-7d2b-4c91-8890-a50e79083f26",
  },
  {
    name: "Bata",
    city: "Bata",
    country: "Equatorial Guinea",
    iata_code: "BSG",
    _geoloc: {
      lat: 1.905469,
      lng: 9.805681,
    },
    links_count: 4,
    objectID: "890",
    uniqueID: "aade6ef1-2276-4baa-bee2-a3393c5ed966",
  },
  {
    name: "Kooddoo",
    city: "Kooddoo",
    country: "Maldives",
    iata_code: "GKK",
    _geoloc: {
      lat: 0.7308,
      lng: 73.433,
    },
    links_count: 4,
    objectID: "8892",
    uniqueID: "a4a95781-0e4d-4163-9010-0c4dc414b917",
  },
  {
    name: "Lankaran International Airport",
    city: "Lankaran",
    country: "Azerbaijan",
    iata_code: "LLK",
    _geoloc: {
      lat: 38.746389,
      lng: 48.817778,
    },
    links_count: 4,
    objectID: "8774",
    uniqueID: "11e0d221-ae6a-431e-a84f-f023fd335dad",
  },
  {
    name: "Francistown",
    city: "Francistown",
    country: "Botswana",
    iata_code: "FRW",
    _geoloc: {
      lat: -21.159597,
      lng: 27.474525,
    },
    links_count: 4,
    objectID: "877",
    uniqueID: "a7154d24-7217-430f-b45b-8fca4ce97108",
  },
  {
    name: "Upington",
    city: "Upington",
    country: "South Africa",
    iata_code: "UTN",
    _geoloc: {
      lat: -28.399097,
      lng: 21.260239,
    },
    links_count: 4,
    objectID: "865",
    uniqueID: "1c080d36-c567-4cb6-9219-2a1d255fd892",
  },
  {
    name: "Prince Abdul Majeed Airport",
    city: "Al-Ula",
    country: "Saudi Arabia",
    iata_code: "ULH",
    _geoloc: {
      lat: 26.636718,
      lng: 37.908018,
    },
    links_count: 4,
    objectID: "8381",
    uniqueID: "7fea5548-400e-440c-8061-4c4cfd31d89c",
  },
  {
    name: "Carlos Alberto da Costa Neves Airport",
    city: "Cacador",
    country: "Brazil",
    iata_code: "CFC",
    _geoloc: {
      lat: -26.788333,
      lng: -50.939722,
    },
    links_count: 4,
    objectID: "8244",
    uniqueID: "59c9bfdd-2d20-434d-a177-cd3bb085fe7b",
  },
  {
    name: "Arctic Bay Airport",
    city: "Arctic Bay",
    country: "Canada",
    iata_code: "YAB",
    _geoloc: {
      lat: 73.006389,
      lng: -85.047222,
    },
    links_count: 4,
    objectID: "8225",
    uniqueID: "a5b55c2b-9f0f-4fe8-a546-180f9f7ae6d1",
  },
  {
    name: "Kegaska Airport",
    city: "Kegaska",
    country: "Canada",
    iata_code: "ZKG",
    _geoloc: {
      lat: 50.195833,
      lng: -61.265833,
    },
    links_count: 4,
    objectID: "8217",
    uniqueID: "d268a644-c405-485a-b51d-658f2b258ee6",
  },
  {
    name: "Newtok Airport",
    city: "Newtok",
    country: "United States",
    iata_code: "WWT",
    _geoloc: {
      lat: 60.939167,
      lng: -164.641111,
    },
    links_count: 4,
    objectID: "8203",
    uniqueID: "66a759cf-2c93-478e-b5c2-5425bea2b412",
  },
  {
    name: "Zona da Mata Regional Airport",
    city: "Juiz de Fora",
    country: "Brazil",
    iata_code: "IZA",
    _geoloc: {
      lat: -21.5130558014,
      lng: -43.1730575562,
    },
    links_count: 4,
    objectID: "8180",
    uniqueID: "987f2d10-184e-45ec-9fc3-41cb10f73c81",
  },
  {
    name: "Friday Harbor Seaplane Base",
    city: "Friday Harbor",
    country: "United States",
    iata_code: "FBS",
    _geoloc: {
      lat: 48.537222,
      lng: -123.009722,
    },
    links_count: 4,
    objectID: "8174",
    uniqueID: "a14d8062-2f58-4824-a4ad-39f9903efaa8",
  },
  {
    name: "Westsound Seaplane Base",
    city: "Westsound",
    country: "United States",
    iata_code: "WSX",
    _geoloc: {
      lat: 48.617778,
      lng: -122.952778,
    },
    links_count: 4,
    objectID: "8173",
    uniqueID: "9ec22996-1ebd-4389-b235-96f73a8845d8",
  },
  {
    name: "Roche Harbor Seaplane Base",
    city: "Roche Harbor",
    country: "United States",
    iata_code: "RCE",
    _geoloc: {
      lat: 48.608056,
      lng: -123.159722,
    },
    links_count: 4,
    objectID: "8170",
    uniqueID: "a8cb6ce6-c152-419d-966e-db6d16041975",
  },
  {
    name: "Kimberley",
    city: "Kimberley",
    country: "South Africa",
    iata_code: "KIM",
    _geoloc: {
      lat: -28.802834,
      lng: 24.765167,
    },
    links_count: 4,
    objectID: "815",
    uniqueID: "31801cf7-3497-4cdd-8d98-d76c635f5803",
  },
  {
    name: "NAYPYITAW",
    city: "NAYPYITAW",
    country: "Burma",
    iata_code: "NYT",
    _geoloc: {
      lat: 19.374,
      lng: 96.121,
    },
    links_count: 4,
    objectID: "8130",
    uniqueID: "a3eb25e1-f6fd-4a1c-a8a3-7aa6b07f9cc8",
  },
  {
    name: "Hoedspruit Afb",
    city: "Hoedspruit",
    country: "South Africa",
    iata_code: "HDS",
    _geoloc: {
      lat: -24.368642,
      lng: 31.048744,
    },
    links_count: 4,
    objectID: "811",
    uniqueID: "1354ba5a-44d9-451f-8301-aa54953f08e7",
  },
  {
    name: "Zhongwei Xiangshan Airport",
    city: "Zhongwei",
    country: "China",
    iata_code: "ZHY",
    _geoloc: {
      lat: 37.5728,
      lng: 105.1544,
    },
    links_count: 4,
    objectID: "8043",
    uniqueID: "f7306305-8b63-426a-b241-ad4b2590e4ff",
  },
  {
    name: "Gunsa",
    city: "Shiquanhe",
    country: "China",
    iata_code: "NGQ",
    _geoloc: {
      lat: 32.10027,
      lng: 80.052778,
    },
    links_count: 4,
    objectID: "7932",
    uniqueID: "1443e938-73f9-4a4f-8111-70c3694ba567",
  },
  {
    name: "Yushu Batang",
    city: "Yushu",
    country: "China",
    iata_code: "YUS",
    _geoloc: {
      lat: 32.825,
      lng: 97.125,
    },
    links_count: 4,
    objectID: "7894",
    uniqueID: "b5a0c106-12e3-4592-93c6-460218ec1ec1",
  },
  {
    name: "Belbek Sevastopol International Airport",
    city: "Sevastopol",
    country: "Ukraine",
    iata_code: "UKS",
    _geoloc: {
      lat: 44.691431,
      lng: 33.57567,
    },
    links_count: 4,
    objectID: "7863",
    uniqueID: "734dfb5e-687c-4962-ad2a-540a7a8a65e7",
  },
  {
    name: "Zonguldak",
    city: "Zonguldak",
    country: "Turkey",
    iata_code: "ONQ",
    _geoloc: {
      lat: 41.506111,
      lng: 32.088611,
    },
    links_count: 4,
    objectID: "7821",
    uniqueID: "9ecd8f9f-5bc9-44d3-9d6c-ab62cdafc92e",
  },
  {
    name: "Cotopaxi International Airport",
    city: "Latacunga",
    country: "Ecuador",
    iata_code: "LTX",
    _geoloc: {
      lat: -0.5425,
      lng: -78.3657,
    },
    links_count: 4,
    objectID: "7695",
    uniqueID: "fbb6b78c-30c1-4c33-ab90-b3f2e9406909",
  },
  {
    name: "Morristown Municipal Airport",
    city: "Morristown",
    country: "United States",
    iata_code: "MMU",
    _geoloc: {
      lat: 40.79935,
      lng: -74.4148747,
    },
    links_count: 4,
    objectID: "7657",
    uniqueID: "8663468f-baed-4872-b7da-3ce11ce6c204",
  },
  {
    name: "Rundu",
    city: "Rundu",
    country: "Namibia",
    iata_code: "NDU",
    _geoloc: {
      lat: -17.956461,
      lng: 19.719439,
    },
    links_count: 4,
    objectID: "7634",
    uniqueID: "13df6f9d-df61-43f3-9e61-c073516a3b8d",
  },
  {
    name: "Santa Ana Airport",
    city: "Santa Ana",
    country: "Solomon Islands",
    iata_code: "NNB",
    _geoloc: {
      lat: -10.848056,
      lng: 162.454167,
    },
    links_count: 4,
    objectID: "7618",
    uniqueID: "d1b32c1b-3baf-42da-82c6-fc32ac9eb514",
  },
  {
    name: "Ulawa Airport",
    city: "Ulawa",
    country: "Solomon Islands",
    iata_code: "RNA",
    _geoloc: {
      lat: -9.854722,
      lng: 161.979167,
    },
    links_count: 4,
    objectID: "7616",
    uniqueID: "f77ea814-181a-4501-92d1-15d64a957f04",
  },
  {
    name: "Cachimbo",
    city: "Itaituba",
    country: "Brazil",
    iata_code: "ITB",
    _geoloc: {
      lat: -4.2446,
      lng: -56.00384,
    },
    links_count: 4,
    objectID: "7534",
    uniqueID: "ce2e27f5-0064-4f61-b083-80a16656b43b",
  },
  {
    name: "Wenshan Airport",
    city: "Wenshan",
    country: "China",
    iata_code: "WNH",
    _geoloc: {
      lat: 23.375833,
      lng: 104.243056,
    },
    links_count: 4,
    objectID: "7528",
    uniqueID: "7e56e734-422b-4c9c-ad02-36275b92c54b",
  },
  {
    name: "Lincang Airport",
    city: "Lincang",
    country: "China",
    iata_code: "LNJ",
    _geoloc: {
      lat: 23.738333,
      lng: 100.025,
    },
    links_count: 4,
    objectID: "7527",
    uniqueID: "03bb0d14-8507-4b29-9628-37b7da2f39c3",
  },
  {
    name: "Baoshan Airport",
    city: "Baoshan",
    country: "China",
    iata_code: "BSD",
    _geoloc: {
      lat: 25.053333,
      lng: 99.168333,
    },
    links_count: 4,
    objectID: "7505",
    uniqueID: "ea459362-17e4-408b-bb09-bf198597d096",
  },
  {
    name: "Okha Airport",
    city: "Okha",
    country: "Russia",
    iata_code: "OHH",
    _geoloc: {
      lat: 53.583333,
      lng: 142.933333,
    },
    links_count: 4,
    objectID: "7479",
    uniqueID: "b5ddf553-5fd4-420d-acc4-45fda2277b9e",
  },
  {
    name: "Foshan",
    city: "Foshan",
    country: "China",
    iata_code: "FUO",
    _geoloc: {
      lat: 23.133333,
      lng: 113.28333,
    },
    links_count: 4,
    objectID: "7457",
    uniqueID: "18c7237a-2b8f-41b5-b0bf-396b08e17b84",
  },
  {
    name: "Raivavae Airport",
    city: "Raivavae",
    country: "French Polynesia",
    iata_code: "RVV",
    _geoloc: {
      lat: -23.87,
      lng: -147.67,
    },
    links_count: 4,
    objectID: "7456",
    uniqueID: "86d9fc36-f5d4-4d4e-9bfd-399baafa6a50",
  },
  {
    name: "Kungsangen",
    city: "Norrkoeping",
    country: "Sweden",
    iata_code: "NRK",
    _geoloc: {
      lat: 58.586253,
      lng: 16.250622,
    },
    links_count: 4,
    objectID: "743",
    uniqueID: "77485931-aac2-42da-b77e-20122d0458eb",
  },
  {
    name: "Saab",
    city: "Linkoeping",
    country: "Sweden",
    iata_code: "LPI",
    _geoloc: {
      lat: 58.40615,
      lng: 15.680508,
    },
    links_count: 4,
    objectID: "742",
    uniqueID: "da386dba-8752-425d-a3e9-69d938001f2f",
  },
  {
    name: "Borlange",
    city: "Borlange",
    country: "Sweden",
    iata_code: "BLE",
    _geoloc: {
      lat: 60.422017,
      lng: 15.515211,
    },
    links_count: 4,
    objectID: "739",
    uniqueID: "bfd6f965-210a-4c28-80b7-1a1046c4e984",
  },
  {
    name: "La Chorrera Airport",
    city: "La Chorrera",
    country: "Colombia",
    iata_code: "LCR",
    _geoloc: {
      lat: -0.733333,
      lng: -73.016667,
    },
    links_count: 4,
    objectID: "7356",
    uniqueID: "e71abaef-40ca-4313-aeab-d07427cf34c4",
  },
  {
    name: "Los Colonizadores Airport",
    city: "Saravena",
    country: "Colombia",
    iata_code: "RVE",
    _geoloc: {
      lat: 6.916667,
      lng: -71.9,
    },
    links_count: 4,
    objectID: "7355",
    uniqueID: "2ba7e0da-baa2-4b7a-863d-3f35c81fd2bf",
  },
  {
    name: "Rurrenabaque Airport",
    city: "Rerrenabaque",
    country: "Bolivia",
    iata_code: "RBQ",
    _geoloc: {
      lat: -14.4275,
      lng: -67.498056,
    },
    links_count: 4,
    objectID: "7317",
    uniqueID: "89fa8de4-005a-46ad-a4de-239091ad0b17",
  },
  {
    name: "Orebro",
    city: "Orebro",
    country: "Sweden",
    iata_code: "ORB",
    _geoloc: {
      lat: 59.223733,
      lng: 15.037956,
    },
    links_count: 4,
    objectID: "731",
    uniqueID: "eea0ef58-ce5c-470a-9a1e-3887f6d4382f",
  },
  {
    name: "Arvidsjaur",
    city: "Arvidsjaur",
    country: "Sweden",
    iata_code: "AJR",
    _geoloc: {
      lat: 65.590278,
      lng: 19.281944,
    },
    links_count: 4,
    objectID: "730",
    uniqueID: "518defa4-9f54-4670-b60c-c340099d8493",
  },
  {
    name: "Rainbow Lake Airport",
    city: "Rainbow Lake",
    country: "Canada",
    iata_code: "YOP",
    _geoloc: {
      lat: 58.491389,
      lng: -119.407778,
    },
    links_count: 4,
    objectID: "7266",
    uniqueID: "d5eb66b8-bd90-420a-b305-ff5dc354dc1e",
  },
  {
    name: "Kangiqsujuaq - Wakeham Bay Airport",
    city: "Kangiqsujuaq",
    country: "Canada",
    iata_code: "YWB",
    _geoloc: {
      lat: 61.588611,
      lng: -71.929444,
    },
    links_count: 4,
    objectID: "7253",
    uniqueID: "54e5b3a7-02e1-4949-87f8-1f333bf38842",
  },
  {
    name: "Sleetmute Airport",
    city: "Sleetmute",
    country: "United States",
    iata_code: "SLQ",
    _geoloc: {
      lat: 61.700566,
      lng: -157.165833,
    },
    links_count: 4,
    objectID: "7240",
    uniqueID: "2a1549d0-f5e7-4709-8c7a-f2e8fc086b6a",
  },
  {
    name: "Wainwright Airport",
    city: "Wainwright",
    country: "United States",
    iata_code: "AIN",
    _geoloc: {
      lat: 70.638056,
      lng: -159.994722,
    },
    links_count: 4,
    objectID: "7220",
    uniqueID: "e2e7c226-828c-469e-8a53-2972592f0087",
  },
  {
    name: "Nunapitchuk Airport",
    city: "Nunapitchuk",
    country: "United States",
    iata_code: "NUP",
    _geoloc: {
      lat: 60.905833,
      lng: -162.439167,
    },
    links_count: 4,
    objectID: "7212",
    uniqueID: "4ec58eb3-52a7-46c5-9790-89661dc1f2e2",
  },
  {
    name: "Koyukuk Airport",
    city: "Koyukuk",
    country: "United States",
    iata_code: "KYU",
    _geoloc: {
      lat: 64.875833,
      lng: -157.730556,
    },
    links_count: 4,
    objectID: "7208",
    uniqueID: "e49f3424-13b0-46c6-a89e-c35b9ac6e45d",
  },
  {
    name: "Sheldon Point Airport",
    city: "Nunam Iqua",
    country: "United States",
    iata_code: "SXP",
    _geoloc: {
      lat: 62.520556,
      lng: -164.847778,
    },
    links_count: 4,
    objectID: "7200",
    uniqueID: "0f6cb872-b8b5-4fc8-a0fc-23e624024cc8",
  },
  {
    name: "Alakanuk Airport",
    city: "Alakanuk",
    country: "United States",
    iata_code: "AUK",
    _geoloc: {
      lat: 62.68,
      lng: -164.66,
    },
    links_count: 4,
    objectID: "7199",
    uniqueID: "aec182c1-9085-42af-a578-b196804aa565",
  },
  {
    name: "Selawik Airport",
    city: "Selawik",
    country: "United States",
    iata_code: "WLK",
    _geoloc: {
      lat: 66.6,
      lng: -159.985833,
    },
    links_count: 4,
    objectID: "7182",
    uniqueID: "6a1b7182-5241-4fc2-b5b4-456992cac79e",
  },
  {
    name: "Ouzinkie Airport",
    city: "Ouzinkie",
    country: "United States",
    iata_code: "KOZ",
    _geoloc: {
      lat: 57.922876,
      lng: -152.500511,
    },
    links_count: 4,
    objectID: "7164",
    uniqueID: "415709e4-982d-4a54-9ced-23e9c8104371",
  },
  {
    name: "Sveg",
    city: "Sveg",
    country: "Sweden",
    iata_code: "EVG",
    _geoloc: {
      lat: 62.047811,
      lng: 14.42295,
    },
    links_count: 4,
    objectID: "714",
    uniqueID: "089476cd-bc80-4960-8f49-4a088b4476ad",
  },
  {
    name: "Beaver Airport",
    city: "Beaver",
    country: "United States",
    iata_code: "WBQ",
    _geoloc: {
      lat: 66.362222,
      lng: -147.406667,
    },
    links_count: 4,
    objectID: "7115",
    uniqueID: "0ba3e1d0-cb9e-4a08-9078-17702b5c8c54",
  },
  {
    name: "Venetie Airport",
    city: "Venetie",
    country: "United States",
    iata_code: "VEE",
    _geoloc: {
      lat: 67.008611,
      lng: -146.366389,
    },
    links_count: 4,
    objectID: "7114",
    uniqueID: "c081b090-75e1-44f4-9e2e-0b7cab2356ec",
  },
  {
    name: "Hughes Airport",
    city: "Hughes",
    country: "United States",
    iata_code: "HUS",
    _geoloc: {
      lat: 66.039167,
      lng: -154.264722,
    },
    links_count: 4,
    objectID: "7107",
    uniqueID: "a400a4e2-9c01-416d-b695-cc14789a5b3d",
  },
  {
    name: "Chalkyitsik Airport",
    city: "Chalkyitsik",
    country: "United States",
    iata_code: "CIK",
    _geoloc: {
      lat: 66.645,
      lng: -143.74,
    },
    links_count: 4,
    objectID: "7105",
    uniqueID: "97d3b318-4fb9-49e7-a400-29b4f1c0a774",
  },
  {
    name: "Tuntutuliak Airport",
    city: "Tuntutuliak",
    country: "United States",
    iata_code: "WTL",
    _geoloc: {
      lat: 60.335278,
      lng: -162.666944,
    },
    links_count: 4,
    objectID: "7099",
    uniqueID: "fd946584-6bf9-46ec-8bfd-6bd1643e7707",
  },
  {
    name: "Marshall Don Hunter Sr. Airport",
    city: "Marshall",
    country: "United States",
    iata_code: "MLL",
    _geoloc: {
      lat: 61.8646418,
      lng: -162.026111,
    },
    links_count: 4,
    objectID: "7097",
    uniqueID: "e936e7b0-33d3-4a22-a69e-d547df45adcc",
  },
  {
    name: "Kasigluk Airport",
    city: "Kasigluk",
    country: "United States",
    iata_code: "KUK",
    _geoloc: {
      lat: 60.873333,
      lng: -162.524444,
    },
    links_count: 4,
    objectID: "7094",
    uniqueID: "f7a24e1c-abc5-4b10-aa80-67561c7694ea",
  },
  {
    name: "Nuiqsut Airport",
    city: "Nuiqsut",
    country: "United States",
    iata_code: "NUI",
    _geoloc: {
      lat: 70.21,
      lng: -151.005556,
    },
    links_count: 4,
    objectID: "7092",
    uniqueID: "87401780-54d0-4940-8ab8-11450717c2c5",
  },
  {
    name: "Mammoth Yosemite Airport",
    city: "Mammoth Lakes",
    country: "United States",
    iata_code: "MMH",
    _geoloc: {
      lat: 37.624049,
      lng: -118.837772,
    },
    links_count: 4,
    objectID: "7081",
    uniqueID: "6b8697db-bb3b-43c1-a1df-c3c137688d56",
  },
  {
    name: "Dickinson Theodore Roosevelt Regional Airport",
    city: "Dickinson",
    country: "United States",
    iata_code: "DIK",
    _geoloc: {
      lat: 46.7975,
      lng: -102.801944,
    },
    links_count: 4,
    objectID: "7064",
    uniqueID: "e35088b6-f9cb-450e-a899-7430470d437c",
  },
  {
    name: "Chautauqua County-Jamestown",
    city: "Jamestown",
    country: "United States",
    iata_code: "JHW",
    _geoloc: {
      lat: 42.153333,
      lng: -79.258056,
    },
    links_count: 4,
    objectID: "7059",
    uniqueID: "01cb80cc-4648-4e26-9261-1098e7178b72",
  },
  {
    name: "Barataevka",
    city: "Ulyanovsk",
    country: "Russia",
    iata_code: "ULV",
    _geoloc: {
      lat: 54.268299,
      lng: 48.2267,
    },
    links_count: 4,
    objectID: "7003",
    uniqueID: "c9ad2948-c401-4341-ae41-c804837cec95",
  },
  {
    name: "Aspen Pitkin County Sardy Field",
    city: "Aspen",
    country: "United States",
    iata_code: "ASE",
    _geoloc: {
      lat: 39.2232,
      lng: -106.869,
    },
    links_count: 4,
    objectID: "7001",
    uniqueID: "3e7d8369-1815-4e9d-b947-06e5e7f7dbbf",
  },
  {
    name: "Mora",
    city: "Mora",
    country: "Sweden",
    iata_code: "MXX",
    _geoloc: {
      lat: 60.957908,
      lng: 14.511383,
    },
    links_count: 4,
    objectID: "698",
    uniqueID: "18ef874c-836a-4126-96d5-a0b5ad9cc607",
  },
  {
    name: "Warri Airport",
    city: "Osubi",
    country: "Nigeria",
    iata_code: "QRW",
    _geoloc: {
      lat: 5.31,
      lng: 5.45,
    },
    links_count: 4,
    objectID: "6972",
    uniqueID: "abc353bb-cd67-491a-8cc6-6e7e4e898e41",
  },
  {
    name: "Pitt-Greenville Airport",
    city: "Greenville",
    country: "United States",
    iata_code: "PGV",
    _geoloc: {
      lat: 35.635278,
      lng: -77.385278,
    },
    links_count: 4,
    objectID: "6959",
    uniqueID: "8dbda345-ceb4-4d14-b08f-79d2417085e3",
  },
  {
    name: "Greenbrier Valley Airport",
    city: "Lewisburg",
    country: "United States",
    iata_code: "LWB",
    _geoloc: {
      lat: 37.858333,
      lng: -80.399444,
    },
    links_count: 4,
    objectID: "6958",
    uniqueID: "d14c7744-714d-4205-b2d4-d7e51b1d8a28",
  },
  {
    name: "Brainerd Lakes Rgnl",
    city: "Brainerd",
    country: "United States",
    iata_code: "BRD",
    _geoloc: {
      lat: 46.398308,
      lng: -94.138078,
    },
    links_count: 4,
    objectID: "6957",
    uniqueID: "4a485e98-4974-4210-ba12-6b9e9dadf04e",
  },
  {
    name: "Altay Airport",
    city: "Altay",
    country: "China",
    iata_code: "AAT",
    _geoloc: {
      lat: 47.866667,
      lng: 88.116667,
    },
    links_count: 4,
    objectID: "6946",
    uniqueID: "84785a4b-39dd-4fa2-9400-fea8f7d94d10",
  },
  {
    name: "Rimatara",
    city: "Rimatara",
    country: "French Polynesia",
    iata_code: "RMT",
    _geoloc: {
      lat: -22.637253,
      lng: -152.805192,
    },
    links_count: 4,
    objectID: "6926",
    uniqueID: "4696f35e-4b51-415d-98d9-e75541944ccd",
  },
  {
    name: "Ust-Kut",
    city: "Ust-Kut",
    country: "Russia",
    iata_code: "UKX",
    _geoloc: {
      lat: 56.85,
      lng: 105.7167,
    },
    links_count: 4,
    objectID: "6924",
    uniqueID: "a27d8c2b-b5b1-4f45-923a-5e5e3d434bcd",
  },
  {
    name: "Bellona",
    city: "Bellona",
    country: "Solomon Islands",
    iata_code: "BNY",
    _geoloc: {
      lat: -11.302,
      lng: 159.8,
    },
    links_count: 4,
    objectID: "6919",
    uniqueID: "06141229-09a9-40f6-8bf6-1cc853192974",
  },
  {
    name: "Jonkoping",
    city: "Joenkoeping",
    country: "Sweden",
    iata_code: "JKG",
    _geoloc: {
      lat: 57.757594,
      lng: 14.068731,
    },
    links_count: 4,
    objectID: "688",
    uniqueID: "73c51130-081e-4715-b0a0-a3716a0c59ac",
  },
  {
    name: "Ford Airport",
    city: "Iron Mountain",
    country: "United States",
    iata_code: "IMT",
    _geoloc: {
      lat: 45.8183611,
      lng: -88.1145556,
    },
    links_count: 4,
    objectID: "6837",
    uniqueID: "1b88f879-32ff-425b-84b8-309f1f988717",
  },
  {
    name: "Yakutat",
    city: "Yakutat",
    country: "United States",
    iata_code: "YAK",
    _geoloc: {
      lat: 59.3012,
      lng: -139.3937,
    },
    links_count: 4,
    objectID: "6803",
    uniqueID: "f3bb4d21-3ea4-4bc6-8f32-839da897fab7",
  },
  {
    name: "Taree Airport",
    city: "Taree",
    country: "Australia",
    iata_code: "TRO",
    _geoloc: {
      lat: -31.8886,
      lng: 152.514,
    },
    links_count: 4,
    objectID: "6794",
    uniqueID: "4a949977-dd5a-4b70-8b14-5996487ea5bb",
  },
  {
    name: "Katima Mulilo Airport",
    city: "Mpacha",
    country: "Namibia",
    iata_code: "MPA",
    _geoloc: {
      lat: -17.6344,
      lng: 24.1767,
    },
    links_count: 4,
    objectID: "6779",
    uniqueID: "9ab66410-378a-4e4f-b18c-12be4f268c74",
  },
  {
    name: "Long Akah Airport",
    city: "Long Akah",
    country: "Malaysia",
    iata_code: "LKH",
    _geoloc: {
      lat: 3.3,
      lng: 114.783,
    },
    links_count: 4,
    objectID: "6769",
    uniqueID: "a84e8465-b62f-44f3-bfbf-a2c67819276a",
  },
  {
    name: "Geneina Airport",
    city: "Geneina",
    country: "Sudan",
    iata_code: "EGN",
    _geoloc: {
      lat: 13.4817,
      lng: 22.4672,
    },
    links_count: 4,
    objectID: "6768",
    uniqueID: "b4d96834-accb-4019-9920-e5084e26f998",
  },
  {
    name: "Kangra Airport",
    city: "Kangra",
    country: "India",
    iata_code: "DHM",
    _geoloc: {
      lat: 32.1651,
      lng: 76.2634,
    },
    links_count: 4,
    objectID: "6760",
    uniqueID: "ebf1305a-b4cb-416c-8d44-2aada0d13868",
  },
  {
    name: "Tacheng Airport",
    city: "Tacheng",
    country: "China",
    iata_code: "TCG",
    _geoloc: {
      lat: 46.6725,
      lng: 83.3408,
    },
    links_count: 4,
    objectID: "6752",
    uniqueID: "5ff6bbf6-bf04-4cdc-8bc4-48ff97fc319a",
  },
  {
    name: "Sand Point Airport",
    city: "Sand Point",
    country: "United States",
    iata_code: "SDP",
    _geoloc: {
      lat: 55.315,
      lng: -160.523,
    },
    links_count: 4,
    objectID: "6742",
    uniqueID: "06310a26-dfea-431d-a0bb-676dacc1bb7f",
  },
  {
    name: "Gorakhpur Airport",
    city: "Gorakhpur",
    country: "India",
    iata_code: "GOP",
    _geoloc: {
      lat: 26.739708,
      lng: 83.449708,
    },
    links_count: 4,
    objectID: "6741",
    uniqueID: "046ac1bb-7390-4d93-b10f-998adee05aaa",
  },
  {
    name: "Araracuara Airport",
    city: "Araracuara",
    country: "Colombia",
    iata_code: "ACR",
    _geoloc: {
      lat: -0.58,
      lng: -72.41,
    },
    links_count: 4,
    objectID: "6740",
    uniqueID: "a6053669-82ae-44a4-b34f-6af8dd54ce3f",
  },
  {
    name: "Hagerstown Regional Richard A Henson Field",
    city: "Hagerstown",
    country: "United States",
    iata_code: "HGR",
    _geoloc: {
      lat: 39.7079,
      lng: -77.7295,
    },
    links_count: 4,
    objectID: "6739",
    uniqueID: "b1075345-778d-4edc-b1b5-976dbc457b6e",
  },
  {
    name: "Pajala Airport",
    city: "Pajala",
    country: "Sweden",
    iata_code: "PJA",
    _geoloc: {
      lat: 67.2456,
      lng: 23.0689,
    },
    links_count: 4,
    objectID: "6737",
    uniqueID: "d7d816fa-298b-49a6-8978-e97e4cfccc09",
  },
  {
    name: "Imo Airport",
    city: "Imo",
    country: "Nigeria",
    iata_code: "QOW",
    _geoloc: {
      lat: 5.42706,
      lng: 7.20603,
    },
    links_count: 4,
    objectID: "6730",
    uniqueID: "43c2b87b-42c4-47f6-ba40-903adee4838c",
  },
  {
    name: "Tasiujaq Airport",
    city: "Tasiujaq",
    country: "Canada",
    iata_code: "YTQ",
    _geoloc: {
      lat: 58.6678,
      lng: -69.9558,
    },
    links_count: 4,
    objectID: "6728",
    uniqueID: "9de7bfe1-fa7a-4807-a13e-412a43747790",
  },
  {
    name: "Hooper Bay Airport",
    city: "Hooper Bay",
    country: "United States",
    iata_code: "HPB",
    _geoloc: {
      lat: 61.5239,
      lng: -166.147,
    },
    links_count: 4,
    objectID: "6716",
    uniqueID: "47e04c23-3ac5-4aee-9318-131a6c1632f3",
  },
  {
    name: "Atqasuk Edward Burnell Sr Memorial Airport",
    city: "Atqasuk",
    country: "United States",
    iata_code: "ATK",
    _geoloc: {
      lat: 70.4673,
      lng: -157.436,
    },
    links_count: 4,
    objectID: "6714",
    uniqueID: "9786ce51-2b1f-40e4-8400-e50e5ba8818d",
  },
  {
    name: "Banak",
    city: "Lakselv",
    country: "Norway",
    iata_code: "LKL",
    _geoloc: {
      lat: 70.068814,
      lng: 24.973489,
    },
    links_count: 4,
    objectID: "653",
    uniqueID: "e155e7a2-82cd-4b4e-ba50-839f4a32bb37",
  },
  {
    name: "Hasvik",
    city: "Hasvik",
    country: "Norway",
    iata_code: "HAA",
    _geoloc: {
      lat: 70.486675,
      lng: 22.139744,
    },
    links_count: 4,
    objectID: "646",
    uniqueID: "14322d6e-4ad0-4c54-b379-70395aa77003",
  },
  {
    name: "Worcester Regional Airport",
    city: "Worcester",
    country: "United States",
    iata_code: "ORH",
    _geoloc: {
      lat: 42.2673,
      lng: -71.8757,
    },
    links_count: 4,
    objectID: "6426",
    uniqueID: "321567c9-b49c-4348-b873-50e810cdf4c5",
  },
  {
    name: "Karamay Airport",
    city: "Karamay",
    country: "China",
    iata_code: "KRY",
    _geoloc: {
      lat: 45.617,
      lng: 84.883,
    },
    links_count: 4,
    objectID: "6408",
    uniqueID: "d5b34a6a-f682-45c3-bef0-d185c998fe58",
  },
  {
    name: "Kuqa Airport",
    city: "Kuqa",
    country: "China",
    iata_code: "KCA",
    _geoloc: {
      lat: 41.7181,
      lng: 82.9869,
    },
    links_count: 4,
    objectID: "6406",
    uniqueID: "8083984c-dc39-4006-a81b-bd4db5bc8794",
  },
  {
    name: "Muren Airport",
    city: "Muren",
    country: "Mongolia",
    iata_code: "MXV",
    _geoloc: {
      lat: 49.6633,
      lng: 100.099,
    },
    links_count: 4,
    objectID: "6374",
    uniqueID: "bc7dd58c-48f6-4a3a-a657-9ecb23fde1e4",
  },
  {
    name: "Bayankhongor Airport",
    city: "Bayankhongor",
    country: "Mongolia",
    iata_code: "BYN",
    _geoloc: {
      lat: 46.1633,
      lng: 100.704,
    },
    links_count: 4,
    objectID: "6371",
    uniqueID: "89cb870f-ed65-4824-b0ef-c77512aef9a5",
  },
  {
    name: "Golmud Airport",
    city: "Golmud",
    country: "China",
    iata_code: "GOQ",
    _geoloc: {
      lat: 34.633,
      lng: 98.867,
    },
    links_count: 4,
    objectID: "6363",
    uniqueID: "1a75caee-1fdc-412d-8bca-e0f954d5c909",
  },
  {
    name: "Winton Airport",
    city: "Winton",
    country: "Australia",
    iata_code: "WIN",
    _geoloc: {
      lat: -22.3636,
      lng: 143.086,
    },
    links_count: 4,
    objectID: "6337",
    uniqueID: "12e49ac5-0215-4a18-9ef0-08324c226cb4",
  },
  {
    name: "Windorah Airport",
    city: "Windorah",
    country: "Australia",
    iata_code: "WNR",
    _geoloc: {
      lat: -25.4131,
      lng: 142.667,
    },
    links_count: 4,
    objectID: "6333",
    uniqueID: "b3647f29-6793-49b4-9412-d95780b29d37",
  },
  {
    name: "St George Airport",
    city: "St George",
    country: "Australia",
    iata_code: "SGO",
    _geoloc: {
      lat: -28.0497,
      lng: 148.595,
    },
    links_count: 4,
    objectID: "6325",
    uniqueID: "f4f4f91d-8adb-4c73-8d04-6b6b9c7c9211",
  },
  {
    name: "Quilpie Airport",
    city: "Quilpie",
    country: "Australia",
    iata_code: "ULP",
    _geoloc: {
      lat: -26.6122,
      lng: 144.253,
    },
    links_count: 4,
    objectID: "6322",
    uniqueID: "a4a57d82-838d-45c7-bbf7-ddb7aee78521",
  },
  {
    name: "Pormpuraaw Airport",
    city: "Pormpuraaw",
    country: "Australia",
    iata_code: "EDR",
    _geoloc: {
      lat: -14.8967,
      lng: 141.609,
    },
    links_count: 4,
    objectID: "6319",
    uniqueID: "17981065-59c2-4d68-8b47-b9c51038ad91",
  },
  {
    name: "Port Lincoln Airport",
    city: "Port Lincoln",
    country: "Australia",
    iata_code: "PLO",
    _geoloc: {
      lat: -34.6053,
      lng: 135.88,
    },
    links_count: 4,
    objectID: "6318",
    uniqueID: "8494c7ff-5bd5-40d3-a629-70fb920a893e",
  },
  {
    name: "Parkes Airport",
    city: "Parkes",
    country: "Australia",
    iata_code: "PKE",
    _geoloc: {
      lat: -33.1314,
      lng: 148.239,
    },
    links_count: 4,
    objectID: "6317",
    uniqueID: "c84ee0b4-f63e-44f6-b682-3b51c959109d",
  },
  {
    name: "Newman Airport",
    city: "Newman",
    country: "Australia",
    iata_code: "ZNE",
    _geoloc: {
      lat: -23.4178,
      lng: 119.803,
    },
    links_count: 4,
    objectID: "6310",
    uniqueID: "769fd20d-c8f0-4e87-a1e0-a8c286c27dc9",
  },
  {
    name: "Normanton Airport",
    city: "Normanton",
    country: "Australia",
    iata_code: "NTN",
    _geoloc: {
      lat: -17.6836,
      lng: 141.07,
    },
    links_count: 4,
    objectID: "6309",
    uniqueID: "90eb5d70-e5ab-4164-943b-a8678b1ae57f",
  },
  {
    name: "Narrandera Airport",
    city: "Narrandera",
    country: "Australia",
    iata_code: "NRA",
    _geoloc: {
      lat: -34.7022,
      lng: 146.512,
    },
    links_count: 4,
    objectID: "6307",
    uniqueID: "7036a730-2d22-47bd-8fa7-6fcf91d1a59a",
  },
  {
    name: "Mount Gambier Airport",
    city: "Mount Gambier",
    country: "Australia",
    iata_code: "MGB",
    _geoloc: {
      lat: -37.7456,
      lng: 140.785,
    },
    links_count: 4,
    objectID: "6303",
    uniqueID: "b3340edb-523d-4e2b-9c38-24d6b48c1c2f",
  },
  {
    name: "Moruya Airport",
    city: "Moruya",
    country: "Australia",
    iata_code: "MYA",
    _geoloc: {
      lat: -35.8978,
      lng: 150.144,
    },
    links_count: 4,
    objectID: "6302",
    uniqueID: "33b33c7a-0e9a-49b1-8c00-667727063633",
  },
  {
    name: "Gaspe",
    city: "Gaspe",
    country: "Canada",
    iata_code: "YGP",
    _geoloc: {
      lat: 48.775278,
      lng: -64.478611,
    },
    links_count: 4,
    objectID: "63",
    uniqueID: "acc83e58-ee4d-4dec-9a0e-82d5ca650824",
  },
  {
    name: "Lockhart River Airport",
    city: "Lockhart River",
    country: "Australia",
    iata_code: "IRG",
    _geoloc: {
      lat: -12.7869,
      lng: 143.305,
    },
    links_count: 4,
    objectID: "6286",
    uniqueID: "026ff728-d533-418f-8419-49eeb1327d8c",
  },
  {
    name: "Kowanyama Airport",
    city: "Kowanyama",
    country: "Australia",
    iata_code: "KWM",
    _geoloc: {
      lat: -15.4856,
      lng: 141.751,
    },
    links_count: 4,
    objectID: "6281",
    uniqueID: "a5206b0e-0fed-4978-b81d-7513a7a34d05",
  },
  {
    name: "King Island Airport",
    city: "King Island",
    country: "Australia",
    iata_code: "KNS",
    _geoloc: {
      lat: -39.8775,
      lng: 143.878,
    },
    links_count: 4,
    objectID: "6278",
    uniqueID: "7667b7ef-28c3-461b-b7f6-ccd9af46e77d",
  },
  {
    name: "Julia Creek Airport",
    city: "Julia Creek",
    country: "Australia",
    iata_code: "JCK",
    _geoloc: {
      lat: -20.6683,
      lng: 141.723,
    },
    links_count: 4,
    objectID: "6276",
    uniqueID: "29adb859-c4f6-4bf8-a9ee-661797159dbc",
  },
  {
    name: "Griffith Airport",
    city: "Griffith",
    country: "Australia",
    iata_code: "GFF",
    _geoloc: {
      lat: -34.2508,
      lng: 146.067,
    },
    links_count: 4,
    objectID: "6271",
    uniqueID: "6567e4db-0835-4607-bf54-5d9959322091",
  },
  {
    name: "Cunnamulla Airport",
    city: "Cunnamulla",
    country: "Australia",
    iata_code: "CMA",
    _geoloc: {
      lat: -28.03,
      lng: 145.622,
    },
    links_count: 4,
    objectID: "6258",
    uniqueID: "ea2a6477-72d3-4c93-a381-287724aecbca",
  },
  {
    name: "Bathurst Airport",
    city: "Bathurst",
    country: "Australia",
    iata_code: "BHS",
    _geoloc: {
      lat: -33.4094,
      lng: 149.652,
    },
    links_count: 4,
    objectID: "6249",
    uniqueID: "c8a38a22-3146-4fa5-a95f-92c32b4419ca",
  },
  {
    name: "Boulia Airport",
    city: "Boulia",
    country: "Australia",
    iata_code: "BQL",
    _geoloc: {
      lat: -22.9133,
      lng: 139.9,
    },
    links_count: 4,
    objectID: "6248",
    uniqueID: "0f6a75bb-d8e4-46a8-97a2-c06d7855f96f",
  },
  {
    name: "Burketown Airport",
    city: "Burketown",
    country: "Australia",
    iata_code: "BUC",
    _geoloc: {
      lat: -17.7486,
      lng: 139.534,
    },
    links_count: 4,
    objectID: "6245",
    uniqueID: "9de02b47-236b-44a8-bbd1-8110c1155c35",
  },
  {
    name: "Bedourie Airport",
    city: "Bedourie",
    country: "Australia",
    iata_code: "BEU",
    _geoloc: {
      lat: -24.3461,
      lng: 139.46,
    },
    links_count: 4,
    objectID: "6243",
    uniqueID: "febab2bf-5ae9-4f4f-990d-7a70810d3a24",
  },
  {
    name: "Birdsville Airport",
    city: "Birdsville",
    country: "Australia",
    iata_code: "BVI",
    _geoloc: {
      lat: -25.8975,
      lng: 139.348,
    },
    links_count: 4,
    objectID: "6240",
    uniqueID: "7c6a57a4-0cd4-4423-80c0-06261f20598d",
  },
  {
    name: "Aurukun Airport",
    city: "Aurukun",
    country: "Australia",
    iata_code: "AUU",
    _geoloc: {
      lat: -13.3539,
      lng: 141.721,
    },
    links_count: 4,
    objectID: "6237",
    uniqueID: "93a529e5-2dc4-4455-916f-6d97edfac4af",
  },
  {
    name: "Stagen Airport",
    city: "Laut Island",
    country: "Indonesia",
    iata_code: "KBU",
    _geoloc: {
      lat: -3.29472,
      lng: 116.165,
    },
    links_count: 4,
    objectID: "6231",
    uniqueID: "5835ff2a-117f-451a-a604-d331a81c20ff",
  },
  {
    name: "Kudat Airport",
    city: "Kudat",
    country: "Malaysia",
    iata_code: "KUD",
    _geoloc: {
      lat: 6.9225,
      lng: 116.836,
    },
    links_count: 4,
    objectID: "6222",
    uniqueID: "e0d4b248-b398-4e68-bfd5-35dcc435cbcc",
  },
  {
    name: "Bario Airport",
    city: "Bario",
    country: "Malaysia",
    iata_code: "BBN",
    _geoloc: {
      lat: 3.73389,
      lng: 115.479,
    },
    links_count: 4,
    objectID: "6220",
    uniqueID: "15c9d832-d4bb-4595-9042-dc1b5077bd66",
  },
  {
    name: "Mukah Airport",
    city: "Mukah",
    country: "Malaysia",
    iata_code: "MKM",
    _geoloc: {
      lat: 2.90639,
      lng: 112.08,
    },
    links_count: 4,
    objectID: "6217",
    uniqueID: "9aae0ae3-2ddb-4552-8f77-e14bca87e956",
  },
  {
    name: "Long Seridan Airport",
    city: "Long Seridan",
    country: "Malaysia",
    iata_code: "ODN",
    _geoloc: {
      lat: 3.967,
      lng: 115.05,
    },
    links_count: 4,
    objectID: "6216",
    uniqueID: "2a5eb0bf-2796-458d-ac02-848a63581f0e",
  },
  {
    name: "Long Lellang Airport",
    city: "Long Datih",
    country: "Malaysia",
    iata_code: "LGL",
    _geoloc: {
      lat: 3.421,
      lng: 115.154,
    },
    links_count: 4,
    objectID: "6215",
    uniqueID: "1ea09d6f-7815-4872-afa2-5ba6e40fdbf4",
  },
  {
    name: "Dawei Airport",
    city: "Dawei",
    country: "Burma",
    iata_code: "TVY",
    _geoloc: {
      lat: 14.103886,
      lng: 98.203636,
    },
    links_count: 4,
    objectID: "6197",
    uniqueID: "bc75ffbe-b60d-4c53-bb77-f9f95cb51d27",
  },
  {
    name: "Co Ong Airport",
    city: "Conson",
    country: "Vietnam",
    iata_code: "VCS",
    _geoloc: {
      lat: 8.731831,
      lng: 106.632589,
    },
    links_count: 4,
    objectID: "6190",
    uniqueID: "3fcbeb49-2ac5-4a87-83f7-05e3269cf884",
  },
  {
    name: "Kadhdhoo Airport",
    city: "Laamu Atoll",
    country: "Maldives",
    iata_code: "KDO",
    _geoloc: {
      lat: 1.85917,
      lng: 73.5219,
    },
    links_count: 4,
    objectID: "6185",
    uniqueID: "069838c5-f773-4297-867a-094b6a1075ca",
  },
  {
    name: "Hanimaadhoo Airport",
    city: "Haa Dhaalu Atoll",
    country: "Maldives",
    iata_code: "HAQ",
    _geoloc: {
      lat: 6.74423,
      lng: 73.1705,
    },
    links_count: 4,
    objectID: "6184",
    uniqueID: "9c3c735d-451d-4f02-a371-ef0611f06c50",
  },
  {
    name: "Dimapur Airport",
    city: "Dimapur",
    country: "India",
    iata_code: "DMU",
    _geoloc: {
      lat: 25.8839,
      lng: 93.7711,
    },
    links_count: 4,
    objectID: "6174",
    uniqueID: "4c74bf3e-1077-4b0d-9cb5-e5373fc9a31d",
  },
  {
    name: "Koggala Airport",
    city: "Koggala",
    country: "Sri Lanka",
    iata_code: "KCT",
    _geoloc: {
      lat: 5.99368,
      lng: 80.3203,
    },
    links_count: 4,
    objectID: "6169",
    uniqueID: "c14fb9e8-a3f5-4ad7-ad7c-89f50e00bf45",
  },
  {
    name: "Hubli Airport",
    city: "Hubli",
    country: "India",
    iata_code: "HBX",
    _geoloc: {
      lat: 15.3617,
      lng: 75.0849,
    },
    links_count: 4,
    objectID: "6168",
    uniqueID: "e7c0e900-c52a-4074-9363-668a3742c7d7",
  },
  {
    name: "Saransk Airport",
    city: "Saransk",
    country: "Russia",
    iata_code: "SKX",
    _geoloc: {
      lat: 54.1251,
      lng: 45.2123,
    },
    links_count: 4,
    objectID: "6166",
    uniqueID: "d4f3b477-6cf7-467d-86ba-76a30c4d3802",
  },
  {
    name: "Cheboksary Airport",
    city: "Cheboksary",
    country: "Russia",
    iata_code: "CSY",
    _geoloc: {
      lat: 56.0903,
      lng: 47.3473,
    },
    links_count: 4,
    objectID: "6162",
    uniqueID: "e164822a-59b0-4dec-8d11-720bce822786",
  },
  {
    name: "Vorkuta Airport",
    city: "Vorkuta",
    country: "Russia",
    iata_code: "VKT",
    _geoloc: {
      lat: 67.4886,
      lng: 63.9931,
    },
    links_count: 4,
    objectID: "6159",
    uniqueID: "5278d341-8249-408d-9bc9-192c6c1dc442",
  },
  {
    name: "Termez Airport",
    city: "Termez",
    country: "Uzbekistan",
    iata_code: "TMJ",
    _geoloc: {
      lat: 37.286667,
      lng: 67.31,
    },
    links_count: 4,
    objectID: "6154",
    uniqueID: "e8c98210-5e08-49c7-bb86-98f6323de372",
  },
  {
    name: "Kurgan Airport",
    city: "Kurgan",
    country: "Russia",
    iata_code: "KRO",
    _geoloc: {
      lat: 55.4753,
      lng: 65.4156,
    },
    links_count: 4,
    objectID: "6146",
    uniqueID: "81aeed9f-6704-4ee1-9238-717db363582d",
  },
  {
    name: "Spichenkovo Airport",
    city: "Novokuznetsk",
    country: "Russia",
    iata_code: "NOZ",
    _geoloc: {
      lat: 53.8114,
      lng: 86.8772,
    },
    links_count: 4,
    objectID: "6119",
    uniqueID: "eb312c6c-5911-4fd0-a47b-55a4fe4da732",
  },
  {
    name: "Ivano Frankivsk International Airport",
    city: "Ivano-Frankivsk",
    country: "Ukraine",
    iata_code: "IFO",
    _geoloc: {
      lat: 48.884167,
      lng: 24.686111,
    },
    links_count: 4,
    objectID: "6106",
    uniqueID: "4eb9703e-1012-4ee7-b0b0-1f2c0a53735b",
  },
  {
    name: "Zaporizhzhia International Airport",
    city: "Zaporozhye",
    country: "Ukraine",
    iata_code: "OZH",
    _geoloc: {
      lat: 47.867,
      lng: 35.3157,
    },
    links_count: 4,
    objectID: "6103",
    uniqueID: "e2648941-503f-455b-a506-f7e03eef509d",
  },
  {
    name: "Esbjerg",
    city: "Esbjerg",
    country: "Denmark",
    iata_code: "EBJ",
    _geoloc: {
      lat: 55.525942,
      lng: 8.553403,
    },
    links_count: 4,
    objectID: "610",
    uniqueID: "1710dc63-222c-4a25-bc8c-8d20bc9cb462",
  },
  {
    name: "Ugolny Airport",
    city: "Anadyr",
    country: "Russia",
    iata_code: "DYR",
    _geoloc: {
      lat: 64.73495,
      lng: 177.741483,
    },
    links_count: 4,
    objectID: "6098",
    uniqueID: "f8079d41-09ce-49bb-91c8-c887d72df085",
  },
  {
    name: "Tiksi Airport",
    city: "Tiksi",
    country: "Russia",
    iata_code: "IKS",
    _geoloc: {
      lat: 71.6977,
      lng: 128.903,
    },
    links_count: 4,
    objectID: "6095",
    uniqueID: "63afa0d8-9e5e-41c7-b2e4-e95fe674c7e6",
  },
  {
    name: "Cherskiy Airport",
    city: "Cherskiy",
    country: "Russia",
    iata_code: "CYX",
    _geoloc: {
      lat: 68.7406,
      lng: 161.338,
    },
    links_count: 4,
    objectID: "6094",
    uniqueID: "4e695dbb-32e1-44c7-b1e9-3ed3877d3358",
  },
  {
    name: "Chokurdakh Airport",
    city: "Chokurdah",
    country: "Russia",
    iata_code: "CKH",
    _geoloc: {
      lat: 70.6231,
      lng: 147.902,
    },
    links_count: 4,
    objectID: "6093",
    uniqueID: "01942696-fa8d-4ea3-aa53-9ed57eea544c",
  },
  {
    name: "Zhezkazgan Airport",
    city: "Zhezkazgan",
    country: "Kazakhstan",
    iata_code: "DZN",
    _geoloc: {
      lat: 47.708333,
      lng: 67.733333,
    },
    links_count: 4,
    objectID: "6085",
    uniqueID: "b4774bbb-4caf-4f7c-8a9a-d9684e69bb59",
  },
  {
    name: "Petropavlosk South Airport",
    city: "Petropavlosk",
    country: "Kazakhstan",
    iata_code: "PPK",
    _geoloc: {
      lat: 54.7747,
      lng: 69.1839,
    },
    links_count: 4,
    objectID: "6084",
    uniqueID: "0a88af79-6b2e-4205-b991-f9e6e6bcd010",
  },
  {
    name: "Kokshetau Airport",
    city: "Kokshetau",
    country: "Kazakhstan",
    iata_code: "KOV",
    _geoloc: {
      lat: 53.3291,
      lng: 69.5946,
    },
    links_count: 4,
    objectID: "6083",
    uniqueID: "e124ff10-5f9c-4074-afe9-c35b2f00b72e",
  },
  {
    name: "Ji-Paraná Airport",
    city: "Ji-Paraná",
    country: "Brazil",
    iata_code: "JPR",
    _geoloc: {
      lat: -10.8708,
      lng: -61.8465,
    },
    links_count: 4,
    objectID: "6073",
    uniqueID: "73c09f21-3106-459d-804e-a206022b4c6e",
  },
  {
    name: "Alferez Fap David Figueroa Fernandini Airport",
    city: "Huánuco",
    country: "Peru",
    iata_code: "HUU",
    _geoloc: {
      lat: -9.878811,
      lng: -76.204797,
    },
    links_count: 4,
    objectID: "6067",
    uniqueID: "9052de9b-4d83-4469-ab3c-f850e07e5b30",
  },
  {
    name: "Mayor General FAP Armando Revoredo Iglesias Airport",
    city: "Cajamarca",
    country: "Peru",
    iata_code: "CJA",
    _geoloc: {
      lat: -7.139183,
      lng: -78.4894,
    },
    links_count: 4,
    objectID: "6066",
    uniqueID: "d56c9abe-cce6-4124-8b2c-e378afcef601",
  },
  {
    name: "El Alcaraván Airport",
    city: "Yopal",
    country: "Colombia",
    iata_code: "EYP",
    _geoloc: {
      lat: 5.319114,
      lng: -72.383975,
    },
    links_count: 4,
    objectID: "6055",
    uniqueID: "a3a06f7a-2be5-4480-a407-ba054b11c425",
  },
  {
    name: "Waterford",
    city: "Waterford",
    country: "Ireland",
    iata_code: "WAT",
    _geoloc: {
      lat: 52.1872,
      lng: -7.086964,
    },
    links_count: 4,
    objectID: "605",
    uniqueID: "9e2703db-936f-4771-8cae-10cc8616cb52",
  },
  {
    name: "Camilo Ponce Enriquez Airport",
    city: "La Toma (Catamayo)",
    country: "Ecuador",
    iata_code: "LOH",
    _geoloc: {
      lat: -3.99589,
      lng: -79.3719,
    },
    links_count: 4,
    objectID: "6046",
    uniqueID: "edd6ad9d-8dd0-40a9-9224-8e843793ec02",
  },
  {
    name: "Marília Airport",
    city: "Marília",
    country: "Brazil",
    iata_code: "MII",
    _geoloc: {
      lat: -22.196892,
      lng: -49.9264,
    },
    links_count: 4,
    objectID: "6038",
    uniqueID: "839b958e-629a-4f63-ac99-2df35c2f6295",
  },
  {
    name: "Catarman National Airport",
    city: "Catarman",
    country: "Philippines",
    iata_code: "CRM",
    _geoloc: {
      lat: 12.502417,
      lng: 124.635778,
    },
    links_count: 4,
    objectID: "6024",
    uniqueID: "64f147fb-8387-48d8-b7fd-d152389bd70a",
  },
  {
    name: "Basco Airport",
    city: "Basco",
    country: "Philippines",
    iata_code: "BSO",
    _geoloc: {
      lat: 20.451322,
      lng: 121.979883,
    },
    links_count: 4,
    objectID: "6019",
    uniqueID: "f604c7e1-94cb-4638-9fc9-aca58bb21bd5",
  },
  {
    name: "Naga Airport",
    city: "Naga",
    country: "Philippines",
    iata_code: "WNP",
    _geoloc: {
      lat: 13.584886,
      lng: 123.270239,
    },
    links_count: 4,
    objectID: "6018",
    uniqueID: "aa3c9f43-2bfe-4bea-a063-63e204607d00",
  },
  {
    name: "Kunsan Air Base",
    city: "Kunsan",
    country: "South Korea",
    iata_code: "KUV",
    _geoloc: {
      lat: 35.903756,
      lng: 126.615906,
    },
    links_count: 4,
    objectID: "6003",
    uniqueID: "60c91907-af14-4897-90fc-024afdd55179",
  },
  {
    name: "Fort Simpson",
    city: "Fort Simpson",
    country: "Canada",
    iata_code: "YFS",
    _geoloc: {
      lat: 61.760153,
      lng: -121.236525,
    },
    links_count: 4,
    objectID: "60",
    uniqueID: "b43baa11-102e-4aa0-9560-29e295147cf3",
  },
  {
    name: "Wrangell Airport",
    city: "Wrangell",
    country: "United States",
    iata_code: "WRG",
    _geoloc: {
      lat: 56.4843,
      lng: -132.37,
    },
    links_count: 4,
    objectID: "5969",
    uniqueID: "1914693e-96b1-4312-aa72-5252c1a4a984",
  },
  {
    name: "Chevak Airport",
    city: "Chevak",
    country: "United States",
    iata_code: "VAK",
    _geoloc: {
      lat: 61.5338,
      lng: -165.584,
    },
    links_count: 4,
    objectID: "5968",
    uniqueID: "8367761e-d819-4c0c-9746-167b46dfe59b",
  },
  {
    name: "Mountain Village Airport",
    city: "Mountain Village",
    country: "United States",
    iata_code: "MOU",
    _geoloc: {
      lat: 62.0954,
      lng: -163.682,
    },
    links_count: 4,
    objectID: "5966",
    uniqueID: "665f88f1-59af-42e7-b0e1-e4932bd846e9",
  },
  {
    name: "Dera Ismael Khan Airport",
    city: "Dera Ismael Khan",
    country: "Pakistan",
    iata_code: "DSK",
    _geoloc: {
      lat: 31.909422,
      lng: 70.896639,
    },
    links_count: 4,
    objectID: "5943",
    uniqueID: "f785d4ee-cafc-436e-bc48-101f3f45c2b6",
  },
  {
    name: "Chitral Airport",
    city: "Chitral",
    country: "Pakistan",
    iata_code: "CJL",
    _geoloc: {
      lat: 35.886592,
      lng: 71.800578,
    },
    links_count: 4,
    objectID: "5940",
    uniqueID: "7edabb71-0e8b-40ae-8a82-ec41cf249511",
  },
  {
    name: "Al Ain International Airport",
    city: "Al Ain",
    country: "United Arab Emirates",
    iata_code: "AAN",
    _geoloc: {
      lat: 24.261667,
      lng: 55.609167,
    },
    links_count: 4,
    objectID: "5937",
    uniqueID: "1e671a14-bbc5-4c08-aef4-b9d8dbc68af3",
  },
  {
    name: "Noshahr Airport",
    city: "Noshahr",
    country: "Iran",
    iata_code: "NSH",
    _geoloc: {
      lat: 36.663333,
      lng: 51.464722,
    },
    links_count: 4,
    objectID: "5932",
    uniqueID: "afd62ce3-212f-409d-809c-be7d2fed3b77",
  },
  {
    name: "Al-Jawf Domestic Airport",
    city: "Al-Jawf",
    country: "Saudi Arabia",
    iata_code: "AJF",
    _geoloc: {
      lat: 29.785133,
      lng: 40.100006,
    },
    links_count: 4,
    objectID: "5925",
    uniqueID: "b802e155-c405-491c-a97c-082755f34f55",
  },
  {
    name: "Dawadmi Domestic Airport",
    city: "Dawadmi",
    country: "Saudi Arabia",
    iata_code: "DWD",
    _geoloc: {
      lat: 24.5,
      lng: 44.4,
    },
    links_count: 4,
    objectID: "5924",
    uniqueID: "83bc06ea-d350-4ab5-9f1b-d20f1ca032b2",
  },
  {
    name: "Tiga Airport",
    city: "Tiga",
    country: "New Caledonia",
    iata_code: "TGJ",
    _geoloc: {
      lat: -21.0961,
      lng: 167.804,
    },
    links_count: 4,
    objectID: "5919",
    uniqueID: "1289d702-f670-410c-9c81-6b9ac16fb851",
  },
  {
    name: "Futuna Airport",
    city: "Futuna Island",
    country: "Vanuatu",
    iata_code: "FTA",
    _geoloc: {
      lat: -19.5164,
      lng: 170.232,
    },
    links_count: 4,
    objectID: "5917",
    uniqueID: "fe570672-763e-451d-8ad3-f33ca9355987",
  },
  {
    name: "Dillon's Bay Airport",
    city: "Dillon's Bay",
    country: "Vanuatu",
    iata_code: "DLY",
    _geoloc: {
      lat: -18.7694,
      lng: 169.001,
    },
    links_count: 4,
    objectID: "5916",
    uniqueID: "199e2247-98bc-4cc8-a4a1-bad19d8cf715",
  },
  {
    name: "Aniwa Airport",
    city: "Aniwa",
    country: "Vanuatu",
    iata_code: "AWD",
    _geoloc: {
      lat: -19.24,
      lng: 169.605,
    },
    links_count: 4,
    objectID: "5915",
    uniqueID: "aa19efce-9c8e-4a7a-a3e0-825038b0a90a",
  },
  {
    name: "Southwest Bay Airport",
    city: "Malekula Island",
    country: "Vanuatu",
    iata_code: "SWJ",
    _geoloc: {
      lat: -16.495,
      lng: 167.438,
    },
    links_count: 4,
    objectID: "5912",
    uniqueID: "a6a3a998-dc46-45c1-b295-9137071b4aab",
  },
  {
    name: "Lamen Bay Airport",
    city: "Lamen Bay",
    country: "Vanuatu",
    iata_code: "LNB",
    _geoloc: {
      lat: -16.5842,
      lng: 168.159,
    },
    links_count: 4,
    objectID: "5902",
    uniqueID: "b0c7a941-ae32-4a1a-91c1-477029a62c71",
  },
  {
    name: "Lamap Airport",
    city: "Lamap",
    country: "Vanuatu",
    iata_code: "LPM",
    _geoloc: {
      lat: -16.454,
      lng: 167.823,
    },
    links_count: 4,
    objectID: "5901",
    uniqueID: "76f92ff7-5b50-4563-894d-20e7eeb8bd18",
  },
  {
    name: "Tavie Airport",
    city: "Paama Island",
    country: "Vanuatu",
    iata_code: "PBJ",
    _geoloc: {
      lat: -16.439,
      lng: 168.257,
    },
    links_count: 4,
    objectID: "5900",
    uniqueID: "b789063f-b2e7-490a-8809-c925cd39c565",
  },
  {
    name: "Longana Airport",
    city: "Longana",
    country: "Vanuatu",
    iata_code: "LOD",
    _geoloc: {
      lat: -15.3067,
      lng: 167.967,
    },
    links_count: 4,
    objectID: "5898",
    uniqueID: "4fc00c03-2960-4e25-9b23-b799b1810db2",
  },
  {
    name: "Labasa Airport",
    city: "Lambasa",
    country: "Fiji",
    iata_code: "LBS",
    _geoloc: {
      lat: -16.466749,
      lng: 179.33986,
    },
    links_count: 4,
    objectID: "5874",
    uniqueID: "b6d6d6f5-481c-42ad-ba72-8b97ecdc369f",
  },
  {
    name: "Vunisea Airport",
    city: "Vunisea",
    country: "Fiji",
    iata_code: "KDV",
    _geoloc: {
      lat: -19.0581,
      lng: 178.157,
    },
    links_count: 4,
    objectID: "5869",
    uniqueID: "aaba03c8-2e6b-40a3-aff2-f3743a8bf162",
  },
  {
    name: "Colonel Hill Airport",
    city: "Colonel Hill",
    country: "Bahamas",
    iata_code: "CRI",
    _geoloc: {
      lat: 22.745561,
      lng: -74.182353,
    },
    links_count: 4,
    objectID: "5859",
    uniqueID: "6dacd673-f092-4784-93b5-8b68456df612",
  },
  {
    name: "Lencero Airport",
    city: "Jalapa",
    country: "Mexico",
    iata_code: "JAL",
    _geoloc: {
      lat: 19.475083,
      lng: -96.797506,
    },
    links_count: 4,
    objectID: "5837",
    uniqueID: "0eeacf4c-8404-48f0-bbfb-ffa14eaff844",
  },
  {
    name: "Puerto Lempira Airport",
    city: "Puerto Lempira",
    country: "Honduras",
    iata_code: "PEU",
    _geoloc: {
      lat: 15.2622,
      lng: -83.7812,
    },
    links_count: 4,
    objectID: "5832",
    uniqueID: "9dd6bd4e-4543-4202-8a20-d540aa33ec63",
  },
  {
    name: "Adiyaman Airport",
    city: "Adiyaman",
    country: "Turkey",
    iata_code: "ADF",
    _geoloc: {
      lat: 37.7314,
      lng: 38.4689,
    },
    links_count: 4,
    objectID: "5800",
    uniqueID: "7c820721-f5cf-44ad-a617-6288250e019b",
  },
  {
    name: "Agri Airport",
    city: "Agri",
    country: "Turkey",
    iata_code: "AJI",
    _geoloc: {
      lat: 39.6546,
      lng: 43.0271,
    },
    links_count: 4,
    objectID: "5799",
    uniqueID: "749388a0-f992-413e-95a0-4642d85cdc0e",
  },
  {
    name: "Corvo Airport",
    city: "Corvo",
    country: "Portugal",
    iata_code: "CVU",
    _geoloc: {
      lat: 39.6715,
      lng: -31.1136,
    },
    links_count: 4,
    objectID: "5793",
    uniqueID: "1a2b16a0-85ae-434f-96b2-6593df4d1212",
  },
  {
    name: "Syros Airport",
    city: "Syros Island",
    country: "Greece",
    iata_code: "JSY",
    _geoloc: {
      lat: 37.422792,
      lng: 24.950936,
    },
    links_count: 4,
    objectID: "5787",
    uniqueID: "2a950fcb-bab8-485d-9535-69d8d57eecdf",
  },
  {
    name: "Tupelo Regional Airport",
    city: "Tupelo",
    country: "United States",
    iata_code: "TUP",
    _geoloc: {
      lat: 34.2681,
      lng: -88.7699,
    },
    links_count: 4,
    objectID: "5773",
    uniqueID: "959124cb-3909-4e7f-a752-6509acf392e0",
  },
  {
    name: "Santa Maria Pub Cpt G Allan Hancock Airport",
    city: "Santa Maria",
    country: "United States",
    iata_code: "SMX",
    _geoloc: {
      lat: 34.8989,
      lng: -120.457,
    },
    links_count: 4,
    objectID: "5772",
    uniqueID: "0b8dfa8b-a7f2-412d-a73e-ddfdcdb4bfe9",
  },
  {
    name: "Rhinelander Oneida County Airport",
    city: "Rhinelander",
    country: "United States",
    iata_code: "RHI",
    _geoloc: {
      lat: 45.6312,
      lng: -89.4675,
    },
    links_count: 4,
    objectID: "5765",
    uniqueID: "95c00713-1663-4819-a8d8-7b3c72fb09e7",
  },
  {
    name: "Owensboro Daviess County Airport",
    city: "Owensboro",
    country: "United States",
    iata_code: "OWB",
    _geoloc: {
      lat: 37.7401,
      lng: -87.1668,
    },
    links_count: 4,
    objectID: "5758",
    uniqueID: "52a07a94-e34b-4050-9483-7a9b1feaa90b",
  },
  {
    name: "Southwest Oregon Regional Airport",
    city: "North Bend",
    country: "United States",
    iata_code: "OTH",
    _geoloc: {
      lat: 43.4171,
      lng: -124.246,
    },
    links_count: 4,
    objectID: "5757",
    uniqueID: "d28e4298-5705-4cf3-aa10-fd9a7516d1c6",
  },
  {
    name: "Lynchburg Regional Preston Glenn Field",
    city: "Lynchburg",
    country: "United States",
    iata_code: "LYH",
    _geoloc: {
      lat: 37.3267,
      lng: -79.2004,
    },
    links_count: 4,
    objectID: "5753",
    uniqueID: "99a6045e-c084-44a8-8e73-f8961fae8d0f",
  },
  {
    name: "Klamath Falls Airport",
    city: "Klamath Falls",
    country: "United States",
    iata_code: "LMT",
    _geoloc: {
      lat: 42.1561,
      lng: -121.733,
    },
    links_count: 4,
    objectID: "5750",
    uniqueID: "558bd85a-fa72-4163-92b4-318b38354e55",
  },
  {
    name: "Lebanon Municipal Airport",
    city: "Lebanon",
    country: "United States",
    iata_code: "LEB",
    _geoloc: {
      lat: 43.6261,
      lng: -72.3042,
    },
    links_count: 4,
    objectID: "5749",
    uniqueID: "c3883191-d247-4003-9627-3a907a8ee684",
  },
  {
    name: "Memorial Field",
    city: "Hot Springs",
    country: "United States",
    iata_code: "HOT",
    _geoloc: {
      lat: 34.478,
      lng: -93.0962,
    },
    links_count: 4,
    objectID: "5741",
    uniqueID: "7e18c4e6-1294-4c18-a0fe-061b1b7ae8f3",
  },
  {
    name: "DuBois Regional Airport",
    city: "Du Bois",
    country: "United States",
    iata_code: "DUJ",
    _geoloc: {
      lat: 41.1783,
      lng: -78.8987,
    },
    links_count: 4,
    objectID: "5734",
    uniqueID: "f7f743d3-2877-48d8-8cd1-2bd2cb65ceaa",
  },
  {
    name: "Del Norte County Airport",
    city: "Crescent City",
    country: "United States",
    iata_code: "CEC",
    _geoloc: {
      lat: 41.7802,
      lng: -124.237,
    },
    links_count: 4,
    objectID: "5727",
    uniqueID: "d55be89c-2c4c-4a19-bd2f-7dc157d672fe",
  },
  {
    name: "Southeast Iowa Regional Airport",
    city: "Burlington",
    country: "United States",
    iata_code: "BRL",
    _geoloc: {
      lat: 40.7832,
      lng: -91.1255,
    },
    links_count: 4,
    objectID: "5726",
    uniqueID: "6790066d-fa93-455a-81da-34c817c0ddf8",
  },
  {
    name: "Bradford Regional Airport",
    city: "Bradford",
    country: "United States",
    iata_code: "BFD",
    _geoloc: {
      lat: 41.8031,
      lng: -78.6401,
    },
    links_count: 4,
    objectID: "5722",
    uniqueID: "422f57ef-fe09-4d18-9f9a-64732e22088b",
  },
  {
    name: "Alpena County Regional Airport",
    city: "Alpena",
    country: "United States",
    iata_code: "APN",
    _geoloc: {
      lat: 45.0781,
      lng: -83.5603,
    },
    links_count: 4,
    objectID: "5720",
    uniqueID: "6573e7cf-fc4b-418a-8e6d-99978b4b5f07",
  },
  {
    name: "Waterloo Regional Airport",
    city: "Waterloo",
    country: "United States",
    iata_code: "ALO",
    _geoloc: {
      lat: 42.5571,
      lng: -92.4003,
    },
    links_count: 4,
    objectID: "5718",
    uniqueID: "014f45a1-6aa3-4f5d-969c-845acd7d4810",
  },
  {
    name: "Diu Airport",
    city: "Diu",
    country: "India",
    iata_code: "DIU",
    _geoloc: {
      lat: 20.7131,
      lng: 70.9211,
    },
    links_count: 4,
    objectID: "5711",
    uniqueID: "2e3e3feb-be58-40d5-a312-03af2b38eb22",
  },
  {
    name: "Nyala Airport",
    city: "Nyala",
    country: "Sudan",
    iata_code: "UYL",
    _geoloc: {
      lat: 12.0535,
      lng: 24.9562,
    },
    links_count: 4,
    objectID: "5701",
    uniqueID: "37d2f7d5-cdd6-48de-aca2-9fdc1fea5e59",
  },
  {
    name: "Gardabya Airport",
    city: "Sirt",
    country: "Libya",
    iata_code: "SRX",
    _geoloc: {
      lat: 31.0635,
      lng: 16.595,
    },
    links_count: 4,
    objectID: "5696",
    uniqueID: "ff853e8d-b6bb-40c7-b287-a37a05acdd2b",
  },
  {
    name: "Tshikapa Airport",
    city: "Tshikapa",
    country: "Congo (Kinshasa)",
    iata_code: "TSH",
    _geoloc: {
      lat: -6.43833,
      lng: 20.7947,
    },
    links_count: 4,
    objectID: "5652",
    uniqueID: "3a8c4af1-6266-4e23-8d36-a2a834ec81aa",
  },
  {
    name: "Catumbela Airport",
    city: "Catumbela",
    country: "Angola",
    iata_code: "CBT",
    _geoloc: {
      lat: -12.4792,
      lng: 13.4869,
    },
    links_count: 4,
    objectID: "5630",
    uniqueID: "14fb11a0-da1a-4a12-b0af-7cf756e17056",
  },
  {
    name: "Solwesi Airport",
    city: "Solwesi",
    country: "Zambia",
    iata_code: "SLI",
    _geoloc: {
      lat: -12.1737,
      lng: 26.3651,
    },
    links_count: 4,
    objectID: "5613",
    uniqueID: "2e154782-5ad1-4f39-9753-662670b464ad",
  },
  {
    name: "Hemavan Airport",
    city: "Hemavan",
    country: "Sweden",
    iata_code: "HMV",
    _geoloc: {
      lat: 65.806111,
      lng: 15.082778,
    },
    links_count: 4,
    objectID: "5599",
    uniqueID: "5d40eed5-4e47-48a2-bbb4-0d7b3e8fedc7",
  },
  {
    name: "Torsby Airport",
    city: "Torsby",
    country: "Sweden",
    iata_code: "TYF",
    _geoloc: {
      lat: 60.157622,
      lng: 12.991269,
    },
    links_count: 4,
    objectID: "5596",
    uniqueID: "f9b5103f-051c-4923-a840-937ba29aa6f6",
  },
  {
    name: "Karlstad Airport",
    city: "Karlstad",
    country: "Sweden",
    iata_code: "KSD",
    _geoloc: {
      lat: 59.4447,
      lng: 13.3374,
    },
    links_count: 4,
    objectID: "5595",
    uniqueID: "0e324035-b3dc-417c-87c4-a057bdd35082",
  },
  {
    name: "Hagfors Airport",
    city: "Hagfors",
    country: "Sweden",
    iata_code: "HFS",
    _geoloc: {
      lat: 60.020064,
      lng: 13.578908,
    },
    links_count: 4,
    objectID: "5594",
    uniqueID: "1d4156bb-9b08-40aa-b5a2-ed6337855f9c",
  },
  {
    name: "Anda Airport",
    city: "Sandane",
    country: "Norway",
    iata_code: "SDN",
    _geoloc: {
      lat: 61.83,
      lng: 6.10583,
    },
    links_count: 4,
    objectID: "5585",
    uniqueID: "cbdf4f7c-687e-40dd-b971-7775f75fdf44",
  },
  {
    name: "Røst Airport",
    city: "Røst",
    country: "Norway",
    iata_code: "RET",
    _geoloc: {
      lat: 67.5278,
      lng: 12.1033,
    },
    links_count: 4,
    objectID: "5584",
    uniqueID: "42aff71a-15d9-454d-ba78-ad15458fcb30",
  },
  {
    name: "Donegal Airport",
    city: "Dongloe",
    country: "Ireland",
    iata_code: "CFN",
    _geoloc: {
      lat: 55.044192,
      lng: -8.341,
    },
    links_count: 4,
    objectID: "5577",
    uniqueID: "410c795f-82f9-4af8-8176-e0f8fcaf5bd9",
  },
  {
    name: "Westray Airport",
    city: "Westray",
    country: "United Kingdom",
    iata_code: "WRY",
    _geoloc: {
      lat: 59.3503,
      lng: -2.95,
    },
    links_count: 4,
    objectID: "5571",
    uniqueID: "48f45602-0e71-4180-b5a5-072d2f7eaf31",
  },
  {
    name: "Stronsay Airport",
    city: "Stronsay",
    country: "United Kingdom",
    iata_code: "SOY",
    _geoloc: {
      lat: 59.1553,
      lng: -2.64139,
    },
    links_count: 4,
    objectID: "5568",
    uniqueID: "6bf17ea4-0f77-4209-ac4e-911d63730dc9",
  },
  {
    name: "Churchill Falls Airport",
    city: "Churchill Falls",
    country: "Canada",
    iata_code: "ZUM",
    _geoloc: {
      lat: 53.5619,
      lng: -64.1064,
    },
    links_count: 4,
    objectID: "5550",
    uniqueID: "e092fdcc-1042-4041-83b2-e4d8bfb8508d",
  },
  {
    name: "Sachigo Lake Airport",
    city: "Sachigo Lake",
    country: "Canada",
    iata_code: "ZPB",
    _geoloc: {
      lat: 53.8911,
      lng: -92.1964,
    },
    links_count: 4,
    objectID: "5546",
    uniqueID: "2f3f6459-5e7a-4bea-b7db-b3e6e667ab40",
  },
  {
    name: "Kashechewan Airport",
    city: "Kashechewan",
    country: "Canada",
    iata_code: "ZKE",
    _geoloc: {
      lat: 52.2825,
      lng: -81.6778,
    },
    links_count: 4,
    objectID: "5543",
    uniqueID: "f1c6f545-1bf6-4125-b709-080bdce04e72",
  },
  {
    name: "Eastmain River Airport",
    city: "Eastmain River",
    country: "Canada",
    iata_code: "ZEM",
    _geoloc: {
      lat: 52.2264,
      lng: -78.5225,
    },
    links_count: 4,
    objectID: "5539",
    uniqueID: "10f69555-852d-4872-afff-091eb9674464",
  },
  {
    name: "Salluit Airport",
    city: "Salluit",
    country: "Canada",
    iata_code: "YZG",
    _geoloc: {
      lat: 62.1794,
      lng: -75.6672,
    },
    links_count: 4,
    objectID: "5535",
    uniqueID: "0ee9e839-ca9f-4ea3-9a46-b5bf62975557",
  },
  {
    name: "Webequie Airport",
    city: "Webequie",
    country: "Canada",
    iata_code: "YWP",
    _geoloc: {
      lat: 52.9597,
      lng: -87.3689,
    },
    links_count: 4,
    objectID: "5533",
    uniqueID: "587c2ad5-f30d-4857-a25d-2218a6dbdadc",
  },
  {
    name: "Wemindji Airport",
    city: "Wemindji",
    country: "Canada",
    iata_code: "YNC",
    _geoloc: {
      lat: 53.0106,
      lng: -78.8311,
    },
    links_count: 4,
    objectID: "5516",
    uniqueID: "4e504876-af10-495e-a864-79afe4760c70",
  },
  {
    name: "Lansdowne House Airport",
    city: "Lansdowne House",
    country: "Canada",
    iata_code: "YLH",
    _geoloc: {
      lat: 52.1956,
      lng: -87.9342,
    },
    links_count: 4,
    objectID: "5510",
    uniqueID: "8765a02a-5b99-48c7-b410-f47cfa2ff198",
  },
  {
    name: "Akulivik Airport",
    city: "Akulivik",
    country: "Canada",
    iata_code: "AKV",
    _geoloc: {
      lat: 60.8186,
      lng: -78.1486,
    },
    links_count: 4,
    objectID: "5506",
    uniqueID: "c5cf9651-eddc-416d-a10a-8d485af9e94b",
  },
  {
    name: "Ivujivik Airport",
    city: "Ivujivik",
    country: "Canada",
    iata_code: "YIK",
    _geoloc: {
      lat: 62.4173,
      lng: -77.9253,
    },
    links_count: 4,
    objectID: "5504",
    uniqueID: "4acc9582-50a2-4c96-9612-29a9ba53affb",
  },
  {
    name: "Nemiscau Airport",
    city: "Nemiscau",
    country: "Canada",
    iata_code: "YNS",
    _geoloc: {
      lat: 51.6911,
      lng: -76.1356,
    },
    links_count: 4,
    objectID: "5501",
    uniqueID: "b45a5bc9-2a86-4d04-8ab2-c71347ebe548",
  },
  {
    name: "Quaqtaq Airport",
    city: "Quaqtaq",
    country: "Canada",
    iata_code: "YQC",
    _geoloc: {
      lat: 61.0464,
      lng: -69.6178,
    },
    links_count: 4,
    objectID: "5499",
    uniqueID: "c7c6e06a-79bc-4743-a6a2-14dbe38d5bc5",
  },
  {
    name: "Gillam Airport",
    city: "Gillam",
    country: "Canada",
    iata_code: "YGX",
    _geoloc: {
      lat: 56.3575,
      lng: -94.7106,
    },
    links_count: 4,
    objectID: "5497",
    uniqueID: "17f66fb2-1485-4c8f-a076-de5a1dfa3c16",
  },
  {
    name: "Fort Albany Airport",
    city: "Fort Albany",
    country: "Canada",
    iata_code: "YFA",
    _geoloc: {
      lat: 52.2014,
      lng: -81.6969,
    },
    links_count: 4,
    objectID: "5490",
    uniqueID: "4402c22e-b881-403a-a797-4139c4e5bcb9",
  },
  {
    name: "Attawapiskat Airport",
    city: "Attawapiskat",
    country: "Canada",
    iata_code: "YAT",
    _geoloc: {
      lat: 52.9275,
      lng: -82.4319,
    },
    links_count: 4,
    objectID: "5482",
    uniqueID: "4d6108c3-90e8-4159-b1ae-e704ca40684b",
  },
  {
    name: "Kasabonika Airport",
    city: "Kasabonika",
    country: "Canada",
    iata_code: "XKS",
    _geoloc: {
      lat: 53.5247,
      lng: -88.6428,
    },
    links_count: 4,
    objectID: "5480",
    uniqueID: "86296521-f738-47f3-ad14-48274d87dd40",
  },
  {
    name: "Cat Lake Airport",
    city: "Cat Lake",
    country: "Canada",
    iata_code: "YAC",
    _geoloc: {
      lat: 51.7272,
      lng: -91.8244,
    },
    links_count: 4,
    objectID: "5478",
    uniqueID: "e9c9cee3-fc74-4858-8df0-d157f19f39e3",
  },
  {
    name: "La Tabatière Airport",
    city: "La Tabatière",
    country: "Canada",
    iata_code: "ZLT",
    _geoloc: {
      lat: 50.8308,
      lng: -58.9756,
    },
    links_count: 4,
    objectID: "5474",
    uniqueID: "0e5da569-4b56-498d-8b4e-9c4386bc4a4a",
  },
  {
    name: "Tête-à-la-Baleine Airport",
    city: "Tête-à-la-Baleine",
    country: "Canada",
    iata_code: "ZTB",
    _geoloc: {
      lat: 50.6744,
      lng: -59.3836,
    },
    links_count: 4,
    objectID: "5473",
    uniqueID: "794eb01e-e0d0-4230-a14e-6fabf1df9da7",
  },
  {
    name: "Poplar Hill Airport",
    city: "Poplar Hill",
    country: "Canada",
    iata_code: "YHP",
    _geoloc: {
      lat: 52.1133,
      lng: -94.2556,
    },
    links_count: 4,
    objectID: "5471",
    uniqueID: "b9fd1bc6-cd32-40f1-b630-1c6c3966a924",
  },
  {
    name: "Kingfisher Lake Airport",
    city: "Kingfisher Lake",
    country: "Canada",
    iata_code: "KIF",
    _geoloc: {
      lat: 53.0125,
      lng: -89.8553,
    },
    links_count: 4,
    objectID: "5469",
    uniqueID: "c233ed43-8b21-4e2f-b822-343761358178",
  },
  {
    name: "North Spirit Lake Airport",
    city: "North Spirit Lake",
    country: "Canada",
    iata_code: "YNO",
    _geoloc: {
      lat: 52.49,
      lng: -92.9711,
    },
    links_count: 4,
    objectID: "5467",
    uniqueID: "f98039a0-ce7b-490f-a18f-869af35d361b",
  },
  {
    name: "Rigolet Airport",
    city: "Rigolet",
    country: "Canada",
    iata_code: "YRG",
    _geoloc: {
      lat: 54.1797,
      lng: -58.4575,
    },
    links_count: 4,
    objectID: "5461",
    uniqueID: "db876580-a840-44fd-9110-bccc7729d837",
  },
  {
    name: "Qaarsut Airport",
    city: "Uummannaq",
    country: "Greenland",
    iata_code: "JQA",
    _geoloc: {
      lat: 70.7342,
      lng: -52.6962,
    },
    links_count: 4,
    objectID: "5449",
    uniqueID: "e18e5079-8659-4291-9ef4-cf5620fb1b3b",
  },
  {
    name: "Maniitsoq Airport",
    city: "Maniitsoq",
    country: "Greenland",
    iata_code: "JSU",
    _geoloc: {
      lat: 65.4125,
      lng: -52.9394,
    },
    links_count: 4,
    objectID: "5443",
    uniqueID: "39d9a28f-d6d2-4693-94af-5f72f38c5ad1",
  },
  {
    name: "Paamiut Heliport",
    city: "Paamiut",
    country: "Greenland",
    iata_code: "JFR",
    _geoloc: {
      lat: 61.9922,
      lng: -49.6625,
    },
    links_count: 4,
    objectID: "5440",
    uniqueID: "643b3529-8dac-4f8b-b943-cea58ae65436",
  },
  {
    name: "Neerlerit Inaat Airport",
    city: "Neerlerit Inaat",
    country: "Greenland",
    iata_code: "CNP",
    _geoloc: {
      lat: 70.7433,
      lng: -22.6606,
    },
    links_count: 4,
    objectID: "5439",
    uniqueID: "3393a0ed-29a0-4961-bf03-7b8132cc6f77",
  },
  {
    name: "Alluitsup Paa Heliport",
    city: "Alluitsup Paa",
    country: "Greenland",
    iata_code: "LLU",
    _geoloc: {
      lat: 60.4644,
      lng: -45.5778,
    },
    links_count: 4,
    objectID: "5438",
    uniqueID: "ef0edb14-e3ac-48a7-ad19-e5ee244b8339",
  },
  {
    name: "Misima Island Airport",
    city: "Misima Island",
    country: "Papua New Guinea",
    iata_code: "MIS",
    _geoloc: {
      lat: -10.6892,
      lng: 152.838,
    },
    links_count: 4,
    objectID: "5432",
    uniqueID: "83585e1b-b1f7-4f83-8554-74b9aff4ef89",
  },
  {
    name: "Ramata Airport",
    city: "Ramata",
    country: "Solomon Islands",
    iata_code: "RBV",
    _geoloc: {
      lat: -8.16806,
      lng: 157.643,
    },
    links_count: 4,
    objectID: "5418",
    uniqueID: "f4d5be57-54f7-43e3-9956-a9ec3504ea92",
  },
  {
    name: "Kagau Island Airport",
    city: "Kagau Island",
    country: "Solomon Islands",
    iata_code: "KGE",
    _geoloc: {
      lat: -7.333,
      lng: 157.583,
    },
    links_count: 4,
    objectID: "5417",
    uniqueID: "36742a52-7ec5-4816-bee0-8b04b2366346",
  },
  {
    name: "Suavanao Airport",
    city: "Suavanao",
    country: "Solomon Islands",
    iata_code: "VAO",
    _geoloc: {
      lat: -7.58556,
      lng: 158.731,
    },
    links_count: 4,
    objectID: "5416",
    uniqueID: "539c2eaf-2851-40db-9b01-93b6d4093ff8",
  },
  {
    name: "Rennell/Tingoa Airport",
    city: "Rennell Island",
    country: "Solomon Islands",
    iata_code: "RNL",
    _geoloc: {
      lat: -11.5339,
      lng: 160.063,
    },
    links_count: 4,
    objectID: "5414",
    uniqueID: "abdb80ad-cbca-45e1-a328-3601e83c148b",
  },
  {
    name: "Benbecula",
    city: "Benbecula",
    country: "United Kingdom",
    iata_code: "BEB",
    _geoloc: {
      lat: 57.481111,
      lng: -7.362778,
    },
    links_count: 4,
    objectID: "538",
    uniqueID: "e8431c1e-3887-41b8-a1e9-6cc82e9bca4f",
  },
  {
    name: "Wick",
    city: "Wick",
    country: "United Kingdom",
    iata_code: "WIC",
    _geoloc: {
      lat: 58.458889,
      lng: -3.093056,
    },
    links_count: 4,
    objectID: "531",
    uniqueID: "ac8ad34a-ef05-4c8a-aa01-e34a5c62a1cc",
  },
  {
    name: "Hawarden",
    city: "Hawarden",
    country: "United Kingdom",
    iata_code: "CEG",
    _geoloc: {
      lat: 53.178056,
      lng: -2.977778,
    },
    links_count: 4,
    objectID: "519",
    uniqueID: "9af347b2-5de1-4439-b0c4-48f25cbc5c8f",
  },
  {
    name: "Alderney",
    city: "Alderney",
    country: "Guernsey",
    iata_code: "ACI",
    _geoloc: {
      lat: 49.706111,
      lng: -2.214722,
    },
    links_count: 4,
    objectID: "497",
    uniqueID: "a8ee4458-0da1-4457-956b-181637f03791",
  },
  {
    name: "Dawson Creek",
    city: "Dawson Creek",
    country: "Canada",
    iata_code: "YDQ",
    _geoloc: {
      lat: 55.742333,
      lng: -120.183,
    },
    links_count: 4,
    objectID: "48",
    uniqueID: "0d76594a-fd4d-450b-9330-7d216c74eff8",
  },
  {
    name: "Savonlinna",
    city: "Savonlinna",
    country: "Finland",
    iata_code: "SVL",
    _geoloc: {
      lat: 61.943064,
      lng: 28.945136,
    },
    links_count: 4,
    objectID: "455",
    uniqueID: "a2956350-3eee-4362-8c23-822177ce4397",
  },
  {
    name: "Rovaniemi",
    city: "Rovaniemi",
    country: "Finland",
    iata_code: "RVN",
    _geoloc: {
      lat: 66.564822,
      lng: 25.830411,
    },
    links_count: 4,
    objectID: "453",
    uniqueID: "29d5c7f1-2a30-44da-b1a6-5a9a5063b538",
  },
  {
    name: "Dubuque Rgnl",
    city: "Dubuque IA",
    country: "United States",
    iata_code: "DBQ",
    _geoloc: {
      lat: 42.402,
      lng: -90.709472,
    },
    links_count: 4,
    objectID: "4388",
    uniqueID: "eb38f9b2-4802-409b-90d3-1ae66b8f01e7",
  },
  {
    name: "Camp Mabry Austin City",
    city: "Austin TX",
    country: "United States",
    iata_code: "ATT",
    _geoloc: {
      lat: 30.31666,
      lng: -97.7666,
    },
    links_count: 4,
    objectID: "4385",
    uniqueID: "80b04e5f-df46-4bc1-a053-33707d1a31d1",
  },
  {
    name: "Kittila",
    city: "Kittila",
    country: "Finland",
    iata_code: "KTT",
    _geoloc: {
      lat: 67.701022,
      lng: 24.84685,
    },
    links_count: 4,
    objectID: "438",
    uniqueID: "4f306acf-374b-46a8-9cf5-d0745a80df08",
  },
  {
    name: "Usinsk",
    city: "Usinsk",
    country: "Russia",
    iata_code: "USK",
    _geoloc: {
      lat: 66.00077,
      lng: 57.221113,
    },
    links_count: 4,
    objectID: "4369",
    uniqueID: "4fb8bc39-e3ff-452d-88c5-23fbd2ee309a",
  },
  {
    name: "Joplin Rgnl",
    city: "Joplin",
    country: "United States",
    iata_code: "JLN",
    _geoloc: {
      lat: 37.151814,
      lng: -94.498269,
    },
    links_count: 4,
    objectID: "4354",
    uniqueID: "b751e9a5-04c4-482e-92dd-7354a2b2166f",
  },
  {
    name: "Yellowstone Rgnl",
    city: "Cody",
    country: "United States",
    iata_code: "COD",
    _geoloc: {
      lat: 44.520194,
      lng: -109.023806,
    },
    links_count: 4,
    objectID: "4344",
    uniqueID: "5c81e883-38e3-446b-bfe5-10642c34f95b",
  },
  {
    name: "Kajaani",
    city: "Kajaani",
    country: "Finland",
    iata_code: "KAJ",
    _geoloc: {
      lat: 64.285472,
      lng: 27.692414,
    },
    links_count: 4,
    objectID: "433",
    uniqueID: "70cc9a34-177b-4424-9b54-02d4355c718b",
  },
  {
    name: "Edward Bodden Airfield",
    city: "Little Cayman",
    country: "Cayman Islands",
    iata_code: "LYB",
    _geoloc: {
      lat: 19.6591666667,
      lng: -80.09083333,
    },
    links_count: 4,
    objectID: "4314",
    uniqueID: "c6dd7e7b-dba7-4ef2-a2a2-0f61ec69bef3",
  },
  {
    name: "Morgantown Muni Walter L Bill Hart Fld",
    city: "Morgantown",
    country: "United States",
    iata_code: "MGW",
    _geoloc: {
      lat: 39.642908,
      lng: -79.916314,
    },
    links_count: 4,
    objectID: "4284",
    uniqueID: "0c065333-4852-4c69-8ecd-87cde9b805c3",
  },
  {
    name: "Saint Cloud Regional Airport",
    city: "Saint Cloud",
    country: "United States",
    iata_code: "STC",
    _geoloc: {
      lat: 45.546556,
      lng: -94.059889,
    },
    links_count: 4,
    objectID: "4271",
    uniqueID: "fb9a596b-ac71-4ac1-8f66-9069f88111dd",
  },
  {
    name: "Flagstaff Pulliam Airport",
    city: "Flagstaff",
    country: "United States",
    iata_code: "FLG",
    _geoloc: {
      lat: 35.140318,
      lng: -111.6692392,
    },
    links_count: 4,
    objectID: "4261",
    uniqueID: "aee68825-4b81-4137-8520-70d120fedea2",
  },
  {
    name: "Lawton-Fort Sill Regional Airport",
    city: "Lawton",
    country: "United States",
    iata_code: "LAW",
    _geoloc: {
      lat: 34.5677144,
      lng: -98.4166367,
    },
    links_count: 4,
    objectID: "4256",
    uniqueID: "f326a341-33e0-440f-8313-9ad005864dca",
  },
  {
    name: "Fort Good Hope",
    city: "Fort Good Hope",
    country: "Canada",
    iata_code: "YGH",
    _geoloc: {
      lat: 66.26666667,
      lng: -128.65,
    },
    links_count: 4,
    objectID: "4239",
    uniqueID: "bdd827e0-3657-44d4-b772-29233e04c4bd",
  },
  {
    name: "Tulita",
    city: "Tulita",
    country: "Canada",
    iata_code: "ZFN",
    _geoloc: {
      lat: 64.0833333,
      lng: -125.5833333,
    },
    links_count: 4,
    objectID: "4238",
    uniqueID: "973da80c-7aab-46af-9a65-bfab3400e190",
  },
  {
    name: "Deline",
    city: "Deline",
    country: "Canada",
    iata_code: "YWJ",
    _geoloc: {
      lat: 65.1833333,
      lng: -125.41666667,
    },
    links_count: 4,
    objectID: "4237",
    uniqueID: "e8718d95-35e1-4b0e-b33c-9adec8d412b9",
  },
  {
    name: "Kaadedhdhoo",
    city: "Kaadedhdhoo",
    country: "Maldives",
    iata_code: "KDM",
    _geoloc: {
      lat: 0.4880555,
      lng: 72.995556,
    },
    links_count: 4,
    objectID: "4235",
    uniqueID: "9d42d269-e509-465a-bc83-18c2fbe8ad3d",
  },
  {
    name: "Bundaberg",
    city: "Bundaberg",
    country: "Australia",
    iata_code: "BDB",
    _geoloc: {
      lat: -24.903889,
      lng: 152.318611,
    },
    links_count: 4,
    objectID: "4218",
    uniqueID: "535b27c6-4ba0-4766-9353-966c9ab8a2c2",
  },
  {
    name: "Caldas Novas",
    city: "Caldas Novas",
    country: "Brazil",
    iata_code: "CLV",
    _geoloc: {
      lat: -17.7267,
      lng: -48.6114,
    },
    links_count: 4,
    objectID: "4215",
    uniqueID: "a503e525-aa82-4829-8c44-8e4d94e489c7",
  },
  {
    name: "Laoag Intl",
    city: "Laoag",
    country: "Philippines",
    iata_code: "LAO",
    _geoloc: {
      lat: 18.178092,
      lng: 120.531522,
    },
    links_count: 4,
    objectID: "4202",
    uniqueID: "e0256ff1-84a2-489e-bd0b-bcc0c055834c",
  },
  {
    name: "Kastelorizo",
    city: "Kastelorizo",
    country: "Greece",
    iata_code: "KZS",
    _geoloc: {
      lat: 36.127777,
      lng: 29.566656,
    },
    links_count: 4,
    objectID: "4196",
    uniqueID: "8db6700d-57c8-42f1-8159-cfe81800bff4",
  },
  {
    name: "Paros",
    city: "Paros",
    country: "Greece",
    iata_code: "PAS",
    _geoloc: {
      lat: 37.010278,
      lng: 25.127778,
    },
    links_count: 4,
    objectID: "4195",
    uniqueID: "4db89b3c-8bed-4144-aa58-41f04e6e7ff9",
  },
  {
    name: "Naxos",
    city: "Cyclades Islands",
    country: "Greece",
    iata_code: "JNX",
    _geoloc: {
      lat: 37.080556,
      lng: 25.368056,
    },
    links_count: 4,
    objectID: "4194",
    uniqueID: "c4741bc3-e1d5-4731-b22c-a56300d9f760",
  },
  {
    name: "Milos",
    city: "Milos",
    country: "Greece",
    iata_code: "MLO",
    _geoloc: {
      lat: 36.696111,
      lng: 24.4775,
    },
    links_count: 4,
    objectID: "4193",
    uniqueID: "fb8abacf-6788-4dc1-8288-4f8c61696ed9",
  },
  {
    name: "John Murtha Johnstown-Cambria County Airport",
    city: "Johnstown",
    country: "United States",
    iata_code: "JST",
    _geoloc: {
      lat: 40.316111,
      lng: -78.833889,
    },
    links_count: 4,
    objectID: "4169",
    uniqueID: "e8dbd4f7-7267-4c04-8326-44eb6dbc28e2",
  },
  {
    name: "Tartu",
    city: "Tartu",
    country: "Estonia",
    iata_code: "TAY",
    _geoloc: {
      lat: 58.307461,
      lng: 26.690428,
    },
    links_count: 4,
    objectID: "416",
    uniqueID: "bbfa2f52-1d42-4d94-958c-8ff4d19730f0",
  },
  {
    name: "Dong Tac",
    city: "Tuy Hoa",
    country: "Vietnam",
    iata_code: "TBB",
    _geoloc: {
      lat: 13.04955,
      lng: 109.333706,
    },
    links_count: 4,
    objectID: "4158",
    uniqueID: "94e44fa5-5f38-4363-9e87-91ec3d127529",
  },
  {
    name: "Chu Lai",
    city: "Chu Lai",
    country: "Vietnam",
    iata_code: "VCL",
    _geoloc: {
      lat: 15.405944,
      lng: 108.705889,
    },
    links_count: 4,
    objectID: "4157",
    uniqueID: "1f42af83-a73c-4237-93d6-0b27841de637",
  },
  {
    name: "Dong Hoi",
    city: "Dong Hoi",
    country: "Vietnam",
    iata_code: "VDH",
    _geoloc: {
      lat: 17.515,
      lng: 106.590556,
    },
    links_count: 4,
    objectID: "4154",
    uniqueID: "5ca48799-98b1-4099-8316-96f0854f4054",
  },
  {
    name: "Petersburg James A. Johnson",
    city: "Petersburg",
    country: "United States",
    iata_code: "PSG",
    _geoloc: {
      lat: 56.801667,
      lng: -132.945278,
    },
    links_count: 4,
    objectID: "4147",
    uniqueID: "491a013e-24f0-40eb-b1d2-1638e29973e2",
  },
  {
    name: "Nan",
    city: "Nan",
    country: "Thailand",
    iata_code: "NNT",
    _geoloc: {
      lat: 18.807914,
      lng: 100.783419,
    },
    links_count: 4,
    objectID: "4131",
    uniqueID: "5c25e9e6-e048-4121-b9da-9167e5524fb7",
  },
  {
    name: "Victoria Inner Harbour Airport",
    city: "Victoria",
    country: "Canada",
    iata_code: "YWH",
    _geoloc: {
      lat: 48.422778,
      lng: -123.3875,
    },
    links_count: 4,
    objectID: "4106",
    uniqueID: "0684dde2-b893-4393-af3f-819bc129409f",
  },
  {
    name: "St George Muni",
    city: "Saint George",
    country: "United States",
    iata_code: "SGU",
    _geoloc: {
      lat: 37.090583,
      lng: -113.593056,
    },
    links_count: 4,
    objectID: "4064",
    uniqueID: "c95ff339-2947-4b33-aa0b-64363e9ab4a3",
  },
  {
    name: "Armidale",
    city: "Armidale",
    country: "Australia",
    iata_code: "ARM",
    _geoloc: {
      lat: -30.528056,
      lng: 151.617222,
    },
    links_count: 4,
    objectID: "4062",
    uniqueID: "e7e90c2d-3f59-4544-b8b5-1be8db4da18f",
  },
  {
    name: "Franklin",
    city: "Franklin",
    country: "United States",
    iata_code: "FKL",
    _geoloc: {
      lat: 41.377874,
      lng: -79.860362,
    },
    links_count: 4,
    objectID: "4058",
    uniqueID: "3629bc55-c87b-4927-823c-92861d5ea58b",
  },
  {
    name: "Hervey Bay Airport",
    city: "Hervey Bay",
    country: "Australia",
    iata_code: "HVB",
    _geoloc: {
      lat: -25.318889,
      lng: 152.880278,
    },
    links_count: 4,
    objectID: "4052",
    uniqueID: "734c23c6-b58a-44e7-a332-dcd997af52ef",
  },
  {
    name: "Decatur",
    city: "Decatur",
    country: "United States",
    iata_code: "DEC",
    _geoloc: {
      lat: 39.834564,
      lng: -88.865689,
    },
    links_count: 4,
    objectID: "4042",
    uniqueID: "9adc3514-cc37-477e-bbaf-d438de0abab9",
  },
  {
    name: "Jackson Hole Airport",
    city: "Jacksn Hole",
    country: "United States",
    iata_code: "JAC",
    _geoloc: {
      lat: 43.607333333,
      lng: -110.73775,
    },
    links_count: 4,
    objectID: "4027",
    uniqueID: "642088f8-6512-4e48-a5a0-eb3075ac6b81",
  },
  {
    name: "Redang",
    city: "Redang",
    country: "Malaysia",
    iata_code: "RDN",
    _geoloc: {
      lat: 5.76528,
      lng: 103.007,
    },
    links_count: 4,
    objectID: "4013",
    uniqueID: "b82a01c0-48ce-4b86-b619-59571691a7b8",
  },
  {
    name: "Tweed-New Haven Airport",
    city: "New Haven",
    country: "United States",
    iata_code: "HVN",
    _geoloc: {
      lat: 41.26375,
      lng: -72.886806,
    },
    links_count: 4,
    objectID: "4006",
    uniqueID: "4f681e02-5029-42eb-ba8c-15f7749b2158",
  },
  {
    name: "Kassel Calden",
    city: "Kassel",
    country: "Germany",
    iata_code: "KSF",
    _geoloc: {
      lat: 51.408394,
      lng: 9.377631,
    },
    links_count: 4,
    objectID: "400",
    uniqueID: "36a60593-a2dd-4606-afd5-6b93adb7677d",
  },
  {
    name: "Emerald",
    city: "Emerald",
    country: "Australia",
    iata_code: "EMD",
    _geoloc: {
      lat: -23.5675,
      lng: 148.179167,
    },
    links_count: 4,
    objectID: "3990",
    uniqueID: "22cca151-63b4-4289-8bfe-49a3745d6676",
  },
  {
    name: "Kapadokya",
    city: "Nevsehir",
    country: "Turkey",
    iata_code: "NAV",
    _geoloc: {
      lat: 38.771867,
      lng: 34.53455,
    },
    links_count: 4,
    objectID: "3987",
    uniqueID: "63a71516-1ae3-426c-8173-b52940815fbf",
  },
  {
    name: "Socotra Intl",
    city: "Socotra",
    country: "Yemen",
    iata_code: "SCT",
    _geoloc: {
      lat: 12.630672,
      lng: 53.905778,
    },
    links_count: 4,
    objectID: "3984",
    uniqueID: "b2f40003-2c57-4e13-b4a5-c707bc31e43f",
  },
  {
    name: "Pullman-Moscow Rgnl",
    city: "Pullman",
    country: "United States",
    iata_code: "PUW",
    _geoloc: {
      lat: 46.743861,
      lng: -117.109583,
    },
    links_count: 4,
    objectID: "3944",
    uniqueID: "0bcaa9a1-432a-43e6-8399-dad56d199bf9",
  },
  {
    name: "Khon Kaen",
    city: "Khon Kaen",
    country: "Thailand",
    iata_code: "KKC",
    _geoloc: {
      lat: 16.466628,
      lng: 102.783661,
    },
    links_count: 4,
    objectID: "3938",
    uniqueID: "fa0b7ee8-ca35-4b71-8d96-bfbb09a758be",
  },
  {
    name: "Nakhon Phanom",
    city: "Nakhon Phanom",
    country: "Thailand",
    iata_code: "KOP",
    _geoloc: {
      lat: 17.383794,
      lng: 104.643022,
    },
    links_count: 4,
    objectID: "3936",
    uniqueID: "64ad2bac-b737-4189-9e6a-fdb2236b7110",
  },
  {
    name: "Nakhon Si Thammarat",
    city: "Nakhon Si Thammarat",
    country: "Thailand",
    iata_code: "NST",
    _geoloc: {
      lat: 8.539617,
      lng: 99.944725,
    },
    links_count: 4,
    objectID: "3933",
    uniqueID: "1fc0a9f5-08cb-4e13-a98a-a1462c92d7a2",
  },
  {
    name: "Florence Rgnl",
    city: "Florence",
    country: "United States",
    iata_code: "FLO",
    _geoloc: {
      lat: 34.185361,
      lng: -79.723889,
    },
    links_count: 4,
    objectID: "3879",
    uniqueID: "e16c536d-ff5c-46de-bb6a-12855df20ca8",
  },
  {
    name: "Valdez Pioneer Fld",
    city: "Valdez",
    country: "United States",
    iata_code: "VDZ",
    _geoloc: {
      lat: 61.133949,
      lng: -146.248342,
    },
    links_count: 4,
    objectID: "3865",
    uniqueID: "b289c663-1880-49c3-8dba-dedca8ccf733",
  },
  {
    name: "Kapalua",
    city: "Lahania-kapalua",
    country: "United States",
    iata_code: "JHM",
    _geoloc: {
      lat: 20.962936,
      lng: -156.673031,
    },
    links_count: 4,
    objectID: "3796",
    uniqueID: "284aaeed-ee81-4d6f-8394-fcab0d548a53",
  },
  {
    name: "Texarkana Rgnl Webb Fld",
    city: "Texarkana",
    country: "United States",
    iata_code: "TXK",
    _geoloc: {
      lat: 33.453722,
      lng: -93.991028,
    },
    links_count: 4,
    objectID: "3770",
    uniqueID: "81a8f677-a67c-4c91-8128-95868365ec8d",
  },
  {
    name: "Middle Georgia Rgnl",
    city: "Macon",
    country: "United States",
    iata_code: "MCN",
    _geoloc: {
      lat: 32.69285,
      lng: -83.649211,
    },
    links_count: 4,
    objectID: "3754",
    uniqueID: "dd1ca5db-e829-4169-9d98-c7661411365e",
  },
  {
    name: "Sioux Gateway Col Bud Day Fld",
    city: "Sioux City",
    country: "United States",
    iata_code: "SUX",
    _geoloc: {
      lat: 42.402603,
      lng: -96.384367,
    },
    links_count: 4,
    objectID: "3753",
    uniqueID: "76652523-cc67-4185-b16c-213ac53ac6cd",
  },
  {
    name: "Roswell Intl Air Center",
    city: "Roswell",
    country: "United States",
    iata_code: "ROW",
    _geoloc: {
      lat: 33.301556,
      lng: -104.530556,
    },
    links_count: 4,
    objectID: "3736",
    uniqueID: "1d49146b-c4ee-4ce0-9f1e-2830016ba699",
  },
  {
    name: "Boeing Fld King Co Intl",
    city: "Seattle",
    country: "United States",
    iata_code: "BFI",
    _geoloc: {
      lat: 47.53,
      lng: -122.301947,
    },
    links_count: 4,
    objectID: "3726",
    uniqueID: "a0da3c5b-dfe4-490e-9bcb-fa6cf9290485",
  },
  {
    name: "Abilene Rgnl",
    city: "Abilene",
    country: "United States",
    iata_code: "ABI",
    _geoloc: {
      lat: 32.411319,
      lng: -99.681897,
    },
    links_count: 4,
    objectID: "3718",
    uniqueID: "21ad58d3-6acd-40cc-8eca-1bf651221966",
  },
  {
    name: "San Angelo Rgnl Mathis Fld",
    city: "San Angelo",
    country: "United States",
    iata_code: "SJT",
    _geoloc: {
      lat: 31.35775,
      lng: -100.496306,
    },
    links_count: 4,
    objectID: "3708",
    uniqueID: "1f9f496f-fdc9-4c40-8832-eb6dbffb5eb6",
  },
  {
    name: "Mc Kellar Sipes Rgnl",
    city: "Jackson",
    country: "United States",
    iata_code: "MKL",
    _geoloc: {
      lat: 35.599889,
      lng: -88.915611,
    },
    links_count: 4,
    objectID: "3704",
    uniqueID: "286bc582-ad66-4925-bd2a-ca860c1a855f",
  },
  {
    name: "Waco Rgnl",
    city: "Waco",
    country: "United States",
    iata_code: "ACT",
    _geoloc: {
      lat: 31.611289,
      lng: -97.230519,
    },
    links_count: 4,
    objectID: "3700",
    uniqueID: "6219e680-333d-45c6-aa98-152a724eba36",
  },
  {
    name: "Altoona Blair Co",
    city: "Altoona",
    country: "United States",
    iata_code: "AOO",
    _geoloc: {
      lat: 40.296372,
      lng: -78.320022,
    },
    links_count: 4,
    objectID: "3694",
    uniqueID: "d52b3973-627e-4731-98dd-045faa407001",
  },
  {
    name: "Imperial Co",
    city: "Imperial",
    country: "United States",
    iata_code: "IPL",
    _geoloc: {
      lat: 32.834219,
      lng: -115.578744,
    },
    links_count: 4,
    objectID: "3689",
    uniqueID: "8cc7930e-b304-4a82-ba36-5bbe37a4700a",
  },
  {
    name: "Sheppard Afb Wichita Falls Muni",
    city: "Wichita Falls",
    country: "United States",
    iata_code: "SPS",
    _geoloc: {
      lat: 33.988797,
      lng: -98.491894,
    },
    links_count: 4,
    objectID: "3680",
    uniqueID: "a9f8a9b7-1fac-4b57-873e-681d1fb096d2",
  },
  {
    name: "Rickenbacker Intl",
    city: "Columbus",
    country: "United States",
    iata_code: "LCK",
    _geoloc: {
      lat: 39.813786,
      lng: -82.927822,
    },
    links_count: 4,
    objectID: "3674",
    uniqueID: "40521909-1e04-4004-b849-1b9b0439a132",
  },
  {
    name: "Homer",
    city: "Homer",
    country: "United States",
    iata_code: "HOM",
    _geoloc: {
      lat: 59.645556,
      lng: -151.476583,
    },
    links_count: 4,
    objectID: "3664",
    uniqueID: "2c38434c-b3f0-4ebf-8f8f-faeaee2d1c2e",
  },
  {
    name: "Sloulin Fld Intl",
    city: "Williston",
    country: "United States",
    iata_code: "ISN",
    _geoloc: {
      lat: 48.177939,
      lng: -103.642347,
    },
    links_count: 4,
    objectID: "3659",
    uniqueID: "fd689d66-3c2a-4ceb-82fa-4061d1498f8f",
  },
  {
    name: "Southeast Texas Rgnl",
    city: "Beaumont",
    country: "United States",
    iata_code: "BPT",
    _geoloc: {
      lat: 29.950833,
      lng: -94.020694,
    },
    links_count: 4,
    objectID: "3612",
    uniqueID: "7a7a8da1-4b2e-4b30-b126-ecff7ee9a74c",
  },
  {
    name: "Charlo",
    city: "Charlo",
    country: "Canada",
    iata_code: "YCL",
    _geoloc: {
      lat: 47.990833,
      lng: -66.330278,
    },
    links_count: 4,
    objectID: "36",
    uniqueID: "90dc8940-1270-47cf-add5-fb3b59fe285f",
  },
  {
    name: "Williamsport Rgnl",
    city: "Williamsport",
    country: "United States",
    iata_code: "IPT",
    _geoloc: {
      lat: 41.241836,
      lng: -76.921094,
    },
    links_count: 4,
    objectID: "3584",
    uniqueID: "69b7ee8b-46ae-4fb0-81b7-b8bc9af6132a",
  },
  {
    name: "Falls Intl",
    city: "International Falls",
    country: "United States",
    iata_code: "INL",
    _geoloc: {
      lat: 48.566186,
      lng: -93.403067,
    },
    links_count: 4,
    objectID: "3535",
    uniqueID: "2df38160-758b-4bf1-bb57-4386ec7caebc",
  },
  {
    name: "Northern Maine Rgnl At Presque Isle",
    city: "Presque Isle",
    country: "United States",
    iata_code: "PQI",
    _geoloc: {
      lat: 46.688958,
      lng: -68.044797,
    },
    links_count: 4,
    objectID: "3528",
    uniqueID: "cda492d5-fcc6-4ac8-a4d4-739c07be8211",
  },
  {
    name: "Garden City Rgnl",
    city: "Garden City",
    country: "United States",
    iata_code: "GCK",
    _geoloc: {
      lat: 37.927528,
      lng: -100.724417,
    },
    links_count: 4,
    objectID: "3497",
    uniqueID: "e2b9b9c9-4f6c-400f-8e3d-2bab263c08f1",
  },
  {
    name: "East Texas Rgnl",
    city: "Longview",
    country: "United States",
    iata_code: "GGG",
    _geoloc: {
      lat: 32.384014,
      lng: -94.711486,
    },
    links_count: 4,
    objectID: "3465",
    uniqueID: "133f923a-f633-4daa-bf50-c90c3bf6e89a",
  },
  {
    name: "Boone Co",
    city: "Harrison",
    country: "United States",
    iata_code: "HRO",
    _geoloc: {
      lat: 36.261519,
      lng: -93.154728,
    },
    links_count: 4,
    objectID: "3461",
    uniqueID: "aac39cfc-b7f2-45df-9759-a59a027ebed0",
  },
  {
    name: "Point Lay Lrrs",
    city: "Point Lay",
    country: "United States",
    iata_code: "PIZ",
    _geoloc: {
      lat: 69.732875,
      lng: -163.005342,
    },
    links_count: 4,
    objectID: "3414",
    uniqueID: "73999423-cfd7-4bde-9694-3b103e941b2e",
  },
  {
    name: "Barter Island Lrrs",
    city: "Barter Island",
    country: "United States",
    iata_code: "BTI",
    _geoloc: {
      lat: 70.133989,
      lng: -143.581867,
    },
    links_count: 4,
    objectID: "3411",
    uniqueID: "c2ec3162-335b-4996-bed6-4fb91174433f",
  },
  {
    name: "Castlegar",
    city: "Castlegar",
    country: "Canada",
    iata_code: "YCG",
    _geoloc: {
      lat: 49.296389,
      lng: -117.6325,
    },
    links_count: 4,
    objectID: "34",
    uniqueID: "1a37f821-7e10-4a60-88aa-85bc5ce51f6a",
  },
  {
    name: "Christmas Island",
    city: "Christmas Island",
    country: "Christmas Island",
    iata_code: "XCH",
    _geoloc: {
      lat: -10.450556,
      lng: 105.690278,
    },
    links_count: 4,
    objectID: "3353",
    uniqueID: "76e5f661-fada-4c03-a57c-10a17b80ef00",
  },
  {
    name: "Weipa",
    city: "Weipa",
    country: "Australia",
    iata_code: "WEI",
    _geoloc: {
      lat: -12.678611,
      lng: 141.925278,
    },
    links_count: 4,
    objectID: "3331",
    uniqueID: "db01bf13-5dfc-4e40-b87e-5cf5a56d5088",
  },
  {
    name: "Sultan Azlan Shah",
    city: "Ipoh",
    country: "Malaysia",
    iata_code: "IPH",
    _geoloc: {
      lat: 4.567972,
      lng: 101.092194,
    },
    links_count: 4,
    objectID: "3302",
    uniqueID: "a8d3dda0-4045-4917-add2-05bf0c52ff2d",
  },
  {
    name: "Kerteh",
    city: "Kerteh",
    country: "Malaysia",
    iata_code: "KTE",
    _geoloc: {
      lat: 4.537222,
      lng: 103.426756,
    },
    links_count: 4,
    objectID: "3300",
    uniqueID: "ea207073-22a5-46df-964f-ad6d9b6173db",
  },
  {
    name: "Nanaimo",
    city: "Nanaimo",
    country: "Canada",
    iata_code: "YCD",
    _geoloc: {
      lat: 49.052333,
      lng: -123.870167,
    },
    links_count: 4,
    objectID: "33",
    uniqueID: "4abdf823-baad-44c2-9618-2d75bb388dc7",
  },
  {
    name: "Rahadi Usman",
    city: "Ketapang",
    country: "Indonesia",
    iata_code: "KTG",
    _geoloc: {
      lat: -1.816639,
      lng: 109.963483,
    },
    links_count: 4,
    objectID: "3282",
    uniqueID: "b25969b0-c89c-4af5-b92a-b24da13b9bd0",
  },
  {
    name: "Bubung",
    city: "Luwuk",
    country: "Indonesia",
    iata_code: "LUW",
    _geoloc: {
      lat: -1.038919,
      lng: 122.771906,
    },
    links_count: 4,
    objectID: "3254",
    uniqueID: "76987375-6aef-4a8d-9f9a-cf9e935dc74b",
  },
  {
    name: "Thandwe",
    city: "Thandwe",
    country: "Burma",
    iata_code: "SNW",
    _geoloc: {
      lat: 18.460731,
      lng: 94.300119,
    },
    links_count: 4,
    objectID: "3236",
    uniqueID: "8047d2f6-4982-47d4-89ae-a1a4872134e6",
  },
  {
    name: "Myeik",
    city: "Myeik",
    country: "Burma",
    iata_code: "MGZ",
    _geoloc: {
      lat: 12.439797,
      lng: 98.621478,
    },
    links_count: 4,
    objectID: "3223",
    uniqueID: "c372b36b-edc5-4547-a6a5-7f2fd75a3f12",
  },
  {
    name: "Trang",
    city: "Trang",
    country: "Thailand",
    iata_code: "TST",
    _geoloc: {
      lat: 7.508744,
      lng: 99.616578,
    },
    links_count: 4,
    objectID: "3182",
    uniqueID: "d932d006-decb-442e-8c6b-f8a3162c51bf",
  },
  {
    name: "U Taphao Intl",
    city: "Pattaya",
    country: "Thailand",
    iata_code: "UTP",
    _geoloc: {
      lat: 12.679944,
      lng: 101.005028,
    },
    links_count: 4,
    objectID: "3161",
    uniqueID: "1e2c91c9-e7dc-4602-9c72-6d3240e34ac6",
  },
  {
    name: "Tirupati",
    city: "Tirupeti",
    country: "India",
    iata_code: "TIR",
    _geoloc: {
      lat: 13.632492,
      lng: 79.543256,
    },
    links_count: 4,
    objectID: "3151",
    uniqueID: "825af265-dd24-468e-85fc-d9892a4226d6",
  },
  {
    name: "Allahabad",
    city: "Allahabad",
    country: "India",
    iata_code: "IXD",
    _geoloc: {
      lat: 25.440064,
      lng: 81.733872,
    },
    links_count: 4,
    objectID: "3080",
    uniqueID: "887ef9cb-bdad-42f2-bc2a-1c78b8c3403c",
  },
  {
    name: "Agra",
    city: "Agra",
    country: "India",
    iata_code: "AGR",
    _geoloc: {
      lat: 27.155831,
      lng: 77.960892,
    },
    links_count: 4,
    objectID: "3079",
    uniqueID: "fb64a15d-b93d-41cc-a155-1a09ecdc396e",
  },
  {
    name: "Porbandar",
    city: "Porbandar",
    country: "India",
    iata_code: "PBD",
    _geoloc: {
      lat: 21.648675,
      lng: 69.657219,
    },
    links_count: 4,
    objectID: "3018",
    uniqueID: "b981385b-f72a-4f72-9288-a23a084a91b7",
  },
  {
    name: "Bhavnagar",
    city: "Bhaunagar",
    country: "India",
    iata_code: "BHU",
    _geoloc: {
      lat: 21.752206,
      lng: 72.185181,
    },
    links_count: 4,
    objectID: "3003",
    uniqueID: "c5d7863a-74f5-4f40-9c5b-dc41e91db4e1",
  },
  {
    name: "Bhuj",
    city: "Bhuj",
    country: "India",
    iata_code: "BHJ",
    _geoloc: {
      lat: 23.287828,
      lng: 69.670147,
    },
    links_count: 4,
    objectID: "2999",
    uniqueID: "ef54b26c-2cb0-4cbf-a7c8-edbe9d2b0a20",
  },
  {
    name: "Deurne",
    city: "Antwerp",
    country: "Belgium",
    iata_code: "ANR",
    _geoloc: {
      lat: 51.189444,
      lng: 4.460278,
    },
    links_count: 4,
    objectID: "299",
    uniqueID: "249efc43-004a-4f03-b701-accfd596a344",
  },
  {
    name: "Magnitogorsk",
    city: "Magnetiogorsk",
    country: "Russia",
    iata_code: "MQF",
    _geoloc: {
      lat: 53.393131,
      lng: 58.755661,
    },
    links_count: 4,
    objectID: "2969",
    uniqueID: "cd661c3b-8256-4e7f-ab2a-dcb1b427ce36",
  },
  {
    name: "Gabes",
    city: "Gabes",
    country: "Tunisia",
    iata_code: "GAE",
    _geoloc: {
      lat: 33.876919,
      lng: 10.103333,
    },
    links_count: 4,
    objectID: "291",
    uniqueID: "cd1955d6-c888-4244-8ca9-37b6170ff92d",
  },
  {
    name: "Mercedita",
    city: "Ponce",
    country: "Puerto Rico",
    iata_code: "PSE",
    _geoloc: {
      lat: 18.008306,
      lng: -66.563028,
    },
    links_count: 4,
    objectID: "2889",
    uniqueID: "ea0355b9-f716-4735-b830-0440267057c7",
  },
  {
    name: "Dr Antonio Nicolas Briceno",
    city: "Valera",
    country: "Venezuela",
    iata_code: "VLV",
    _geoloc: {
      lat: 9.340797,
      lng: -70.584089,
    },
    links_count: 4,
    objectID: "2870",
    uniqueID: "b7553531-8289-4398-ba62-493f0e629ff4",
  },
  {
    name: "General Bartolome Salom Intl",
    city: "Puerto Cabello",
    country: "Venezuela",
    iata_code: "PBL",
    _geoloc: {
      lat: 10.4805,
      lng: -68.073025,
    },
    links_count: 4,
    objectID: "2854",
    uniqueID: "9b9c06a8-4a3a-4fb8-9a72-c397d72a5364",
  },
  {
    name: "Alberto Carnevalli",
    city: "Merida",
    country: "Venezuela",
    iata_code: "MRD",
    _geoloc: {
      lat: 8.582294,
      lng: -71.161186,
    },
    links_count: 4,
    objectID: "2849",
    uniqueID: "71700552-4879-4ce4-9d85-8405c67869d1",
  },
  {
    name: "Barinas",
    city: "Barinas",
    country: "Venezuela",
    iata_code: "BNS",
    _geoloc: {
      lat: 8.619575,
      lng: -70.220825,
    },
    links_count: 4,
    objectID: "2822",
    uniqueID: "47889a18-b630-4836-ab6e-2c9cea5a2eb3",
  },
  {
    name: "Capitan Fap Guillermo Concha Iberico",
    city: "Piura",
    country: "Peru",
    iata_code: "PIU",
    _geoloc: {
      lat: -5.20575,
      lng: -80.616444,
    },
    links_count: 4,
    objectID: "2809",
    uniqueID: "cd922d22-7735-458a-852f-2e5657e59703",
  },
  {
    name: "Coronel Carlos Ciriani Santa Rosa Intl",
    city: "Tacna",
    country: "Peru",
    iata_code: "TCQ",
    _geoloc: {
      lat: -18.053333,
      lng: -70.275833,
    },
    links_count: 4,
    objectID: "2807",
    uniqueID: "720d02be-2152-42ec-b568-000b5588960c",
  },
  {
    name: "Coronel Fap Alfredo Mendivil Duarte",
    city: "Ayacucho",
    country: "Peru",
    iata_code: "AYP",
    _geoloc: {
      lat: -13.154819,
      lng: -74.204417,
    },
    links_count: 4,
    objectID: "2786",
    uniqueID: "36f1ceb5-fd96-486c-adb8-1b32a531c544",
  },
  {
    name: "Capitan Nicolas Rojas",
    city: "Potosi",
    country: "Bolivia",
    iata_code: "POI",
    _geoloc: {
      lat: -19.543069,
      lng: -65.723706,
    },
    links_count: 4,
    objectID: "2764",
    uniqueID: "4989f8a5-0947-49e9-b020-7ea9db60b991",
  },
  {
    name: "Alfonso Lopez Pumarejo",
    city: "Valledupar",
    country: "Colombia",
    iata_code: "VUP",
    _geoloc: {
      lat: 10.435042,
      lng: -73.249506,
    },
    links_count: 4,
    objectID: "2755",
    uniqueID: "357f08cb-4a0e-4603-b564-c257d4d56f41",
  },
  {
    name: "Santiago Perez",
    city: "Arauca",
    country: "Colombia",
    iata_code: "AUC",
    _geoloc: {
      lat: 7.068881,
      lng: -70.736925,
    },
    links_count: 4,
    objectID: "2752",
    uniqueID: "de7c1102-af57-43dc-94f3-311f63b6242b",
  },
  {
    name: "Tame",
    city: "Tame",
    country: "Colombia",
    iata_code: "TME",
    _geoloc: {
      lat: 6.451081,
      lng: -71.760261,
    },
    links_count: 4,
    objectID: "2751",
    uniqueID: "762dbd79-482c-4396-bbdf-cadbd9e3494b",
  },
  {
    name: "Eduardo Falla Solano",
    city: "San Vincente De Caguan",
    country: "Colombia",
    iata_code: "SVI",
    _geoloc: {
      lat: 2.152175,
      lng: -74.76635,
    },
    links_count: 4,
    objectID: "2750",
    uniqueID: "55bfa574-775a-4f1e-acf2-e533dd1b0073",
  },
  {
    name: "Jorge E Gonzalez Torres",
    city: "San Jose Del Guaviare",
    country: "Colombia",
    iata_code: "SJE",
    _geoloc: {
      lat: 2.579694,
      lng: -72.639358,
    },
    links_count: 4,
    objectID: "2747",
    uniqueID: "657525ae-3389-4066-be09-5d2391339954",
  },
  {
    name: "Benito Salas",
    city: "Neiva",
    country: "Colombia",
    iata_code: "NVA",
    _geoloc: {
      lat: 2.95015,
      lng: -75.294,
    },
    links_count: 4,
    objectID: "2734",
    uniqueID: "dce28328-6bca-4654-9fa4-0875c1731406",
  },
  {
    name: "Juan Casiano",
    city: "Guapi",
    country: "Colombia",
    iata_code: "GPI",
    _geoloc: {
      lat: 2.570133,
      lng: -77.8986,
    },
    links_count: 4,
    objectID: "2722",
    uniqueID: "e1dbe795-bfb3-4d54-ba50-e628104e61ad",
  },
  {
    name: "Gustavo Artunduaga Paredes",
    city: "Florencia",
    country: "Colombia",
    iata_code: "FLA",
    _geoloc: {
      lat: 1.589189,
      lng: -75.564372,
    },
    links_count: 4,
    objectID: "2719",
    uniqueID: "7b783935-5136-47c7-8ad7-f931cebf7ed0",
  },
  {
    name: "Las Brujas",
    city: "Corozal",
    country: "Colombia",
    iata_code: "CZU",
    _geoloc: {
      lat: 9.332742,
      lng: -75.285594,
    },
    links_count: 4,
    objectID: "2717",
    uniqueID: "b021aaaa-a61c-4c8e-a71d-7c75f662f5be",
  },
  {
    name: "Gerardo Tobar Lopez",
    city: "Buenaventura",
    country: "Colombia",
    iata_code: "BUN",
    _geoloc: {
      lat: 3.819628,
      lng: -76.989767,
    },
    links_count: 4,
    objectID: "2712",
    uniqueID: "edc8b616-1c2b-4a1d-80c8-46cffe60fac6",
  },
  {
    name: "El Eden",
    city: "Armenia",
    country: "Colombia",
    iata_code: "AXM",
    _geoloc: {
      lat: 4.452775,
      lng: -75.766447,
    },
    links_count: 4,
    objectID: "2705",
    uniqueID: "f40ae657-2be7-47ad-8a53-2112bc8a857c",
  },
  {
    name: "Seymour",
    city: "Galapagos",
    country: "Ecuador",
    iata_code: "GPS",
    _geoloc: {
      lat: -0.453758,
      lng: -90.265914,
    },
    links_count: 4,
    objectID: "2672",
    uniqueID: "abd14c81-7629-421d-a420-4afc8cfa157b",
  },
  {
    name: "Pichoy",
    city: "Valdivia",
    country: "Chile",
    iata_code: "ZAL",
    _geoloc: {
      lat: -39.649956,
      lng: -73.086111,
    },
    links_count: 4,
    objectID: "2667",
    uniqueID: "e13301b6-151f-46ea-ba81-1768b953fe66",
  },
  {
    name: "Mataveri Intl",
    city: "Easter Island",
    country: "Chile",
    iata_code: "IPC",
    _geoloc: {
      lat: -27.164792,
      lng: -109.421831,
    },
    links_count: 4,
    objectID: "2657",
    uniqueID: "d9b8ee33-11c6-42f8-9bb9-56e4f5cdec5e",
  },
  {
    name: "Calabar",
    city: "Calabar",
    country: "Nigeria",
    iata_code: "CBQ",
    _geoloc: {
      lat: 4.976019,
      lng: 8.347197,
    },
    links_count: 4,
    objectID: "263",
    uniqueID: "881a4a7b-ca83-4ae1-be64-f5f70482c0e2",
  },
  {
    name: "Benin",
    city: "Benin",
    country: "Nigeria",
    iata_code: "BNI",
    _geoloc: {
      lat: 6.316981,
      lng: 5.599503,
    },
    links_count: 4,
    objectID: "262",
    uniqueID: "691af07c-8637-47c3-9a3e-78fc8f128d6d",
  },
  {
    name: "Rio Grande",
    city: "Rio Grande",
    country: "Brazil",
    iata_code: "RIG",
    _geoloc: {
      lat: -32.082617,
      lng: -52.166542,
    },
    links_count: 4,
    objectID: "2611",
    uniqueID: "a441f856-a622-459d-9e32-46d2cd59313a",
  },
  {
    name: "Santo Angelo",
    city: "Santo Angelo",
    country: "Brazil",
    iata_code: "GEL",
    _geoloc: {
      lat: -28.281683,
      lng: -54.169139,
    },
    links_count: 4,
    objectID: "2596",
    uniqueID: "ad53c7d6-72be-4d4f-b4ed-f9eb10b49a62",
  },
  {
    name: "Presidente Joao Suassuna",
    city: "Campina Grande",
    country: "Brazil",
    iata_code: "CPV",
    _geoloc: {
      lat: -7.269917,
      lng: -35.896364,
    },
    links_count: 4,
    objectID: "2577",
    uniqueID: "24a9e847-cffc-4636-a431-48101cc1618e",
  },
  {
    name: "Presidente Prudente",
    city: "President Prudente",
    country: "Brazil",
    iata_code: "PPB",
    _geoloc: {
      lat: -22.175056,
      lng: -51.424639,
    },
    links_count: 4,
    objectID: "2550",
    uniqueID: "a89cf150-a36e-4a95-822a-f1ff11c0dfc4",
  },
  {
    name: "Forquilhinha",
    city: "Criciuma",
    country: "Brazil",
    iata_code: "CCM",
    _geoloc: {
      lat: -28.725817,
      lng: -49.424739,
    },
    links_count: 4,
    objectID: "2541",
    uniqueID: "e252da49-02ac-4a54-8940-52a2cae1fcb6",
  },
  {
    name: "Aracatuba",
    city: "Aracatuba",
    country: "Brazil",
    iata_code: "ARU",
    _geoloc: {
      lat: -21.141342,
      lng: -50.424722,
    },
    links_count: 4,
    objectID: "2525",
    uniqueID: "bf986d44-a93c-46c5-852f-8ca93dd3e9c1",
  },
  {
    name: "Takoradi",
    city: "Takoradi",
    country: "Ghana",
    iata_code: "TKD",
    _geoloc: {
      lat: 4.896056,
      lng: -1.774756,
    },
    links_count: 4,
    objectID: "252",
    uniqueID: "71b2d7d1-11df-4a24-b888-f7ad85db5c04",
  },
  {
    name: "Rio Grande",
    city: "Rio Grande",
    country: "Argentina",
    iata_code: "RGA",
    _geoloc: {
      lat: -53.777667,
      lng: -67.749389,
    },
    links_count: 4,
    objectID: "2495",
    uniqueID: "772885b4-a668-4244-86b4-dbb306a7242b",
  },
  {
    name: "Jujuy",
    city: "Jujuy",
    country: "Argentina",
    iata_code: "JUJ",
    _geoloc: {
      lat: -24.392778,
      lng: -65.097778,
    },
    links_count: 4,
    objectID: "2477",
    uniqueID: "02dc5dff-690d-4b9a-bf00-1d884227c2d6",
  },
  {
    name: "Bobo Dioulasso",
    city: "Bobo-dioulasso",
    country: "Burkina Faso",
    iata_code: "BOY",
    _geoloc: {
      lat: 11.160056,
      lng: -4.330969,
    },
    links_count: 4,
    objectID: "247",
    uniqueID: "8d55cd29-da5d-4fbb-bdc8-c7b5131c5b65",
  },
  {
    name: "San Juan",
    city: "San Julian",
    country: "Argentina",
    iata_code: "UAQ",
    _geoloc: {
      lat: -31.571472,
      lng: -68.418194,
    },
    links_count: 4,
    objectID: "2461",
    uniqueID: "9176734b-40c3-49fa-80e6-3179b31487ff",
  },
  {
    name: "Teniente Benjamin Matienzo",
    city: "Tucuman",
    country: "Argentina",
    iata_code: "TUC",
    _geoloc: {
      lat: -26.840861,
      lng: -65.104944,
    },
    links_count: 4,
    objectID: "2460",
    uniqueID: "bec7c752-c5a8-4c6a-ae4e-d0098171aa0d",
  },
  {
    name: "San Rafael",
    city: "San Rafael",
    country: "Argentina",
    iata_code: "AFA",
    _geoloc: {
      lat: -34.588314,
      lng: -68.403854,
    },
    links_count: 4,
    objectID: "2454",
    uniqueID: "dace8add-68f4-4578-982c-103d4f468834",
  },
  {
    name: "Sauce Viejo",
    city: "Santa Fe",
    country: "Argentina",
    iata_code: "SFN",
    _geoloc: {
      lat: -31.711666,
      lng: -60.811668,
    },
    links_count: 4,
    objectID: "2441",
    uniqueID: "010ca6af-84d8-4235-9418-5dd732d5c999",
  },
  {
    name: "In Amenas",
    city: "Zarzaitine",
    country: "Algeria",
    iata_code: "IAM",
    _geoloc: {
      lat: 28.05155,
      lng: 9.642911,
    },
    links_count: 4,
    objectID: "244",
    uniqueID: "096ab0d5-2b05-4f3f-b800-7fe869fcf533",
  },
  {
    name: "General Urquiza",
    city: "Parana",
    country: "Argentina",
    iata_code: "PRA",
    _geoloc: {
      lat: -31.794778,
      lng: -60.480361,
    },
    links_count: 4,
    objectID: "2439",
    uniqueID: "1e190614-96e7-4bbb-9934-88a977aaf43c",
  },
  {
    name: "Pagadian",
    city: "Pagadian",
    country: "Philippines",
    iata_code: "PAG",
    _geoloc: {
      lat: 7.827197,
      lng: 123.458294,
    },
    links_count: 4,
    objectID: "2401",
    uniqueID: "b120fbb6-8d8c-4942-9b46-eee83c56139a",
  },
  {
    name: "El Golea",
    city: "El Golea",
    country: "Algeria",
    iata_code: "ELG",
    _geoloc: {
      lat: 30.571294,
      lng: 2.859586,
    },
    links_count: 4,
    objectID: "236",
    uniqueID: "f71e175a-f1b5-4c92-959c-a8550ec2fa4d",
  },
  {
    name: "Misawa Ab",
    city: "Misawa",
    country: "Japan",
    iata_code: "MSJ",
    _geoloc: {
      lat: 40.703222,
      lng: 141.368364,
    },
    links_count: 4,
    objectID: "2346",
    uniqueID: "a89121ef-6b5a-4583-b970-6fce9e25d075",
  },
  {
    name: "Tokushima",
    city: "Tokushima",
    country: "Japan",
    iata_code: "TKS",
    _geoloc: {
      lat: 34.132808,
      lng: 134.606639,
    },
    links_count: 4,
    objectID: "2336",
    uniqueID: "c12a97af-08bb-4755-9212-4951dde659b0",
  },
  {
    name: "Amami",
    city: "Amami",
    country: "Japan",
    iata_code: "ASJ",
    _geoloc: {
      lat: 28.430633,
      lng: 129.712542,
    },
    links_count: 4,
    objectID: "2316",
    uniqueID: "70cdcfcd-4978-4fe0-b233-dbf8ee976182",
  },
  {
    name: "Iki",
    city: "Iki",
    country: "Japan",
    iata_code: "IKI",
    _geoloc: {
      lat: 33.749027,
      lng: 129.785417,
    },
    links_count: 4,
    objectID: "2295",
    uniqueID: "e01a5d75-b362-4dae-a36c-818b1b65a143",
  },
  {
    name: "Wakkanai",
    city: "Wakkanai",
    country: "Japan",
    iata_code: "WKJ",
    _geoloc: {
      lat: 45.404167,
      lng: 141.800833,
    },
    links_count: 4,
    objectID: "2294",
    uniqueID: "e6b26740-bdaa-404d-8b0d-c15ae0d05984",
  },
  {
    name: "Nakashibetsu",
    city: "Nakashibetsu",
    country: "Japan",
    iata_code: "SHB",
    _geoloc: {
      lat: 43.5775,
      lng: 144.96,
    },
    links_count: 4,
    objectID: "2291",
    uniqueID: "1b97be5f-3a66-4ac9-8431-586e3dfb150b",
  },
  {
    name: "Matsumoto",
    city: "Matsumoto",
    country: "Japan",
    iata_code: "MMJ",
    _geoloc: {
      lat: 36.166758,
      lng: 137.922669,
    },
    links_count: 4,
    objectID: "2280",
    uniqueID: "097b7073-6ef7-41f2-8b55-b513c27e89e4",
  },
  {
    name: "Yap Intl",
    city: "Yap",
    country: "Micronesia",
    iata_code: "YAP",
    _geoloc: {
      lat: 9.498911,
      lng: 138.082497,
    },
    links_count: 4,
    objectID: "2258",
    uniqueID: "697f903f-e47f-4132-b99f-1a4b6e95349b",
  },
  {
    name: "Kosrae",
    city: "Kosrae",
    country: "Micronesia",
    iata_code: "KSA",
    _geoloc: {
      lat: 5.356975,
      lng: 162.958386,
    },
    links_count: 4,
    objectID: "2257",
    uniqueID: "fbe4dcaa-1675-4edc-a446-bbd497268857",
  },
  {
    name: "Chuuk Intl",
    city: "Chuuk",
    country: "Micronesia",
    iata_code: "TKK",
    _geoloc: {
      lat: 7.461869,
      lng: 151.843006,
    },
    links_count: 4,
    objectID: "2254",
    uniqueID: "4f32e0e5-9c72-4648-aa1b-a5176dbdfb84",
  },
  {
    name: "Rota Intl",
    city: "Rota",
    country: "Northern Mariana Islands",
    iata_code: "ROP",
    _geoloc: {
      lat: 14.174308,
      lng: 145.242536,
    },
    links_count: 4,
    objectID: "2243",
    uniqueID: "8d0868f5-d41e-4e9c-a4a8-9e12cf3cde19",
  },
  {
    name: "Hassi R Mel",
    city: "Tilrempt",
    country: "Algeria",
    iata_code: "HRM",
    _geoloc: {
      lat: 32.930431,
      lng: 3.311542,
    },
    links_count: 4,
    objectID: "224",
    uniqueID: "c19202c7-1540-425d-9fef-8638ad7ae2af",
  },
  {
    name: "Zhob",
    city: "Zhob",
    country: "Pakistan",
    iata_code: "PZH",
    _geoloc: {
      lat: 31.358381,
      lng: 69.463606,
    },
    links_count: 4,
    objectID: "2233",
    uniqueID: "1fb2ab9d-a8da-4569-a19d-ea07cc6c360e",
  },
  {
    name: "Moenjodaro",
    city: "Moenjodaro",
    country: "Pakistan",
    iata_code: "MJD",
    _geoloc: {
      lat: 27.335156,
      lng: 68.143053,
    },
    links_count: 4,
    objectID: "2212",
    uniqueID: "aabf3205-0bea-4ba8-80ea-659e4faf77fa",
  },
  {
    name: "Faisalabad Intl",
    city: "Faisalabad",
    country: "Pakistan",
    iata_code: "LYP",
    _geoloc: {
      lat: 31.365014,
      lng: 72.994842,
    },
    links_count: 4,
    objectID: "2202",
    uniqueID: "83fffcea-262b-4b28-8b7b-75d1803d1fc8",
  },
  {
    name: "Aqaba King Hussein Intl",
    city: "Aqaba",
    country: "Jordan",
    iata_code: "AQJ",
    _geoloc: {
      lat: 29.611619,
      lng: 35.018067,
    },
    links_count: 4,
    objectID: "2172",
    uniqueID: "d49f64fa-a80e-4515-b6ac-f1f654d6bea7",
  },
  {
    name: "Ramsar",
    city: "Ramsar",
    country: "Iran",
    iata_code: "RZR",
    _geoloc: {
      lat: 36.909908,
      lng: 50.679589,
    },
    links_count: 4,
    objectID: "2151",
    uniqueID: "7759de76-820a-4a11-8196-e7e302344d6a",
  },
  {
    name: "Birjand",
    city: "Birjand",
    country: "Iran",
    iata_code: "XBJ",
    _geoloc: {
      lat: 32.898056,
      lng: 59.266111,
    },
    links_count: 4,
    objectID: "2142",
    uniqueID: "1a8a6bd3-10c8-4cbc-8293-05a8098a9f6f",
  },
  {
    name: "Bandar Lengeh",
    city: "Bandar Lengeh",
    country: "Iran",
    iata_code: "BDH",
    _geoloc: {
      lat: 26.532,
      lng: 54.824847,
    },
    links_count: 4,
    objectID: "2110",
    uniqueID: "d8b29b51-c807-44f3-9b28-86029990720b",
  },
  {
    name: "Mahshahr",
    city: "Bandar Mahshahr",
    country: "Iran",
    iata_code: "MRX",
    _geoloc: {
      lat: 30.556192,
      lng: 49.151879,
    },
    links_count: 4,
    objectID: "2103",
    uniqueID: "493a9e84-8870-43bf-a305-05bb370c89d5",
  },
  {
    name: "Sharurah",
    city: "Sharurah",
    country: "Saudi Arabia",
    iata_code: "SHW",
    _geoloc: {
      lat: 17.466875,
      lng: 47.121431,
    },
    links_count: 4,
    objectID: "2086",
    uniqueID: "83c5eacc-086d-4ab2-a6ab-d1660759df00",
  },
  {
    name: "Arar",
    city: "Arar",
    country: "Saudi Arabia",
    iata_code: "RAE",
    _geoloc: {
      lat: 30.906589,
      lng: 41.138217,
    },
    links_count: 4,
    objectID: "2084",
    uniqueID: "3e8ce919-3aa6-471d-84ff-e0689fb70f11",
  },
  {
    name: "Qaisumah",
    city: "Hafr Al-batin",
    country: "Saudi Arabia",
    iata_code: "AQI",
    _geoloc: {
      lat: 28.335192,
      lng: 46.125069,
    },
    links_count: 4,
    objectID: "2076",
    uniqueID: "c51c6ea5-5b10-4a8a-83d7-1e0d6134bf11",
  },
  {
    name: "Guriat",
    city: "Guriat",
    country: "Saudi Arabia",
    iata_code: "URY",
    _geoloc: {
      lat: 31.411942,
      lng: 37.279469,
    },
    links_count: 4,
    objectID: "2068",
    uniqueID: "1b10d84d-ebb2-4b13-bc2b-fc3c9adde0c8",
  },
  {
    name: "Whangarei",
    city: "Whangarei",
    country: "New Zealand",
    iata_code: "WRE",
    _geoloc: {
      lat: -35.768333,
      lng: 174.365,
    },
    links_count: 4,
    objectID: "2045",
    uniqueID: "b5037568-c0c0-4fa2-8ae8-b55afec2734d",
  },
  {
    name: "Paraparaumu",
    city: "Paraparaumu",
    country: "New Zealand",
    iata_code: "PPQ",
    _geoloc: {
      lat: -40.904722,
      lng: 174.989167,
    },
    links_count: 4,
    objectID: "2029",
    uniqueID: "0aebaba9-dd62-470b-9562-06751eb2fe0f",
  },
  {
    name: "Invercargill",
    city: "Invercargill",
    country: "New Zealand",
    iata_code: "IVC",
    _geoloc: {
      lat: -46.412408,
      lng: 168.312992,
    },
    links_count: 4,
    objectID: "2025",
    uniqueID: "8f9ef34f-1572-4300-a201-348509262bc3",
  },
  {
    name: "Gisborne",
    city: "Gisborne",
    country: "New Zealand",
    iata_code: "GIS",
    _geoloc: {
      lat: -38.663333,
      lng: 177.978333,
    },
    links_count: 4,
    objectID: "2012",
    uniqueID: "9efe0a3f-1ebf-493a-a162-1f4c8f97fc3b",
  },
  {
    name: "Taupo",
    city: "Taupo",
    country: "New Zealand",
    iata_code: "TUO",
    _geoloc: {
      lat: -38.739723,
      lng: 176.084444,
    },
    links_count: 4,
    objectID: "2007",
    uniqueID: "a07234ec-f02c-40a1-97fc-4a971742f01f",
  },
  {
    name: "Ouvea",
    city: "Ouvea",
    country: "New Caledonia",
    iata_code: "UVE",
    _geoloc: {
      lat: -20.640556,
      lng: 166.572778,
    },
    links_count: 4,
    objectID: "2004",
    uniqueID: "972ca7aa-cafc-404d-aff0-5f4000e519ae",
  },
  {
    name: "Port Hardy",
    city: "Port Hardy",
    country: "Canada",
    iata_code: "YZT",
    _geoloc: {
      lat: 50.680556,
      lng: -127.366667,
    },
    links_count: 4,
    objectID: "200",
    uniqueID: "c9ab9cb1-1cca-4cb8-8c1f-57547289c7fb",
  },
  {
    name: "Koumac",
    city: "Koumac",
    country: "New Caledonia",
    iata_code: "KOC",
    _geoloc: {
      lat: -20.546314,
      lng: 164.255625,
    },
    links_count: 4,
    objectID: "1999",
    uniqueID: "2e6ca874-1db7-4713-b2bb-1047874a9383",
  },
  {
    name: "Mataiva",
    city: "Mataiva",
    country: "French Polynesia",
    iata_code: "MVT",
    _geoloc: {
      lat: -14.868055,
      lng: -148.717225,
    },
    links_count: 4,
    objectID: "1985",
    uniqueID: "40c36c1a-fc3d-4ee9-b58a-cb73bab59429",
  },
  {
    name: "Fakarava",
    city: "Fakarava",
    country: "French Polynesia",
    iata_code: "FAV",
    _geoloc: {
      lat: -16.05415,
      lng: -145.656994,
    },
    links_count: 4,
    objectID: "1977",
    uniqueID: "3578d7a7-22a6-4402-bc00-81d97d71e7be",
  },
  {
    name: "Mayaguana",
    city: "Mayaguana",
    country: "Bahamas",
    iata_code: "MYG",
    _geoloc: {
      lat: 22.379528,
      lng: -73.0135,
    },
    links_count: 4,
    objectID: "1952",
    uniqueID: "2b04216a-e3bc-4b88-bf36-87f602e971b1",
  },
  {
    name: "Stella Maris",
    city: "Stella Maris",
    country: "Bahamas",
    iata_code: "SML",
    _geoloc: {
      lat: 23.581444,
      lng: -75.270475,
    },
    links_count: 4,
    objectID: "1951",
    uniqueID: "0bc8e616-f8c4-4bae-9b23-bb99df1a9f4f",
  },
  {
    name: "Deadmans Cay",
    city: "Dead Man's Cay",
    country: "Bahamas",
    iata_code: "LGI",
    _geoloc: {
      lat: 23.179014,
      lng: -75.093597,
    },
    links_count: 4,
    objectID: "1950",
    uniqueID: "89a49fc7-f90b-4e8b-bace-ad4f8585924d",
  },
  {
    name: "Matthew Town",
    city: "Matthew Town",
    country: "Bahamas",
    iata_code: "IGA",
    _geoloc: {
      lat: 20.975,
      lng: -73.666862,
    },
    links_count: 4,
    objectID: "1949",
    uniqueID: "39008acb-1ca1-4d22-a760-935a76c0aad4",
  },
  {
    name: "Rock Sound",
    city: "Rock Sound",
    country: "Bahamas",
    iata_code: "RSD",
    _geoloc: {
      lat: 24.8917,
      lng: -76.177739,
    },
    links_count: 4,
    objectID: "1946",
    uniqueID: "7cc38639-4ba9-4348-93c2-658be211b540",
  },
  {
    name: "South Bimini",
    city: "Alice Town",
    country: "Bahamas",
    iata_code: "BIM",
    _geoloc: {
      lat: 25.699881,
      lng: -79.264656,
    },
    links_count: 4,
    objectID: "1937",
    uniqueID: "37a15f8d-5e75-41cb-b7c9-dcb14e74607e",
  },
  {
    name: "Treasure Cay",
    city: "Treasure Cay",
    country: "Bahamas",
    iata_code: "TCB",
    _geoloc: {
      lat: 26.745336,
      lng: -77.391269,
    },
    links_count: 4,
    objectID: "1934",
    uniqueID: "62af715c-f064-4589-93c1-c501b882877e",
  },
  {
    name: "Spring Point",
    city: "Spring Point",
    country: "Bahamas",
    iata_code: "AXP",
    _geoloc: {
      lat: 22.441828,
      lng: -73.970858,
    },
    links_count: 4,
    objectID: "1932",
    uniqueID: "f4bbc185-5c12-45ad-bd6e-85bba7ceafa7",
  },
  {
    name: "Enrique Malek Intl",
    city: "David",
    country: "Panama",
    iata_code: "DAV",
    _geoloc: {
      lat: 8.391003,
      lng: -82.434992,
    },
    links_count: 4,
    objectID: "1867",
    uniqueID: "172fff5b-74a2-4470-8890-6fa546b4db1c",
  },
  {
    name: "Tapachula Intl",
    city: "Tapachula",
    country: "Mexico",
    iata_code: "TAP",
    _geoloc: {
      lat: 14.794339,
      lng: -92.370025,
    },
    links_count: 4,
    objectID: "1851",
    uniqueID: "ff70fb49-e4e7-4962-ad59-08a1c67184a4",
  },
  {
    name: "Licenciado Y Gen Ignacio Lopez Rayon",
    city: "Uruapan",
    country: "Mexico",
    iata_code: "UPN",
    _geoloc: {
      lat: 19.396692,
      lng: -102.039056,
    },
    links_count: 4,
    objectID: "1835",
    uniqueID: "4083d715-6b79-4aac-8163-c64be06e068e",
  },
  {
    name: "Piedras Negras Intl",
    city: "Piedras Negras",
    country: "Mexico",
    iata_code: "PDS",
    _geoloc: {
      lat: 28.627394,
      lng: -100.535211,
    },
    links_count: 4,
    objectID: "1834",
    uniqueID: "77615f24-5c3b-476a-9983-9eb31cea0232",
  },
  {
    name: "Minatitlan",
    city: "Minatitlan",
    country: "Mexico",
    iata_code: "MTT",
    _geoloc: {
      lat: 18.103419,
      lng: -94.580681,
    },
    links_count: 4,
    objectID: "1822",
    uniqueID: "ba97aa3f-892c-4a1f-a31f-a7b6b65aabd3",
  },
  {
    name: "General Servando Canales Intl",
    city: "Matamoros",
    country: "Mexico",
    iata_code: "MAM",
    _geoloc: {
      lat: 25.769894,
      lng: -97.525311,
    },
    links_count: 4,
    objectID: "1818",
    uniqueID: "1d15f65c-8561-4638-85cb-a6ff37652e01",
  },
  {
    name: "Lazaro Cardenas",
    city: "Lazard Cardenas",
    country: "Mexico",
    iata_code: "LZC",
    _geoloc: {
      lat: 18.001731,
      lng: -102.220525,
    },
    links_count: 4,
    objectID: "1813",
    uniqueID: "3623b83d-6634-4321-b0dc-f39780297620",
  },
  {
    name: "Plan De Guadalupe Intl",
    city: "Saltillo",
    country: "Mexico",
    iata_code: "SLW",
    _geoloc: {
      lat: 25.549497,
      lng: -100.928669,
    },
    links_count: 4,
    objectID: "1810",
    uniqueID: "760fe417-20f8-4457-9902-caf53f6fbe06",
  },
  {
    name: "General Pedro Jose Mendez Intl",
    city: "Ciudad Victoria",
    country: "Mexico",
    iata_code: "CVM",
    _geoloc: {
      lat: 23.703336,
      lng: -98.956486,
    },
    links_count: 4,
    objectID: "1798",
    uniqueID: "78ef1129-815e-4ddf-ba59-cfc662f6bd0f",
  },
  {
    name: "Ingeniero Alberto Acuna Ongay Intl",
    city: "Campeche",
    country: "Mexico",
    iata_code: "CPE",
    _geoloc: {
      lat: 19.816794,
      lng: -90.500314,
    },
    links_count: 4,
    objectID: "1795",
    uniqueID: "77067105-da98-47fb-abfe-eb403901101d",
  },
  {
    name: "North Bay",
    city: "North Bay",
    country: "Canada",
    iata_code: "YYB",
    _geoloc: {
      lat: 46.363611,
      lng: -79.422778,
    },
    links_count: 4,
    objectID: "177",
    uniqueID: "cee66e09-0a97-40db-965d-e8ce8f681298",
  },
  {
    name: "South Caicos",
    city: "South Caicos",
    country: "Turks and Caicos Islands",
    iata_code: "XSC",
    _geoloc: {
      lat: 21.515739,
      lng: -71.528528,
    },
    links_count: 4,
    objectID: "1755",
    uniqueID: "6bc09e52-0435-412e-9bb1-80500a53d44c",
  },
  {
    name: "Abbotsford",
    city: "Abbotsford",
    country: "Canada",
    iata_code: "YXX",
    _geoloc: {
      lat: 49.025278,
      lng: -122.360556,
    },
    links_count: 4,
    objectID: "175",
    uniqueID: "70b75e43-0726-4a6b-ad42-10ea1f9cf8ad",
  },
  {
    name: "Cardak",
    city: "Denizli",
    country: "Turkey",
    iata_code: "DNZ",
    _geoloc: {
      lat: 37.785567,
      lng: 29.701297,
    },
    links_count: 4,
    objectID: "1699",
    uniqueID: "563cd5bb-b244-4368-8771-9ddeafb270bc",
  },
  {
    name: "Merzifon",
    city: "Merzifon",
    country: "Turkey",
    iata_code: "MZH",
    _geoloc: {
      lat: 40.829375,
      lng: 35.521992,
    },
    links_count: 4,
    objectID: "1693",
    uniqueID: "871aab81-67c2-44aa-acce-626f90006b92",
  },
  {
    name: "St Gallen Altenrhein",
    city: "Altenrhein",
    country: "Switzerland",
    iata_code: "ACH",
    _geoloc: {
      lat: 47.485033,
      lng: 9.560775,
    },
    links_count: 4,
    objectID: "1679",
    uniqueID: "8340872c-1d48-4381-b58d-154764f98157",
  },
  {
    name: "Lugano",
    city: "Lugano",
    country: "Switzerland",
    iata_code: "LUG",
    _geoloc: {
      lat: 46.004275,
      lng: 8.910578,
    },
    links_count: 4,
    objectID: "1675",
    uniqueID: "2efdcf9b-81dd-485d-b18f-4216b24af8d9",
  },
  {
    name: "Satu Mare",
    city: "Satu Mare",
    country: "Romania",
    iata_code: "SUJ",
    _geoloc: {
      lat: 47.703275,
      lng: 22.8857,
    },
    links_count: 4,
    objectID: "1659",
    uniqueID: "f616dcd4-432b-484a-b7b5-d4310a684e6c",
  },
  {
    name: "Oradea",
    city: "Oradea",
    country: "Romania",
    iata_code: "OMR",
    _geoloc: {
      lat: 47.025278,
      lng: 21.9025,
    },
    links_count: 4,
    objectID: "1656",
    uniqueID: "5ec7c6fc-28b1-4ea4-b2f0-3f09917242bd",
  },
  {
    name: "Craiova",
    city: "Craiova",
    country: "Romania",
    iata_code: "CRA",
    _geoloc: {
      lat: 44.318139,
      lng: 23.888611,
    },
    links_count: 4,
    objectID: "1654",
    uniqueID: "a97b9433-6dad-4a71-8794-0f7f8f7531c0",
  },
  {
    name: "Tautii Magheraus",
    city: "Baia Mare",
    country: "Romania",
    iata_code: "BAY",
    _geoloc: {
      lat: 47.658389,
      lng: 23.470022,
    },
    links_count: 4,
    objectID: "1649",
    uniqueID: "4f089047-5c61-4323-999f-96ad3b0817d4",
  },
  {
    name: "Sao Jorge",
    city: "Sao Jorge Island",
    country: "Portugal",
    iata_code: "SJZ",
    _geoloc: {
      lat: 38.6655,
      lng: -28.175817,
    },
    links_count: 4,
    objectID: "1639",
    uniqueID: "17f846d2-c8f9-43b5-886e-5e4452a37fad",
  },
  {
    name: "Haifa",
    city: "Haifa",
    country: "Israel",
    iata_code: "HFA",
    _geoloc: {
      lat: 32.809444,
      lng: 35.043056,
    },
    links_count: 4,
    objectID: "1596",
    uniqueID: "4f40d2e7-874f-4816-b2ef-a3849f1e130b",
  },
  {
    name: "Pardubice",
    city: "Pardubice",
    country: "Czech Republic",
    iata_code: "PED",
    _geoloc: {
      lat: 50.013419,
      lng: 15.738647,
    },
    links_count: 4,
    objectID: "1584",
    uniqueID: "7b460a00-5d04-429c-ae11-07027e9792ec",
  },
  {
    name: "Bolzano",
    city: "Bolzano",
    country: "Italy",
    iata_code: "BZO",
    _geoloc: {
      lat: 46.460194,
      lng: 11.326383,
    },
    links_count: 4,
    objectID: "1536",
    uniqueID: "58c5c708-6e4f-48ba-afed-90a6b2e8ab6e",
  },
  {
    name: "Qikiqtarjuaq",
    city: "Broughton Island",
    country: "Canada",
    iata_code: "YVM",
    _geoloc: {
      lat: 67.545833,
      lng: -64.031389,
    },
    links_count: 4,
    objectID: "152",
    uniqueID: "41e4586c-10d7-4233-9eb0-542c216b62b0",
  },
  {
    name: "Debrecen",
    city: "Debrecen",
    country: "Hungary",
    iata_code: "DEB",
    _geoloc: {
      lat: 47.488917,
      lng: 21.615333,
    },
    links_count: 4,
    objectID: "1490",
    uniqueID: "54b6428a-d58a-471b-845f-4c8dd2fd65a4",
  },
  {
    name: "Alexandros Papadiamantis",
    city: "Skiathos",
    country: "Greece",
    iata_code: "JSI",
    _geoloc: {
      lat: 39.1771,
      lng: 23.503675,
    },
    links_count: 4,
    objectID: "1475",
    uniqueID: "affd77b5-1341-4d3a-9a82-142894909bce",
  },
  {
    name: "Ioannina",
    city: "Ioannina",
    country: "Greece",
    iata_code: "IOA",
    _geoloc: {
      lat: 39.696388,
      lng: 20.8225,
    },
    links_count: 4,
    objectID: "1451",
    uniqueID: "ae00f1ed-02c6-42cd-897e-5a57444ab748",
  },
  {
    name: "Lann Bihoue",
    city: "Lorient",
    country: "France",
    iata_code: "LRT",
    _geoloc: {
      lat: 47.760555,
      lng: -3.44,
    },
    links_count: 4,
    objectID: "1409",
    uniqueID: "5dbdf71a-d4a2-4c39-aa53-4f349eaaef22",
  },
  {
    name: "St Gatien",
    city: "Deauville",
    country: "France",
    iata_code: "DOL",
    _geoloc: {
      lat: 49.365339,
      lng: 0.154306,
    },
    links_count: 4,
    objectID: "1408",
    uniqueID: "1c3c964f-3e2d-4e8a-95e0-8f90969528b4",
  },
  {
    name: "Sachs Harbour",
    city: "Sachs Harbour",
    country: "Canada",
    iata_code: "YSY",
    _geoloc: {
      lat: 71.993889,
      lng: -125.2425,
    },
    links_count: 4,
    objectID: "139",
    uniqueID: "8f873fc7-b1b3-47f6-9300-aae8edce282d",
  },
  {
    name: "Vatry",
    city: "Chalons",
    country: "France",
    iata_code: "XCR",
    _geoloc: {
      lat: 48.776072,
      lng: 4.184492,
    },
    links_count: 4,
    objectID: "1374",
    uniqueID: "b95388ea-91e0-42a6-92b8-5934a29208c7",
  },
  {
    name: "Caumont",
    city: "Avignon",
    country: "France",
    iata_code: "AVN",
    _geoloc: {
      lat: 43.9073,
      lng: 4.901831,
    },
    links_count: 4,
    objectID: "1361",
    uniqueID: "07630b68-11b2-4897-9a68-8e4377d166ab",
  },
  {
    name: "Resolute Bay",
    city: "Resolute",
    country: "Canada",
    iata_code: "YRB",
    _geoloc: {
      lat: 74.716944,
      lng: -94.969444,
    },
    links_count: 4,
    objectID: "128",
    uniqueID: "7b8637ae-82bf-4e7b-a8cc-61241036c739",
  },
  {
    name: "Bassillac",
    city: "Perigueux",
    country: "France",
    iata_code: "PGX",
    _geoloc: {
      lat: 45.198055,
      lng: 0.815556,
    },
    links_count: 4,
    objectID: "1279",
    uniqueID: "8002813a-eeb9-4a2c-a1a8-738e19c3da03",
  },
  {
    name: "La Garenne",
    city: "Agen",
    country: "France",
    iata_code: "AGF",
    _geoloc: {
      lat: 44.174721,
      lng: 0.590556,
    },
    links_count: 4,
    objectID: "1262",
    uniqueID: "2d50e20b-1c96-474b-aa51-d9d42b87c0bd",
  },
  {
    name: "Sydney",
    city: "Sydney",
    country: "Canada",
    iata_code: "YQY",
    _geoloc: {
      lat: 46.161388,
      lng: -60.047779,
    },
    links_count: 4,
    objectID: "126",
    uniqueID: "282c0bec-8538-4de8-81d0-a3cf6438ec61",
  },
  {
    name: "Talavera La Real",
    city: "Badajoz",
    country: "Spain",
    iata_code: "BJZ",
    _geoloc: {
      lat: 38.89125,
      lng: -6.821333,
    },
    links_count: 4,
    objectID: "1219",
    uniqueID: "8018d20a-0f8e-4b67-88a3-887351fa4923",
  },
  {
    name: "Plovdiv",
    city: "Plovdiv",
    country: "Bulgaria",
    iata_code: "PDV",
    _geoloc: {
      lat: 42.067806,
      lng: 24.850833,
    },
    links_count: 4,
    objectID: "1193",
    uniqueID: "a4f87733-e5e5-48c1-ab82-3091d73b7088",
  },
  {
    name: "El Fashir",
    city: "El Fasher",
    country: "Sudan",
    iata_code: "ELF",
    _geoloc: {
      lat: 13.614892,
      lng: 25.32465,
    },
    links_count: 4,
    objectID: "1169",
    uniqueID: "12b0ede7-c888-463a-b5c7-0760ddf9072d",
  },
  {
    name: "Gondar",
    city: "Gondar",
    country: "Ethiopia",
    iata_code: "GDQ",
    _geoloc: {
      lat: 12.5199,
      lng: 37.434047,
    },
    links_count: 4,
    objectID: "1114",
    uniqueID: "8869cd8b-eb0d-4119-a898-87fb4df3ba7e",
  },
  {
    name: "Axum",
    city: "Axum",
    country: "Ethiopia",
    iata_code: "AXU",
    _geoloc: {
      lat: 14.14675,
      lng: 38.772833,
    },
    links_count: 4,
    objectID: "1110",
    uniqueID: "458443f6-2e81-4d89-8b0e-dfc8d79266e9",
  },
  {
    name: "Preguica",
    city: "Sao Nocolau Island",
    country: "Cape Verde",
    iata_code: "SNE",
    _geoloc: {
      lat: 16.588356,
      lng: -24.284656,
    },
    links_count: 4,
    objectID: "1105",
    uniqueID: "89491ba7-c616-4ff5-8784-726b75226405",
  },
  {
    name: "Fort Chipewyan",
    city: "Fort Chipewyan",
    country: "Canada",
    iata_code: "YPY",
    _geoloc: {
      lat: 58.767223,
      lng: -111.117222,
    },
    links_count: 4,
    objectID: "109",
    uniqueID: "3ea7de65-f0aa-46c5-88ff-fb17e5e88d2b",
  },
  {
    name: "Prince Rupert",
    city: "Prince Pupert",
    country: "Canada",
    iata_code: "YPR",
    _geoloc: {
      lat: 54.28611,
      lng: -130.444722,
    },
    links_count: 4,
    objectID: "108",
    uniqueID: "2a18eb12-f4d0-4534-a075-ff17439a0db0",
  },
  {
    name: "Ouarzazate",
    city: "Ouarzazate",
    country: "Morocco",
    iata_code: "OZZ",
    _geoloc: {
      lat: 30.939053,
      lng: -6.909431,
    },
    links_count: 4,
    objectID: "1077",
    uniqueID: "77fa4c6a-42bf-47bb-ac9e-82a54b0ff45d",
  },
  {
    name: "Monrovia Spriggs Payne",
    city: "Monrovia",
    country: "Liberia",
    iata_code: "MLW",
    _geoloc: {
      lat: 6.289061,
      lng: -10.758722,
    },
    links_count: 4,
    objectID: "1062",
    uniqueID: "ebf37242-0be4-4b84-b43a-7573f00734d4",
  },
  {
    name: "Port Menier",
    city: "Port Menier",
    country: "Canada",
    iata_code: "YPN",
    _geoloc: {
      lat: 49.836389,
      lng: -64.288611,
    },
    links_count: 4,
    objectID: "106",
    uniqueID: "153edeb3-1423-4237-8c93-45e93026a4bb",
  },
  {
    name: "Mbuji Mayi",
    city: "Mbuji-mayi",
    country: "Congo (Kinshasa)",
    iata_code: "MJM",
    _geoloc: {
      lat: -6.121236,
      lng: 23.569008,
    },
    links_count: 4,
    objectID: "1043",
    uniqueID: "0cfa621e-d185-4333-a321-a044b99cdf3c",
  },
  {
    name: "Kindu",
    city: "Kindu",
    country: "Congo (Kinshasa)",
    iata_code: "KND",
    _geoloc: {
      lat: -2.919178,
      lng: 25.915361,
    },
    links_count: 4,
    objectID: "1037",
    uniqueID: "086021b8-7f2a-492c-996f-7066b40e3186",
  },
  {
    name: "Bukavu Kavumu",
    city: "Bukavu/kavumu",
    country: "Congo (Kinshasa)",
    iata_code: "BKY",
    _geoloc: {
      lat: -2.308978,
      lng: 28.808803,
    },
    links_count: 4,
    objectID: "1035",
    uniqueID: "f2331eb5-7353-4e32-a6de-b8fec1873b6b",
  },
  {
    name: "Bunia",
    city: "Bunia",
    country: "Congo (Kinshasa)",
    iata_code: "BUX",
    _geoloc: {
      lat: 1.565719,
      lng: 30.220833,
    },
    links_count: 4,
    objectID: "1033",
    uniqueID: "96e2b722-accc-427e-b667-f4fccd170288",
  },
  {
    name: "Thule Air Base",
    city: "Thule",
    country: "Greenland",
    iata_code: "THU",
    _geoloc: {
      lat: 76.531203,
      lng: -68.703161,
    },
    links_count: 4,
    objectID: "10",
    uniqueID: "dc7a864e-1181-4fdb-a793-a8e47ef7bc44",
  },
  {
    name: "Oriximina Airport",
    city: "Oriximina",
    country: "Brazil",
    iata_code: "ORX",
    _geoloc: {
      lat: -1.714167,
      lng: -55.836111,
    },
    links_count: 3,
    objectID: "8259",
    uniqueID: "399d11f2-a674-4eee-9a33-6db658130d64",
  },
  {
    name: "Ourilandia do Norte Airport",
    city: "Ourilandia do Norte",
    country: "Brazil",
    iata_code: "OIA",
    _geoloc: {
      lat: -6.763056,
      lng: -51.05,
    },
    links_count: 3,
    objectID: "8239",
    uniqueID: "37a5bd48-09f4-4888-9b7f-ac9d945e8556",
  },
  {
    name: "Patos de Minas Airport",
    city: "Patos de Minas",
    country: "Brazil",
    iata_code: "POJ",
    _geoloc: {
      lat: -18.672778,
      lng: -46.491111,
    },
    links_count: 3,
    objectID: "8237",
    uniqueID: "1e7f9580-7a0d-4b32-a1ce-110736767223",
  },
  {
    name: "Choiseul Bay Airport",
    city: "Choiseul Bay",
    country: "Solomon Islands",
    iata_code: "CHY",
    _geoloc: {
      lat: -6.711944,
      lng: 156.396111,
    },
    links_count: 3,
    objectID: "7617",
    uniqueID: "cf92c336-bdd3-46b4-84b3-4b3d912e3173",
  },
  {
    name: "Crooked Creek Airport",
    city: "Crooked Creek",
    country: "United States",
    iata_code: "CKD",
    _geoloc: {
      lat: 61.867778,
      lng: -158.135,
    },
    links_count: 3,
    objectID: "7238",
    uniqueID: "b42003c5-9f8c-4723-af6e-617d5f56ae0a",
  },
  {
    name: "Coffman Cove Seaplane Base",
    city: "Coffman Cove",
    country: "United States",
    iata_code: "KCC",
    _geoloc: {
      lat: 56.014722,
      lng: -132.833889,
    },
    links_count: 3,
    objectID: "7237",
    uniqueID: "20924140-ed6d-4feb-bb2c-0dc5cfd80813",
  },
  {
    name: "Scammon Bay Airport",
    city: "Scammon Bay",
    country: "United States",
    iata_code: "SCM",
    _geoloc: {
      lat: 61.845278,
      lng: -165.571389,
    },
    links_count: 3,
    objectID: "7209",
    uniqueID: "d56e6861-c9c1-472a-bd39-e8e3a73401c9",
  },
  {
    name: "Port Lions Airport",
    city: "Port Lions",
    country: "United States",
    iata_code: "ORI",
    _geoloc: {
      lat: 57.885278,
      lng: -152.846111,
    },
    links_count: 3,
    objectID: "7165",
    uniqueID: "6b82f270-bff5-43cb-a57b-865eba81d0dd",
  },
  {
    name: "Old Harbor Airport",
    city: "Old Harbor",
    country: "United States",
    iata_code: "OLH",
    _geoloc: {
      lat: 57.218056,
      lng: -153.269722,
    },
    links_count: 3,
    objectID: "7163",
    uniqueID: "02883b64-beb6-41ba-ba9b-9e1b5bdc1d68",
  },
  {
    name: "Shenandoah Valley Regional Airport",
    city: "Weyers Cave",
    country: "United States",
    iata_code: "SHD",
    _geoloc: {
      lat: 38.263889,
      lng: -78.896389,
    },
    links_count: 3,
    objectID: "7062",
    uniqueID: "5e7b8c62-ab86-456c-b9fd-ed0398d2bc7b",
  },
  {
    name: "Bella Coola Airport",
    city: "Bella Coola",
    country: "Canada",
    iata_code: "QBC",
    _geoloc: {
      lat: 52.3875,
      lng: -126.596,
    },
    links_count: 3,
    objectID: "6738",
    uniqueID: "c6eea48f-13f8-4e46-95ae-30ccf624ed7f",
  },
  {
    name: "Flinders Island Airport",
    city: "Flinders Island",
    country: "Australia",
    iata_code: "FLS",
    _geoloc: {
      lat: -40.0917,
      lng: 147.993,
    },
    links_count: 3,
    objectID: "6267",
    uniqueID: "deac1ed2-eb02-440d-8b47-44a631181dc7",
  },
  {
    name: "Coen Airport",
    city: "Coen",
    country: "Australia",
    iata_code: "CUQ",
    _geoloc: {
      lat: -13.7608,
      lng: 143.114,
    },
    links_count: 3,
    objectID: "6260",
    uniqueID: "ce3b439c-a321-47e7-898d-bd1409a8e82d",
  },
  {
    name: "Tufi Airport",
    city: "Tufi",
    country: "Papua New Guinea",
    iata_code: "TFI",
    _geoloc: {
      lat: -9.07595,
      lng: 149.32,
    },
    links_count: 3,
    objectID: "5984",
    uniqueID: "85665c5b-4b97-4dbb-9fe5-4a51d496be29",
  },
  {
    name: "Belep Islands Airport",
    city: "Waala",
    country: "New Caledonia",
    iata_code: "BMY",
    _geoloc: {
      lat: -19.7206,
      lng: 163.661,
    },
    links_count: 3,
    objectID: "5920",
    uniqueID: "f3f46be6-bcc7-466c-9aef-a2a0b3c256d0",
  },
  {
    name: "Atiu Island Airport",
    city: "Atiu Island",
    country: "Cook Islands",
    iata_code: "AIU",
    _geoloc: {
      lat: -19.9678,
      lng: -158.119,
    },
    links_count: 3,
    objectID: "5861",
    uniqueID: "2a26718d-22fd-4a54-a0b9-69cba11bf1b8",
  },
  {
    name: "Raleigh County Memorial Airport",
    city: "Beckley",
    country: "United States",
    iata_code: "BKW",
    _geoloc: {
      lat: 37.7873,
      lng: -81.1242,
    },
    links_count: 3,
    objectID: "5724",
    uniqueID: "a50c1c95-ca52-45ce-98ba-0748100eb4df",
  },
  {
    name: "Amboseli Airport",
    city: "Amboseli National Park",
    country: "Kenya",
    iata_code: "ASV",
    _geoloc: {
      lat: -2.64505,
      lng: 37.2531,
    },
    links_count: 3,
    objectID: "5692",
    uniqueID: "fc1f623a-0b49-4ec4-9f60-07345e1689f7",
  },
  {
    name: "Eday Airport",
    city: "Eday",
    country: "United Kingdom",
    iata_code: "EOI",
    _geoloc: {
      lat: 59.1906,
      lng: -2.77222,
    },
    links_count: 3,
    objectID: "5564",
    uniqueID: "b862f537-ad87-419c-b552-08d7cbc53f16",
  },
  {
    name: "Bathurst Airport",
    city: "Bathurst",
    country: "Canada",
    iata_code: "ZBF",
    _geoloc: {
      lat: 47.6297,
      lng: -65.7389,
    },
    links_count: 3,
    objectID: "5538",
    uniqueID: "fac57024-aed6-493e-81b3-30ac70a02f6f",
  },
  {
    name: "Deer Lake Airport",
    city: "Deer Lake",
    country: "Canada",
    iata_code: "YVZ",
    _geoloc: {
      lat: 52.6558,
      lng: -94.0614,
    },
    links_count: 3,
    objectID: "5532",
    uniqueID: "1283cc18-f826-4f04-a216-9e499c997174",
  },
  {
    name: "Fera/Maringe Airport",
    city: "Fera Island",
    country: "Solomon Islands",
    iata_code: "FRE",
    _geoloc: {
      lat: -8.1075,
      lng: 159.577,
    },
    links_count: 3,
    objectID: "5407",
    uniqueID: "fb1887d1-a8c0-4d12-888e-ed20a1138860",
  },
  {
    name: "Ivalo",
    city: "Ivalo",
    country: "Finland",
    iata_code: "IVL",
    _geoloc: {
      lat: 68.607269,
      lng: 27.405328,
    },
    links_count: 3,
    objectID: "428",
    uniqueID: "ff7e5e02-6f91-4dba-b720-a5e3d0410e7f",
  },
  {
    name: "Juancho E. Yrausquin",
    city: "Saba",
    country: "Netherlands Antilles",
    iata_code: "SAB",
    _geoloc: {
      lat: 17.645278,
      lng: -63.220556,
    },
    links_count: 3,
    objectID: "4249",
    uniqueID: "58a67a31-2005-4460-b91f-d8efcf3864d5",
  },
  {
    name: "Blackall",
    city: "Blackall",
    country: "Australia",
    iata_code: "BKQ",
    _geoloc: {
      lat: -24.427778,
      lng: 145.428611,
    },
    links_count: 3,
    objectID: "4217",
    uniqueID: "c7f542a4-c98a-4303-9bb2-699d4ec5ce81",
  },
  {
    name: "Al Ghaidah Intl",
    city: "Al Ghaidah Intl",
    country: "Yemen",
    iata_code: "AAY",
    _geoloc: {
      lat: 16.191667,
      lng: 52.175,
    },
    links_count: 3,
    objectID: "3979",
    uniqueID: "7695f8b2-259a-4777-8b25-003de0bec2a6",
  },
  {
    name: "Saidpur",
    city: "Saidpur",
    country: "Bangladesh",
    iata_code: "SPD",
    _geoloc: {
      lat: 25.759228,
      lng: 88.908869,
    },
    links_count: 3,
    objectID: "3073",
    uniqueID: "87abfcd4-b8f8-49ec-a76f-f077ed084e93",
  },
  {
    name: "Gafsa",
    city: "Gafsa",
    country: "Tunisia",
    iata_code: "GAF",
    _geoloc: {
      lat: 34.422022,
      lng: 8.822503,
    },
    links_count: 3,
    objectID: "290",
    uniqueID: "ad04d2f1-569a-4240-a1ad-14878dac2d9f",
  },
  {
    name: "San Luis",
    city: "San Luis",
    country: "Argentina",
    iata_code: "LUQ",
    _geoloc: {
      lat: -33.273192,
      lng: -66.356422,
    },
    links_count: 3,
    objectID: "2467",
    uniqueID: "e0632fdd-1360-4930-a628-1d63192000d9",
  },
  {
    name: "Ghriss",
    city: "Ghriss",
    country: "Algeria",
    iata_code: "MUW",
    _geoloc: {
      lat: 35.207725,
      lng: 0.147142,
    },
    links_count: 3,
    objectID: "233",
    uniqueID: "0ccc5063-afe5-443b-ad0a-d1fcdc58deb8",
  },
  {
    name: "Bou Chekif",
    city: "Tiaret",
    country: "Algeria",
    iata_code: "TID",
    _geoloc: {
      lat: 35.341136,
      lng: 1.463147,
    },
    links_count: 3,
    objectID: "225",
    uniqueID: "e0a413a5-3058-49ee-9dae-64e9d7d57072",
  },
  {
    name: "Tiska",
    city: "Djanet",
    country: "Algeria",
    iata_code: "DJG",
    _geoloc: {
      lat: 24.292767,
      lng: 9.452444,
    },
    links_count: 3,
    objectID: "211",
    uniqueID: "286ca1f9-3e86-4087-8d33-0b67d0d5974a",
  },
  {
    name: "Hao",
    city: "Hao Island",
    country: "French Polynesia",
    iata_code: "HOI",
    _geoloc: {
      lat: -18.074814,
      lng: -140.945886,
    },
    links_count: 3,
    objectID: "1993",
    uniqueID: "6c23bfb9-1cb9-491d-955a-aaa42bd096ce",
  },
  {
    name: "Kapuskasing",
    city: "Kapuskasing",
    country: "Canada",
    iata_code: "YYU",
    _geoloc: {
      lat: 49.413889,
      lng: -82.4675,
    },
    links_count: 3,
    objectID: "190",
    uniqueID: "f5768f24-67a0-4a8a-b088-a00f226e6620",
  },
  {
    name: "Pamplona",
    city: "Pamplona",
    country: "Spain",
    iata_code: "PNA",
    _geoloc: {
      lat: 42.770039,
      lng: -1.646331,
    },
    links_count: 3,
    objectID: "1234",
    uniqueID: "c68cec07-7b3e-4c66-a362-3dfcbf8baa50",
  },
  {
    name: "Lodwar",
    city: "Lodwar",
    country: "Kenya",
    iata_code: "LOK",
    _geoloc: {
      lat: 3.121967,
      lng: 35.608692,
    },
    links_count: 3,
    objectID: "1143",
    uniqueID: "39a099a5-fc5e-467a-b956-c1a1ba92e65a",
  },
  {
    name: "Asosa",
    city: "Asosa",
    country: "Ethiopia",
    iata_code: "ASO",
    _geoloc: {
      lat: 10.01855,
      lng: 34.586253,
    },
    links_count: 3,
    objectID: "1119",
    uniqueID: "5bcd711a-025e-40d2-9cd0-86e5184ae39f",
  },
  {
    name: "Jimma",
    city: "Jimma",
    country: "Ethiopia",
    iata_code: "JIM",
    _geoloc: {
      lat: 7.666094,
      lng: 36.816639,
    },
    links_count: 3,
    objectID: "1116",
    uniqueID: "dd58c70c-3286-4a1f-b25b-43bf8e3a952b",
  },
  {
    name: "Gambella",
    city: "Gambella",
    country: "Ethiopia",
    iata_code: "GMB",
    _geoloc: {
      lat: 8.128764,
      lng: 34.563131,
    },
    links_count: 3,
    objectID: "1113",
    uniqueID: "d19d7bbb-8dde-422d-8078-22d5f813ce4b",
  },
  {
    name: "Bahir Dar",
    city: "Bahar Dar",
    country: "Ethiopia",
    iata_code: "BJR",
    _geoloc: {
      lat: 11.608075,
      lng: 37.321644,
    },
    links_count: 3,
    objectID: "1111",
    uniqueID: "6749619b-0a71-4e5c-a9e1-953b39b0b961",
  },
  {
    name: "Arba Minch",
    city: "Arba Minch",
    country: "Ethiopia",
    iata_code: "AMH",
    _geoloc: {
      lat: 6.039389,
      lng: 37.590453,
    },
    links_count: 3,
    objectID: "1109",
    uniqueID: "962fd889-19e3-4439-b9b5-45b5639a51cb",
  },
  {
    name: "Ziguinchor",
    city: "Ziguinchor",
    country: "Senegal",
    iata_code: "ZIG",
    _geoloc: {
      lat: 12.555617,
      lng: -16.281783,
    },
    links_count: 3,
    objectID: "1081",
    uniqueID: "4bc8cc89-f651-415b-8939-e895ce2f687e",
  },
  {
    name: "Praslin",
    city: "Praslin",
    country: "Seychelles",
    iata_code: "PRI",
    _geoloc: {
      lat: -4.319292,
      lng: 55.691417,
    },
    links_count: 2,
    objectID: "995",
    uniqueID: "ba8534ac-4cea-4624-9c1f-da837714ae90",
  },
  {
    name: "Port Gentil",
    city: "Port Gentil",
    country: "Gabon",
    iata_code: "POG",
    _geoloc: {
      lat: -0.711739,
      lng: 8.754383,
    },
    links_count: 2,
    objectID: "967",
    uniqueID: "2338622b-8e0a-4720-991f-92502610f98b",
  },
  {
    name: "Luena",
    city: "Luena",
    country: "Angola",
    iata_code: "LUO",
    _geoloc: {
      lat: -11.768086,
      lng: 19.897672,
    },
    links_count: 2,
    objectID: "960",
    uniqueID: "e4fc2eb0-f825-437c-85e1-b1b8a89e88bf",
  },
  {
    name: "Mbanza Congo",
    city: "M'banza-congo",
    country: "Angola",
    iata_code: "SSY",
    _geoloc: {
      lat: -6.269897,
      lng: 14.247025,
    },
    links_count: 2,
    objectID: "944",
    uniqueID: "7e3a80ea-c5c5-4fc5-bd27-d42ab7fed752",
  },
  {
    name: "Uzunyazi",
    city: "Kastamonu",
    country: "Turkey",
    iata_code: "KFS",
    _geoloc: {
      lat: 41.316944,
      lng: 33.796111,
    },
    links_count: 2,
    objectID: "9273",
    uniqueID: "9c6a6879-e8ef-44b4-864e-45d4ab2be8f9",
  },
  {
    name: "Avaratra",
    city: "Mananara",
    country: "Madagascar",
    iata_code: "WMR",
    _geoloc: {
      lat: -16.1639,
      lng: 49.773753,
    },
    links_count: 2,
    objectID: "924",
    uniqueID: "97cf7b14-5af5-4f3d-90ce-ac1ce54d1630",
  },
  {
    name: "Tampa Padang",
    city: "Mamuju",
    country: "Indonesia",
    iata_code: "MJU",
    _geoloc: {
      lat: -2.583333,
      lng: 119.033333,
    },
    links_count: 2,
    objectID: "9181",
    uniqueID: "f5deda6a-9704-4d7c-b1cb-46a7c6a5f2da",
  },
  {
    name: "Tho Xuan Airport",
    city: "Thanh Hoa",
    country: "Vietnam",
    iata_code: "THD",
    _geoloc: {
      lat: 19.90167,
      lng: 105.46778,
    },
    links_count: 2,
    objectID: "9135",
    uniqueID: "e063315e-87e1-4baf-8960-f6ec93a9de78",
  },
  {
    name: "Mfuwe",
    city: "Mfuwe",
    country: "Zambia",
    iata_code: "MFU",
    _geoloc: {
      lat: -13.258878,
      lng: 31.936581,
    },
    links_count: 2,
    objectID: "908",
    uniqueID: "960cae9f-928c-4ac6-aae1-f825727b8f50",
  },
  {
    name: "Zafer",
    city: "Kutahya",
    country: "Turkey",
    iata_code: "KZR",
    _geoloc: {
      lat: 39.111389,
      lng: 30.13,
    },
    links_count: 2,
    objectID: "9045",
    uniqueID: "77d9bdca-b33e-41ff-970c-f1f8a5ff2e5c",
  },
  {
    name: "Ngaoundere",
    city: "N'gaoundere",
    country: "Cameroon",
    iata_code: "NGE",
    _geoloc: {
      lat: 7.357011,
      lng: 13.559242,
    },
    links_count: 2,
    objectID: "900",
    uniqueID: "bb7f5569-48a8-4aa2-b81a-0ba81174f5f4",
  },
  {
    name: "Salak",
    city: "Maroua",
    country: "Cameroon",
    iata_code: "MVR",
    _geoloc: {
      lat: 10.451392,
      lng: 14.257361,
    },
    links_count: 2,
    objectID: "898",
    uniqueID: "41dd8c09-0fef-45d3-a340-598e499c4f89",
  },
  {
    name: "Dalma Airport",
    city: "Dalma Island",
    country: "United Arab Emirates",
    iata_code: "ZDY",
    _geoloc: {
      lat: 24.5033833,
      lng: 52.3360528,
    },
    links_count: 2,
    objectID: "8962",
    uniqueID: "a8724e93-7802-4469-a679-97fed1d32fb4",
  },
  {
    name: "Plaine Corail",
    city: "Rodriguez Island",
    country: "Mauritius",
    iata_code: "RRG",
    _geoloc: {
      lat: -19.757658,
      lng: 63.360983,
    },
    links_count: 2,
    objectID: "894",
    uniqueID: "b7039499-9411-4752-8f43-1219d07a39f0",
  },
  {
    name: "Veliky Ustyug",
    city: "Veliky Ustyug",
    country: "Russia",
    iata_code: "VUS",
    _geoloc: {
      lat: 60.788333,
      lng: 46.26,
    },
    links_count: 2,
    objectID: "8924",
    uniqueID: "483a6a9f-f71c-45a2-b96b-be1f9fa1829b",
  },
  {
    name: "Kangding Airport",
    city: "Kangding",
    country: "China",
    iata_code: "KGT",
    _geoloc: {
      lat: 30.1575,
      lng: 101.734722,
    },
    links_count: 2,
    objectID: "8921",
    uniqueID: "26e72e5a-8618-4f3f-925b-4f8e8b494f4e",
  },
  {
    name: "Termal",
    city: "Rio Hondo",
    country: "Argentina",
    iata_code: "RHD",
    _geoloc: {
      lat: -27.509946,
      lng: -64.936666,
    },
    links_count: 2,
    objectID: "8914",
    uniqueID: "2a7e736f-4dde-4beb-a6c7-fb89428dce08",
  },
  {
    name: "Matsapha",
    city: "Manzini",
    country: "Swaziland",
    iata_code: "MTS",
    _geoloc: {
      lat: -26.529022,
      lng: 31.307519,
    },
    links_count: 2,
    objectID: "887",
    uniqueID: "1c021276-22bc-496c-8e94-e183ca506cc1",
  },
  {
    name: "Mendeleevo",
    city: "Yuzhno-Kurilsk",
    country: "Russia",
    iata_code: "DEE",
    _geoloc: {
      lat: 43.5739,
      lng: 145.4107,
    },
    links_count: 2,
    objectID: "8782",
    uniqueID: "416cbc14-f73c-4896-acd0-fa8acb3f354a",
  },
  {
    name: "Qabala Airport",
    city: "Qabala",
    country: "Azerbaijan",
    iata_code: "GBB",
    _geoloc: {
      lat: 40.826667,
      lng: 47.7125,
    },
    links_count: 2,
    objectID: "8775",
    uniqueID: "fa58a819-b092-4d30-8e84-39eb15d3d779",
  },
  {
    name: "Gorno-Altaysk Airport",
    city: "Gorno-Altaysk",
    country: "Russia",
    iata_code: "RGK",
    _geoloc: {
      lat: 51.966667,
      lng: 85.833333,
    },
    links_count: 2,
    objectID: "8740",
    uniqueID: "b82017b9-26cf-468f-a7b8-e1ef2ac963c7",
  },
  {
    name: "Pomalaa",
    city: "Pomalaa",
    country: "Indonesia",
    iata_code: "PUM",
    _geoloc: {
      lat: -4.183333,
      lng: 121.61667,
    },
    links_count: 2,
    objectID: "8735",
    uniqueID: "0e2e7654-93b3-4fec-a69b-2428dd16f1cb",
  },
  {
    name: "Mthatha",
    city: "Umtata",
    country: "South Africa",
    iata_code: "UTT",
    _geoloc: {
      lat: -31.547903,
      lng: 28.674289,
    },
    links_count: 2,
    objectID: "866",
    uniqueID: "496de78a-f9e7-4062-9aec-714b3ec79740",
  },
  {
    name: "Lloydminster",
    city: "Lloydminster",
    country: "Canada",
    iata_code: "YLL",
    _geoloc: {
      lat: 53.309166,
      lng: -110.0725,
    },
    links_count: 2,
    objectID: "85",
    uniqueID: "a7b5f783-3e30-4d99-bd79-c6a1e810b233",
  },
  {
    name: "Ellisras",
    city: "Lephalale",
    country: "South Africa",
    iata_code: "ELL",
    _geoloc: {
      lat: -23.666667,
      lng: 27.75,
    },
    links_count: 2,
    objectID: "8492",
    uniqueID: "54e77e46-4085-47d1-bbd2-97ca7df6fa98",
  },
  {
    name: "Richards Bay",
    city: "Richard's Bay",
    country: "South Africa",
    iata_code: "RCB",
    _geoloc: {
      lat: -28.741039,
      lng: 32.092111,
    },
    links_count: 2,
    objectID: "849",
    uniqueID: "d5d23193-750a-4757-a73b-ee5ce263f69f",
  },
  {
    name: "Pietermaritzburg",
    city: "Pietermaritzburg",
    country: "South Africa",
    iata_code: "PZB",
    _geoloc: {
      lat: -29.648975,
      lng: 30.398667,
    },
    links_count: 2,
    objectID: "843",
    uniqueID: "0def83a2-0673-44d2-bcc4-76b0272c90dd",
  },
  {
    name: "Polokwane International",
    city: "Polokwane",
    country: "South Africa",
    iata_code: "PTG",
    _geoloc: {
      lat: -23.926089,
      lng: 29.484422,
    },
    links_count: 2,
    objectID: "841",
    uniqueID: "fe452c7f-0c5f-42b1-a11e-7e6032732435",
  },
  {
    name: "Phalaborwa",
    city: "Phalaborwa",
    country: "South Africa",
    iata_code: "PHW",
    _geoloc: {
      lat: -23.937166,
      lng: 31.15539,
    },
    links_count: 2,
    objectID: "840",
    uniqueID: "f2bdc76f-2a77-4f8f-b6d7-8435d22ea87e",
  },
  {
    name: "Keewaywin",
    city: "Keewaywin",
    country: "Canada",
    iata_code: "KEW",
    _geoloc: {
      lat: 52.991111,
      lng: -92.836389,
    },
    links_count: 2,
    objectID: "8355",
    uniqueID: "6cd8a5ec-31b0-4d8d-ae41-631f1ca8a843",
  },
  {
    name: "Margate",
    city: "Margate",
    country: "South Africa",
    iata_code: "MGH",
    _geoloc: {
      lat: -30.857408,
      lng: 30.343019,
    },
    links_count: 2,
    objectID: "827",
    uniqueID: "ab9bde6b-f303-460f-83b0-828f02470c4a",
  },
  {
    name: "Tapuruquara Airport",
    city: "Santa Isabel do Rio Negro",
    country: "Brazil",
    iata_code: "IRZ",
    _geoloc: {
      lat: -0.416944,
      lng: -65.033889,
    },
    links_count: 2,
    objectID: "8258",
    uniqueID: "d79def21-b28e-42d5-b590-1ae69d162af3",
  },
  {
    name: "Senadora Eunice Micheles Airport",
    city: "Sao Paulo de Olivenca",
    country: "Brazil",
    iata_code: "OLC",
    _geoloc: {
      lat: -3.465556,
      lng: -68.918889,
    },
    links_count: 2,
    objectID: "8256",
    uniqueID: "5f5f01c9-22ea-4faa-8192-b70481d35af3",
  },
  {
    name: "Amaury Feitosa Tomaz Airport",
    city: "Eirunepe",
    country: "Brazil",
    iata_code: "ERN",
    _geoloc: {
      lat: -6.639444,
      lng: -69.879722,
    },
    links_count: 2,
    objectID: "8247",
    uniqueID: "8bbe6f52-9cd3-4f97-94ef-d678f41e6da6",
  },
  {
    name: "Sao Felix do Araguaia Airport",
    city: "Sao Felix do Araguaia",
    country: "Brazil",
    iata_code: "SXO",
    _geoloc: {
      lat: -11.6325,
      lng: -50.689444,
    },
    links_count: 2,
    objectID: "8243",
    uniqueID: "5fd3503a-8d49-448d-bb57-e8bfc39840db",
  },
  {
    name: "Bonito Airport",
    city: "Bointo",
    country: "Brazil",
    iata_code: "BYO",
    _geoloc: {
      lat: -21.247222,
      lng: -56.4525,
    },
    links_count: 2,
    objectID: "8242",
    uniqueID: "8f2400c4-2bcf-4109-8896-b1e143a855ec",
  },
  {
    name: "Redencao Airport",
    city: "Redencao",
    country: "Brazil",
    iata_code: "RDC",
    _geoloc: {
      lat: -8.033333,
      lng: -49.98,
    },
    links_count: 2,
    objectID: "8240",
    uniqueID: "d3fab798-5880-48a2-a554-184a2d88791e",
  },
  {
    name: "Trail Airport",
    city: "Trail",
    country: "Canada",
    iata_code: "YZZ",
    _geoloc: {
      lat: 49.055556,
      lng: -117.609167,
    },
    links_count: 2,
    objectID: "8223",
    uniqueID: "5b2025b9-6316-4f53-9311-c446815bf1af",
  },
  {
    name: "Vinnitsa",
    city: "Vinnitsa",
    country: "Ukraine",
    iata_code: "VIN",
    _geoloc: {
      lat: 49.2433,
      lng: 28.6063,
    },
    links_count: 2,
    objectID: "8209",
    uniqueID: "dead1592-2112-4120-88f7-748ba148a226",
  },
  {
    name: "Ittoqqortoormiit Heliport",
    city: "Ittoqqortoormiit",
    country: "Greenland",
    iata_code: "OBY",
    _geoloc: {
      lat: 70.485278,
      lng: -21.966667,
    },
    links_count: 2,
    objectID: "8208",
    uniqueID: "0e223986-c8b7-491d-b44b-e1ec748857d5",
  },
  {
    name: "Mulatupo Airport",
    city: "Mulatupo",
    country: "Panama",
    iata_code: "MPP",
    _geoloc: {
      lat: 8.95,
      lng: -77.75,
    },
    links_count: 2,
    objectID: "8207",
    uniqueID: "712da0f6-3c71-4311-b689-6b4658487b70",
  },
  {
    name: "Garachine Airport",
    city: "Garachine",
    country: "Panama",
    iata_code: "GHE",
    _geoloc: {
      lat: 8.06,
      lng: -78.36,
    },
    links_count: 2,
    objectID: "8206",
    uniqueID: "410dd7e1-8e7e-4fd6-9da8-10600c8bb9a4",
  },
  {
    name: "Achutupo Airport",
    city: "Achutupo",
    country: "Panama",
    iata_code: "ACU",
    _geoloc: {
      lat: 9.2,
      lng: -77.98,
    },
    links_count: 2,
    objectID: "8204",
    uniqueID: "3a9a65ec-9823-4273-a590-2a74f3c19b8c",
  },
  {
    name: "Goodnews Airport",
    city: "Goodnews Bay",
    country: "United States",
    iata_code: "GNU",
    _geoloc: {
      lat: 59.1175,
      lng: -161.5775,
    },
    links_count: 2,
    objectID: "8202",
    uniqueID: "7850ac3f-1022-44a3-b787-f001b828ad5f",
  },
  {
    name: "Puerto Obaldia",
    city: "Puerto Obaldia",
    country: "Panama",
    iata_code: "PUE",
    _geoloc: {
      lat: 8.68333,
      lng: -77.5333,
    },
    links_count: 2,
    objectID: "7962",
    uniqueID: "133f5e67-c743-4327-95af-cf89b14f6547",
  },
  {
    name: "El Porvenir",
    city: "El Porvenir",
    country: "Panama",
    iata_code: "PVE",
    _geoloc: {
      lat: 9.559167,
      lng: -78.971111,
    },
    links_count: 2,
    objectID: "7901",
    uniqueID: "4dcde051-fbfe-4feb-8dec-568c3516de3e",
  },
  {
    name: "Playon Chico",
    city: "Playon Chico",
    country: "Panama",
    iata_code: "PYC",
    _geoloc: {
      lat: 9.303333,
      lng: -78.236111,
    },
    links_count: 2,
    objectID: "7895",
    uniqueID: "256e6540-6944-494c-81a5-1e4692dcff8c",
  },
  {
    name: "Marktoberdorf BF",
    city: "Marktoberdorf",
    country: "Germany",
    iata_code: "OAL",
    _geoloc: {
      lat: 47.78,
      lng: 10.627,
    },
    links_count: 2,
    objectID: "7798",
    uniqueID: "38a8636e-00e4-460f-a48c-e2f238a6850f",
  },
  {
    name: "Mysore Airport",
    city: "Mysore",
    country: "India",
    iata_code: "MYQ",
    _geoloc: {
      lat: 12.3072,
      lng: 76.6497,
    },
    links_count: 2,
    objectID: "7774",
    uniqueID: "0a88f938-28d4-4204-9125-c6009c4ea145",
  },
  {
    name: "Stephenville",
    city: "Stephenville",
    country: "Canada",
    iata_code: "YJT",
    _geoloc: {
      lat: 48.544167,
      lng: -58.549999,
    },
    links_count: 2,
    objectID: "77",
    uniqueID: "690f59f8-4451-4fdc-b699-a1233862f2ab",
  },
  {
    name: "Los Alamos Airport",
    city: "Los Alamos",
    country: "United States",
    iata_code: "LAM",
    _geoloc: {
      lat: 35.8798019,
      lng: -106.2694153,
    },
    links_count: 2,
    objectID: "7584",
    uniqueID: "4a2c8faf-b858-4bb5-828a-5c6a0481ab67",
  },
  {
    name: "Dickwella Airport",
    city: "Dickwella",
    country: "Sri Lanka",
    iata_code: "DIW",
    _geoloc: {
      lat: 5.966667,
      lng: 80.683333,
    },
    links_count: 2,
    objectID: "7562",
    uniqueID: "af1c2104-5cc8-4315-97e0-62572df2b3b2",
  },
  {
    name: "Ulaangom Airport",
    city: "Ulaangom",
    country: "Mongolia",
    iata_code: "ULO",
    _geoloc: {
      lat: 49.973333,
      lng: 92.079722,
    },
    links_count: 2,
    objectID: "7558",
    uniqueID: "5c428d36-7737-4375-baf4-5e1cd7a5b6da",
  },
  {
    name: "Yasuj Airport",
    city: "Yasuj",
    country: "Iran",
    iata_code: "YES",
    _geoloc: {
      lat: 30.700556,
      lng: 51.545,
    },
    links_count: 2,
    objectID: "7547",
    uniqueID: "769c9ce6-8e04-432e-af40-a29469774dc3",
  },
  {
    name: "Sao Gabriel da Cachoeira Airport",
    city: "Sao Gabriel da Cachoeira",
    country: "Brazil",
    iata_code: "SJL",
    _geoloc: {
      lat: -0.148056,
      lng: -66.9858,
    },
    links_count: 2,
    objectID: "7530",
    uniqueID: "f8e4e7f3-843d-44f3-97ff-e72c8e8cd67a",
  },
  {
    name: "Longyan Airport",
    city: "Longyan",
    country: "China",
    iata_code: "LCX",
    _geoloc: {
      lat: 25.674167,
      lng: 116.746389,
    },
    links_count: 2,
    objectID: "7504",
    uniqueID: "787636cd-ea0f-45af-b4aa-adc0ffc9fd42",
  },
  {
    name: "Burevestnik Airport",
    city: "Iturup Island",
    country: "Russia",
    iata_code: "BVV",
    _geoloc: {
      lat: 44.92,
      lng: 147.621667,
    },
    links_count: 2,
    objectID: "7478",
    uniqueID: "7157a74a-fabd-4cd5-86b8-bf5773c9ea67",
  },
  {
    name: "Olgii Airport",
    city: "Olgii",
    country: "Mongolia",
    iata_code: "ULG",
    _geoloc: {
      lat: 48.991667,
      lng: 89.919722,
    },
    links_count: 2,
    objectID: "7470",
    uniqueID: "1ac1ee3c-e5e1-45f7-b2e5-83fef2e97ddd",
  },
  {
    name: "Lleida-Alguaire Airport",
    city: "Lleida",
    country: "Spain",
    iata_code: "ILD",
    _geoloc: {
      lat: 41.727778,
      lng: 0.535833,
    },
    links_count: 2,
    objectID: "7459",
    uniqueID: "2cfe3b8d-0eb8-437c-b66c-4c3c211a6fb3",
  },
  {
    name: "Pakuba Airport",
    city: "Pakuba",
    country: "Uganda",
    iata_code: "PAF",
    _geoloc: {
      lat: 2.3275,
      lng: 31.5,
    },
    links_count: 2,
    objectID: "7450",
    uniqueID: "a626d66a-c834-4459-a7a6-12ce51894cb7",
  },
  {
    name: "Guanambi Airport",
    city: "Guanambi",
    country: "Brazil",
    iata_code: "GNM",
    _geoloc: {
      lat: -14.216667,
      lng: -42.783333,
    },
    links_count: 2,
    objectID: "7407",
    uniqueID: "39be5c6f-8e49-49c5-b0cf-42fbb106415b",
  },
  {
    name: "Barcelos Airport",
    city: "Barcelos",
    country: "Brazil",
    iata_code: "BAZ",
    _geoloc: {
      lat: -0.975,
      lng: -62.923889,
    },
    links_count: 2,
    objectID: "7399",
    uniqueID: "652d23c4-951d-4869-a7a5-cafd990ce406",
  },
  {
    name: "Coari Airport",
    city: "Coari",
    country: "Brazil",
    iata_code: "CIZ",
    _geoloc: {
      lat: -4.085,
      lng: -63.140833,
    },
    links_count: 2,
    objectID: "7398",
    uniqueID: "bb37b9ba-13da-4ed7-b5a0-791fd91e2b82",
  },
  {
    name: "Araxa Airport",
    city: "Araxa",
    country: "Brazil",
    iata_code: "AAX",
    _geoloc: {
      lat: -19.563056,
      lng: -46.960278,
    },
    links_count: 2,
    objectID: "7395",
    uniqueID: "d487e3b1-f61d-460b-886d-562618d6c8ef",
  },
  {
    name: "General leite de Castro Airport",
    city: "Rio Verde",
    country: "Brazil",
    iata_code: "RVD",
    _geoloc: {
      lat: -17.790278,
      lng: -50.918333,
    },
    links_count: 2,
    objectID: "7394",
    uniqueID: "4c0bfb27-b55c-4476-8b72-5244cfb39211",
  },
  {
    name: "Tancredo Thomaz de Faria Airport",
    city: "Guarapuava",
    country: "Brazil",
    iata_code: "GPB",
    _geoloc: {
      lat: -25.383333,
      lng: -51.45,
    },
    links_count: 2,
    objectID: "7383",
    uniqueID: "14b7b5a1-448e-439a-baa2-de2b68e8ee53",
  },
  {
    name: "Rondonopolis Airport",
    city: "Rondonopolis",
    country: "Brazil",
    iata_code: "ROO",
    _geoloc: {
      lat: -16.466667,
      lng: -54.633333,
    },
    links_count: 2,
    objectID: "7382",
    uniqueID: "f0ad1546-1cce-4118-ab54-97967dd7e286",
  },
  {
    name: "Minacu Airport",
    city: "Minacu",
    country: "Brazil",
    iata_code: "MQH",
    _geoloc: {
      lat: -13.526944,
      lng: -48.220556,
    },
    links_count: 2,
    objectID: "7375",
    uniqueID: "c666e5f6-80a3-4f5d-9244-e95b4d2799f6",
  },
  {
    name: "Julio Belem Airport",
    city: "Parintins",
    country: "Brazil",
    iata_code: "PIN",
    _geoloc: {
      lat: -2.627778,
      lng: -56.735833,
    },
    links_count: 2,
    objectID: "7372",
    uniqueID: "f52c9262-39e5-4182-a72f-b6ece0e75004",
  },
  {
    name: "Gurupi Airport",
    city: "Gurupi",
    country: "Brazil",
    iata_code: "GRP",
    _geoloc: {
      lat: -11.728889,
      lng: -49.068889,
    },
    links_count: 2,
    objectID: "7368",
    uniqueID: "c14bba35-0aa4-43b6-84f8-96e2a5038d18",
  },
  {
    name: "Villa Garzon Airport",
    city: "Villa Garzon",
    country: "Colombia",
    iata_code: "VGZ",
    _geoloc: {
      lat: 0.978889,
      lng: -76.605556,
    },
    links_count: 2,
    objectID: "7359",
    uniqueID: "c0f3a0ff-e82e-48b8-b468-d6fbe39d4135",
  },
  {
    name: "La Macarena",
    city: "La Macarena",
    country: "Colombia",
    iata_code: "LMC",
    _geoloc: {
      lat: 2.179167,
      lng: -73.7875,
    },
    links_count: 2,
    objectID: "7358",
    uniqueID: "b3afa30c-d3c8-4a46-b270-5588749782a9",
  },
  {
    name: "Uyuni Airport",
    city: "Uyuni",
    country: "Bolivia",
    iata_code: "UYU",
    _geoloc: {
      lat: -20.466667,
      lng: -66.833333,
    },
    links_count: 2,
    objectID: "7313",
    uniqueID: "b2868c5c-fe07-4a36-bb7a-3c5b9519da2d",
  },
  {
    name: "Christiansted Harbor Seaplane Base",
    city: "Christiansted",
    country: "Virgin Islands",
    iata_code: "SSB",
    _geoloc: {
      lat: 17.747222,
      lng: -64.705,
    },
    links_count: 2,
    objectID: "7310",
    uniqueID: "8a8b70f8-9c96-4ff4-8ac1-68cd33bed6db",
  },
  {
    name: "Manley Hot Springs Airport",
    city: "Manley Hot Springs",
    country: "United States",
    iata_code: "MLY",
    _geoloc: {
      lat: 64.9975,
      lng: -150.644167,
    },
    links_count: 2,
    objectID: "7246",
    uniqueID: "22cad72f-9a2f-4262-b655-6399eb5b4be2",
  },
  {
    name: "Kake Seaplane Base",
    city: "Kake",
    country: "United States",
    iata_code: "KAE",
    _geoloc: {
      lat: 56.973056,
      lng: -133.945556,
    },
    links_count: 2,
    objectID: "7243",
    uniqueID: "33761e04-597f-4b5d-a70a-3bd69f9eb179",
  },
  {
    name: "Healy River Airport",
    city: "Healy",
    country: "United States",
    iata_code: "HKB",
    _geoloc: {
      lat: 63.8675,
      lng: -148.968889,
    },
    links_count: 2,
    objectID: "7242",
    uniqueID: "16b7b9fb-df82-4e38-af69-4234fb8d28bd",
  },
  {
    name: "Stony River 2 Airport",
    city: "Stony River",
    country: "United States",
    iata_code: "SRV",
    _geoloc: {
      lat: 61.7875,
      lng: -156.591111,
    },
    links_count: 2,
    objectID: "7241",
    uniqueID: "ac803394-a783-4144-849f-fdf4c9cfe340",
  },
  {
    name: "Red Devil Airport",
    city: "Red Devil",
    country: "United States",
    iata_code: "RDV",
    _geoloc: {
      lat: 61.788056,
      lng: -157.350278,
    },
    links_count: 2,
    objectID: "7239",
    uniqueID: "3973a182-06d0-4ab6-91a5-84662a3a82da",
  },
  {
    name: "Circle City Airport",
    city: "Circle",
    country: "United States",
    iata_code: "IRC",
    _geoloc: {
      lat: 65.827778,
      lng: -144.076111,
    },
    links_count: 2,
    objectID: "7236",
    uniqueID: "fb7aea0b-5eeb-4af9-b112-eb3e1340b41d",
  },
  {
    name: "Ornskoldsvik",
    city: "Ornskoldsvik",
    country: "Sweden",
    iata_code: "OER",
    _geoloc: {
      lat: 63.408339,
      lng: 18.990039,
    },
    links_count: 2,
    objectID: "722",
    uniqueID: "8d5a1c11-91bd-4701-955a-640925e202d6",
  },
  {
    name: "Takotna Airport",
    city: "Takotna",
    country: "United States",
    iata_code: "TCT",
    _geoloc: {
      lat: 62.971944,
      lng: -156.082778,
    },
    links_count: 2,
    objectID: "7215",
    uniqueID: "416c40d4-4b65-4201-8b23-7178ca19b1cd",
  },
  {
    name: "Nikolai Airport",
    city: "Nikolai",
    country: "United States",
    iata_code: "NIB",
    _geoloc: {
      lat: 63.010833,
      lng: -154.383889,
    },
    links_count: 2,
    objectID: "7214",
    uniqueID: "2dac740f-929e-4b12-8b51-92839c3cafd7",
  },
  {
    name: "Nelson Lagoon",
    city: "Nelson Lagoon",
    country: "United States",
    iata_code: "NLG",
    _geoloc: {
      lat: 56.0075,
      lng: -161.160278,
    },
    links_count: 2,
    objectID: "7203",
    uniqueID: "846717a0-f139-4152-874e-cd3a08f7aeb3",
  },
  {
    name: "False Pass Airport",
    city: "False Pass",
    country: "United States",
    iata_code: "KFP",
    _geoloc: {
      lat: 54.8475,
      lng: -163.410278,
    },
    links_count: 2,
    objectID: "7202",
    uniqueID: "64254dfc-c412-42d8-855c-cc4c1d7c692b",
  },
  {
    name: "Nikolski Air Station",
    city: "Nikolski",
    country: "United States",
    iata_code: "IKO",
    _geoloc: {
      lat: 52.941667,
      lng: -168.848889,
    },
    links_count: 2,
    objectID: "7196",
    uniqueID: "3cace589-52db-45ec-a58a-90fee21f22b4",
  },
  {
    name: "Atka Airport",
    city: "Atka",
    country: "United States",
    iata_code: "AKB",
    _geoloc: {
      lat: 52.220278,
      lng: -174.206389,
    },
    links_count: 2,
    objectID: "7195",
    uniqueID: "f4474045-f7b5-438d-a13a-16e943d9b04a",
  },
  {
    name: "Tin City LRRS Airport",
    city: "Tin City",
    country: "United States",
    iata_code: "TNC",
    _geoloc: {
      lat: 65.563056,
      lng: -167.921667,
    },
    links_count: 2,
    objectID: "7194",
    uniqueID: "6bcae4ff-d4b2-4f11-aaa5-130c279dcd90",
  },
  {
    name: "Seal Bay Seaplane Base",
    city: "Seal Bay",
    country: "United States",
    iata_code: "SYB",
    _geoloc: {
      lat: 58.166667,
      lng: -152.5,
    },
    links_count: 2,
    objectID: "7173",
    uniqueID: "202410dc-f27c-483c-a2e2-8b7cb29494bc",
  },
  {
    name: "Kitoi Bay Seaplane Base",
    city: "Kitoi Bay",
    country: "United States",
    iata_code: "KKB",
    _geoloc: {
      lat: 58.190833,
      lng: -152.370556,
    },
    links_count: 2,
    objectID: "7168",
    uniqueID: "55b09bb4-6f8f-47ed-93de-ec0b8219299c",
  },
  {
    name: "Amook Bay Seaplane Base",
    city: "Amook Bay",
    country: "United States",
    iata_code: "AOS",
    _geoloc: {
      lat: 57.471389,
      lng: -153.815278,
    },
    links_count: 2,
    objectID: "7167",
    uniqueID: "98516688-b197-4c95-92f7-fc2cf259e372",
  },
  {
    name: "Larsen Bay Airport",
    city: "Larsen Bay",
    country: "United States",
    iata_code: "KLN",
    _geoloc: {
      lat: 57.535,
      lng: -153.976667,
    },
    links_count: 2,
    objectID: "7162",
    uniqueID: "aaa93513-4609-4a91-b6d8-02a4ee06b48e",
  },
  {
    name: "Akhiok Airport",
    city: "Akhiok",
    country: "United States",
    iata_code: "AKK",
    _geoloc: {
      lat: 56.938611,
      lng: -154.1825,
    },
    links_count: 2,
    objectID: "7160",
    uniqueID: "13338822-6b07-4a6e-932f-5dd29780d949",
  },
  {
    name: "South Naknek Airport",
    city: "South Naknek",
    country: "United States",
    iata_code: "WSN",
    _geoloc: {
      lat: 58.702222,
      lng: -157.0025,
    },
    links_count: 2,
    objectID: "7159",
    uniqueID: "500273f2-8a72-42aa-9e6b-896dd6580575",
  },
  {
    name: "Pilot Point Airport",
    city: "Pilot Point",
    country: "United States",
    iata_code: "PIP",
    _geoloc: {
      lat: 57.585393,
      lng: -157.571944,
    },
    links_count: 2,
    objectID: "7158",
    uniqueID: "c9a8868b-a103-40c1-9d17-2a38aaaacc7a",
  },
  {
    name: "Perryville Airport",
    city: "Perryville",
    country: "United States",
    iata_code: "KPV",
    _geoloc: {
      lat: 55.906667,
      lng: -159.160833,
    },
    links_count: 2,
    objectID: "7157",
    uniqueID: "7dc25baa-8414-4f4e-8085-8435c172fbc0",
  },
  {
    name: "Chignik Lagoon Airport",
    city: "Chignik Lagoon",
    country: "United States",
    iata_code: "KCL",
    _geoloc: {
      lat: 56.311111,
      lng: -158.534167,
    },
    links_count: 2,
    objectID: "7155",
    uniqueID: "fc70e226-5670-4aa8-b6e3-fba3076f32b9",
  },
  {
    name: "Egegik Airport",
    city: "Egegik",
    country: "United States",
    iata_code: "EGX",
    _geoloc: {
      lat: 58.185556,
      lng: -157.375556,
    },
    links_count: 2,
    objectID: "7154",
    uniqueID: "29820ad0-e20d-4c84-b424-8538fe54ef50",
  },
  {
    name: "Chignik Lake Airport",
    city: "Chignik Lake",
    country: "United States",
    iata_code: "KCQ",
    _geoloc: {
      lat: 56.255,
      lng: -158.775278,
    },
    links_count: 2,
    objectID: "7153",
    uniqueID: "25723404-f9e1-4873-be35-f9b0b4467827",
  },
  {
    name: "North Whale Seaplane Base",
    city: "North Whale Pass",
    country: "United States",
    iata_code: "WWP",
    _geoloc: {
      lat: 56.116389,
      lng: -133.121667,
    },
    links_count: 2,
    objectID: "7152",
    uniqueID: "a6f3bf73-3ef1-4273-a4e0-ea32abc19726",
  },
  {
    name: "Port Protection Seaplane Base",
    city: "Port Protection",
    country: "United States",
    iata_code: "PPV",
    _geoloc: {
      lat: 56.328889,
      lng: -133.61,
    },
    links_count: 2,
    objectID: "7151",
    uniqueID: "fa18fd8c-3b29-4d09-a33b-3ff2a6357e6b",
  },
  {
    name: "Point Baker Seaplane Base",
    city: "Point Baker",
    country: "United States",
    iata_code: "KPB",
    _geoloc: {
      lat: 56.351944,
      lng: -133.6225,
    },
    links_count: 2,
    objectID: "7150",
    uniqueID: "d7f3bc3d-201a-4daa-b967-a977e6668f7f",
  },
  {
    name: "Hydaburg Seaplane Base",
    city: "Hydaburg",
    country: "United States",
    iata_code: "HYG",
    _geoloc: {
      lat: 55.206389,
      lng: -132.828333,
    },
    links_count: 2,
    objectID: "7148",
    uniqueID: "b6bbac41-47ef-4ce8-aff6-7e7a8e00fd9e",
  },
  {
    name: "Excursion Inlet Seaplane Base",
    city: "Excursion Inlet",
    country: "United States",
    iata_code: "EXI",
    _geoloc: {
      lat: 58.420556,
      lng: -135.449167,
    },
    links_count: 2,
    objectID: "7141",
    uniqueID: "8fa9beca-16e8-408a-b962-c1d262da9ac0",
  },
  {
    name: "Pelican Seaplane Base",
    city: "Pelican",
    country: "United States",
    iata_code: "PEC",
    _geoloc: {
      lat: 57.955278,
      lng: -136.236389,
    },
    links_count: 2,
    objectID: "7138",
    uniqueID: "d5017d24-13a9-4a13-9adf-b3136f6e616a",
  },
  {
    name: "Tenakee Seaplane Base",
    city: "Tenakee Springs",
    country: "United States",
    iata_code: "TKE",
    _geoloc: {
      lat: 57.779722,
      lng: -135.218333,
    },
    links_count: 2,
    objectID: "7137",
    uniqueID: "ad441ffb-761c-4f73-9231-e06cf2a50f3c",
  },
  {
    name: "Elfin Cove Seaplane Base",
    city: "Elfin Cove",
    country: "United States",
    iata_code: "ELV",
    _geoloc: {
      lat: 58.195278,
      lng: -136.3475,
    },
    links_count: 2,
    objectID: "7136",
    uniqueID: "1572c14e-489e-4eaf-a072-c0a765b84286",
  },
  {
    name: "Angoon Seaplane Base",
    city: "Angoon",
    country: "United States",
    iata_code: "AGN",
    _geoloc: {
      lat: 57.503611,
      lng: -134.585,
    },
    links_count: 2,
    objectID: "7135",
    uniqueID: "23a2bfe9-ae0a-49c4-8a36-9a12f7ad5339",
  },
  {
    name: "Merced Municipal Airport",
    city: "Merced",
    country: "United States",
    iata_code: "MCE",
    _geoloc: {
      lat: 37.284722,
      lng: -120.513889,
    },
    links_count: 2,
    objectID: "7122",
    uniqueID: "f0539dfb-fdc7-4e7c-a186-4605922377b3",
  },
  {
    name: "Visalia Municipal Airport",
    city: "Visalia",
    country: "United States",
    iata_code: "VIS",
    _geoloc: {
      lat: 36.318611,
      lng: -119.392778,
    },
    links_count: 2,
    objectID: "7121",
    uniqueID: "0914413b-e23b-4a45-95c8-51eac044cf93",
  },
  {
    name: "Coldfoot Airport",
    city: "Coldfoot",
    country: "United States",
    iata_code: "CXF",
    _geoloc: {
      lat: 67.251389,
      lng: -150.176111,
    },
    links_count: 2,
    objectID: "7119",
    uniqueID: "77c84fcd-b3e3-47ed-a4f2-fc22b26839b9",
  },
  {
    name: "Birch Creek Airport",
    city: "Brich Creek",
    country: "United States",
    iata_code: "KBC",
    _geoloc: {
      lat: 66.256708,
      lng: -145.815319,
    },
    links_count: 2,
    objectID: "7118",
    uniqueID: "68290b09-3880-4e43-a75b-c670ddef166d",
  },
  {
    name: "Central Airport",
    city: "Central",
    country: "United States",
    iata_code: "CEM",
    _geoloc: {
      lat: 65.573889,
      lng: -144.780833,
    },
    links_count: 2,
    objectID: "7116",
    uniqueID: "ceca6485-3097-4363-9551-1849cb8fe2eb",
  },
  {
    name: "Rampart Airport",
    city: "Rampart",
    country: "United States",
    iata_code: "RMP",
    _geoloc: {
      lat: 65.507778,
      lng: -150.140833,
    },
    links_count: 2,
    objectID: "7112",
    uniqueID: "bb2e0994-f0c9-4b45-bcfe-72c8541967d3",
  },
  {
    name: "Minto Airport",
    city: "Minto",
    country: "United States",
    iata_code: "MNT",
    _geoloc: {
      lat: 65.143611,
      lng: -149.37,
    },
    links_count: 2,
    objectID: "7110",
    uniqueID: "6602a144-dca5-4326-816f-71e5ca0e2d6b",
  },
  {
    name: "Levelock Airport",
    city: "Levelock",
    country: "United States",
    iata_code: "KLL",
    _geoloc: {
      lat: 59.128056,
      lng: -156.858611,
    },
    links_count: 2,
    objectID: "7102",
    uniqueID: "5bad4887-d188-424e-a54f-0930b3491844",
  },
  {
    name: "Chuathbaluk Airport",
    city: "Chuathbaluk",
    country: "United States",
    iata_code: "CHU",
    _geoloc: {
      lat: 61.579167,
      lng: -159.215556,
    },
    links_count: 2,
    objectID: "7091",
    uniqueID: "0dbab5f2-64e3-457a-8b9a-9a30aaae2c22",
  },
  {
    name: "Orcas Island Airport",
    city: "Eastsound",
    country: "United States",
    iata_code: "ESD",
    _geoloc: {
      lat: 48.708056,
      lng: -122.910556,
    },
    links_count: 2,
    objectID: "7083",
    uniqueID: "7ec9a589-f49b-4acf-b731-30d2b69b31b0",
  },
  {
    name: "Friday Harbor Airport",
    city: "Friday Harbor",
    country: "United States",
    iata_code: "FRD",
    _geoloc: {
      lat: 48.521944,
      lng: -123.024444,
    },
    links_count: 2,
    objectID: "7082",
    uniqueID: "d5bc59f8-c0c6-474d-92d7-525e51c8f76d",
  },
  {
    name: "Vernal Regional Airport",
    city: "Vernal",
    country: "United States",
    iata_code: "VEL",
    _geoloc: {
      lat: 40.440833,
      lng: -109.51,
    },
    links_count: 2,
    objectID: "7076",
    uniqueID: "1a8501f5-2533-479c-be12-585c544bf393",
  },
  {
    name: "Canyonlands Field",
    city: "Moab",
    country: "United States",
    iata_code: "CNY",
    _geoloc: {
      lat: 38.755,
      lng: -109.754722,
    },
    links_count: 2,
    objectID: "7074",
    uniqueID: "f6562410-3ae1-4fe2-9fdd-9c4703b6565d",
  },
  {
    name: "LM Clayton Airport",
    city: "Wolf Point",
    country: "United States",
    iata_code: "OLF",
    _geoloc: {
      lat: 48.094444,
      lng: -105.575,
    },
    links_count: 2,
    objectID: "7071",
    uniqueID: "df7fca72-b7e6-4ce5-b3b8-79c197ec847d",
  },
  {
    name: "Dawson Community Airport",
    city: "Glendive",
    country: "United States",
    iata_code: "GDV",
    _geoloc: {
      lat: 47.138611,
      lng: -104.807222,
    },
    links_count: 2,
    objectID: "7070",
    uniqueID: "7bfd6f11-4093-45a6-8b24-1084335cc74d",
  },
  {
    name: "McCook Regional Airport",
    city: "McCook",
    country: "United States",
    iata_code: "MCK",
    _geoloc: {
      lat: 40.206389,
      lng: -100.592222,
    },
    links_count: 2,
    objectID: "7068",
    uniqueID: "8761c77e-503d-4532-99ef-dc3c324c4982",
  },
  {
    name: "Sidney-Richland Municipal Airport",
    city: "Sidney",
    country: "United States",
    iata_code: "SDY",
    _geoloc: {
      lat: 47.706944,
      lng: -104.1925,
    },
    links_count: 2,
    objectID: "7065",
    uniqueID: "f2d6e71b-d687-4c19-9772-71dd7226db65",
  },
  {
    name: "Manistee County-Blacker Airport",
    city: "Manistee",
    country: "United States",
    iata_code: "MBL",
    _geoloc: {
      lat: 44.2725,
      lng: -86.246944,
    },
    links_count: 2,
    objectID: "7054",
    uniqueID: "e9e4d4cf-684d-42a4-9523-f6f0a620f301",
  },
  {
    name: "Gunnison - Crested Butte",
    city: "Gunnison",
    country: "United States",
    iata_code: "GUC",
    _geoloc: {
      lat: 38.533889,
      lng: -106.933056,
    },
    links_count: 2,
    objectID: "7051",
    uniqueID: "965e9d7a-45ba-4b01-9966-dd2db5db7b0c",
  },
  {
    name: "Gelendzik",
    city: "Gelendzik",
    country: "Russia",
    iata_code: "GDZ",
    _geoloc: {
      lat: 44.566666666667,
      lng: 38.016666666667,
    },
    links_count: 2,
    objectID: "7041",
    uniqueID: "10fa374a-ba0c-4bdc-8d2a-b8a7b744e511",
  },
  {
    name: "Kristianstad",
    city: "Kristianstad",
    country: "Sweden",
    iata_code: "KID",
    _geoloc: {
      lat: 55.921686,
      lng: 14.085536,
    },
    links_count: 2,
    objectID: "703",
    uniqueID: "d770df59-8bbe-43fd-9afb-ad7b2804d832",
  },
  {
    name: "Thief River Falls",
    city: "Thief River Falls",
    country: "United States",
    iata_code: "TVF",
    _geoloc: {
      lat: 48.065556,
      lng: -96.185,
    },
    links_count: 2,
    objectID: "7018",
    uniqueID: "7c033b60-6d68-48fc-8d98-36ea19cd2bc3",
  },
  {
    name: "St. Augustine Airport",
    city: "St. Augustine Airport",
    country: "United States",
    iata_code: "UST",
    _geoloc: {
      lat: 29.959167,
      lng: -81.339722,
    },
    links_count: 2,
    objectID: "6989",
    uniqueID: "344f1c15-1310-4f8a-a16b-3a030fd7209c",
  },
  {
    name: "Tunoshna",
    city: "Yaroslavl",
    country: "Russia",
    iata_code: "IAR",
    _geoloc: {
      lat: 57.560666676667,
      lng: 40.157369454444,
    },
    links_count: 2,
    objectID: "6940",
    uniqueID: "3243d5ba-9f04-458c-aeb0-90a57290962b",
  },
  {
    name: "Trollhattan Vanersborg",
    city: "Trollhattan",
    country: "Sweden",
    iata_code: "THN",
    _geoloc: {
      lat: 58.318056,
      lng: 12.345,
    },
    links_count: 2,
    objectID: "693",
    uniqueID: "509c5096-8b57-4a4b-afc4-72cb9913ec9b",
  },
  {
    name: "Pangborn Field",
    city: "Wenatchee",
    country: "United States",
    iata_code: "EAT",
    _geoloc: {
      lat: 47.398,
      lng: -120.206,
    },
    links_count: 2,
    objectID: "6916",
    uniqueID: "029fb901-ea9f-4c56-858f-5f2d57c38cc9",
  },
  {
    name: "Eastern Oregon Regional Airport",
    city: "Pendleton",
    country: "United States",
    iata_code: "PDT",
    _geoloc: {
      lat: 45.695,
      lng: -118.841389,
    },
    links_count: 2,
    objectID: "6883",
    uniqueID: "3eb02a7e-fc6b-44a6-822f-65ac276e1b8c",
  },
  {
    name: "Riverton Regional",
    city: "Riverton WY",
    country: "United States",
    iata_code: "RIW",
    _geoloc: {
      lat: 43.064167,
      lng: -108.459722,
    },
    links_count: 2,
    objectID: "6881",
    uniqueID: "c4342c76-8156-4d00-9634-fe4e1bfc4206",
  },
  {
    name: "Montrose Regional Airport",
    city: "Montrose CO",
    country: "United States",
    iata_code: "MTJ",
    _geoloc: {
      lat: 38.509794,
      lng: -107.894242,
    },
    links_count: 2,
    objectID: "6880",
    uniqueID: "debba235-7d2b-49ac-94e6-e5dbd78241f2",
  },
  {
    name: "Williamson Country Regional Airport",
    city: "Marion",
    country: "United States",
    iata_code: "MWA",
    _geoloc: {
      lat: 37.7549569,
      lng: -89.0110936,
    },
    links_count: 2,
    objectID: "6825",
    uniqueID: "a82ef190-dc29-432c-9824-34a293b2327e",
  },
  {
    name: "Culebra Airport",
    city: "Culebra Island",
    country: "Puerto Rico",
    iata_code: "CPX",
    _geoloc: {
      lat: 18.3127,
      lng: -65.3034,
    },
    links_count: 2,
    objectID: "6814",
    uniqueID: "133fca69-ccef-49d4-b386-924b9261017c",
  },
  {
    name: "Delta County Airport",
    city: "Escanaba",
    country: "United States",
    iata_code: "ESC",
    _geoloc: {
      lat: 45.722778,
      lng: -87.093611,
    },
    links_count: 2,
    objectID: "6800",
    uniqueID: "27b0d99a-90e7-4627-ab2d-ed7d01a21f93",
  },
  {
    name: "Orange Airport",
    city: "Orange",
    country: "Australia",
    iata_code: "OAG",
    _geoloc: {
      lat: -33.3817,
      lng: 149.133,
    },
    links_count: 2,
    objectID: "6793",
    uniqueID: "e0c8fc7b-d49b-4d30-bcbd-492a799e0789",
  },
  {
    name: "Grafton Airport",
    city: "Grafton",
    country: "Australia",
    iata_code: "GFN",
    _geoloc: {
      lat: -29.7594,
      lng: 153.03,
    },
    links_count: 2,
    objectID: "6792",
    uniqueID: "cf0bd546-b03e-49ac-9560-305a0cc544e2",
  },
  {
    name: "Hamadan Airport",
    city: "Hamadan",
    country: "Iran",
    iata_code: "HDM",
    _geoloc: {
      lat: 34.869167,
      lng: 48.5525,
    },
    links_count: 2,
    objectID: "6790",
    uniqueID: "7dd95700-a8fa-4720-ad3d-ccb11a756fbb",
  },
  {
    name: "Tekirdağ Çorlu Airport",
    city: "Çorlu",
    country: "Turkey",
    iata_code: "TEQ",
    _geoloc: {
      lat: 41.13825,
      lng: 27.919094,
    },
    links_count: 2,
    objectID: "6784",
    uniqueID: "cb23f3ce-09c8-4d67-ad33-b025a2e9babf",
  },
  {
    name: "Sinop Airport",
    city: "Sinop",
    country: "Turkey",
    iata_code: "SIC",
    _geoloc: {
      lat: 42.0158,
      lng: 35.0664,
    },
    links_count: 2,
    objectID: "6783",
    uniqueID: "17d7ef06-82a2-40f4-ba95-5a04c1738600",
  },
  {
    name: "Canakkale Airport",
    city: "Canakkale",
    country: "Turkey",
    iata_code: "CKZ",
    _geoloc: {
      lat: 40.137722,
      lng: 26.426777,
    },
    links_count: 2,
    objectID: "6781",
    uniqueID: "f72acfaf-d1f7-429a-8b02-357b1c8e8ecc",
  },
  {
    name: "Anadolu Airport",
    city: "Eskissehir",
    country: "Turkey",
    iata_code: "AOE",
    _geoloc: {
      lat: 39.809858,
      lng: 30.519378,
    },
    links_count: 2,
    objectID: "6780",
    uniqueID: "b54138e6-6060-4821-8677-d5d7a8b7048a",
  },
  {
    name: "Walaha Airport",
    city: "Walaha",
    country: "Vanuatu",
    iata_code: "WLH",
    _geoloc: {
      lat: -15.412,
      lng: 167.691,
    },
    links_count: 2,
    objectID: "6771",
    uniqueID: "2bf86543-a832-494d-a739-2f5fd9de4781",
  },
  {
    name: "Tanjung Manis Airport",
    city: "Tanjung Manis",
    country: "Malaysia",
    iata_code: "TGC",
    _geoloc: {
      lat: 2.17784,
      lng: 111.202,
    },
    links_count: 2,
    objectID: "6770",
    uniqueID: "993da5e1-75d2-4ca0-8698-b3b305b57cd9",
  },
  {
    name: "Togiak Airport",
    city: "Togiak Village",
    country: "United States",
    iata_code: "TOG",
    _geoloc: {
      lat: 59.0528,
      lng: -160.397,
    },
    links_count: 2,
    objectID: "6767",
    uniqueID: "511bfaa3-c313-4925-8cf4-fdb57897a329",
  },
  {
    name: "Port Heiden Airport",
    city: "Port Heiden",
    country: "United States",
    iata_code: "PTH",
    _geoloc: {
      lat: 56.9591,
      lng: -158.633,
    },
    links_count: 2,
    objectID: "6766",
    uniqueID: "59d3085a-f78c-428e-bf53-a7e31d39eaf6",
  },
  {
    name: "King Cove Airport",
    city: "King Cove",
    country: "United States",
    iata_code: "KVC",
    _geoloc: {
      lat: 55.1163,
      lng: -162.266,
    },
    links_count: 2,
    objectID: "6765",
    uniqueID: "34daa725-0317-4729-af5f-d2b9cc14a8ff",
  },
  {
    name: "Igiugig Airport",
    city: "Igiugig",
    country: "United States",
    iata_code: "IGG",
    _geoloc: {
      lat: 59.324,
      lng: -155.902,
    },
    links_count: 2,
    objectID: "6763",
    uniqueID: "4299c6ad-04e0-4f1e-b41a-1f587944cdd9",
  },
  {
    name: "Burgos Airport",
    city: "Burgos",
    country: "Spain",
    iata_code: "RGS",
    _geoloc: {
      lat: 42.357628,
      lng: -3.620764,
    },
    links_count: 2,
    objectID: "6757",
    uniqueID: "2dc52e1a-4bdc-4349-a597-4b7f6e28da2f",
  },
  {
    name: "Leon Airport",
    city: "Leon",
    country: "Spain",
    iata_code: "LEN",
    _geoloc: {
      lat: 42.589,
      lng: -5.655556,
    },
    links_count: 2,
    objectID: "6756",
    uniqueID: "9f1d4aa3-aad4-410c-9b5e-ac97f39fd5f1",
  },
  {
    name: "Nueva Loja Airport",
    city: "Lago Agrio",
    country: "Ecuador",
    iata_code: "LGQ",
    _geoloc: {
      lat: 0.093056,
      lng: -76.8675,
    },
    links_count: 2,
    objectID: "6750",
    uniqueID: "cb3816fa-8725-48f5-ab19-165a6b8ac5e8",
  },
  {
    name: "Parsabade Moghan Airport",
    city: "Parsabad",
    country: "Iran",
    iata_code: "PFQ",
    _geoloc: {
      lat: 39.603606,
      lng: 47.8815,
    },
    links_count: 2,
    objectID: "6749",
    uniqueID: "64663b79-38cc-45e3-8aaf-5b0e5020f44d",
  },
  {
    name: "Ilam Airport",
    city: "Ilam",
    country: "Iran",
    iata_code: "IIL",
    _geoloc: {
      lat: 33.586606,
      lng: 46.404842,
    },
    links_count: 2,
    objectID: "6748",
    uniqueID: "ce5aab8b-d947-43eb-9470-c04d127f5e9c",
  },
  {
    name: "Governador Valadares Airport",
    city: "Governador Valadares",
    country: "Brazil",
    iata_code: "GVR",
    _geoloc: {
      lat: -18.8952,
      lng: -41.9822,
    },
    links_count: 2,
    objectID: "6735",
    uniqueID: "3433c814-c8ff-41ea-926d-35cba7406230",
  },
  {
    name: "Kirovsk-Apatity Airport",
    city: "Apatity",
    country: "Russia",
    iata_code: "KVK",
    _geoloc: {
      lat: 67.4633,
      lng: 33.5883,
    },
    links_count: 2,
    objectID: "6734",
    uniqueID: "7dc07a0a-9c37-4bc7-81ca-d924de805b83",
  },
  {
    name: "Cauayan Airport",
    city: "Cauayan",
    country: "Philippines",
    iata_code: "CYZ",
    _geoloc: {
      lat: 16.929861,
      lng: 121.753036,
    },
    links_count: 2,
    objectID: "6733",
    uniqueID: "6ead045f-88d2-4581-9b33-f028c8dd2a2b",
  },
  {
    name: "Arctic Village Airport",
    city: "Arctic Village",
    country: "United States",
    iata_code: "ARC",
    _geoloc: {
      lat: 68.1147,
      lng: -145.579,
    },
    links_count: 2,
    objectID: "6729",
    uniqueID: "0f808331-39c0-4efc-9a58-9bb7621a2b94",
  },
  {
    name: "Mekoryuk Airport",
    city: "Mekoryuk",
    country: "United States",
    iata_code: "MYU",
    _geoloc: {
      lat: 60.3714,
      lng: -166.271,
    },
    links_count: 2,
    objectID: "6720",
    uniqueID: "fcfcae65-c355-4301-8393-59cfee55dfa1",
  },
  {
    name: "Geiteryggen",
    city: "Skien",
    country: "Norway",
    iata_code: "SKE",
    _geoloc: {
      lat: 59.185,
      lng: 9.566944,
    },
    links_count: 2,
    objectID: "659",
    uniqueID: "77a55332-3f3d-46ec-a15d-2e2578e0a103",
  },
  {
    name: "Roros",
    city: "Roros",
    country: "Norway",
    iata_code: "RRS",
    _geoloc: {
      lat: 62.578411,
      lng: 11.342347,
    },
    links_count: 2,
    objectID: "656",
    uniqueID: "91d93f16-2ec1-46b7-8256-ef51f32d75e0",
  },
  {
    name: "Orland",
    city: "Orland",
    country: "Norway",
    iata_code: "OLA",
    _geoloc: {
      lat: 63.698908,
      lng: 9.604003,
    },
    links_count: 2,
    objectID: "655",
    uniqueID: "ab9e7583-fbe7-46d4-834d-0b8c691b5a9e",
  },
  {
    name: "Tambow",
    city: "Tambow",
    country: "Russia",
    iata_code: "TBW",
    _geoloc: {
      lat: 52.81,
      lng: 41.48,
    },
    links_count: 2,
    objectID: "6466",
    uniqueID: "421e8ecc-8c9a-43b5-915f-a73f9a5c70b9",
  },
  {
    name: "Provincetown Muni",
    city: "Provincetown",
    country: "United States",
    iata_code: "PVC",
    _geoloc: {
      lat: 42.071945,
      lng: -70.22139,
    },
    links_count: 2,
    objectID: "6456",
    uniqueID: "c93a246f-9745-456a-b2ad-8d7451cef9b9",
  },
  {
    name: "Boulder City Municipal Airport",
    city: "Boulder City",
    country: "United States",
    iata_code: "BLD",
    _geoloc: {
      lat: 35.5651,
      lng: -114.514,
    },
    links_count: 2,
    objectID: "6449",
    uniqueID: "bf853ece-9a96-4730-addf-e601fe18d93b",
  },
  {
    name: "Grand Canyon West Airport",
    city: "Grand Canyon West",
    country: "United States",
    iata_code: "GCW",
    _geoloc: {
      lat: 35.5925,
      lng: -113.4859,
    },
    links_count: 2,
    objectID: "6448",
    uniqueID: "8995f374-c63c-4a1b-a0f8-099b6a802286",
  },
  {
    name: "Leirin",
    city: "Fagernes",
    country: "Norway",
    iata_code: "VDB",
    _geoloc: {
      lat: 61.015556,
      lng: 9.288056,
    },
    links_count: 2,
    objectID: "642",
    uniqueID: "2c461ac7-3ab0-4704-984f-cc8243482a44",
  },
  {
    name: "Bardufoss",
    city: "Bardufoss",
    country: "Norway",
    iata_code: "BDU",
    _geoloc: {
      lat: 69.055758,
      lng: 18.540356,
    },
    links_count: 2,
    objectID: "640",
    uniqueID: "8952859c-d875-4627-b2f3-32fdebf51e3a",
  },
  {
    name: "Khovd Airport",
    city: "Khovd",
    country: "Mongolia",
    iata_code: "HVD",
    _geoloc: {
      lat: 47.9541,
      lng: 91.6282,
    },
    links_count: 2,
    objectID: "6373",
    uniqueID: "4bbce2d2-1989-4e0a-8958-23757c9160ab",
  },
  {
    name: "Altai Airport",
    city: "Altai",
    country: "Mongolia",
    iata_code: "LTI",
    _geoloc: {
      lat: 46.3764,
      lng: 96.2211,
    },
    links_count: 2,
    objectID: "6370",
    uniqueID: "080aa9ed-84e3-4c6c-a30b-250e05f934a4",
  },
  {
    name: "Whyalla Airport",
    city: "Whyalla",
    country: "Australia",
    iata_code: "WYA",
    _geoloc: {
      lat: -33.0589,
      lng: 137.514,
    },
    links_count: 2,
    objectID: "6334",
    uniqueID: "e5eb72be-8036-4a4b-8714-5c0cf3b56a54",
  },
  {
    name: "Thargomindah Airport",
    city: "Thargomindah",
    country: "Australia",
    iata_code: "XTG",
    _geoloc: {
      lat: -27.9864,
      lng: 143.811,
    },
    links_count: 2,
    objectID: "6329",
    uniqueID: "2a14fe53-f33c-4d4a-a69c-400c1b9f82b6",
  },
  {
    name: "Cocos Keeling Island Airport",
    city: "Cocos Keeling Island",
    country: "Cocos (Keeling) Islands",
    iata_code: "CCK",
    _geoloc: {
      lat: -12.1883,
      lng: 96.8339,
    },
    links_count: 2,
    objectID: "6315",
    uniqueID: "0a358c48-4e45-498a-9fe8-3038277d5793",
  },
  {
    name: "Port Augusta Airport",
    city: "Argyle",
    country: "Australia",
    iata_code: "PUG",
    _geoloc: {
      lat: -32.506944,
      lng: 137.716667,
    },
    links_count: 2,
    objectID: "6312",
    uniqueID: "f81719bf-9740-40a7-9a2d-a2b3c82f0dbd",
  },
  {
    name: "Olympic Dam Airport",
    city: "Olympic Dam",
    country: "Australia",
    iata_code: "OLP",
    _geoloc: {
      lat: -30.485,
      lng: 136.877,
    },
    links_count: 2,
    objectID: "6311",
    uniqueID: "e21f625d-16ea-4248-87f6-61c7d66e7f89",
  },
  {
    name: "Moree Airport",
    city: "Moree",
    country: "Australia",
    iata_code: "MRZ",
    _geoloc: {
      lat: -29.4989,
      lng: 149.845,
    },
    links_count: 2,
    objectID: "6300",
    uniqueID: "b2469edb-f5e0-4891-b317-b583d8ba8865",
  },
  {
    name: "McArthur River Mine Airport",
    city: "McArthur River Mine",
    country: "Australia",
    iata_code: "MCV",
    _geoloc: {
      lat: -16.4425,
      lng: 136.084,
    },
    links_count: 2,
    objectID: "6297",
    uniqueID: "b5171d28-c5a4-4010-bb9a-79dbdb93bb33",
  },
  {
    name: "Milingimbi Airport",
    city: "Milingimbi",
    country: "Australia",
    iata_code: "MGT",
    _geoloc: {
      lat: -12.0944,
      lng: 134.894,
    },
    links_count: 2,
    objectID: "6295",
    uniqueID: "25df38cb-84a7-416a-ba95-bbc2e8e37986",
  },
  {
    name: "Lismore Airport",
    city: "Lismore",
    country: "Australia",
    iata_code: "LSY",
    _geoloc: {
      lat: -28.8303,
      lng: 153.26,
    },
    links_count: 2,
    objectID: "6287",
    uniqueID: "464556c3-6763-4e49-8ee4-5ab2ece98371",
  },
  {
    name: "Horn Island Airport",
    city: "Horn Island",
    country: "Australia",
    iata_code: "HID",
    _geoloc: {
      lat: -10.5864,
      lng: 142.29,
    },
    links_count: 2,
    objectID: "6272",
    uniqueID: "884ddd89-57a6-43be-b619-ecc4ccb14864",
  },
  {
    name: "Elcho Island Airport",
    city: "Elcho Island",
    country: "Australia",
    iata_code: "ELC",
    _geoloc: {
      lat: -12.0194,
      lng: 135.571,
    },
    links_count: 2,
    objectID: "6265",
    uniqueID: "65c57e77-bcd4-44ba-aca4-d81daf241238",
  },
  {
    name: "Devonport Airport",
    city: "Devonport",
    country: "Australia",
    iata_code: "DPO",
    _geoloc: {
      lat: -41.1697,
      lng: 146.43,
    },
    links_count: 2,
    objectID: "6264",
    uniqueID: "59392d86-0531-4e4d-9355-992676f805f5",
  },
  {
    name: "Ceduna Airport",
    city: "Ceduna",
    country: "Australia",
    iata_code: "CED",
    _geoloc: {
      lat: -32.1306,
      lng: 133.71,
    },
    links_count: 2,
    objectID: "6256",
    uniqueID: "3d67df1f-de69-4281-b998-b8530846496c",
  },
  {
    name: "Coober Pedy Airport",
    city: "Coober Pedy",
    country: "Australia",
    iata_code: "CPD",
    _geoloc: {
      lat: -29.04,
      lng: 134.721,
    },
    links_count: 2,
    objectID: "6253",
    uniqueID: "6d9e8194-7794-4159-8a7e-d28a7d4327d3",
  },
  {
    name: "Barcaldine Airport",
    city: "Barcaldine",
    country: "Australia",
    iata_code: "BCI",
    _geoloc: {
      lat: -23.5653,
      lng: 145.307,
    },
    links_count: 2,
    objectID: "6238",
    uniqueID: "e19a9d4c-20be-45c0-9bff-60fc504ac823",
  },
  {
    name: "Ranai Airport",
    city: "Ranai-Natuna Besar Island",
    country: "Indonesia",
    iata_code: "NTX",
    _geoloc: {
      lat: 3.908714,
      lng: 108.387897,
    },
    links_count: 2,
    objectID: "6225",
    uniqueID: "c6fa7842-753a-47a8-ae16-93b2d0e21fe2",
  },
  {
    name: "Bakalalan Airport",
    city: "Bakalalan",
    country: "Malaysia",
    iata_code: "BKM",
    _geoloc: {
      lat: 3.974,
      lng: 115.618,
    },
    links_count: 2,
    objectID: "6218",
    uniqueID: "76047c47-2974-4a8a-9cd1-7d18e3f3fd3b",
  },
  {
    name: "Kawthoung Airport",
    city: "Kawthoung",
    country: "Burma",
    iata_code: "KAW",
    _geoloc: {
      lat: 10.049258,
      lng: 98.538006,
    },
    links_count: 2,
    objectID: "6198",
    uniqueID: "26a08514-fe47-4773-b83e-999d140701dd",
  },
  {
    name: "Dien Bien Phu Airport",
    city: "Dienbienphu",
    country: "Vietnam",
    iata_code: "DIN",
    _geoloc: {
      lat: 21.397481,
      lng: 103.007831,
    },
    links_count: 2,
    objectID: "6192",
    uniqueID: "56972e8c-69c1-4190-ad27-e4ada3c112c6",
  },
  {
    name: "Sonderborg",
    city: "Soenderborg",
    country: "Denmark",
    iata_code: "SGD",
    _geoloc: {
      lat: 54.964367,
      lng: 9.791731,
    },
    links_count: 2,
    objectID: "619",
    uniqueID: "b9ba7323-405d-4c58-95d1-73978a81d111",
  },
  {
    name: "Mae Sot Airport",
    city: "Tak",
    country: "Thailand",
    iata_code: "MAQ",
    _geoloc: {
      lat: 16.699856,
      lng: 98.545056,
    },
    links_count: 2,
    objectID: "6186",
    uniqueID: "3b5721bf-2700-43d5-877f-8bd474ed70a1",
  },
  {
    name: "Nepalgunj Airport",
    city: "Nepalgunj",
    country: "Nepal",
    iata_code: "KEP",
    _geoloc: {
      lat: 28.103633,
      lng: 81.667006,
    },
    links_count: 2,
    objectID: "6182",
    uniqueID: "c505c4dc-363f-43af-ad9c-c0225d44b3be",
  },
  {
    name: "Chandragadhi Airport",
    city: "Chandragarhi",
    country: "Nepal",
    iata_code: "BDP",
    _geoloc: {
      lat: 26.570822,
      lng: 88.079578,
    },
    links_count: 2,
    objectID: "6180",
    uniqueID: "31407c3c-b581-4f11-bf7f-8a91ef9a4004",
  },
  {
    name: "Bornholm Ronne",
    city: "Ronne",
    country: "Denmark",
    iata_code: "RNN",
    _geoloc: {
      lat: 55.063267,
      lng: 14.759558,
    },
    links_count: 2,
    objectID: "618",
    uniqueID: "5df0acb8-2d29-4fb1-bf65-0ca701445aab",
  },
  {
    name: "Bharatpur Airport",
    city: "Bharatpur",
    country: "Nepal",
    iata_code: "BHR",
    _geoloc: {
      lat: 27.6781,
      lng: 84.4294,
    },
    links_count: 2,
    objectID: "6179",
    uniqueID: "519c2a42-6357-4c79-b34b-815432d6f871",
  },
  {
    name: "Barisal Airport",
    city: "Barisal",
    country: "Bangladesh",
    iata_code: "BZL",
    _geoloc: {
      lat: 22.801,
      lng: 90.3012,
    },
    links_count: 2,
    objectID: "6176",
    uniqueID: "c08ed155-5891-4795-aeca-9e0f13bd681b",
  },
  {
    name: "Shillong Airport",
    city: "Shillong",
    country: "India",
    iata_code: "SHL",
    _geoloc: {
      lat: 25.7036,
      lng: 91.9787,
    },
    links_count: 2,
    objectID: "6172",
    uniqueID: "96b529ec-5f2d-425f-ba5e-df4defa4568c",
  },
  {
    name: "Penza Airport",
    city: "Penza",
    country: "Russia",
    iata_code: "PEZ",
    _geoloc: {
      lat: 53.1106,
      lng: 45.0211,
    },
    links_count: 2,
    objectID: "6165",
    uniqueID: "9c00d257-6e05-477c-be0a-fe7bced6dbf7",
  },
  {
    name: "Orsk Airport",
    city: "Orsk",
    country: "Russia",
    iata_code: "OSW",
    _geoloc: {
      lat: 51.0725,
      lng: 58.5956,
    },
    links_count: 2,
    objectID: "6164",
    uniqueID: "667f6299-d7c6-40bf-b7bf-eeefd3128e9f",
  },
  {
    name: "Kursk East Airport",
    city: "Kursk",
    country: "Russia",
    iata_code: "URS",
    _geoloc: {
      lat: 51.7506,
      lng: 36.2956,
    },
    links_count: 2,
    objectID: "6157",
    uniqueID: "1e628356-61d7-48c9-94ce-efa9db991567",
  },
  {
    name: "Lopez Island Airport",
    city: "Lopez",
    country: "United States",
    iata_code: "LPS",
    _geoloc: {
      lat: 48.4839,
      lng: -122.938,
    },
    links_count: 2,
    objectID: "6136",
    uniqueID: "8a3628f1-7f27-4d12-8f7d-ebb53e1af063",
  },
  {
    name: "Grant County Airport",
    city: "Silver City",
    country: "United States",
    iata_code: "SVC",
    _geoloc: {
      lat: 32.6365,
      lng: -108.156,
    },
    links_count: 2,
    objectID: "6135",
    uniqueID: "708c983c-2979-4152-b2ca-8d419ddd7648",
  },
  {
    name: "Akutan Seaplane Base",
    city: "Akutan",
    country: "United States",
    iata_code: "KQA",
    _geoloc: {
      lat: 54.1325,
      lng: -165.785,
    },
    links_count: 2,
    objectID: "6134",
    uniqueID: "44c00683-387a-4fc6-bd7e-287ef9825bdf",
  },
  {
    name: "Mid Delta Regional Airport",
    city: "Greenville",
    country: "United States",
    iata_code: "GLH",
    _geoloc: {
      lat: 33.4829,
      lng: -90.9856,
    },
    links_count: 2,
    objectID: "6130",
    uniqueID: "7754e887-93a7-411f-b406-a1d89b1008a0",
  },
  {
    name: "Kearney Municipal Airport",
    city: "Kearney",
    country: "United States",
    iata_code: "EAR",
    _geoloc: {
      lat: 40.727,
      lng: -99.0068,
    },
    links_count: 2,
    objectID: "6129",
    uniqueID: "72d0b11e-f974-480f-b46d-03d7cc8df7e1",
  },
  {
    name: "Elista Airport",
    city: "Elista",
    country: "Russia",
    iata_code: "ESL",
    _geoloc: {
      lat: 46.3739,
      lng: 44.3309,
    },
    links_count: 2,
    objectID: "6125",
    uniqueID: "37cb17a6-d54d-446a-b2ff-67999f5dd52e",
  },
  {
    name: "Beslan Airport",
    city: "Beslan",
    country: "Russia",
    iata_code: "OGZ",
    _geoloc: {
      lat: 43.2051,
      lng: 44.6066,
    },
    links_count: 2,
    objectID: "6124",
    uniqueID: "d5f51d1e-b0ae-4e08-a157-1fbf17a821a6",
  },
  {
    name: "Grozny Airport",
    city: "Grozny",
    country: "Russia",
    iata_code: "GRV",
    _geoloc: {
      lat: 43.2981,
      lng: 45.7841,
    },
    links_count: 2,
    objectID: "6122",
    uniqueID: "2b9c868e-2345-4ea4-bcc2-5235edac406a",
  },
  {
    name: "Igarka Airport",
    city: "Igarka",
    country: "Russia",
    iata_code: "IAA",
    _geoloc: {
      lat: 67.4372,
      lng: 86.6219,
    },
    links_count: 2,
    objectID: "6121",
    uniqueID: "41c024b1-0a42-45a7-9ab3-9bdb8e2e097c",
  },
  {
    name: "Karup",
    city: "Karup",
    country: "Denmark",
    iata_code: "KRP",
    _geoloc: {
      lat: 56.297458,
      lng: 9.124628,
    },
    links_count: 2,
    objectID: "612",
    uniqueID: "6d18eaf1-8fd3-4a41-a605-014872724574",
  },
  {
    name: "Kyzyl Airport",
    city: "Kyzyl",
    country: "Russia",
    iata_code: "KYZ",
    _geoloc: {
      lat: 51.6694,
      lng: 94.4006,
    },
    links_count: 2,
    objectID: "6118",
    uniqueID: "5c1c4a28-c564-48e5-985a-c559860cbe6f",
  },
  {
    name: "Petrozavodsk Airport",
    city: "Petrozavodsk",
    country: "Russia",
    iata_code: "PES",
    _geoloc: {
      lat: 61.8852,
      lng: 34.1547,
    },
    links_count: 2,
    objectID: "6114",
    uniqueID: "fe0a4ce1-2b53-4eb8-a00c-c89fdc6f6116",
  },
  {
    name: "Solovki Airport",
    city: "Solovetsky Islands",
    country: "Russia",
    iata_code: "CSH",
    _geoloc: {
      lat: 65.03,
      lng: 35.7333,
    },
    links_count: 2,
    objectID: "6110",
    uniqueID: "4a124eb4-6421-4afa-ab33-c00c95826088",
  },
  {
    name: "Uzhhorod International Airport",
    city: "Uzhgorod",
    country: "Ukraine",
    iata_code: "UDJ",
    _geoloc: {
      lat: 48.634278,
      lng: 22.263356,
    },
    links_count: 2,
    objectID: "6109",
    uniqueID: "09dc321a-9b1e-4219-b26d-4b44fe9cc3da",
  },
  {
    name: "Kingston",
    city: "Kingston",
    country: "Canada",
    iata_code: "YGK",
    _geoloc: {
      lat: 44.225277,
      lng: -76.596944,
    },
    links_count: 2,
    objectID: "61",
    uniqueID: "618f9378-9198-425a-9640-fb766191a6a6",
  },
  {
    name: "Nakhchivan Airport",
    city: "Nakhchivan",
    country: "Azerbaijan",
    iata_code: "NAJ",
    _geoloc: {
      lat: 39.1888,
      lng: 45.4584,
    },
    links_count: 2,
    objectID: "6090",
    uniqueID: "c71a9700-6d1e-4333-8fdc-a3bd5190cc03",
  },
  {
    name: "Santa Rosa Airport",
    city: "Santa Rosa",
    country: "Brazil",
    iata_code: "SRA",
    _geoloc: {
      lat: -27.9067,
      lng: -54.5204,
    },
    links_count: 2,
    objectID: "6069",
    uniqueID: "e088c8d8-0ca6-4350-a4d9-c0f2180ec70d",
  },
  {
    name: "Capitán Av. Selin Zeitun Lopez Airport",
    city: "Riberalta",
    country: "Bolivia",
    iata_code: "RIB",
    _geoloc: {
      lat: -11,
      lng: -66,
    },
    links_count: 2,
    objectID: "6058",
    uniqueID: "4a7a32ba-57f1-472a-b79f-914c5fe9f140",
  },
  {
    name: "Capitán de Av. Emilio Beltrán Airport",
    city: "Guayaramerín",
    country: "Bolivia",
    iata_code: "GYA",
    _geoloc: {
      lat: -10.8206,
      lng: -65.3456,
    },
    links_count: 2,
    objectID: "6056",
    uniqueID: "be18a0f2-fff6-4a0c-a1af-2300c6ef5531",
  },
  {
    name: "Reyes Murillo Airport",
    city: "Nuquí",
    country: "Colombia",
    iata_code: "NQU",
    _geoloc: {
      lat: 5.7,
      lng: -77.28,
    },
    links_count: 2,
    objectID: "6053",
    uniqueID: "741d0864-9f90-43e0-8686-f0151719c34b",
  },
  {
    name: "La Pedrera Airport",
    city: "La Pedrera",
    country: "Colombia",
    iata_code: "LPD",
    _geoloc: {
      lat: -1.33,
      lng: -69.58,
    },
    links_count: 2,
    objectID: "6052",
    uniqueID: "4bc089e6-4f61-4d3d-9b65-4be2b56cdc1c",
  },
  {
    name: "Caucaya Airport",
    city: "Puerto Leguízamo",
    country: "Colombia",
    iata_code: "LQM",
    _geoloc: {
      lat: -0.18,
      lng: -74.77,
    },
    links_count: 2,
    objectID: "6051",
    uniqueID: "c360c2fb-ac29-4712-ade3-ff9128a9f609",
  },
  {
    name: "Chapada Diamantina Airport",
    city: "Lençóis",
    country: "Brazil",
    iata_code: "LEC",
    _geoloc: {
      lat: -12.4823,
      lng: -41.277,
    },
    links_count: 2,
    objectID: "6036",
    uniqueID: "44a81909-4bba-4ca7-91b1-58a25e3f9120",
  },
  {
    name: "Masbate Airport",
    city: "Masbate",
    country: "Philippines",
    iata_code: "MBT",
    _geoloc: {
      lat: 12.3694,
      lng: 123.629,
    },
    links_count: 2,
    objectID: "6025",
    uniqueID: "30ec7aa8-4f41-4811-8931-f2c6ffcc7d5f",
  },
  {
    name: "Calbayog Airport",
    city: "Calbayog City",
    country: "Philippines",
    iata_code: "CYP",
    _geoloc: {
      lat: 12.072706,
      lng: 124.545092,
    },
    links_count: 2,
    objectID: "6023",
    uniqueID: "598ea7c0-f8bd-418b-85aa-0a686b2ca432",
  },
  {
    name: "Virac Airport",
    city: "Virac",
    country: "Philippines",
    iata_code: "VRC",
    _geoloc: {
      lat: 13.576439,
      lng: 124.205672,
    },
    links_count: 2,
    objectID: "6022",
    uniqueID: "f44d0afe-0ff3-4425-bc19-eced64214f6a",
  },
  {
    name: "Jolo Airport",
    city: "Jolo",
    country: "Philippines",
    iata_code: "JOL",
    _geoloc: {
      lat: 6.05367,
      lng: 121.011,
    },
    links_count: 2,
    objectID: "6013",
    uniqueID: "77291184-5289-4108-ba69-6abd35d1567b",
  },
  {
    name: "Camiguin Airport",
    city: "Camiguin",
    country: "Philippines",
    iata_code: "CGM",
    _geoloc: {
      lat: 9.25352,
      lng: 124.707,
    },
    links_count: 2,
    objectID: "6012",
    uniqueID: "48e8a614-3415-46a1-8631-f1759448c9e6",
  },
  {
    name: "Yangyang International Airport",
    city: "Sokcho / Gangneung",
    country: "South Korea",
    iata_code: "YNY",
    _geoloc: {
      lat: 38.061311,
      lng: 128.669164,
    },
    links_count: 2,
    objectID: "6006",
    uniqueID: "aa32b8eb-d814-46a4-86a1-729b93505443",
  },
  {
    name: "Wonju Airport",
    city: "Wonju",
    country: "South Korea",
    iata_code: "WJU",
    _geoloc: {
      lat: 37.438081,
      lng: 127.960383,
    },
    links_count: 2,
    objectID: "6005",
    uniqueID: "36e233b5-3a26-4d71-a5ad-4dadd346c009",
  },
  {
    name: "Shonai Airport",
    city: "Shonai",
    country: "Japan",
    iata_code: "SYO",
    _geoloc: {
      lat: 38.812222,
      lng: 139.787222,
    },
    links_count: 2,
    objectID: "6001",
    uniqueID: "c80b2407-40d7-437e-89f8-3a1303054fb1",
  },
  {
    name: "Odate Noshiro Airport",
    city: "Odate Noshiro",
    country: "Japan",
    iata_code: "ONJ",
    _geoloc: {
      lat: 40.1919,
      lng: 140.371,
    },
    links_count: 2,
    objectID: "6000",
    uniqueID: "3d8cae75-65f6-4dce-a475-f0fef7f41e50",
  },
  {
    name: "Iwami Airport",
    city: "Iwami",
    country: "Japan",
    iata_code: "IWJ",
    _geoloc: {
      lat: 34.6764,
      lng: 131.79,
    },
    links_count: 2,
    objectID: "5998",
    uniqueID: "792ba306-4a15-41c2-8d7e-dab4bb9b7018",
  },
  {
    name: "Losuia Airport",
    city: "Losuia",
    country: "Papua New Guinea",
    iata_code: "LSA",
    _geoloc: {
      lat: -8.50582,
      lng: 151.081,
    },
    links_count: 2,
    objectID: "5980",
    uniqueID: "b32ba134-52d9-4095-881b-4eec8865aa30",
  },
  {
    name: "Adak Airport",
    city: "Adak Island",
    country: "United States",
    iata_code: "ADK",
    _geoloc: {
      lat: 51.878,
      lng: -176.646,
    },
    links_count: 2,
    objectID: "5959",
    uniqueID: "464b97c6-1518-4ebd-bd2e-af83e4771f04",
  },
  {
    name: "Sambu Airport",
    city: "Boca de Sábalo",
    country: "Panama",
    iata_code: "SAX",
    _geoloc: {
      lat: 8.017,
      lng: -78.2,
    },
    links_count: 2,
    objectID: "5958",
    uniqueID: "2a1bb6c8-b229-4a35-bb68-f28a802ba6ba",
  },
  {
    name: "Contadora Airport",
    city: "Contadora Island",
    country: "Panama",
    iata_code: "OTD",
    _geoloc: {
      lat: 8.62876,
      lng: -79.0347,
    },
    links_count: 2,
    objectID: "5957",
    uniqueID: "b576676f-43d1-4522-969c-25b3eac6d854",
  },
  {
    name: "Bahia Piña Airport",
    city: "Bahia Piña",
    country: "Panama",
    iata_code: "BFQ",
    _geoloc: {
      lat: 7.583,
      lng: -78.2,
    },
    links_count: 2,
    objectID: "5955",
    uniqueID: "d99e4bc3-6c8e-4d02-8259-6663bc145739",
  },
  {
    name: "Skardu Airport",
    city: "Skardu",
    country: "Pakistan",
    iata_code: "KDU",
    _geoloc: {
      lat: 35.335508,
      lng: 75.536047,
    },
    links_count: 2,
    objectID: "5949",
    uniqueID: "79537bb5-795d-4ab7-badd-0f631c1fc6f5",
  },
  {
    name: "Dalbandin Airport",
    city: "Dalbandin",
    country: "Pakistan",
    iata_code: "DBA",
    _geoloc: {
      lat: 28.8783,
      lng: 64.3998,
    },
    links_count: 2,
    objectID: "5941",
    uniqueID: "58f3e109-bd9e-44a9-bd09-c58a1ce15e01",
  },
  {
    name: "Sabzevar National Airport",
    city: "Sabzevar",
    country: "Iran",
    iata_code: "AFZ",
    _geoloc: {
      lat: 36.168083,
      lng: 57.595183,
    },
    links_count: 2,
    objectID: "5931",
    uniqueID: "16b1b239-1f73-4cef-b1e8-76973cc92af0",
  },
  {
    name: "Bojnourd Airport",
    city: "Bojnourd",
    country: "Iran",
    iata_code: "BJB",
    _geoloc: {
      lat: 37.492958,
      lng: 57.308219,
    },
    links_count: 2,
    objectID: "5930",
    uniqueID: "727af23c-3c83-4c18-a919-65eb1127b453",
  },
  {
    name: "Bam Airport",
    city: "Bam",
    country: "Iran",
    iata_code: "BXR",
    _geoloc: {
      lat: 29.084169,
      lng: 58.450042,
    },
    links_count: 2,
    objectID: "5928",
    uniqueID: "6807f0a9-6926-43c9-adbd-1980040d6e2d",
  },
  {
    name: "Khoram Abad Airport",
    city: "Khorram Abad",
    country: "Iran",
    iata_code: "KHD",
    _geoloc: {
      lat: 33.435378,
      lng: 48.282889,
    },
    links_count: 2,
    objectID: "5927",
    uniqueID: "4b587c34-dd20-47e8-a018-64cd75df9d7b",
  },
  {
    name: "Île des Pins Airport",
    city: "Île des Pins",
    country: "New Caledonia",
    iata_code: "ILP",
    _geoloc: {
      lat: -22.5889,
      lng: 167.456,
    },
    links_count: 2,
    objectID: "5921",
    uniqueID: "9b15720e-cad7-480a-8e43-8522fe037f90",
  },
  {
    name: "Anelghowhat Airport",
    city: "Anelghowhat",
    country: "Vanuatu",
    iata_code: "AUY",
    _geoloc: {
      lat: -20.2492,
      lng: 169.771,
    },
    links_count: 2,
    objectID: "5914",
    uniqueID: "840ad61b-3088-4949-8546-958f51317724",
  },
  {
    name: "Valesdir Airport",
    city: "Valesdir",
    country: "Vanuatu",
    iata_code: "VLS",
    _geoloc: {
      lat: -16.7961,
      lng: 168.177,
    },
    links_count: 2,
    objectID: "5911",
    uniqueID: "e28921df-b01f-4c86-a88d-490bf9fc3767",
  },
  {
    name: "Tongoa Island Airport",
    city: "Tongoa Island",
    country: "Vanuatu",
    iata_code: "TGH",
    _geoloc: {
      lat: -16.8911,
      lng: 168.551,
    },
    links_count: 2,
    objectID: "5909",
    uniqueID: "38670a3d-aa45-442e-916e-3f95c66b8a62",
  },
  {
    name: "Naone Airport",
    city: "Maewo Island",
    country: "Vanuatu",
    iata_code: "MWF",
    _geoloc: {
      lat: -15,
      lng: 168.083,
    },
    links_count: 2,
    objectID: "5903",
    uniqueID: "4dbeaa0c-9053-4cef-bdd7-e5e7f0fbd1a5",
  },
  {
    name: "Sara Airport",
    city: "Pentecost Island",
    country: "Vanuatu",
    iata_code: "SSR",
    _geoloc: {
      lat: -15.4708,
      lng: 168.152,
    },
    links_count: 2,
    objectID: "5899",
    uniqueID: "077da752-8871-4383-abee-a182a202b540",
  },
  {
    name: "Sangafa Airport",
    city: "Sangafa",
    country: "Vanuatu",
    iata_code: "EAE",
    _geoloc: {
      lat: -17.0903,
      lng: 168.343,
    },
    links_count: 2,
    objectID: "5896",
    uniqueID: "9a59ac37-ebc7-4e12-bf09-0dc5a623aaf1",
  },
  {
    name: "Pointe Vele Airport",
    city: "Futuna Island",
    country: "Wallis and Futuna",
    iata_code: "FUT",
    _geoloc: {
      lat: -14.3114,
      lng: -178.066,
    },
    links_count: 2,
    objectID: "5885",
    uniqueID: "4bdfadc8-653e-41ad-abbc-f0b3839fe78a",
  },
  {
    name: "Vanua Balavu Airport",
    city: "Vanua Balavu",
    country: "Fiji",
    iata_code: "VBV",
    _geoloc: {
      lat: -17.269,
      lng: -178.976,
    },
    links_count: 2,
    objectID: "5883",
    uniqueID: "cbb9d371-1c22-4613-b546-786fc3843888",
  },
  {
    name: "Rotuma Airport",
    city: "Rotuma",
    country: "Fiji",
    iata_code: "RTA",
    _geoloc: {
      lat: -12.4825,
      lng: 177.071,
    },
    links_count: 2,
    objectID: "5877",
    uniqueID: "7f1ceb2b-c7f2-4794-848e-2da68f010e4e",
  },
  {
    name: "Lakeba Island Airport",
    city: "Lakeba Island",
    country: "Fiji",
    iata_code: "LKB",
    _geoloc: {
      lat: -18.1992,
      lng: -178.817,
    },
    links_count: 2,
    objectID: "5873",
    uniqueID: "b195ac9d-5973-4863-928c-99cb4fe30d69",
  },
  {
    name: "Cicia Airport",
    city: "Cicia",
    country: "Fiji",
    iata_code: "ICI",
    _geoloc: {
      lat: -17.7433,
      lng: -179.342,
    },
    links_count: 2,
    objectID: "5867",
    uniqueID: "6ad1943b-0fb8-416c-8863-abfa99bc9ac0",
  },
  {
    name: "Mitiaro Island Airport",
    city: "Mitiaro Island",
    country: "Cook Islands",
    iata_code: "MOI",
    _geoloc: {
      lat: -19.8425,
      lng: -157.703,
    },
    links_count: 2,
    objectID: "5865",
    uniqueID: "e6373c4a-6c61-428b-bf2f-f4450f5efe48",
  },
  {
    name: "Mauke Airport",
    city: "Mauke Island",
    country: "Cook Islands",
    iata_code: "MUK",
    _geoloc: {
      lat: -20.1361,
      lng: -157.345,
    },
    links_count: 2,
    objectID: "5864",
    uniqueID: "e5be4548-eb52-4023-8248-53debe733d8e",
  },
  {
    name: "Mangaia Island Airport",
    city: "Mangaia Island",
    country: "Cook Islands",
    iata_code: "MGS",
    _geoloc: {
      lat: -21.8956,
      lng: -157.905,
    },
    links_count: 2,
    objectID: "5862",
    uniqueID: "5217ac0e-9e06-440e-8ed2-e26c23195706",
  },
  {
    name: "Long Banga Airport",
    city: "Long Banga",
    country: "Malaysia",
    iata_code: "LBP",
    _geoloc: {
      lat: 3.18495,
      lng: 115.454,
    },
    links_count: 2,
    objectID: "5855",
    uniqueID: "921bd284-a551-41a7-9e53-7915a3e4fb8b",
  },
  {
    name: "Tobias Bolanos International Airport",
    city: "San Jose",
    country: "Costa Rica",
    iata_code: "SYQ",
    _geoloc: {
      lat: 9.957053,
      lng: -84.139797,
    },
    links_count: 2,
    objectID: "5848",
    uniqueID: "9e92a19a-36c8-4d1d-9900-dda0c4d72569",
  },
  {
    name: "Aerotortuguero Airport",
    city: "Roxana",
    country: "Costa Rica",
    iata_code: "TTQ",
    _geoloc: {
      lat: 10.569,
      lng: -83.5148,
    },
    links_count: 2,
    objectID: "5842",
    uniqueID: "a8968b56-8452-461a-b7f5-d345bd07617e",
  },
  {
    name: "Jaqué Airport",
    city: "Jaqué",
    country: "Panama",
    iata_code: "JQE",
    _geoloc: {
      lat: 7.51778,
      lng: -78.1572,
    },
    links_count: 2,
    objectID: "5840",
    uniqueID: "64f6465c-bb24-42c3-b944-a53925019c32",
  },
  {
    name: "Salt Cay Airport",
    city: "Salt Cay",
    country: "Turks and Caicos Islands",
    iata_code: "SLX",
    _geoloc: {
      lat: 21.333,
      lng: -71.2,
    },
    links_count: 2,
    objectID: "5809",
    uniqueID: "3e001aae-7977-4a99-96e2-d800726f1eca",
  },
  {
    name: "Ubari Airport",
    city: "Ubari",
    country: "Libya",
    iata_code: "QUB",
    _geoloc: {
      lat: 26.5675,
      lng: 12.8231,
    },
    links_count: 2,
    objectID: "5805",
    uniqueID: "e6b5d525-bf36-4027-b0fb-028aee5cca46",
  },
  {
    name: "Balikesir Korfez Airport",
    city: "Balikesir Korfez",
    country: "Turkey",
    iata_code: "EDO",
    _geoloc: {
      lat: 39.5546,
      lng: 27.0138,
    },
    links_count: 2,
    objectID: "5802",
    uniqueID: "636cfa52-cb59-483f-8a67-171bd826a026",
  },
  {
    name: "Isparta Süleyman Demirel Airport",
    city: "Isparta",
    country: "Turkey",
    iata_code: "ISE",
    _geoloc: {
      lat: 37.8554,
      lng: 30.3684,
    },
    links_count: 2,
    objectID: "5801",
    uniqueID: "48215d4f-40c7-4516-ba3b-4c038b496335",
  },
  {
    name: "Mount Pleasant",
    city: "Mount Pleasant",
    country: "Falkland Islands",
    iata_code: "MPN",
    _geoloc: {
      lat: -51.822777,
      lng: -58.447222,
    },
    links_count: 2,
    objectID: "579",
    uniqueID: "7c91827c-e5d4-4e5e-bce4-a67383c1eff2",
  },
  {
    name: "Logroño-Agoncillo Airport",
    city: "Logroño-Agoncillo",
    country: "Spain",
    iata_code: "RJL",
    _geoloc: {
      lat: 42.4542,
      lng: -2.32083,
    },
    links_count: 2,
    objectID: "5781",
    uniqueID: "f8e56f4d-b638-4035-806f-8c3654139d84",
  },
  {
    name: "Yakima Air Terminal McAllister Field",
    city: "Yakima",
    country: "United States",
    iata_code: "YKM",
    _geoloc: {
      lat: 46.5682,
      lng: -120.544,
    },
    links_count: 2,
    objectID: "5779",
    uniqueID: "5e1c13ea-f498-492c-8f9f-b9a6cab7be9a",
  },
  {
    name: "Victoria Regional Airport",
    city: "Victoria",
    country: "United States",
    iata_code: "VCT",
    _geoloc: {
      lat: 28.8526,
      lng: -96.9185,
    },
    links_count: 2,
    objectID: "5775",
    uniqueID: "191ec5fd-a8be-409b-b326-1ec1c1d552e4",
  },
  {
    name: "Quincy Regional Baldwin Field",
    city: "Quincy",
    country: "United States",
    iata_code: "UIN",
    _geoloc: {
      lat: 39.9427,
      lng: -91.1946,
    },
    links_count: 2,
    objectID: "5774",
    uniqueID: "20425fcf-9090-4c9d-9633-7c53e06174a7",
  },
  {
    name: "Salina Municipal Airport",
    city: "Salina",
    country: "United States",
    iata_code: "SLN",
    _geoloc: {
      lat: 38.791,
      lng: -97.6522,
    },
    links_count: 2,
    objectID: "5771",
    uniqueID: "68accd22-3754-47ed-a4c6-5f22d8660150",
  },
  {
    name: "Adirondack Regional Airport",
    city: "Saranac Lake",
    country: "United States",
    iata_code: "SLK",
    _geoloc: {
      lat: 44.3853,
      lng: -74.2062,
    },
    links_count: 2,
    objectID: "5770",
    uniqueID: "2a19a236-7770-4925-82f2-fa9e20ebd930",
  },
  {
    name: "Sheridan County Airport",
    city: "Sheridan",
    country: "United States",
    iata_code: "SHR",
    _geoloc: {
      lat: 44.7692,
      lng: -106.98,
    },
    links_count: 2,
    objectID: "5769",
    uniqueID: "83c55c1e-6618-4730-b92a-56b6a503c760",
  },
  {
    name: "Rutland State Airport",
    city: "Rutland",
    country: "United States",
    iata_code: "RUT",
    _geoloc: {
      lat: 43.5294,
      lng: -72.9496,
    },
    links_count: 2,
    objectID: "5767",
    uniqueID: "faec3614-5093-48b7-b8a1-cc04e8999441",
  },
  {
    name: "Pease International Tradeport",
    city: "Portsmouth",
    country: "United States",
    iata_code: "PSM",
    _geoloc: {
      lat: 43.0779,
      lng: -70.8233,
    },
    links_count: 2,
    objectID: "5763",
    uniqueID: "56d94744-470a-40d5-b5a8-d28c54e7a1aa",
  },
  {
    name: "Pellston Regional Airport of Emmet County Airport",
    city: "Pellston",
    country: "United States",
    iata_code: "PLN",
    _geoloc: {
      lat: 45.5709,
      lng: -84.7967,
    },
    links_count: 2,
    objectID: "5762",
    uniqueID: "12ced266-b052-4efb-aa3c-4f3ccf9bf5eb",
  },
  {
    name: "Pocatello Regional Airport",
    city: "Pocatello",
    country: "United States",
    iata_code: "PIH",
    _geoloc: {
      lat: 42.9098,
      lng: -112.596,
    },
    links_count: 2,
    objectID: "5760",
    uniqueID: "fd2715ef-f807-4e3b-8702-8d52bd5c71ed",
  },
  {
    name: "Hattiesburg Laurel Regional Airport",
    city: "Hattiesburg/Laurel",
    country: "United States",
    iata_code: "PIB",
    _geoloc: {
      lat: 31.4671,
      lng: -89.3371,
    },
    links_count: 2,
    objectID: "5759",
    uniqueID: "092d328d-65b5-4041-a882-3083ffb53eac",
  },
  {
    name: "Northwest Alabama Regional Airport",
    city: "Muscle Shoals",
    country: "United States",
    iata_code: "MSL",
    _geoloc: {
      lat: 34.7453,
      lng: -87.6102,
    },
    links_count: 2,
    objectID: "5756",
    uniqueID: "2a4a2d37-a249-4cc1-8402-412a9b22780f",
  },
  {
    name: "Muskegon County Airport",
    city: "Muskegon",
    country: "United States",
    iata_code: "MKG",
    _geoloc: {
      lat: 43.1695,
      lng: -86.2382,
    },
    links_count: 2,
    objectID: "5754",
    uniqueID: "639565ea-70cf-4c6e-9b2a-8759912af74d",
  },
  {
    name: "Lancaster Airport",
    city: "Lancaster",
    country: "United States",
    iata_code: "LNS",
    _geoloc: {
      lat: 40.1217,
      lng: -76.2961,
    },
    links_count: 2,
    objectID: "5751",
    uniqueID: "71183492-91ff-4fc6-a00e-e7f6603674e8",
  },
  {
    name: "North Platte Regional Airport Lee Bird Field",
    city: "North Platte",
    country: "United States",
    iata_code: "LBF",
    _geoloc: {
      lat: 41.1262,
      lng: -100.684,
    },
    links_count: 2,
    objectID: "5748",
    uniqueID: "baa5cff1-3389-4088-9e51-dff5aedfc884",
  },
  {
    name: "Laramie Regional Airport",
    city: "Laramie",
    country: "United States",
    iata_code: "LAR",
    _geoloc: {
      lat: 41.3121,
      lng: -105.675,
    },
    links_count: 2,
    objectID: "5746",
    uniqueID: "de5e2ba7-df56-42ae-be9a-22b2305bc113",
  },
  {
    name: "Kirksville Regional Airport",
    city: "Kirksville",
    country: "United States",
    iata_code: "IRK",
    _geoloc: {
      lat: 40.0935,
      lng: -92.5449,
    },
    links_count: 2,
    objectID: "5744",
    uniqueID: "a4fbf4ab-43e1-42a0-9b3a-0b845b08fc47",
  },
  {
    name: "Wokal Field Glasgow International Airport",
    city: "Glasgow",
    country: "United States",
    iata_code: "GGW",
    _geoloc: {
      lat: 48.2125,
      lng: -106.615,
    },
    links_count: 2,
    objectID: "5739",
    uniqueID: "ef1efeac-6e0d-4814-af1a-9f47308afe5d",
  },
  {
    name: "Elko Regional Airport",
    city: "Elko",
    country: "United States",
    iata_code: "EKO",
    _geoloc: {
      lat: 40.8249,
      lng: -115.792,
    },
    links_count: 2,
    objectID: "5736",
    uniqueID: "1cfb33c5-feba-497d-aa2e-65d18a6335b5",
  },
  {
    name: "Chippewa Valley Regional Airport",
    city: "Eau Claire",
    country: "United States",
    iata_code: "EAU",
    _geoloc: {
      lat: 44.8658,
      lng: -91.4843,
    },
    links_count: 2,
    objectID: "5735",
    uniqueID: "b966eb50-b475-4040-b9a1-f900978588ea",
  },
  {
    name: "Houghton County Memorial Airport",
    city: "Hancock",
    country: "United States",
    iata_code: "CMX",
    _geoloc: {
      lat: 47.1684,
      lng: -88.4891,
    },
    links_count: 2,
    objectID: "5732",
    uniqueID: "23e56ce4-3094-4852-bef9-9bd49fbccd3c",
  },
  {
    name: "William R Fairchild International Airport",
    city: "Port Angeles",
    country: "United States",
    iata_code: "CLM",
    _geoloc: {
      lat: 48.1202,
      lng: -123.5,
    },
    links_count: 2,
    objectID: "5731",
    uniqueID: "439f026b-7279-4c6d-ac15-82132ffb1f69",
  },
  {
    name: "Chippewa County International Airport",
    city: "Sault Ste Marie",
    country: "United States",
    iata_code: "CIU",
    _geoloc: {
      lat: 46.2508,
      lng: -84.4724,
    },
    links_count: 2,
    objectID: "5729",
    uniqueID: "ca999f43-f437-4c69-b618-d7c3df571975",
  },
  {
    name: "Cape Girardeau Regional Airport",
    city: "Cape Girardeau",
    country: "United States",
    iata_code: "CGI",
    _geoloc: {
      lat: 37.2253,
      lng: -89.5708,
    },
    links_count: 2,
    objectID: "5728",
    uniqueID: "b68149c0-2db0-452f-93f6-90274e85931d",
  },
  {
    name: "Western Nebraska Regional Airport",
    city: "Scottsbluff",
    country: "United States",
    iata_code: "BFF",
    _geoloc: {
      lat: 41.874,
      lng: -103.596,
    },
    links_count: 2,
    objectID: "5723",
    uniqueID: "22aa80d8-5023-4383-8472-3c61e04bc089",
  },
  {
    name: "Walla Walla Regional Airport",
    city: "Walla Walla",
    country: "United States",
    iata_code: "ALW",
    _geoloc: {
      lat: 46.0949,
      lng: -118.288,
    },
    links_count: 2,
    objectID: "5719",
    uniqueID: "d3a87890-751e-431c-82c4-756ee6718b23",
  },
  {
    name: "Athens Ben Epps Airport",
    city: "Athens",
    country: "United States",
    iata_code: "AHN",
    _geoloc: {
      lat: 33.9486,
      lng: -83.3263,
    },
    links_count: 2,
    objectID: "5716",
    uniqueID: "590ce4b9-7848-4e44-821e-00b406be794b",
  },
  {
    name: "Aberdeen Regional Airport",
    city: "Aberdeen",
    country: "United States",
    iata_code: "ABR",
    _geoloc: {
      lat: 45.4491,
      lng: -98.4218,
    },
    links_count: 2,
    objectID: "5714",
    uniqueID: "71df9578-760c-4434-a9fd-de734aed6ff2",
  },
  {
    name: "Arua Airport",
    city: "Arua",
    country: "Uganda",
    iata_code: "RUA",
    _geoloc: {
      lat: 3.05,
      lng: 30.917,
    },
    links_count: 2,
    objectID: "5709",
    uniqueID: "28e70898-a26c-4822-b51a-1295a896abb0",
  },
  {
    name: "Bukoba Airport",
    city: "Bukoba",
    country: "Tanzania",
    iata_code: "BKZ",
    _geoloc: {
      lat: -1.3,
      lng: 31.8,
    },
    links_count: 2,
    objectID: "5703",
    uniqueID: "7bc251fe-0702-430a-b1aa-c8124d530aee",
  },
  {
    name: "Lokichoggio Airport",
    city: "Lokichoggio",
    country: "Kenya",
    iata_code: "LKG",
    _geoloc: {
      lat: 4.204117,
      lng: 34.348186,
    },
    links_count: 2,
    objectID: "5693",
    uniqueID: "e4b741ef-0809-415a-ae4a-ea8cca2e9826",
  },
  {
    name: "Gode Airport",
    city: "Gode",
    country: "Ethiopia",
    iata_code: "GDE",
    _geoloc: {
      lat: 5.935128,
      lng: 43.578567,
    },
    links_count: 2,
    objectID: "5680",
    uniqueID: "14b9db8c-7312-48a9-b4a2-f358514d2979",
  },
  {
    name: "Sao Filipe Airport",
    city: "Sao Filipe",
    country: "Fogo Island",
    iata_code: "SFL",
    _geoloc: {
      lat: 0,
      lng: 14.885,
    },
    links_count: 2,
    objectID: "5675",
    uniqueID: "03628086-8e64-421b-ae33-856095730c7e",
  },
  {
    name: "La Gomera Airport",
    city: "La Gomera",
    country: "Spain",
    iata_code: "GMZ",
    _geoloc: {
      lat: 28.0296,
      lng: -17.2146,
    },
    links_count: 2,
    objectID: "5659",
    uniqueID: "d989680d-7838-4465-8220-0ac48f293353",
  },
  {
    name: "Oranjemund Airport",
    city: "Oranjemund",
    country: "Namibia",
    iata_code: "OMD",
    _geoloc: {
      lat: -28.5847,
      lng: 16.4467,
    },
    links_count: 2,
    objectID: "5643",
    uniqueID: "a9d08d5e-9e62-4dea-9047-db4c75bc9228",
  },
  {
    name: "Ondangwa Airport",
    city: "Ondangwa",
    country: "Namibia",
    iata_code: "OND",
    _geoloc: {
      lat: -17.8782,
      lng: 15.9526,
    },
    links_count: 2,
    objectID: "5642",
    uniqueID: "097a6719-979b-4460-9410-f477b393c6a5",
  },
  {
    name: "Luderitz Airport",
    city: "Luderitz",
    country: "Namibia",
    iata_code: "LUD",
    _geoloc: {
      lat: -26.6874,
      lng: 15.2429,
    },
    links_count: 2,
    objectID: "5641",
    uniqueID: "93c6736e-aeb6-43ac-9aff-47fb8460a54d",
  },
  {
    name: "Chimoio Airport",
    city: "Chimoio",
    country: "Mozambique",
    iata_code: "VPY",
    _geoloc: {
      lat: -19.151267,
      lng: 33.428958,
    },
    links_count: 2,
    objectID: "5637",
    uniqueID: "60f1382e-cbaf-444c-a8b9-ed34d6a89f4f",
  },
  {
    name: "Namibe Airport",
    city: "Mocamedes",
    country: "Angola",
    iata_code: "MSZ",
    _geoloc: {
      lat: -15.261222,
      lng: 12.146756,
    },
    links_count: 2,
    objectID: "5633",
    uniqueID: "114ffeec-8d0b-4a87-adc6-6da8650a6c16",
  },
  {
    name: "Chipata Airport",
    city: "Chipata",
    country: "Zambia",
    iata_code: "CIP",
    _geoloc: {
      lat: -13.5583,
      lng: 32.5872,
    },
    links_count: 2,
    objectID: "5612",
    uniqueID: "f6a16f54-a74d-4d2e-82ae-5bc1fcbf74f0",
  },
  {
    name: "Shire Inda Selassie Airport",
    city: "Shire Indasilase",
    country: "Ethiopia",
    iata_code: "SHC",
    _geoloc: {
      lat: 14.0781,
      lng: 38.2725,
    },
    links_count: 2,
    objectID: "5601",
    uniqueID: "85dba690-dc87-407d-abff-731606942192",
  },
  {
    name: "Barra Airport",
    city: "Barra",
    country: "United Kingdom",
    iata_code: "BRR",
    _geoloc: {
      lat: 57.0228,
      lng: -7.44306,
    },
    links_count: 2,
    objectID: "5575",
    uniqueID: "60f45ca0-b362-48c5-973e-6b54af94013d",
  },
  {
    name: "Land's End / St. Just Airport",
    city: "Land's End",
    country: "United Kingdom",
    iata_code: "LEQ",
    _geoloc: {
      lat: 50.1028,
      lng: -5.67056,
    },
    links_count: 2,
    objectID: "5572",
    uniqueID: "801d03ff-0cf7-4916-8dac-f3f36e7044c9",
  },
  {
    name: "Campbeltown Airport",
    city: "Campbeltown",
    country: "United Kingdom",
    iata_code: "CAL",
    _geoloc: {
      lat: 55.4372,
      lng: -5.68639,
    },
    links_count: 2,
    objectID: "5563",
    uniqueID: "7e0d56ce-c8c0-428e-8076-edd8020fc7bf",
  },
  {
    name: "Guemar Airport",
    city: "Guemar",
    country: "Algeria",
    iata_code: "ELU",
    _geoloc: {
      lat: 33.5114,
      lng: 6.77679,
    },
    links_count: 2,
    objectID: "5555",
    uniqueID: "53ed6f40-6a2e-416b-862e-3de681e2a73a",
  },
  {
    name: "Bordj Badji Mokhtar Airport",
    city: "Bordj Badji Mokhtar",
    country: "Algeria",
    iata_code: "BMW",
    _geoloc: {
      lat: 21.375,
      lng: 0.923889,
    },
    links_count: 2,
    objectID: "5554",
    uniqueID: "dbc91b03-ba40-4aac-94d9-bb906ffa5702",
  },
  {
    name: "Béchar Boudghene Ben Ali Lotfi Airport",
    city: "Béchar",
    country: "Algeria",
    iata_code: "CBH",
    _geoloc: {
      lat: 31.6457,
      lng: -2.26986,
    },
    links_count: 2,
    objectID: "5553",
    uniqueID: "4fb80082-855e-4a0e-bb9e-eb50f59b962b",
  },
  {
    name: "Masset Airport",
    city: "Masset",
    country: "Canada",
    iata_code: "ZMT",
    _geoloc: {
      lat: 54.0275,
      lng: -132.125,
    },
    links_count: 2,
    objectID: "5545",
    uniqueID: "2fd5ed88-40d4-48a9-98fd-d9ac3a7b96cc",
  },
  {
    name: "Rae Lakes Airport",
    city: "Gamètì",
    country: "Canada",
    iata_code: "YRA",
    _geoloc: {
      lat: 64.1161,
      lng: -117.31,
    },
    links_count: 2,
    objectID: "5526",
    uniqueID: "6860a830-4fb1-4baf-b6d0-5676edd7e462",
  },
  {
    name: "Powell River Airport",
    city: "Powell River",
    country: "Canada",
    iata_code: "YPW",
    _geoloc: {
      lat: 49.8342,
      lng: -124.5,
    },
    links_count: 2,
    objectID: "5523",
    uniqueID: "792054f2-2fb0-45b5-89f7-781a159d4ec7",
  },
  {
    name: "Peawanuck Airport",
    city: "Peawanuck",
    country: "Canada",
    iata_code: "YPO",
    _geoloc: {
      lat: 54.9881,
      lng: -85.4433,
    },
    links_count: 2,
    objectID: "5522",
    uniqueID: "cbcdf446-a73f-4d89-85d2-aa7f9d1ad313",
  },
  {
    name: "Kangiqsualujjuaq (Georges River) Airport",
    city: "Kangiqsualujjuaq",
    country: "Canada",
    iata_code: "XGR",
    _geoloc: {
      lat: 58.7114,
      lng: -65.9928,
    },
    links_count: 2,
    objectID: "5512",
    uniqueID: "c08235cd-4187-4230-bf46-210fc5231b86",
  },
  {
    name: "Lutselk'e Airport",
    city: "Lutselk'e",
    country: "Canada",
    iata_code: "YSG",
    _geoloc: {
      lat: 62.4183,
      lng: -110.682,
    },
    links_count: 2,
    objectID: "5511",
    uniqueID: "fada14a9-ab2e-4965-9847-cc24f516119a",
  },
  {
    name: "Kimmirut Airport",
    city: "Kimmirut",
    country: "Canada",
    iata_code: "YLC",
    _geoloc: {
      lat: 62.85,
      lng: -69.8833,
    },
    links_count: 2,
    objectID: "5509",
    uniqueID: "6fa699cb-84e8-4012-b4ed-1c1d4ec29c02",
  },
  {
    name: "Grise Fiord Airport",
    city: "Grise Fiord",
    country: "Canada",
    iata_code: "YGZ",
    _geoloc: {
      lat: 76.4261,
      lng: -82.9092,
    },
    links_count: 2,
    objectID: "5498",
    uniqueID: "28669cd3-bb6f-4967-a3aa-e39e8ab3a2f1",
  },
  {
    name: "Tarapacá Airport",
    city: "Tarapacá",
    country: "Colombia",
    iata_code: "TCD",
    _geoloc: {
      lat: -2.867,
      lng: -69.733,
    },
    links_count: 2,
    objectID: "5477",
    uniqueID: "d9c4223f-008e-4e04-9f74-e0315b69fb42",
  },
  {
    name: "Ogoki Post Airport",
    city: "Ogoki Post",
    country: "Canada",
    iata_code: "YOG",
    _geoloc: {
      lat: 51.6586,
      lng: -85.9017,
    },
    links_count: 2,
    objectID: "5470",
    uniqueID: "b4777db8-396c-4a0a-b6d6-e22894c0fd95",
  },
  {
    name: "Summer Beaver Airport",
    city: "Summer Beaver",
    country: "Canada",
    iata_code: "SUR",
    _geoloc: {
      lat: 52.7086,
      lng: -88.5419,
    },
    links_count: 2,
    objectID: "5464",
    uniqueID: "77270dff-949e-4003-bf6e-56ecb36a5459",
  },
  {
    name: "Whatì Airport",
    city: "Whatì",
    country: "Canada",
    iata_code: "YLE",
    _geoloc: {
      lat: 63.1317,
      lng: -117.246,
    },
    links_count: 2,
    objectID: "5463",
    uniqueID: "31e9aeb5-3763-4685-9e2f-23728005a7c2",
  },
  {
    name: "Anahim Lake Airport",
    city: "Anahim Lake",
    country: "Canada",
    iata_code: "YAA",
    _geoloc: {
      lat: 52.4525,
      lng: -125.303,
    },
    links_count: 2,
    objectID: "5457",
    uniqueID: "24e3cd53-f2ad-4499-aefb-a9b872ba052a",
  },
  {
    name: "Wapenamanda Airport",
    city: "Wapenamanda",
    country: "Papua New Guinea",
    iata_code: "WBM",
    _geoloc: {
      lat: -5.6433,
      lng: 143.895,
    },
    links_count: 2,
    objectID: "5437",
    uniqueID: "eed0522f-4d77-4763-b121-ce960b1c1b3c",
  },
  {
    name: "Mendi Airport",
    city: "Mendi",
    country: "Papua New Guinea",
    iata_code: "MDU",
    _geoloc: {
      lat: -6.14774,
      lng: 143.657,
    },
    links_count: 2,
    objectID: "5429",
    uniqueID: "b9dab4a5-2fd1-47ab-a7f5-0e7e0d33e65e",
  },
  {
    name: "Chimbu Airport",
    city: "Kundiawa",
    country: "Papua New Guinea",
    iata_code: "CMU",
    _geoloc: {
      lat: -6.02429,
      lng: 144.971,
    },
    links_count: 2,
    objectID: "5420",
    uniqueID: "2c090391-1c6a-4dac-bec1-773a26edb094",
  },
  {
    name: "Tiree",
    city: "Tiree",
    country: "United Kingdom",
    iata_code: "TRE",
    _geoloc: {
      lat: 56.499167,
      lng: -6.869167,
    },
    links_count: 2,
    objectID: "542",
    uniqueID: "57970852-f93e-4dba-8711-2036535a6ce3",
  },
  {
    name: "Marau Airport",
    city: "Marau",
    country: "Solomon Islands",
    iata_code: "RUS",
    _geoloc: {
      lat: -9.86167,
      lng: 160.825,
    },
    links_count: 2,
    objectID: "5415",
    uniqueID: "812a208c-13ad-45da-8d48-d955dd5f1841",
  },
  {
    name: "Santa Cruz/Graciosa Bay/Luova Airport",
    city: "Santa Cruz/Graciosa Bay/Luova",
    country: "Solomon Islands",
    iata_code: "SCZ",
    _geoloc: {
      lat: -10.7203,
      lng: 165.795,
    },
    links_count: 2,
    objectID: "5410",
    uniqueID: "feb172ac-026c-4b01-98cb-b7545087071a",
  },
  {
    name: "Uru Harbour Airport",
    city: "Atoifi",
    country: "Solomon Islands",
    iata_code: "ATD",
    _geoloc: {
      lat: -8.87333,
      lng: 161.011,
    },
    links_count: 2,
    objectID: "5404",
    uniqueID: "5d7ba93d-4a15-44a7-8926-dd9c792dc4fd",
  },
  {
    name: "Dundee",
    city: "Dundee",
    country: "United Kingdom",
    iata_code: "DND",
    _geoloc: {
      lat: 56.452499,
      lng: -3.025833,
    },
    links_count: 2,
    objectID: "540",
    uniqueID: "12135760-a1f3-416d-906d-43a197fa7db9",
  },
  {
    name: "Islay",
    city: "Islay",
    country: "United Kingdom",
    iata_code: "ILY",
    _geoloc: {
      lat: 55.681944,
      lng: -6.256667,
    },
    links_count: 2,
    objectID: "536",
    uniqueID: "9d8a4a10-89b6-49d2-be3f-677789a4ce89",
  },
  {
    name: "Gloucestershire",
    city: "Golouchestershire",
    country: "United Kingdom",
    iata_code: "GLO",
    _geoloc: {
      lat: 51.894167,
      lng: -2.167222,
    },
    links_count: 2,
    objectID: "472",
    uniqueID: "0bbdae52-029e-42e9-9a51-fe27d8580de1",
  },
  {
    name: "Kuopio",
    city: "Kuopio",
    country: "Finland",
    iata_code: "KUO",
    _geoloc: {
      lat: 63.00715,
      lng: 27.797756,
    },
    links_count: 2,
    objectID: "439",
    uniqueID: "647ddd7b-61d1-4b19-97cb-9425580abd15",
  },
  {
    name: "Kogalym International",
    city: "Kogalym",
    country: "Russia",
    iata_code: "KGP",
    _geoloc: {
      lat: 62.18,
      lng: 74.53,
    },
    links_count: 2,
    objectID: "4373",
    uniqueID: "73d70d6f-10de-45a6-a118-b42020db877a",
  },
  {
    name: "Kuusamo",
    city: "Kuusamo",
    country: "Finland",
    iata_code: "KAO",
    _geoloc: {
      lat: 65.987575,
      lng: 29.239381,
    },
    links_count: 2,
    objectID: "437",
    uniqueID: "22bdb399-4f09-400e-8c84-274fd86625c3",
  },
  {
    name: "Framnes",
    city: "Narvik",
    country: "Norway",
    iata_code: "NVK",
    _geoloc: {
      lat: 68.435833,
      lng: 17.388056,
    },
    links_count: 2,
    objectID: "4349",
    uniqueID: "9578bde9-2cdc-4847-a3d9-ea90982213c1",
  },
  {
    name: "Yampa Valley",
    city: "Hayden",
    country: "United States",
    iata_code: "HDN",
    _geoloc: {
      lat: 40.481181,
      lng: -107.21766,
    },
    links_count: 2,
    objectID: "4339",
    uniqueID: "c44df42e-ed44-4f12-9d91-6f8188a712d4",
  },
  {
    name: "Cortez Muni",
    city: "Cortez",
    country: "United States",
    iata_code: "CEZ",
    _geoloc: {
      lat: 37.303,
      lng: -108.628056,
    },
    links_count: 2,
    objectID: "4338",
    uniqueID: "41d816c6-963f-4dd9-b961-95b9523dbba0",
  },
  {
    name: "Key Field",
    city: "Meridian",
    country: "United States",
    iata_code: "MEI",
    _geoloc: {
      lat: 32.332624,
      lng: -88.751868,
    },
    links_count: 2,
    objectID: "4335",
    uniqueID: "38d266eb-8ff3-4fe2-bca5-5150a7fc390e",
  },
  {
    name: "Ogle",
    city: "Georgetown",
    country: "Guyana",
    iata_code: "OGL",
    _geoloc: {
      lat: 6.806944,
      lng: -58.104444,
    },
    links_count: 2,
    objectID: "4306",
    uniqueID: "2c8a755f-1750-4130-8110-2b8a368eaf26",
  },
  {
    name: "Chumphon",
    city: "Chumphon",
    country: "Thailand",
    iata_code: "CJM",
    _geoloc: {
      lat: 10.7112,
      lng: 99.361706,
    },
    links_count: 2,
    objectID: "4300",
    uniqueID: "395a7b09-bc46-4910-8daa-99daff18a0a8",
  },
  {
    name: "Joensuu",
    city: "Joensuu",
    country: "Finland",
    iata_code: "JOE",
    _geoloc: {
      lat: 62.662906,
      lng: 29.60755,
    },
    links_count: 2,
    objectID: "429",
    uniqueID: "3b633e94-6672-4063-a33b-9546d8a0538f",
  },
  {
    name: "Bemidji Regional Airport",
    city: "Bemidji",
    country: "United States",
    iata_code: "BJI",
    _geoloc: {
      lat: 47.510722,
      lng: -94.934722,
    },
    links_count: 2,
    objectID: "4287",
    uniqueID: "c9018a52-9982-4ef3-a121-fb3945001dba",
  },
  {
    name: "Monaco",
    city: "Monaco",
    country: "Monaco",
    iata_code: "MCM",
    _geoloc: {
      lat: 43.73333333,
      lng: 7.41666666,
    },
    links_count: 2,
    objectID: "4264",
    uniqueID: "8b38db45-f690-4f59-b3e1-98f7e2546598",
  },
  {
    name: "Magic Valley Regional Airport",
    city: "Twin Falls",
    country: "United States",
    iata_code: "TWF",
    _geoloc: {
      lat: 42.481803,
      lng: -114.487733,
    },
    links_count: 2,
    objectID: "4263",
    uniqueID: "60ba8728-2d69-4778-9ba0-20a33b0de59d",
  },
  {
    name: "Eagle Co Rgnl",
    city: "Vail",
    country: "United States",
    iata_code: "EGE",
    _geoloc: {
      lat: 39.642556,
      lng: -106.917694,
    },
    links_count: 2,
    objectID: "4250",
    uniqueID: "e6ddc7e1-e6e1-43de-aa0c-1cfe946cf26d",
  },
  {
    name: "Paulatuk",
    city: "Paulatuk",
    country: "Canada",
    iata_code: "YPC",
    _geoloc: {
      lat: 62.35,
      lng: -124.3333,
    },
    links_count: 2,
    objectID: "4244",
    uniqueID: "5738b004-1843-473c-9255-569795ddb0d4",
  },
  {
    name: "Grand Canyon National Park Airport",
    city: "Grand Canyon",
    country: "United States",
    iata_code: "GCN",
    _geoloc: {
      lat: 35.9523611,
      lng: -112.1469722,
    },
    links_count: 2,
    objectID: "4219",
    uniqueID: "8d9e2c29-3699-4645-a36e-f57728b6f4c0",
  },
  {
    name: "Dhangarhi",
    city: "Dhangarhi",
    country: "Nepal",
    iata_code: "DHI",
    _geoloc: {
      lat: 28.753333,
      lng: 80.581944,
    },
    links_count: 2,
    objectID: "4188",
    uniqueID: "81ff8157-d5f6-41c7-9dfd-5673374d106e",
  },
  {
    name: "Tumling Tar",
    city: "Tumling Tar",
    country: "Nepal",
    iata_code: "TMI",
    _geoloc: {
      lat: 27.315,
      lng: 87.193333,
    },
    links_count: 2,
    objectID: "4183",
    uniqueID: "51b38a13-55d1-4db7-897b-d510a7ae4f4d",
  },
  {
    name: "Brac",
    city: "Brac",
    country: "Croatia",
    iata_code: "BWK",
    _geoloc: {
      lat: 43.285719,
      lng: 16.679719,
    },
    links_count: 2,
    objectID: "4160",
    uniqueID: "00be7bd5-274d-4763-8f99-38af69d0e6ac",
  },
  {
    name: "Pai",
    city: "Pai",
    country: "Thailand",
    iata_code: "PYY",
    _geoloc: {
      lat: 19.372,
      lng: 98.437,
    },
    links_count: 2,
    objectID: "4159",
    uniqueID: "9fbeb33c-547a-4172-b906-48641d329ec0",
  },
  {
    name: "Ca Mau",
    city: "Ca Mau",
    country: "Vietnam",
    iata_code: "CAH",
    _geoloc: {
      lat: 9.188049,
      lng: 105.174721,
    },
    links_count: 2,
    objectID: "4156",
    uniqueID: "bd7e3fa5-ab37-4e69-9b22-8250c50ed792",
  },
  {
    name: "Rach Gia",
    city: "Rach Gia",
    country: "Vietnam",
    iata_code: "VKG",
    _geoloc: {
      lat: 9.949676,
      lng: 105.133659,
    },
    links_count: 2,
    objectID: "4155",
    uniqueID: "f490c528-1d59-4cd7-a0c5-f38803d2a852",
  },
  {
    name: "Oudomxay",
    city: "Muang Xay",
    country: "Laos",
    iata_code: "ODY",
    _geoloc: {
      lat: 20.6827,
      lng: 101.994,
    },
    links_count: 2,
    objectID: "4143",
    uniqueID: "6508e7fc-ef5c-4003-a3bb-e04e543f3b2a",
  },
  {
    name: "Luang Namtha",
    city: "Luang Namtha",
    country: "Laos",
    iata_code: "LXG",
    _geoloc: {
      lat: 20.960556,
      lng: 101.4025,
    },
    links_count: 2,
    objectID: "4142",
    uniqueID: "19e45919-0f21-4f05-b404-7b1bd566d7d8",
  },
  {
    name: "Trat",
    city: "Trat",
    country: "Thailand",
    iata_code: "TDX",
    _geoloc: {
      lat: 12.274572,
      lng: 102.318958,
    },
    links_count: 2,
    objectID: "4135",
    uniqueID: "04ec9609-fd22-43a3-8ea0-8f4e98920528",
  },
  {
    name: "Ranong",
    city: "Ranong",
    country: "Thailand",
    iata_code: "UNN",
    _geoloc: {
      lat: 9.777622,
      lng: 98.585483,
    },
    links_count: 2,
    objectID: "4134",
    uniqueID: "5d4ba43d-f4f2-485a-a9ef-c287f3d7ca2c",
  },
  {
    name: "Buri Ram",
    city: "Buri Ram",
    country: "Thailand",
    iata_code: "BFV",
    _geoloc: {
      lat: 15.229539,
      lng: 103.253231,
    },
    links_count: 2,
    objectID: "4133",
    uniqueID: "4de13164-1a40-4d6f-b161-5ffbd21757f2",
  },
  {
    name: "Roi Et",
    city: "Roi Et",
    country: "Thailand",
    iata_code: "ROI",
    _geoloc: {
      lat: 16.116761,
      lng: 103.773797,
    },
    links_count: 2,
    objectID: "4132",
    uniqueID: "b20fe5a8-fb69-4e89-834e-44e963a3a7e0",
  },
  {
    name: "Kuressaare",
    city: "Kuressaare",
    country: "Estonia",
    iata_code: "URE",
    _geoloc: {
      lat: 58.229883,
      lng: 22.509494,
    },
    links_count: 2,
    objectID: "413",
    uniqueID: "7bd6df33-c3e9-43dc-bcf5-4cfb2ae30b54",
  },
  {
    name: "Santa Elena Airport",
    city: "Santa Elena de Uairen",
    country: "Venezuela",
    iata_code: "SNV",
    _geoloc: {
      lat: 4.554722,
      lng: -61.144922,
    },
    links_count: 2,
    objectID: "4126",
    uniqueID: "493d79b1-703d-4f67-b4be-a47ceaa96184",
  },
  {
    name: "Barkley Regional Airport",
    city: "PADUCAH",
    country: "United States",
    iata_code: "PAH",
    _geoloc: {
      lat: 37.0602875,
      lng: -88.7729583,
    },
    links_count: 2,
    objectID: "4117",
    uniqueID: "8a52663f-89b8-47cc-a2f6-45b2dd794cd6",
  },
  {
    name: "Mid-Ohio Valley Regional Airport",
    city: "PARKERSBURG",
    country: "United States",
    iata_code: "PKB",
    _geoloc: {
      lat: 39.3451039,
      lng: -81.4392031,
    },
    links_count: 2,
    objectID: "4114",
    uniqueID: "052a8297-1ce2-4e14-b310-500ed01fe410",
  },
  {
    name: "Vancouver Coal Harbour",
    city: "Vancouver",
    country: "Canada",
    iata_code: "CXH",
    _geoloc: {
      lat: 49.289722,
      lng: -123.115833,
    },
    links_count: 2,
    objectID: "4107",
    uniqueID: "97dc1ef3-a5b8-4d75-b809-cb106046b61e",
  },
  {
    name: "Redding Muni",
    city: "Redding",
    country: "United States",
    iata_code: "RDD",
    _geoloc: {
      lat: 40.509,
      lng: -122.293389,
    },
    links_count: 2,
    objectID: "4098",
    uniqueID: "72cabb0a-0b58-4d32-8267-8d3374054dcf",
  },
  {
    name: "Sihanoukville",
    city: "Sihanoukville",
    country: "Cambodia",
    iata_code: "KOS",
    _geoloc: {
      lat: 10.579686,
      lng: 103.636828,
    },
    links_count: 2,
    objectID: "4093",
    uniqueID: "367e806c-bb7b-469e-b8b6-e241971bb295",
  },
  {
    name: "McClellan-Palomar Airport",
    city: "Carlsbad",
    country: "United States",
    iata_code: "CLD",
    _geoloc: {
      lat: 33.0742,
      lng: -117.1648,
    },
    links_count: 2,
    objectID: "4088",
    uniqueID: "7f9d79e6-aad4-48b4-a3c6-e477c15015c5",
  },
  {
    name: "Mae Hong Son",
    city: "Mae Hong Son",
    country: "Thailand",
    iata_code: "HGN",
    _geoloc: {
      lat: 19.301667,
      lng: 97.975,
    },
    links_count: 2,
    objectID: "4086",
    uniqueID: "02d31869-58af-498e-8fe1-118398f7ca54",
  },
  {
    name: "Xieng Khouang",
    city: "Phon Savan",
    country: "Laos",
    iata_code: "XKH",
    _geoloc: {
      lat: 19.449997,
      lng: 103.158333,
    },
    links_count: 2,
    objectID: "4081",
    uniqueID: "9827fac0-2a3a-4beb-8bab-a9da897dc5e1",
  },
  {
    name: "Funafuti International",
    city: "Funafuti",
    country: "Tuvalu",
    iata_code: "FUN",
    _geoloc: {
      lat: -8.525,
      lng: 179.196389,
    },
    links_count: 2,
    objectID: "4077",
    uniqueID: "9f97647f-c8fa-409f-aefc-33e7e264841a",
  },
  {
    name: "Kingscote Airport",
    city: "Kingscote",
    country: "Australia",
    iata_code: "KGC",
    _geoloc: {
      lat: -35.713889,
      lng: 137.521389,
    },
    links_count: 2,
    objectID: "4051",
    uniqueID: "3ec18d53-b333-43ca-b99b-b26e70114ed2",
  },
  {
    name: "Knox County Regional Airport",
    city: "Rockland",
    country: "United States",
    iata_code: "RKD",
    _geoloc: {
      lat: 44.0601111,
      lng: -69.0992303,
    },
    links_count: 2,
    objectID: "4026",
    uniqueID: "27e834cc-c9d8-4da8-93c4-02f7c0c251f7",
  },
  {
    name: "Hancock County - Bar Harbor",
    city: "Bar Harbor",
    country: "United States",
    iata_code: "BHB",
    _geoloc: {
      lat: 44.4497689,
      lng: -68.3615653,
    },
    links_count: 2,
    objectID: "4025",
    uniqueID: "2194fd7b-e9db-4915-a824-f3a32b90c9f1",
  },
  {
    name: "Bert Mooney Airport",
    city: "Butte",
    country: "United States",
    iata_code: "BTM",
    _geoloc: {
      lat: 45.954806,
      lng: -112.497472,
    },
    links_count: 2,
    objectID: "4022",
    uniqueID: "78e3ac16-8bc1-445b-b54b-06e0b75fd791",
  },
  {
    name: "Naples Muni",
    city: "Naples",
    country: "United States",
    iata_code: "APF",
    _geoloc: {
      lat: 26.152619,
      lng: -81.775294,
    },
    links_count: 2,
    objectID: "4012",
    uniqueID: "458a4697-5188-4a17-a211-98eb43197ebc",
  },
  {
    name: "Ataq",
    city: "Ataq",
    country: "Yemen",
    iata_code: "AXK",
    _geoloc: {
      lat: 14.551322,
      lng: 46.826183,
    },
    links_count: 2,
    objectID: "3978",
    uniqueID: "13d35b7d-2d42-4f95-aff4-3db6d966b3d9",
  },
  {
    name: "Gyumri",
    city: "Gyumri",
    country: "Armenia",
    iata_code: "LWN",
    _geoloc: {
      lat: 40.750369,
      lng: 43.859342,
    },
    links_count: 2,
    objectID: "3965",
    uniqueID: "9295a5e7-b856-43b5-9850-3f53c5855f33",
  },
  {
    name: "Sukhothai",
    city: "Sukhothai",
    country: "Thailand",
    iata_code: "THS",
    _geoloc: {
      lat: 17.237992,
      lng: 99.818183,
    },
    links_count: 2,
    objectID: "3939",
    uniqueID: "67a1d6d2-873f-4474-bf96-b4e82d016134",
  },
  {
    name: "Mau Hau",
    city: "Waingapu",
    country: "Indonesia",
    iata_code: "WGP",
    _geoloc: {
      lat: -9.669217,
      lng: 120.302006,
    },
    links_count: 2,
    objectID: "3927",
    uniqueID: "b4c6d468-f81e-4cff-970c-afa445fbb975",
  },
  {
    name: "Naha",
    city: "Naha",
    country: "Indonesia",
    iata_code: "NAH",
    _geoloc: {
      lat: 3.683214,
      lng: 125.528019,
    },
    links_count: 2,
    objectID: "3886",
    uniqueID: "14e22f70-2100-4f03-b687-8be93b216ef9",
  },
  {
    name: "Forbes Fld",
    city: "Topeka",
    country: "United States",
    iata_code: "FOE",
    _geoloc: {
      lat: 38.950944,
      lng: -95.663611,
    },
    links_count: 2,
    objectID: "3843",
    uniqueID: "bc1e4117-8dfa-4942-8f84-2f2e03348fb3",
  },
  {
    name: "Cedar City Rgnl",
    city: "Cedar City",
    country: "United States",
    iata_code: "CDC",
    _geoloc: {
      lat: 37.700967,
      lng: -113.098847,
    },
    links_count: 2,
    objectID: "3824",
    uniqueID: "7e5d324d-9667-404c-8a65-4e33762ab560",
  },
  {
    name: "Modesto City Co Harry Sham",
    city: "Modesto",
    country: "United States",
    iata_code: "MOD",
    _geoloc: {
      lat: 37.625817,
      lng: -120.954422,
    },
    links_count: 2,
    objectID: "3816",
    uniqueID: "3767080e-ea4e-4dad-99e7-93a01561bed1",
  },
  {
    name: "Stockton Metropolitan",
    city: "Stockton",
    country: "United States",
    iata_code: "SCK",
    _geoloc: {
      lat: 37.894167,
      lng: -121.238306,
    },
    links_count: 2,
    objectID: "3805",
    uniqueID: "6ca6e1ff-43a2-40aa-9b38-6eb689c6a8ab",
  },
  {
    name: "Scott Afb Midamerica",
    city: "Belleville",
    country: "United States",
    iata_code: "BLV",
    _geoloc: {
      lat: 38.545178,
      lng: -89.835183,
    },
    links_count: 2,
    objectID: "3790",
    uniqueID: "989e9f9f-5d39-41e9-addc-260aa28b7422",
  },
  {
    name: "Lea Co Rgnl",
    city: "Hobbs",
    country: "United States",
    iata_code: "HOB",
    _geoloc: {
      lat: 32.687528,
      lng: -103.217028,
    },
    links_count: 2,
    objectID: "3749",
    uniqueID: "837e3c49-1245-472f-b8a5-4e36462fe07f",
  },
  {
    name: "Augsburg",
    city: "Augsburg",
    country: "Germany",
    iata_code: "AGB",
    _geoloc: {
      lat: 48.425158,
      lng: 10.931764,
    },
    links_count: 2,
    objectID: "374",
    uniqueID: "66ffbcbe-9d9a-4a83-8b9a-55a2bf7ad437",
  },
  {
    name: "Ogdensburg Intl",
    city: "Ogdensburg",
    country: "United States",
    iata_code: "OGS",
    _geoloc: {
      lat: 44.681854,
      lng: -75.4655,
    },
    links_count: 2,
    objectID: "3724",
    uniqueID: "fd6769bd-6148-4cb0-8bbd-2780a645d691",
  },
  {
    name: "Chico Muni",
    city: "Chico",
    country: "United States",
    iata_code: "CIC",
    _geoloc: {
      lat: 39.795383,
      lng: -121.858422,
    },
    links_count: 2,
    objectID: "3710",
    uniqueID: "7f259f88-0dd4-4e41-a117-e28c732b3133",
  },
  {
    name: "Augusta State",
    city: "Augusta",
    country: "United States",
    iata_code: "AUG",
    _geoloc: {
      lat: 44.320647,
      lng: -69.797317,
    },
    links_count: 2,
    objectID: "3701",
    uniqueID: "1de981be-6617-4e13-ad42-1a55ec76c7ec",
  },
  {
    name: "Waynesville Rgnl Arpt At Forney Fld",
    city: "Fort Leonardwood",
    country: "United States",
    iata_code: "TBN",
    _geoloc: {
      lat: 37.741631,
      lng: -92.140736,
    },
    links_count: 2,
    objectID: "3665",
    uniqueID: "e259354a-2583-40fd-8e06-703b032db455",
  },
  {
    name: "Chisholm Hibbing",
    city: "Hibbing",
    country: "United States",
    iata_code: "HIB",
    _geoloc: {
      lat: 47.3866,
      lng: -92.838994,
    },
    links_count: 2,
    objectID: "3650",
    uniqueID: "7ae29766-460d-45d8-a089-0940610eef3e",
  },
  {
    name: "Teterboro",
    city: "Teterboro",
    country: "United States",
    iata_code: "TEB",
    _geoloc: {
      lat: 40.850103,
      lng: -74.060837,
    },
    links_count: 2,
    objectID: "3624",
    uniqueID: "7f8309b7-fe21-4a18-93ca-187ae7170bcb",
  },
  {
    name: "Hamburg Finkenwerder",
    city: "Hamburg",
    country: "Germany",
    iata_code: "XFW",
    _geoloc: {
      lat: 53.535886,
      lng: 9.837025,
    },
    links_count: 2,
    objectID: "362",
    uniqueID: "2bdcdf64-7828-43d2-86f1-3e6c6185ade9",
  },
  {
    name: "Scappoose Industrial Airpark",
    city: "San Luis",
    country: "United States",
    iata_code: "SPB",
    _geoloc: {
      lat: 45.771028,
      lng: -122.861833,
    },
    links_count: 2,
    objectID: "3616",
    uniqueID: "a9757d34-516a-426d-8254-4a7c61edebd8",
  },
  {
    name: "Havre City Co",
    city: "Havre",
    country: "United States",
    iata_code: "HVR",
    _geoloc: {
      lat: 48.542983,
      lng: -109.762342,
    },
    links_count: 2,
    objectID: "3604",
    uniqueID: "0b418d03-09c8-4316-9c8d-2d39e9b9014c",
  },
  {
    name: "Cavern City Air Terminal",
    city: "Carlsbad",
    country: "United States",
    iata_code: "CNM",
    _geoloc: {
      lat: 32.337472,
      lng: -104.263278,
    },
    links_count: 2,
    objectID: "3597",
    uniqueID: "49e9da29-9fb8-42e2-9787-d20ca0105156",
  },
  {
    name: "Jonesboro Muni",
    city: "Jonesboro",
    country: "United States",
    iata_code: "JBR",
    _geoloc: {
      lat: 35.831708,
      lng: -90.646417,
    },
    links_count: 2,
    objectID: "3591",
    uniqueID: "ca17847d-e159-42d4-b510-077af3dfb77c",
  },
  {
    name: "Mannheim City",
    city: "Mannheim",
    country: "Germany",
    iata_code: "MHG",
    _geoloc: {
      lat: 49.472706,
      lng: 8.514264,
    },
    links_count: 2,
    objectID: "356",
    uniqueID: "5e540c4c-a600-4e34-8161-c6b0aab59a07",
  },
  {
    name: "Massena Intl Richards Fld",
    city: "Massena",
    country: "United States",
    iata_code: "MSS",
    _geoloc: {
      lat: 44.935833,
      lng: -74.845547,
    },
    links_count: 2,
    objectID: "3546",
    uniqueID: "e31268f1-975d-48dc-a3d7-7b79d0a23830",
  },
  {
    name: "Waimea Kohala",
    city: "Kamuela",
    country: "United States",
    iata_code: "MUE",
    _geoloc: {
      lat: 20.001328,
      lng: -155.668108,
    },
    links_count: 2,
    objectID: "3545",
    uniqueID: "9bcb6d07-47ea-41d8-9359-553d5dcba27a",
  },
  {
    name: "Pueblo Memorial",
    city: "Pueblo",
    country: "United States",
    iata_code: "PUB",
    _geoloc: {
      lat: 38.289085,
      lng: -104.496572,
    },
    links_count: 2,
    objectID: "3527",
    uniqueID: "ec43c70c-5fce-40da-9e50-886db0da5f7e",
  },
  {
    name: "Ernest A Love Fld",
    city: "Prescott",
    country: "United States",
    iata_code: "PRC",
    _geoloc: {
      lat: 34.654472,
      lng: -112.419583,
    },
    links_count: 2,
    objectID: "3446",
    uniqueID: "4f0e35b7-6e40-4ad7-a22e-9f3efd817d6a",
  },
  {
    name: "Hana",
    city: "Hana",
    country: "United States",
    iata_code: "HNM",
    _geoloc: {
      lat: 20.795636,
      lng: -156.014439,
    },
    links_count: 2,
    objectID: "3445",
    uniqueID: "8a206b4d-c8a1-4d81-abc7-85cf57dc3216",
  },
  {
    name: "Platinum",
    city: "Port Moller",
    country: "United States",
    iata_code: "PTU",
    _geoloc: {
      lat: 59.011356,
      lng: -161.819664,
    },
    links_count: 2,
    objectID: "3430",
    uniqueID: "e65d785f-8f4c-4027-b3ea-247b31ee8d08",
  },
  {
    name: "Iliamna",
    city: "Iliamna",
    country: "United States",
    iata_code: "ILI",
    _geoloc: {
      lat: 59.754356,
      lng: -154.910961,
    },
    links_count: 2,
    objectID: "3429",
    uniqueID: "a8fd45a9-bb25-4b63-af45-3e12487b5dc1",
  },
  {
    name: "Tatalina Lrrs",
    city: "Tatalina",
    country: "United States",
    iata_code: "TLJ",
    _geoloc: {
      lat: 62.894369,
      lng: -155.976525,
    },
    links_count: 2,
    objectID: "3423",
    uniqueID: "e6e792a4-79d2-4a03-ae21-7d809a4ecb11",
  },
  {
    name: "Cape Lisburne Lrrs",
    city: "Cape Lisburne",
    country: "United States",
    iata_code: "LUR",
    _geoloc: {
      lat: 68.875133,
      lng: -166.110022,
    },
    links_count: 2,
    objectID: "3413",
    uniqueID: "89959da3-db78-4cc1-844a-e297cb1a45d2",
  },
  {
    name: "Noto",
    city: "Wajima",
    country: "Japan",
    iata_code: "NTQ",
    _geoloc: {
      lat: 37.293097,
      lng: 136.961853,
    },
    links_count: 2,
    objectID: "3409",
    uniqueID: "415d960d-c7d3-42ab-b2ad-658d8dee2bca",
  },
  {
    name: "Tamworth",
    city: "Tamworth",
    country: "Australia",
    iata_code: "TMW",
    _geoloc: {
      lat: -31.083889,
      lng: 150.846667,
    },
    links_count: 2,
    objectID: "3362",
    uniqueID: "8511c22d-1aee-4de9-aced-5d4c9fe67997",
  },
  {
    name: "Richmond",
    city: "Richmond",
    country: "Australia",
    iata_code: "RCM",
    _geoloc: {
      lat: -33.600556,
      lng: 150.780833,
    },
    links_count: 2,
    objectID: "3360",
    uniqueID: "2911a385-32cd-446f-a021-118ccca90f37",
  },
  {
    name: "Melbourne Essendon",
    city: "Melbourne",
    country: "Australia",
    iata_code: "MEB",
    _geoloc: {
      lat: -37.728056,
      lng: 144.901944,
    },
    links_count: 2,
    objectID: "3334",
    uniqueID: "f1b3f139-5932-40ec-8c74-8b12205fabee",
  },
  {
    name: "Avalon",
    city: "Avalon",
    country: "Australia",
    iata_code: "AVV",
    _geoloc: {
      lat: -38.039444,
      lng: 144.469444,
    },
    links_count: 2,
    objectID: "3332",
    uniqueID: "bd5d5a47-c12c-401a-9581-737df957af26",
  },
  {
    name: "Bamaga Injinoo",
    city: "Amberley",
    country: "Australia",
    iata_code: "ABM",
    _geoloc: {
      lat: -10.950833,
      lng: 142.459444,
    },
    links_count: 2,
    objectID: "3318",
    uniqueID: "2f8fcf20-49e4-4b1e-bf54-502ac5c58dff",
  },
  {
    name: "Lahad Datu",
    city: "Lahad Datu",
    country: "Malaysia",
    iata_code: "LDU",
    _geoloc: {
      lat: 5.032247,
      lng: 118.324036,
    },
    links_count: 2,
    objectID: "3268",
    uniqueID: "14fcd870-1139-4af8-9dfd-123d6b75bd31",
  },
  {
    name: "Limbang",
    city: "Limbang",
    country: "Malaysia",
    iata_code: "LMN",
    _geoloc: {
      lat: 4.808303,
      lng: 115.010439,
    },
    links_count: 2,
    objectID: "3264",
    uniqueID: "a442dcd5-05fb-43d0-ad51-3af89d1dce78",
  },
  {
    name: "Babo",
    city: "Babo",
    country: "Indonesia",
    iata_code: "BXB",
    _geoloc: {
      lat: -2.532242,
      lng: 133.438894,
    },
    links_count: 2,
    objectID: "3259",
    uniqueID: "d10b8824-30f1-4b53-96cb-694e21af0413",
  },
  {
    name: "Kasiguncu",
    city: "Poso",
    country: "Indonesia",
    iata_code: "PSJ",
    _geoloc: {
      lat: -1.416753,
      lng: 120.657669,
    },
    links_count: 2,
    objectID: "3251",
    uniqueID: "e75f8c10-4fda-4167-8f28-7a55b44b03fa",
  },
  {
    name: "Wamena",
    city: "Wamena",
    country: "Indonesia",
    iata_code: "WMX",
    _geoloc: {
      lat: -4.102511,
      lng: 138.957372,
    },
    links_count: 2,
    objectID: "3245",
    uniqueID: "74b46906-eec9-43f1-97ea-0189e5ace1c5",
  },
  {
    name: "Putao",
    city: "Putao",
    country: "Burma",
    iata_code: "PBU",
    _geoloc: {
      lat: 27.329922,
      lng: 97.426269,
    },
    links_count: 2,
    objectID: "3232",
    uniqueID: "db902f3f-8f80-4614-9185-874a6386ab97",
  },
  {
    name: "Kyaukpyu",
    city: "Kyaukpyu",
    country: "Burma",
    iata_code: "KYP",
    _geoloc: {
      lat: 19.426447,
      lng: 93.534836,
    },
    links_count: 2,
    objectID: "3217",
    uniqueID: "1d8f687a-2b45-4c9b-a178-2115a39466fd",
  },
  {
    name: "Kengtung",
    city: "Kengtung",
    country: "Burma",
    iata_code: "KET",
    _geoloc: {
      lat: 21.301611,
      lng: 99.635997,
    },
    links_count: 2,
    objectID: "3215",
    uniqueID: "c9d7ae44-38fb-43d6-bb39-eda2d66cbdbd",
  },
  {
    name: "Loei",
    city: "Loei",
    country: "Thailand",
    iata_code: "LOE",
    _geoloc: {
      lat: 17.439133,
      lng: 101.722064,
    },
    links_count: 2,
    objectID: "3186",
    uniqueID: "31779c0b-2016-492f-a9e7-3b29f5864eae",
  },
  {
    name: "Sakon Nakhon",
    city: "Sakon Nakhon",
    country: "Thailand",
    iata_code: "SNO",
    _geoloc: {
      lat: 17.195142,
      lng: 104.118625,
    },
    links_count: 2,
    objectID: "3184",
    uniqueID: "e6f3199d-c9e9-4f6e-b0e8-0e835f0c1489",
  },
  {
    name: "Narathiwat",
    city: "Narathiwat",
    country: "Thailand",
    iata_code: "NAW",
    _geoloc: {
      lat: 6.519922,
      lng: 101.7434,
    },
    links_count: 2,
    objectID: "3173",
    uniqueID: "b950d2d1-f639-47af-853a-616c5184b263",
  },
  {
    name: "Phrae",
    city: "Phrae",
    country: "Thailand",
    iata_code: "PRH",
    _geoloc: {
      lat: 18.132169,
      lng: 100.164664,
    },
    links_count: 2,
    objectID: "3164",
    uniqueID: "55570318-8e60-4239-9490-2291ac6b7b2f",
  },
  {
    name: "Lampang",
    city: "Lampang",
    country: "Thailand",
    iata_code: "LPT",
    _geoloc: {
      lat: 18.270933,
      lng: 99.504167,
    },
    links_count: 2,
    objectID: "3163",
    uniqueID: "aaacf7e3-f1cd-4b4d-a62f-584cdf9ae02d",
  },
  {
    name: "Agatti",
    city: "Agatti Island",
    country: "India",
    iata_code: "AGX",
    _geoloc: {
      lat: 10.823656,
      lng: 72.176042,
    },
    links_count: 2,
    objectID: "3130",
    uniqueID: "e627e8c5-37ce-4d61-b167-41d64b3aaad2",
  },
  {
    name: "Biratnagar",
    city: "Biratnagar",
    country: "Nepal",
    iata_code: "BIR",
    _geoloc: {
      lat: 26.481453,
      lng: 87.264036,
    },
    links_count: 2,
    objectID: "3129",
    uniqueID: "f4c6f6a3-c96e-4040-b8ad-b51e17606071",
  },
  {
    name: "Pokhara",
    city: "Pokhara",
    country: "Nepal",
    iata_code: "PKR",
    _geoloc: {
      lat: 28.200881,
      lng: 83.982056,
    },
    links_count: 2,
    objectID: "3127",
    uniqueID: "2de70de0-e87b-4c2c-8565-1b0e8a3c5c2d",
  },
  {
    name: "Bhairahawa",
    city: "Bhairawa",
    country: "Nepal",
    iata_code: "BWA",
    _geoloc: {
      lat: 27.505703,
      lng: 83.41625,
    },
    links_count: 2,
    objectID: "3122",
    uniqueID: "35667929-0ccc-42d5-bcf2-8f8377091295",
  },
  {
    name: "Ludhiana",
    city: "Ludhiaha",
    country: "India",
    iata_code: "LUH",
    _geoloc: {
      lat: 30.854681,
      lng: 75.952592,
    },
    links_count: 2,
    objectID: "3103",
    uniqueID: "ceb8d012-ce87-45b8-a43c-ddc445c261c1",
  },
  {
    name: "Kanpur",
    city: "Kanpur",
    country: "India",
    iata_code: "KNU",
    _geoloc: {
      lat: 26.441444,
      lng: 80.364864,
    },
    links_count: 2,
    objectID: "3101",
    uniqueID: "eb7a22ab-c5b6-40f1-b9eb-ba722e933c1a",
  },
  {
    name: "Oostende",
    city: "Ostend",
    country: "Belgium",
    iata_code: "OST",
    _geoloc: {
      lat: 51.198889,
      lng: 2.862222,
    },
    links_count: 2,
    objectID: "310",
    uniqueID: "84042b07-bc7f-4f4f-88bb-8eadabede20c",
  },
  {
    name: "Brandon Muni",
    city: "Brandon",
    country: "Canada",
    iata_code: "YBR",
    _geoloc: {
      lat: 49.91,
      lng: -99.951944,
    },
    links_count: 2,
    objectID: "31",
    uniqueID: "4e455f36-5556-472f-a8fb-8fb62edd1ed4",
  },
  {
    name: "Gwalior",
    city: "Gwalior",
    country: "India",
    iata_code: "GWL",
    _geoloc: {
      lat: 26.293336,
      lng: 78.227753,
    },
    links_count: 2,
    objectID: "3094",
    uniqueID: "1dcf09f6-63bd-4296-9629-f268c9fe8c7d",
  },
  {
    name: "Kullu Manali",
    city: "Kulu",
    country: "India",
    iata_code: "KUU",
    _geoloc: {
      lat: 31.876706,
      lng: 77.154367,
    },
    links_count: 2,
    objectID: "3085",
    uniqueID: "e1784e28-086c-4c97-9b95-fa06eb0ad6c6",
  },
  {
    name: "Shah Mokhdum",
    city: "Rajshahi",
    country: "Bangladesh",
    iata_code: "RJH",
    _geoloc: {
      lat: 24.437219,
      lng: 88.616511,
    },
    links_count: 2,
    objectID: "3072",
    uniqueID: "d315d1e2-3292-4182-ab87-3183bad66537",
  },
  {
    name: "China Bay",
    city: "Trinciomalee",
    country: "Sri Lanka",
    iata_code: "TRR",
    _geoloc: {
      lat: 8.538514,
      lng: 81.181853,
    },
    links_count: 2,
    objectID: "3030",
    uniqueID: "5792d82d-0d7d-4f8b-9d3c-69ea69481ecc",
  },
  {
    name: "Khajuraho",
    city: "Khajuraho",
    country: "India",
    iata_code: "HJR",
    _geoloc: {
      lat: 24.817197,
      lng: 79.918597,
    },
    links_count: 2,
    objectID: "3012",
    uniqueID: "92fa90bf-e853-4b82-8d70-5408ce7ea406",
  },
  {
    name: "Jamnagar",
    city: "Jamnagar",
    country: "India",
    iata_code: "JGA",
    _geoloc: {
      lat: 22.465522,
      lng: 70.012556,
    },
    links_count: 2,
    objectID: "3010",
    uniqueID: "1269ecf6-0136-4f17-bb89-f807b6c39f6a",
  },
  {
    name: "Belgaum",
    city: "Belgaum",
    country: "India",
    iata_code: "IXG",
    _geoloc: {
      lat: 15.859286,
      lng: 74.618292,
    },
    links_count: 2,
    objectID: "3000",
    uniqueID: "89a73d20-4c71-4aee-9eee-bd4dcab23658",
  },
  {
    name: "Pevek",
    city: "Pevek",
    country: "Russia",
    iata_code: "PWE",
    _geoloc: {
      lat: 69.783283,
      lng: 170.597006,
    },
    links_count: 2,
    objectID: "2931",
    uniqueID: "63124eb9-7467-4f9f-a33b-32db82a70e02",
  },
  {
    name: "F D Roosevelt",
    city: "Oranjestad",
    country: "Netherlands Antilles",
    iata_code: "EUX",
    _geoloc: {
      lat: 17.496492,
      lng: -62.979439,
    },
    links_count: 2,
    objectID: "2898",
    uniqueID: "b0f60a77-80dc-461d-b014-1a0ba084c1a9",
  },
  {
    name: "Eugenio Maria De Hostos",
    city: "Mayaguez",
    country: "Puerto Rico",
    iata_code: "MAZ",
    _geoloc: {
      lat: 18.255694,
      lng: -67.148472,
    },
    links_count: 2,
    objectID: "2888",
    uniqueID: "85191afc-aa39-42b3-9fc3-5d67bc0fabc8",
  },
  {
    name: "San Tome",
    city: "San Tome",
    country: "Venezuela",
    iata_code: "SOM",
    _geoloc: {
      lat: 8.945147,
      lng: -64.151083,
    },
    links_count: 2,
    objectID: "2864",
    uniqueID: "1f266f1b-7df3-429b-a6c3-43cf44f6a778",
  },
  {
    name: "San Antonio Del Tachira",
    city: "San Antonio",
    country: "Venezuela",
    iata_code: "SVZ",
    _geoloc: {
      lat: 7.840831,
      lng: -72.439742,
    },
    links_count: 2,
    objectID: "2858",
    uniqueID: "88bf6b51-3eb0-4e60-9e91-4f32b58e563c",
  },
  {
    name: "Casique Aramare",
    city: "Puerto Ayacucho",
    country: "Venezuela",
    iata_code: "PYH",
    _geoloc: {
      lat: 5.619992,
      lng: -67.606103,
    },
    links_count: 2,
    objectID: "2853",
    uniqueID: "38be6b0d-db2d-44d4-bc0c-f993626d22a1",
  },
  {
    name: "La Fria",
    city: "La Fria",
    country: "Venezuela",
    iata_code: "LFR",
    _geoloc: {
      lat: 8.239167,
      lng: -72.271028,
    },
    links_count: 2,
    objectID: "2846",
    uniqueID: "7916f2f6-7ca3-44f4-9584-73b974f72099",
  },
  {
    name: "Antonio Jose De Sucre",
    city: "Cumana",
    country: "Venezuela",
    iata_code: "CUM",
    _geoloc: {
      lat: 10.450333,
      lng: -64.130472,
    },
    links_count: 2,
    objectID: "2835",
    uniqueID: "4de38c1a-3aa0-4bc2-aff4-e3ccf84b4b0d",
  },
  {
    name: "Jose Leonardo Chirinos",
    city: "Coro",
    country: "Venezuela",
    iata_code: "CZE",
    _geoloc: {
      lat: 11.414867,
      lng: -69.681656,
    },
    links_count: 2,
    objectID: "2833",
    uniqueID: "f5bc42f0-468e-4107-8b0b-7efabb5f3a27",
  },
  {
    name: "Canaima",
    city: "Canaima",
    country: "Venezuela",
    iata_code: "CAJ",
    _geoloc: {
      lat: 6.231989,
      lng: -62.854433,
    },
    links_count: 2,
    objectID: "2830",
    uniqueID: "5f707db6-41cc-438d-9318-747b8f2d05f4",
  },
  {
    name: "Capitan Carlos Martinez De Pinillos",
    city: "Trujillo",
    country: "Peru",
    iata_code: "TRU",
    _geoloc: {
      lat: -8.081411,
      lng: -79.108761,
    },
    links_count: 2,
    objectID: "2804",
    uniqueID: "00498bee-492e-49ab-a954-82ebb479cf8e",
  },
  {
    name: "Pedro Canga",
    city: "Tumbes",
    country: "Peru",
    iata_code: "TBP",
    _geoloc: {
      lat: -3.552528,
      lng: -80.381356,
    },
    links_count: 2,
    objectID: "2796",
    uniqueID: "51487c05-0c8b-43a3-8faf-011637114a87",
  },
  {
    name: "Comandante Fap German Arias Graziani",
    city: "Anta",
    country: "Peru",
    iata_code: "ATA",
    _geoloc: {
      lat: -9.347444,
      lng: -77.598392,
    },
    links_count: 2,
    objectID: "2788",
    uniqueID: "6db12223-c632-4f71-a2d6-4a86536cd852",
  },
  {
    name: "Andahuaylas",
    city: "Andahuaylas",
    country: "Peru",
    iata_code: "ANS",
    _geoloc: {
      lat: -13.706408,
      lng: -73.350378,
    },
    links_count: 2,
    objectID: "2787",
    uniqueID: "7ceded45-f35b-4af3-820d-a74147a24196",
  },
  {
    name: "Capt Jose A Quinones Gonzales Intl",
    city: "Chiclayo",
    country: "Peru",
    iata_code: "CIX",
    _geoloc: {
      lat: -6.787475,
      lng: -79.828097,
    },
    links_count: 2,
    objectID: "2785",
    uniqueID: "7939a43a-28ce-4a2b-ae0c-d7618b8a1d37",
  },
  {
    name: "Yola",
    city: "Yola",
    country: "Nigeria",
    iata_code: "YOL",
    _geoloc: {
      lat: 9.257553,
      lng: 12.430422,
    },
    links_count: 2,
    objectID: "277",
    uniqueID: "9f6f9392-87c8-44f2-bf82-e0397cff9e08",
  },
  {
    name: "Sadiq Abubakar Iii Intl",
    city: "Sokoto",
    country: "Nigeria",
    iata_code: "SKO",
    _geoloc: {
      lat: 12.916322,
      lng: 5.207189,
    },
    links_count: 2,
    objectID: "276",
    uniqueID: "fcffa667-523d-4ba2-bfe4-92cafeb61abc",
  },
  {
    name: "Almirante Padilla",
    city: "Rio Hacha",
    country: "Colombia",
    iata_code: "RCH",
    _geoloc: {
      lat: 11.526222,
      lng: -72.925958,
    },
    links_count: 2,
    objectID: "2746",
    uniqueID: "49e8d0f3-4d85-421c-bc72-8a6359050793",
  },
  {
    name: "El Embrujo",
    city: "Providencia",
    country: "Colombia",
    iata_code: "PVA",
    _geoloc: {
      lat: 13.356944,
      lng: -81.35833,
    },
    links_count: 2,
    objectID: "2743",
    uniqueID: "994835a2-ff46-4948-b29f-fb64c567caf7",
  },
  {
    name: "Guillermo Leon Valencia",
    city: "Popayan",
    country: "Colombia",
    iata_code: "PPN",
    _geoloc: {
      lat: 2.4544,
      lng: -76.609319,
    },
    links_count: 2,
    objectID: "2741",
    uniqueID: "ab93aea6-a684-4d63-a982-fc9727e5a2f7",
  },
  {
    name: "La Nubia",
    city: "Manizales",
    country: "Colombia",
    iata_code: "MZL",
    _geoloc: {
      lat: 5.029597,
      lng: -75.464708,
    },
    links_count: 2,
    objectID: "2733",
    uniqueID: "599bc731-2388-454d-8273-9ccd14819800",
  },
  {
    name: "San Luis",
    city: "Ipiales",
    country: "Colombia",
    iata_code: "IPI",
    _geoloc: {
      lat: 0.861925,
      lng: -77.671764,
    },
    links_count: 2,
    objectID: "2725",
    uniqueID: "1e3c4614-77cf-416f-b25f-5c4b3c4a3481",
  },
  {
    name: "Yariguies",
    city: "Barrancabermeja",
    country: "Colombia",
    iata_code: "EJA",
    _geoloc: {
      lat: 7.024331,
      lng: -73.8068,
    },
    links_count: 2,
    objectID: "2718",
    uniqueID: "be0b7c8a-86cd-4d1f-ab17-668f8f1b7f9d",
  },
  {
    name: "La Florida",
    city: "Tumaco",
    country: "Colombia",
    iata_code: "TCO",
    _geoloc: {
      lat: 1.814417,
      lng: -78.749228,
    },
    links_count: 2,
    objectID: "2716",
    uniqueID: "c6289955-318e-4c68-8a0d-0a99af06115c",
  },
  {
    name: "Jose Celestino Mutis",
    city: "Bahia Solano",
    country: "Colombia",
    iata_code: "BSC",
    _geoloc: {
      lat: 6.202917,
      lng: -77.394675,
    },
    links_count: 2,
    objectID: "2711",
    uniqueID: "aca11bc5-4e66-4e94-b75f-8b6657e54ddd",
  },
  {
    name: "Kaduna",
    city: "Kaduna",
    country: "Nigeria",
    iata_code: "KAD",
    _geoloc: {
      lat: 10.696025,
      lng: 7.320114,
    },
    links_count: 2,
    objectID: "269",
    uniqueID: "2d5e09e4-1b9e-4ddf-a8aa-8fb0099d7003",
  },
  {
    name: "Eloy Alfaro Intl",
    city: "Manta",
    country: "Ecuador",
    iata_code: "MEC",
    _geoloc: {
      lat: -0.946078,
      lng: -80.678808,
    },
    links_count: 2,
    objectID: "2683",
    uniqueID: "662808c2-fa3a-4beb-bb82-2d995d6a696c",
  },
  {
    name: "Coronel E Carvajal",
    city: "Macas",
    country: "Ecuador",
    iata_code: "XMS",
    _geoloc: {
      lat: -2.299167,
      lng: -78.12075,
    },
    links_count: 2,
    objectID: "2680",
    uniqueID: "eb1d7d53-7984-4b91-9222-b414e756b377",
  },
  {
    name: "Yakubu Gowon",
    city: "Jos",
    country: "Nigeria",
    iata_code: "JOS",
    _geoloc: {
      lat: 9.639828,
      lng: 8.86905,
    },
    links_count: 2,
    objectID: "268",
    uniqueID: "03a68114-908c-4a94-8d34-042dc3206044",
  },
  {
    name: "Ilorin",
    city: "Ilorin",
    country: "Nigeria",
    iata_code: "ILR",
    _geoloc: {
      lat: 8.440211,
      lng: 4.493919,
    },
    links_count: 2,
    objectID: "267",
    uniqueID: "fd7fb66e-aed2-4440-bf52-f27880e865e9",
  },
  {
    name: "Ibadan",
    city: "Ibadan",
    country: "Nigeria",
    iata_code: "IBA",
    _geoloc: {
      lat: 7.362458,
      lng: 3.978333,
    },
    links_count: 2,
    objectID: "266",
    uniqueID: "dfdf1c00-9d13-4aa1-bf42-37385ee90d79",
  },
  {
    name: "Canal Bajo Carlos Hott Siebert",
    city: "Osorno",
    country: "Chile",
    iata_code: "ZOS",
    _geoloc: {
      lat: -40.611208,
      lng: -73.061042,
    },
    links_count: 2,
    objectID: "2658",
    uniqueID: "31c0b2e4-606b-4a2a-8592-bc8c8cf350b8",
  },
  {
    name: "Vilhena",
    city: "Vilhena",
    country: "Brazil",
    iata_code: "BVH",
    _geoloc: {
      lat: -12.694375,
      lng: -60.098269,
    },
    links_count: 2,
    objectID: "2637",
    uniqueID: "d6146986-dde9-49fe-8a93-ce3c6aa5dd38",
  },
  {
    name: "Rubem Berta",
    city: "Uruguaiana",
    country: "Brazil",
    iata_code: "URG",
    _geoloc: {
      lat: -29.782178,
      lng: -57.038189,
    },
    links_count: 2,
    objectID: "2632",
    uniqueID: "04580a51-b60f-4ae5-bb5c-46d05e039553",
  },
  {
    name: "Paulo Afonso",
    city: "Paulo Alfonso",
    country: "Brazil",
    iata_code: "PAV",
    _geoloc: {
      lat: -9.400878,
      lng: -38.250575,
    },
    links_count: 2,
    objectID: "2631",
    uniqueID: "ca9b100b-ba52-4d33-8de5-e34a033b0584",
  },
  {
    name: "Tucurui",
    city: "Tucurui",
    country: "Brazil",
    iata_code: "TUR",
    _geoloc: {
      lat: -3.786008,
      lng: -49.720267,
    },
    links_count: 2,
    objectID: "2629",
    uniqueID: "c374220d-f708-430f-914c-744e5af6ead5",
  },
  {
    name: "Tabatinga",
    city: "Tabatinga",
    country: "Brazil",
    iata_code: "TBT",
    _geoloc: {
      lat: -4.255669,
      lng: -69.935828,
    },
    links_count: 2,
    objectID: "2628",
    uniqueID: "47e33bc0-1cf8-46ae-94d2-d8688567f25e",
  },
  {
    name: "Trombetas",
    city: "Oriximina",
    country: "Brazil",
    iata_code: "TMT",
    _geoloc: {
      lat: -1.4896,
      lng: -56.396803,
    },
    links_count: 2,
    objectID: "2622",
    uniqueID: "5edd2035-ee32-485a-a38e-a2aad2400b1f",
  },
  {
    name: "Bom Jesus Da Lapa",
    city: "Bom Jesus Da Lapa",
    country: "Brazil",
    iata_code: "LAZ",
    _geoloc: {
      lat: -13.262086,
      lng: -43.408114,
    },
    links_count: 2,
    objectID: "2582",
    uniqueID: "fe45e7ea-13b3-47e7-8dc7-f20b9f25018e",
  },
  {
    name: "Cruzeiro do Sul",
    city: "Cruzeiro do Sul",
    country: "Brazil",
    iata_code: "CZS",
    _geoloc: {
      lat: -7.599906,
      lng: -72.769489,
    },
    links_count: 2,
    objectID: "2549",
    uniqueID: "20ea6efc-8447-4adb-b166-8f61b85bbafe",
  },
  {
    name: "Corumba Intl",
    city: "Corumba",
    country: "Brazil",
    iata_code: "CMG",
    _geoloc: {
      lat: -19.011931,
      lng: -57.673053,
    },
    links_count: 2,
    objectID: "2544",
    uniqueID: "626400a4-a2f2-45aa-ae53-b4beb2674c82",
  },
  {
    name: "Alta Floresta",
    city: "Alta Floresta",
    country: "Brazil",
    iata_code: "AFL",
    _geoloc: {
      lat: -9.866092,
      lng: -56.106206,
    },
    links_count: 2,
    objectID: "2524",
    uniqueID: "06ea4cb2-b7b5-436c-9f60-6930b4a94ee2",
  },
  {
    name: "Araraquara",
    city: "Araracuara",
    country: "Brazil",
    iata_code: "AQA",
    _geoloc: {
      lat: -21.812,
      lng: -48.133028,
    },
    links_count: 2,
    objectID: "2521",
    uniqueID: "224fa791-c31e-47d9-9bd1-1b71e44b22db",
  },
  {
    name: "Conceicao Do Araguaia",
    city: "Conceicao Do Araguaia",
    country: "Brazil",
    iata_code: "CDJ",
    _geoloc: {
      lat: -8.348347,
      lng: -49.301528,
    },
    links_count: 2,
    objectID: "2518",
    uniqueID: "3d238658-11d5-4763-81e9-27754ba7d786",
  },
  {
    name: "Aviador C Campos",
    city: "San Martin Des Andes",
    country: "Argentina",
    iata_code: "CPC",
    _geoloc: {
      lat: -40.075383,
      lng: -71.137294,
    },
    links_count: 2,
    objectID: "2517",
    uniqueID: "96a0fb1b-5cab-4c00-9b8d-fb59f32d1591",
  },
  {
    name: "Santa Rosa",
    city: "Santa Rosa",
    country: "Argentina",
    iata_code: "RSA",
    _geoloc: {
      lat: -36.588322,
      lng: -64.275694,
    },
    links_count: 2,
    objectID: "2512",
    uniqueID: "ed487454-e00d-4eb8-81ab-56a3f6f92c3c",
  },
  {
    name: "Sunyani",
    city: "Sunyani",
    country: "Ghana",
    iata_code: "NYI",
    _geoloc: {
      lat: 7.361828,
      lng: -2.328756,
    },
    links_count: 2,
    objectID: "251",
    uniqueID: "7a232940-d2e4-4b14-8afd-8908f5019bdd",
  },
  {
    name: "El Tehuelche",
    city: "Puerto Madryn",
    country: "Argentina",
    iata_code: "PMY",
    _geoloc: {
      lat: -42.759161,
      lng: -65.102725,
    },
    links_count: 2,
    objectID: "2492",
    uniqueID: "9e0aa86b-4cb2-458e-b199-c0d5116749de",
  },
  {
    name: "Gobernador Castello",
    city: "Viedma",
    country: "Argentina",
    iata_code: "VDM",
    _geoloc: {
      lat: -40.869222,
      lng: -63.000389,
    },
    links_count: 2,
    objectID: "2491",
    uniqueID: "2cd9e1aa-b238-4e98-86f6-57056c537a50",
  },
  {
    name: "Posadas",
    city: "Posadas",
    country: "Argentina",
    iata_code: "PSS",
    _geoloc: {
      lat: -27.385839,
      lng: -55.970728,
    },
    links_count: 2,
    objectID: "2474",
    uniqueID: "cc58d9ae-382e-4df4-b561-fa4d0efa825c",
  },
  {
    name: "Formosa",
    city: "Formosa",
    country: "Argentina",
    iata_code: "FMA",
    _geoloc: {
      lat: -26.212722,
      lng: -58.228111,
    },
    links_count: 2,
    objectID: "2470",
    uniqueID: "d990e45b-7fd2-4dea-a76a-530ad2c60b37",
  },
  {
    name: "Resistencia",
    city: "Resistencia",
    country: "Argentina",
    iata_code: "RES",
    _geoloc: {
      lat: -27.449986,
      lng: -59.056125,
    },
    links_count: 2,
    objectID: "2469",
    uniqueID: "194bf399-47c9-4c9d-9cf8-351b0ac3a309",
  },
  {
    name: "La Rioja",
    city: "La Rioja",
    country: "Argentina",
    iata_code: "IRJ",
    _geoloc: {
      lat: -29.381636,
      lng: -66.795839,
    },
    links_count: 2,
    objectID: "2458",
    uniqueID: "dd09ecd7-90f0-41e7-8435-60ee41bfdc47",
  },
  {
    name: "Santiago Del Estero",
    city: "Santiago Del Estero",
    country: "Argentina",
    iata_code: "SDE",
    _geoloc: {
      lat: -27.765617,
      lng: -64.310122,
    },
    links_count: 2,
    objectID: "2456",
    uniqueID: "4df6ee4a-f590-461e-a64a-dd35df4e8056",
  },
  {
    name: "Catamarca",
    city: "Catamarca",
    country: "Argentina",
    iata_code: "CTC",
    _geoloc: {
      lat: -28.593214,
      lng: -65.750925,
    },
    links_count: 2,
    objectID: "2455",
    uniqueID: "42cc5bc6-5448-4871-9382-058abe772d3c",
  },
  {
    name: "Antique",
    city: "San Jose",
    country: "Philippines",
    iata_code: "SJI",
    _geoloc: {
      lat: 10.766044,
      lng: 121.933439,
    },
    links_count: 2,
    objectID: "2435",
    uniqueID: "9685d252-f5b6-4d58-ba59-a7c906b95b55",
  },
  {
    name: "Sidi Mahdi",
    city: "Touggourt",
    country: "Algeria",
    iata_code: "TGR",
    _geoloc: {
      lat: 33.067803,
      lng: 6.088672,
    },
    links_count: 2,
    objectID: "240",
    uniqueID: "0768ea15-2858-47ee-a289-bd36e8397658",
  },
  {
    name: "Kumejima",
    city: "Kumejima",
    country: "Japan",
    iata_code: "UEO",
    _geoloc: {
      lat: 26.363506,
      lng: 126.713806,
    },
    links_count: 2,
    objectID: "2388",
    uniqueID: "5b059e6e-8591-4b5e-9d56-da0f4f285d12",
  },
  {
    name: "Oshima",
    city: "Oshima",
    country: "Japan",
    iata_code: "OIM",
    _geoloc: {
      lat: 34.782033,
      lng: 139.360306,
    },
    links_count: 2,
    objectID: "2356",
    uniqueID: "a0ef51d3-b9d0-41e8-81f7-2cba0e109f3e",
  },
  {
    name: "Hachijojima",
    city: "Hachijojima",
    country: "Japan",
    iata_code: "HAC",
    _geoloc: {
      lat: 33.115,
      lng: 139.785833,
    },
    links_count: 2,
    objectID: "2352",
    uniqueID: "78539bdd-5dbe-4586-8e9d-53d85cfceb68",
  },
  {
    name: "Tottori",
    city: "Tottori",
    country: "Japan",
    iata_code: "TTJ",
    _geoloc: {
      lat: 35.530069,
      lng: 134.166553,
    },
    links_count: 2,
    objectID: "2335",
    uniqueID: "a50f79a6-c4e5-46ef-88fd-27654485fbe4",
  },
  {
    name: "Izumo",
    city: "Izumo",
    country: "Japan",
    iata_code: "IZO",
    _geoloc: {
      lat: 35.413611,
      lng: 132.89,
    },
    links_count: 2,
    objectID: "2328",
    uniqueID: "5b4aa098-9289-493b-a40a-0d529a0e4e16",
  },
  {
    name: "Monbetsu",
    city: "Monbetsu",
    country: "Japan",
    iata_code: "MBE",
    _geoloc: {
      lat: 44.303914,
      lng: 143.404028,
    },
    links_count: 2,
    objectID: "2298",
    uniqueID: "7a56ea7f-f170-4834-b0d1-506dfaf58f5f",
  },
  {
    name: "Nanki Shirahama",
    city: "Nanki-shirahama",
    country: "Japan",
    iata_code: "SHM",
    _geoloc: {
      lat: 33.662222,
      lng: 135.364444,
    },
    links_count: 2,
    objectID: "2284",
    uniqueID: "36dba84c-60f2-4df7-9918-0300e6eca8ce",
  },
  {
    name: "Tainan",
    city: "Tainan",
    country: "Taiwan",
    iata_code: "TNN",
    _geoloc: {
      lat: 22.950361,
      lng: 120.205778,
    },
    links_count: 2,
    objectID: "2270",
    uniqueID: "5eadfcb2-349b-40b1-9274-23b4c6efd361",
  },
  {
    name: "Fengnin",
    city: "Fengnin",
    country: "Taiwan",
    iata_code: "TTT",
    _geoloc: {
      lat: 22.754986,
      lng: 121.101681,
    },
    links_count: 2,
    objectID: "2262",
    uniqueID: "fb6325fe-2879-4bcd-a432-df64cdb9c5e1",
  },
  {
    name: "Cheikh Larbi Tebessi",
    city: "Tebessa",
    country: "Algeria",
    iata_code: "TEE",
    _geoloc: {
      lat: 35.431611,
      lng: 8.120717,
    },
    links_count: 2,
    objectID: "222",
    uniqueID: "ed901800-3ea1-4e71-8213-7740490222b4",
  },
  {
    name: "Panjgur",
    city: "Panjgur",
    country: "Pakistan",
    iata_code: "PJG",
    _geoloc: {
      lat: 26.954547,
      lng: 64.132517,
    },
    links_count: 2,
    objectID: "2217",
    uniqueID: "5ba27558-da4c-4c27-a80b-242aee5b9fad",
  },
  {
    name: "Gilgit",
    city: "Gilgit",
    country: "Pakistan",
    iata_code: "GIL",
    _geoloc: {
      lat: 35.918786,
      lng: 74.333644,
    },
    links_count: 2,
    objectID: "2204",
    uniqueID: "d631518c-601c-45d7-bf2b-893b8a4f01b1",
  },
  {
    name: "Khasab",
    city: "Khasab",
    country: "Oman",
    iata_code: "KHS",
    _geoloc: {
      lat: 26.170986,
      lng: 56.240569,
    },
    links_count: 2,
    objectID: "2192",
    uniqueID: "351b3884-d11e-446b-a972-47f0e7b5e543",
  },
  {
    name: "Fujairah Intl",
    city: "Fujeirah",
    country: "United Arab Emirates",
    iata_code: "FJR",
    _geoloc: {
      lat: 25.112225,
      lng: 56.323964,
    },
    links_count: 2,
    objectID: "2189",
    uniqueID: "b7378297-2d96-4e6b-97cd-db0e4c49cef3",
  },
  {
    name: "Sanandaj",
    city: "Sanandaj",
    country: "Iran",
    iata_code: "SDG",
    _geoloc: {
      lat: 35.245856,
      lng: 47.009247,
    },
    links_count: 2,
    objectID: "2117",
    uniqueID: "d982e2e4-cf85-4fa3-b64f-f69fb2bdeccc",
  },
  {
    name: "Wejh",
    city: "Wejh",
    country: "Saudi Arabia",
    iata_code: "EJH",
    _geoloc: {
      lat: 26.198553,
      lng: 36.476381,
    },
    links_count: 2,
    objectID: "2095",
    uniqueID: "cb01cf18-4170-4f1a-95f7-0ee2fe04c251",
  },
  {
    name: "Turaif",
    city: "Turaif",
    country: "Saudi Arabia",
    iata_code: "TUI",
    _geoloc: {
      lat: 31.692683,
      lng: 38.7312,
    },
    links_count: 2,
    objectID: "2093",
    uniqueID: "831746df-2f3a-4dbb-8272-c12910c6bd4e",
  },
  {
    name: "Rafha",
    city: "Rafha",
    country: "Saudi Arabia",
    iata_code: "RAH",
    _geoloc: {
      lat: 29.626419,
      lng: 43.490614,
    },
    links_count: 2,
    objectID: "2081",
    uniqueID: "b39d3d16-319d-40f6-b47b-14e7e6ca856d",
  },
  {
    name: "Fort Mcpherson",
    city: "Fort Mcpherson",
    country: "Canada",
    iata_code: "ZFM",
    _geoloc: {
      lat: 67.4075,
      lng: -134.860556,
    },
    links_count: 2,
    objectID: "206",
    uniqueID: "8d6cff35-e78f-4962-8597-9c3601312f73",
  },
  {
    name: "Wanganui",
    city: "Wanganui",
    country: "New Zealand",
    iata_code: "WAG",
    _geoloc: {
      lat: -39.962222,
      lng: 175.025278,
    },
    links_count: 2,
    objectID: "2047",
    uniqueID: "aae15499-b114-4edc-928b-f97830677d79",
  },
  {
    name: "Westport",
    city: "Westport",
    country: "New Zealand",
    iata_code: "WSZ",
    _geoloc: {
      lat: -41.738056,
      lng: 171.580833,
    },
    links_count: 2,
    objectID: "2046",
    uniqueID: "0ade5396-b844-4bd2-8da8-380d33e93b7a",
  },
  {
    name: "Whakatane",
    city: "Whakatane",
    country: "New Zealand",
    iata_code: "WHK",
    _geoloc: {
      lat: -37.920556,
      lng: 176.914167,
    },
    links_count: 2,
    objectID: "2041",
    uniqueID: "e4be3c8b-06e2-4dd1-930b-2baae0de8661",
  },
  {
    name: "Timaru",
    city: "Timaru",
    country: "New Zealand",
    iata_code: "TIU",
    _geoloc: {
      lat: -44.302778,
      lng: 171.225278,
    },
    links_count: 2,
    objectID: "2035",
    uniqueID: "20845d66-89e9-4911-97fc-fc08d224feea",
  },
  {
    name: "Kaitaia",
    city: "Kaitaia",
    country: "New Zealand",
    iata_code: "KAT",
    _geoloc: {
      lat: -35.07,
      lng: 173.285278,
    },
    links_count: 2,
    objectID: "2018",
    uniqueID: "cb9a624f-bd5e-4bfc-a9d0-cd1c8e7fac4e",
  },
  {
    name: "Kerikeri",
    city: "Kerikeri",
    country: "New Zealand",
    iata_code: "KKE",
    _geoloc: {
      lat: -35.262779,
      lng: 173.911944,
    },
    links_count: 2,
    objectID: "2017",
    uniqueID: "43678589-432b-4eb7-8921-aa0df353c575",
  },
  {
    name: "Hokitika",
    city: "Hokitika",
    country: "New Zealand",
    iata_code: "HKK",
    _geoloc: {
      lat: -42.713611,
      lng: 170.985278,
    },
    links_count: 2,
    objectID: "2014",
    uniqueID: "17cb5b8c-b038-410c-9c03-e70e324611c5",
  },
  {
    name: "Touho",
    city: "Touho",
    country: "New Caledonia",
    iata_code: "TOU",
    _geoloc: {
      lat: -20.790028,
      lng: 165.259486,
    },
    links_count: 2,
    objectID: "2003",
    uniqueID: "f5b94705-edd2-4690-95c6-174ab6b0bc54",
  },
  {
    name: "Mare",
    city: "Mare",
    country: "New Caledonia",
    iata_code: "MEE",
    _geoloc: {
      lat: -21.481678,
      lng: 168.037508,
    },
    links_count: 2,
    objectID: "2002",
    uniqueID: "568fbc3e-0686-4890-9108-f7fbb42b1046",
  },
  {
    name: "Kone",
    city: "Kone",
    country: "New Caledonia",
    iata_code: "KNQ",
    _geoloc: {
      lat: -21.053428,
      lng: 164.837806,
    },
    links_count: 2,
    objectID: "1998",
    uniqueID: "facd372f-632b-4d3c-857d-9db86b4d15f1",
  },
  {
    name: "Chris Hadfield",
    city: "Sarnia",
    country: "Canada",
    iata_code: "YZR",
    _geoloc: {
      lat: 42.999444,
      lng: -82.308889,
    },
    links_count: 2,
    objectID: "199",
    uniqueID: "bdd7bdad-a073-4408-bb2d-029b05b62586",
  },
  {
    name: "Arutua",
    city: "Arutua",
    country: "French Polynesia",
    iata_code: "AXR",
    _geoloc: {
      lat: -15.248289,
      lng: -146.616708,
    },
    links_count: 2,
    objectID: "1984",
    uniqueID: "71e88caf-9610-4460-998b-d9d70275dae1",
  },
  {
    name: "Takapoto",
    city: "Takapoto",
    country: "French Polynesia",
    iata_code: "TKP",
    _geoloc: {
      lat: -14.709544,
      lng: -145.245814,
    },
    links_count: 2,
    objectID: "1983",
    uniqueID: "2aaf921a-7147-49a6-ad94-d4731b0ad369",
  },
  {
    name: "Makemo",
    city: "Makemo",
    country: "French Polynesia",
    iata_code: "MKP",
    _geoloc: {
      lat: -16.583919,
      lng: -143.658369,
    },
    links_count: 2,
    objectID: "1981",
    uniqueID: "f5a77775-492d-4c0e-85b5-591861e9f5f0",
  },
  {
    name: "Kaukura",
    city: "Kaukura Atoll",
    country: "French Polynesia",
    iata_code: "KKR",
    _geoloc: {
      lat: -15.663333,
      lng: -146.884769,
    },
    links_count: 2,
    objectID: "1980",
    uniqueID: "d751be14-64b4-4b15-8d40-806162f31aa8",
  },
  {
    name: "Sandspit",
    city: "Sandspit",
    country: "Canada",
    iata_code: "YZP",
    _geoloc: {
      lat: 53.254333,
      lng: -131.813833,
    },
    links_count: 2,
    objectID: "198",
    uniqueID: "5c00cd35-6cc4-42c5-80d4-9febbba0d227",
  },
  {
    name: "Totegegie",
    city: "Totegegie",
    country: "French Polynesia",
    iata_code: "GMR",
    _geoloc: {
      lat: -23.079861,
      lng: -134.890333,
    },
    links_count: 2,
    objectID: "1979",
    uniqueID: "522a2655-9c90-4cbf-8156-85bf2742ff76",
  },
  {
    name: "Pago Pago Intl",
    city: "Pago Pago",
    country: "American Samoa",
    iata_code: "PPG",
    _geoloc: {
      lat: -14.331,
      lng: -170.7105,
    },
    links_count: 2,
    objectID: "1970",
    uniqueID: "65c5fddb-6276-4b36-a8ed-c187efc60f72",
  },
  {
    name: "Mariana Grajales",
    city: "Guantanamo",
    country: "Cuba",
    iata_code: "GAO",
    _geoloc: {
      lat: 20.085419,
      lng: -75.158328,
    },
    links_count: 2,
    objectID: "1908",
    uniqueID: "4a11621f-291c-4493-ac00-3a07793fd9e1",
  },
  {
    name: "Nosara",
    city: "Nosara Beach",
    country: "Costa Rica",
    iata_code: "NOB",
    _geoloc: {
      lat: 9.97649,
      lng: -85.653,
    },
    links_count: 2,
    objectID: "1884",
    uniqueID: "0ad15896-d57f-4171-b3a5-ee576e740db5",
  },
  {
    name: "Cap Manuel Nino Intl",
    city: "Changuinola",
    country: "Panama",
    iata_code: "CHX",
    _geoloc: {
      lat: 9.458636,
      lng: -82.516806,
    },
    links_count: 2,
    objectID: "1866",
    uniqueID: "aa42887e-99ee-4506-8ce7-8d12d10c92e5",
  },
  {
    name: "Quetzalcoatl Intl",
    city: "Nuevo Laredo",
    country: "Mexico",
    iata_code: "NLD",
    _geoloc: {
      lat: 27.443918,
      lng: -99.57046,
    },
    links_count: 2,
    objectID: "1828",
    uniqueID: "19071c9f-dde3-4d07-822c-0e7b73abf71a",
  },
  {
    name: "Loreto Intl",
    city: "Loreto",
    country: "Mexico",
    iata_code: "LTO",
    _geoloc: {
      lat: 25.989194,
      lng: -111.348361,
    },
    links_count: 2,
    objectID: "1817",
    uniqueID: "40d7538b-919c-41ef-8065-3c46a5fd9213",
  },
  {
    name: "Penticton",
    city: "Penticton",
    country: "Canada",
    iata_code: "YYF",
    _geoloc: {
      lat: 49.463056,
      lng: -119.602222,
    },
    links_count: 2,
    objectID: "181",
    uniqueID: "a7e5c01b-d3f3-467d-8d88-95c268e27925",
  },
  {
    name: "Chetumal Intl",
    city: "Chetumal",
    country: "Mexico",
    iata_code: "CTM",
    _geoloc: {
      lat: 18.504667,
      lng: -88.326847,
    },
    links_count: 2,
    objectID: "1793",
    uniqueID: "3729dc69-6983-4117-8f68-8f5f0f0fd587",
  },
  {
    name: "Guanaja",
    city: "Guanaja",
    country: "Honduras",
    iata_code: "GJA",
    _geoloc: {
      lat: 16.445367,
      lng: -85.906611,
    },
    links_count: 2,
    objectID: "1773",
    uniqueID: "0960138b-14db-406f-817a-59940772e0fe",
  },
  {
    name: "Medicine Hat",
    city: "Medicine Hat",
    country: "Canada",
    iata_code: "YXH",
    _geoloc: {
      lat: 50.01889,
      lng: -110.720833,
    },
    links_count: 2,
    objectID: "167",
    uniqueID: "fa1555ba-f441-4986-a335-15cc7dcb8aae",
  },
  {
    name: "Mihail Kogalniceanu",
    city: "Constanta",
    country: "Romania",
    iata_code: "CND",
    _geoloc: {
      lat: 44.362222,
      lng: 28.488333,
    },
    links_count: 2,
    objectID: "1651",
    uniqueID: "eafd82a6-d02b-48d0-935b-1746d3a06224",
  },
  {
    name: "Arad",
    city: "Arad",
    country: "Romania",
    iata_code: "ARW",
    _geoloc: {
      lat: 46.17655,
      lng: 21.262022,
    },
    links_count: 2,
    objectID: "1647",
    uniqueID: "781d29cb-0178-458d-b8d7-1e9bcb4e8435",
  },
  {
    name: "Mostar",
    city: "Mostar",
    country: "Bosnia and Herzegovina",
    iata_code: "OMO",
    _geoloc: {
      lat: 43.2829,
      lng: 17.845878,
    },
    links_count: 2,
    objectID: "1645",
    uniqueID: "3b1b278a-8dd6-495d-aaf5-d3fdb235c19d",
  },
  {
    name: "Graciosa",
    city: "Graciosa Island",
    country: "Portugal",
    iata_code: "GRW",
    _geoloc: {
      lat: 39.092169,
      lng: -28.029847,
    },
    links_count: 2,
    objectID: "1627",
    uniqueID: "3c785c07-8320-4330-9b2f-840c02c4a2b7",
  },
  {
    name: "Turany",
    city: "Brno",
    country: "Czech Republic",
    iata_code: "BRQ",
    _geoloc: {
      lat: 49.151269,
      lng: 16.694433,
    },
    links_count: 2,
    objectID: "1588",
    uniqueID: "16e2384f-e0c2-48d5-88df-82df6c203ee6",
  },
  {
    name: "Isafjordur",
    city: "Isafjordur",
    country: "Iceland",
    iata_code: "IFJ",
    _geoloc: {
      lat: 66.058056,
      lng: -23.135278,
    },
    links_count: 2,
    objectID: "15",
    uniqueID: "64278671-578d-44fe-86f4-c3b87ed5d501",
  },
  {
    name: "Aristotelis",
    city: "Kastoria",
    country: "Greece",
    iata_code: "KSO",
    _geoloc: {
      lat: 40.446294,
      lng: 21.282186,
    },
    links_count: 2,
    objectID: "1453",
    uniqueID: "821b884b-2638-4d06-adce-c80e39dc9dbc",
  },
  {
    name: "Tuktoyaktuk",
    city: "Tuktoyaktuk",
    country: "Canada",
    iata_code: "YUB",
    _geoloc: {
      lat: 69.433334,
      lng: -133.026389,
    },
    links_count: 2,
    objectID: "145",
    uniqueID: "d62c86f8-32ef-4802-b1fb-9b9a7f6c08f9",
  },
  {
    name: "Longvic",
    city: "Dijon",
    country: "France",
    iata_code: "DIJ",
    _geoloc: {
      lat: 47.26889,
      lng: 5.09,
    },
    links_count: 2,
    objectID: "1425",
    uniqueID: "f705a551-4b09-44ae-ac74-4eccc592c18a",
  },
  {
    name: "Pluguffan",
    city: "Quimper",
    country: "France",
    iata_code: "UIP",
    _geoloc: {
      lat: 47.974981,
      lng: -4.167786,
    },
    links_count: 2,
    objectID: "1417",
    uniqueID: "5800bf11-2357-42f2-8298-68e735787f2a",
  },
  {
    name: "Lannion",
    city: "Lannion",
    country: "France",
    iata_code: "LAI",
    _geoloc: {
      lat: 48.754378,
      lng: -3.471656,
    },
    links_count: 2,
    objectID: "1416",
    uniqueID: "6d6bc516-d2c6-40a5-bf55-6ffd95edeee7",
  },
  {
    name: "Octeville",
    city: "Le Havre",
    country: "France",
    iata_code: "LEH",
    _geoloc: {
      lat: 49.533889,
      lng: 0.088056,
    },
    links_count: 2,
    objectID: "1371",
    uniqueID: "f44a002c-c74a-4f59-b871-7e72557c01b7",
  },
  {
    name: "Aurillac",
    city: "Aurillac",
    country: "France",
    iata_code: "AUR",
    _geoloc: {
      lat: 44.891388,
      lng: 2.421944,
    },
    links_count: 2,
    objectID: "1344",
    uniqueID: "41e6a8a9-ed8b-4e1b-8f27-18cc96b4f18d",
  },
  {
    name: "Loudes",
    city: "Le Puy",
    country: "France",
    iata_code: "LPY",
    _geoloc: {
      lat: 45.080689,
      lng: 3.762889,
    },
    links_count: 2,
    objectID: "1313",
    uniqueID: "40f975ea-c448-4ffb-86c8-333d804a72dc",
  },
  {
    name: "Brie Champniers",
    city: "Angouleme",
    country: "France",
    iata_code: "ANG",
    _geoloc: {
      lat: 45.729247,
      lng: 0.221456,
    },
    links_count: 2,
    objectID: "1277",
    uniqueID: "ad02180b-0425-4ad3-ae06-56de1e8f9c25",
  },
  {
    name: "Salamanca",
    city: "Salamanca",
    country: "Spain",
    iata_code: "SLM",
    _geoloc: {
      lat: 40.952117,
      lng: -5.501986,
    },
    links_count: 2,
    objectID: "1238",
    uniqueID: "a32bd494-814b-4a46-bb1a-a795aa655db3",
  },
  {
    name: "Egilsstadir",
    city: "Egilsstadir",
    country: "Iceland",
    iata_code: "EGS",
    _geoloc: {
      lat: 65.283333,
      lng: -14.401389,
    },
    links_count: 2,
    objectID: "12",
    uniqueID: "e87af4f8-8a4e-48e1-bb96-14ca0afce893",
  },
  {
    name: "Mtwara",
    city: "Mtwara",
    country: "Tanzania",
    iata_code: "MYW",
    _geoloc: {
      lat: -10.339058,
      lng: 40.181781,
    },
    links_count: 2,
    objectID: "1182",
    uniqueID: "7c424ddd-fb14-4244-b7cc-a8c786929fd2",
  },
  {
    name: "Arusha",
    city: "Arusha",
    country: "Tanzania",
    iata_code: "ARK",
    _geoloc: {
      lat: -3.367794,
      lng: 36.633333,
    },
    links_count: 2,
    objectID: "1176",
    uniqueID: "be6cd3ed-1128-4461-b4bd-7d76c47968b4",
  },
  {
    name: "Kamembe",
    city: "Kamembe",
    country: "Rwanda",
    iata_code: "KME",
    _geoloc: {
      lat: -2.462242,
      lng: 28.90795,
    },
    links_count: 2,
    objectID: "1166",
    uniqueID: "f1892ffc-03fd-4603-985b-2cae6feb4762",
  },
  {
    name: "Ghadames East",
    city: "Ghadames",
    country: "Libya",
    iata_code: "LTD",
    _geoloc: {
      lat: 30.151695,
      lng: 9.715305,
    },
    links_count: 2,
    objectID: "1162",
    uniqueID: "059acf73-8174-4111-8666-8c5939cfdd91",
  },
  {
    name: "Lethbridge",
    city: "Lethbridge",
    country: "Canada",
    iata_code: "YQL",
    _geoloc: {
      lat: 49.630278,
      lng: -112.799722,
    },
    links_count: 2,
    objectID: "116",
    uniqueID: "03ddc5a5-8137-468e-abbb-df20b141249c",
  },
  {
    name: "Ghat",
    city: "Ghat",
    country: "Libya",
    iata_code: "GHT",
    _geoloc: {
      lat: 25.145564,
      lng: 10.142647,
    },
    links_count: 2,
    objectID: "1152",
    uniqueID: "b4033736-4ba5-4aba-ac30-e41a093165c3",
  },
  {
    name: "Kitale",
    city: "Kitale",
    country: "Kenya",
    iata_code: "KTL",
    _geoloc: {
      lat: 0.971989,
      lng: 34.958556,
    },
    links_count: 2,
    objectID: "1141",
    uniqueID: "c517c2fa-8031-45fc-960d-8bb054027429",
  },
  {
    name: "Abu Simbel",
    city: "Abu Simbel",
    country: "Egypt",
    iata_code: "ABS",
    _geoloc: {
      lat: 22.375953,
      lng: 31.611722,
    },
    links_count: 2,
    objectID: "1127",
    uniqueID: "29d1fc5c-cbf9-437f-9992-24c13b3c9934",
  },
  {
    name: "Maio",
    city: "Maio",
    country: "Cape Verde",
    iata_code: "MMO",
    _geoloc: {
      lat: 15.155928,
      lng: -23.213703,
    },
    links_count: 2,
    objectID: "1104",
    uniqueID: "04e72d22-75b0-4943-9708-f6a7283cb746",
  },
  {
    name: "Akureyri",
    city: "Akureyri",
    country: "Iceland",
    iata_code: "AEY",
    _geoloc: {
      lat: 65.659994,
      lng: -18.072703,
    },
    links_count: 2,
    objectID: "11",
    uniqueID: "9da9ee6b-208c-45db-a8ba-215d64a9db56",
  },
  {
    name: "Cap Skiring",
    city: "Cap Skiring",
    country: "Senegal",
    iata_code: "CSK",
    _geoloc: {
      lat: 12.4102,
      lng: -16.746125,
    },
    links_count: 2,
    objectID: "1082",
    uniqueID: "7d12f9f0-e073-4860-a528-103efae62684",
  },
  {
    name: "Saniat Rmel",
    city: "Tetouan",
    country: "Morocco",
    iata_code: "TTU",
    _geoloc: {
      lat: 35.594333,
      lng: -5.320019,
    },
    links_count: 2,
    objectID: "1079",
    uniqueID: "58499424-be2b-4fdc-bb76-f0dacc4929e9",
  },
  {
    name: "Cherif El Idrissi",
    city: "Al Hociema",
    country: "Morocco",
    iata_code: "AHU",
    _geoloc: {
      lat: 35.177103,
      lng: -3.839525,
    },
    links_count: 2,
    objectID: "1078",
    uniqueID: "25242fd8-c107-4408-a7e2-fd8c47bfc21d",
  },
  {
    name: "Plage Blanche",
    city: "Tan Tan",
    country: "Morocco",
    iata_code: "TTA",
    _geoloc: {
      lat: 28.448194,
      lng: -11.161347,
    },
    links_count: 2,
    objectID: "1065",
    uniqueID: "2581ce49-1fcb-4975-8daa-fe53d68cd02f",
  },
  {
    name: "Pickle Lake",
    city: "Pickle Lake",
    country: "Canada",
    iata_code: "YPL",
    _geoloc: {
      lat: 51.446388,
      lng: -90.214167,
    },
    links_count: 2,
    objectID: "105",
    uniqueID: "abc1b6cf-665c-4e15-8e29-bccdb7d9930a",
  },
  {
    name: "Matari",
    city: "Isiro",
    country: "Congo (Kinshasa)",
    iata_code: "IRP",
    _geoloc: {
      lat: 2.827606,
      lng: 27.588253,
    },
    links_count: 2,
    objectID: "1032",
    uniqueID: "af39b8ce-a84e-403a-b63e-af0050793c9d",
  },
  {
    name: "Gemena",
    city: "Gemena",
    country: "Congo (Kinshasa)",
    iata_code: "GMA",
    _geoloc: {
      lat: 3.235369,
      lng: 19.771258,
    },
    links_count: 2,
    objectID: "1028",
    uniqueID: "8a1321a0-9596-4622-a9a4-c8368359b542",
  },
  {
    name: "Moshoeshoe I Intl",
    city: "Maseru",
    country: "Lesotho",
    iata_code: "MSU",
    _geoloc: {
      lat: -29.462256,
      lng: 27.552503,
    },
    links_count: 2,
    objectID: "1018",
    uniqueID: "72066938-b0d7-470c-a997-30a2a669dc64",
  },
  {
    name: "Sao Felix do Xingu Airport",
    city: "Sao Felix do Xingu",
    country: "Brazil",
    iata_code: "SXX",
    _geoloc: {
      lat: -6.641389,
      lng: -51.952222,
    },
    links_count: 1,
    objectID: "8241",
    uniqueID: "44f8b021-40f5-4f75-86a8-0cd4f6cb3a9c",
  },
  {
    name: "Confresa Airport",
    city: "Santa Terezinha",
    country: "Brazil",
    iata_code: "STZ",
    _geoloc: {
      lat: -10.47,
      lng: -50.502778,
    },
    links_count: 1,
    objectID: "7374",
    uniqueID: "8dca8885-eb6e-4cc9-8711-467b737ece98",
  },
  {
    name: "Breves Airport",
    city: "Breves",
    country: "Brazil",
    iata_code: "BVS",
    _geoloc: {
      lat: -1.681944,
      lng: -50.48,
    },
    links_count: 1,
    objectID: "7370",
    uniqueID: "80d4494c-b8bf-4763-b865-c7581f36a144",
  },
  {
    name: "Campo Alegre Airport",
    city: "Santana do Araguaia",
    country: "Brazil",
    iata_code: "CMP",
    _geoloc: {
      lat: -9.505,
      lng: -50.625,
    },
    links_count: 1,
    objectID: "7369",
    uniqueID: "c3d0ac9d-6752-4520-8307-97b5ee03dee1",
  },
  {
    name: "Zachar Bay Seaplane Base",
    city: "Zachar Bay",
    country: "United States",
    iata_code: "KZB",
    _geoloc: {
      lat: 57.55,
      lng: -153.75,
    },
    links_count: 1,
    objectID: "7176",
    uniqueID: "803ba91c-8082-4bec-a76d-d30528c41d7a",
  },
  {
    name: "Port Williams Seaplane Base",
    city: "Port Williams",
    country: "United States",
    iata_code: "KPR",
    _geoloc: {
      lat: 58.49,
      lng: -152.582222,
    },
    links_count: 1,
    objectID: "7172",
    uniqueID: "acf43afd-e2d5-4031-9f66-3403e54d8e10",
  },
  {
    name: "Karuluk Airport",
    city: "Karluk",
    country: "United States",
    iata_code: "KYK",
    _geoloc: {
      lat: 57.566944,
      lng: -154.450278,
    },
    links_count: 1,
    objectID: "7161",
    uniqueID: "1268610f-0959-43b3-a4e2-badcb2ade2cb",
  },
  {
    name: "Dalanzadgad Airport",
    city: "Dalanzadgad",
    country: "Mongolia",
    iata_code: "DLZ",
    _geoloc: {
      lat: 43.5917,
      lng: 104.43,
    },
    links_count: 1,
    objectID: "6372",
    uniqueID: "78b22083-4385-4a28-ab45-1483d46ded12",
  },
  {
    name: "Portland Airport",
    city: "Portland",
    country: "Australia",
    iata_code: "PTJ",
    _geoloc: {
      lat: -38.3181,
      lng: 141.471,
    },
    links_count: 1,
    objectID: "6321",
    uniqueID: "e17cd358-ded2-41e2-bc9e-f94289d424e4",
  },
  {
    name: "Niue International Airport",
    city: "Alofi",
    country: "Niue",
    iata_code: "IUE",
    _geoloc: {
      lat: -19.080028,
      lng: -169.925639,
    },
    links_count: 1,
    objectID: "5884",
    uniqueID: "55e8fc18-3e34-4bd1-b6c2-1cfb7ed1f5ec",
  },
  {
    name: "Lodja Airport",
    city: "Lodja",
    country: "Congo (Kinshasa)",
    iata_code: "LJA",
    _geoloc: {
      lat: -3.417,
      lng: 23.45,
    },
    links_count: 1,
    objectID: "5653",
    uniqueID: "ea7e1c26-ae60-4d9d-bbaf-07491fe7d234",
  },
  {
    name: "Utila Airport",
    city: "Utila",
    country: "Honduras",
    iata_code: "UII",
    _geoloc: {
      lat: 16.091667,
      lng: -86.8875,
    },
    links_count: 1,
    objectID: "4125",
    uniqueID: "74ff6e17-daf6-4c5c-b204-3a0314796671",
  },
  {
    name: "Massawa Intl",
    city: "Massawa",
    country: "Eritrea",
    iata_code: "MSW",
    _geoloc: {
      lat: 15.669989,
      lng: 39.370103,
    },
    links_count: 1,
    objectID: "3968",
    uniqueID: "69a24aa6-29af-408a-a089-95ff199ad01c",
  },
  {
    name: "Teniente Coronel Luis A Mantilla",
    city: "Tulcan",
    country: "Ecuador",
    iata_code: "TUA",
    _geoloc: {
      lat: 0.809506,
      lng: -77.708056,
    },
    links_count: 1,
    objectID: "2697",
    uniqueID: "8d077fe7-3dea-43ff-a7a4-2e9a609d8e8a",
  },
  {
    name: "Ovda",
    city: "Ovda",
    country: "Israel",
    iata_code: "VDA",
    _geoloc: {
      lat: 29.94025,
      lng: 34.93585,
    },
    links_count: 1,
    objectID: "1602",
    uniqueID: "7f986155-f782-4411-812c-4d0d9be86015",
  },
  {
    name: "Filippos",
    city: "Kozani",
    country: "Greece",
    iata_code: "KZI",
    _geoloc: {
      lat: 40.28611,
      lng: 21.840834,
    },
    links_count: 1,
    objectID: "1463",
    uniqueID: "691253fc-a790-4cb0-b455-57dba4026911",
  },
  {
    name: "Kalemie",
    city: "Kalemie",
    country: "Congo (Kinshasa)",
    iata_code: "FMI",
    _geoloc: {
      lat: -5.875556,
      lng: 29.25,
    },
    links_count: 1,
    objectID: "1040",
    uniqueID: "8532f781-f5b0-4309-8036-0540b028712e",
  },
];
