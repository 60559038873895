import { Link } from "react-router-dom";
import HeroNav from "../components/homeComponent/HeroNav.component";
import { FlightBanner } from "../utils/images";
import { contactInfo } from "../dummydata/contactInfo";
import ScrollToTop from "../components/ScrollToTop";
import Breadcrumb from "../components/Includes/BreadCrumbs";

const RefundPolicy = () => {
  return (
    <>
      <ScrollToTop />
      <div
        className={`w-full h-full md:h-[35rem] lg:h-[15rem] bg-cover bg-no-repeat bg-center`}
        style={{ backgroundImage: `url(${FlightBanner})` }}
      >
        <HeroNav />
        <h1 className="text-white text-4xl sm:text-7xl font-bold w-[70vw] mx-auto mt-10">
          Refund Policy
        </h1>
      </div>
      <div className="w-[94%] sm:w-[70vw] mx-auto py-2">
        <Breadcrumb />
      </div>
      <div className="flex flex-col gap-y-4 w-[94%] sm:w-[70vw] mx-auto my-2 sm:my-10 text-justify">
        <p className="text-base text-gray-900 font-medium">
          As a company policy, we do not issue refunds unless a system
          malfunction caused a problem or your ticket was cancel without cause.
          In this article, you will learn about dtt.inc refund policy.
        </p>
        <h3 className="text-base text-gray-900 font-bold">
          Refund, Cancellation and Re-booking Policy
        </h3>
        <p className="text-sm text-gray-700 font-medium">
          If you need to cancel your flight arrangements, call{" "}
          <Link to="/">dreamstravelandtours.com</Link> at{" "}
          {contactInfo.mobileNum}. Refund will be only given if the Tickets
          purchased is refundable. Non-refundable flights will not receive a
          refund. If your flight is booked with a Low-Cost Carrier, we also
          cannot refund your booking fee.
        </p>
        <p className="text-sm text-gray-700 font-medium">
          Passenger will only receive future credit from the airline if cancel a
          non refundable Tickets and may have to pay airline penalty in order to
          use his credit other terms and conditions may apply depending on the
          airline.
        </p>
        <h3 className="text-base text-gray-900 font-bold">
          Additional charges on re-booked flights
        </h3>
        <p className="text-sm text-gray-700 font-medium">
          Airline change fee, ranging from USD 100 to USD 300 will apply.
        </p>
        <p className="text-sm text-gray-700 font-medium">
          Difference in airfare (if the cost of your new flight exceeds your
          flight credit) will be applicable.
        </p>
        <h3 className="text-base text-gray-900 font-bold">Compliance Issues</h3>
        <p className="text-sm text-gray-700 font-medium">
          We do not provide refunds for unused Pay As You Go credits on tickets
          that were terminated due to a compliance issue.
        </p>
        <p className="text-sm text-gray-700 font-medium">
          Be sure to read and understand our Terms of Use and Acceptable Use
          Policy before you send a campaign with{" "}
          <Link to="/">dreamstravelandtours.com</Link> We reserve the right to
          disable or cancel any ticket if our Compliance Team finds that
          passanger has abused our system in any way.
        </p>
        <h3 className="text-base text-gray-900 font-bold">Charge backs</h3>
        <p className="text-sm text-gray-700 font-medium">
          When you request a chargeback, the bank that issues your credit card
          reverses a payment you made to dtt.inc, and sending is disabled in
          your account. To resolve the chargeback and re-enable sending,
          navigate to the travel date and re-purchase your order.
        </p>
        <p className="text-sm text-gray-700 font-medium">
          To request a refund, <Link to="/contact-us">Contact Us</Link>
        </p>
      </div>
    </>
  );
};

export default RefundPolicy;
