import { FlightBanner } from "../../utils/images";

import FlightSearchInputFields from "./Includes/FlightSearchInputFields";
import BannerNav from "../BannerNav";

const HeroSection: React.FC<{ title?: string }> = ({ title = "" }) => {
  return (
    <div
      className="h-[35rem] sm:h-[90vh] lg:h-[60vh] flex flex-col bg-cover bg-no-repeat bg-center p-5 relative"
      style={{ backgroundImage: `url(${FlightBanner})` }}
    >
      <BannerNav />
      <div className="absolute top-[30%] sm:top-[35%] md:top-[45%] left-1/2 -translate-x-1/2 -translate-y-1/4 z-10 backdrop-blur-xs">
        {title.trim() !== "" && (
          <p className="text-base md:text-4xl text-white font-semibold -mb-16 md:mb-2 mt-16 md:mt-0">
            {title}
          </p>
        )}
        <FlightSearchInputFields showNav={false} />
      </div>
    </div>
  );
};

export default HeroSection;
