import React, { ChangeEvent, useEffect, useState } from "react";
import { PassengerI, psgTypeT } from "../../types/flightSearchReducer.types";
import CalenderDOB from "../Includes/CalenderDOB";

interface UserFormProp {
  getForm: (obj: PassengerI) => void;
  PsgType: psgTypeT;
  adultsLenght?: any;
  passengerId: number;
}

export enum PSGTYPE {
  ADT = "ADT",
  CHD = "CHD",
  INF = "INF",
}

const Userform: React.FC<UserFormProp> = ({
  getForm,
  PsgType,
  adultsLenght,
  passengerId,
}) => {
  const [UserBasicForm, setUserBasicForm] = useState<PassengerI>({
    passengerIndex: passengerId + 1,
    title: "Mr",
    psgType: PsgType,
    nationality: "US",
    birthday: "",
    firstName: "",
    middleName: "",
    lastName: "",
    sex: "M",
    associatedPassengerIndex: 0,
  });

  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  //step 1 form validation
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});

  //step2 form validation make function validateField
  const validateField = (fieldName: string, value: string) => {
    let error = "";

    if (["firstName", "lastName"].includes(fieldName)) {
      if (!value) {
        error = `${fieldName} is required.`;
      } else if (!/^[a-zA-Z\s]+$/.test(value)) {
        error = `contain only letters.`;
      }
    }

    if (fieldName === "middleName" && value && !/^[a-zA-Z\s]+$/.test(value)) {
      error = "contain only letters .";
    }

    if (fieldName === "birthday") {
      if (!value) {
        error = "Date of Birth is required.";
      } else {
        const dateValue = new Date(value);
        if (isNaN(dateValue.getTime())) {
          error = "Invalid date format.";
        } else if (
          dateValue > new Date(maxDate) ||
          dateValue < new Date(minDate)
        ) {
          error = `Date of birth must be between ${minDate} and ${maxDate}.`;
        }
      }
    }

    return error;
  };

  useEffect(() => {
    const today = new Date();
    const formatDate = (date: Date) => date.toISOString().split("T")[0];

    // Default to today's date as max for all types
    setMaxDate(formatDate(today));

    if (UserBasicForm.psgType === "ADT") {
      // Adults must be older than 11 years, so max date is today - 11 years
      const maxAdultDate = new Date(today);
      maxAdultDate.setFullYear(today.getFullYear() - 11);
      setMaxDate(formatDate(maxAdultDate));
    } else if (UserBasicForm.psgType === "CHD") {
      // Children should be between 1 to 12 years old, so max date is today - 1 year and min date is today - 12 years
      const maxChildDate = new Date(today);
      maxChildDate.setFullYear(today.getFullYear() - 2);
      const minChildDate = new Date(today);
      minChildDate.setFullYear(today.getFullYear() - 13);
      setMaxDate(formatDate(maxChildDate));
      setMinDate(formatDate(minChildDate));
    } else if (UserBasicForm.psgType === "INF") {
      // Infants should be 2 years old or younger, so min date is today - 2 years
      const minInfantDate = new Date(today);
      minInfantDate.setFullYear(today.getFullYear() - 2);
      setMaxDate(formatDate(today)); // Set max to today
      setMinDate(formatDate(minInfantDate)); // Set min to 2 years ago
    }
  }, [UserBasicForm.psgType]);
  const [title, setTitle] = useState("Mr");

  const handleTitleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const selectedTitle = event.target.value;
    setTitle(selectedTitle);

    // Automatically set gender based on selected title
    if (selectedTitle === "Mr") {
      setUserBasicForm((prevForm) => ({ ...prevForm, sex: "M" }));
    } else if (selectedTitle === "Ms" || selectedTitle === "Mrs") {
      setUserBasicForm((prevForm) => ({ ...prevForm, sex: "F" }));
    }
  };

  const onChangeInputField = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setUserBasicForm((prevForm) => ({ ...prevForm, [name]: value }));

    const error = validateField(name, value);
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  useEffect(() => {
    getForm(UserBasicForm);
  }, [UserBasicForm, validationErrors, getForm]);

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.showPicker) {
      inputElement.showPicker(); // Open date picker on click
    }
  };
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  // Effect to update birthday when selectedDate changes
  useEffect(() => {
    if (selectedDate) {
      setUserBasicForm((prevForm) => ({
        ...prevForm,
        birthday: formatDate(selectedDate),
      }));
    }
  }, [selectedDate]);

  const formatDate = (date: Date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
  };

  return (
    <>
      <form
        action=""
        className="flex flex-wrap md:flex-nowrap gap-x-2 px-4 py-3 mb-3 border-[1px] border-black/10 rounded-md items-center"
      >
        <div className="flex flex-col w-[20rem]">
          <label
            htmlFor="title"
            className="text-sm font-medium text-gray-700 mb-2"
          >
            Title<span className="text-red-600 text-sm">*</span>
          </label>
          <select
            id="title"
            name="title"
            value={title}
            onChange={handleTitleChange}
            className="px-1 py-1 outline-none border-[1px] shadow-md rounded-md w-full"
          >
            <option value="Mr">Mr</option>
            <option value="Ms">Ms</option>
            <option value="Mrs">Mrs</option>
          </select>
          <div className="flex w-full h-5 "></div>
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor="firstName"
            className="text-sm font-medium text-gray-700 mb-2"
          >
            First Name <span className="text-red-600">*</span>
          </label>
          <input
            name="firstName"
            id="firstName"
            onChange={onChangeInputField}
            className="px-2 py-1 outline-none border-[1px] shadow-md rounded-md w-full "
            placeholder="Your first name"
            required
          />
          <div className="flex w-full h-5 ">
            {validationErrors.firstName && (
              <span className="text-red-600 text-xs">
                {validationErrors.firstName}
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor="middleName"
            className="text-sm font-medium text-gray-700 mb-2"
          >
            Middle Name
          </label>
          <input
            name="middleName"
            id="middleName"
            onChange={onChangeInputField}
            className="px-2 py-1 outline-none border-[1px] shadow-md rounded-md w-full"
            placeholder="Your middle name"
          />
          <div className="flex w-full h-5 ">
            {validationErrors.middleName && (
              <span className="text-red-600 text-xs">
                {validationErrors.middleName}
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor="LastName"
            className="text-sm font-medium text-gray-700 mb-2"
          >
            Last Name <span className="text-red-600">*</span>
          </label>
          <input
            name="lastName"
            id="lastName"
            onChange={onChangeInputField}
            className="px-2 py-1 outline-none w-full border-[1px] shadow-md rounded-md"
            placeholder="Your last name"
            required
          />
          <div className="flex w-full h-5 ">
            {validationErrors.lastName && (
              <span className="text-red-600 text-xs">
                {validationErrors.lastName}
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor="birthday"
            className="text-sm font-medium text-gray-700 mb-2"
          >
            Date of Birth <span className="text-red-600">*</span>
          </label>
          <CalenderDOB
            userType={PsgType}
            onSelectDate={setSelectedDate}
            selectedDate={selectedDate}
          />
          <div className="flex w-full h-5 "></div>
        </div>

        {/* {PsgType === "INF" && (
          <div className="flex flex-col w-full">
            <label
              htmlFor="associatedPassengerIndex"
              className="text-sm font-medium text-gray-700 mb-2"
            >
              Assoc. Pax <span className="text-red-600">*</span>
            </label>
            <select
              name="associatedPassengerIndex"
              id="Passenger"
              className="px-2 py-1 outline-none border-[1px] shadow-md rounded-md w-full"
              required
              onChange={onChangeInputField}
              defaultValue={"select"}
            >
              <option value={"select"}>Select</option>
              {Array.from({ length: adultsLenght }).map((_, index) => (
                <option value={index + 1} key={index}>
                  {index + 1}
                </option>
              ))}
            </select>
            <div className="flex w-full h-5 "></div>
          </div>
        )} */}

        <div className="flex flex-col w-full">
          <label
            htmlFor="sex"
            className="text-sm font-medium text-gray-700 mb-2"
          >
            Gender <span className="text-red-600">*</span>
          </label>
          <select
            name="sex"
            id="sex"
            value={UserBasicForm.sex}
            className="px-2 py-1 outline-none border-[1px] shadow-md rounded-md w-full"
            required
            disabled
            onChange={onChangeInputField}
          >
            <option value="M">Male</option>
            <option value="F">Female</option>
          </select>
          <div className="flex w-full h-5 "></div>
        </div>
      </form>
    </>
  );
};

export default Userform;
