import React, { useState, useEffect } from "react";
import { FaCalendar } from "react-icons/fa";

type UserType = "ADT" | "CHD" | "INF";

interface CalenderDOBProps {
  userType: UserType;
  selectedDate: Date | null;
  onSelectDate: (date: Date) => void;
}

export default function CalenderDOB({
  userType,
  onSelectDate,
  selectedDate,
}: CalenderDOBProps) {
  const [isOpen, setIsOpen] = useState(false);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [availableYears, setAvailableYears] = useState<number[]>([]);

  const getMinMaxDates = (type: UserType): { minDate: Date; maxDate: Date } => {
    const today = new Date();
    const minDate = new Date(today);
    const maxDate = new Date(today);

    switch (type) {
      case "ADT":
        minDate.setFullYear(today.getFullYear() - 120);
        maxDate.setFullYear(today.getFullYear() - 12);
        break;
      case "CHD":
        minDate.setFullYear(today.getFullYear() - 12);
        maxDate.setFullYear(today.getFullYear() - 2);
        break;
      case "INF":
        minDate.setFullYear(today.getFullYear() - 2);
        break;
    }

    return { minDate, maxDate };
  };

  const { minDate, maxDate } = getMinMaxDates(userType);

  useEffect(() => {
    const years = [];
    for (
      let year = maxDate.getFullYear();
      year >= minDate.getFullYear();
      year--
    ) {
      years.push(year);
    }
    setAvailableYears(years);
    // Set the default year to the first available year
    if (years.length > 0 && currentDate.getFullYear() !== years[0]) {
      setCurrentDate(new Date(years[0], currentDate.getMonth(), 1));
    }
  }, [userType]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const isDateInRange = (date: Date) => {
    return date >= minDate && date <= maxDate;
  };

  const getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getMonthData = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = getDaysInMonth(year, month);
    const days = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(null);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      days.push(new Date(year, month, i));
    }

    return days;
  };

  const handleYearChange = (year: string) => {
    setCurrentDate(new Date(parseInt(year), currentDate.getMonth(), 1));
  };

  const handleMonthChange = (month: string) => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), months.indexOf(month), 1)
    );
  };

  const handleSelectDate = (date: Date) => {
    if (isDateInRange(date)) {
      onSelectDate(date);
      setIsOpen(false);
    }
  };

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`w-full  flex items-center gap-1 px-1 py-1 bg-white border border-gray-200 rounded-md hover:bg-gray-50 transition-colors shadow-md      `}
      >
        <FaCalendar className="text-gray-500" />
        {selectedDate ? (
          <div className="flex-1 py-0.5 text-left">
            <p className="text-sm ">{formatDate(selectedDate)}</p>
          </div>
        ) : (
          <span className="text-gray-500 py-0.5 text-sm text-left flex-1">
            Select Date
          </span>
        )}
      </button>

      {isOpen && (
        <>
          <div
            className=" fixed inset-0 z-10 bg-black/5"
            onClick={() => setIsOpen(false)}
          />
          <div className="absolute left-0 top-full mt-2 z-20 bg-white rounded-lg shadow-xl border border-gray-200 p-4 w-[280px]">
            <div className="flex gap-2 mb-4">
              <select
                value={currentDate.getFullYear()}
                onChange={(e) => handleYearChange(e.target.value)}
                className="flex-1 px-2 py-1 bg-white border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {availableYears.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <select
                value={months[currentDate.getMonth()]}
                onChange={(e) => handleMonthChange(e.target.value)}
                className="flex-1 px-2 py-1 bg-white border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>

            <div className="grid grid-cols-7 gap-1 text-center mb-2">
              {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day) => (
                <div
                  key={day}
                  className="text-xs font-medium text-gray-500 py-1"
                >
                  {day}
                </div>
              ))}
            </div>

            <div className="grid grid-cols-7 gap-1">
              {getMonthData().map((date, index) => (
                <button
                  key={index}
                  onClick={() => date && handleSelectDate(date)}
                  disabled={!date || !isDateInRange(date)}
                  className={`
                    p-2 text-sm rounded-md transition-colors
                    ${!date ? "invisible" : ""}
                    ${
                      date && isDateInRange(date)
                        ? "hover:bg-blue-50 text-gray-700"
                        : "text-gray-300 cursor-not-allowed"
                    }
                    ${
                      date &&
                      selectedDate &&
                      date.toDateString() === selectedDate.toDateString()
                        ? "bg-blue-500 text-white hover:bg-blue-600"
                        : ""
                    }
                  `}
                >
                  {date ? date.getDate() : ""}
                </button>
              ))}
            </div>

            <div className="mt-4 p-2 bg-gray-50 rounded-md">
              <p className="text-xs text-gray-600">
                {userType === "ADT" && "12+ years old"}
                {userType === "CHD" && "2-11 years old"}
                {userType === "INF" && "Under 2 years old"}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
