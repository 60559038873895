import { MapImg } from "../../utils/images";
import Button from "../Buttons/Button";
import { FlightRoutes } from "../../dummydata/flightRouts";

const Routes = () => {
  return (
    <div className={`flex flex-wrap w-[95%] md:w-[95%] rounded-lg`}>
      <div className="w-[90%] mx-auto sm:w-[100%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4  lg:gap-4 gap-3 md:gap-4">
        {FlightRoutes.map((flightRoute, index) => (
          <Button
            key={index}
            imgSrc={flightRoute.imgSrc}
            departure={flightRoute.departure}
            arrival={flightRoute.arrival}
            departureTitle={flightRoute.departureTitle}
            arrivalTittle={flightRoute.arrivalTittle}
          />
        ))}
      </div>
    </div>
  );
};

export default Routes;
