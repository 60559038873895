import React from "react";
import { Trust } from "../../utils/images";

interface Props {
  src?: string;
  title?: string;
  desc?: string;
}

const Achieve: React.FC<Props> = ({ src, title, desc }) => {
  return (
    <div className="border-[1px] w-[16rem] h-[12rem] border-[#ec2027]/60 rounded-xl px-2 py-4 shadow-md flex flex-col items-center justify-center">
      <img src={src ? src : Trust} alt="img" className="h-[4rem]" />
      <h2 className="text-lg font-bold mt-4 mb-1">
        {title ? title : "Trusted"}
      </h2>
      <p className="w-[12rem] text-center text-xs font-semibold">
        {desc ? desc : ""}
      </p>
    </div>
  );
};

export default Achieve;
