import React, { ChangeEvent, useEffect, useState } from "react";
import { CardCVV } from "../../utils/icons";
import YearMonthPicker from "../FlightDetails.component/YearMonthPicker";

export interface Payment {
  cardNum: string;
  cardType: string;
  cardExpiredDate: string;
  cvv: string;
  cardHolderName: string;
}

interface PaymentInfoProp {
  getForm: (obj: Payment) => void;
}

const PaymentInfoform: React.FC<PaymentInfoProp> = ({ getForm }) => {
  const [paymentInfo, setPaymentInfo] = useState<Payment>({
    cardNum: "",
    cardType: "",
    cardExpiredDate: "",
    cvv: "",
    cardHolderName: "",
  });

  const [cardError, setCardError] = useState("");
  const [cvvError, setCvvError] = useState("");
  const [cardHolderNameError, setCardHolderNameError] = useState("");
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");

  const handleYearMonthSelect = (year: number | null, month: number | null) => {
    if (year !== null && month !== null) {
      setPaymentInfo({
        ...paymentInfo,
        cardExpiredDate: `${month + 1}/${year}`,
      });
    } else {
      setPaymentInfo({ ...paymentInfo, cardExpiredDate: `` });
    }
  };
  const onChangeInputField = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPaymentInfo({ ...paymentInfo, [name]: value });

    if (name === "cardNum") validateCardNumber(value);
    if (name === "cvv") validateCvv(value);
    if (name === "cardHolderName") validateCardHolderName(value);
  };

  useEffect(() => {
    getForm(paymentInfo);
  }, [paymentInfo, getForm]);

  useEffect(() => {
    const today = new Date();
    const formatDate = (date: Date) => date.toISOString().split("T")[0];

    // Default to today's date as max for all types
    setMinDate(formatDate(today));

    const maxCardDate = new Date(today);
    maxCardDate.setFullYear(today.getFullYear() + 15);
    setMaxDate(formatDate(maxCardDate));
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.showPicker) {
      inputElement.showPicker(); // Open date picker on click
    }
  };

  const validateCardNumber = (cardNumber: string) => {
    const patterns: { [key: string]: RegExp } = {
      M: /^5[1-5][0-9]{14}$/, // MasterCard
      V: /^4[0-9]{12}(?:[0-9]{3})?$/, // Visa
      A: /^3[47][0-9]{13}$/, // American Express
      DS: /^6(?:011|5[0-9]{2})[0-9]{12}$/, // Discover
    };

    const pattern = patterns[paymentInfo.cardType];
    if (pattern && !pattern.test(cardNumber)) {
      setCardError(
        `Invalid ${getCardTypeName(paymentInfo.cardType)} card number.`
      );
    } else {
      setCardError("");
    }
  };

  const validateCvv = (cvv: string) => {
    const cvvLength = paymentInfo.cardType === "A" ? 4 : 3;
    if (cvv.length !== cvvLength) {
      setCvvError(`CVV must be ${cvvLength} digits.`);
    } else {
      setCvvError("");
    }
  };

  const validateCardHolderName = (value: string) => {
    if (!/^[a-zA-Z\s]+$/.test(value)) {
      setCardHolderNameError(`Name is invalid.`);
    } else {
      setCardHolderNameError("");
    }
  };

  const getCardTypeName = (type: string) => {
    switch (type) {
      case "M":
        return "MasterCard";
      case "V":
        return "Visa";
      case "A":
        return "American Express";
      case "DS":
        return "Discover";
      default:
        return "Card";
    }
  };

  return (
    <form action="" className="flex flex-col px-4 py-5 gap-y-2">
      <div className="grid grid-cols-1 sm:grid-cols-3 w-full gap-x-2 items-center">
        <label htmlFor="method" className="text-sm font-bold text-gray-700">
          Payment Method<span className="text-red-600">*</span>
        </label>
        <select
          name="cardType"
          id="method"
          className="px-2 py-1 outline-none border-[1px] shadow-md rounded-md"
          required
          onChange={onChangeInputField}
          defaultValue={"select"}
        >
          <option value="select">Select</option>
          <option value="M">MasterCard</option>
          <option value="V">Visa</option>
          <option value="A">American Express</option>
          <option value="DS">Discover</option>
        </select>
        <div></div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 w-full gap-x-2 items-center">
        <label htmlFor="cardNumber" className="text-sm font-bold text-gray-700">
          Credit or Debit Card Number
          <span className="text-red-600">*</span>
        </label>
        <div>
          <input
            name="cardNum"
            id="cardNumber"
            onChange={onChangeInputField}
            className="px-2 py-1 outline-none border-[1px] shadow-md rounded-md w-full"
            placeholder="Enter You Card Details"
            required
          />
          {cardError && <p className="text-red-600 text-xs">{cardError}</p>}
        </div>
        <p className="text-sm font-medium text-gray-700">
          (Pay with credit or debit card)
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 w-full gap-x-2 items-center">
        <label
          htmlFor="CaHolderName"
          className="text-sm font-bold text-gray-700"
        >
          Card Holder Name<span className="text-red-600 text-xs ">*</span>
        </label>
        <div>
          <input
            name="cardHolderName"
            id="cardHolderName"
            onChange={onChangeInputField}
            className="px-2 py-1 outline-none border-[1px] shadow-md rounded-md w-full"
            placeholder="Card Holder Name"
            required
          />
          {cardHolderNameError && (
            <p className="text-red-500 text-xs">{cardHolderNameError}</p>
          )}
        </div>
        <p className="text-sm font-medium text-gray-700">
          (As it appears on your credit card)
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 w-full gap-x-2 items-center">
        <label
          htmlFor="cardExpiredDate"
          className="text-sm  font-bold text-gray-700"
        >
          Expiration<span className="text-red-600 text-xs">*</span>
        </label>

        <YearMonthPicker yearRange={10} onSelect={handleYearMonthSelect} />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 w-full gap-x-2 items-center">
        <label htmlFor="CVNumber" className="text-sm font-bold text-gray-700">
          Card Verification Number
          <span className="text-red-600 text-xs">*</span>
        </label>
        <div>
          <input
            name="cvv"
            id="CVNumber"
            pattern="[0-9],{4}"
            maxLength={4}
            onChange={onChangeInputField}
            className="px-2 py-1 outline-none border-[1px] shadow-md rounded-md w-full"
            placeholder="Enter Card Verification No"
            required
          />
          {cvvError && <p className="text-red-500 text-xs">{cvvError}</p>}
        </div>
        <div className="flex items-center">
          <img src={CardCVV} alt="card cvv number" width={50} />
          <span className="text-sm font-medium text-gray-700">
            3 digit number from your card
          </span>
        </div>
      </div>
    </form>
  );
};

export default PaymentInfoform;
