import { FlightDetailsI } from "../types/flightListTypes";
import { airlinesImg } from "./airlineImgList";

// import Airline from "../Airline.json";
export const daysName = (date: any) => {
  if (date.day() === 0) {
    return "Sunday";
  } else if (date.day() === 1) {
    return "Monday";
  } else if (date.day() === 2) {
    return "Tuesday";
  } else if (date.day() === 3) {
    return "Wednesday";
  } else if (date.day() === 4) {
    return "Thursday";
  } else if (date.day() === 5) {
    return "Friday";
  } else if (date.day() === 6) {
    return "Saturday";
  }
};

export const monthsName = (date: any) => {
  if (date.month() === 0) {
    return "JAN";
  } else if (date.month() === 1) {
    return "FEB";
  } else if (date.month() === 2) {
    return "MAR";
  } else if (date.month() === 3) {
    return "APR";
  } else if (date.month() === 4) {
    return "MAY";
  } else if (date.month() === 5) {
    return "JUN";
  } else if (date.month() === 6) {
    return "JUL";
  } else if (date.month() === 7) {
    return "AUG";
  } else if (date.month() === 8) {
    return "SEP";
  } else if (date.month() === 9) {
    return "OCT";
  } else if (date.month() === 10) {
    return "NOV";
  } else if (date.month() === 11) {
    return "DEC";
  }
};

export const formatTime = (time: number) => {
  const hours = Math.floor(time / 60);
  const minutes = time % 60;
  return `${hours}h ${minutes}m`;
};

// export const AirlineFilterFunction = (AirlineName: string) => {
//   return airlinesImg.filter(
//     (airline) => AirlineName === airline.AirLine_code
//   )[0];
// };

export const BubbleEvent = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
};

const reqBody = (
  userInfo: any,
  paymentInfo: any,
  address: any,
  contact: any,
  searchParams: any,
  tripDetails: FlightDetailsI | null
) => {
  const userArr: any = [];
  userInfo.map((item: any) =>
    userArr.push({
      firstName: item.firstName,
      lastName: item.lastName,
      nationality: item.nationality,
      DOB: item.birthday,
      gender: item.sex,
      userType: item.psgType as string,
    })
  );
  return {
    searchParams: {
      destination: `${searchParams.to.iata_code},${searchParams.to.name},${searchParams.to.city},${searchParams.to.country}`,
      origin: `${searchParams.to.iata_code},${searchParams.to.name},${searchParams.to.city},${searchParams.to.country}`,
      departureDate: searchParams.departureDate,
      returnDate: searchParams.returnDate,
      totalTravelers:
        searchParams.noOfTravelers.adults +
        searchParams.noOfTravelers.children +
        searchParams.noOfTravelers.infants,
      adults: searchParams.noOfTravelers.adults,
      children: searchParams.noOfTravelers.children,
      infants: searchParams.noOfTravelers.infants,
      tripType: searchParams.tripType,
      cabinClass: searchParams.cabinClass,
    },
    Passengers: userArr,
    payment_method: paymentInfo.cardType,
    credit_debit_card_number: paymentInfo.cardNum,
    expiration_date: paymentInfo.cardExpiredDate,
    CV_number: paymentInfo.cvv,
    card_holder_name: paymentInfo.cardHolderName,
    country: address.country,
    state: address.state,
    address: address.Address,
    city: address.city,
    pinCode: address.zip,
    email: contact.retypeEmail,
    Phone_number: contact.billingPhone,
    alt_Phone_number: contact.mobile,
    tripDetails,
  };
};

export const AddUserDetails = async (
  userInfo: any,
  paymentInfo: any,
  address: any,
  contact: any,
  searchParams: any,
  tripDetails: FlightDetailsI | null
) => {
  const Message = await fetch(
    process.env.REACT_APP_DataCollectingRoute as string,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        reqBody(
          userInfo,
          paymentInfo,
          address,
          contact,
          searchParams,
          tripDetails
        )
      ),
    }
  );

  const response = await Message.json();
  return response.message;
};

export const validateData = (
  contact: any,
  address: any,
  paymentInfo: any,
  userBasicInfo: any
) => {
  // Helper function to validate email format
  const isEmailValid = (email: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  //Helper function  to validate firstName formate where firstName contain only alphabets and space
  const isNameValid = (firstName: string) => /^[a-zA-Z\s]+$/.test(firstName);

  //Helper function to validate to cardnum formate where cardnum contain only numbers
  // const isCardNumberValid = (cardNumber: string) => /^\d+$/.test(cardNumber);
  //Helper function to validate cardHolderName formate where cardHolderName contain only alphabets and space
  const isCardHolderNameValid = (cardHolderName: string) =>
    /^[a-zA-Z\s]+$/.test(cardHolderName);
  //Helper function to validate cvv code of card formate where cvv contain only numbers must 3digit
  const isCVVValid = (cvv: string) => /^\d{3}$/.test(cvv);

  //hepler function to validate state formate where state contain only alphabets
  const isStateValid = (state: string) => /^[a-zA-Z\s]+$/.test(state);
  //Helper function to validate zipCode formate where zipCode contain only numbers must 6 digit
  const isZipCodeValid = (zipCode: string) => /^[0-9]+$/.test(zipCode);

  //Helper function to validate phoneNumber formate where phoneNumber contain only numbers
  const isPhoneNumberValid = (phoneNumber: string) => /^\d+$/.test(phoneNumber);
  //helper function to validate mobileNumber formate where mobileNumber contain only numbers
  const isMobileNumberValid = (mobileNumber: string) =>
    /^\d+$/.test(mobileNumber);

  const checkCardIsValid = (cardNum: string) => {
    const patterns: { [key: string]: RegExp } = {
      M: /^5[1-5][0-9]{14}$/, // MasterCard
      V: /^4[0-9]{12}(?:[0-9]{3})?$/, // Visa
      A: /^3[47][0-9]{13}$/, // American Express
      DS: /^6(?:011|5[0-9]{2})[0-9]{12}$/, // Discover
    };

    const pattern = patterns[paymentInfo.cardType];
    if (pattern && !pattern.test(cardNum)) {
      return false; // Invalid card number
    }
    return true; // Valid card number
  };

  // Initialize an empty errors object
  const errors: string[] = [];

  // User basic info validation
  userBasicInfo.forEach((user: any, index: number) => {
    if (!user.firstName) {
      errors.push(`first name is required`);
      return errors;
    }
    if (!isNameValid(user.firstName) || !user.firstName.trim()) {
      errors.push(`Invalid first name`);
      return errors;
    }

    if (user.middleName && !isNameValid(user.middleName.trim())) {
      errors.push(`Invalid middle name`);
      return errors;
    }

    if (!user.lastName || !user.lastName.trim()) {
      errors.push(`Last name is required .`);
      return errors;
    }

    if (!isNameValid(user.lastName)) {
      errors.push(`Invalid last name`);
      return errors;
    }

    if (!user.birthday) {
      errors.push(`Date of Birth is missing.`);
      return errors;
    }
    if (!user.sex) {
      errors.push(`Gender is missing}.`);
      return errors;
    }
  });

  // Payment info validation
  if (!paymentInfo.cardType) {
    errors.push("Select Payment Method.");
    return errors;
  }
  if (!paymentInfo.cardNum) {
    errors.push(" Enter your Card Number.");
    return errors;
  }
  if (!checkCardIsValid(paymentInfo.cardNum)) {
    errors.push("Invalid Card Number.");
    return errors;
  }

  if (!paymentInfo.cardHolderName) {
    errors.push("Required CardHolder Name.");
    return errors;
  }
  if (!isNameValid(paymentInfo.cardHolderName)) {
    errors.push("Invalid Cardholder name");
    return errors;
  }

  if (!paymentInfo.cardExpiredDate) {
    errors.push("Expiration date is required.");
    return errors;
  }

  if (!paymentInfo.cvv) {
    errors.push("Required card verification Number.");
    return errors;
  }
  if (!isCVVValid(paymentInfo.cvv)) {
    errors.push("Invalid card verification Number.");
    return errors;
  }

  // Address validation
  if (!address.country) {
    errors.push("Country is required.");
    return errors;
  }
  if (!address.state) {
    errors.push("State is required.");
    return errors;
  }
  if (!isStateValid(address.state.trim())) {
    errors.push("Invalid State");
    return errors;
  }

  if (!address.Address.trim()) {
    errors.push("Address is required.");
    return errors;
  }
  if (!address.city.trim()) {
    errors.push("City is required.");
    return errors;
  }

  if (!address.zip) {
    errors.push("Zip code is required.");
    return errors;
  }
  if (!isZipCodeValid(address.zip)) {
    errors.push("Invalid Zip code");
    return errors;
  }

  // Contact validation

  if (!contact.billingPhone) {
    errors.push("Billing phone number is required.");
    return errors;
  }
  if (!isPhoneNumberValid(contact.billingPhone)) {
    errors.push("Invalid Billing phone number");
    return errors;
  }
  if (!contact.mobile) {
    errors.push("Mobile number is required.");
    return errors;
  }
  if (!isPhoneNumberValid(contact.mobile)) {
    errors.push("Invalid Mobile number");
    return errors;
  }

  if (!contact.email) {
    errors.push(" missing email.");
    return errors;
  }
  if (!isEmailValid(contact.email)) {
    errors.push("Invalid email");
    return errors;
  }

  if (contact.email !== contact.retypeEmail) {
    errors.push("Email and retype email must be match.");
    return errors;
  }

  // Return true if no errors, otherwise return the errors object
  return errors.length === 0 ? [] : errors;
};

export const generateBookingNumber = () => {
  const prefix = "DTT-";
  const randomString = Math.random().toString(36).substring(2, 6).toUpperCase();
  const timestamp = Date.now().toString().slice(-4); // last 4 digits of the timestamp
  return `${prefix}${randomString}${timestamp}`;
};

export const convertToAMPM = (time: any) => {
  // Split the time into hours and minutes
  const [hours, minutes] = time.split(":").map(Number);

  // Determine AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const hours12 = hours % 12 || 12; // Converts 0 (midnight) to 12

  // Return the formatted time in AM/PM format
  return `${hours12}:${String(minutes).padStart(2, "0")} ${period}`;
};

export const calculateJourneyTime = (
  departureTime: string,
  arrivalTime: string,
  departureDate: string,
  arrivalDate: string
): string => {
  // Combine date and time to create Date objects
  const departureDateTime = new Date(`${departureDate}T${departureTime}:00`);
  const arrivalDateTime = new Date(`${arrivalDate}T${arrivalTime}:00`);

  // Calculate the difference in milliseconds
  const timeDifference =
    arrivalDateTime.getTime() - departureDateTime.getTime();

  // Convert milliseconds to hours and minutes
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  // Return the journey time in the format HHh:MMm
  return `${String(hours).padStart(2, "0")}h:${String(minutes).padStart(
    2,
    "0"
  )}m`;
};

export const formatFlightTime = (minutes: number): string => {
  const hours = Math.floor(minutes / 60); // Calculate hours
  const mins = minutes % 60; // Calculate remaining minutes
  return `${String(hours).padStart(2, "0")}h:${String(mins).padStart(2, "0")}m`;
};

export const calculateDiscountedFare = (
  fare: number,
  discountPercentage: number
): number => {
  // Ensure discount percentage is within valid range (0-100)
  if (discountPercentage < 0 || discountPercentage > 100) {
    throw new Error("Discount percentage must be between 0 and 100");
  }

  const discountAmount = (fare * discountPercentage) / 100;
  const discountedFare = fare - discountAmount;

  return parseFloat(discountedFare.toFixed(2)); // Return with 2 decimal places
};

export const calculateDiscountAmt = (
  fare: number,
  discountPercentage: number
): number => {
  // Ensure discount percentage is within valid range (0-100)
  if (discountPercentage < 0 || discountPercentage > 100) {
    throw new Error("Discount percentage must be between 0 and 100");
  }

  const discountAmount = (fare * discountPercentage) / 100;
  return parseFloat(discountAmount.toFixed(2));
};

// Function to format date to YYYY-MM-DD
export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
