import React, { useEffect, useState } from "react";

interface Testimonial {
  id: number;
  name: string;
  message: string;
  rating: number;
}

interface TestimonialCarouselProps {
  testimonials: Testimonial[];
}

const TestimonialCarousel: React.FC<TestimonialCarouselProps> = ({
  testimonials,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const renderStars = (rating: number) => {
    return Array.from({ length: 5 }, (_, index) => (
      <span
        key={index}
        className={index < rating ? "text-yellow-500" : "text-gray-300"}
      >
        ★
      </span>
    ));
  };

  useEffect(() => {
    if (isPaused) return;
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);
    return () => clearInterval(interval);
  }, [isPaused, currentIndex]);

  return (
    <div
      className="relative w-full max-w-4xl mx-auto overflow-hidden bg-gray-100 p-2 rounded-md"
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      {/* Testimonial Content */}
      <div className="flex flex-row items-center justify-end gap-x-2">
        {/* Navigation Buttons */}
        <button
          onClick={prevSlide}
          className="relative w-8 h-8 bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white rounded-full"
        >
          <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            {"<"}
          </p>
        </button>
        <button
          onClick={nextSlide}
          className="relative w-8 h-8 bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white text-center rounded-full"
        >
          <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            {">"}
          </p>
        </button>
      </div>
      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {testimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className="w-full flex-shrink-0 p-4 flex flex-col items-center text-center"
          >
            <p className="text-sm md:text-lg font-medium mb-2">
              "{testimonial.message}"
            </p>
            <div className="flex justify-center mb-1">
              {renderStars(testimonial.rating)}
            </div>
            <h3 className="text-lg md:text-xl font-bold">{testimonial.name}</h3>
          </div>
        ))}
      </div>
      {/* Indicators */}
      <div className="flex justify-center space-x-2">
        {testimonials.map((_, index) => (
          <div
            key={index}
            className={`w-1 h-1 rounded-full ${
              index === currentIndex ? "bg-blue-500" : "bg-gray-300"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default TestimonialCarousel;
