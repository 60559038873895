import React from "react";
import { CgArrowsExchangeAlt } from "../../utils/solidIcons";
import { useNavigate } from "react-router-dom";
import { airportList } from "../../utils/airportList";
import { AirportListI } from "../../types/flightSearchReducer.types";
import { convertDate } from "../../utils/formatTimeInMinuteToHourandMinute";

interface ButtonI {
  imgSrc: string;
  departure: string;
  arrival: string;
  departureTitle: string;
  arrivalTittle: string;
}
const Button: React.FC<ButtonI> = ({
  imgSrc,
  departure,
  arrival,
  departureTitle,
  arrivalTittle,
}) => {
  const Navigate = useNavigate();

  // Define the dates
  const today: Date = new Date();
  const departureDate: Date = new Date(today);
  departureDate.setDate(today.getDate() + 4);
  const arrivalDate: Date = new Date(departureDate);
  arrivalDate.setDate(departureDate.getDate() + 4);

  const fromField = airportList.find(
    (airport: AirportListI) => airport.iata_code === departure
  )!;
  const toField = airportList.find(
    (airport: AirportListI) => airport.iata_code === arrival
  )!;

  const adults = 1;
  const children = 0;
  const infants = 0;
  const tripType = "roundtrip";
  const cabinClass = "Economy";

  const onButtonClickHandler = () => {
    const searchQuery = {
      from: fromField,
      to: toField,
      departureDate,
      returnDate: arrivalDate,
      selectedReturnDate: arrivalDate,
      tripType,
      cabinClass,
      noOfTravelers: {
        adults: 1,
        children: 0,
        infants: 0,
      },
    };
    localStorage.setItem(
      "flightSearchKey",
      JSON.stringify(btoa(JSON.stringify(searchQuery)))
    );
    Navigate(
      `/flight/search?origin=${fromField.iata_code}&destination=${
        toField.iata_code
      }&trip-type=${tripType}&cabin-class=${cabinClass}&adults=${adults}&children=${children}&infants=${infants}&departure-date=${convertDate(
        departureDate
      )}&return-date=${convertDate(arrivalDate)}`
    );
  };
  return (
    <button
      className=" w-full text-xs   font-medium text-gray-700 h-fit flex items-center justify-between px-2 gap-2 py-2 bg-white/70 shadow-md border-[1px] hover:bg-gradient-to-r from-[#ec2027] to-[#c81212] hover:text-white border-black/15 rounded-2xl"
      onClick={onButtonClickHandler}
    >
      <img
        src={`${imgSrc}`}
        alt={`${departure}-${arrival}`}
        className="rounded-full w-[2rem] h-[2rem] sm:w-[3rem] sm:h-[3rem]"
      />
      <span>{departureTitle}</span>
      <CgArrowsExchangeAlt className="text-2xl" />
      <span>{arrivalTittle}</span>
    </button>
  );
};

export default Button;
