import React, { useEffect } from "react";
import Container from "../components/Containers/Container.component";
import Routes from "../components/homeComponent/Routes.component";
import Achieve from "../components/Cards/Achieve.card";
import FaqAccordion from "../components/Accordion/Faq.accordion";
import { useAppDispatch } from "../store";
import { setFlightSearchParams } from "../store/reducers/flightSearch.reducer";
import { reviews } from "../dummydata/reviews";
import ScrollToTop from "../components/ScrollToTop";
import ContactBanner from "../components/Banner/ContactBanner";
import CallBtn from "../components/Buttons/CallBtn";
import { Helmet } from "react-helmet-async";
import HeroSection from "../components/Flights/HeroSection";
import { Why } from "../dummydata/Data";
import TestimonialCarousel from "../components/carousal/Testimonials";

const Homepage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setFlightSearchParams({
        destination: "",
        origin: "",
        departureDate: null,
        returnDate: null,
        totalTravelers: 0,
        adults: 1,
        children: 0,
        infants: 0,
        tripType: "roundtrip",
        cabinClass: "Economy",
      })
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Book Flights, Grab festive Air deals & Cheap Air Tickets-@DT&T
        </title>
        <meta
          name="description"
          content="Flight Booking & Air Tickets at cheapest Airfare, Book online air tickets Compare top airlines to Grab Best 
Deals & book Flight with ease #BookNow #CheapFlights "
        />
        <meta
          name="keywords"
          content="Cheap Air Tickets,Festive air Deals,Air Tickets,Best Deals"
        />
        <meta property="og:title" content="Dreams tavel and tours" />
        <meta property="og:description" content="book flight" />
        <meta property="og:type" content="article" />
      </Helmet>
      <ScrollToTop />
      <HeroSection />
      <div className="w-full flex flex-col items-center my-2 gap-y-5">
        <Container title="Top" title2="Flight Routes">
          <Routes />
        </Container>

        <Container title="Why" title2="Dreams Travel & Tours ?">
          <div className="lg:w-full xl:w-[80%] grid mt-2 place-items-center sm:grid-cols-2 sm:grid-rows-2 lg:grid-cols-4 lg:grid-rows-1 gap-y-2 gap-x-2">
            {Why.map((e, index) => (
              <Achieve
                title={e.title}
                src={e.icons}
                desc={e.desc}
                key={index}
              />
            ))}
          </div>
        </Container>
        <div className="w-[98vw] lg:w-[80vw] xl:w-[70vw] my-2">
          <div className="hidden xs:block">
            <ContactBanner />
          </div>
          <div className=" xs:hidden">
            <CallBtn />
          </div>
        </div>
        <Container
          title="Customer"
          title2="Reviews"
          des="What do our dream travelers say about us"
        >
          <TestimonialCarousel testimonials={reviews} />
        </Container>
        <Container title="FAQ" title2=" " des="Frequently Ask Questions">
          <FaqAccordion />
        </Container>
      </div>
    </>
  );
};

export default Homepage;
